import { useDebounce } from 'use-debounce'
import useDeepEffect from 'use-deep-compare-effect'
import {
  AppliedCategoryFilters,
  useAppliedFilterOptions,
  useCustomFilters,
} from './Hooks/useAppliedFilters'
import DataTableSlim, {
  OrderBy,
  DataTableSlimColumn,
  DataTableSlimProps,
  Column,
  ValueOf,
  Categorizable,
  ColumnsOrder,
} from './DataTableSlim'
import { useEffect, useState, useMemo } from 'react'
import { ServerSelectedItems, Page } from './types'
import { PageSize } from './DataTableSlimPagination'
import { handleOnSelect } from './Utils'
import { useDataTable } from './Hooks/useDataTable'
import { useCategoriesLazyQuery } from 'graphql/__generated__/hasura-types'

type DataTableProps =
  | 'id'
  | 'title'
  | 'striped'
  | 'searchable'
  | 'selectable'
  | 'allowExportToCsv'
  | 'addableColumns'
  | 'columnManager'
  | 'blur'
  | 'editColumn'
  | 'scrollable'
  | 'filterable'
  | 'defaultSelectAll'
  | 'defaultNoDataMessage'
  | 'wideHeader'
  | 'fixed'
  | 'padded'

type ExposedDataTableProps<T extends {}> = Pick<DataTableSlimProps<T>, DataTableProps>

type ServerDataTableProps<T extends {}> = ExposedDataTableProps<T> & {
  debounceMs?: number
  data: Categorizable<T>[]
  idSelector?: (item: T) => ValueOf<T>
  columns: Column<T>[]
  defaultSort?: OrderBy
  loading: boolean
  totalItems: number
  totalItemsSelectable?: number
  totalItemsSelected?: number
  onApplyFilters?: (appliedFilters: AppliedCategoryFilters) => void
  onSort?: (orderBy: OrderBy) => void
  onPageChange?: (page: Page) => void
  onSearch?: (val: string) => void
  onSelect?: (selectedItems: ServerSelectedItems) => void
  exportProcessing?: boolean
  onExport?: (columns: DataTableSlimColumn<T>[], addedColumns: string[]) => void
  persistAddedColumns?: boolean
  columnsOrder?: ColumnsOrder[]
  addCategoryCols?: boolean
  includeGeneratedCategories?: boolean
  errorOnRow?: (e: {}) => boolean
  filterDrawer?: boolean
  tableHeaderColumnAutoWidth?: boolean
}

function ServerDataTable<T extends {}>(props: ServerDataTableProps<T>) {
  const {
    idSelector = (row: any) => row.id,
    defaultSort,
    addableColumns,
    columns,
    columnsOrder,
    data,
    onSort,
    onSearch,
    loading = true,
    onSelect,
    exportProcessing,
    blur,
    columnManager,
    persistAddedColumns = true,
    editColumn,
    addCategoryCols,
    includeGeneratedCategories = false,
    errorOnRow,
    filterDrawer,
  } = props

  const [fetchCategoryCols, { data: categoryResult }] = useCategoriesLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: includeGeneratedCategories
      ? {}
      : {
        where: {
          category_type: {
            _eq: 'CUSTOM',
          },
        },
      },
  })

  useEffect(() => {
    if (addCategoryCols) {
      fetchCategoryCols()
    }
  }, [addCategoryCols])

  const categories = categoryResult?._categories ?? []

  const { categoryFilters = [] } = useAppliedFilterOptions(props.id)
  const [localSearchValue, setLocalSearchValue] = useState<string>()
  const [debouncedValue] = useDebounce(
    localSearchValue,
    props.debounceMs || 300,
  )
  useEffect(() => {
    if (debouncedValue !== undefined) {
      if (onSearch) {
        onSearch(debouncedValue)
      }
    }
  }, [debouncedValue])

  const {
    setAddedColumns,
    setSearchValue,
    setOrderBy,
    setPage,
    setSelectedItems,
    tableData,
  } = useDataTable<any>(props.id)
  const { customFilters } = useCustomFilters(props.id)

  const {
    selectedItems: currentSelectedItems,
    orderBy = defaultSort,
    addedColumns,
    searchValue,
    page,
  } = tableData || {
    selectedItems: {
      items: [],
      ids: [],
    },
    addedColumns: columns.map(c => c.name),
    searchValue: '',
    orderBy: defaultSort,
    page: { number: 1, size: 25 },
    categorySort: {},
  }
  const currentSelectedIds = currentSelectedItems
    ? currentSelectedItems.ids
    : []

  useEffect(() => {
    const staticColumnsMissing = !columns
      .filter(c => c.addable === false)
      .every(c => addedColumns.includes(c.name))
    if (
      addableColumns
      && persistAddedColumns
      && (addedColumns.length === 0 || staticColumnsMissing)
    ) {
      setAddedColumns([
        ...columns.filter(c => c.defaultAdded !== false).map(c => c.name),
        ...addedColumns,
      ])
    }
  }, [columns, persistAddedColumns])

  useDeepEffect(() => {
    if ((props.columnsOrder || []).length > 0) {
      setAddedColumns(
        addedColumns.concat((props.columnsOrder || []).map(i => i.name) || []),
      )
    }
  }, [props.columnsOrder || []])

  const categoryColumns = addableColumns
    ? categories.map(cat => ({
      displayName: cat.displayName ?? '',
      name: cat.id ?? '',
      category_type: cat.category_type,
    }))
    : []

  const combinedColumns: DataTableSlimColumn<T>[] = useMemo(() =>
    columns
      .concat(categoryColumns.map(column => ({ ...column, isCategory: true })))
      .map(column => {
        const exist = columnsOrder ?  columnsOrder.findIndex(item => item.name === column.name)  : -1

        return {
          ...column,
          sortOrder: exist !== -1 ? exist : Infinity,
        }
      })
      .sort((a, b) => a.sortOrder - b.sortOrder),
  [columns, categoryResult, columnsOrder],
  )

  return DataTableSlim<T>({
    id: props.id,
    idSelector,
    title: props.title,
    orderBy,
    selectedIds: currentSelectedIds,
    tableHeaderColumnAutoWidth: props.tableHeaderColumnAutoWidth,
    addableColumns,
    addedColumns,
    columnManager,
    editColumn,
    addCategoryCols,
    errorOnRow,
    filterDrawer,
    onAddColumn: columnName => {
      setAddedColumns([...addedColumns, columnName])
    },
    onRemoveColumn: columnName => {
      const indexToRemove = addedColumns.indexOf(columnName)
      const updatedAddedColumns = [
        ...addedColumns.slice(0, indexToRemove),
        ...addedColumns.slice(indexToRemove + 1),
      ]
      setAddedColumns(updatedAddedColumns)
    },
    searchValue,
    onSearch: val => {
      setSearchValue(val)
      setLocalSearchValue(val)
    },
    onSort: (field, direction) => {
      setOrderBy(field, direction)
      if (onSort) {
        onSort({ field, direction })
      }
    },
    onSelect: (selectType, selectQuantity, items) => {
      const result = handleOnSelect({
        selectType,
        selectQuantity,
        items: items ?? [],
        currentSelectedItems,
        idSelector,
        selectable: props.selectable,
        data,
      })

      setSelectedItems(result)
      if (onSelect) {
        onSelect({
          includedIds: result.includedIds,
          excludedIds: result.excludedIds,
        })
      }
    },
    data: props.data,
    columns: combinedColumns,
    pagination: {
      currentPage: page.number,
      onPageChange: newPageNumber => {
        const newPage = {
          ...page,
          number: newPageNumber,
        }
        setPage(newPage)
        if (props.onPageChange) {
          props.onPageChange(newPage)
        }
      },
      onPageSizeChange: (newPageSize: PageSize) => {
        const newPage = {
          size: newPageSize,
          number: 1,
        }
        setPage(newPage)
        if (props.onPageChange) {
          props.onPageChange(newPage)
        }
      },
      itemsPerPage: page.size,
      totalItems: props.totalItems,
      totalItemsSelectable: props.totalItemsSelectable || 0,
      totalItemsSelected: props.totalItemsSelected || 0,
      allowExportToCsv: props.allowExportToCsv,
      exportProcessing,
      onExportToCsv: () => {
        if (props.onExport) {
          props.onExport(combinedColumns, addedColumns)
        }
      },
      hasItems: data.length > 0,
    },
    defaultNoDataMessage: props.defaultNoDataMessage,
    scrollable: props.scrollable,
    filterable: props.filterable,
    hasAppliedFilters:
      Object.values(categoryFilters).length > 0
      || Object.values(customFilters).length > 0,
    selectable: props.selectable,
    columnsOrder: props.columnsOrder,
    excludedIds: currentSelectedItems.excludedIds,
    includedIds: currentSelectedItems.includedIds,
    loading,
    blur,
    defaultSelectAll: props.defaultSelectAll,
    wideHeader: props.wideHeader,
    fixed: props.fixed,
    padded: props.padded,
  })
}

export default ServerDataTable
