import {
  AuthServiceApiInterface,
  AuthCredentialInterface,
  AuthUserUpdateInterface,
  LocalStorageToken,
  AuthUserInviteInterface,
  AuthUserDeleteInterface,
  AuthUserInviteToken,
  AuthUserInvitePassword,
  AuthUserInviteStatus,
  AuthUserInviteResponseInterface,
  UpdateRoleApiRequest,
} from '../types'
import { Role } from 'shared/types/Role'

const mockLogin = require('./login.json')

export class AuthServiceMock implements AuthServiceApiInterface {
  apiUrl: string
  rest: any
  constructor(apiUrl: string = '', rest: any = null) {
    this.apiUrl = apiUrl
    this.rest = rest
  }

  login(_credentials: AuthCredentialInterface) {
    return Promise.resolve(mockLogin)
  }

  createOrg(_credentials: AuthCredentialInterface) {
    return Promise.resolve(mockLogin)
  }

  resetPassword(_token: LocalStorageToken = '') {
    // TODO: mock from real test response
    return Promise.resolve()
  }

  checkInviteStatus(_inviteToken: AuthUserInviteToken) {
    // TODO: mock from real test response
    return Promise.resolve({})
  }

  setInvitedUserPassword(
    _inviteToken: AuthUserInviteToken, // jwt
    _newPassword: AuthUserInvitePassword,
  ) {
    // TODO: mock from real test response
    return Promise.resolve({
      data: { status: 'invite sent' as AuthUserInviteStatus },
    } as AuthUserInviteResponseInterface)
  }

  inviteUsers(
    _invite: AuthUserInviteInterface,
    _token: LocalStorageToken = '',
  ) {
    // TODO: mock from real test response
    return Promise.resolve()
  }

  getUsers(_token: LocalStorageToken = '') {
    // TODO: mock from real test response
    return Promise.resolve([])
  }

  updateUser(_update: AuthUserUpdateInterface, _token: LocalStorageToken = '') {
    // TODO: mock from real test response
    return Promise.resolve([])
  }

  updateUsers(
    _update: AuthUserUpdateInterface | AuthUserUpdateInterface[],
    _token: LocalStorageToken = '',
  ) {
    // TODO: mock from real test response
    return Promise.resolve([])
  }

  deleteUser(_data: AuthUserDeleteInterface, _token: LocalStorageToken = '') {
    // TODO: mock from real test response
    return Promise.resolve()
  }

  deletePod(podId: string) {
    return Promise.resolve(podId)
  }

  updateRole(data: UpdateRoleApiRequest) {
    return Promise.resolve(data)
  }

  getUserAuthorization() {
    return Promise.resolve({
      authorization: { permissions: ['delete:displays'], roles: [Role['Super Admin']] },
    })
  }
}

export default new AuthServiceMock()
