import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { NetworkStatus } from '@apollo/client'

import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance, OrderBy } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { orderByTransform } from 'shared/domain/pods'
import { getSearchIds } from 'components/DataTableSlim/Utils/hasuraUtils'

import {
  SharingDisplaysQuery,
  SharingDisplaysQueryVariables,
  SharingDisplaysDocument,
  Display,
  DisplaySearchOptions,
  TotalSelectableDisplaysQuery,
  TotalSelectableDisplaysQueryVariables,
  TotalSelectableDisplaysDocument,
  DisplayOrderableField,
  OrderByDisplay,
  Direction,
} from 'graphql/__generated__/types'
import { OptionDate } from 'shared/types/OptionDate'

export const useTable = (optionDate: OptionDate) => {
  const { searchValue: searchTerm, page, selectedItems, orderBy } = useDataTable<Display>(DataTableInstance.Sharing)

  let defaultOrder: OrderByDisplay = {
    direction: Direction.Desc,
    field: DisplayOrderableField.Name,
  }

  if (orderBy) {
    defaultOrder = orderByTransform(orderBy)
  }

  const [orderByParam, setOrderByParam] = useState<OrderByDisplay>(defaultOrder)

  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.Sharing)

  const handleOnSort = (order: OrderBy) => setOrderByParam(orderByTransform(order))

  const displaySearchOptions: DisplaySearchOptions = {
    records: page.size,
    searchTerm,
    page: page.number,
    orderBy: orderByParam,
    metrics: optionDate,
    categories: categoryFilters,
    locationFilters: locationFilters,
  }

  if (searchTerm.length === 0) {
    delete displaySearchOptions.searchTerm
  }

  const { data: tableQueryResponse, networkStatus: tableStatus } = useQuery<
    SharingDisplaysQuery,
    SharingDisplaysQueryVariables
  >(SharingDisplaysDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      options: displaySearchOptions,
    },
  })

  const result = getSearchIds({
    includedIds: selectedItems?.includedIds,
    excludedIds: selectedItems?.excludedIds,
  })
  const { data: totalDisplaysResponse, networkStatus: totalDisplayStatus } = useQuery<
    TotalSelectableDisplaysQuery,
    TotalSelectableDisplaysQueryVariables
  >(TotalSelectableDisplaysDocument, {
    variables: {
      categories: categoryFilters,
      locationFilters: locationFilters,
      searchTerm,
      displayIds: selectedItems?.includedIds?.includes('*') ? selectedItems?.includedIds : result?.include_ids,
      excludeDisplayIds: selectedItems?.excludedIds?.includes('*') ? selectedItems?.excludedIds : result?.exclude_ids,
      isIncludedElementPods: false,
    },
  })

  const totalItemsSelectable = totalDisplaysResponse?.totalSelectableDisplays?.totalRecords ?? 0

  const totalItemsSelected = totalDisplaysResponse?.totalSelectedDisplays?.totalRecords ?? 0

  const totalFilteredItems = tableQueryResponse?.displays?.totalRecords || 0

  const netStatus = [tableStatus, totalDisplayStatus]
  const isLoading = netStatus.includes(NetworkStatus.loading) || netStatus.includes(NetworkStatus.setVariables)

  const hasSelectedPills
    = (totalItemsSelected > 0 && totalItemsSelected < totalFilteredItems)
    || (categoryFilters && categoryFilters.length > 0)
    || (locationFilters)
    || (searchTerm && searchTerm.length > 0)

  return {
    totalItemsSelectable,
    totalItemsSelected,
    totalFilteredItems,
    hasSelectedPills,
    isLoading,
    handleOnSort,
    data: tableQueryResponse?.displays?.items,
    searchOptions: {
      ...displaySearchOptions,
      excludedIds: selectedItems.excludedIds,
      includedIds: selectedItems.includedIds,
    },
  }
}
