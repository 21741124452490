import React from 'react'
import { Menu, Message } from 'semantic-ui-react'
import styles from './ErrorBoundary.module.scss'

const logo = require('assets/solstice_cloud_logo.png')

interface State {
  hasError: boolean
  errorMessage: any
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: '',
    }
  }

  static getDerivedStateFromError(_error: any) {
    return {
      hasError: true,
    }
  }

  componentDidCatch(_error: any) {
    if (window.location.href.indexOf('localhost:3000') > -1) {
      return
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="errorBoundary">
          <Menu id={styles.header} size="huge" borderless inverted>
            <Menu.Item id={styles.logoWrapper}>
              <img src={logo} id={styles.image} />
            </Menu.Item>
          </Menu>
          <div className={styles.errorBoundary}>
            <Message negative>
              <Message.Header>Oops, something went wrong.</Message.Header>
              <p>Please contact a customer support representative.</p>
            </Message>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
