import React, { useState } from 'react'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  useRealtimeDisplaysQuery,
  RealtimeDisplay,
  useRealtimeDisplaysLazyQuery,
} from 'graphql/__generated__/types'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useCustomFilters, useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import RealtimePage from './Realtime'
import { StatusEnum as RealtimeStatus } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'

const RealtimeController = () => {
  const [visiblePodsBox, setVisiblePodsBox] = useState<number[][] | undefined>(undefined)

  const {
    searchValue,
    page,
    orderBy: orderByTable,
  } = useDataTable<RealtimeDisplay>(
    DataTableInstance.Realtime,
  )

  const {
    customFilters: realtimeStatusFilters,
    setCustomFilter: setCustomRealtimeStatusFilter,
  } = useCustomFilters<RealtimeStatus>(DataTableInstance.Realtime)
  
  /**
   * To finish these Realtime Cards, the metrics need to be re designed into 
   * classifications that we can A) Query and B) display coherently.
   * For now we're leaving a stub of how the cards "worked" prior, with zeroes
   * instead of oddly queried values.
   * 
   * Possibly we can present breakdowns like:
   *  Booked, but Empty
   *  Booked, In Use
   *  Unbooked, Empty
   *  Unbooked, In Use (Ad hoc)
   * 
   * But we can't present Occupancy Count without improvements to camera
   * machine learning - for now we only trust the camera to tell us whether
   * there are any occupants or not.
   */
  const {
    unknownRooms,
    loadingUnknownRooms,
    roomsInUse,
    loadingRoomsInUse,
    bookedRooms,
    loadingBookedRooms,
    adhocRooms,
    loadingAdHocRooms,
    totalRooms,
    loadingTotalRooms,
    filteredDisplay,
    isFilteredDisplaysLoading,
  } = {
    unknownRooms: 0,
    loadingUnknownRooms: false,
    roomsInUse: 0,
    loadingRoomsInUse: false,
    bookedRooms: 0,
    loadingBookedRooms: false,
    adhocRooms: 0,
    loadingAdHocRooms: false,
    totalRooms: 0,
    loadingTotalRooms: false,
    filteredDisplay: 0,
    isFilteredDisplaysLoading: false,
  }

  const realtimeStatusCustomFilterOptions: {
    realtimeStatusFilters?: string[]
  } = (() => {
    const filters = Object.keys(realtimeStatusFilters?.RealtimeStatus?.values ?? {})
    return { realtimeStatusFilters: filters }
  })()

  const { data: realtimeDisplaysData, loading: realtimeDisplaysLoading } = useRealtimeDisplaysQuery({
    variables: { options: {
      orderBy: { [orderByTable?.field ?? 'name']: (orderByTable?.direction !== 'ascending' ? 'DESC' : 'ASC') },
      limit: page.size,
      offset: (page.number - 1) * page.size,
      nameLike: searchValue,
      ...useAppliedFilterOptions(DataTableInstance.Realtime),
      ...realtimeStatusCustomFilterOptions,
    } },
  })
  const realtimeDisplays = realtimeDisplaysData?.realtimeDisplays

  const [fetchCsvData] = useRealtimeDisplaysLazyQuery({
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Realtime),
        ...realtimeStatusCustomFilterOptions,
        nameLike: searchValue,
        offset: 0,
      },
    },
  })

  const { data: realtimeMapData, loading: realtimeMapLoading } = useRealtimeDisplaysQuery({
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Realtime),
        ...realtimeStatusCustomFilterOptions,
        nameLike: searchValue,
        offset: 0,
      },
    },
  })

  const { data: occupancyData, loading: occupancyDataLoading } = useRealtimeDisplaysQuery({
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Realtime),
        ...realtimeStatusCustomFilterOptions,
        nameLike: searchValue,
        offset: 0,
      },
    },
  })

  const loadingPage
      = realtimeDisplaysLoading
      || realtimeMapLoading
      || isFilteredDisplaysLoading
      || loadingTotalRooms
      || loadingRoomsInUse
      || loadingBookedRooms
      || loadingAdHocRooms
      || loadingUnknownRooms
      || occupancyDataLoading

  const loadingRooms = loadingTotalRooms
        || loadingRoomsInUse
        || loadingBookedRooms
        || loadingAdHocRooms
        || loadingUnknownRooms
        || false

  return (<RealtimePage
    aspectRatio={1}
    filteredItems={filteredDisplay}
    totalRooms={totalRooms || 0}
    roomsInUse={roomsInUse || 0}
    bookedRooms={bookedRooms || 0}
    adhocRooms={adhocRooms || 0}
    unknownRooms={unknownRooms || 0}
    loadingRooms={loadingRooms}
    occupancyData={occupancyData}
    occupancyDataLoading={occupancyDataLoading}
    realtimeMapData={realtimeMapData}
    realtimeTableData={realtimeDisplays}
    loadingPage={loadingPage}
    visiblePodsBox={visiblePodsBox}
    setVisiblePodsBox={setVisiblePodsBox}
    realtimeStatusFilters={realtimeStatusFilters}
    setCustomRealtimeStatusFilter={setCustomRealtimeStatusFilter}
    fetchCsvData={fetchCsvData}
  ></RealtimePage>)
}

export default RealtimeController
