import useDeepEffect from 'use-deep-compare-effect'
import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types'

export const useValidation = (
  name: string,
  value: string,
  rules: RegisterOptions,
) => {
  const {
    register,
    setValue,
    errors,
    unregister,
    clearErrors,
    trigger,
  } = useFormContext()

  const validate = () => trigger(name)

  /* Pick out the `validate()` function from the rules [see here](https://github.com/kentcdodds/use-deep-compare-effect/issues/47) */
  const { validate: _, ...rulesToWatch } = rules ?? {}

  useDeepEffect((): any => {
    register({ name, value }, rules)
    validate()
    return async () => {
      unregister(name)
      clearErrors(name)
    }
  }, [name, rulesToWatch, value])

  const error = !!errors[name]

  return { setValue, error }
}
