import { useRef } from 'react'
const ttl = 15000 // ms

/**
 * TODO: Get rid of this entire file once the Task Service has been created.
 * 
 * This entire file is to account for a race condition in the backend where Tasks do not appear in the database until several seconds after the frontend requests the task to be created.
 * This results in us not removing pods from the updateable pods list, and can allow for multiple update tasks for one pod.
 * 
 * Once we have created the Task service, we can most likely remove this functionality entirely. :)
 */

export const usePodsPendingTask = () => {
  const pendingTaskContainer = useRef<Array<string>>([])

  const addPodsPendingTask = (podIds: string[]) => {
    // Concat the 2 arrays into a larger array, then remove duplicate values
    pendingTaskContainer.current = [...pendingTaskContainer.current, ...podIds]
      .filter((value, index, self) => self.indexOf(value) === index)

    // remove these ids from the array after ttl
    setTimeout(() => removePodsPendingTasks(podIds), ttl)
  }

  const removePodsPendingTasks = (podIds: string[]) => {
    pendingTaskContainer.current = pendingTaskContainer.current.filter(value => !podIds.includes(value))
  }

  const getPodsPendingTasks = () => {
    return [...pendingTaskContainer.current]
  }

  return { addPodsPendingTask, removePodsPendingTasks, getPodsPendingTasks }
}
