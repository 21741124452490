import { LatLng, HeatLatLngTuple } from 'leaflet'
import { MarkerObject, LatLngOffsets } from '../MarkerObjectTypes'

const ONE_OVER_TWO_TO_THE_THIRTY_FIRST = 1 / (2 ** 31)

/**
 * Checks a given marker to see if there any other in the same list that are close enough to be considered the same spot.
 *
 * @function
 * @name isMarkerInSamePostion
 * @param {MarkerObject[]} markersList A list of details on the pod(s) at a given location
 * @param {MarkerObject} element A marker to check against the full list
 * @returns {boolean} Whether or not the marker is in the same spot as one or more others in the list of markers
 */
export function isMarkerInSamePostion(
  markersList: MarkerObject[],
  element: MarkerObject,
): boolean {
  return (
    element != null
    && (markersList || []).some(
      marker =>
        element.latlng.lat === marker?.latlng.lat && element.latlng.lng === marker?.latlng.lng
        && marker?.id !== element.id,
    )
  )
}

/**
 * Creates a map of lat/lng coordinate offsets for a list of markers so that none will visually overlap when drawn on a map together
 *
 * @function
 * @name getMarkerOffsets
 * @param {MarkerObject[]} markersList A list of details on the pod(s) at a given location
 * @returns {LatLngOffsets} A map of offsets to use for the lat and lng coordinates for each marker (in case of overlap)
 */
export function getMarkerOffsets(markersList: MarkerObject[]): LatLngOffsets {
  return markersList
    ?.filter(ele => isMarkerInSamePostion(markersList, ele))
    .reduce(
      (obj, { id }) => ({
        ...obj,
        [id]: [
          +`0.000${id
            .replace(/[a-z-]/g, '')
            .split('')
            .reverse()
            .join('')}`,
          +`0.000${id.replace(/[a-z-]/g, '')}`,
        ],
      }),
      {},
    )
}

/**
 * @function
 * @name hashCode
 * @param {string} str A string of any kind which will map deterministically to 
 * @returns {float} a pseudorandom float between -1 and 1
 */
function hashCode(str: string): number {
  let hash = 0, i, chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return hash * ONE_OVER_TWO_TO_THE_THIRTY_FIRST
};

/**
 * @function
 * @name offsetLatLng
 * @param {LatLng} coords A LatLng representing a global location
 * @param {string} seed A string associated with the location to seed the 
 * @param {number} variance 
 * @returns {LatLng} Another LatLng coordinate that has been offset between -1/2 and 1/2 variances in both cardinal directions
 */
export function offsetLatLng( coords: LatLng, seed: string = '', variance: number = 1.0 ): LatLng {
  coords.lat += ( hashCode(seed) * variance )
  coords.lng += ( hashCode(seed + 'a')  * variance )
  return coords
}

/**
 * Parses a list of (numeric) location markers from a list of overall usage/size/count/address details on each marker.
 * __Note__: when multiple markers are in the same position they are offset by 0.00002 lat/lng.
 *
 * @function
 * @name toLatLngAltitude
 * @param {MarkerObject[]} markersList A list of details on the pod(s) at a given location
 * @returns {HeatLatLngTuple[]} A list of location markers which are formatted as three numeric values representing the latitude, longitube and altitide (in that order)
 */
export function toLatLngAltitude(
  markersList: MarkerObject[],
): HeatLatLngTuple[] {
  return markersList?.map(({ latlng: { lat, lng }, usage }) => [lat, lng, usage ?? 0.1]) as HeatLatLngTuple[]
}

/**
 * Ensures a list of markers have lat/lng coordinates that do not overlap.
 * Optionally can duplicate markers when they have alerts associated with them.
 *
 * @function
 * @name spreadOverlappingMarkers
 * @param {MarkerObject[]} markersList A list of details on the pod(s) at a given location
 * @returns {MarkerObject[]} The same list of markers but with altered lat/lng coordinates (if necessary) to handle overlap
 */
export function spreadOverlappingMarkers(
  markersList: MarkerObject[],
  addMarkerForEveryAlert?: boolean,
): MarkerObject[] {
  const markers = markersList
  if (!addMarkerForEveryAlert) {
    return markers
  }

  const markersForEveryAlert: MarkerObject[] = []

  markers.forEach(marker => {
    if (marker.numberOfAlerts) {
      for (let i = 0; i < marker.numberOfAlerts; i++) {
        markersForEveryAlert.push(marker)
      }
    } else {
      markersForEveryAlert.push(marker)
    }
  })

  return markersForEveryAlert
}
