import React, { useState, useEffect } from 'react'
import { LatLng, divIcon } from 'leaflet'
import { Marker, useLeaflet, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { MostCollaborativeSpacesQuery, ViewMostCollaborativeSpacesResult } from 'graphql/__generated__/hasura-types'
import styles from './Map.module.scss'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import './MarkerCluster.css'
import { validateCoordinates } from 'shared/helpers/coordinates'

type Props = {
  data?: MostCollaborativeSpacesQuery
  zoomToBounds: boolean
  showCluster: boolean
  selectedLocation: ViewMostCollaborativeSpacesResult | null
  setSelectedLocation: Function
}

const getMarkerSize = (score: number) => {
  if (score < 10) {
    return 1
  } else if (score < 50) {
    return 2
  }
  return 3
  
}

const getIcon = (size?: number) => {
  const thirdCircle = size !== undefined && size >= 3 ? '<div />' : ''
  const secondCircle = size !== undefined && size >= 2 ? `<div>${thirdCircle}</div>` : ''
  return divIcon({
    html: `<div>${secondCircle}</div>`,
    className: `scatter-marker ${styles.podDot} scatter-marker-${size}`,
  })
}

type Spot = MarkerObject & { roomType: string }

export function PodsOnMap(props: Props) {
  const [orgPods, setOrgPods] = useState<Spot[]>([])
  const { data, selectedLocation, setSelectedLocation } = props

  useEffect(() => {
    if (data) {
      if (selectedLocation) {
        const location = {
          lat: selectedLocation.location_latitude,
          lng: selectedLocation.location_longitude,
        }
        leaflet.map?.setView(location, 5)
      } else {
        leaflet.map?.setZoom(1)
      }
      const locationData = selectedLocation ? [selectedLocation] : data?._search_most_collaborative_spaces

      const locations: Spot[] = locationData
        .filter(display => {
          const lat = display.location_latitude
          const long = display.location_longitude
          const validLocation = validateCoordinates(lat, long)
          if (selectedLocation && validLocation) {
            return selectedLocation.display_id === display.display_id
          }
          return validLocation
        })
        .map(
          display =>
            ({
              id: display.display_id,
              name: display.name ?? '',
              plotScore: display.plot_score,
              roomType: display.room_type,
              size: getMarkerSize(display.plot_score),
              latlng: new LatLng(display.location_latitude, display.location_longitude),
            } as Spot),
        )

      setOrgPods(locations)
    } else {
      setOrgPods([])
    }
  }, [data, selectedLocation])

  const [zoom, setZoom] = useState(1)
  const leaflet = useLeaflet()

  useEffect(() => {
    leaflet.map?.addEventListener('zoomend', () => {
      setZoom(leaflet.map?.getZoom() ?? zoom)
    })
  }, [])

  return (
    <div>
      {orgPods.map(pod => (
        <Marker
          key={`${pod.id}-${pod.roomType?.replace(/\s/g, '-')}`}
          icon={getIcon(pod.size)}
          position={new LatLng(pod.latlng.lat, pod.latlng.lng)}
          onmousedown={() => {
            setSelectedLocation(
              data?._search_most_collaborative_spaces?.find(location => location.display_id === pod.id),
            )
          }}
        >
          <Tooltip className={styles.mapTooltip} direction="bottom">
            {pod.name}
          </Tooltip>
        </Marker>
      ))}
    </div>
  )
}
export default PodsOnMap
