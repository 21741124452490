import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import qs from 'qs'
import styles from '../style.module.scss'
import { SolTabs, SolAlertMessage, SolRouteGuard } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { RoomTypeTab } from './RoomTypeTab'
import { AddPodsTab, spacePodsInvalid } from './AddPodsTab'
import { DesignLayoutTab } from './DesignLayoutTab'
import { RoutePresetsTab } from './RoutePresetsTab'
import { Icon, Menu } from 'semantic-ui-react'
import {
  CreateSolsticeRoutingSpaceInput,
  SolsticeRoutingSpace,
  useCreateSolsticeRoutingSpaceMutation,
  useUpdateSolsticeRoutingSpaceMutation,
  UpdateSolsticeRoutingSpaceInput,
  useSolsticeRoutingSpaceLazyQuery,
  SolsticeRoutingRoutePresetInput,
  SolsticeRoutingPodInput,
  SolsticeRoutingRoute,
} from 'graphql/__generated__/types'
import { omit, pick } from 'lodash'

/**
 * The Space Page holds the four tabs used to edit Spaces, as well as serving as a 
 * controller, querying the primary space object that will hold user's changes
 * until they Save/Cancel.
 */

const emptySpace = {
  'name': null,
  'space_type': 'SINGLE',
  'building_name': null,
  'room_number': null,
  'primary_pod': null,
  'pods': [],
  'route_presets': [
    {
      name: 'Preset #1',
      routes: [],
    },
  ],
  'show_routing_banner': false,
  'require_screen_key_verification': false,
  'require_password_on_exit': false,
  'moderator_sharing_mode': 'presentation',
} as SolsticeRoutingSpace

export type SpaceParams = { spaceId: string | undefined }

const SpacePage = ({ history, location }: RouteComponentProps) => {

  const [currentTab, setCurrentTab] = useState('type')
  const [toast, setToast] = useState<string | null>(null)
  const { spaceId } = useParams<SpaceParams>()
  const [lazySpaceQuery] = useSolsticeRoutingSpaceLazyQuery()
  const [space, setSpace] = useState<SolsticeRoutingSpace>(emptySpace)
  const [spaceIsDirty, setSpaceIsDirty] = useState(false)

  const [createSolsticeRoutingSpaceMutation] = useCreateSolsticeRoutingSpaceMutation()
  const createSpace = async (options: CreateSolsticeRoutingSpaceInput) => {
    const createdSpace = (await createSolsticeRoutingSpaceMutation(
      { variables: { options } },
    )).data!.createSolsticeRoutingSpace
    return createdSpace
  }

  const [updateSolsticeRoutingSpaceMutation] = useUpdateSolsticeRoutingSpaceMutation()
  const updateSpace = async (options: UpdateSolsticeRoutingSpaceInput) => {
    const updatedSpace = (await updateSolsticeRoutingSpaceMutation(
      { variables: { options } },
    )).data!.updateSolsticeRoutingSpace
    return updatedSpace
  }

  /**
   * Conditionally fetch the Space indicated by the param spaceId, if present
   */
  useEffect(() => {
    if (spaceId) {
      lazySpaceQuery({ variables: { spaceId }, fetchPolicy: 'no-cache' })
        .then(result => {
          setSpace(result?.data?.solsticeRoutingSpace!)
        })
    }
  }, [spaceId])

  useEffect(() => {
    if (location.search) {
      const tab = qs.parse(location.search.replace('?', '')).tab as string
      if (['type', 'pods', 'design', 'presets'].includes(tab)) {
        setCurrentTab(tab)
      }
    }
  }, [location.search])

  const tabs = () => {
    return [
      {
        displayName: 'Type of Space',
        name: 'type',
        disabled: false,
      },
      {
        displayName:
  <Menu.Item disabled={!space?.name} key="AddPodsMenuItem">
    Add & Configure
  </Menu.Item>,
        name: 'pods',
      },
      {
        displayName: <Menu.Item
          disabled={spacePodsInvalid(space)} key="DesignSpaceMenuitem">
          Design Space
        </Menu.Item>,
        name: 'design',
      },
      {
        displayName: <Menu.Item
          disabled={spacePodsInvalid(space)} key="RoutePresetsMenuItem">
          Custom Presets
        </Menu.Item>,
        name: 'presets',
      },
    ]
  }


  const saveSpace = async () => {
    const spaceToSave: CreateSolsticeRoutingSpaceInput | UpdateSolsticeRoutingSpaceInput = omit({
      name: space?.name,
      org: space?.org,
      partition: space?.partition,
      building_name: space?.building_name,
      moderator_sharing_mode: space?.moderator_sharing_mode,
      primary_pod: space?.primary_pod,
      require_password_on_exit: space?.require_password_on_exit,
      require_screen_key_verification: space?.require_screen_key_verification,
      room_number: space?.room_number,
      show_routing_banner: space?.show_routing_banner,
      space_type: space?.space_type,
      id: space?.id,
      route_presets: space?.route_presets ? space.route_presets!.map( rp =>
        pick({ ...rp, routes: rp?.routes?.map( r =>
          pick({
            ...r,
            sink_pod: pick(r?.sink_pod, ['id']),
            source_pod: pick(r?.source_pod, ['id']),
          }, 'id', 'sink_pod', 'source_pod') as SolsticeRoutingRoute,
        ) }, 'id', 'name', 'routes')) as SolsticeRoutingRoutePresetInput[] : [],
      pods: space?.pods ? space?.pods!.map(p => pick(p, [
        'audio_routing_enabled', 'color', 'column', 'id', 'row',
      ])) as SolsticeRoutingPodInput[] : [],
    }, ['__typename'])

    if (space && spaceToSave ) {
      if (!space?.id) {
        const createdSpace = await createSpace(spaceToSave)
        if (createdSpace) {
          setSpace(createdSpace)
          setToast('Space Created')
        }
      } else {
        const updatedSpace = await updateSpace({ ...spaceToSave })
        if (updatedSpace) {
          setSpace(updatedSpace)
          setToast('Space Saved')
        }
      }
    }
  }

  const setAndDirtySpace = (sp: SolsticeRoutingSpace) => {
    setSpace(sp)
    setSpaceIsDirty(true)
  }

  const switchTabs = (tab: String) => {
    switch (tab) {
      case 'type':
        return (<RoomTypeTab
          setCurrentTab={setCurrentTab}
          space={space}
          setSpace={setAndDirtySpace}
          saveSpace={saveSpace}
        />)
      case 'pods':
        return (<AddPodsTab
          setCurrentTab={setCurrentTab}
          space={space}
          setSpace={setAndDirtySpace}
          saveSpace={saveSpace}
        />)
      case 'design':
        return (<DesignLayoutTab
          setCurrentTab={setCurrentTab}
          space={space}
          setSpace={setAndDirtySpace}
          saveSpace={saveSpace}
        />)
      case 'presets':
        return (<RoutePresetsTab
          setCurrentTab={setCurrentTab}
          space={space}
          setSpace={setAndDirtySpace}
          saveSpace={saveSpace}
        />)
      default:
        return
    }
  }

  return (
    <div className={styles.wrapper}>
      <SolAlertMessage message={toast ?? ''} show={!!toast} type="success" onDismiss={() => setToast(null)} />
      <ContentWrapper>

        <div className={styles.header}>
          <span
            className={styles.grey}
            onClick={() => {history.replace('/solstice-routing/')}}
          >Solstice Routing</span>
          <Icon name="angle right" color="grey" />
          <span>Spaces</span>
        </div>

        <SolTabs
          alignment="center"
          menuItems={tabs()}
          currentTab={currentTab}
          onTabChange={tab => {
            setCurrentTab(tab)
            history.replace(`?tab=${tab}`)
          }}
        />

        <div className={styles.tab}>
          {switchTabs(currentTab)}
        </div>

        <SolRouteGuard
          modalProps={{
            modalText: 'Are you sure you want to leave?',
            cancelText: 'Cancel',
          }}
          when={spaceIsDirty}
        />
      </ContentWrapper>
    </div>
  )
}

export default SpacePage
