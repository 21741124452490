import React from 'react'
import { Header } from 'semantic-ui-react'
import { Bar, XAxisProps, YAxisProps } from 'recharts'
import { SolBarChart, SolCard } from 'SolComponents'
import styles from './index.module.scss'
import { ViewContentSharingTypes } from 'graphql/__generated__/hasura-types'
import { pluralize } from 'shared/core/utils'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'

interface Props {
  data: ViewContentSharingTypes[]
  testId: string
  isLoading: boolean
}

const xAxisProps: XAxisProps = { type: 'number' }
const yAxisProps: YAxisProps = {
  type: 'category',
  dataKey: 'shareType',
  width: 100,
}
const chartConfig = {
  margin: { right: 24 },
  responsiveHeight: 260,
  width: 600,
  tooltip: {
    formatter: (value: number) => [value, `${pluralize(value, 'Post', false)}`],
  },
}

const HowCard = ({ data, testId, isLoading }: Props) => {
  return (
    <div data-testid={testId} className={styles.howCard}>
      <SolCard className={styles.howCard} fluid>
        <Header as="h3" textAlign="left" content="How Users Are Sharing" />
        <LoadingWrapper isEmpty={!data || data.length === 0} isLoading={isLoading}>
          <SolBarChart
            data={data}
            width={chartConfig.width}
            responsiveHeight={chartConfig.responsiveHeight}
            layout="vertical"
            xAxis={xAxisProps}
            yAxis={yAxisProps}
            margin={chartConfig.margin}
            tooltip={chartConfig.tooltip}
          >
            <Bar dataKey="numPosts" fill="#E9668C" />
          </SolBarChart>
        </LoadingWrapper>
      </SolCard>
    </div>
  )
}

HowCard.defaultProps = {
  isLoading: false,
}

export default HowCard
