import React from 'react'
import { Grid, Header, Image } from 'semantic-ui-react'

import { CreateMxRenewLeadInterface } from 'shared/services/mxRenew'

import { renewFlow as palette } from 'assets/palettes'

import { FlowButtons } from 'components/Maintenance/Renewal/Partials'

const solsticeCloudIcon = require('assets/solstice_sub_small.svg')

export interface Props {
  cancelFlow: () => void
  createLead: (lead: CreateMxRenewLeadInterface, nextStep: number) => void
  nextStep: number
}

export const style = {
  page: {
    margin: '10px',
  } as React.CSSProperties,
  title: {
    fontWeight: 1000,
  } as React.CSSProperties,
  numbers: {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: palette.blue,
    lineHeight: '2',
    color: 'white',
    marginLeft: '30px',
    marginRight: '30px',
  } as React.CSSProperties,
  stepText: {
    lineHeight: '2',
    fontSize: '16px',
    marginLeft: '40px',
  } as React.CSSProperties,
  lastStep: {
    height: '30px',
    lineHeight: '30px',
  } as React.CSSProperties,
  lastStepText: {
    paddingTop: '3px',
    fontSize: '16px',
    marginLeft: '40px',
    paddingLeft: '20px',
    borderLeft: `4px solid ${palette.blue}`,
  } as React.CSSProperties,
  process: {
    fontSize: '16px',
  } as React.CSSProperties,
  image: {
    margin: 'auto',
  } as React.CSSProperties,
  shift: {
    paddingTop: '2.5%',
    paddingBottom: '2.5%',
  } as React.CSSProperties,
}

class GettingStarted extends React.Component<Props> {
  handleNext = () => {
    const { createLead, nextStep } = this.props
    createLead(
      {
        step: 1,
      },
      nextStep,
    )
  }

  handleCancel = () => {
    const { cancelFlow } = this.props
    cancelFlow()
  }

  render() {
    return (
      <Grid columns={2} style={style.page} divided>
        <Grid.Column style={style.shift} width={5}>
          <Grid>
            <Grid.Column width={14}>
              <Image style={style.image} src={solsticeCloudIcon} />
              <Header as="h2" style={style.title}>
                Let's get started.
              </Header>
              <p style={style.process}>
                This process will get you a quote for renewing Solstice Subscriptions for your Pods.
              </p>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column style={style.shift} width={11}>
          <Grid columns={2}>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>
                <div style={style.numbers}>1</div>
              </Grid.Column>
              <Grid.Column width={15}>
                <p style={style.stepText}>Select Pods you'd like to renew.</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>
                <div style={style.numbers}>2</div>
              </Grid.Column>
              <Grid.Column width={15}>
                <p style={style.stepText}>Choose a co-term date.</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>
                <div style={style.numbers}>3</div>
              </Grid.Column>
              <Grid.Column width={15}>
                <p style={style.stepText}>Submit the request, we'll send you a quote.</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>&nbsp;</Grid.Column>
              <Grid.Column width={12} style={style.lastStep}>
                <p style={style.lastStepText}>
                  Once a purchase order is fulfilled for the quote, we'll automatically update the Solstice
                  Subscriptions for your Pods.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <FlowButtons next={this.handleNext} cancel={this.handleCancel} nextText="Get Started" />
      </Grid>
    )
  }
}

export default GettingStarted
