import React, { useState } from 'react'
import { TaskType, Template, useGetConfigDiffQuery } from 'graphql/__generated__/types'
import { Icon, Dimmer, Loader } from 'semantic-ui-react'
import styles from './PodConfig.module.scss'
import LoadingBar from 'components/Loaders/LoadingBar'
import { withRouter, RouteComponentProps } from 'react-router'
import { useDisplay, updateTemplateListQuery } from 'shared/hooks/management/useDisplay'
import { useCurrentPodLocation } from 'shared/hooks/maps/useLocation'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'
import { NetworkStatus } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useGetAllTemplates } from 'shared/hooks/management/useTemplates'
import { TemplateCards, CommonProps } from './TemplateCards/TemplateCards'
import { PreviousNextPodButtons } from './PreviousNextPodButtons'
import { SettingsCards } from './SettingsCards/SettingsCards'
import { SolNotificationCard } from 'SolComponents'
import RefreshIcon from 'mdi-react/RefreshIcon'
import { SolPodActions } from 'SolComponents/SolDropdown/SolPodActions/SolPodActions'
import UniqueSettings, { Props as UniqueSettingsProps } from 'pages/Templates/UniqueSettings/UniqueSettings'
import { FormValidation } from 'shared/context/FormValidation'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import InfoModal from 'components/OnboardFailureInfo/InfoModal'

type UniqueSettingsWorkflow = {
  drawerIsOpen: boolean
} & Omit<UniqueSettingsProps, 'onClose' | 'assignTemplates'>

export interface Params {
  podId: string
}

const Breadcrumbs = (props: { podName: string }) => (
  <div className={styles.header}>
    <Link to="/manage/pods" className={styles.grey}>
      Pods
    </Link>
    <Icon name="angle right" color="grey" size="small" />
    <span>{props.podName} Configuration Settings</span>
  </div>
)

function PodConfig({ match: { params } }: RouteComponentProps) {
  const podId = (params as Params).podId
  const {
    status,
    podName,
    updateDisplay,
    updatingDisplay: saving,
    templates,
    networkStatus,
    cancelPending,
    refetchDisplay,
    templateCompatibility,
    categories,
    appliedCategories,
    applyCategory,
    versions,
    catchingUp,
    macAddresses,
    isOnline,
    displayUuid,
    syncState,
    serialId,
  } = useDisplay(podId)
  const [uniqueSettingsWorkflow, setUniqueSettingsWorkflow] = useState<UniqueSettingsWorkflow>({
    drawerIsOpen: true,
  })

  const [modalOpen, setModalOpen] = useState(false), FailedConnectionInfo = (
    <InfoModal isOpen={modalOpen} onClose={() => {setModalOpen(false)}}/>
  )

  const UnmanageableWarning = () => (
    <div className={styles.unmanageableWarning} data-testid="unmanageable-warning">
      <SolAlertIcon className={styles.exclamationTriangle} />
      Template configuration unavailable. Wait for Cloud connection or delete pod and retry.
      <Link to={{}} onClick={() => {setModalOpen(true)}}> LEARN MORE</Link>
      <InfoModal isOpen={modalOpen} onClose={() => {setModalOpen(false)}}/>
      {FailedConnectionInfo}
    </div>
  )

  const mismatched = syncState === 'mismatched' || syncState === 'rejected'
  const { data: confDiff } = useGetConfigDiffQuery({
    variables: {
      id: podId,
    },
    skip: !mismatched,
  })

  const { data: templateOptions, loading: loadingTemplateOptions } = useGetAllTemplates()

  const { currentPodLocation, address } = useCurrentPodLocation(podId)

  const [showUpdateNotification, setShowUpdateNotification] = useState(false)

  const handleSave = async (template: Template, saveAsNewTemplate: boolean) => {
    const response = await updateDisplay(
      {
        newTemplateId: template.id,
        displayId: podId,
        settingsGroupType: template.settingsGroup.type,
        configuration: template.revision.configuration,
        saveAsNewTemplate,
        newTemplateName: template.name,
      },
      saveAsNewTemplate ? updateTemplateListQuery(template.settingsGroup.type) : undefined,
    )

    setUniqueSettingsWorkflow({
      drawerIsOpen: true,
      template,
      validation: response.data?.updateDisplay?.validation,
    } as UniqueSettingsWorkflow)

    if ((response.data?.updateDisplay?.validation?.incompatibleDisplays?.length || 0) > 0) {
      return null
    }

    return template as Template
  }

  const isManageable = status !== 'Unsupported'

  const commonProps: CommonProps = {
    displayName: podName,
    view: 'Pod',
    onDuplicateTemplate: () => null,
    onSave: handleSave,
    podId,
    isOnline,
    cancelPending,
    refetchDisplay,
    notifyUpdate: () => setShowUpdateNotification(true),
    templateCompatibility,
    isManageable,
    uniqueSettingsTemplate: templates.uniqueSettings?.template,
    loadingTemplateOptions,
  }

  const truncatedName = podName && podName.length > 24
    ? `${podName.slice(0, 24)}...`
    : podName

  const reload = () => window.location.reload()
  return (
    <ContentWrapper>
      <LoadingBar visible={saving} header />
      {networkStatus === NetworkStatus.loading ? (
        <Dimmer active>
          <Loader size="huge">Loading...</Loader>
        </Dimmer>
      ) : (
        <>
          {showUpdateNotification && (
            <SolNotificationCard onClose={() => setShowUpdateNotification(false)} className={styles.updateNotification}>
              <div className={styles.cardContent}>
                <RefreshIcon className={styles.refreshIcon} onClick={reload} />
                <p>
                  This page has been updated.&nbsp;&nbsp;
                  <a onClick={reload}>Refresh</a>
                </p>
              </div>
            </SolNotificationCard>
          )}
          <Breadcrumbs podName={truncatedName} />
          <PreviousNextPodButtons podId={podId} />
          <SettingsCards
            podId={podId}
            status={status}
            podName={truncatedName}
            podUuid={displayUuid}
            isManageable={isManageable}
            handleSave={template => handleSave(template, false)}
            uniqueSettingsTemplate={templates.uniqueSettings?.template}
            versions={versions}
            catchingUp={catchingUp}
            categories={categories}
            appliedCategories={appliedCategories}
            macAddresses={macAddresses}
            applyCategory={applyCategory}
            currentPodLocation={currentPodLocation}
            currentAddress={address}
            serialId={serialId}
          />
          <div className={styles.templates}>
            <div className={styles.headerRow}>
              <h2 className={styles.templatesHeader}>Template Configuration for {truncatedName}</h2>
              <SolPodActions
                podId={podId}
                podName={truncatedName}
                versions={versions}
                serialId={serialId ?? undefined}
                taskTypes={isOnline ? [
                  TaskType.Reboot,
                  TaskType.BootAllUsers,
                  TaskType.ClearScreen,
                  TaskType.Wake,
                  TaskType.Suspend,
                  TaskType.ForgetWifiNetworks,
                  TaskType.DisplayUpdate,
                  TaskType.Delete,
                ] : [
                  TaskType.Delete,
                ]}/>
            </div>
            {!isManageable && <UnmanageableWarning />}
            {networkStatus !== NetworkStatus.setVariables && (
              <TemplateCards
                templates={templates}
                mismatches={confDiff?.configDiff?.diffObject}
                templateOptions={templateOptions}
                commonProps={commonProps}
              />
            )}
          </div>
        </>
      )}
      <FormValidation>
        <UniqueSettings
          onClose={() => setUniqueSettingsWorkflow({ drawerIsOpen: false })}
          {...uniqueSettingsWorkflow}
        />
      </FormValidation>
    </ContentWrapper>
  )
}

export default withRouter(PodConfig)
