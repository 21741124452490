import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { usePodsPendingTask } from './usePodsPendingTask'

export const useUpdateablePodsOptions = () => {
  const { page, orderBy, searchValue } = useDataTable<any>(DataTableInstance.Updates)
  const { getPodsPendingTasks } = usePodsPendingTask()
  const podsPendingTasks = getPodsPendingTasks()
  return {
    ...useAppliedFilterOptions(DataTableInstance.Updates),
    ...(podsPendingTasks.length > 0 ? { idNotIn: podsPendingTasks } : {}),
    ...(searchValue ? { nameLike: searchValue } : {}),
    orderBy: { [orderBy?.field ?? 'name']: (orderBy?.direction !== 'ascending' ? 'DESC' : 'ASC') },
    offset: (page.number - 1) * page.size,
    limit: page.size,
  }
}
