import { Template } from 'graphql/__generated__/types'

export type PodSettingsGroup = {
  pending: boolean
  syncState?: string
  template: Template
}

export type PodTemplates = {
  advanced?: PodSettingsGroup
  calendar?: PodSettingsGroup
  digitalSignage?: PodSettingsGroup
  discovery?: PodSettingsGroup
  ethernet?: PodSettingsGroup
  features?: PodSettingsGroup
  messageCenter?: PodSettingsGroup
  powerManagement?: PodSettingsGroup
  proxy?: PodSettingsGroup
  security?: PodSettingsGroup
  timeLocale?: PodSettingsGroup
  uniqueSettings?: PodSettingsGroup
  welcomeScreen?: PodSettingsGroup
  wifi?: PodSettingsGroup
  roomIntelligence?: PodSettingsGroup
}

export const DefaultTemplate = {
  id: '',
  org: '',
  name: '',
  isCustom: false,
  isDefault: false,
}
