import React, { useState } from 'react'
import styles from './SolStatus.module.scss'
import { Icon } from 'semantic-ui-react'
import { SolTooltip } from 'SolComponents'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { SolLoaderIcon } from 'SolComponents/Icons'
import InfoModal from 'components/OnboardFailureInfo/InfoModal'

export type StatusOptions =
  'OfflinePending' |
  'TemplateSyncError' |
  'NotYetDeployed' |
  'Unknown' |
  'OnlinePending' |
  'Online' |
  'Offline' |
  'NeverConnected' |
  'Unsupported'

export const OutOfSubscriptionStatus = 'RENEW'
export const SolsticeElement = 'ELEMENT'

type StatusDescriptions = { [status in StatusOptions]: string | JSX.Element }

export interface Props {
  status: StatusOptions
  iconSize?: 'big' | 'small' | 'tiny' | 'mini' | 'large' | 'huge' | 'massive' | undefined
  isInline?: boolean
  className?: string
  containerClassName?: string
  tooltipText?: string
  statusDescriptions?: Partial<StatusDescriptions>
}

export const SolStatus = (props: Props) => {
  const determineColor = (status: StatusOptions) => {
    if (status === 'Online') {
      return 'green'
    }
    if (status === 'Offline') {
      return 'red'
    }
    if (status === 'OfflinePending') {
      return 'yellow'
    }
    if (status === 'Unknown') {
      return 'grey'
    }
    if (status === 'NeverConnected') {
      return 'grey'
    }

    return
  }

  const [modalOpen, setModalOpen] = useState(false), FailedConnectionInfo = (
    <InfoModal isOpen={modalOpen} onClose={() => {setModalOpen(false)}}/>
  )

  const statusIcon = (
    <span className={classNames(styles.icon, props.className)}>
      {!['OnlinePending', 'NotYetDeployed', 'Unsupported', 'TemplateSyncError'].includes(props.status) && (
        <Icon name="circle" color={determineColor(props.status)} size={props.iconSize} />
      )}
      {props.status === 'OnlinePending' && (
        <SolLoaderIcon size="tiny" className={styles.loading} />
      )}
      {props.status === 'TemplateSyncError' && (
        <Icon name="exclamation circle" color="green"  />
      )}
      {props.status === 'Unsupported'
        && <Icon name="exclamation circle" color="red" />
      }
      {props.status === 'NotYetDeployed' && (
        <Icon className={styles.notYetDeployed} name="circle" size={props.iconSize} />
      )}
      {FailedConnectionInfo}
    </span>
  )

  const defaultStatusDescriptions: StatusDescriptions = {
    Online: 'Online',
    Unsupported: (
      <div className={styles.minVersionText}>
        Pod has not yet connected to the Cloud.
        <div className={styles.updateNow}>
          <Link to={{}} onClick={() => {setModalOpen(true)}}>LEARN MORE</Link>
        </div>
      </div>
    ),
    TemplateSyncError: 'Unable to sync all templates',
    Offline: 'Offline',
    OnlinePending: 'Online (Pending)',
    NotYetDeployed: 'Not Yet Deployed',
    OfflinePending:
      'This Pod is currently offline, and has pending template changes that will take place after it is back online.',
    Unknown: 'Unknown Status',
    NeverConnected: 'Pod has not yet connected to cloud.',
  }

  const statusDescription = {
    ...defaultStatusDescriptions,
    ...props.statusDescriptions,
  }[props.status || 'Unknown Status']

  return (
    <SolTooltip
      hoverable
      position="bottom center"
      trigger={statusIcon}
      text={props.tooltipText ?? statusDescription ?? ''}
      isInline={props.isInline !== false}
      className={classNames(styles.tooltip, props.containerClassName)}
    />
  )
}

export default SolStatus
