import React from 'react'
import { SolCard } from 'SolComponents'
import classNames from 'classnames'
import styles from './SolNotificationCard.module.scss'
import SolCloseIcon from 'SolComponents/Icons/SolCloseIcon/SolCloseIcon'

interface Props {
  onClose: () => void
  className: string
  children: React.ReactNode
}

const SolNotificationCard = ({ onClose, className, children }: Props) => (
  <SolCard className={classNames(styles.card, className)}>
    {children}
    <SolCloseIcon onClick={onClose} className={styles.closeIcon} />
  </SolCard>
)

export default SolNotificationCard
