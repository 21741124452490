import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Template, FeaturesConfiguration } from 'graphql/__generated__/types'
import styles from './Features.module.scss'
import AlignmentOptions from './FeatureOptions/AlignmentOptions'
import BrowserLookInOptions from './FeatureOptions/BrowserLookIn'
import BasicOptions from './FeatureOptions/BasicOptions'
import HDMIInput from './FeatureOptions/HDMIInput'
import { SolNotification } from 'SolComponents'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: FeaturesConfiguration) => void
}


function Features({ updateConfiguration, template }: Props) {
  return (
    <div>
      <Grid divided="vertically" className={styles.columnWrapper}>
        <Grid.Column mobile={8} className={styles.columnOne}>
          <BasicOptions
            template={template}
            updateConfiguration={updateConfiguration}
          />
        </Grid.Column>
        <Grid.Column mobile={8} className={styles.secondColumn}>
          <h4 className={styles.header2}>Content Alignment Default</h4>
          <AlignmentOptions
            template={template}
            updateConfiguration={updateConfiguration}
          />
          <h4 className={styles.header2}>Browser Look-In</h4>
          <BrowserLookInOptions
            template={template}
            updateConfiguration={updateConfiguration}
          />
          <h4 className={styles.header2}>
            HDMI Input Behavior <SolNotification type="menu" notifications="Gen3+"/>
          </h4>
          <HDMIInput
            template={template}
            updateConfiguration={updateConfiguration}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Features
