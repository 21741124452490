import { useQuery } from '@apollo/client'
import {
  ActiveLearningDisplayMetricsQuery,
  ActiveLearningDisplayMetricsQueryVariables,
  ActiveLearningDisplayMetricsDocument,
  ActiveLearningRoomsQuery,
  ActiveLearningRoomsDocument,
  DisplaySearchOptions,
} from 'graphql/__generated__/types'
import { useActiveLearningUserCountsQuery } from 'graphql/__generated__/hasura-types'
import { Role } from 'shared/types/Role'

export const useDisplayCount = (
  displaySearchOptions: Pick<DisplaySearchOptions, 'searchTerm' | 'categories' | 'locationFilters'>,
) => {
  const { data } = useQuery<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>(
    ActiveLearningDisplayMetricsDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: displaySearchOptions,
    },
  )
  return {
    currentSubscription: data?.currentSubscriptionDisplays?.totalRecords ?? 0,
    trial: data?.trialDisplays?.totalRecords ?? 0,
    expiringSoon: data?.expiringSoonDisplays?.totalRecords ?? 0,
    readyToEnable: data?.readyToEnableDisplays?.totalRecords ?? 0,
  }
}

export const useRoomCount = () => {
  const { data } = useQuery<ActiveLearningRoomsQuery>(ActiveLearningRoomsDocument, {
    fetchPolicy: 'cache-and-network',
  })
  return data?.activeLearningRooms?.length ?? 0
}

export const useUserRoleCount = () => {
  const { data } = useActiveLearningUserCountsQuery({
    variables: {
      designerRole: Role['Active Learning Designer'],
      facilitatorRole: Role['Active Learning Facilitator'],
    },
  })

  return {
    [Role['Active Learning Designer']]: data?.designers.aggregate?.count,
    [Role['Active Learning Facilitator']]: data?.facilitators.aggregate?.count,
  }
}
