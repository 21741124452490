import React, { useState } from 'react'
import { useCreateUpdateSsoMutation, useSsoInfoQuery } from 'graphql/__generated__/types'
import SSO from './SSO'
import { DropdownItemProps } from 'semantic-ui-react'
import authService from 'shared/services/auth'

const SSOController = () => {

  const [dropDown, setDropdown] = useState<DropdownItemProps[]>([])
  const [showError, setShowError] = useState<boolean>(false)
  const [showUpdateNotification, setShowUpdateNotification] = useState(false)

  const baseUrl = authService.ssoUrl

  // These are from idp table sso-auth-svc datebase. They could likely be used instead of the db call.
  // const idps: DropdownItemProps[] = [{
  //   value: 1,
  //   text: 'Okta',
  //   url: `${baseUrl}/saml/okta`,
  //   placeholder: 'https://{domain}.okta.com/{app-info}/sso/saml',
  // }, {
  //   value: 2,
  //   text: 'Azure',
  //   route: `${baseUrl}/saml/azure`,
  //   placeholder: 'https://login.microsoftonline.com/{uuid}/saml2',
  // }]

  const { data: ssoData, loading: ssoLoading } = useSsoInfoQuery({
    onCompleted: ({ idps = [] }) => {
      if (idps) {
        const dropdownOptions: DropdownItemProps[] = idps.map((el = {}) => ({
          value: el?.id,
          text: el?.name,
          url: `${baseUrl}/saml/${el?.route}`,
          placeholder: el?.example_idp_url,
        } as DropdownItemProps))
        setDropdown(dropdownOptions)
      }
    },
    onError: () => {
      setShowError(true)
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const [
    createUpdateSsoMutation,
    { data: upsertData, loading: upsertLoading },
  ] = useCreateUpdateSsoMutation({
    onCompleted: () => {
      setShowUpdateNotification(true)
    },
    onError: () => {
      setShowError(true)
    },
  })

  return (<SSO
    orgData={upsertData?.createSSOOrg || ssoData?.ssoOrg}
    setShowError={setShowError}
    showError={showError}
    idps={dropDown}
    ssoLoading={ssoLoading || upsertLoading}
    updateOrg={createUpdateSsoMutation}
    showUpdateNotification={showUpdateNotification}
    setShowUpdateNotification={setShowUpdateNotification}
  />)
}

export default SSOController
