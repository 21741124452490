import { MetricType } from 'graphql/__generated__/types'
import { ComputeMetrics } from 'shared/domain/metric'
import { DateRange } from 'shared/core/utils'

export enum Period {
  '48HRS' = 2,
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
}

export enum Bucket {
  HOURS = (1 / 24),
  DAYS = 1,
  WEEKS = 7,
  MONTHS = 30,
}

export const toBucket = (s: string) => {
  switch (s) {
    case 'HOUR': return Bucket.HOURS
    case 'DAY': return Bucket.DAYS
    case 'WEEK': return Bucket.WEEKS
    default: return Bucket.MONTHS
  }
}

export type ChartType = 'home' | 'usage'

export interface TimePeriod {
  start: number
  end: number
  period: Period
  range: DateRange
}

export type PrimaryAxis = MetricType | ComputeMetrics | 'millisInUse'

export type SecondaryAxis = PrimaryAxis | 'none'

export type AxisOptions = PrimaryAxis | SecondaryAxis

export interface Option<T = any> {
  key: number | string
  text: string
  value: T
}

export interface PrimaryOption extends Option {
  value: PrimaryAxis
}

export interface SecondaryOption extends Option {
  value: SecondaryAxis
}

export type ChartNames = 'usage' | 'home'
