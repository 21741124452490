import React, { useState } from 'react'
import { Cell, PieChart, Pie, Legend, Tooltip } from 'recharts'
import { PieData } from 'components/Charts/types'

export interface Props {
  data: PieData[]
  clickable?: boolean
  onClick?: () => void
}

export const CategoryChart = ({ onClick, data, clickable = false }: Props) => {
  const [hovering, setHovering] = useState(false)

  const style = {
    heading: {
      textAlign: 'center',
    } as React.CSSProperties,
    menu: {},
    renameInput: {},
    pointer: {
      cursor: clickable ? 'pointer' : 'default',
      backgroundColor: hovering ? '#f8f8f8' : '',
      borderRadius: '5px',
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    } as React.CSSProperties,
  }

  return (
    <div className="category-chart-parent">
      <div className="category-chart-content" style={style.horizontal}>
        <PieChart
          width={220}
          height={220}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={onClick}
        >
          <Pie
            dataKey="value"
            data={data}
            cx={110}
            cy={110}
            outerRadius={90}
            startAngle={90}
            endAngle={450}
            style={style.pointer}
          >
            {data.map((_entry, index) => (
              <Cell
                key={index}
                fill={data[index % data.length].color}
                stroke={data[index % data.length].color}
              />
            ))}
          </Pie>
          <Tooltip separator=": "/>
          <Legend
            verticalAlign="middle"
            layout="vertical"
            align="right"
            content={<React.Fragment />}
          />
        </PieChart>
      </div>
    </div>
  )
}
