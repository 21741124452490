import { MetricType } from 'graphql/__generated__/types'

export enum ComputeMetrics {
  Hour = 'HOURS_IN_USE'
}

export const metricLabels = {
  [MetricType.Users]: 'Total Users',
  [MetricType.Meetings]: 'Meetings',
  [MetricType.Posts]: 'Content Items',
  [MetricType.MinutesInUse]: 'Minutes In Use',
  [ComputeMetrics.Hour]: 'Hours In Use',
  [MetricType.AverageMeetingDuration]: 'Avg Meeting Duration',
  [MetricType.ContentItemsPerMeeting]: 'Content Per Meeting',
}
