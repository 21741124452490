import React, { useState, useEffect } from 'react'
import CompareChart from './Chart/CompareChart'
import MetricTable from './MetricTable'
import { ComparePageRide } from './ComparePageRide'
import { useQuery } from '@apollo/client'
import {
  CategoryMetricsByDateDocument,
  CategoryMetricsByDateQuery,
  CategoryMetricsByDateQueryVariables,
  TimeInterval,
  MetricType,
  DisplaysQuery,
  DisplaysQueryVariables,
  DisplaysDocument,
  Display,
  TotalDisplaysDocument,
  TotalDisplaysQuery,
  CategoryMetricsByDate,
  OrderByDisplay,
  Direction,
  DisplayOrderableField, PlatformConfigQuery, PlatformConfigDocument,
} from 'graphql/__generated__/types'
import { format, setDate, getDate } from 'date-fns'
import { NetworkStatus } from '@apollo/client'

import { ChartTimePeriod } from 'SolComponents/SolChart/types'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { orderByTransform } from 'shared/domain/pods'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { getPreviousDate } from 'SolComponents/SolChart/chartUtils'
import { ComputeMetrics } from 'shared/domain/metric'
import { AxisOptions } from '../UsageHistory/types'
import { useCategoriesQuery } from 'graphql/__generated__/hasura-types'
import { Category } from 'shared/types/categories'
import { OptionDate } from 'shared/types/OptionDate'
import { SolFilterHeaderCard } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

const standarizeChartData = (data: CategoryMetricsByDate[] = [], optionSelected: AxisOptions) => {
  if (optionSelected === ComputeMetrics.Hour) {
    return data.map(res => {
      const updateMetrics = (res.options || []).map(met => {
        return {
          ...met,
          total: (met?.total || 0) / 60,
        }
      })
      return { ...res, options: updateMetrics }
    })
  }
  return data
}

export default React.memo(function ComparePage() {
  const [metric, setMetric] = useState<MetricType>(MetricType.Meetings)
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(TimeInterval.Day)
  const [timePeriod, setTimePeriod] = useState<ChartTimePeriod>(ChartTimePeriod.MONTH)

  const { data: categories } = useCategoriesQuery({
    fetchPolicy: 'cache-and-network',
  })

  const [category, setCategory] = useState('')

  const { searchValue: searchTerm, page, orderBy } = useDataTable<Display>(DataTableInstance.CategoryCompare)

  let defaultOrder: OrderByDisplay = {
    direction: Direction.Asc,
    field: DisplayOrderableField.Name,
  }

  if (orderBy && orderBy.field) {
    defaultOrder = orderByTransform(orderBy)
  }

  const [orderByParam, setOrderByParam] = useState<OrderByDisplay>(defaultOrder)

  const [tableData, setTableData] = useState<Display[]>([])

  const [optionDate, setOptionDate] = React.useState({
    fromDate: format(getPreviousDate(timePeriod, new Date()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
  })

  const filterAxisOption = (axisSelected: MetricType | ComputeMetrics) =>
    (axisSelected === ComputeMetrics.Hour ? MetricType.MinutesInUse : axisSelected)

  const { data: totalDisplaysResult, networkStatus: totalDisplayStatus } = useQuery<TotalDisplaysQuery>(
    TotalDisplaysDocument,
    {
      variables: { options: {} },
    },
  )

  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.CategoryCompare)

  const { data: chartData, networkStatus: graphStatus } = useQuery<
    CategoryMetricsByDateQuery,
    CategoryMetricsByDateQueryVariables
  >(CategoryMetricsByDateDocument, {
    variables: {
      options: {
        timeInterval,
        metricType: filterAxisOption(metric),
        fromDate: optionDate.fromDate,
        toDate:
        timeInterval === TimeInterval.Month ? format(
          setDate(new Date(optionDate.toDate), getDate(new Date(optionDate.fromDate))), 'yyyy-MM-dd\'T\'HH:mm:ssXXX',
        ) : format(new Date(optionDate.toDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
        searchTerm,
        primaryCategoryId: category,
        categories: categoryFilters,
        locations: locationFilters,
      },
    },
    skip: !!!category,
    fetchPolicy: 'cache-and-network',
  })

  const displaySearchOptions = {
    records: page.size,
    searchTerm,
    page: page.number,
    orderBy: orderByParam,
    metrics: {
      fromDate: optionDate.fromDate,
      toDate: optionDate.toDate,
    },
    categories: categoryFilters,
    locationFilters: locationFilters,
  }

  const { data: tableQueryResponse, networkStatus: tableStatus } = useQuery<DisplaysQuery, DisplaysQueryVariables>(
    DisplaysDocument,
    {
      variables: {
        options: displaySearchOptions,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const totalFilteredItems = tableQueryResponse?.displays?.totalRecords ?? 0
  const totalDisplays = totalDisplaysResult?.displays?.totalRecords ?? 0
  const response = tableQueryResponse?.displays?.items as Display[]

  useEffect(() => {
    if (response) {
      setTableData(response)
    }
  }, [response])

  useEffect(() => {
    const catRoom = categories?._categories.find(cat => {
      return cat.id === 'keplerCategoryRoomType'
    })
    const catSelected = catRoom || categories?._categories[0]
    setCategory(catSelected?.id ?? '')
  }, [categories])

  const metricsByDate = (chartData?.categoryMetricsByDate || []) as CategoryMetricsByDate[]

  const netStatus = [tableStatus, graphStatus, totalDisplayStatus]
  const isLoading = netStatus.includes(NetworkStatus.loading) || netStatus.includes(NetworkStatus.setVariables)

  const handleIntervalChange = ( period: ChartTimePeriod, range?: OptionDate) => {
    if (range) {
      setOptionDate({ ...range })
    }
    setTimePeriod(period)
  }

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).
    data?.
    platformConfig?.
    hasElementPods

  return (
    <div>
      <SolFilterHeaderCard
        header="Category Compare"
        filteredItems={totalFilteredItems}
        tableId={DataTableInstance.CategoryCompare}
        dateRange={{
          include: true,
          options: {
            fourtyEightHours: true,
            sevenDays: true,
          },
        }}
        setTimeInterval={setTimeInterval}
        timeInterval={timeInterval}
        handleIntervalChange={handleIntervalChange}
      />
      <ComparePageRide forceShowRide={false} />
      <CompareChart
        selectedDisplays={totalFilteredItems}
        totalDisplays={totalDisplays}
        data={standarizeChartData(metricsByDate, metric) as CategoryMetricsByDate[]}
        categories={(categories?._categories ?? []) as Category[]}
        metric={metric}
        timePeriod={timePeriod}
        interval={timeInterval}
        category={category || (categories?._categories[0]?.id as string)}
        onChangeMetric={setMetric}
        onChangeTimeInterval={setTimeInterval}
        onChangeCategory={setCategory}
        hasElementPods={hasElementPods}
      />
      <ContentWrapper>
        <MetricTable
          id={DataTableInstance.CategoryCompare}
          columnsOrder={[
            { name: DisplayOrderableField.Subscription },
            { name: 'name' },
            { emphasize: true, name: category },
            { emphasize: true, name: metric },
          ]}
          onSort={order => {
            setOrderByParam(orderByTransform(order))
          }}
          totalItems={totalFilteredItems}
          data={tableData}
          displaySearchOptions={displaySearchOptions}
          loading={isLoading}
        />
      </ContentWrapper>
    </div>
  )
})
