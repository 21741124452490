import React, { useState } from 'react'
import { Form, InputOnChangeData, Message, Segment } from 'semantic-ui-react'
import { isEmail } from 'validator'
import { Role, RoleDescriptions } from 'shared/types/Role'
import { parseErrorMessage } from 'shared/errors'
import styles from './InvitePanel.module.scss'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import SolModal from 'SolComponents/SolModal/SolModal'
import { RolesTable } from '../RolesTable'
import { SolCheckbox, SolTooltipWithLink } from 'SolComponents'
import { InviteUserInput } from 'graphql/__generated__/types'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface Props {
  sendInviteForUser: (user: InviteUserInput) => Promise<{}>
  addSsoUser: (user: InviteUserInput) => Promise<{}>
  refetchUsers: () => void
  isFetching: boolean
  ssoEnabled: boolean
  me: { name: string; role: Role; permissions: string[] }
}

interface State {
  email: string
  error: string
  isValidEmail: boolean
  role?: Role
  showRolesModal: boolean
  ssoOnly: boolean
}

export default function InvitePanel(props: Props) {
  const [
    {
      email,
      error,
      isValidEmail,
      role,
      showRolesModal,
      ssoOnly,
    },
    setState,
  ] = useState<State>({
    email: '',
    error: '',
    isValidEmail: false,
    role: undefined,
    showRolesModal: false,
    ssoOnly: false,
  })

  const { roomDiscoveryRole } = useFlags()

  const allRoles = (roomDiscoveryRole
    ? Object.values(Role)
    : Object.values(Role)
      .filter(v => (v.valueOf() !== 500 && v.valueOf() !== 'Room Discovery Guest')))
    .map((roleId: Role) => ({
      text: Role[roleId],
      value: roleId,
      description: RoleDescriptions[Role[roleId]],
    }))

  async function addUser() {
    setState(state => ({
      ...state,
      error: '',
    }))
    if (role !== undefined) {
      try {
        if (ssoOnly) {
          await props.addSsoUser({ email, role })
        } else {
          await props.sendInviteForUser({ email, role: role as any })
        }
        setState(state => ({
          ...state,
          email: '',
          isValidEmail: false,
        }))

        props.refetchUsers()
      } catch (err) {
        setState(state => ({
          ...state,
          error: parseErrorMessage(err, 'Unable to invite user for that email address'),
        }))
      }
    } else {
      setState(state => ({
        ...state,
        error: 'Please select a role to invite user.',
      }))
    }
  }

  function handleChange(_: {}, { name, value }: InputOnChangeData) {
    setState(state => ({
      ...state,
      [name]: value,
      isValidEmail: name === 'email' ? isEmail(value) : state.isValidEmail,
    }))
  }

  // effectiveRole is taking into account Support Login Admins' lack of permissions
  const effectiveLoggedInRole = props.me.permissions.includes('update:displays')
    ? props.me.role : Role.Viewer
  const roleOptions = allRoles.filter(option => {
    return option.value >= effectiveLoggedInRole
  }).map(option => {
    if (option.text && option.text === 'Super Admin') { option.text = 'Admin' }
    return option
  })

  const rolesLabel = (
    <label>
      Choose role
      <SolQuestionCircle
        className={styles.helpCircleIcon}
        onClick={() => setState(state => ({
          ...state,
          showRolesModal: true,
        }))}
      />
    </label>
  )

  return (
    <Segment>
      <Form>
        <Form.Group>
          <Form.Input
            label="Invite someone to Solstice Cloud"
            onChange={handleChange}
            placeholder="Email address"
            type="text"
            name="email"
            value={email}
            className={styles.email}
          />
          <Form.Select
            title="Role"
            label={rolesLabel}
            name="role"
            value={role}
            onChange={handleChange}
            options={roleOptions}
            className={styles.roles}
          />
          <SolModal
            isOpen={showRolesModal}
            onCloseModal={() => setState(state => ({
              ...state,
              showRolesModal: false,
            }))}
            showCloseIcon={false}
            className={styles.rolesModal}
          >
            <RolesTable />
          </SolModal>
          {(props.ssoEnabled)
            && <SolCheckbox
              label="SSO Only"
              checked={ssoOnly}
              onClick={e => setState(state => ({
                ...state,
                ssoOnly: e,
              }))}
              className={styles.checkbox}
              notification={
                <SolTooltipWithLink
                  key={1}
                  isInline
                  trigger={<SolQuestionCircle className={styles.helpCircleIcon} />}
                  text={`Check to require a new user to log in 
                  through your SSO provider.
                  Users not set to SSO Only can log
                  in either with a username and
                  password or with SSO.`}
                  buttonText="Learn More"
                  isExternalLink
                  linkUrl="https://documentation.mersive.com/content/topics/cloud-roles-permissions.htm"
                />
              }
            />
          }
          <Form.Button
            onClick={addUser}
            content={ssoOnly ? 'Add User' : 'Send Invite'}
            disabled={!isValidEmail || role === undefined}
            loading={props.isFetching}
            primary
          />
        </Form.Group>
      </Form>
      {error && (
        <Message negative>
          <Message.Header>Something went wrong</Message.Header>
          <p>{error}</p>
        </Message>
      )}
    </Segment>
  )
}
