import React from 'react'
import styles from './ethernet.module.scss'
import { Template, EthernetConfiguration, Vlan } from 'graphql/__generated__/types'
import EthernetSettings from './EthernetSettings/EthernetSettings'
import EthernetAuth from './EthernetAuth/EthernetAuth'
import VLAN from './VLAN/VLAN'
import { SolCheckbox, SolGrid, SolRow, SolColumn, SolNotification } from 'SolComponents'
import { defaultVlan, defaultEthernetTemplate } from './EthernetTypes'
import { setVlanIpAddress, getVlanIpAddress } from 'shared/config/ethernet'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: EthernetConfiguration) => void
  podId?: string
}

interface SetVlan {
  0?: Vlan
  1?: Vlan
  2?: Vlan
}

function Ethernet({ updateConfiguration, template, podId }: Props) {
  const config = (template?.revision?.configuration
    ?? defaultEthernetTemplate.revision.configuration) as EthernetConfiguration
  const vlan0 = (config?.vlans ?? [])[0] ?? defaultVlan
  const vlan1 = (config?.vlans ?? [])[1] ?? defaultVlan
  const vlan2 = (config?.vlans ?? [])[2] ?? defaultVlan

  const setVlans = (vlans: SetVlan) => {
    updateConfiguration({
      ...config,
      vlans: Object.assign([...(config?.vlans ?? [])], vlans),
    })
  }

  return (
    <div>
      <div className={styles.ethernetWrapper}>
        <SolGrid>
          <SolRow className={styles.borderBottom}>
            <SolColumn lg={7} sm={12} className={styles.borderRightLg}>
              <EthernetSettings
                config={config}
                updateConfig={updateConfiguration}
                podId={podId}
                isDefault={template.isDefault}
              />
            </SolColumn>
            <SolColumn lg={5} sm={12}>
              <EthernetAuth config={config} setConfig={updateConfiguration} />
            </SolColumn>
          </SolRow>
          <SolRow>
            <SolColumn xl={4} sm={12} className={styles.borderRightLg}>
              <SolCheckbox
                bold
                onClick={val => {
                  setVlans({
                    0: {
                      ...vlan0,
                      enabled: val,
                    },
                    1: {
                      ...vlan1,
                      enabled: !val ? false : vlan1.enabled,
                    },
                    2: {
                      ...vlan2,
                      enabled: !val ? false : vlan2.enabled,
                    },
                  })
                }}
                disabled={!config.enableEthernet}
                checked={!!vlan0.enabled}
                label="Enable VLAN 1"
                notification={<SolNotification type="menu" notifications="Gen 2i+" />}
              />
              {vlan0.enabled && (
                <VLAN
                  index={1}
                  vlan={vlan0}
                  setVlan={vlan => setVlans({ 0: vlan })}
                  getIpAddress={getVlanIpAddress(0)}
                  updateUniqueSettingsConfig={setVlanIpAddress(0)}
                  podId={podId}
                  isDefault={template.isDefault}
                />
              )}
            </SolColumn>
            <SolColumn xl={4} sm={12} className={styles.borderRightXl}>
              <SolCheckbox
                disabled={!config.enableEthernet || !vlan0.enabled}
                bold
                onClick={val => {
                  setVlans({
                    1: {
                      ...vlan1,
                      enabled: val,
                    },
                    2: {
                      ...vlan2,
                      enabled: !val ? false : vlan2.enabled,
                    },
                  })
                }}
                checked={!!vlan1.enabled}
                notification={<SolNotification type="menu" notifications="Gen 2i+" />}
                label="Enable VLAN 2"
              />
              {vlan1.enabled && (
                <VLAN
                  index={2}
                  vlan={vlan1}
                  setVlan={vlan => setVlans({ 1: vlan })}
                  getIpAddress={getVlanIpAddress(1)}
                  updateUniqueSettingsConfig={setVlanIpAddress(1)}
                  podId={podId}
                  isDefault={template.isDefault}
                />
              )}
            </SolColumn>
            <SolColumn xl={4} sm={12}>
              <SolCheckbox
                disabled={!config.enableEthernet || !vlan0.enabled || !vlan1.enabled}
                bold
                onClick={val => {
                  setVlans({
                    2: {
                      ...vlan2,
                      enabled: val,
                    },
                  })
                }}
                checked={!!vlan2.enabled}
                notification={<SolNotification type="menu" notifications="Gen 2i+" />}
                label="Enable VLAN 3"
              />
              {vlan2.enabled && (
                <VLAN
                  index={3}
                  vlan={vlan2}
                  setVlan={vlan =>
                    setVlans({
                      2: vlan,
                    })
                  }
                  getIpAddress={getVlanIpAddress(2)}
                  updateUniqueSettingsConfig={setVlanIpAddress(2)}
                  podId={podId}
                  isDefault={template.isDefault}
                />
              )}
            </SolColumn>
          </SolRow>
        </SolGrid>
      </div>
    </div>
  )
}

export default Ethernet
