import {
  Categorizable,
  Column,
  OrderBy,
  SELECTABLE_COLUMN,
  DataTableSlimColumn,
  GraphQLCategorizable,
  SelectType,
  SelectQuantity,
  DataTableSlimProps,
} from '../DataTableSlim'
import { AppliedCategoryFilter, AppliedCategoryFilters } from '../Hooks/useAppliedFilters'

import { AppliedCategory } from 'graphql/__generated__/types'
import { ServerSelectedItems, SelectedItems } from '../types'
import { toTitleCase, unique } from 'shared/core/utils'
import { useSelectedItems } from '../Hooks/useSelectedItems'
import { ReactNode } from 'react'

export function sortItems<T>(columns: Column<T>[], orderBy?: OrderBy, selectable?: boolean | ((row: T) => boolean)) {
  return (a: Categorizable<T>, b: Categorizable<T>) => {
    if (!orderBy) {
      return 0
    }

    if (orderBy.field === SELECTABLE_COLUMN && (!selectable || typeof selectable !== 'function')) {
      return 0
    }

    let fieldA = a[orderBy.field] || ''
    let fieldB = b[orderBy.field] || ''
    const customSort = columns.find(c => c.name === orderBy.field)
    if (customSort && customSort.sort) {
      fieldA = customSort.sort(a)
      fieldB = customSort.sort(b)
    }

    if (orderBy.field === SELECTABLE_COLUMN && selectable && typeof selectable === 'function') {
      fieldA = selectable(a)
      fieldB = selectable(b)
    }

    // assume orderBy.field is a categoryId
    if (!fieldA && !fieldB) {
      fieldA = ((a.appliedCategories || {})[orderBy.field] || {}).displayName || ''
      fieldB = ((b.appliedCategories || {})[orderBy.field] || {}).displayName || ''
    }

    if (typeof fieldA === 'boolean' && typeof fieldB === 'boolean') {
      fieldA = fieldA ? 0 : 1
      fieldB = fieldB ? 0 : 1
    }

    const orderByMultiplier = orderBy.direction === 'ascending' ? 1 : -1
    if (fieldA.toString().toLowerCase() < fieldB.toString().toLowerCase()) {
      return -1 * orderByMultiplier
    }
    if (fieldB.toString().toLowerCase() < fieldA.toString().toLowerCase()) {
      return 1 * orderByMultiplier
    }
    return 0
  }
}

export function applyFilters<T>(appliedFilters?: AppliedCategoryFilters) {
  return (item: Categorizable<T>) => {
    if (!appliedFilters) {
      return true
    }
    const filters: AppliedCategoryFilter[] = Object.values(appliedFilters)
    const appliedCategories = item.appliedCategories || {}

    return filters.every(appliedFilter => {
      const optionMatches = Object.values(appliedFilter.optionMatches || {}) || []
      let hasMatch = false

      hasMatch = optionMatches.some(({ value: optionId }) => {
        return (
          (appliedCategories[appliedFilter.categoryInternalName]
            && appliedCategories[appliedFilter.categoryInternalName].name === optionId)
          || (optionId === 'unassigned' && !appliedCategories[appliedFilter.categoryInternalName])
        )
      })
      return hasMatch
    })
  }
}

export function search<T>(val: string, columns: Column<T>[]) {
  return (item: Categorizable<T>) => {
    const appliedCategories: string[] = Object.values(Object.values(item.appliedCategories || {}) || {}).map(cat => {
      return cat.displayName
    })
    return (
      columns.some(column => (item[column.name + ''] || '').toString().toLowerCase().includes(val.toLowerCase()))
      || appliedCategories.some(cat => cat.toString().toLowerCase().includes(val.toLowerCase()))
    )
  }
}

export function filter<T>(searchVal: string, columns: Column<T>[], categoryFilters?: AppliedCategoryFilters) {
  return (items: Categorizable<T>[]) => items.filter(search(searchVal, columns)).filter(applyFilters(categoryFilters))
}

export function toDisplayName<T>(column: Column<T>): ReactNode {
  return column.displayName
    ?? column.name
      .split('_')
      .map(toTitleCase)
      .join(' ')
}

export interface MapDataOuput {
  [key: string]: string
}

export function mapDataForCSV<T>(
  data: Categorizable<T>[],
  columns: DataTableSlimColumn<T>[],
  addedColumns: string[],
  addableColumns: boolean = true,
): MapDataOuput[] {
  const filteredColumns = columns.filter(c => !addableColumns || addedColumns.includes(c.name))
  return data.map(item => {
    return filteredColumns.reduce((acc: MapDataOuput, current) => {
      if (!current.isCategory) {
        const rendered = current.render?.(item, true) ?? item[current.name + '']
        acc[toDisplayName(current) + ''] = rendered
        if (typeof rendered !== 'string') {
          /* eslint-disable-next-line no-console */
          console.error('Error mapping data to export it to csv, rendering non-string value to CSV.')
        }
      } else {
        acc[toDisplayName(current) + ''] = ((item.appliedCategories || {})[current.name] || {}).displayName || ''
      }
      return acc
    }, {})
  })
}

export function transformAppliedCategories<T>(items: GraphQLCategorizable<T>[]): Categorizable<T>[] {
  return items.map(d => ({
    ...d,
    appliedCategories: (d.categories || []).reduce((acc, current: AppliedCategory) => {
      const option = current?.option || {
        id: '',
        name: '',
      }
      acc[current.categoryId] = {
        name: option.id,
        displayName: option.name,
      }
      return acc
    }, {}),
  }))
}

export function idHasBeenSelected(id: string, selectedItems: ServerSelectedItems): boolean {
  const includedIds = selectedItems.includedIds || []
  const excludedIds = selectedItems.excludedIds || []

  if (includedIds.length === 0 && excludedIds.length === 0) {
    return false
  }

  if (excludedIds.includes('*')) {
    return false
  }

  if (excludedIds.includes(id)) {
    return false
  }

  if (includedIds.includes(id) || includedIds.includes('*')) {
    return true
  }

  return false
}

interface HandleOnSelect<T> {
  selectType: SelectType
  selectQuantity: SelectQuantity
  items: T[]
  idSelector: (item: T) => string
  data?: T[]
  selectable?: DataTableSlimProps<T>['selectable']
  currentSelectedItems: SelectedItems
}

export function handleSelectItems<T>(
  currentSelectedItems: SelectedItems,
  idSelector: (item: T) => string,
  items: T[],
): SelectedItems {

  // this functor garble appears to find a subset of these 2 arrays with only unique ids
  const updatedItems = [...currentSelectedItems.items, ...items].filter(
    (val, i, self) => self.findIndex(_ => idSelector(_) === idSelector(val)) === i,
  )
  const currentExcludedIds = currentSelectedItems.excludedIds ?? []
  const currentIncludedIds = currentSelectedItems.includedIds ?? []

  const includedIds = [...currentIncludedIds, ...updatedItems.map(idSelector)].filter(unique)
  return {
    excludedIds: currentExcludedIds
      .filter(id => !items.map(idSelector).includes(id) && id !== '*')
      .filter(id => !includedIds.some(includedId => includedId === id)),
    includedIds,
    items: updatedItems,
    ids: updatedItems.map(idSelector),
  }
}

export function resetOnSelectState(): SelectedItems {
  return {
    includedIds: [],
    excludedIds: [],
    items: [],
    ids: [],
  }
}

export function handleOnSelect<T>({
  selectType,
  selectQuantity,
  items,
  idSelector,
  currentSelectedItems,
}: HandleOnSelect<T>): SelectedItems {
  if (selectQuantity === 'all') {
    return {
      includedIds: selectType === 'select' ? ['*'] : [],
      excludedIds: selectType !== 'select' ? ['*'] : [],
      items: [],
      ids: [],
    }
  }
  const currentExcludedIds = currentSelectedItems.excludedIds ?? []
  const currentIncludedIds = currentSelectedItems.includedIds ?? []

  if (selectType === 'select') {
    return handleSelectItems(currentSelectedItems, idSelector, items)
  }
  const indexesToRemove = items.map(item => currentSelectedItems.ids.indexOf(idSelector(item)))
  const updatedIds = currentSelectedItems.ids.filter((_, index) => !indexesToRemove.includes(index)).filter(unique)
  const itemIds = items.map(idSelector)
  return {
    excludedIds: [...currentExcludedIds, ...itemIds],
    includedIds: [...currentIncludedIds, ...updatedIds].filter(unique).filter(id => !itemIds.includes(id)),
    items: currentSelectedItems.items.filter(i => updatedIds.includes(idSelector(i))),
    ids: updatedIds,
  }
}

export function getTotalItemsSelected(
  selectedItems: ReturnType<typeof useSelectedItems>,
  totalItemsSelectable: number,
) {
  if (selectedItems.excludedIds?.length && selectedItems.includedIds?.includes('*')) {
    const excludedCount = selectedItems.excludedIds?.length ?? 0
    return totalItemsSelectable - excludedCount
  } else if (selectedItems.includedIds?.includes('*')) {
    return totalItemsSelectable
  }
  return selectedItems.includedIds?.length ?? 0
}
