import { UniqueSettingsConfiguration } from 'graphql/__generated__/types'

export const getExchangeUserAccount = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.exchangeSettings?.userAccount ?? ''

export const setExchangeUserAccount = (
  uniqueSettings: UniqueSettingsConfiguration,
  userAccount: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    exchangeSettings: {
      userAccount,
    },
  },
})

export const getExchangeUserPassword = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.exchangeSettings?.userPassword?.secret ?? ''

export const setExchangeUserPassword = (
  uniqueSettings: UniqueSettingsConfiguration,
  secret: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    exchangeSettings: {
      userPassword: {
        secret,
      },
    },
  },
})

export const getOffice365Username = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.office365Settings?.username ?? ''

export const setOffice365Username = (
  uniqueSettings: UniqueSettingsConfiguration,
  secret: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    office365Settings: {
      username: secret,
    },
  },
})
export const getGoogleRoomMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.googleSettings?.roomMailbox ?? ''

export const setGoogleRoomMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
  mailbox: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    googleSettings: {
      roomMailbox: mailbox,
    },
  },
})


export const getExchangeImpersonationMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.exchangeSettings?.impersonationMailbox ?? ''

export const setExchangeImpersonationMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
  impersonationMailbox: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    exchangeSettings: {
      impersonationMailbox,
    },
  },
})

export const getExchangeDelegationMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.CALENDAR?.exchangeSettings?.delegationMailbox ?? ''

export const setExchangeDelegationMailbox = (
  uniqueSettings: UniqueSettingsConfiguration,
  delegationMailbox: string,
) => ({
  CALENDAR: {
    ...uniqueSettings.CALENDAR,
    exchangeSettings: {
      delegationMailbox,
    },
  },
})
