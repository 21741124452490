import {
  ConfigurationSetting,
  DisplayCompatibilityAggregate,
} from './../../graphql/__generated__/types'
import { DropdownItemProps } from 'semantic-ui-react'

export interface CompatibilityResponse {
  isCompatible: boolean
  reason?: string
}

export function dropdownCompatibility(
  ddprops: DropdownItemProps[],
  aggregate: DisplayCompatibilityAggregate | null | undefined,
  settings: ConfigurationSetting[],
) {
  if (settings.length === 0) {
    return ddprops
  }
  if (!aggregate) {
    return ddprops
  }

  const props = ddprops.map(prop => {
    const setting = settings.filter(
      s => s.key === prop.setting && s.value === prop.value,
    )

    const compatRes = isCompatible(setting, aggregate)
    if (compatRes.isCompatible) {
      return prop
    }
    return {
      ...prop,
      description: compatRes.reason,
      icon: 'exclamation circle',
      disabled: true,
    }
  })
  return props
}

export function isCompatible(
  settings: ConfigurationSetting[],
  aggregate?: DisplayCompatibilityAggregate,
): CompatibilityResponse {

  if (settings.length === 0) {
    return {
      isCompatible: true,
    }
  }
  if (!aggregate) {
    return {
      isCompatible: false,
      reason: 'Not Compatible',
    }
  }

  const minHW = settings
    .map(s => s?.compatibility?.minHardware)
    .sort()[settings.length - 1]

  const minSW = settings
    .map(s => s?.compatibility?.minSoftware)
    .sort()[settings.length - 1]

  const minLMS = settings.map(s => s?.compatibility?.minLms).sort()[
    settings.length - 1
  ]

  let response = {
    isCompatible: true,
    reason: '',
  }

  if (minHW && aggregate.minHardware < minHW) {
    response.isCompatible = false
    response.reason = `Requires ${minHW} hardware`
    return response
  }
  if (minSW && aggregate.minSoftware < minSW) {
    response.isCompatible = false
    response.reason += `Requires ${minSW} software`
    return response
  }
  if (minLMS && aggregate.minLms < minLMS) {
    response.isCompatible = false
    response.reason += `Requires ${minLMS} LMS`
    return response
  }

  return response
}
