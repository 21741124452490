import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form } from 'semantic-ui-react'
import styling from '../../Login.module.scss'
import { SolButton } from 'SolComponents'
import qs from 'qs'
import { useCallback } from 'react'

interface Props {
  location: any
  error: string
  handleOnSubmit: (e: string) => void
  loading: boolean
  setError: (error: string) => void
}

function ResetPassword(props: Props) {
  const { location, setError } = props
  const hasLocation = location && (location.state || location.search)

  const paramEmail = hasLocation && location.state ? location.state.email : qs.parse(location.search)['?email']

  const [email, setEmail] = useState(hasLocation ? paramEmail : '')

  const updateError = useCallback(() => setError(''), [setError])

  const styles = {
    input: {
      margin: '0 0 1.5rem',
      minWidth: '24rem',
      width: '350px',
    } as React.CSSProperties,
    button: {
      width: '350px',
      marginBottom: '18px',
    } as React.CSSProperties,
    group: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '350px;',
    } as React.CSSProperties,
    back: {
      display: 'flex',
      width: '100%',
      marginTop: '12px',
      alignItems: 'left',
      marginLeft: '30px',
    } as React.CSSProperties,
  }

  const { error, loading } = props

  return (
    <Form.Group style={styles.group}>
      <Form.Input
        style={styles.input}
        value={email}
        placeholder="Email address"
        onChange={(e, data: any) => setEmail(data.value)}
      />
      {error ? <p className={styling.error}>{error}</p> : null}
      <div className={styling.loginButton} style={styles.button}>
        <SolButton
          text="Reset password"
          primary
          basic={false}
          isLink={false}
          handleClick={() => {
            props.handleOnSubmit(email)
          }}
          loading={loading}
          type="button"
          disabled={!email}
        />
      </div>
      <div style={styles.back}>
        <Link
          to={{
            pathname: '/login',
            state: { email },
          }}
          onClick={updateError}
        >
          <strong>&larr; Back to login page</strong>
        </Link>
      </div>
    </Form.Group>
  )
}

export default withRouter(ResetPassword as any) as any
