import FlowProgress, {
  Props as FlowProps,
  style,
} from './FlowProgress'

export { style }

export type Props = FlowProps

export default FlowProgress
