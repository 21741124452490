import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'

import {
  FlowProgress,
  StepSpinner,
  FlowHeader,
} from 'components/Maintenance/Renewal/Partials'

import {
  GettingStarted,
  SelectTermDate,
  InformationStep,
  AlmostDoneStep,
  AllDone,
} from 'components/Maintenance/Renewal/Steps'

import { useTable } from 'components/Maintenance/Renewal/Steps/SelectExpiredDisplays/useTable'
import SelectExpiredDisplay from 'components/Maintenance/Renewal/Steps/SelectExpiredDisplays'
import { useTable as useTableOther } from 'components/Maintenance/Renewal/Steps/SelectOtherDisplays/useTable'
import SelectOtherDisplay from 'components/Maintenance/Renewal/Steps/SelectOtherDisplays'

import {
  getRenewFlowIsWaiting,
  getRenewFlowCurrentLead,
  getRenewFlowSelectedPods,
  getRenewFlowEarliestPodExpiration,
  getRenewFlowLatestPodExpiration,
} from 'shared/store/selectors/maintenance'

import {
  rewindMxRenew,
  editMxRenew,
  finishMxRenew,
  setMxBanner,
} from 'shared/store/slices/maintenance'

import {  createMxRenewLead, updateMxRenewLead } from 'shared/store/actions/maintenance'

import getHistory from 'shared/core/history'
import { useDisplayCountQuery, ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'
import { getEmail } from 'shared/core/authenticate'
import { State } from 'shared/store/slices/reducerUtils'

export const style = {
  wrapper: {
    width: '100%',
  } as React.CSSProperties,
  padding: {
    padding: '8px',
  } as React.CSSProperties,
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  flexItem: {
    margin: 'auto',
    width: '860px',
  } as React.CSSProperties,
}

const RenewFlow = () => {
  const { totalItems: totalExpiredItems } = useTable()

  const { totalItems: totalOtherItems } = useTableOther(false)

  const dispatch = useDispatch()

  const where: ViewDisplaysBoolExp = {
    _and: [
      {
        deployment: {
          license: { _neq: 'Element' },
        },
      },
    ],
  }

  const isWaiting = useSelector(state => getRenewFlowIsWaiting(state))
  const currentStep = useSelector((state: State) => state.mxRenewal.currentStep)
  const currentLead = useSelector(state => getRenewFlowCurrentLead(state))
  const totalSelectedExpiredPods = Object.keys(
    currentLead.selectedExpiredPods || {},
  ).length

  const selectedPods = useSelector(state => getRenewFlowSelectedPods(state))
  const { data } = useDisplayCountQuery({ variables: { where: where } })
  const numRawPods = data?.totalDisplays.aggregate?.count ?? 0
  const userEmail = getEmail()
  const earliestPodExpirationDate = useSelector(state =>
    getRenewFlowEarliestPodExpiration(state),
  )
  const latestPodExpirationDate = useSelector(state =>
    getRenewFlowLatestPodExpiration(state),
  )

  const createLead = useCallback(
    (lead: MxRenewLeadInterface, nextStep: number) =>
      dispatch(createMxRenewLead(lead, nextStep)),
    [dispatch],
  )

  const updateLead = useCallback(
    (leadId: MxRenewLeadId, update: MxRenewLeadInterface, nextStep: number) =>
      dispatch(updateMxRenewLead(leadId, update, nextStep)),
    [dispatch],
  )

  const cancelFlow = useCallback(
    () => {
      const history = getHistory()
      history.replace('/home')
      dispatch(finishMxRenew())
    },
    [dispatch],
  )
  const rewindFlow = (jumpToStep?: number) =>
    dispatch(rewindMxRenew(jumpToStep))

  const edit = useCallback(() => dispatch(editMxRenew()), [dispatch])
  const finishFlow = useCallback(
    () => dispatch(finishMxRenew()),
    [dispatch],
  )
  const closeBannerCb = useCallback(() => dispatch(setMxBanner(false)), [dispatch])

  const renderHeader = () => {
    return <FlowHeader />
  }

  const renderProgressBar = () => {
    return <FlowProgress currentStep={currentStep} totalSteps={6} />
  }

  const renderSpinner = () => {
    return <StepSpinner />
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <GettingStarted
            cancelFlow={cancelFlow}
            createLead={createLead}
            nextStep={1}
          />
        )
      case 1:
        return (
          <SelectExpiredDisplay
            hasOtherPods={totalOtherItems > 0}
            rewindFlow={rewindFlow}
            currentLead={currentLead}
            cancelFlow={cancelFlow}
            updateLead={updateLead}
            nextStep={2}
          />
        )
      case 2:
        return (
          <SelectOtherDisplay
            hasOtherPods={totalOtherItems > 0}
            hasExpiredPods={totalExpiredItems > 0}
            hasSelectedExpiredPods={totalSelectedExpiredPods > 0}
            rewindFlow={() => {
              if (totalExpiredItems === 0) {
                rewindFlow(0)
              } else {
                rewindFlow()
              }
            }}
            currentLead={currentLead}
            cancelFlow={cancelFlow}
            updateLead={updateLead}
            nextStep={3}
          />
        )
      case 3:
        return (
          <SelectTermDate
            earliestPodExpirationDate={earliestPodExpirationDate}
            latestPodExpirationDate={latestPodExpirationDate}
            selectedPods={selectedPods}
            numRawPods={numRawPods}
            currentLead={currentLead}
            cancelFlow={cancelFlow}
            rewindFlow={() => {
              if (!(totalOtherItems > 0)) {
                rewindFlow(1)
              } else {
                rewindFlow()
              }
            }}
            updateLead={updateLead}
            nextStep={4}
          />
        )
      case 4:
        return (
          <InformationStep
            currentLead={currentLead}
            selectedPods={selectedPods}
            userEmail={userEmail}
            cancelFlow={cancelFlow}
            rewindFlow={rewindFlow}
            updateLead={updateLead}
            nextStep={5}
          />
        )
      case 5:
        return (
          <AlmostDoneStep
            closeBanner={closeBannerCb}
            currentLead={currentLead}
            pods={selectedPods}
            numRawPods={numRawPods}
            rewindFlow={rewindFlow}
            cancelFlow={cancelFlow}
            updateLead={updateLead}
            nextStep={6}
            edit={edit}
          />
        )
      case 6:
        return <AllDone finishFlow={finishFlow} />
      default:
        return renderSpinner()
    }
  }

  return (
    <div style={style.wrapper}>
      <div style={style.padding}>
        {renderHeader()}
        {renderProgressBar()}
        <div style={style.flexContainer}>
          <div style={style.flexItem}>
            {isWaiting ? renderSpinner() : renderCurrentStep()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenewFlow
