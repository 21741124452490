import React from 'react'
import { toPercentage } from 'shared/core/utils'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { SolDonutCard } from 'SolComponents'
import styles from './UsbDeviceProductsCard.module.scss'
import { UsbProduct } from 'graphql/__generated__/types'

type Props = {
  addProductFilter: (id: number, name: string) => void
  productData?: UsbProduct[] | undefined
  productConnectionsCount: number
}

export const UsbDeviceProductsCard = ({
  addProductFilter,
  productData,
  productConnectionsCount,
}: Props ) => {
  const total = productConnectionsCount

  const chartData: PieSector[]
    = productData?.map(product => {
      let count = product?.displayCount ?? 0
      return {
        name: product.productName ?? '',
        count,
        percentage: toPercentage(count, total),
        onClick: () => addProductFilter(
          Number.parseInt(product.productId || ''),
          product.productName ?? '',
        ),
      }
    }) ?? []

  return (
    <SolDonutCard
      className={styles.card}
      data={chartData}
      isLoading={false}
      title="Device Model"
      testId="usb-device-products-card"
      paging
      chartProps={{
        aspectRatio: 2,
        minWidth: 300,
        legendWidth: 196,
        xPosition: '58%',
        innerRadius: '70%',
        outerRadius: '88%',
      }}
    />
  )
}
