import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import './style.scss'

interface Props {
  checked: boolean
  disabled?: boolean
  onClick: (val: boolean) => void
}

export const SolAudioToggle = ({
  checked,
  disabled = false,
  onClick,
}: Props) => {
  return (
    <Checkbox
      toggle
      disabled={disabled}
      checked={checked}
      onClick={(_, val) =>
        !disabled && onClick(!!val.checked) // eslint-disable-line no-unused-vars
      }
    />
  )
}
