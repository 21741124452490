import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import React, { useState } from 'react'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import { SolArrowButton } from 'SolComponents'
import FilterHeader from './FilterHeader'
import RoomsInUse from './RoomsInUse'
import styles from './index.module.scss'
import Map from './Map/Map'
import RealtimeTable from './RealtimeTable'
import Occupancy from './Occupancy'
import { CustomFilters, CustomFilter } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { RealtimeDisplay, RealtimeDisplaysQuery } from 'graphql/__generated__/types'
import { StatusEnum as RealtimeStatus } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'

const responsiveType: ResponsiveType = {
  desktop: {
    breakpoint: { max: 9999, min: 1390 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1390, min: 970 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 970, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
}

type Props = {
  aspectRatio?: number
  filteredItems: number
  totalRooms: number
  roomsInUse: number
  bookedRooms: number
  adhocRooms: number
  unknownRooms: number
  loadingRooms: boolean
  occupancyData: RealtimeDisplaysQuery | undefined
  occupancyDataLoading: boolean
  realtimeMapData: RealtimeDisplaysQuery | undefined
  realtimeTableData: RealtimeDisplay[] | undefined
  loadingPage: boolean
  visiblePodsBox: any
  setVisiblePodsBox: any
  realtimeStatusFilters: CustomFilters<RealtimeStatus>
  setCustomRealtimeStatusFilter: (
    filterId: string,
    newCustomFilter: Partial<CustomFilter<RealtimeStatus>>
  ) => void
  fetchCsvData: any
}

const RealtimePage = ({
  aspectRatio,
  filteredItems,
  totalRooms,
  roomsInUse,
  bookedRooms,
  adhocRooms,
  unknownRooms,
  loadingRooms,
  occupancyData,
  occupancyDataLoading,
  realtimeMapData,
  realtimeTableData,
  loadingPage,
  visiblePodsBox,
  setVisiblePodsBox,
  realtimeStatusFilters,
  setCustomRealtimeStatusFilter,
  fetchCsvData,
}: Props) => {
  const [mapBounds, setMapBounds] = useState<number[][] | undefined>(undefined)
  const [filterCheckBoxValue, setFilterCheckBoxValue] = useState(false)

  return (
    <div className={styles.realtime}>
      <FilterHeader
        filterbyVisiblePods={filterCheckBoxValue}
        clearVisiblePods={() => {
          setVisiblePodsBox(undefined)
          setFilterCheckBoxValue(false)
        }}
        selectedPodCount={filteredItems}
        totalPodCount={totalRooms}
      />
      <ContentWrapper>
        <div>
          <Carousel
            draggable={false}
            responsive={responsiveType}
            ssr={!!aspectRatio}
            deviceType={aspectRatio ? 'desktop' : ''}
            containerClass={styles.topRow}
            partialVisible
            customLeftArrow={<SolArrowButton direction="left" />}
            customRightArrow={<SolArrowButton direction="right" />}
          >
            <RoomsInUse
              totalRooms={totalRooms}
              roomsInUse={roomsInUse}
              bookedRooms={bookedRooms}
              adhocRooms={adhocRooms}
              unknownRooms={unknownRooms}
              loading={loadingRooms}
              setCustomRealtimeStatusFilter={setCustomRealtimeStatusFilter}
              realtimeStatusFilters={realtimeStatusFilters}
            />
            <Occupancy
              occupancyData={occupancyData}
              loading={occupancyDataLoading}
            />
          </Carousel>
        </div>
        <Map
          data={realtimeMapData}
          setBoundingBoxFilter={e => setVisiblePodsBox(e)}
          boundingBoxFilter={visiblePodsBox}
          setMapBounds={setMapBounds}
          mapBounds={mapBounds}
          filterCheckBoxValue={filterCheckBoxValue}
          setFilterCheckBoxValue={e => setFilterCheckBoxValue(e)}
        />
        <div className={styles.table}>
          <RealtimeTable
            realtimeData={realtimeTableData}
            loading={loadingPage}
            totalItems={filteredItems}
            fetchCsvData={fetchCsvData}
          />
        </div>
      </ContentWrapper>
    </div>
  )
}

export default RealtimePage
