import React from 'react'
import PeriodSelector from './PeriodSelector'
import CurrentMeetings from './CurrentMeetings'
import BusiestHour from './BusiestHour'
import UniqueUsers from './UniqueUsers'
import MedianMeeting from './MedianMeeting'
import { useTopRow } from './TopRow.hook'
import styles from './index.module.scss'
import { TimeInterval } from 'graphql/__generated__/types'
import SolElementInfoMessage from '../../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'

export interface DateRange {
  fromDate: string
  toDate: string
  interval: TimeInterval
}

interface TopRowProps {
  dateRange: DateRange
  onChange: (days: number) => void
}

const TopRow = ({ dateRange, onChange }: TopRowProps) => {

  const {
    uniqueUsers,
    busiestHour,
    medianMeeting,
    activeMeetings,
    hasElementPods,
  } = useTopRow({ dateRange })

  return (
    <div className={styles.parent}>
      <div className={styles.firstRow}>
        <div className={styles.top}>
          <PeriodSelector onChange={onChange} />
          {hasElementPods
            && (
              <div className={styles.elementMessageContainer}>
                <SolElementInfoMessage
                  message="Solstice Element pods are not included"
                />
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.bottom}>
        <CurrentMeetings count={activeMeetings}/>

        <UniqueUsers count={uniqueUsers}/>

        <MedianMeeting count={medianMeeting} />

        <BusiestHour count={busiestHour} />
      </div>
    </div>
  )
}

export default TopRow
