import React from 'react'
import { SolCard, SolProgressBar } from 'SolComponents'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import styles from './index.module.scss'
import { CustomFilter, CustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { toPercentage } from 'shared/core/utils'
import { StatusEnum as RealtimeStatus } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'

type Props = {
  totalRooms: number
  roomsInUse: number
  bookedRooms: number
  adhocRooms: number
  unknownRooms: number
  loading: boolean
  setCustomRealtimeStatusFilter: (
    filterId: string,
    newCustomFilter: Partial<CustomFilter<RealtimeStatus>>
  ) => void
  realtimeStatusFilters: CustomFilters<RealtimeStatus>
}

const RoomsInUse = ({
  totalRooms,
  roomsInUse,
  bookedRooms,
  adhocRooms,
  unknownRooms,
  loading,
  setCustomRealtimeStatusFilter,
  realtimeStatusFilters,
}: Props) => {

  const roundedCapacity = toPercentage(
    totalRooms && roomsInUse,
    totalRooms || 0,
  )

  const roundedBookedPercentage = toPercentage(
    totalRooms && bookedRooms,
    totalRooms || 0,
  )

  const roundedAdhocRoomsPercentage = toPercentage(
    totalRooms && adhocRooms,
    totalRooms || 0,
  )

  const roundedUnknownRoomsPercentage = toPercentage(
    totalRooms && unknownRooms,
    totalRooms || 0,
  )

  const addRealtimeStatusFilter = (
    internalName: string,
    displayName: string,
    status: RealtimeStatus,
  ) => {
    const values = {
      ...realtimeStatusFilters?.RealtimeStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomRealtimeStatusFilter('RealtimeStatus', {
      values,
      color: 'blue',
    })
  }
  const addAdHocRealtimeStatusFilter = () =>
    addRealtimeStatusFilter('adHoc', 'Ad Hoc', RealtimeStatus.AD_HOC)
  const addBookedEmptyRealtimeStatusFilter = () =>
    addRealtimeStatusFilter('bookedEmpty', 'Booked, Empty', RealtimeStatus.BOOKED_EMPTY)
  const addUnknownRealtimeStatusFilter = () =>
    addRealtimeStatusFilter('unknown', 'Unknown', RealtimeStatus.UNKNOWN)

  return (
    <SolCard className={styles.roomsInUse}>
      <LoadingWrapper isEmpty={false} isLoading={loading || false}>
        <div data-testid="rooms-in-use-card" className={styles.content}>
          <div className={styles.contentLeft}>
            <div
              className={`${styles.inUseNumber} ${
                roomsInUse > 9999 ? styles.reducedFont : ''
              }`}
            >
              {roomsInUse}
            </div>
            <div className={styles.inUseLegend}>Rooms in use right now</div>
            <div className={styles.capacity}>
              <SolProgressBar percent={roundedCapacity} label="capacity" />
            </div>
          </div>
          <div className={styles.contentRight}>
            <button
              onClick={addBookedEmptyRealtimeStatusFilter}
              className={styles.row}
            >
              <div className={styles.number}>{roundedBookedPercentage}%</div>
              <div className={styles.label}>Booked Meetings</div>
            </button>
            <button
              onClick={addAdHocRealtimeStatusFilter}
              className={styles.row}
            >
              <div className={styles.number}>
                {roundedAdhocRoomsPercentage}%
              </div>
              <div className={styles.label}>Ad Hoc Meetings</div>
            </button>
            <button
              onClick={addUnknownRealtimeStatusFilter}
              className={styles.row}
            >
              <div className={styles.number}>
                {roundedUnknownRoomsPercentage}%
              </div>
              <div className={styles.label}>Unknown</div>
            </button>
          </div>
        </div>
      </LoadingWrapper>
    </SolCard>
  )
}

export default RoomsInUse
