import React from 'react'
import styles from './SolRadio.module.scss'
import classNames from 'classnames'
import { Radio } from 'semantic-ui-react'

export interface Props {
  label: String
  checked: boolean | undefined | null
  onClick: (e: any, v: any) => void
  disabled?: boolean
  className?: string
  notification?: JSX.Element
}

export const SolRadio = (props: Props) => {

  return (
    <Radio
      className={classNames(styles.radioWrapper, props.className)}
      label={<label>{props.label} {props.notification}</label>}
      checked={props?.checked ?? false}
      onClick={props.disabled ? undefined : props.onClick}
      disabled={props.disabled}
    />
  )
}

export default SolRadio
