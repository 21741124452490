import React, { ReactNode } from 'react'
import {
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  Text,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
  XAxisProps,
  TextProps,
} from 'recharts'

interface Props {
  data: any[]
  width?: number
  responsiveHeight: number | string
  children?: ReactNode
  margin?: Record<string, any>
  testId?: string
  grid?: boolean
  barGap?: number
  barCategoryGap?: number
  barSize?: number
  layout?: 'vertical' | 'horizontal'
  legend?: boolean
  tooltip?: {
    formatter?: any
    content?: ({
      active,
      payload,
      label,
    }: {
      active: boolean
      payload: { value: number }[]
      label: string
    }) => JSX.Element
  }
  xAxis: XAxisProps
  yAxis: YAxisProps
}

const tickConfig: TextProps = {
  fill: '#333333',
  fontSize: '12px',
  fontWeight: 700,
}

const truncateText = (text: string = '') => {
  const MAX_LENGTH = 12
  if (text.length > MAX_LENGTH) {
    return `${text.substring(0, MAX_LENGTH)}...`
  }
  return text
}

const SolBarChart = ({
  data,
  width,
  responsiveHeight,
  testId,
  layout = 'horizontal',
  margin,
  children,
  grid = true,
  barSize = 15,
  barGap,
  barCategoryGap = 1,
  tooltip = {},
  xAxis,
  yAxis,
}: Props) => {
  const xTick = {
    ...tickConfig,
    ...(typeof xAxis.tick === 'object' && xAxis.tick),
  }
  const yTick = {
    ...tickConfig,
    ...(typeof yAxis.tick === 'object' && yAxis.tick),
  }

  const CustomYAxisTick = (props: any & { payload: { value?: string } }) => (
    <Text {...props} {...yTick}>
      {truncateText(props.payload.value)}
    </Text>
  )

  return (
    <ResponsiveContainer width="100%" height={responsiveHeight}>
      <BarChart
        data-testid={testId}
        width={width}
        data={data}
        layout={layout}
        barSize={barSize}
        barGap={barGap}
        barCategoryGap={barCategoryGap}
        margin={margin}
      >
        {grid && <CartesianGrid strokeDasharray="3 3" />}
        <XAxis tick={xTick} {...xAxis} />
        <YAxis tick={CustomYAxisTick} {...yAxis} />
        <Tooltip formatter={tooltip?.formatter} cursor={{ fill: '#eee' }} content={tooltip?.content} separator=": " />
        {children}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SolBarChart
