import React, { useEffect, useState } from 'react'

import styles from './Map.module.scss'
import PodsOnMap from './PodsOnMap'
import AddressesOnMap from './AddressesOnMap'
import BuildingClusters from './BuildingClusters'
import FilterByVisiblePodsCheckbox from './FilterByVisiblePodsCheckbox'
import SolMap from 'SolComponents/SolMap/SolMap'
import { SolCard, SolButtonGroupProps, SolButtonGroup } from 'SolComponents'
import { ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'
import { AppliedCategoryFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
  categoryFilters: AppliedCategoryFilters

  boundingBoxFilter: number[][] | undefined
  setBoundingBoxFilter: (newFilter: number[][] | undefined) => void

  mapBounds: number[][] | undefined
  setMapBounds: (newFilter: number[][] | undefined) => void

  filterCheckBoxValue: boolean
  setFilterCheckBoxValue: (el: boolean) => void

  buildingFloorMap: { [key: number]: string }
  setBuildingFloorMap: (e: { [key: number]: string }) => void
}

export function OverViewMap({
  searchParamsWhere,
  categoryFilters,
  setBoundingBoxFilter,
  setMapBounds,
  boundingBoxFilter,
  filterCheckBoxValue,
  setFilterCheckBoxValue,
  buildingFloorMap,
  setBuildingFloorMap,
  mapBounds,
}: Props) {
  const [zoom, setZoom] = useState(5)
  const [zoomToNewFilter, setZoomToNewFilter] = useState(false)
  const [clusterType, setClusterType] = useState<'Pods' | 'Buildings'>('Pods')
  const [podsOrBuildingsButtons, setPodsOrBuildingButtons] = useState<SolButtonGroupProps>([])

  useEffect(() => {
    if (zoom < 18 && Object.keys(buildingFloorMap).length) {
      setBuildingFloorMap({}) // clear all floor constrants so that we cluster with correct number of pods
    }

    if (zoomToNewFilter) {
      setZoomToNewFilter(false) // set back to false so tht you can zoom in and out without it fitting to bounds agian
    }
  }, [zoom])

  useEffect(() => {
    const currentFilterTypes = Object.keys(categoryFilters)

    if (
      !currentFilterTypes.length
      || currentFilterTypes.some(filterType => filterType === 'city' || filterType === 'country')
    ) {
      setZoomToNewFilter(true)
    }
  }, [categoryFilters])

  useEffect(() => {
    setZoomToNewFilter(true)
    setPodsOrBuildingButtons([
      {
        primary: clusterType === 'Pods',
        value: 'Pods',
        content: 'Cluster by Pods',
        onClick: () => setClusterType('Pods'),
      },
      {
        primary: clusterType === 'Buildings',
        value: 'Buildings',
        content: 'Cluster by Buildings',
        onClick: () => setClusterType('Buildings'),
      },
    ] as SolButtonGroupProps)
  }, [clusterType])

  return (
    <div className={styles.mapWrapper}>
      <SolCard className={styles.mapCard}>
        <div className={filterCheckBoxValue ? styles.borderActive : styles.border}>
          <SolMap className={styles.map} maxZoom={20} minZoom={2}>
            <FilterByVisiblePodsCheckbox
              setBoundingBoxFilter={setBoundingBoxFilter}
              boundingBoxFilter={boundingBoxFilter}
              filterCheckBoxValue={filterCheckBoxValue}
              setFilterCheckBoxValue={setFilterCheckBoxValue}
              setMapBounds={setMapBounds}
            />
            <SolButtonGroup buttons={podsOrBuildingsButtons} className={styles.PodsOrBuildingsButtons} />
            <BuildingClusters
              searchParamsWhere={searchParamsWhere}
              showCluster={clusterType === 'Buildings'}
              zoom={zoom}
              zoomToNewFilter={zoomToNewFilter}
            />
            <PodsOnMap
              searchParamsWhere={searchParamsWhere}
              showCluster={clusterType === 'Pods' || zoom > 17}
              zoomToBounds={clusterType === 'Pods' && zoomToNewFilter}
              zoom={zoom}
              buildingFloorMap={buildingFloorMap}
            />
            <AddressesOnMap
              searchParamsWhere={searchParamsWhere}
              clusterType={clusterType}
              zoom={zoom}
              setZoom={setZoom}
              mapBounds={mapBounds}
              buildingFloorMap={buildingFloorMap}
              setBuildingFloorMap={setBuildingFloorMap}
            />
          </SolMap>
        </div>
      </SolCard>
    </div>
  )
}

export default OverViewMap
