import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps<any> {
  isAuthenticated: boolean
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: !!state?.user?.token,
})

function requireAuth(Component: any) {
  class AuthenticatedComponent extends React.Component<Props> {
    componentDidMount() {
      const { isAuthenticated } = this.props
      if (!isAuthenticated) {
        this.props.history.push('/login', { forward: this.props.location.pathname })
      }
    }

    render() {
      return this.props.isAuthenticated ? <Component {...this.props} /> : null
    }
  }

  return withRouter(connect<{}, any, any>(mapStateToProps)(AuthenticatedComponent))
}

export default requireAuth
