import { createSlice } from '@reduxjs/toolkit'

export type RenewalState = {
  initiated: boolean
  lastInitiatedTime?: any
  fromRoute?: string
  active: boolean
  waiting: boolean
  currentStep?: number
  lead?: any
  currentErrors?: any
  showExpiringSoon?: boolean
  showBanner?: boolean
}

const initialState = {
  initiated: true,
  active: true,
  waiting: false,
  currentStep: 0,
  lead: null,
  currentErrors: null,
  showExpiringSoon: true,
}


export const maintenanceSlice = createSlice({
  name: 'mxRenewal',
  initialState,
  reducers: {
    initMxRenew : (state: RenewalState, action) => {
      state.initiated = true
      state.lastInitiatedTime = action.payload.timestamp
      state.fromRoute = action.payload.fromRoute
      state.active = true
      state.waiting = false
      state.currentStep = 0
      state.lead = null
      state.currentErrors = null
      state.showExpiringSoon = true
    },
    pauseMxRenew: ( state: RenewalState) => {
      state.active = false
    },
    rewindMxRenew: (state: RenewalState, action ) => {
      const currentStep = state.currentStep
      let prevStep: number = currentStep ? currentStep - 1 : 0
      if (action.payload?.jumpToStep !== undefined) {
        prevStep = action.payload.jumpToStep
      }
      state.currentStep = prevStep
    },
    editMxRenew: (state: RenewalState) => {
      state.currentStep = 1
    },
    createMxRenewLead: (state: RenewalState) => {
      state.waiting = true
    },
    toggleOnlyShowExpiringSoon: (state: RenewalState) => {
      state.showExpiringSoon = !state.showExpiringSoon
    },
    setMxBanner: (state: RenewalState, action) => {
      state.showBanner = action.payload
    },
    createMxRenewLeadSuccess: (state, action) => {
      state.waiting = false
      state.lead = {
        ...action.payload.createdLead,
        maxStep: action.payload.createdLead.step,
      }
      state.currentStep = action.payload.nextStep
    },
    updateMxRenewLead: state => {
      state.waiting = true
    },
    finishMxRenew: (state: RenewalState) => {
      state.initiated = false
      state.active = false
      state.currentStep = undefined
      state.currentErrors = null
      state.lead = null
    },
    createMxRenewLeadError: (state, action) => {
      state.waiting = false
      state.currentErrors = action.payload.error
    },
    updateMxRenewLeadError: (state, action) => {
      state.waiting = false
      state.currentErrors = action.payload.error
    },
    updateMxRenewLeadSuccess: (state: RenewalState, action) => {
      const currentLead = state.lead
      state.waiting = false
      state.lead = {
        ...currentLead,
        ...action.payload.updatedLead,
        maxStep: Math.max(currentLead?.step || 0, action.payload.updatedLead.step),
      }
      state.currentStep = action.payload.nextStep
    },
  },
})

export const {
  initMxRenew,
  pauseMxRenew,
  rewindMxRenew,
  editMxRenew,
  createMxRenewLead,
  toggleOnlyShowExpiringSoon,
  createMxRenewLeadSuccess,
  updateMxRenewLead,
  finishMxRenew,
  createMxRenewLeadError,
  updateMxRenewLeadError,
  updateMxRenewLeadSuccess,
  setMxBanner,
} = maintenanceSlice.actions

export default maintenanceSlice.reducer
