import React from 'react'
import { Marker, Tooltip } from 'react-leaflet'
import { LatLng, divIcon } from 'leaflet'
import { renderToString } from 'react-dom/server'
import styles from './Map.module.scss'
import SolGpsTargetIcon from 'SolComponents/Icons/SolGpsIcon/SolGpsTargetIcon'

interface TargetMarkerType {
  position: LatLng
  name: string
  editing?: boolean | false
}

export const TargetMarker = (props: TargetMarkerType) => {
  const target = divIcon({
    html: renderToString(
      <SolGpsTargetIcon color={props.editing ? '#faa734' : '#2db84b'} />,
    ),
    className: `${styles.gps}`,
  })

  return (
    <Marker icon={target} position={props.position}>
      <Tooltip className={styles.dotTooltip} direction="bottom">
        {props.name}
      </Tooltip>
    </Marker>
  )
}
