import React from 'react'
import { useGetSupportAccessQuery, useSsoInfoQuery, useUpdateSupportAccessMutation } from 'graphql/__generated__/types'
import UsersPage from './UsersPage'
import { useAlerts } from 'shared/hooks/useAlerts'

const AccountController = () => {
  const [updateSupportAccess] = useUpdateSupportAccessMutation({})
  const { data, refetch } = useGetSupportAccessQuery()
  const { showSuccess } = useAlerts()
  
  const { data: ssoData } = useSsoInfoQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const updateAccess = async (time: string) => {
    await updateSupportAccess({
      variables: {
        options: {
          grantAccessUntil: time,
        },
      },
    })
    showSuccess(time  ? 'Acces Granted'  : 'Access has been revoked')
    refetch()
  }

  return (<UsersPage
    supportAccess={data?.supportAccess?.grant_support_access_until || ''}
    updateSupportAccess={updateAccess}
    ssoEnabled={!!ssoData?.ssoOrg}
  />)
}

export default AccountController
