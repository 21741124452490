import React from 'react'
import { Dropdown, Label, Loader } from 'semantic-ui-react'
import classNames from 'classnames'
import { useOptionsWithDisplayCountQuery } from 'graphql/__generated__/hasura-types'
import { AppliedCategoryFilters } from '../Hooks/useAppliedFilters'
import styles from './FilterMenu.module.scss'

type Props = {
  categoryId: string
  totalDisplayCount: number
  onOptionClick: (optionId: { value: string }) => void
  appliedFilters?: AppliedCategoryFilters
}

export function FilterMenuDropdown({ categoryId, onOptionClick, totalDisplayCount, appliedFilters }: Props) {
  const { data, loading } = useOptionsWithDisplayCountQuery({
    variables: {
      category_id: categoryId,
      where: {},
    },
  })
  const unassignedIsApplied = appliedFilters?.[categoryId]?.optionMatches?.unassigned

  const unassignedCount
    = data?._options?.reduce(
      (count, option) => count - (option?.assignedDisplays_aggregate?.aggregate?.count ?? 0),
      totalDisplayCount,
    ) ?? 0
  if (loading) {
    return <Dropdown.Header icon={<Loader active />} className={styles.loader} />
  }

  return (
    <React.Fragment>
      {data?._options?.map(option => {
        const optionId = option.id ?? ''
        const optionCount = option?.assignedDisplays_aggregate?.aggregate?.count ?? 0
        const optionIsApplied = appliedFilters?.[categoryId]?.optionMatches?.[optionId]
        return (
          <Dropdown.Item
            key={optionId}
            onClick={(_e: React.SyntheticEvent<HTMLElement>, d: { value: string }) => {
              onOptionClick(d)
            }}
            className={classNames(styles.option, { gray: optionCount === 0 })}
            value={`${categoryId} ${optionId}`}
            content={
              <React.Fragment>
                {option.display_name || '-'}
                <Label horizontal className={styles.label} color={optionIsApplied ? 'blue' : undefined}>
                  {optionCount}
                </Label>
              </React.Fragment>
            }
          />
        )
      })}
      {(totalDisplayCount === 0 || unassignedCount > 0) && (
        <Dropdown.Item
          onClick={(_e: React.SyntheticEvent<HTMLElement>, _d: { value: string }) => {
            onOptionClick({ value: `${categoryId} unassigned` })
          }}
          value={categoryId}
          className={classNames(styles.option, { gray: unassignedCount === 0 })}
          content={
            <React.Fragment>
              Unassigned
              <Label horizontal className={styles.label} color={unassignedIsApplied ? 'blue' : undefined}>
                {unassignedCount}
              </Label>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  )
}
