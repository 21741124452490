import { AppliedCategoryFilters } from '../Hooks/useAppliedFilters'

export const filterExists = (
  appliedFilters: AppliedCategoryFilters,
  categoryInternalName: string,
  optionInternalName: string,
) => {
  const exists: boolean = Object.values(appliedFilters).some(filter => {
    const result
      = filter.categoryInternalName === categoryInternalName
      && filter.optionMatches
      && filter.optionMatches[optionInternalName]

    return !!result
  })
  return exists
}

export const filterExistsOnCategory = (
  appliedFilters: AppliedCategoryFilters,
  categoryInternalName: string,
) => {
  const exists: boolean = !!Object.values(appliedFilters).find(
    filter => filter.categoryInternalName === categoryInternalName,
  )
  return exists
}

export function addFilter(
  appliedFilters: AppliedCategoryFilters,
  categoryId: string,
  optionId: string,
  displayName?: string,
): AppliedCategoryFilters {
  if (filterExistsOnCategory(appliedFilters, categoryId)) {
    return {
      ...appliedFilters,
      [categoryId]: {
        categoryInternalName: categoryId,
        optionMatches: {
          ...(appliedFilters[categoryId].optionMatches || {}),
          [optionId]: { value: optionId, displayName },
        },
      },
    }
  }
  return {
    ...appliedFilters,
    [categoryId]: {
      categoryInternalName: categoryId,
      optionMatches: { [optionId]: { value: optionId, displayName } },
    },
  }
  
}

export function removeFilter(
  appliedFilters: AppliedCategoryFilters,
  categoryId: string,
  optionId: string,
): AppliedCategoryFilters {
  const optionMatches = appliedFilters[categoryId].optionMatches || {}
  const optionMatchCount = Object.keys(optionMatches).length
  if (optionMatchCount === 1) {
    const { [categoryId]: _existingCategory, ...rest } = appliedFilters
    return rest
  }
  const {
    [optionId]: _existingOptionMatch,
    ...restExistingOptions
  } = optionMatches

  return {
    ...appliedFilters,
    [categoryId]: {
      ...appliedFilters[categoryId],
      optionMatches: restExistingOptions,
    },
  }
  
}
