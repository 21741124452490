import React, { useState } from 'react'
import Chart from 'SolComponents/SolChart/Components/Chart'

import { TimeInterval } from 'graphql/__generated__/types'
import { formatTime } from 'SolComponents/SolChart/chartUtils'
import styles from './index.module.scss'
import { SolCheckbox } from 'SolComponents'
import { ViewAlertsChartResult } from 'graphql/__generated__/hasura-types'

type ParsedChartAlert = {
  interval: string
  out_of_template: number
  unreachable: number
  usb_removed_event: number
  restart: number
  usb_added_event: number
  deployed: number
  imported: number
}

type Props = {
  data: ViewAlertsChartResult[] | undefined
}

type OptionsType = {
  alertTypes: {
    id: string
    label: string
    visible: boolean
  }[]
  setAlertTypes: Function
}

const chartLines = [
  {
    id: 'UNREACHABLE',
    name: 'Pod unreachable',
    color: '#1CB5AC',
    checked: true,
    right: false,
  },
  {
    id: 'RESTARTED',
    name: 'Pod rebooted',
    color: '#2987CD',
    checked: true,
    right: false,
  },
  {
    id: 'OUT_OF_TEMPLATE',
    name: 'Pod unassigned from template',
    color: '#DE3E96',
    checked: true,
    right: false,
  },
  {
    id: 'USB_REMOVED_EVENT',
    name: 'USB device unplugged',
    color: '#F0712C',
    checked: true,
    right: false,
  },
  {
    id: 'USB_ADDED_EVENT',
    name: 'USB device plugged in',
    color: '#A23BC5',
    checked: true,
    right: false,
  },
  {
    id: 'DEPLOYED',
    name: 'Pod deployed',
    color: '#EAEAEA',
    checked: true,
    right: false,
  },
  {
    id: 'IMPORTED',
    name: 'Pod imported',
    color: '#2DB84B',
    checked: true,
    right: false,
  },
]

const alertNames = {
  out_of_template: 'Pod unassigned from template',
  unreachable: 'Pod unreachable',
  usb_removed_event: 'USB device unplugged',
  restart: 'Pod rebooted',
  usb_added_event: 'USB device plugged in',
  deployed: 'Pod deployed',
  imported: 'Pod imported',
}

const Options = ({ alertTypes, setAlertTypes }: OptionsType) => (
  <div className={styles.options}>
    {alertTypes.map(type => (
      <div key={type.id}>
        <SolCheckbox
          className={styles[`checkbox--${type.id}`]}
          label={type.label}
          checked={type.visible}
          onClick={() =>
            setAlertTypes(
              alertTypes.map(alertType => {
                if (alertType.id === type.id) {
                  return { ...alertType, visible: !alertType.visible }
                }
                return alertType
              }),
            )
          }
        />
      </div>
    ))}
  </div>
)

const AlertsHistoryChart = ({ data }: Props) => {
  const [alertTypes, setAlertTypes] = useState(
    chartLines.map(line => ({ id: line.id, label: line.name, visible: true })),
  )
  const chartData = data?.reduce((parsed: Array<ParsedChartAlert>, row) => {
    const rowDt = formatTime(TimeInterval.Day, new Date(row.dt))
    const alertName = alertNames[row.alert_type ?? 'alertType']
    if (parsed.some(parsedRow => parsedRow.interval === rowDt)) {
      return parsed.map(parsedRow => {
        if (parsedRow.interval === rowDt) {
          return {
            ...parsedRow,
            [alertName]: row.alerts,
          }
        }
        return parsedRow
      })
    }
    return [
      ...parsed,
      {
        interval: rowDt,
        [alertName]: row.alerts,
      },
    ]
  }, [])

  return (
    <div className={styles.alertsChart}>
      <Options alertTypes={alertTypes} setAlertTypes={setAlertTypes} />
      <Chart
        data={chartData || []}
        options={chartLines.filter(
          line => alertTypes.find(type => type.id === line.id)?.visible,
        )}
      />
    </div>
  )
}

export default AlertsHistoryChart
