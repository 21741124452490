import React, { useState, useEffect } from 'react'
import { DropdownItemProps, Icon, Loader } from 'semantic-ui-react'
import LoadingBar from 'components/Loaders/LoadingBar'
import { SolCard, SolAlertMessage, SolCardHeader, SolTooltip } from 'SolComponents'
import styles from './SSO.module.scss'
import authService from 'shared/services/auth'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { FormValidation } from 'shared/context/FormValidation'
import { useFormContext } from 'react-hook-form'
import CloudInfo from './CloudInfo'
import SSOForm from './SSOForm'
import { IDP } from './SSOForm'
import SsoLabel from './SsoLabel'
import { SolQuestionCircle } from 'SolComponents/Icons'

export const formatCertificate = (certString: string): string => {
  const formattedCert = certString
    .replace(/\n/g, '')
    .replace(/.*-----BEGIN CERTIFICATE-----/, '')
    .replace(/-----END CERTIFICATE-----.*/, '')
    .trim()
  return formattedCert
}

type Props = {
  orgData: any
  idps: DropdownItemProps[]
  ssoLoading: boolean
  updateOrg: (any: any) => {}
  showError: boolean
  setShowError: (error: boolean) => null
  setShowUpdateNotification: (showNotification: boolean) => null
  showUpdateNotification: boolean
}

const SSO = ({
  orgData,
  idps,
  ssoLoading,
  updateOrg,
  showError,
  setShowError,
  setShowUpdateNotification,
  showUpdateNotification,
}: Props) => {
  const { errors } = useFormContext()

  const org = localStorage.getItem('org')
  const baseUrl = authService.ssoUrl
  const spEntityId = `${baseUrl}/kepler/sp`

  const [title, setTitle] = useState('Add SSO')
  const [canEdit, setCanEdit] = useState(true)
  const [invalidCert, setInvalidCert] = useState(false)

  const [cert, setCert] = useState('')
  const [certName, setCertName] = useState('')
  const [idp, setIdp] = useState<IDP>({ id: -1, name: '', spURL: 'fake', placeholder: 'https://idp.com/login' })
  const [signOnURL, setSignOnURL] = useState('')
  const [idpEntityId, setIdpEntityId] = useState('')

  useEffect(() => {
    if (idps.length) {
      resetForm()
    }
  }, [idps])


  useEffect(() => {
    if (orgData) setCanEdit(false)
  }, [orgData])

  useEffect(() => {
    setInvalidCert(false)
  }, [cert])

  const submitSSO = () => {
    let checkedCert: string

    // run these checks/decode if the cert was uploaded
    if (certName) {
      if (!cert.includes('-----BEGIN CERTIFICATE-----')) {
        try {
          checkedCert = decodeURIComponent(atob(cert))
        } catch {
          // this is expected to break for "Raw" certs
          // unfortunately we won't know if a cert is raw or base64encoded until we try to decode it
          checkedCert = cert
        }
      } else {
        checkedCert = cert
      }
    } else {
      checkedCert = cert
    }

    const x509Cert = formatCertificate(checkedCert)

    if (idp.id > 0) {
      updateOrg({
        variables: {
          options: {
            idp_id: idp.id,
            idp_entity_id: idpEntityId,
            idp_url: signOnURL,
            ...(x509Cert.length && { idp_x509_cert: x509Cert }),
            sp_entity_id: spEntityId, // Solstice Cloud SSO URL
            sp_url: idp.spURL, // Solstice Cloud SSO URL Service provider?  that the same for all orgs ... people copy this into their IDP
          },
        },
      })
      setTitle(idp.name)
      // we reset the cert and cert name so that the cancel button will be disabled
      setCert('')
      setCertName('')
    }
  }

  // check if the form has been altered
  const checkForChange = (): boolean => {
    if (orgData?.idp_url) {
      return !!cert
      || !!certName
      || signOnURL !== orgData.idp_url
      || idpEntityId !== orgData.idp_entity_id
      || idp.id !== orgData.idp_id
    }
    return !!signOnURL || !!idpEntityId || !!cert || !!certName || idp.id !== -1
  }

  const saveButtonDisabled = !canEdit
    || Object.keys(errors).length > 0
    || (!orgData && !cert)
    || idp.id < 0
    || !checkForChange()

  const cancelButtonDisabled = (!orgData || !canEdit) && !checkForChange()

  const resetForm = () => {
    if (!ssoLoading && orgData?.idp_id) {
      setCanEdit(false)
      setSignOnURL(orgData?.idp_url)
      const idpInfo = idps.find(el => el.value === orgData?.idp_id)
      const text = (idpInfo?.text)?.toString() ?? ''
      setIdp({ id: orgData?.idp_id, name: text, spURL: idpInfo?.url, placeholder: idpInfo?.placeholder || '' })
      setIdpEntityId(orgData?.idp_entity_id)
      setTitle(text)
      setCert('')
      setCertName('')
    } else {
      setSignOnURL('')
      setIdpEntityId('')
      setCert('')
      setCertName('')
      setIdp({ id: -1, name: 'Add SSO', spURL: '', placeholder: idp.placeholder })
      setTitle('Add Single Sign-On Provider')
    }
  }

  return (
    <>
      <LoadingBar visible={ssoLoading} header />
      <div className={styles.header}>
        <span className={styles.grey}>Organization</span>
        <Icon name="angle right" color="grey" />
        <span>SSO</span>
      </div>
      {showUpdateNotification && (
        <SolAlertMessage
          message="SSO Saved"
          show={showUpdateNotification}
          type="success"
          onDismiss={() => setShowUpdateNotification(false)}
        />
      )}
      <SolAlertMessage
        message="Unable to process request"
        show={showError}
        type="error"
        onDismiss={() => setShowError(false)}
      />
      <ContentWrapper>
        <div className={styles.instructions}>
          <h1>SSO Setup Instructions</h1>
          <span>
            {/* eslint-disable-next-line max-len */}
            In order to setup SSO for your organization, choose <b>SAML 2.0</b> in your identity provider. Information listed under <b>Identify Provider Info</b> can be used for setup with your identity provider. Fill out the fields under <b>Solstice Cloud Info</b>, all of which are required. You may upload or copy your certificate information which will be stored in Solstice Cloud.
          </span>
        </div>
        {
          ssoLoading
            ? <Loader active size="big" className={styles.loader}>
              Loading...
            </Loader>
            : <SolCard className={styles.ssoCard}>
              <SolCardHeader
                submit={submitSSO}
                cancel={resetForm}
                cancelButtonDisabled={cancelButtonDisabled}
                saveButtonDisabled={saveButtonDisabled || ssoLoading}
                name={title}
              />
              <div className={styles.body}>
                <div className={styles.inputSection}>
                  <h3>Solstice Cloud info</h3>
                  {
                    canEdit
                      ? <SSOForm
                        cert={cert}
                        certName={certName}
                        idpDropdown={idps}
                        idp={idp}
                        idpEntityId={idpEntityId}
                        setIdpEntityId={setIdpEntityId}
                        ssoData={orgData}
                        ssoLoading={ssoLoading}
                        setIdp={setIdp}
                        signOnURL={signOnURL}
                        setSignOnURL={setSignOnURL}
                        setCert={setCert}
                        setCertName={setCertName}
                        invalidCert={invalidCert}
                      />
                      : <CloudInfo
                        idp={idp}
                        idpEntityId={idpEntityId}
                        setCanEdit={setCanEdit}
                        signOnURL={signOnURL}
                        certTimestamp={
                          orgData?.idp_cert_timestamp
                        }
                      />
                  }

                </div>
                <div className={styles.displaySection}>
                  <h3>Identity Provider info</h3>

                  <div className={styles.section}>
                    <SsoLabel
                      required
                      labelTitle="Service Provider URL"
                      tooltip={
                        `Also known as Single sign on URL. This is dynamic based on the selected Identity
                         Provider (IdP) (e.g., Okta). It is how the IdP redirects to our service.`
                      }
                    />
                    {idp.spURL || <span className={styles.placeHolder}>Based on the selected Identity Provider</span>}
                  </div>

                  <div className={styles.section}>
                    <SsoLabel
                      required
                      labelTitle="Service Provider Entity Id"
                      tooltip={
                        `An Entity ID is a globally unique name for a SAML entity, i.e., your Identity
                         Provider (IdP) or Service Provider (SP). It is how the IdP identifies our service.`
                      }
                    />
                    {spEntityId}
                  </div>
                  <h3 className={styles.attributeHeader}>Custom SAML Attributes
                    <span className={styles.icon}>
                      <SolTooltip
                        isInline
                        /* eslint-disable-next-line max-len */
                        text="Required attributes for Cloud. Add the name and value of each as an Attribute to your Identity Provider."
                        trigger={<SolQuestionCircle size="small" />}
                      />
                    </span>
                  </h3>
                  <div className={`${styles.section}, ${styles.attributesSection}`}>
                    <div>
                      <div className={styles.attributeLabel}>Name</div>
                      <div>orgId <span className={styles.required}>*</span></div>
                      <div>email <span className={styles.required}>*</span></div>
                    </div>
                    <div>
                      <div className={styles.attributeLabel}>Value</div>
                      <div>{org}</div>
                      <div>{idp.name === 'Azure' ? 'user.mail' : 'user.email'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SolCard>
        }
      </ContentWrapper>
    </>
  )
}

export default (props: any) => (
  <FormValidation>
    <SSO {...props} />
  </FormValidation>
)
