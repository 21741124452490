import {
  PreferredHdmInputResolution,
  SettingsGroupType,
  Template,
} from '../../../graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultAdvancedTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Advanced',
    type: SettingsGroupType.Advanced,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enableGatewayCheck: false,
      enableQos: false,
      qosVideoDscp: '101110',
      qosAudioDscp: '101000',
      enabledDailyRestart: false,
      dailyRestartTime: '02:00',
      enableHDCP: false,
      licenseAgree: false,
      lldp: {
        enabled: false,
        poeEnabled: false,
      },
      preferredHDMIInput: {
        enabled: false,
        resolution: PreferredHdmInputResolution._1080p,
      },
      host_name: '',
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
