import React from 'react'
import { Header, Icon, Input, Menu } from 'semantic-ui-react'
import { WithHelp } from '../../WithHelp/WithHelp'
import { focusAndSelectElement } from '../focusAndSelectElement'
import styles from './AddCategoryAddOptions.module.scss'

interface Props {
  cancel: () => void
  optionNames: string[]
  updateOptionValue: (index: number, displayName: string) => void
  addOption: () => void
  removeOption: (index: number) => void
}

const AddCategoryAddOptions = ({
  cancel,
  optionNames,
  updateOptionValue,
  addOption,
  removeOption,
}: Props) => {
  const optionInputRefs: HTMLInputElement[] = []
  const handleKeypress = (e: any, index: number) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    // ASSUMPTION: that there will always be at least one option passed into this component via props
    const lastOptionInput = optionInputRefs[optionInputRefs.length - 1]
    // Tab to next element if possible
    const currentRefIndex = optionInputRefs.indexOf(e.target)

    if ((e.key === 'Enter' || e.key === 'ArrowDown') && currentRefIndex < optionInputRefs.length - 1) {
      focusAndSelectElement(optionInputRefs[currentRefIndex + 1])
    } else if (e.key === 'ArrowUp' && currentRefIndex > 0) {
      focusAndSelectElement(optionInputRefs[currentRefIndex - 1])
    } else if (e.key === 'Escape') {
      cancel()
    } else if (
      e.key === 'Backspace'
      && optionNames[index] === ''
      && e.target.value === ''
      && optionNames.length > 1
    ) {
      // removeOption(option)
    } else if (e.target === lastOptionInput && e.target.value.trim() !== '') {
      addOption()
    }
  }

  const handleChange = (e: any, index: number) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    updateOptionValue(index, e.target.value)
  }

  const onRemoveOption = (index: number, inputRef: HTMLInputElement | null = null) => {
    if (inputRef && document.activeElement === inputRef) {
      const inputRefIndex = optionInputRefs.indexOf(inputRef)
      const nextSelectableIndex = inputRefIndex === 0 ? 1 : inputRefIndex - 1
      focusAndSelectElement(optionInputRefs[nextSelectableIndex])
    }
    removeOption(index)
  }

  const helpText = `Add the appropriate options for this category. When finished,
    you'll be able to assign one option to each Solstice instance.`

  return (
    <WithHelp
      component={() => (
        <div className={styles.scrollWrapper}>
          <div className={styles.contentWrapper}>
            <Header as="h5" content="OPTIONS" />
            <Menu vertical>
              {optionNames.map((optionName, index) => (
                <Menu.Item key={index}>
                  <Input
                    icon
                    name={index}
                    onKeyUp={(e: KeyboardEvent) => handleKeypress(e, index)}
                    onChange={e => handleChange(e, index)}
                    placeholder="Option"
                    autoFocus={index  ===  0}
                  >
                    <input
                      maxLength={25}
                      value={optionName ?? ''}
                      ref={ref => (optionInputRefs[index] = ref as HTMLInputElement)}
                    />
                    {optionName.length > 2 ? (
                      <Icon link name="close" onClick={() => onRemoveOption(index)} />
                    ) : (
                      false
                    )}
                  </Input>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      )}
      helpText={helpText}
    />
  )
}

export default AddCategoryAddOptions
