import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { Column, DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  Direction,
  DisplayOrderableField,
  SolsticeRoutingPod,
  SolsticeRoutingSpace,
  useSolsticeRoutingDisplaysQuery,
} from 'graphql/__generated__/types'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styles from './addpods.module.scss'
import classNames from 'classnames'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import {
  SolRadio,
  SolColorPalettePicker,
  SolPodName,
  SolButton,
} from 'SolComponents'
import { Icon } from 'semantic-ui-react'
import { SolAudioToggle } from '../../../SolComponents/SolRadioToggle/SolRadioToggle'

const MAX_PODS_PER_SPACE = 54

const SolsticeRoutingPalette = {
  LightGrey : '#b8bdbf',
  Violet : '#7080d4',
  LightBlue : '#7ac4e5',
  Red : '#fb413d',
  Orange : '#fb8048',
  Yellow : '#f3b11b',
  Green : '#61b425',
  Purple : '#945ecf',
  Pink : '#f36f8f',
  LimeGreen : '#1abe89',
  Blue : '#55a3d8',
  Grey : '#8f9fa5',
}

const isPrimaryPodValid = (space: SolsticeRoutingSpace) => {
  return space.primary_pod
    && space?.pods?.find(p => p?.id === space.primary_pod) !== undefined
}

export const spacePodsInvalid = (space: SolsticeRoutingSpace) => {
  return !space?.pods
    || space?.pods?.length < 2
    || !isPrimaryPodValid(space)
}
export interface Props {
    setCurrentTab: Dispatch<SetStateAction<string>>
    space: SolsticeRoutingSpace
    setSpace: Dispatch<SetStateAction<SolsticeRoutingSpace | undefined>>
    saveSpace: any
}

type PotentialRoutingPod = SolsticeRoutingPod & {
  isPrimaryPod: Boolean
  hardware: string
  ipAddressPrimary: string
}

const nextAvailableGridPosition = (pods: SolsticeRoutingPod[]) => {
  const [cols, rows] = [9, 6]
  const takenPositions = pods?.map(p => (p?.row || 0) * cols + (p?.column || 0)).sort()
  const remaining  = Array.from({ length: rows * cols }, (x, i) => i)
    .filter(pos => !takenPositions?.find(tp => tp === pos))
  const index = remaining.shift() ?? 0
  return {
    x: remaining[index] % cols,
    y: Math.floor(remaining[index] / cols),
  }
}

export const AddPodsTab = ({
  setCurrentTab,
  space,
  setSpace,
  saveSpace,
}: Props) => {

  const {
    searchValue,
    page,
    orderBy,
    // selectedItems,
    setSelectedItems,
  } = useDataTable<PotentialRoutingPod>
  (DataTableInstance.SolsticeRoutingAddPods)

  const { data: keplerPods, loading: keplerPodsLoading } = useSolsticeRoutingDisplaysQuery({
    variables: { options: {
      page: page.number,
      records:  page.size,
      searchTerm: searchValue,
      orderBy: {
        direction: orderBy?.direction === 'ascending' ? Direction.Asc : Direction.Desc,
        field: orderBy?.field.toUpperCase() as DisplayOrderableField,
      },
    } },
    fetchPolicy: 'network-only',
  })

  // ensure the page of pods gets their space_pod related preexisting data attached 
  const [visiblePods, setVisiblePods] = useState<PotentialRoutingPod[]>([])

  const repopulateVisiblePods = (primaryPodId?: string) => {
    setVisiblePods( keplerPods?.displays?.items?.map(kpod => {
      const spacePod = space?.pods?.find(sPod => sPod?.id === kpod?.id)
      return {
        ...spacePod,
        name: kpod?.name,
        id: kpod?.id,
        isPrimaryPod: (primaryPodId === kpod?.id),
        hardware: kpod?.versions.hardware,
        ipAddressPrimary: kpod?.ipAddressesPrimary,
      } as PotentialRoutingPod
    }) ?? [])
  }

  /**
   * This was an attempt to check the boxes of pods already attached to this pre existing space
   */
  useEffect(() => {
    if (space?.pods) {
      setSelectedItems(
        {
          items: space?.pods as PotentialRoutingPod[],
          ids: space?.pods.map(sp => sp?.id),
        },
      )
      repopulateVisiblePods(space?.primary_pod ?? '')
    }
  }, [space, keplerPods])
    
  const allPodsTotal = keplerPods?.displays?.totalRecords ?? 0

  const columns: Column<PotentialRoutingPod>[] = [
    {
      name: 'name',
      displayName: 'Pod Name',
      centered: false,
      render: (r: PotentialRoutingPod) => {
        const hardware = r?.hardware?.replace('Pod ', '') ?? ''
        const ip = r.ipAddressPrimary ?? ''
        return (<SolPodName
          id={r.id ?? ''}
          name={r.name ?? ''}
          ip={`${hardware} ${hardware && ip ? '|' : ''} ${ip}`}
        />)
      },
    },
    {
      name: 'color',
      displayName: 'Color',
      centered: true,
      sortable: false,
      render: (pod: PotentialRoutingPod) => (
        space?.pods?.map(p => p?.id ?? null).includes(pod.id ?? null)
          && <span className={styles.addPodsColorPicker}>
            <SolColorPalettePicker
              colors={Object.values(SolsticeRoutingPalette)}
              color={pod?.color || '#b8bdbf'}
              setter={color => {
                setSpace({ ...space, pods: space?.pods?.map(p =>
                  (p?.id === pod.id ? { ...p, color: color } : p),
                ) })
              }}
            /></span>
      ),
    },
    {
      name: 'primary_display',
      tooltipText: 'This will not apply if users login with SSO.',
      displayName: 'Primary Pod',
      centered: true,
      sortable: false,
      render: (pod: PotentialRoutingPod) => (
        space?.pods?.map(p => p?.id ?? null).includes(pod.id ?? null)
        && <SolRadio
          className={styles.primaryPodRadio}
          checked={!!pod?.isPrimaryPod}
          label=""
          onClick={val => {
            if (val) {
              setSpace({ ...space, primary_pod: pod.id })
              repopulateVisiblePods(pod?.id ?? '')
            }
          }}
        />
      ),
    },
    {
      name: 'audio_output',
      tooltipText: 'Choose which Pods will output audio when routing content to them.',
      displayName: 'Audio Output',
      centered: true,
      sortable: false,
      render: (pod: PotentialRoutingPod) => (
        space?.pods?.map(p => p?.id ?? null).includes(pod.id ?? null)
        && <SolAudioToggle
          checked={pod?.audio_routing_enabled ?? false}
          onClick={() => {
            setSpace({ ...space, pods: space?.pods?.map(p =>
              (p?.id === pod.id
                ? { ...p, audio_routing_enabled: !p?.audio_routing_enabled } : p
              ),
            ) })
          }}
        />
      ),
    },
  ]

  const podsTotalManager = () => {
    return (
      <div className={styles.podsTally}>
        <span>{space?.pods?.length}/{keplerPods?.displays?.totalRecords} </span>
        <span> Pods Selected</span>
      </div>
    )
  }

  return (
    <ContentWrapper>
      <div>
        <div className={styles.header}>Add and Configure Pods</div>
        <div className={styles.subHeader}>
          Select pods to add the space and assign colors so moderators can
          distinguish them in the app.
        </div>
        <div
          className={classNames(
            styles.instructions,
            { [styles.instructionsInvalid]: spacePodsInvalid(space) },
          )}
        >
          Your space must have:
        </div>
        <ul className={styles.instructionsList}>
          <li className={classNames({ [styles.atLeastTwoPods]:
            space?.pods && space?.pods?.length > 1 })}>
            At least two pods
            <Icon
              name="check circle"
              color="green"
              className={classNames({ [styles.hideCheck]:
                space?.pods && space?.pods?.length < 2 })}
            />
          </li>
          <li className={classNames({ [styles.onePrimaryPod]: isPrimaryPodValid(space) })}>
            One primary pod
            <Icon
              name="check circle"
              color="green"
              className={classNames({ [styles.hideCheck]: !isPrimaryPodValid(space) })}
            />
          </li>
        </ul>

      </div>

      <div data-testid="add-pods-table" className={styles.addPodsTable}>
        {ServerDataTable<PotentialRoutingPod>({
          selectable(row: PotentialRoutingPod) {
            return ( !!space?.pods
              && ( space?.pods?.length < MAX_PODS_PER_SPACE
              || !!space?.pods.find(pod => pod?.id === row.id) )
            )
          },
          defaultNoDataMessage: `Solstice Routing is not enabled on any Pods
           in your organization. Please contact your Mersive representative to upgrade.`,
          onSelect: (selected => {
            const updatedSpace = {
              ...space,
              pods: selected?.includedIds?.
                filter( id => id !== undefined && id !== null).
                map( id => {
                  const existingSpacePod = space?.pods?.find(pod => pod?.id === id)
                  const justSelectedPod = visiblePods?.find(pod => pod?.id === id)
                  const addedPod = existingSpacePod || justSelectedPod
                  const pos = nextAvailableGridPosition(space.pods as SolsticeRoutingPod[] )
                  return { ...addedPod,
                    color: addedPod?.color ?? '#b8bdbf',
                    column: addedPod?.column ?? pos.x,
                    row: addedPod?.row ?? pos.y,
                  }
                }),
            }
            setSpace(updatedSpace)
          }),
          searchable: true,
          addCategoryCols: false,
          addableColumns: false,
          allowExportToCsv: false,
          columns: columns,
          data: visiblePods ?? [],
          loading: keplerPodsLoading,
          padded: false,
          id: DataTableInstance.SolsticeRoutingAddPods,
          title: '',
          totalItems: allPodsTotal,
          columnManager: podsTotalManager,
        })}
      </div>

      <div className={styles.buttonHolder}>
        {
          !space?.id
            ? <SolButton
              text="Back"
              className={styles.addPodsBackButton}
              handleClick={() => { setCurrentTab('type') }}
            /> : <div></div> // dummy div lets space-between push Save button right
        }
        <SolButton
          text={space && space?.id ? 'Save' : 'Next'}
          color="blue"
          className={styles.addPodsNextButton}
          handleClick={() =>
            (space && space?.id ? saveSpace() : setCurrentTab('design'))
          }
        />
      </div>
    </ContentWrapper>
  )
}
