import React from 'react'
import {
  Template,
  FeaturesConfiguration,
  InputMode,
} from 'graphql/__generated__/types'
import { SolRadio } from 'SolComponents/index'
import styles from '../Features.module.scss'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: FeaturesConfiguration) => void
}

function HDMIInput({ updateConfiguration, template }: Props) {
  const configuration = template.revision.configuration as FeaturesConfiguration

  return (
    <div className={styles.radioButtons}>
      <div>
        <SolRadio
          label="Standard post"
          checked={configuration.hdmiInputMode === InputMode.Standard}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              hdmiInputMode: InputMode.Standard,
            })
          }}
        />
      </div>
      <div>
        <SolRadio
          label="Persistent post"
          checked={configuration.hdmiInputMode === InputMode.Persistent}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              hdmiInputMode: InputMode.Persistent,
            })
          }}
        />
      </div>
    </div>
  )
}

export default HDMIInput
