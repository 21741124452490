import React from 'react'

import styles from './SolNotification.module.scss'
import { Icon } from 'semantic-ui-react'
import classNames from 'classnames'

export interface Props {
  type?: 'info' | 'menu' | 'warning' | 'beta'
  notifications?: number | string | JSX.Element
  className?: string
}

function SolNotification({ type, notifications, className }: Props) {
  if (type === 'warning') {
    return (
      <Icon
        className={classNames(styles.warning, className)}
        color="red"
        name="exclamation circle"
      />
    )
  }
  return (
    <div className={classNames(
      styles[type || 'info'],
      styles.notification,
      className,
    )}>
      {notifications}
    </div>
  )
}

export default SolNotification
