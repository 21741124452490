import React, { useState, useEffect } from 'react'
import styles from './Location.module.scss'
import {
  useLocationsPageMapQuery,
  useLocationsPageQuery,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { AppliedCategoryFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import Filter from 'components/DataTableSlim/Filter'
import { Nullable } from 'shared/types/common'
import { getTotalItemsSelected } from 'components/DataTableSlim/Utils'
import { SelectedItems } from 'components/DataTableSlim/types'

type Props = {
  searchParamsWhere: Nullable<ViewDisplaysBoolExp>
  currentFilters: AppliedCategoryFilters
  clearVisiblePods: () => void
  filterbyVisiblePods: boolean
  selectedItems: SelectedItems
  searchValue: string
  setSearchValue: (searchValue: string) => void

  buildingFloorMap: { [key: number]: string }
  setBuildingFloorMap: (e: { [key: number]: string }) => void
}

const FilterHeader = (props: Props) => {
  const [idBuildingName, setIdBuildingName] = useState<{ [key: number]: string }>({})
  const { data: locationPageData } = useLocationsPageQuery({
    variables: { where: props.searchParamsWhere },
  })

  const { data } = useLocationsPageMapQuery({
    variables: {
      where: props.searchParamsWhere,
    },
  })

  useEffect(() => {
    if (data) {
      const locationData = data?._displays
      let map: { [key: number]: string } = {}
      locationData.forEach(display => {
        const addressID = display.location?.address?.id ?? null
        const nickname = display.location?.address?.nickname ?? display.location?.address?.building ?? null
        if (addressID && nickname) {
          map[addressID] = nickname
        }
      })
      setIdBuildingName(map)
    }
  }, [data])

  const TotalItems = locationPageData?.filteredDisplayCount?.aggregate?.count ?? 0
  const selectedPodCount = getTotalItemsSelected(props.selectedItems, TotalItems)

  const VisiblePodsFilter = () => {
    if (props.filterbyVisiblePods) {
      return (
        <>
          <Filter
            key="VisibleOnMap"
            optionDisplayName="Visible on Map"
            color="grey"
            className={styles.pill}
            onRemoveFilter={() => props.clearVisiblePods()}
          />
        </>
      )
    }
    return <div></div>
    
  }

  const FloorFilter = () => {
    if (Object.keys(props.buildingFloorMap).length !== 0) {
      const filters = Object.keys(props.buildingFloorMap).map(val => {
        return (
          <Filter
            key="floor"
            optionDisplayName={idBuildingName[parseInt(val)] + ' Floor ' + props.buildingFloorMap[val]}
            color="grey"
            className={styles.pill}
            onRemoveFilter={() => {
              props.setBuildingFloorMap({})
            }}
          />
        )
      })
      return <>{filters}</>
    }
    return <div></div>
    
  }

  return (
    <SolFilterHeaderCard
      header="Location"
      filteredItems={selectedPodCount}
      tableId={DataTableInstance.Locations}
      customFilter={
        <>
          <VisiblePodsFilter />
          <FloorFilter />
        </>
      }
      searchValue={props.searchValue}
      setSearchValue={props.setSearchValue}
    />
  )
}

export default FilterHeader
