import React from 'react'
import styles from './Calendar.module.scss'
import {
  AuthenticationType,
  CalendarConfiguration,
  CalendarType, PlatformConfigDocument, PlatformConfigQuery,
  Template,
} from 'graphql/__generated__/types'
import { SolCheckbox, SolDropdown } from 'SolComponents'
import { DropdownItemProps } from 'semantic-ui-react'
import ExchangeSettings from './ExchangeSettings'
import { Nullable } from 'shared/types/common'
import GoogleSettings from './GoogleSettings'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: CalendarConfiguration) => void
  podId?: string
}

type CalendarTypeDropdownValue =
  | CalendarType.Custom
  | CalendarType.Exchange
  | CalendarType.Office365
  | CalendarType.Google

function getCalendarType(type?: CalendarType | null): Nullable<CalendarTypeDropdownValue> {
  if (type === CalendarType.Office365Oauth) {
    return CalendarType.Office365
  }
  return type
}

function Calendar({ template, updateConfiguration, podId }: Props) {
  const configuration = template.revision.configuration as CalendarConfiguration
  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const calendarTypeOptionsWithGoogle: DropdownItemProps[] = [
    { value: CalendarType.Exchange, text: 'Microsoft Exchange' },
    { value: CalendarType.Office365, text: 'Microsoft 365 Online' },
    { value: CalendarType.Custom, text: '3rd Party Only' },
    {
      value: CalendarType.Google,
      text: 'Google Calendar (v5.2+)',
    },
  ]

  const updateIntervalOptions: DropdownItemProps[] = [
    { value: 5, text: '5s' },
    { value: 10, text: '10s' },
    { value: 30, text: '30s' },
    { value: 60, text: '1m' },
    { value: 120, text: '2m' },
    { value: 300, text: '5m' },
    { value: 600, text: '10m' },
  ]

  const labelText = `Enable Calendar Feature${hasElementPods ? '*' : ''}`

  return (
    <div className={styles.templateWrapper}>
      <SolCheckbox
        bold
        label={labelText}
        checked={!!configuration.enabled}
        onClick={enabled => updateConfiguration({ ...configuration, enabled })}
      />
      {configuration.enabled && (
        <div className={styles.calendarSettings}>
          <SolDropdown
            type="caret"
            direction="left"
            options={calendarTypeOptionsWithGoogle}
            label="Calendar Type"
            value={getCalendarType(configuration.type)}
            onChange={(type: CalendarType) => updateConfiguration({ ...configuration, type })}
            size="medium-large"
          />
          {configuration.type !== CalendarType.Custom && configuration.type !== CalendarType.Google && (
            <ExchangeSettings
              calendarType={configuration.type}
              exchangeSettings={configuration?.exchangeSettings ?? {}}
              template={template}
              setExchangeSettings={newSettings => {
                let type = configuration?.type
                let authenticationType = newSettings?.authenticationType ?? AuthenticationType.Basic
                if (authenticationType === AuthenticationType.Oauth && configuration?.type === CalendarType.Office365) {
                  type = CalendarType.Office365Oauth
                  authenticationType = AuthenticationType.Basic
                } else if (
                  authenticationType !== AuthenticationType.Oauth
                  && configuration?.type === CalendarType.Office365Oauth
                ) {
                  type = CalendarType.Office365
                }

                updateConfiguration({
                  ...configuration,
                  type,
                  exchangeSettings: {
                    ...configuration.exchangeSettings,
                    ...newSettings,
                    authenticationType,
                  },
                })
              }}
              podId={podId}
              office365Settings={configuration?.office365Settings ?? {}}
              setOffice365Settings={newSettings =>
                updateConfiguration({
                  ...configuration,
                  exchangeSettings: {
                    ...configuration.exchangeSettings,
                  },
                  office365Settings: {
                    ...configuration.office365Settings,
                    ...newSettings,
                  },
                })
              }
            />
          )}
          {configuration.type === CalendarType.Google && (
            <GoogleSettings
              setGoogleSettings={newSettings => {
                updateConfiguration({
                  ...configuration,
                  googleSettings: {
                    ...configuration.googleSettings,
                    ...newSettings,
                  },
                })
              }}
              template={template}
              podId={podId}
              googleSettings={configuration?.googleSettings ?? {}}
            />
          )}
          <div className={styles.newRow}>
            <SolCheckbox
              label="Show calendar overlay"
              checked={!!configuration.showOverlay}
              onClick={showOverlay => updateConfiguration({ ...configuration, showOverlay })}
            />
            <SolCheckbox
              label="Show meeting titles"
              checked={!!configuration.showTitles}
              onClick={showTitles => updateConfiguration({ ...configuration, showTitles })}
            />
            <SolCheckbox
              label="Show meeting organizers"
              checked={!!configuration.showOrganizers}
              onClick={showOrganizers => updateConfiguration({ ...configuration, showOrganizers })}
            />
            <SolDropdown
              type="caret"
              direction="left"
              options={updateIntervalOptions}
              label="Update Interval"
              value={configuration.updateInterval}
              onChange={updateInterval =>
                updateConfiguration({
                  ...configuration,
                  updateInterval: Number(updateInterval),
                })
              }
              size="tiny"
            />
          </div>
        </div>
      )}
      {hasElementPods
        && (
          <div className={styles.spanMargin}>
            <span>* Certain features are not available for Solstice Element.</span>
          </div>
        )
      }
    </div>
  )
}

export default Calendar
