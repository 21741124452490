import { TimeInterval } from 'graphql/__generated__/types'
import { ChartTimePeriod } from './types'
import { format, startOfHour, startOfDay, addDays } from 'date-fns'

export interface ChartMetric {
  interval: TimeInterval
  timePeriod: ChartTimePeriod
  onChangeTimeInterval: (timeInterval: TimeInterval) => void
  onChangeTimePeriod: (timePeriod: ChartTimePeriod) => void
}

export enum FillColor {
  Green = '#66cc33',
  Red = '#bf1244'
}

export const defaultPalette = [
  '#643CC6',
  '#2987CD',
  '#B5CA31',
  '#1CB5AC',
  '#66cc33',
  '#fbb812',
  '#F0712C',
  '#D92B2F',
]

export const formatTime = (
  timeInterval: TimeInterval,
  rawBucketDate: Date,
  rawEndDate?: Date,
): string => {
  const bucketDate = new Date(rawBucketDate)
  const endDate = rawEndDate ? new Date(rawEndDate) : new Date()

  switch (timeInterval) {
    case TimeInterval.Hour:
      return format(bucketDate, 'EEE h:mm a')
    case TimeInterval.Day:
      return format( bucketDate, 'MMM d')
    case TimeInterval.Week: {
      const aWeekFromThen = addDays(rawBucketDate, 6)

      let lastDayOfWeek
        = aWeekFromThen.valueOf() > endDate.valueOf() ? endDate : aWeekFromThen

      if (
        rawEndDate
        && format(bucketDate, 'MMM') === format(lastDayOfWeek, 'MMM')
      ) {
        return format(bucketDate, 'MMM d - ') + format(lastDayOfWeek, 'd')
      }

      return format(bucketDate, 'MMM d - ') + format(lastDayOfWeek, 'MMM d')
    }
    case TimeInterval.Month:
      return format(bucketDate, 'MMM')
    default:
      return ''
  }
}

export function getPreviousDate(
  timePeriod: ChartTimePeriod,
  initialDate: Date,
): Date {
  switch (timePeriod) {
    case ChartTimePeriod.HOURS: {
      return addDays(startOfHour(new Date(initialDate) ), -2)
    }
    case ChartTimePeriod.WEEK: {
      return addDays(startOfDay(new Date(initialDate) ), -7)
    }
    case ChartTimePeriod.MONTH: {
      return addDays(startOfDay(new Date(initialDate) ), -30)
    }
    case ChartTimePeriod.QUARTER: {
      return addDays(startOfDay(new Date(initialDate) ), -90)
    }
    default:
      return new Date(initialDate)
  }
}

export function getNextDate(
  timePeriod: ChartTimePeriod,
  initialDate: Date,
): Date {
  switch (timePeriod) {
    case ChartTimePeriod.HOURS: {
      return addDays(startOfHour(new Date(initialDate) ), 2)
    }
    case ChartTimePeriod.WEEK: {
      return addDays(startOfDay(new Date(initialDate) ), 7)
    }
    case ChartTimePeriod.MONTH: {
      return addDays(startOfDay(new Date(initialDate) ), 30)
    }
    case ChartTimePeriod.QUARTER: {
      return addDays(startOfDay(new Date(initialDate) ), 90)
    }
    default:
      return new Date(initialDate)
  }
}
