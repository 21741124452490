import React from 'react'
import { UsbTable } from './Table/UsbTable'
import styles from './UsbDevices.module.scss'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { UsbDevicesCountCard } from './Cards/UsbDevicesCount/UsbDevicesCountCard'
import { UsbDeviceAvailabilityCard } from './Cards/UsbDeviceAvailability/UsbDeviceAvailabilityCard'
import { UsbDeviceProductsCard } from './Cards/UsbDeviceProducts/UsbDeviceProductsCard'
import { UsbDeviceHistoryCard } from './Cards/UsbDeviceHistory/UsbDeviceHistoryCard'
import { SemanticCOLORS } from 'semantic-ui-react'
import { UsbDevice, UsbDeviceHistory, UsbProduct } from 'graphql/__generated__/types'

type Props = {
  addProductFilter: (id: number, name: string) => void
  addAvailabilityFilter: (
    availability: boolean, displayName: string, color: SemanticCOLORS
    ) => void
  addAvDeviceFilter: (value: boolean, displayName: string) => void
  tableData?: UsbDevice[] | undefined
  usbDeviceHistory?: UsbDeviceHistory[] | undefined
  plugCountSum: number
  unplugCountSum: number

  podsWithDevicesCount: number
  totalDevicesCount: number
  totalAvDevicesCount: number
  totalOtherDevicesCount: number
  usbOnlineDeviceCount: number
  usbOfflineDeviceCount: number
  totalPodsCount: number
  usbDevicesDataLoading: boolean

  productData: UsbProduct[]
  productConnectionsCount: number
}

const UsbPage = React.memo((
  {
    addProductFilter,
    addAvailabilityFilter,
    addAvDeviceFilter,
    tableData,
    usbDeviceHistory,

    podsWithDevicesCount,
    totalDevicesCount,
    totalAvDevicesCount,
    totalOtherDevicesCount,
    totalPodsCount,
    usbDevicesDataLoading,

    usbOnlineDeviceCount,
    usbOfflineDeviceCount,
    plugCountSum,
    unplugCountSum,
    
    productData,
    productConnectionsCount,

  }: Props ) => {
  return (
    <div className={styles.usbPage}>
      <SolFilterHeaderCard
        header="USB Devices Page"
        tableId={DataTableInstance.Usb}
        subHeaderDisabled
      />
      <ContentWrapper>
        <div className={styles.cards}>
          <div className={styles.top}>
            <UsbDevicesCountCard
              addAvDeviceFilter={addAvDeviceFilter}
              podsWithDevicesCount={podsWithDevicesCount}
              totalDevicesCount={totalDevicesCount}
              totalAvDevicesCount={totalAvDevicesCount}
              totalOtherDevicesCount={totalOtherDevicesCount}
              totalPodsCount={totalPodsCount}
            />
            <UsbDeviceAvailabilityCard
              addAvailabilityFilter={addAvailabilityFilter}
              usbOnlineDeviceCount={usbOnlineDeviceCount}
              usbOfflineDeviceCount={usbOfflineDeviceCount}
              usbDevicesDataLoading={usbDevicesDataLoading}
            />
            <UsbDeviceProductsCard
              addProductFilter={addProductFilter}
              productData={productData}
              productConnectionsCount={productConnectionsCount}
            />
          </div>
          <UsbDeviceHistoryCard
            usbDeviceHistory={usbDeviceHistory}
            plugCountSum={plugCountSum}
            unplugCountSum={unplugCountSum}
          />
        </div>
        <UsbTable
          tableData={tableData || []}
          tableDataLoading={usbDevicesDataLoading}
          totalItems={totalDevicesCount}
        />
      </ContentWrapper>
    </div>
  )
})

export default UsbPage
