import React, { SetStateAction, Dispatch, Suspense, lazy, useState } from 'react'
import styles from './WelcomeScreen.module.scss'
import { Header, Form, Loader } from 'semantic-ui-react'
import { SolColorPicker, SolCheckbox, SolColumn, SolGrid, SolRow, SolNotification } from 'SolComponents'
import {
  WelcomeScreenConfiguration,
  HdmiOutputModeType,
  InstructionsDisplayType,
  Template,
  ConfigurationSetting,
} from 'graphql/__generated__/types'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import { useFlags } from 'launchdarkly-react-client-sdk'

import 'react-quill/dist/quill.snow.css'
// @ts-ignore
const ReactQuill = lazy(() => import('react-quill'))

import { SolDropdown } from 'SolComponents'
import { dropdownCompatibility } from 'shared/domain/compatibility'
import { hdmiOptions, instructionOptions, notAvailableForElementPods } from './WelcomeScreen.types'
import defaultCustomInstructions from './defaultCustomInstructions.html'
import SolRadio from '../../../SolComponents/SolRadio/SolRadio'

export interface Props {
  template: Template
  setter: Dispatch<SetStateAction<WelcomeScreenConfiguration>>
  hasElementPods: boolean|null|undefined
}

const quillModules = {
  toolbar: [
    ['bold', 'italic', { color: [] }],
    [{ list: 'bullet' }, { list: 'ordered' }, 'blockquote'],
    [{ align: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const quillFormats = ['bold', 'italic', 'color', 'list', 'bullet', 'blockquote', 'align']

function WelcomeScreenOptions(props: Props) {
  const {
    hdmiOutput,
    customInstructions,
    showAirplay,
    showMiracast,
    instructions,
    pbShowPresenceBar,
    pbShowRoomName,
    pbShowScreenKey,
    pbShowIPAddress,
    showRoomName,
    showClock,
    screenKey,
    textColor,
    pbPersist,
    showWifi,
    showHostnameInsteadOfIp,
    shouldAnimateBackground,
    showFullyQualifiedName,
  } = props.template.revision.configuration as WelcomeScreenConfiguration

  const settings = (props.template.settingsGroup?.settings ?? []) as ConfigurationSetting[]
  const aggregate = props.template.assignedDisplays?.compatibilityAggregate

  let hdmiProps = dropdownCompatibility(hdmiOptions, aggregate, settings)

  if (props.hasElementPods) {
    hdmiProps = hdmiProps.map(hp => {
      if (notAvailableForElementPods.includes(hp.value)) {
        return {
          ...hp,
          text: `${hp.text}*`,
        }
      }

      return hp
    })
  }

  const instructionProps = dropdownCompatibility(instructionOptions, aggregate, settings)

  const [hostnameCheckbox, setHostnameCheckbox] = useState((showHostnameInsteadOfIp || showFullyQualifiedName) ?? false)

  const hostnameText
    = 'Solstice will query DNS to find a hostname associated'
    + ' with the display’s IP address. If found, the hostname will be'
    + ' used as the URL for the quick-connect page. Note: if the display is using'
    + ' dual networks, both IP addresses must resolve to the same name.'

  const { showWifiStrength: SHOW_WIFI = true } = useFlags()

  return (
    <React.Fragment>
      <SolColumn className={styles.middleColumnWrapper} xl={3} lg={3} sm={6}>
        <SolTooltip
          text={<div>Choose how you would like monitors to behave when connected to a Gen3 Pod with dual HDMI output.
            <strong> Extended mode</strong> will reflect when sharing to the pod but the Welcome Screen will remain
            <strong> mirrored</strong>.</div>}
          hoverable
          position="top left"
          trigger={<Header size="small">Display Options</Header>}
        />
        <SolDropdown
          type="caret"
          direction="left"
          options={hdmiProps}
          value={hdmiOutput}
          onChange={val => {
            props.setter({
              ...props.template.revision.configuration,
              hdmiOutput: val as HdmiOutputModeType,
            })
          }}
          size="fluid"
        />
        <SolTooltip
          text={`This is the instructions overlay on the right side of the welcome screen.
            It provides users with step-by-step instructions for connecting to the display.
            You can toggle its visibility with this setting.`}
          hoverable
          position="top left"
          trigger={<Header size="small">Instructions</Header>}
        />
        <SolDropdown
          type="caret"
          direction="left"
          options={instructionProps}
          value={instructions}
          onChange={val => {
            props.setter({
              ...props.template.revision.configuration,
              instructions: val as InstructionsDisplayType,
            })
          }}
          size="fluid"
        />
        <SolTooltip
          text="If enabled, this will display AirPlay specific instructions in the overlay."
          hoverable
          position="top left"
          trigger={
            <SolCheckbox
              disabled={instructions === InstructionsDisplayType.Hide}
              checked={instructions !== InstructionsDisplayType.Hide && !!showAirplay}
              label="Show Airplay instruction line"
              onClick={value => {
                props.setter({
                  ...props.template.revision.configuration,
                  showAirplay: value,
                })
              }}
            />
          }
        />
        <SolTooltip
          text="If enabled, this will display Miracast specific instructions in the overlay."
          hoverable
          position="top left"
          trigger={
            <SolCheckbox
              disabled={instructions === InstructionsDisplayType.Hide}
              checked={instructions !== InstructionsDisplayType.Hide && !!showMiracast}
              label="Show Miracast instruction line"
              onClick={value => {
                props.setter({
                  ...props.template.revision.configuration,
                  showMiracast: value,
                })
              }}
            />
          }
        />
        {instructions === InstructionsDisplayType.ShowCustom && (
          <React.Fragment>
            <SolTooltip
              text={`Customize the connection instructions that users will see on the welcome screen.
            You can embed certain key words that will automatically be translated into
            room-specific information. For example: [RoomName] will be converted to the name
            for that room in the instruction text.`}
              hoverable
              position="top left"
              trigger={
                <div className={styles.helpText}>
                  <span>Customize the text in your instructions:</span>
                  <p>
                    <strong>[RoomName], [ScreenKey], [WifiNetworkName], [WifiIP], [EthNetworkName], [EthIP]</strong>
                  </p>
                </div>
              }
            />
            <Form>
              <Suspense fallback={<Loader active inline />}>
                <ReactQuill
                  theme="snow"
                  readOnly={instructions !== InstructionsDisplayType.ShowCustom}
                  value={
                    customInstructions && customInstructions.length > 0 ? customInstructions : defaultCustomInstructions
                  }
                  onChange={(data: any) => {
                    props.setter({
                      ...props.template.revision.configuration,
                      customInstructions: data,
                    })
                  }}
                  modules={quillModules}
                  formats={quillFormats}
                />
              </Suspense>
            </Form>
          </React.Fragment>
        )}
      </SolColumn>
      <SolColumn xl={3} lg={7} sm={6}>
        <SolGrid>
          <SolRow>
            <SolColumn xl={12} lg={6} sm={12}>
              <Header size="small">Footer Options</Header>
              <SolCheckbox
                checked={!!pbShowPresenceBar}
                label="Show footer on Welcome Screen"
                onClick={value => {
                  const newSettings = !value
                    ? {
                      ...props.template.revision.configuration,
                      pbShowPresenceBar: false,
                    }
                    : {
                      ...props.template.revision.configuration,
                      pbShowPresenceBar: true,
                      pbShowRoomName: true,
                      pbShowIPAddress: true,
                      pbShowScreenKey: true,
                    }
                  props.setter(newSettings)
                }}
              />
              <SolCheckbox
                disabled={!pbShowPresenceBar}
                checked={!!pbShowPresenceBar && !!pbShowRoomName}
                label="Show room name in footer"
                onClick={value => {
                  props.setter({
                    ...props.template.revision.configuration,
                    pbShowRoomName: value,
                  })
                }}
              />
              <SolCheckbox
                disabled={!pbShowPresenceBar}
                checked={!!pbShowPresenceBar && !!pbPersist}
                label="Persist footer"
                onClick={value => {
                  props.setter({
                    ...props.template.revision.configuration,
                    pbPersist: value,
                  })
                }}
              />
              <SolCheckbox
                disabled={!pbShowPresenceBar}
                checked={!!pbShowPresenceBar && !!pbShowIPAddress}
                label="Show IP address in footer"
                onClick={value => {
                  props.setter({
                    ...props.template.revision.configuration,
                    pbShowIPAddress: value,
                  })
                }}
              />
              <SolCheckbox
                disabled={!pbShowPresenceBar}
                checked={!!pbShowPresenceBar && !!pbShowScreenKey}
                label="Show screen key in footer"
                onClick={value => {
                  props.setter({
                    ...props.template.revision.configuration,
                    pbShowScreenKey: value,
                  })
                }}
              />
            </SolColumn>
            <SolColumn xl={12} lg={6} sm={12}>
              <Header size="small">Main Screen Options</Header>
              <SolTooltip
                text="When enabled, the current time will be shown on the splash screen."
                hoverable
                position="top left"
                trigger={
                  <SolCheckbox
                    checked={!!showClock}
                    label="Show clock"
                    onClick={value => {
                      props.setter({
                        ...props.template.revision.configuration,
                        showClock: value,
                      })
                    }}
                  />
                }
              />
              {SHOW_WIFI && (
                <SolTooltip
                  text="Choose whether the WiFi signal strength should be visible on the welcome screen."
                  hoverable
                  position="top left"
                  trigger={
                    <SolCheckbox
                      checked={!!showWifi}
                      label="Show WiFi signal strength indicator"
                      onClick={value => {
                        props.setter({
                          ...props.template.revision.configuration,
                          showWifi: value,
                        })
                      }}
                    />
                  }
                />
              )}
              <SolTooltip
                text="Choose whether the room name should be visible on the welcome screen."
                hoverable
                position="top left"
                trigger={
                  <SolCheckbox
                    checked={!!showRoomName}
                    label="Show room name"
                    onClick={value => {
                      props.setter({
                        ...props.template.revision.configuration,
                        showRoomName: value,
                      })
                    }}
                  />
                }
              />
              <SolTooltip
                text="Choose whether the screen key should be visible on the welcome screen."
                hoverable
                position="top left"
                trigger={
                  <SolCheckbox
                    checked={!!screenKey}
                    label="Show screen key"
                    onClick={value => {
                      props.setter({
                        ...props.template.revision.configuration,
                        screenKey: value,
                      })
                    }}
                  />
                }
              />
              <>
                <SolTooltip
                  text={hostnameText}
                  trigger={
                    <SolCheckbox
                      checked={hostnameCheckbox}
                      label="Show hostname instead of IP address"
                      onClick={value => {
                        setHostnameCheckbox(value)
                        if (!value) {
                          props.setter({
                            ...props.template.revision.configuration,
                            showFullyQualifiedName: false,
                            showHostnameInsteadOfIp: false,
                          })
                        } else {
                          props.setter({
                            ...props.template.revision.configuration,
                            showHostnameInsteadOfIp: true,
                          })
                        }
                      }}
                    />
                  }
                />
                <div className={styles.hostnameOptions}>
                  <SolRadio
                    label="Hostname Only"
                    checked={showHostnameInsteadOfIp}
                    disabled={!hostnameCheckbox}
                    onClick={() => {
                      props.setter({
                        ...props.template.revision.configuration,
                        showHostnameInsteadOfIp: true,
                        showFullyQualifiedName: false,
                      })
                    }}
                  />
                  <SolRadio
                    label="Fully qualified name (long names may not always fit on screen, please check)"
                    checked={showFullyQualifiedName}
                    disabled={!hostnameCheckbox}
                    notification={<SolNotification type="menu" notifications="v5.3+" />}
                    onClick={() => {
                      props.setter({
                        ...props.template.revision.configuration,
                        showFullyQualifiedName: true,
                        showHostnameInsteadOfIp: false,
                      })
                    }}
                  />
                </div>
              </>
              <SolCheckbox
                checked={!!shouldAnimateBackground}
                label="Background animation"
                notification={<SolNotification type="menu" notifications="v5.2+" />}
                onClick={value => {
                  props.setter({
                    ...props.template.revision.configuration,
                    shouldAnimateBackground: value,
                  })
                }}
              />
              <div className={styles.colorWrapper}>
                <SolTooltip
                  hoverable
                  position="top right"
                  isInline
                  text={`Choose a color for the foreground text that is displayed on the welcome screen.
                        For instance: room name, screen key, etc.`}
                  trigger={<div className={styles.colorText}>Foreground text color</div>}
                />
                <SolColorPicker
                  color={textColor || '#FFFFFF'}
                  setter={color => {
                    props.setter({
                      ...props.template.revision.configuration,
                      textColor: color,
                    })
                  }}
                />
              </div>
            </SolColumn>
          </SolRow>
        </SolGrid>
      </SolColumn>
    </React.Fragment>
  )
}

export default WelcomeScreenOptions
