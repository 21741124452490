import React, { useEffect } from 'react'
import Joyride from 'react-joyride'
import { useJoyride, styles } from 'shared/hooks/useJoyride'
import { Icon } from 'semantic-ui-react'

type Props = {
  forceShowRide?: boolean
} & ReturnType<typeof useJoyride>

export function SolsticeUpdatesRide({ forceShowRide, show: showRide, finishRide, startRide }: Props) {
  useEffect(() => {
    if (forceShowRide) {
      startRide()
    }
  }, [])
  return (
    <Joyride
      disableScrolling
      showSkipButton
      styles={{
        ...styles,
        tooltipContent: {
          textAlign: 'left',
        },
      }}
      disableOverlayClose
      callback={event => {
        if (event.action === 'close' || event.type === 'tour:end') {
          finishRide()
        }
      }}
      run={showRide}
      continuous
      locale={{
        last: 'Finish',
      }}
      steps={[
        {
          disableBeacon: true,
          target: '.joyride-update-pods-table-status',
          title: (
            <p>
              Only Pods that are online <Icon className="exIcon" color="green" name="circle" /> can be updated.
            </p>
          ),
          content: (
            <p>
              Offline <Icon className="exIcon" color="red" name="circle" /> Pods are ineligible for updates.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: '.selectable-column-header',
          title: 'Select eligible Pods to be updated.',
          content:
            /* eslint-disable-next-line max-len */
            'Only online Pods are eligible for software updates. Pods with a lapsed subscription can be updated to a release prior to the subscription end date',
        },
        {
          styles: {
            tooltipContent: {
              marginTop: '-20px',
            },
          },
          disableBeacon: true,
          target: '.joyride-update-pods-button',
          content: <strong>Click to begin updating selected Pods.</strong>,
        },
      ]}
      disableScrollParentFix
    />
  )
}
