import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import CreateOrg from './CreateOrg'
import { createOrg } from 'shared/store/actions/authenticate'
import { useDispatch, useSelector } from 'react-redux'

export interface createOrgData {
  email: string
  password: string
}

export interface returnValue {
  status: number
  statusText: string
}

const CreateOrgController = (props: RouteComponentProps) => {
  const token = useSelector((state: any) => state.user?.token)
  useEffect(() => {
    if (token) {
      props.history.push('/')
    }
  })
  const dispatch = useDispatch()
  async function createOrgRequest(org: createOrgData ) {
    return dispatch(createOrg(org))
  }
  return (
    <CreateOrg
      createOrgRequest={createOrgRequest}
      {...props}/>
  )
}

export default CreateOrgController
