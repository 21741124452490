import React from 'react'
import { Link } from 'react-router-dom'
import styles from './SolPodName.module.scss'

type Props = {
  id: string
  name: string
  ip?: string | null
}

function SolPodName({ id, name, ip }: Props) {
  return (
    <div className={styles.name}>
      <div>
        <Link title={name} to={`/manage/pod/${id}`}>
          {name}
        </Link>
      </div>
      {!!ip && <div className={styles.ip}>{ip}</div>}
    </div>
  )
}

export default SolPodName
