import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toggleSidebar } from 'shared/store/slices/core'

import { Dispatch } from 'shared/types/redux'

import Header from './Header'

const mapStateToProps = (state: any) => ({
  token: state?.user?.token,
  name: state?.user?.name,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
})

export default connect<{}, any, any>(mapStateToProps, mapDispatchToProps)(withRouter(Header))
