// TODO: make an injection point for this
import authService, { AuthCredentialInterface } from 'shared/services/auth'
import {
  parseUserDetailsFromToken,
  setIdToken,
  setOrg,
  setEmail,
  setToken,
  setAuthType,
} from 'shared/services/auth/helpers'
import { setUserInfo } from 'shared/store/actions/account'
import { Dispatch } from 'shared/types/redux'
import { Role } from 'shared/types/Role'
import { AuthenticatedUserClaims } from 'shared/types/AuthenticatedUserClaims'
import { AxiosError } from 'axios'
import { CreateOrgInterface, SupportCredentials } from 'shared/services/auth/types'

type AuthenticateFn = (credentials: AuthCredentialInterface) => any // AuthenticateDispatchFn
type SupportLoginFn = (credentials: SupportCredentials) => any // AuthenticateDispatchFn

interface TokenResponse {
  access_token: string
  id_token: string
  org: string
}

// Here we will add authenticateSSO
// we will have to set all the same local storage variables including  setAuthType("sso")

export const authenticateAuth0: AuthenticateFn = (credentials: AuthCredentialInterface) => (dispatch: Dispatch) =>
  authService
    .login(credentials)
    .then((data: TokenResponse) => {
      if (data?.access_token) {
        const claims = parseUserDetailsFromToken(data.access_token) as AuthenticatedUserClaims
        const role = claims.roles?.[0].id ?? Role.Viewer
        if ([Role['Active Learning Facilitator'], Role['Active Learning Designer']].includes(role)) {
          window.location.replace('./active-learning-app?logout=true')
          return Promise.resolve()
        }
        setToken(data.access_token)
        setIdToken(data.id_token)
        setOrg(claims.orgId)
        setEmail(claims.userEmail)
        dispatch(setUserInfo())
        setAuthType('auth0')

        return Promise.resolve()
      }
      return Promise.reject(new Error('invalid response from auth service'))
    })
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })

export const createOrg: AuthenticateFn = (credentials: CreateOrgInterface) => (dispatch: Dispatch) =>
  authService
    .createOrg(credentials)
    .then((data: TokenResponse) => {
      if (data?.access_token) {
        const claims = parseUserDetailsFromToken(data.access_token) as AuthenticatedUserClaims
        const role = claims.roles?.[0].id ?? Role.Viewer
        if ([Role['Active Learning Facilitator'], Role['Active Learning Designer']].includes(role)) {
          window.location.replace('./active-learning-app?logout=true')
          return Promise.resolve()
        }
        setToken(data.access_token)
        setIdToken(data.id_token)
        setOrg(claims.orgId)
        setEmail(claims.userEmail)
        dispatch(setUserInfo())
        setAuthType('auth0')
  
        return Promise.resolve()
      }
      return Promise.reject(new Error('invalid response from auth service'))
    })
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })

export const supportLogin: SupportLoginFn = (credentials: SupportCredentials) => (dispatch: Dispatch) =>
  authService
    .supportLogin(credentials)
    .then((data: TokenResponse) => {
      if (data?.access_token) {
        const claims = parseUserDetailsFromToken(data.access_token) as AuthenticatedUserClaims
        const role = claims.roles?.[0].id ?? Role.Viewer
        if ([Role['Active Learning Facilitator'], Role['Active Learning Designer']].includes(role)) {
          window.location.replace('./active-learning-app?logout=true')
          return Promise.resolve()
        }
        setToken(data.access_token)
        setIdToken(data.id_token)
        setOrg(claims.orgId)
        setEmail(claims.userEmail)
        dispatch(setUserInfo())
        setAuthType('auth0')
  
        return Promise.resolve()
      }
      return Promise.reject(new Error('invalid response from auth service'))
    })
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })

export const authenticateWithSSO: AuthenticateFn = (credentials: AuthCredentialInterface) => () =>
  authService
    .ssoSignIn(credentials)
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })

export const completeSSO  = (token: string) => (dispatch: Dispatch) =>
  authService
    .ssoFinish(token)
    .then((data: TokenResponse) => {
      if (data?.access_token) {
        const claims = parseUserDetailsFromToken(data.access_token) as AuthenticatedUserClaims
        const role = claims.roles?.[0].id ?? Role.Viewer
        if ([Role['Active Learning Facilitator'], Role['Active Learning Designer']].includes(role)) {
          window.location.replace('./active-learning-app?logout=true')
          return Promise.resolve()
        }
        setToken(data.access_token)
        setIdToken(data.id_token)
        setOrg(claims.orgId)
        setEmail(claims.userEmail)
        dispatch(setUserInfo())
        setAuthType('sso')

        return Promise.resolve()
      }
      return Promise.reject(new Error('invalid response from auth service'))
    })
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })


export const getSsoStatus = (email: string) => () =>
  authService
    .getSsoStatus(email).then(data => {
      return Promise.resolve(data)
    })
    .catch((err: AxiosError) => {
      return Promise.reject(err)
    })

type ResetPasswordFn = (email: string) => any

export const resetPassword: ResetPasswordFn = email => (_dispatch: Dispatch) =>
  authService.resetPassword(email).catch(Promise.reject)
