import { createBrowserHistory } from 'history'
import { History } from 'history'

let history: History | null = null

export function createHistory(): History {
  history = createBrowserHistory()
  return history
}

export default function getHistory(): History {
  return history!
}
