import { SolsticeRoutingSpace } from 'graphql/__generated__/types'
import React, { Dispatch, SetStateAction } from 'react'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { PodGrid } from './PodGrid/PodGrid'
import { SolButton } from 'SolComponents'
import styles from './designlayout.module.scss'

export interface Props {
    setCurrentTab: Dispatch<SetStateAction<string>>
    space: SolsticeRoutingSpace | undefined
    setSpace: Dispatch<SetStateAction<SolsticeRoutingSpace | undefined>>
    saveSpace: any
}

export const DesignLayoutTab = ({
  setCurrentTab,
  space,
  setSpace,
  saveSpace,
}: Props) => {

  return (<ContentWrapper>
    <div className={styles.header}>Design Space</div>
    <div className={styles.subHeader}>
      Move the pods around to match your space.
      This will help the instructor or moderator visualize the space.
    </div>
    <PodGrid
      pods={space?.pods}
      routes={[]}
      setPods={(
        (pods: any) => {
          setSpace({ ...space, pods })
        }
      )}
      cols={9}
      rows={6}
      showRoutes={false}
      allowDraggingPods
      primaryPodId={space?.primary_pod ?? ''}
    />
      

    <span className={styles.buttonHolder}>
      {
        !space?.id
          ? <SolButton
            text="Back"
            className={styles.backButton}
            handleClick={() => { setCurrentTab('pods') }}
          /> : <div></div> // dummy div lets space-between push Save button right
      }
      
      <SolButton
        text={space?.id ? 'Save' : 'Next'}
        color="blue"
        className={styles.nextButton}
        handleClick={() => (space?.id
          ? saveSpace() : setCurrentTab('presets')
        )}
      />
    </span>
  </ContentWrapper>)
}
