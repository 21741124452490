import React from 'react'
import { TextArea, Form } from 'semantic-ui-react'
import styles from './SolTextArea.module.scss'

export interface Props {
  value: any
  placeholder?: string
  onChange: (e: any) => void
  label?: string
  growable?: boolean
}

export default function SolTextArea(props: Props) {
  return (
    <div className={styles.exclusions}>
      <div className={styles.header}>
        <label>{props.label}</label>
      </div>
      {props.growable ?? false ? (
        <Form className={styles.growable}>
          <TextArea placeholder={props.placeholder} onChange={props.onChange} value={props.value} />
        </Form>
      ) : (
        <textarea onChange={props.onChange} value={props.value} placeholder={props.placeholder} />
      )}
    </div>
  )
}
