import React from 'react'
import { WifiClientSettings, EapMethod } from 'graphql/__generated__/types'
import styles from './ExistingSecurity.module.scss'
import { SolRow, SolDropdown, SolInput } from 'SolComponents'
import { DropdownItemProps } from 'semantic-ui-react'
import IpConfiguration from './IpConfiguration'
import EapSecurity from './EapSecurity'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import UniqueSettingsInput, {
  getTooltipText,
} from 'pages/Templates/UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import { getExistingNetworkDnsHostname, setExistingNetworkDnsHostname } from 'shared/config/wifi'
import { SecurityProtocol } from '../WifiTypes'

export interface Props {
  wifiClientSettings: WifiClientSettings
  updateWifiClientSettings: (newSettings: WifiClientSettings) => void
  podId?: string
  isDefault: boolean
}

const securityOptions: DropdownItemProps[] = [
  { value: SecurityProtocol.Open, text: 'Open' },
  { value: SecurityProtocol.Wep, text: 'WEP' },
  { value: SecurityProtocol.Wpa, text: 'WPA/WPA2' },
  { value: SecurityProtocol['802.1x'], text: '802.1x EAP' },
]

function ExistingNetwork({ updateWifiClientSettings, wifiClientSettings: config, podId, isDefault }: Props) {
  const ssidName = !config.ssid ? '[Selected Wireless Network Name] ' : config.ssid
  return (
    <div>
      <SolRow>
        <div className={styles.securityTypeWrapper}>
          <div className={styles.securitySingles}>
            <SolInput
              label="SSID"
              maxLength={32}
              value={config.ssid}
              onChange={(e, value) => {
                updateWifiClientSettings({
                  ...config,
                  ssid: value.value,
                })
              }}
            />
          </div>
          <div className={styles.securitySingles}>
            <SolDropdown
              type="caret"
              direction="left"
              label="Security Type"
              options={securityOptions}
              value={config.securityProtocol}
              onChange={(type: SecurityProtocol) => {
                updateWifiClientSettings({
                  ...config,
                  securityProtocol: type,
                  enterpriseSettings: {
                    ...config?.enterpriseSettings,
                    eapMethod: type !== SecurityProtocol['802.1x'] ? EapMethod.None : EapMethod.Peap,
                  },
                })
              }}
            />
          </div>
        </div>
      </SolRow>
      <div>
        <div className={styles.ssidName}>
          <span className={styles.name}>{ssidName}&nbsp;</span>
          Settings
        </div>
        {config.securityProtocol === SecurityProtocol['802.1x'] && (
          <EapSecurity
            config={config.enterpriseSettings ?? {}}
            setConfig={c =>
              updateWifiClientSettings({
                ...config,
                enterpriseSettings: c,
              })
            }
          />
        )}
        {config.securityProtocol === SecurityProtocol.Wep && (
          <div className={styles.wepPassword}>
            <ValidationInput
              name="existing_network_pw"
              variant="password"
              label="Password"
              required
              rules={{ required: true }}
              value={config?.enterpriseSettings?.password?.secret ?? ''}
              onChange={(e, value) =>
                updateWifiClientSettings({
                  ...config,
                  enterpriseSettings: {
                    ...config.enterpriseSettings,
                    password: {
                      secret: value.value,
                    },
                  },
                })
              }
              onClear={() => {
                updateWifiClientSettings({
                  ...config,
                  enterpriseSettings: {
                    ...config.enterpriseSettings,
                    password: {
                      secret: '',
                    },
                  },
                })
              }}
            />
          </div>
        )}
        {config.securityProtocol === SecurityProtocol.Wpa && (
          <div className={styles.wepPassword}>
            <ValidationInput
              name="security_pw"
              rules={{ required: true }}
              variant="password"
              label="Password"
              value={config?.enterpriseSettings?.password?.secret ?? ''}
              onChange={(e, value) =>
                updateWifiClientSettings({
                  ...config,
                  enterpriseSettings: {
                    ...config.enterpriseSettings,
                    password: {
                      secret: value.value,
                    },
                  },
                })
              }
              onClear={() => {
                updateWifiClientSettings({
                  ...config,
                  enterpriseSettings: {
                    ...config.enterpriseSettings,
                    password: {
                      secret: '',
                    },
                  },
                })
              }}
            />
          </div>
        )}

        <IpConfiguration
          config={config?.ipConfiguration ?? {}}
          updateConfiguration={ipConfig => {
            updateWifiClientSettings({
              ...config,
              ipConfiguration: ipConfig,
            })
          }}
          podId={podId}
          isDefault={isDefault}
        />
        <div>
          <UniqueSettingsInput
            label="DNS Hostname"
            tooltipText={getTooltipText({
              setting: 'DNS Hostname',
              template: 'Wifi',
            })}
            getValue={getExistingNetworkDnsHostname}
            updateConfig={setExistingNetworkDnsHostname}
            podId={podId}
          />
        </div>
      </div>
    </div>
  )
}

export default ExistingNetwork
