import { useSelector } from 'react-redux'
import { DataTableInstance } from '../DataTableSlim'
import {
  defaultValue,
} from '../types'
import { useDataTable } from './useDataTable'

interface SelectedItems<T, K> {
  items: T[]
  ids: K[]
  includedIds?: string[]
  excludedIds?: string[]
}

export const useSelectedItems = <T = void, K = void>(
  tableId: string &
    (T extends void ? 'You need to pass in a generic type' : string),
): SelectedItems<T, K> => {
  const value = useSelector((state: any) => state.table)
  const { name } = useSelector((state: any) => state.user)
  const tableData = value[name][tableId]

  if (!tableData) {
    return defaultValue.selectedItems
  }

  return tableData.selectedItems
}


// This is for constructing graphql queries that use idIn and idNotIn parameters which is as of this comment only enforced with convention :)
interface SelectedPodsOptions {
  idIn?: string[]
  idNotIn?: string[]
}
export const useSelectedPodsOptions = (table: DataTableInstance) => {
  const { selectedItems } = useDataTable<any>(table)
  const options: SelectedPodsOptions = {}
  const idIn = selectedItems?.includedIds
  const idNotIn = selectedItems?.excludedIds
  if (idIn && !idIn.includes('*') && idIn.length > 0) {
    options.idIn = idIn
  }
  if (idNotIn && !idNotIn.includes('*') && idNotIn.length > 0) {
    options.idNotIn = idNotIn
  }
  return options
}
