import React from 'react'
import { Segment } from 'semantic-ui-react'
import styles from './Metric.module.scss'
import classNames from 'classnames'
import { TestingProps } from 'shared/types/TestingProps'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

interface MetricProps extends TestingProps {
  live: boolean
  count: number | '--'
  unit?: string
  caption: string
  url?: string
  linkName?: string
}

const Metric = ({ live, count, unit, caption, url, linkName, dataTestId }: MetricProps) => {
  return (
    <Segment className={classNames(styles.box, { [styles.live]: live })} raised data-testid={dataTestId}>
      <div className={styles.metric}>
        {count}
        {unit && <span className={styles.unit}>{unit}</span>}
      </div>
      <div className={classNames(styles.caption, { [styles.live]: live })}>{caption}</div>
      {url && linkName && (
        <Link to={url}>
          {linkName}&nbsp;
          <Icon name="angle right" />
        </Link>
      )}
    </Segment>
  )
}

export default Metric
