import React from 'react'
import { format, differenceInDays } from 'date-fns'
import { useCards } from './useCards.hook'
import { useTable } from './useTable.hook'
import { SolDonutCard, SolThreeCardCarousel, SolFilterHeaderCard } from 'SolComponents'
import { ChartTimePeriod } from 'SolComponents/SolChart/types'
import { getPreviousDate } from 'SolComponents/SolChart/chartUtils'
import styles from './index.module.scss'
import SharingTable from './SharingTable'
import HowChart from './How' // stopped refreshing the materialized view: content_sharing_types , because of performance concerns. So there is never any data in sharingData. See BUG-4725
import SoftwareUsedCard from './SoftwareUsed/SoftwareUsedCard'
import { TimeInterval, Display } from 'graphql/__generated__/types'
import HybridProximateCard from './HybridProximateCard'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { OptionDate } from 'shared/types/OptionDate'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import SolElementInfoMessage from '../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'

interface Props {
  aspectRatio?: number
}

const getInterval = (difference: Number, period: String): String => {
  if (period === 'hours') {
    return TimeInterval.Hour
  }
  if (difference >= 90) {
    return TimeInterval.Month
  }
  return TimeInterval.Day
}

const Sharing = (props?: Props) => {
  const [timeInterval, setTimeInterval] = React.useState<TimeInterval>(TimeInterval.Day)
  const [timePeriod, setTimePeriod] = React.useState<ChartTimePeriod>(ChartTimePeriod.MONTH)
  const [optionDate, setDate] = React.useState({
    fromDate: format( getPreviousDate(timePeriod, new Date()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format( new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    interval: timeInterval?.toLowerCase(),
  })
  const { howChart } = useFlags()

  const handleChangeTimePeriod = (period: ChartTimePeriod, range: OptionDate) => {
    const from = new Date(range?.fromDate)
    const to = new Date(range?.toDate)
    const difference = differenceInDays(to, from ) /// to.diff(from, 'days')
    if (difference >= 90) {
      setTimeInterval(TimeInterval.Month)
    }
    if (range) {
      const timeIntervalArg = getInterval(difference, period)?.toLowerCase()
      if (period === ChartTimePeriod.CUSTOM) {
        setDate({
          fromDate: range.fromDate,
          toDate: range.toDate,
          interval: timeIntervalArg,
        })
      }
      if (period !== ChartTimePeriod.CUSTOM) {
        setDate({ ...range, interval: timeIntervalArg })
      }
    }
    setTimePeriod(period)
  }

  const {
    totalItemsSelectable,
    totalItemsSelected,
    totalFilteredItems,
    handleOnSort,
    data: tableData,
    searchOptions,
    isLoading: isLoadingTable,
  } = useTable({
    fromDate: optionDate.fromDate,
    toDate: optionDate.toDate,
  })

  const args = { ...optionDate, searchOptions }

  const {
    devices,
    versions,
    screens,
    sharingTypes,
    hybridMeetings,
    isHybridMeetingsLoading,
    contentApp,
    softwareUsedState,
    pagination,
    isLoading: areCardsLoading,
    isTypeLoading,
    isAppSharing,
    handleAxisYSoftwareUsed,
    handleCategoryActive,
    handleGrouping,
    hasElementPods,
  } = useCards(args)

  const isLoading = areCardsLoading || isLoadingTable

  const { toDate } = optionDate

  const SecondRow = () => {
    return (
      <div className={styles.chartWrapper}>
        {howChart
        && <div className={styles.chart}>
          <HowChart data={sharingTypes || []} testId="how-card" isLoading={isTypeLoading} />
        </div>
        }
        <div className={styles.chart}>
          <SoftwareUsedCard
            records={contentApp}
            testId="what-card"
            category={softwareUsedState.categoryName}
            isLoading={isAppSharing}
            isGrouped={softwareUsedState.isGrouped}
            pagination={pagination}
            handleAxisYClick={handleAxisYSoftwareUsed}
            handleCategoryActive={handleCategoryActive}
            handleGrouping={handleGrouping}
          />
        </div>
        <div className={styles.chart}>
          <HybridProximateCard
            data={hybridMeetings || []}
            testId="hybrid-proximate"
            isLoading={isHybridMeetingsLoading}
            interval={timeInterval}
            toDate={new Date(toDate)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="sharing">
      <SolFilterHeaderCard
        header="In-Room Tech"
        filteredItems={totalItemsSelected}
        tableId={DataTableInstance.Sharing}
        dateRange={{ include: true }}
        setTimeInterval={setTimeInterval}
        timeInterval={timeInterval}
        handleIntervalChange={handleChangeTimePeriod}
      />
      <ContentWrapper>
        <div data-testid="top-carousel" className={styles.topCarousel}>
          <SolThreeCardCarousel>
            <div>
              <SolDonutCard
                data={devices}
                isLoading={isLoading}
                title="User Device Platform"
                testId="platform-card"
                aspectRatio={props?.aspectRatio}
              />
            </div>
            <div>
              <SolDonutCard
                data={versions}
                isLoading={isLoading}
                title="Solstice Client App Version"
                testId="version-card"
                aspectRatio={props?.aspectRatio}
              />
            </div>
            <div>
              <SolDonutCard
                data={screens}
                isLoading={isLoading}
                title="User Device Resolution"
                testId="resolution-card"
                aspectRatio={props?.aspectRatio}
              />
            </div>
          </SolThreeCardCarousel>
        </div>

        <div data-testid="medium-carousel" className={styles.row}>
          <SecondRow />
        </div>

        {hasElementPods
          && (
            <div className={styles.row}>
              <SolElementInfoMessage
                message="Solstice Element pods are not included"
              />
            </div>
          )
        }

        <div className={styles['table-container']}>
          <SharingTable
            data={(tableData || []) as Display[]}
            totalItems={totalFilteredItems}
            loading={isLoading}
            onSort={handleOnSort}
            totalItemsSelectable={totalItemsSelectable}
            totalItemsSelected={totalItemsSelected}
          />
        </div>
      </ContentWrapper>
    </div>
  )
}

export default React.memo(Sharing)
