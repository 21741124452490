import React, { SFC, createRef } from 'react'
import { Button } from 'semantic-ui-react'
import styles from './SolMap.module.scss'
import 'leaflet/dist/leaflet.css'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'
import { LatLng, LatLngBounds } from 'leaflet'
import SolGpsTargetIcon from 'SolComponents/Icons/SolGpsIcon/SolGpsTargetIcon'
import classNames from 'classnames'

export type Props = {
  children: React.ReactNode
  className?: string
  maxZoom: number
  minZoom: number
  afterZoomToYourLocation?: () => void
  beforeZoomToYourLocation?: () => void
}

const SolMap: SFC<Props> = (props: Props) => {
  const refMap = createRef<Map>()

  const reCenterMapOnPoint = (point: LatLng, z: number = 19) => {
    const map = refMap.current
    if (map) {
      map?.leafletElement.flyTo([point.lat, point.lng], z)
    }
  }

  const zoomToYourLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        reCenterMapOnPoint(new LatLng(position.coords.latitude, position.coords.longitude), 12)
      })

      const map = refMap.current

      map?.leafletElement.once('movestart', function() {
        if (props.beforeZoomToYourLocation) {
          props.beforeZoomToYourLocation()
        }
      })

      map?.leafletElement.once('moveend', function() {
        if (props.afterZoomToYourLocation) {
          props.afterZoomToYourLocation()
        }
      })
    }
  }

  return (
    <Map
      className={classNames(styles.solMap, props.className ? props.className : styles.map)}
      zoom={3}
      maxZoom={props.maxZoom}
      minZoom={props.minZoom}
      ref={refMap}
      center={new LatLng(39.5, -38.35)}
      zoomControl={false}
      setView
    >
      <Button className={styles.controlButtonsOut}>
        <SolGpsTargetIcon onClick={() => zoomToYourLocation()} className={styles.targetIcon} />
      </Button>
      <ZoomControl position="topright" />
      <TileLayer
        noWrap
        url="//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        bounds={new LatLngBounds(new LatLng(-90, -180), new LatLng(90, 180))}
        /* eslint-disable-next-line max-len */
        attribution={`&copy; <a href=https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`}
      />

      {props.children}
    </Map>
  )
}

export default SolMap
