import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { urlParams, queryString } from 'shared/core/utils'
import { logout } from 'shared/core/authenticate'
import styles from './ActiveLearningApp.module.scss'
import { SolButton } from 'SolComponents'
const logo = require('../../assets/active_learning/logo_light.svg')
const appIcon = require('../../assets/active_learning/app_icon.svg')

const utm = queryString({
  utm_source: 'solstice_cloud',
  utm_campaign: 'active_learning',
  utm_medium: 'new_user',
})

const urls = {
  download: `https://www.mersive.com/download/?${utm}`,
  learnMore: `https://documentation.mersive.com/en/al-settings-and-sessions.html?${utm}`,
}

const ActiveLearningApp = ({ location, history }: RouteComponentProps) => {
  const logoutParam = urlParams(location.search).get('logout')
  if (logoutParam === 'true') {
    logout(false)
    history.replace('/active-learning-app')
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} className={styles.logo} />
      </div>
      <div className={styles.body}>
        <p className={styles.introduction}>
          Thank you for creating your Solstice Cloud account for{' '}
          <span className={styles.productName}>Solstice Active Learning</span>, a software-enabled video routing
          solution focused on providing users flexibility and ease of use.
        </p>
        <div className={styles.instructions}>
          <img src={appIcon} className={styles.appIcon} />
          <p className={styles.text}>
            To get started, <a href={urls.download}>download</a> the Solstice Active Learning app on your laptop or
            tablet device and login using your same Solstice Cloud credentials.
          </p>
          <SolButton
            color="green"
            basic
            isLink
            text="Learn More&nbsp;&nbsp;&nbsp;&#8594;"
            externalLink={urls.learnMore}
            className={styles.download}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(ActiveLearningApp)
