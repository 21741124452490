import groupBy from 'lodash/groupBy'
import {
  useDisplayCountQuery,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import { Filter } from './types'

export const useDisplayCount = (where: ViewDisplaysBoolExp = {}) => {
  const { data } = useDisplayCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where,
    },
  })
  return data?.totalDisplays?.aggregate?.count ?? 0
}

export const useFiltersDisplayCount = (filters: Filter[]) => {
  const filtersByCategory = Object.values(
    groupBy(filters, f => f.categoryInternalName),
  )
  return useDisplayCount({
    _and: filtersByCategory.map(filter => ({
      _or: filter.map(option => ({
        categories: { option_id: { _eq: option.optionInternalName } },
      })),
    })),
  })
}
