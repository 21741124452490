import React from 'react'
import { Icon, Menu, Dropdown } from 'semantic-ui-react'
import Media from 'react-media'
import styles from './Header.module.scss'
import { logout } from 'shared/core/authenticate'

import { menu as palette } from 'assets/palettes'
import { RouteComponentProps } from 'react-router'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { SolTooltip } from 'SolComponents'

const solsticeLogo = require('assets/solstice_cloud_logo.png')

export const style = {
  menu: {
    marginBottom: '0px',
    marginTop: '0px',
    border: '0px',
    borderRadius: '0px',
    boxShadow: 'none',
    backgroundColor: palette.blueBlack,
  },
  item: {
    padding: '19px',
    overflow: 'hidden',
  },
  name: {
    color: '#EAEAEC',
    fontSize: '12px',
    verticalAlign: 'middle',
    margin: 'auto 5px',
    fontWeight: 300,
  },
}

export interface Props {
  token: string | null
  name: string
  toggleSidebar: () => any
}

function Header(props: Props & RouteComponentProps) {
  const currentUser = useCurrentUser()

  const handleMenuDropdownClick = (_evt: React.SyntheticEvent<any>, data: any) => {
    switch (data.value) {
      case 'logout':
        localStorage.clear()
        return logout()
      default:
        return props.history.push(`/${data.value}`)
    }
  }

  const handleItemClick = (_e: any, { name }: { name: string }) => {
    if (name === 'sidebarToggle') {
      props.toggleSidebar()
    }
  }

  const documentation = [
    {
      key: 1,
      text: 'Documentation',
      onClick: () => {window.open('https://documentation.mersive.com')},
      value: 'documentation',
      id: 'documentation-button',
      selected: false,
    },
    {
      key: 2,
      text: 'Resources',
      onClick: () => {window.open('https://www.mersive.com/resources/?topics=solstice-cloud')},
      value: 'resources',
      id: 'resources-button',
    },
    {
      key: 3,
      text: 'Training',
      onClick: () => {window.open('https://www.mersive.com/partners/training-certification-program/')},
      value: 'training',
      id: 'training-button',
    },
    {
      key: 4,
      text: 'Support',
      onClick: () => {window.open('https://www.mersive.com/support')},
      value: 'support',
      id: 'support-button',
    },
  ]

  const options = [
    {
      key: 1,
      text: 'Logout',
      value: 'logout',
      id: 'logout-button',
    },
  ]

  // don't add this option if the user is sso_only
  if (!currentUser?.ssoOnly) {
    options.splice(0, 0, {
      key: 3,
      text: 'Change Password',
      value: 'manage/accounts/change-password',
      id: 'change-password',
    })
  }
 
  const emailLength = props.name?.length

  return (
    <React.Fragment>
      {props.token ? (
        <Menu style={style.menu} size="huge" borderless inverted data-testid="header-bar">
          <Media query={{ 'max-width': 768 }}>
            <Menu.Item name="sidebarToggle" onClick={handleItemClick}>
              <Icon name="sidebar" size="large" />
            </Menu.Item>
          </Media>
          <Menu.Item style={style.item}>
            <img className={styles.solstice} src={solsticeLogo} />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item onClick={() => undefined} className={styles.menuItem}>
              <Dropdown
                selectOnBlur={false}
                floating
                icon={null}
                pointing="top right"
                options={documentation}
                onChange={handleMenuDropdownClick}
                trigger={<Icon name="question circle" size="large" data-testid="documentation-icon" />}
                className={styles.menuDropdown}
              />
            </Menu.Item>
            <Menu.Item onClick={() => undefined} className={styles.menuItem}>
              <Dropdown
                header={emailLength > 25
                  ? <SolTooltip
                    text={props.name}
                    position="top left"
                    trigger={
                      <div className={styles.menuHeaderTruncate}>
                        {props.name}
                      </div>
                    }
                  />
                  :                  <div className={styles.menuHeader}>{props.name}</div>
                }
                selectOnBlur={false}
                floating
                icon={null}
                pointing="top right"
                options={options}
                onChange={handleMenuDropdownClick}
                trigger={<Icon name="user circle" size="large" data-testid="account-icon" />}
                value={location.pathname.substring(1)}
                className={styles.menuDropdown}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      ) : null}
    </React.Fragment>
  )
}

export default Header
