import { DisplayUnreachableDelay, FilterType, UsbPlugEventType } from '../types'
import {
  useUpdateAlertConfigMutation,
  AlertConfigDocument,
  AlertConfigQuery,
} from 'graphql/__generated__/hasura-types'

export const useUpdateAlertConfig = () => {
  const [updateAlertConfig] = useUpdateAlertConfigMutation()

  const setConfigEnabled = (name: FilterType, value: boolean) => {
    const [cacheProp, mutProp] = {
      [FilterType.DisplayOutOfTemplate]: [
        'displayOutOfTemplateEnabled',
        'config_alerts_display_out_of_template',
      ],
      [FilterType.DisplayRebooted]: [
        'displayRebootedEnabled',
        'config_alerts_display_rebooted_enabled',
      ],
      [FilterType.DisplayUnreachable]: [
        'displayUnreachableEnabled',
        'config_alerts_display_unreachable_enabled',
      ],
      [FilterType.UsbDevice]: [
        'usbPlugEventEnabled',
        'config_alerts_usb_plug_event_enabled',
      ],
    }[name]
    return updateAlertConfig({
      variables: { config: { [mutProp]: value } },
      update: proxy => {
        const cacheData = proxy.readQuery({
          query: AlertConfigDocument,
        }) as AlertConfigQuery
        const newUser = {
          ...cacheData.users[0],
          [cacheProp]: value,
        }
        proxy.writeQuery({
          query: AlertConfigDocument,
          data: {
            ...cacheData,
            users: [newUser],
          },
        })
      },
    })
  }

  const setDisplayUnreachableDelay = (value: DisplayUnreachableDelay) =>
    updateAlertConfig({
      variables: { config: { config_alerts_display_unreachable_delay: value } },
      update: proxy => {
        const cacheData = proxy.readQuery({
          query: AlertConfigDocument,
        }) as AlertConfigQuery
        const newUser = {
          ...cacheData.users[0],
          displayUnreachableDelay: value,
        }
        proxy.writeQuery({
          query: AlertConfigDocument,
          data: {
            ...cacheData,
            users: [newUser],
          },
        })
      },
    })

  const setUsbPlugEventType = (value: UsbPlugEventType) =>
    updateAlertConfig({
      variables: { config: { config_alerts_usb_plug_event_type: value } },
      update: proxy => {
        const cacheData = proxy.readQuery({
          query: AlertConfigDocument,
        }) as AlertConfigQuery
        const newUser = {
          ...cacheData.users[0],
          usbPlugEventType: value,
        }
        proxy.writeQuery({
          query: AlertConfigDocument,
          data: {
            ...cacheData,
            users: [newUser],
          },
        })
      },
    })

  return {
    setConfigEnabled,
    setDisplayUnreachableDelay,
    setUsbPlugEventType,
  }
}
