import React from 'react'
import { SolCard } from 'SolComponents'
import { Header } from 'semantic-ui-react'

import styles from './index.module.scss'
import Map from './Map/Map'
import { TopTenList } from '../TopTenCollaborativeRooms'
import { MostCollaborativeSpacesQuery, ViewMostCollaborativeSpacesResult } from 'graphql/__generated__/hasura-types'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'

interface Props {
  mostCollaborativeSpaces?: MostCollaborativeSpacesQuery
  loadingMostCollaborativeSpaces: boolean
  setSelectedLocation: (location: ViewMostCollaborativeSpacesResult | null) => void
  selectedLocation: ViewMostCollaborativeSpacesResult | null
  showTopTen: Boolean
}

const MostCollaborativeSpaces = ({
  mostCollaborativeSpaces,
  loadingMostCollaborativeSpaces,
  showTopTen,
  selectedLocation,
  setSelectedLocation,
}: Props) => {
  return (
    <div className={styles.mostCollaborativeSpaces} data-testid="most-collaborative-spaces">
      <SolCard
        className={styles.card}
        header={
          <div>
            <Header className={styles.header} as="h3" textAlign="left" content="Most Collaborative Spaces" />
          </div>
        }
      >
        <LoadingWrapper
          isEmpty={(mostCollaborativeSpaces?._search_most_collaborative_spaces?.length || 0) === 0}
          isLoading={loadingMostCollaborativeSpaces || false}
        >
          <div className={styles.cardContent}>
            <div className={styles.mapContainer}>
              <p className={styles.subText}>Where do your collaborative users choose to meet?</p>
              <div className="content">
                <div className="map">
                  <Map
                    data={mostCollaborativeSpaces}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                  />
                </div>
              </div>
            </div>
            {showTopTen && (
              <div>
                <div className={styles.topTen}>
                  <TopTenList
                    mostCollaborativeSpaces={mostCollaborativeSpaces}
                    loadingMostCollaborativeSpaces={loadingMostCollaborativeSpaces}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={location => setSelectedLocation(location)}
                  />
                </div>
              </div>
            )}
          </div>
        </LoadingWrapper>
      </SolCard>
    </div>
  )
}

export default MostCollaborativeSpaces
