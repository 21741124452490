import {
  OrderBy,
  SELECTABLE_COLUMN,
} from 'components/DataTableSlim/DataTableSlim'
import {
  Direction,
  DisplayOrderableField,
  MetricType,
  OrderByDisplay,
  SettingsGroupType,
} from 'graphql/__generated__/types'
import { ComputeMetrics } from './metric'

export function orderByTransform(
  orderBy: OrderBy,
  selectableField?: DisplayOrderableField,
): OrderByDisplay {
  const { field: orderField, direction: orderDirection }
    = orderBy.field === ComputeMetrics.Hour
      ? { ...orderBy, field: MetricType.MinutesInUse }
      : orderBy

  const direction
    = orderDirection === 'ascending' ? Direction.Asc : Direction.Desc

  let field: DisplayOrderableField | undefined = undefined
  let enumKey = Object.keys(DisplayOrderableField).find(
    key => DisplayOrderableField[key] === orderField?.toUpperCase(),
  )

  if (enumKey) {
    field = DisplayOrderableField[enumKey]
  }

  if (orderField === SELECTABLE_COLUMN && selectableField) {
    field = selectableField
  }

  if (field) {
    return {
      direction,
      field,
    }
  }

  const settingsGroupKey = Object.keys(SettingsGroupType).find(
    key => SettingsGroupType[key] === orderField?.toUpperCase(),
  )

  if (settingsGroupKey) {
    return {
      direction,
      settingsGroupType: SettingsGroupType[settingsGroupKey],
    }
  }

  return {
    direction,
    category: orderField,
  }
}
