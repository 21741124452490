import { Template, SettingsGroupType } from 'graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultSecurityTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Security',
    type: SettingsGroupType.Security,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      adminPassword: {
        secret: '',
      },
      enforcePasswordValidation: false,
      allowLocalConfiguration: true,
      allowWebBrowserConfiguration: true,
      enableEncryption: true,
      displayCertificate: {
        cert: {
          name: '',
          content: {
            secret: '',
          },
        },
        key: {
          name: '',
          content: {
            secret: '',
          },
        },
        password: {
          secret: '',
        },
      },
      useCaCertificate: false,
      caCertificate: {
        name: '',
        content: '',
      },
      redirectHTTPS: false,
      disableCaptivePortal: false,
      disableICMP: false,
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
