import { connect } from 'react-redux'
import SolAlertMessage from './SolAlertMessage'
import { dismissAlert } from 'shared/store/slices/alert'

const mapStateToProps = (state: any): any => {
  return {
    message: state?.alert?.message,
    show: !!state?.alert?.message || false,
    type: state?.alert?.alertType,
  }
}

interface StateFromProps {
  message: string
  show: boolean
  type: 'success' | 'error'
}

interface DispatchFromProps {
  onDismiss: () => void
}

export default connect<StateFromProps, DispatchFromProps>(
  mapStateToProps,
  {
    onDismiss: dismissAlert,
  },
)(SolAlertMessage)
