import React, { useState, useEffect } from 'react'
import { Marker, useLeaflet } from 'react-leaflet'
import { LatLng, divIcon } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import styles from './Map.module.scss'
import MarkerCluster from 'components/Location/Clustering/MarkerCluster'
import { renderToString } from 'react-dom/server'
import MapDot from 'SolComponents/Icons/SolGpsIcon/MapDot'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import { PodsClusterGroup, AlertClusterGroup, HeatCluster } from 'components/Location/Clustering/ClusterTypes'
import HeatMapCluster from 'components/Location/Clustering/HeatMapCluster'

type Props = {
  orgPods: MarkerObject[]
  alertMarkers: MarkerObject[]
  showUsage: boolean
  showAlerts: boolean
}

export function HomeMapClusters(props: Props) {
  const { map } = useLeaflet()
  const [zoom, setZoom] = useState(2)

  useEffect(() => {
    map?.addEventListener('zoomend', () => {
      setZoom(map?.getZoom() ?? zoom)
    })
  }, [])

  const dot = divIcon({
    html: renderToString(<MapDot color="#3388ff" />),
    className: `${styles.podDot}`,
  })

  return (
    <>
      {props.orgPods.length > 0 ? (
        <>
          <MarkerCluster
            mcg={PodsClusterGroup}
            markersList={props.orgPods}
            showCluster
            buttonPopUp
            fitBounds
            type="Pod"
            icon={dot}
          />
          <MarkerCluster
            mcg={AlertClusterGroup}
            showCluster={props.showAlerts}
            markersList={props.alertMarkers}
            buttonPopUp={false}
            fitBounds={false}
            type="Alerts"
            refreshAlertsOnZoom={zoom >= 18}
          />
          <HeatMapCluster markersList={props.orgPods} showCluster={props.showUsage} heatMapCluster={HeatCluster} />
        </>
      ) : (
        <MarkerClusterGroup>
          <Marker icon={dot} position={new LatLng(0, 0)} className={styles.displaynone} />
        </MarkerClusterGroup>
      )}
    </>
  )
}
