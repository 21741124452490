import React from 'react'
import styles from './SolTimePicker.module.scss'
import classNames from 'classnames'
import Flatpickr from 'react-flatpickr'
import { SolTooltip } from 'SolComponents'
import { Icon } from 'semantic-ui-react'

export interface SolTimePicker {
  label?: string | JSX.Element
  required?: boolean
  disabled?: boolean
  value?: string | number | Date
  onChange: (e: any, value: any) => void
  mismatched?: boolean
  note?: string
}

export default function SolTimePickerComponent({
  mismatched,
  note,
  label,
  disabled,
  value = '02:00AM',
  onChange,
}:  SolTimePicker) {
  const labelComponent = (
    <label
      className={classNames(styles.inputGroup, {
        [styles.inputDisabled]: disabled,
      })}
    >
      {label}
      {mismatched
      && <span className={styles.syncStateIcon}>
        <SolTooltip
          isInline
          trigger={<Icon  name="exclamation circle" color="red" />}
          text={`This setting does not match between Solstice Cloud and the 
          Pod. Please review this setting and try again.`}
          position="top left"
        />
      </span>
      }
    </label>
  )

  return (
    <div className={styles.numWrapper}>
      {label && labelComponent}
      <Flatpickr
        // @ts-ignore
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: 'h:i K',
        }}
        className={styles.time}
      />
      {!!note && (
        <div id="testing1234" className={styles.note}>
          {note}
        </div>
      )}
    </div>
  )
}
