import React from 'react'
import { SolButton } from 'SolComponents'
import styles from './SSO.module.scss'
import SsoLabel from './SsoLabel'
import { format } from 'date-fns'

type Props = {
  idp: any
  idpEntityId: string
  signOnURL: string
  certTimestamp?: string
  setCanEdit: (edit: boolean) => void
}

const CloudInfo = ({ idp, signOnURL, certTimestamp, idpEntityId, setCanEdit }: Props) => {
  return (
    <>
      <div className={styles.section}>
        <label>Identity Provider</label>
        <span>{idp.name}</span>
      </div>
      <div className={styles.section}>
        <SsoLabel
          labelTitle="Sign On Url"
          tooltip={
            `the sign on URL of the SSO provider where 
             Solstice Cloud will redirect users to log in
             when a user selects to sign in with SSO.
          `}
        />
        <div className={styles.signOnURL}>{signOnURL}</div>
      </div>
      <div className={styles.section}>
        <SsoLabel
          labelTitle={idp.name === 'Azure'  ? 'Azure AD Identifier' : 'Identity Provider Issuer'}
          tooltip={
            `The entity ID is the unique name for your 
            identity provider account and can be found within their setup dashboard.
          `}
        />
        <div className={styles.signOnURL}>{idpEntityId}</div>
      </div>
      <div className={styles.section}>
        <SsoLabel
          labelTitle="Verification Certificate"
          tooltip="the public x509 certificate provided by your SAML provider"
        />
        <div>last updated:</div>
        <div className={styles.signOnURL}><i>
          {format(new Date(certTimestamp || 0), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')}
        </i></div>
      </div>
      <SolButton basic color="blue" handleClick={() => setCanEdit(true)} text="Edit" />
    </>
  )
}

export default CloudInfo
