import React from 'react'
import { NetworkConfig, UniqueSettingsConfiguration } from 'graphql/__generated__/types'
import { SolNumInput } from 'SolComponents/index'
import styles from './StaticOptions.module.scss'
import UniqueSettingsInput, {
  getTooltipText,
} from 'pages/Templates/UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import { ValidationInput, RegexPattern } from 'components/FormValidation/ValidationInput'

export interface Props {
  section: 'Ethernet' | string
  networkConfig: NetworkConfig
  updateNetworkConfig: (config: NetworkConfig) => void
  getIpAddress: (config: UniqueSettingsConfiguration) => string
  updateUniqueSettingsConfig: (config: UniqueSettingsConfiguration, value: any) => UniqueSettingsConfiguration
  podId?: string
}

export default function StaticOptions({
  section,
  networkConfig,
  updateNetworkConfig,
  getIpAddress,
  updateUniqueSettingsConfig,
  podId,
}: Props) {
  const dns1 = networkConfig?.dns1 || ''
  const dns2 = networkConfig?.dns2 || ''
  const networkPrefixLength = networkConfig?.networkPrefixLength || 24
  const classes = section === 'Ethernet' ? styles.ethernet : styles.vlan
  const size = section === 'Ethernet' ? 'medium' : 'small'
  return (
    <div className={classes}>
      <UniqueSettingsInput
        size={size}
        label="IP Address"
        tooltipText={getTooltipText({
          setting: 'IP Address',
          template: 'Ethernet',
        })}
        getValue={getIpAddress}
        updateConfig={updateUniqueSettingsConfig}
        podId={podId}
        inline
        name={`${section}_ethernet_ip_address`}
        rules={{
          required: true,
          pattern: RegexPattern.IpAddress,
        }}
      />
      <ValidationInput
        size={size}
        name={`${section}_gateway`}
        rules={{
          required: true,
          pattern: RegexPattern.IpAddress,
        }}
        label="Gateway"
        required
        value={networkConfig.gateway ?? ''}
        onChange={(e, value) => {
          updateNetworkConfig({
            ...networkConfig,
            gateway: value.value,
          })
        }}
      />
      <SolNumInput
        min={0}
        max={32}
        required
        label="Network Prefix Length"
        inputSize="large"
        value={networkPrefixLength}
        onChange={num => {
          updateNetworkConfig({
            ...networkConfig,
            networkPrefixLength: num,
          })
        }}
      />
      {section !== 'Ethernet' && <div />}
      <ValidationInput
        size={size}
        name={`${section}_dns1`}
        rules={{ required: true, pattern: RegexPattern.IpAddress }}
        label="DNS 1"
        required
        value={dns1}
        onChange={(e, value) => {
          updateNetworkConfig({
            ...networkConfig,
            dns1: value.value,
          })
        }}
      />
      <ValidationInput
        size={size}
        name={`${section}_dns2`}
        rules={{ pattern: RegexPattern.IpAddress }}
        label="DNS 2"
        required={false}
        value={dns2}
        onChange={(e, value) => {
          updateNetworkConfig({
            ...networkConfig,
            dns2: value.value,
          })
        }}
      />
    </div>
  )
}
