import React from 'react'
import { Loader, SemanticSIZES, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import styles from './SolLoaderIcon.module.scss'

interface Props {
  size?: SemanticSIZES
  className?: string
  text?: string
}

const loader = (size: SemanticSIZES = 'small') => (
  <Loader
    active
    inline
    size={size}
  />
)

function SolLoaderIcon({ size, className, text }: Props) {
  return (
    <div className={classNames(styles.icon, className)}>
      <Popup
        hoverable={!!text}
        content={text}
        trigger={loader(size)}
        disabled={!text}
      />
    </div>
  )
}

export default SolLoaderIcon
