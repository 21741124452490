import React from 'react'
import { Button, Segment } from 'semantic-ui-react'

interface Props {
  categoryInternalName: string
  deleteOption: (categoryInternalName: string, optionInternalName: string) => void
  optionInternalName: string
  optionDisplayName: string
  modalHide: (cancel: boolean) => void
  podCount: number
  alertFilterCount: number
}

export const DeleteOptionConfirm = ({
  categoryInternalName,
  deleteOption,
  optionDisplayName,
  optionInternalName,
  modalHide,
  podCount,
  alertFilterCount,
}: Props) => {
  const style = {
    segment: {
      height: '300px',
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
    },
    h3: {
      textAlign: 'center',
    } as React.CSSProperties,
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    } as React.CSSProperties,
    flexy: {
      flex: '1',
    },
    trunc: (width: number) => {
      return {
        display: 'inline-block',
        verticalAlign: 'bottom',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: `${width}%`,
      } as React.CSSProperties
    },
  }

  return (
    <Segment color="blue" style={style.segment}>
      <h3 style={style.h3}>
        Are you sure you want to delete the <span style={style.trunc(70)}>{optionDisplayName}</span> option?
      </h3>
      <p>
        {podCount} display{podCount > 1 && `s`} belong
        {podCount === 1 && `s`} to the <span style={style.trunc(40)}>{optionDisplayName}</span> option.
        <br />
        {alertFilterCount || 0} alert filter{alertFilterCount > 1 && `s`} belong
        {alertFilterCount === 1 && `s`} to the <span style={style.trunc(40)}>{optionDisplayName}</span> option.
      </p>
      <p>Deleting the option will unapply the option from these pods and remove any associated filters.</p>
      <p>This action can't be undone.</p>
      <p>Display configurations will not be changed.</p>
      <div style={style.flexy} />
      <div style={style.buttons}>
        <Button
          basic
          color="grey"
          content="YES - DELETE"
          onClick={() => {
            // SAAS-434: bugfix
            // TODO: investigate why TS does not protect us from this
            // The problem is the cast of modalProps to type any in the TransitionButtons component.
            // Can be fixed with refactoring
            if (typeof deleteOption === 'function') {
              deleteOption(categoryInternalName, optionInternalName)
              modalHide(true)
            }
          }}
          style={style.flexy}
        />
        <Button color="blue" content="NO - CANCEL" onClick={() => modalHide(true)} style={style.flexy} />
      </div>
    </Segment>
  )
}

export default DeleteOptionConfirm
