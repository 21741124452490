import { MostCollaborativeSpacesQuery, ViewMostCollaborativeSpacesResult } from 'graphql/__generated__/hasura-types'
import React from 'react'
import SolMap from 'SolComponents/SolMap/SolMap'

import styles from './Map.module.scss'
import PodsOnMap from './PodsOnMap'

interface Props {
  data?: MostCollaborativeSpacesQuery
  selectedLocation: ViewMostCollaborativeSpacesResult | null
  setSelectedLocation: Function
}

const Map = ({ data, selectedLocation, setSelectedLocation }: Props) => (
  <SolMap className={styles.map} maxZoom={20} minZoom={2}>
    <PodsOnMap
      data={data}
      showCluster
      zoomToBounds
      selectedLocation={selectedLocation}
      setSelectedLocation={setSelectedLocation}
    />
  </SolMap>
)

export default Map
