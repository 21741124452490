import {
  DisplaySearchOptions,
  ManagementStatusDisplayMetricsQuery,
  ManagementStatusDisplayMetricsQueryVariables,
  ManagementStatusDisplayMetricsDocument,
} from 'graphql/__generated__/types'
import { useQuery } from '@apollo/client'

export const getManagementStatusCounts = (
  options: Pick<DisplaySearchOptions, 'searchTerm' | 'categories' | 'locationFilters'>,
) => {
  const { data } = useQuery<
    ManagementStatusDisplayMetricsQuery,
    ManagementStatusDisplayMetricsQueryVariables
  >(ManagementStatusDisplayMetricsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: options,
    pollInterval: 10000,
  })
  return {
    offline: data?.Offline?.totalRecords ?? 0,
    offlinePending: data?.OfflinePending?.totalRecords ?? 0,
    online: data?.Online?.totalRecords ?? 0,
    unsupported: data?.Unsupported?.totalRecords ?? 0,
    notYetDeployed: data?.NotYetDeployed?.totalRecords ?? 0,
  }
}
