import React from 'react'
import { WapSettings } from 'graphql/__generated__/types'
import styles from './WapSettings.module.scss'

import { SolGrid, SolRow, SolCheckbox } from 'SolComponents'
import AdvancedWap from './AdvancedWap'
import UniqueSettingsInput, {
  getTooltipText,
} from 'pages/Templates/UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import { getWapSsid, setWapSsid, getWapDnsHostname, setWapDnsHostname } from 'shared/config/wifi'

export interface Props {
  config: WapSettings
  updateConfiguration: (newSettings: WapSettings) => void
  podId?: string
}

const ssidTooltipText
  = 'By default, the SSID will be the same as the Pod\'s name. You can change this individually in the pod detail page.'

function WapSettingsCmp({ updateConfiguration, config, podId }: Props) {
  return (
    <div>
      <SolGrid>
        <SolRow>
          <div className={styles.hideSsidWrapper}>
            <div className={styles.hideSsid}>
              <UniqueSettingsInput
                label="SSID"
                tooltipText={ssidTooltipText}
                getValue={getWapSsid}
                updateConfig={setWapSsid}
                name="wap_ssid"
                rules={{ required: true }}
                podId={podId}
                inline
                maxLength={32}
              />
            </div>
            <div className={styles.hideSsid}>
              <SolCheckbox
                label="Hide SSID"
                checked={config?.hideSsid ?? false}
                onClick={val => {
                  updateConfiguration({
                    ...config,
                    hideSsid: val,
                  })
                }}
              />
            </div>
          </div>
          <AdvancedWap config={config} updateConfiguration={updateConfiguration} />
          <div>
            <UniqueSettingsInput
              label="DNS Hostname"
              tooltipText={getTooltipText({
                setting: 'DNS Hostname',
                template: 'Wifi',
              })}
              getValue={getWapDnsHostname}
              updateConfig={setWapDnsHostname}
              podId={podId}
            />
          </div>
        </SolRow>
      </SolGrid>
    </div>
  )
}

export default WapSettingsCmp
