import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { Template, PowerManagementConfiguration } from 'graphql/__generated__/types'
import { SolCheckbox } from 'SolComponents'
import styles from './PowerManagement.module.scss'
import OffHours from './OffHours'
import SuspendOptions from './SuspendOptions'
import SolDropdown from '../../../SolComponents/SolDropdown/SolDropdown'
import { delayOptions } from './PowerManagementTypes'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: PowerManagementConfiguration) => void
}

function PowerManagement({ updateConfiguration, template }: Props) {
  const configuration = template.revision.configuration as PowerManagementConfiguration
  const determineIfNewTemplate = configuration.enableOffHours || configuration.occupancyEnabled
  const [enablePowerManagement, setEnablePowerManagement] = useState<boolean>(determineIfNewTemplate ?? false)
  const powerManagementMode = [
    { key: 0, value: 'enableOffHours', text: 'Based on set schedule' },
    { key: 1, value: 'occupancy', text: 'Based on room occupancy (v5.1+)' },
  ]

  const determineModeDropdown = configuration.occupancyEnabled ? 'occupancy' : 'enableOffHours'

  useEffect(() => {
    setEnablePowerManagement(!!determineIfNewTemplate)
  }, [template])

  return (
    <>
      <Grid.Row className={styles.rowOne}>
        <SolCheckbox
          label="Enable Display Power Management"
          checked={enablePowerManagement}
          onClick={val => {
            setEnablePowerManagement(val)
            updateConfiguration({
              ...configuration,
              enableOffHours: val,
              occupancyEnabled: false,
            })
          }}
          bold
        />
      </Grid.Row>
      {enablePowerManagement && (
        <>
          <Grid.Row className={styles.rowTwo}>
            <Grid.Column className={styles.modeWrapper}>
              <SolDropdown
                options={powerManagementMode}
                size="large"
                label="Select How Power Management is Enabled"
                value={determineModeDropdown}
                onChange={val => {
                  if (val === 'enableOffHours') {
                    updateConfiguration({
                      ...configuration,
                      enableOffHours: true,
                      occupancyEnabled: false,
                    })
                  }
                  if (val === 'occupancy') {
                    updateConfiguration({
                      ...configuration,
                      enableOffHours: false,
                      occupancyEnabled: true,
                    })
                  }
                }}
              />
              {configuration.occupancyEnabled && (
                <>
                  <p className={styles.occupancyHelpText}>
                    In order to use this feature, a USB camera must be plugged into each pod and occupancy
                    sensing must be turned on (in the Room Intelligence template, select "Enable occupancy sensing”).
                  </p>
                  <div className={styles.delayOptions}>
                    <SolDropdown
                      options={delayOptions}
                      value={configuration.occupancyDelayMinutes}
                      size="small"
                      onChange={val => {
                        updateConfiguration({
                          ...configuration,
                          occupancyDelayMinutes: parseInt(val),
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          {configuration.enableOffHours && <OffHours template={template} updateConfiguration={updateConfiguration} />}
          <SuspendOptions template={template} updateConfiguration={updateConfiguration} />
        </>
      )}
    </>
  )
}

export default PowerManagement
