import React from 'react'
import styles from './ProxyOptions.module.scss'
import { Proxy } from '../../../../graphql/__generated__/types'
import { SolTextArea, SolCheckbox, SolInput } from 'SolComponents/index'
import { RegexPattern, ValidationInput } from 'components/FormValidation/ValidationInput'
import { PasswordContainer } from 'pages/Templates/Common/PasswordContainer'
import { ValidationNumInput } from 'components/FormValidation/ValidationNumInput'

export interface Props {
  settings: Proxy
  updateConfig: (newSettings: Proxy) => void
  variant: string
}

function ProxyOptions(props: Props) {
  const { ipAddress, port, username, password, exclusionList, bypassOnSameSubnet = false } = props.settings

  return (
    <div>
      <div className={styles.proxyOptions}>
        <ValidationInput
          name={`${props.variant}-proxyIp`}
          rules={{ required: true }}
          label="Proxy Address"
          value={ipAddress ?? ''}
          onChange={(e, value) =>
            props.updateConfig({
              ...props.settings,
              ipAddress: value.value,
            })
          }
        />
        <ValidationNumInput
          name={`${props.variant}-proxyPort`}
          rules={{ pattern: RegexPattern.PositiveIntegers }}
          min={0}
          max={65535}
          required
          label="Proxy Port"
          inputSize="large"
          value={port ?? 0}
          onChange={(num: number) => {
            props.updateConfig({
              ...props.settings,
              port: num,
            })
          }}
        />
      </div>
      <div className={styles.proxyOptions}>
        <SolInput
          name={`${props.variant}-proxyLoginName`}
          label="Login Name"
          value={username ?? ''}
          onChange={(e, value) =>
            props.updateConfig({
              ...props.settings,
              username: value.value,
            })
          }
        />
        <PasswordContainer
          name={`${props.variant}-proxyPassword`}
          label="Password"
          value={password?.secret}
          hash={password?.secretHash}
          salt={password?.secretSalt}
          size="small"
          onClear={() => {
            props.updateConfig({
              ...props.settings,
              password: {
                secret: '',
              },
            })
          }}
          onChange={(_, value) => {
            props.updateConfig({
              ...props.settings,
              password: {
                secret: value.value,
              },
            })
          }}
        />
      </div>
      <div className={styles.proxyOptions}>
        <SolTextArea
          value={exclusionList ? exclusionList : ''}
          label="Exclusion List"
          onChange={e => {
            props.updateConfig({
              ...props.settings,
              exclusionList: e.target.value,
            })
          }}
          placeholder="Use semicolons to separate entries"
        />
      </div>
      <div className={styles.subnetCheckbox}>
        <SolCheckbox
          label={`Don't use proxy for local addresses on same subnet`}
          checked={bypassOnSameSubnet ? bypassOnSameSubnet : false}
          onClick={value => {
            props.updateConfig({
              ...props.settings,
              bypassOnSameSubnet: value,
            })
          }}
        />
      </div>
    </div>
  )
}

export default ProxyOptions
