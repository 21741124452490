import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { NetworkStatus } from '@apollo/client'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance, OrderBy } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { orderByTransform } from 'shared/domain/pods'
import { setDate, format } from 'date-fns'

const metricTypeValues = Object.values<string>({
  ...MetricType,
  ...ComputeMetrics,
})

const filterAxisOptions = (axisSelected: AxisOptions[]) =>
  axisSelected
    .filter(axis => axis === ComputeMetrics.Hour || (axis && metricTypeValues.includes(axis)))
    .map(res => (res === ComputeMetrics.Hour ? MetricType.MinutesInUse : res)) as MetricType[]

import {
  Display,
  DisplaySearchOptions,
  TotalSelectableDisplaysQuery,
  TotalSelectableDisplaysQueryVariables,
  TotalSelectableDisplaysDocument,
  DisplayOrderableField,
  OrderByDisplay,
  Direction,
  DisplaysQuery,
  DisplaysQueryVariables,
  DisplaysDocument,
  MetricsByDateQuery,
  MetricsByDateQueryVariables,
  MetricsByDateDocument,
  TimeInterval,
  MetricType,
} from 'graphql/__generated__/types'
import { AxisOptions } from './types'
import { ComputeMetrics } from 'shared/domain/metric'
import { OptionDate } from 'shared/types/OptionDate'

interface Props {
  optionDate: OptionDate
  metrics: AxisOptions[]
  timeInterval: TimeInterval
}

export const useTable = ({ optionDate, metrics, timeInterval }: Props) => {
  const { searchValue: searchTerm, page, selectedItems, orderBy } = useDataTable<Display>(
    DataTableInstance.UsageHistory,
  )

  let defaultOrder: OrderByDisplay = {
    direction: Direction.Desc,
    field: DisplayOrderableField.Name,
  }

  if (orderBy) {
    defaultOrder = orderByTransform(orderBy)
  }

  const [orderByParam, setOrderByParam] = useState<OrderByDisplay>(defaultOrder)

  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.UsageHistory)

  const handleOnSort = (order: OrderBy) => setOrderByParam(orderByTransform(order))

  const displaySearchOptions: DisplaySearchOptions = {
    records: page.size,
    searchTerm,
    page: page.number,
    orderBy: orderByParam,
    metrics: optionDate,
    categories: categoryFilters,
    locationFilters: locationFilters,
  }

  if (searchTerm.length === 0) {
    delete displaySearchOptions.searchTerm
  }

  const { data: tableQueryResponse, networkStatus: tableStatus } = useQuery<DisplaysQuery, DisplaysQueryVariables>(
    DisplaysDocument,
    {
      fetchPolicy: 'no-cache',
      variables: {
        options: displaySearchOptions,
      },
    },
  )

  // const result = getSearchIds({ includedIds: selectedItems?.includedIds, excludedIds: selectedItems?.excludedIds })

  const { data: totalDisplaysResponse, networkStatus: totalDisplayStatus } = useQuery<
    TotalSelectableDisplaysQuery,
    TotalSelectableDisplaysQueryVariables
  >(TotalSelectableDisplaysDocument, {
    variables: {
      categories: categoryFilters,
      locationFilters: locationFilters,
      searchTerm,
      displayIds: selectedItems.includedIds,
      excludeDisplayIds: selectedItems.excludedIds,
      isIncludedElementPods: false,
    },
  })

  const { data: chartData, networkStatus: graphStatus } = useQuery<MetricsByDateQuery, MetricsByDateQueryVariables>(
    MetricsByDateDocument,
    {
      variables: {
        options: {
          timeInterval,
          metricTypes: filterAxisOptions(metrics),
          fromDate: optionDate.fromDate,
          toDate:
            timeInterval === TimeInterval.Month
            // swap out toDate's day of the month for the from date's day of month
              ? format( setDate ( new Date(optionDate.toDate),
                new Date(optionDate.fromDate).getDate()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX' )
              : format( new Date(optionDate.toDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX' ),
          displaySearchOptions: {
            displayIds: selectedItems.includedIds,
            excludeDisplayIds: selectedItems.excludedIds,
            categories: categoryFilters,
            locationFilters: locationFilters,
            searchTerm,
          },
        },
      },
    },
  )

  const totalItemsSelectable = totalDisplaysResponse?.totalSelectableDisplays?.totalRecords ?? 0

  const totalItemsSelected = totalDisplaysResponse?.totalSelectedDisplays?.totalRecords ?? 0

  const totalFilteredItems = tableQueryResponse?.displays?.totalRecords || 0

  const netStatus = [tableStatus, totalDisplayStatus]
  const isLoading = netStatus.includes(NetworkStatus.loading) || netStatus.includes(NetworkStatus.setVariables)

  const hasSelectedPills
    = (totalItemsSelected > 0 && totalItemsSelected < totalFilteredItems)
    || (categoryFilters && categoryFilters.length > 0)
    || (locationFilters)
    || (searchTerm && searchTerm.length > 0)

  return {
    totalItemsSelectable,
    totalItemsSelected,
    totalFilteredItems,
    hasSelectedPills,
    isLoading,
    data: tableQueryResponse?.displays?.items,
    searchOptions: {
      ...displaySearchOptions,
      excludedIds: selectedItems.excludedIds,
      includedIds: selectedItems.includedIds,
    },
    chartData,
    graphStatus,
    tableQueryResponse,
    tableStatus,
    totalDisplaysResponse,
    totalDisplayStatus,
    handleOnSort,
    orderByParam,
  }
}
