import React from 'react'
import HomeIcon from 'mdi-react/HomeIcon'
import RocketIcon from 'mdi-react/RocketIcon'
import ChartBarIcon from 'mdi-react/ChartBarIcon'
import TagIcon from 'mdi-react/TagIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import SourceForkIcon from 'mdi-react/SourceForkIcon'
import { SolMenuProps } from './SolMenuWrapper'
import { Menu } from './Menu'
import MenuLink from './MenuLink'
import { solsticeRoutingIcon } from './solsticeRoutingIcon'

export enum ActiveIndex {
  Monitor,
  Analytics,
  Manage,
  Templates,
  Accounts,
  SolsticeRouting,
}

export const childIndexes = {
  [ActiveIndex.Manage]: [
    ActiveIndex.Templates,
    ActiveIndex.Accounts,
  ],
}

export default function SolMenu({
  path,
  updates,
  manageEnabled,
  hasDisplayUpdates,
  userAnalyticsPage,
  locationsPage,
  realtimePage,
  ssoAdminPage,
  manageAccounts,
  managementHistory,
  solsticeRouting,
  dropdown: MenuDropdown,
}: SolMenuProps) {
  const manageActive = path.includes('/manage') || path === '/account'
  const monitorActive = [
    '/availability',
    '/alerts',
    '/deployment',
  ].includes(path)
  const analyticsActive = [
    '/usage-history',
    '/compare',
    '/in-room-tech',
    '/user-trends',
    '/real-time',
  ].includes(path)
  const templatesActive = path.includes('/template')
  const accountsActive = path.includes('/accounts')
  const solsticeRoutingActive = path.includes('/solstice-routing')

  return (
    <Menu>
      <MenuLink
        path="/home"
        icon={HomeIcon}
        label="Home"
      />
      <MenuDropdown
        activeIndex={ActiveIndex.Monitor}
        active={monitorActive}
        icon={RocketIcon}
        label="Monitor"
      >
        <MenuLink
          child
          path="/availability"
          label="Availability"
        />
        <MenuLink
          child
          path="/deployment"
          label="Deployment"
        />
        <MenuLink
          child
          path="/alerts"
          label="Alerts"
        />
        <MenuLink
          hidden={!managementHistory}
          child
          path="/history"
          label="History"
        />
      </MenuDropdown>
      <MenuDropdown
        activeIndex={ActiveIndex.Analytics}
        active={analyticsActive}
        icon={ChartBarIcon}
        label="Analytics"
      >
        <MenuLink
          child
          path="/usage-history"
          label="Usage"
        />
        <MenuLink
          child
          path="/compare"
          label="Compare"
        />
        <MenuLink
          hidden={!userAnalyticsPage}
          child
          path="/user-trends"
          label="User Trends"
        />
        <MenuLink
          child
          path="/in-room-tech"
          label="In-Room Tech"
        />
        <MenuLink
          hidden={!realtimePage}
          child
          path="/real-time"
          label="Realtime"
        />
      </MenuDropdown>
      <MenuDropdown
        hidden={!manageEnabled}
        active={manageActive}
        activeIndex={ActiveIndex.Manage}
        icon={SettingsIcon}
        label="Manage"
      >
        <MenuLink
          hidden={!hasDisplayUpdates}
          child
          path="/manage/pods"
          label="Pods"
        />
        <MenuDropdown
          hidden={!hasDisplayUpdates}
          child
          activeIndex={ActiveIndex.Templates}
          active={templatesActive}
          label="Templates"
        >
          <MenuLink
            grandchild
            path="/manage/templates/welcome-screen"
            label="Welcome Screen"
          />
          <MenuLink
            grandchild
            path="/manage/templates/calendar"
            label="Calendar"
          />
          <MenuLink
            grandchild
            path="/manage/templates/features"
            label="Features"
          />
          <MenuLink
            grandchild
            path="/manage/templates/digital-signage"
            label="Digital Signage"
          />
          <MenuLink
            grandchild
            path="/manage/templates/room-intelligence"
            label="Room Intelligence"
          />
          <MenuLink
            grandchild
            path="/manage/templates/ethernet"
            label="Ethernet"
          />
          <MenuLink
            grandchild
            path="/manage/templates/proxy"
            label="Proxy"
          />
          <MenuLink
            grandchild
            path="/manage/templates/wifi"
            label="WiFi"
          />
          <MenuLink
            grandchild
            path="/manage/templates/discovery"
            label="Discovery"
          />
          <MenuLink
            grandchild
            path="/manage/templates/security"
            label="Security"
          />
          <MenuLink
            grandchild
            path="/manage/templates/locale"
            label="Time/Locale"
          />
          <MenuLink
            grandchild
            path="/manage/templates/advanced"
            label="Advanced"
          />
          <MenuLink
            grandchild
            path="/manage/templates/power"
            label="Power Management"
          />
          <MenuLink
            grandchild
            path="/manage/templates/message"
            label="Message Center"
          />
        </MenuDropdown>
        <MenuLink
          hidden={!locationsPage}
          child
          path="/manage/location"
          label="Location"
        />
        <MenuLink
          child
          path="/manage/usb"
          label="USB Devices"
        />
        <MenuLink
          hidden={!hasDisplayUpdates}
          child
          path="/manage/updates"
          label="Updates"
          notification={updates || undefined}
        />
        <MenuDropdown
          hidden={!manageAccounts}
          activeIndex={ActiveIndex.Accounts}
          active={accountsActive}
          child
          label="Organization"
        >
          <MenuLink
            grandchild
            path="/manage/organization/users"
            label="Users"
          />
          <MenuLink
            hidden={!ssoAdminPage}
            grandchild
            path="/manage/organization/sso"
            label="SSO"
          />
        </MenuDropdown>
      </MenuDropdown>
      <MenuLink
        path="/categories"
        icon={TagIcon}
        label="Categories"
      />
      <MenuLink
        path="/active-learning"
        icon={SourceForkIcon}
        label="Active Learning"
      />
      <MenuDropdown
        activeIndex={ActiveIndex.SolsticeRouting}
        active={solsticeRoutingActive}
        hidden={!solsticeRouting}
        icon={solsticeRoutingIcon}
        label="Solstice Routing"
      >
        <MenuLink
          path="/solstice-routing"
          label="Spaces"
        />
        <MenuLink
          path="/solstice-routing/settings"
          label="Settings"
        />
      </MenuDropdown>
    </Menu>
  )
}
