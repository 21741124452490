import { useState, useEffect } from 'react'
import {
  useUserDevicesQuery,
  useScreenResolutionsQuery,
  useClientVersionsQuery,
  useContentSharingTypeQuery, // stopped refreshing the materialized view: content_sharing_types , because of performance concerns. So there is never any data in sharingData. See BUG-4725
  useContentSharingAppsGroupsQuery,
  OrderBy,
  useContentSharingAppNamesLazyQuery,
  useHybridMeetingsQuery,
  useConferenceVendorsQuery,
  UserDevicesQuery,
  ScreenResolutionsQuery,
  ClientVersionsQuery,
} from 'graphql/__generated__/hasura-types'
import { DisplaySearchOptions, PlatformConfigDocument, PlatformConfigQuery } from 'graphql/__generated__/types'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { usePagination } from 'shared/hooks/usePagination'
import { cool } from 'assets/palettes'
import { getSearchIds } from 'components/DataTableSlim/Utils/hasuraUtils'
import { set, addDays, format } from 'date-fns'
import { getVersionLabel, toPercentage } from 'shared/core/utils'
import { useReleases } from '../../shared/hooks/versions'
import { Release } from '../../shared/hooks/versions/useVersionUtils'
import { useQuery } from '@apollo/client'

type ScreenResolution = ScreenResolutionsQuery['screenResolutions']['nodes'][number] & { name?: string }
type ClientVersion = ClientVersionsQuery['clientVersions']['nodes'][number] & { name?: string }

interface Props {
  fromDate: string
  toDate: string
  interval: string
  searchOptions: { includedIds?: string[]; excludedIds?: string[] } & Pick<
    DisplaySearchOptions,
    'searchTerm' | 'categories'
  >
}

interface SoftwareUsedState {
  isGrouped: boolean
  categoryName: string | undefined
}

export const MAX_PER_PAGE = 7

const initPagination = { limit: MAX_PER_PAGE, offset: 0 }

const getTotal = (data: UserDevicesQuery['userDevices']): number => data.reduce((acc, record) => acc + +record.total, 0)

const getTotalsByDevice = (data: UserDevicesQuery['userDevices']): Record<string, PieSector> => {
  const palette = cool(6)
  const total = getTotal(data)
  const devices = {
    macos: { name: 'macOS', count: 0, percentage: 0 },
    android: { name: 'Android', count: 0, percentage: 0 },
    windows: { name: 'Windows', count: 0, percentage: 0 },
    ios: { name: 'iOS', count: 0, percentage: 0 },
    apple_airplay: { name: 'Clientless', count: 0, percentage: 0 },
  }

  return data.reduce((acc, record, index) => {
    const platform = record.clientPlatform?.toLowerCase() ?? ''

    if (platform === 'apple' || platform === 'ios') {
      acc.ios.count = +(+record.total + +acc.ios.count).toFixed(1)
      acc.ios.percentage = toPercentage(acc.ios.count, total, 1)
    } else {
      if (!!acc[platform]) {
        acc[platform].count = +(+record.total).toFixed(1)
        acc[platform].percentage = toPercentage(acc[platform].count, total, 1)
      }
    }

    if (!!acc[platform]) {
      acc[platform].fill = palette[index % palette.length]
    }

    return acc
  }, devices)
}

export const getTotalResolutions = (screenResolutions: ScreenResolution[], totalCount: number): PieSector[] => {
  const palette = cool(screenResolutions.length)
  return screenResolutions.map((item, index) => {
    const { total, screenWidth, screenHeight, name } = item
    const screenName = `${screenWidth}x${screenHeight}`

    return {
      name: name || screenName,
      count: total,
      percentage: toPercentage(total, totalCount, 1),
      fill: palette[index % palette.length],
    }
  })
}

export const getTotalVersions = (versions: ClientVersion[], totalCount: number, releases?: Release[]): PieSector[] => {
  const palette = cool(versions.length)
  return versions.map((item, index) => {
    const { total, clientVersion, name } = item
    const label: string | undefined = releases
      ? getVersionLabel(releases, clientVersion)
      : undefined

    const versionName = label || `v${clientVersion}`

    return {
      name: name || versionName,
      count: total,
      percentage: toPercentage(total, totalCount, 1),
      fill: palette[index % palette.length],
    }
  })
}

export function groupOtherValue<Item extends { total?: number; name?: string }>(list: Item[]): Item[] {
  if (list.length > MAX_PER_PAGE) {
    const others = list.slice(MAX_PER_PAGE, list.length).reduce(
      (acc, item) => {
        return { ...acc, total: acc.total + (item.total ?? 0) }
      },
      { name: 'Other', total: 0 },
    ) as Item

    const values = list.slice(0, MAX_PER_PAGE)

    return [...values, others]
  }

  return list
}

export const useCards = ({ fromDate, toDate, interval, searchOptions }: Props) => {
  const modifiedToDate = set(addDays(new Date(toDate), 1), { hours: 0, minutes: 0, seconds: 0 })
  const args: Record<string, any> = {
    variables: {
      args: {
        from_date: fromDate,
        to_date: modifiedToDate,
        categories: searchOptions.categories,
        search: searchOptions.searchTerm,
        ...getSearchIds({
          includedIds: searchOptions.includedIds,
          excludedIds: searchOptions.excludedIds,
        }),
      },
      order_by: [{ total: OrderBy.Desc }],
    },
  }
  const hybridArgs: Record<string, any> = {
    variables: {
      args: {
        from_date: fromDate,
        to_date: interval === 'month'
          ? format(set(new Date(toDate), { date: new Date(fromDate).getDate() }), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
          : format( new Date(toDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX' ),
        di: interval,
        categories: searchOptions.categories,
        search: searchOptions.searchTerm,
        ...getSearchIds({
          includedIds: searchOptions.includedIds,
          excludedIds: searchOptions.excludedIds,
        }),
      },
    },
  }

  const [softwareUsedState, setSoftwareUsedState] = useState<SoftwareUsedState>({
    isGrouped: true,
    categoryName: undefined,
  })

  const { isGrouped, categoryName } = softwareUsedState

  const appPagination = usePagination(initPagination)

  const groupPagination = usePagination(initPagination)

  const { offset: offsetApps } = appPagination

  const { offset: offsetGroup } = groupPagination

  const contentQueryVars = { variables: { args: { ...args.variables.args } } }

  const { data: dataDevices, loading: isDeviceLoading } = useUserDevicesQuery(args)

  const { data: dataScreens, loading: isScreenLoading } = useScreenResolutionsQuery(args)

  const { data: dataVersions, loading: isVersionLoading } = useClientVersionsQuery(args)

  const { data: dataContentTypes, loading: isTypeLoading } = useContentSharingTypeQuery(contentQueryVars)

  const { data: hybridMeetings, loading: isHybridMeetingsLoading } = useHybridMeetingsQuery(hybridArgs)

  const { data: conferenceVendors, loading: isConferenceVendorsLoading } = useConferenceVendorsQuery(hybridArgs)

  const { data: dataAppGroups, loading: isGroupsLoading } = useContentSharingAppsGroupsQuery({
    variables: {
      ...contentQueryVars.variables,
      limit: MAX_PER_PAGE,
      offset: offsetGroup,
    },
  })

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).
    data?.
    platformConfig?.
    hasElementPods

  const releases = useReleases()

  // should only get data when ungrouped is false or there is a category selected
  const [getAppNames, { data: dataAppNames, loading: isAppNamesLoading }] = useContentSharingAppNamesLazyQuery()

  const totalsByDevice = getTotalsByDevice(dataDevices?.userDevices || [])

  const groupScreens = groupOtherValue<ScreenResolution>(dataScreens?.screenResolutions.nodes || [])

  const groupVersions = groupOtherValue<ClientVersion>(dataVersions?.clientVersions.nodes || [])

  const isCategoryActive = !!softwareUsedState.categoryName

  const handleAxisYSoftwareUsed = (category: string) => {
    setSoftwareUsedState(state => ({ ...state, categoryName: category }))
  }

  const handleCategoryActive = (isActive: string) => {
    setSoftwareUsedState(state => ({ ...state, categoryName: isActive }))
    appPagination.reset()
  }

  const handleGrouping = (grouped: boolean) => {
    setSoftwareUsedState(state => ({ ...state, isGrouped: grouped }))
    appPagination.reset()
  }

  useEffect(() => {
    if (!!dataAppNames?.count.aggregate?.total) {
      appPagination.setParams({ total: dataAppNames?.count.aggregate.total })
    }
  }, [dataAppNames])

  useEffect(() => {
    if (!!dataAppGroups?.count.aggregate?.total) {
      groupPagination.setParams({ total: dataAppGroups?.count.aggregate.total })
    }
  }, [dataAppGroups])

  useDeepCompareEffect(() => {
    if (!!dataAppNames?.count.aggregate?.total) {
      appPagination.reset()
    }
  }, [args])

  useDeepCompareEffect(() => {
    if (!isGrouped || !!categoryName) {
      const appNamesVars = !!categoryName
        ? {
          variables: {
            args: {
              ...contentQueryVars.variables.args,
              app_group: categoryName,
            },
            limit: MAX_PER_PAGE,
            offset: offsetApps,
          },
        }
        : {
          variables: {
            ...contentQueryVars.variables,
            limit: MAX_PER_PAGE,
            offset: offsetApps,
          },
        }

      getAppNames(appNamesVars)
    }
  }, [args, isGrouped, categoryName, offsetApps])

  return {
    devices: Object.values(totalsByDevice).sort((a, b) => b.count - a.count) || undefined,
    versions: dataVersions
      ? getTotalVersions(groupVersions, dataVersions?.clientVersions.aggregate?.sum?.total, releases)
      : undefined,
    screens: dataScreens
      ? getTotalResolutions(groupScreens, dataScreens.screenResolutions.aggregate?.sum?.total)
      : undefined,
    sharingTypes: dataContentTypes?.sharingTypes,
    contentApp: isCategoryActive || (!isCategoryActive && !isGrouped) ? dataAppNames : dataAppGroups,
    pagination: isCategoryActive || (!isCategoryActive && !isGrouped) ? appPagination : groupPagination,
    isLoading:
      isDeviceLoading
      || isScreenLoading
      || isVersionLoading
      || isTypeLoading
      || isGroupsLoading
      || isAppNamesLoading
      || isHybridMeetingsLoading
      || isConferenceVendorsLoading,
    isTypeLoading,
    isHybridMeetingsLoading,
    isConferenceVendorsLoading,
    hybridMeetings: hybridMeetings?.hybridMeetings,
    conferenceVendors: conferenceVendors?.conferenceVendors,
    isAppSharing: isGroupsLoading || isAppNamesLoading,
    softwareUsedState,
    handleAxisYSoftwareUsed,
    handleCategoryActive,
    handleGrouping,
    hasElementPods,
  }
}
