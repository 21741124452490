import React from 'react'
import { Icon, Label } from 'semantic-ui-react'
import styles from './Alerts.module.scss'
import groupBy from 'lodash/groupBy'
import { Filter, CombinedFilter } from './types'
import { useDisplayCountQuery } from 'graphql/__generated__/hasura-types'
import { Category } from 'shared/types/categories'

export interface Props {
  categories: Category[]
  filters: Filter[]
  removeFilter: (filter: Partial<Filter>) => any | void
  removeAll: () => any | void
  editing?: boolean
  active?: boolean
}

const DisplayFilter = ({ filter, categories }: { filter: CombinedFilter; categories: Category[] }) => {
  const { data, loading } = useDisplayCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        _or: filter.optionInternalNames.map(element => ({
          categories: { option_id: { _eq: element.toString() } },
        })),
      },
    },
  })
  const count = loading ? '' : `(${data?.totalDisplays?.aggregate?.count})`

  const selected = categories.find(o => o.id === filter.categoryInternalName)
  const names = filter.optionInternalNames.reduce((acc, current) => {
    const option = selected?.options.find(o => o.id === current)
    const optionName = option?.displayName
    return optionName ? [...acc, optionName] : acc
  }, [])

  return (
    <span className={styles.labelPill}>
      {names.join(' OR ')}&nbsp;&nbsp;{count}
    </span>
  )
}

export function AlertFilterPills(props: Props) {
  const removeFilterByCategory = (categoryInternalName: string) => {
    props.removeFilter({ categoryInternalName })
  }

  const filtersArray = Object.entries(groupBy(props.filters, 'categoryInternalName'))
    .map(([categoryInternalName, options]) => ({
      categoryInternalName,
      optionInternalNames: options.map(_ => _.optionInternalName ?? ''),
    }))
    .sort()

  return (
    <div className={styles.pillContainer}>
      <Label.Group color={props.active ? `blue` : `grey`} circular>
        {filtersArray.map((filter, i) => (
          <Label key={i} className={styles.pill}>
            <DisplayFilter filter={filter} categories={props.categories} />
            <Icon
              name="close"
              className={styles.closeIcon}
              onClick={() => removeFilterByCategory(filter.categoryInternalName)}
            />
          </Label>
        ))}
      </Label.Group>
      {props.editing ? (
        <a className={styles.removeAll} onClick={() => props.removeAll()}>
          remove all
          <Icon name="trash" />
        </a>
      ) : null}
    </div>
  )
}

export default AlertFilterPills
