import React from 'react'
import { Header } from 'semantic-ui-react'

import { SolCard } from 'SolComponents'
import styles from './index.module.scss'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import {
  SessionsPerUserQuery,
  AvgHoursSpentPerUserQuery,
  AvgRoomsVisitedQuery,
  UniqueUsersCountQuery,
} from 'graphql/__generated__/hasura-types'

interface Props {
  sessionsPerUser?: SessionsPerUserQuery
  loadingSessionsPerUser?: boolean
  hoursPerUser?: AvgHoursSpentPerUserQuery
  loadingHoursPerUser?: boolean
  roomsVisited?: AvgRoomsVisitedQuery
  loadingRoomsVisited?: boolean
  usersCount?: UniqueUsersCountQuery
  loadingUsersCount?: boolean
}

const KeyValue = ({ label, value }: { label: string; value: number }) => {
  const rounded = Math.round(value * 100) / 100
  return (
    <div>
      <div className={styles.value}>{rounded}</div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export const SingleResultMetrics = ({
  sessionsPerUser,
  loadingSessionsPerUser,
  hoursPerUser,
  loadingHoursPerUser,
  roomsVisited,
  loadingRoomsVisited,
  usersCount,
  loadingUsersCount,
}: Props) => {
  const loading = loadingSessionsPerUser || loadingHoursPerUser || loadingRoomsVisited || loadingUsersCount || false

  return (
    <div data-testid="single-result" className="single-result-metrics">
      <LoadingWrapper isEmpty={false} isLoading={loading}>
        <div className={styles.result}>
          <KeyValue value={usersCount?.usersCount[0]?.value || 0} label="# of unique users" />
        </div>
        <div className={styles.result}>
          <KeyValue value={sessionsPerUser?.sessionsPerUser[0]?.value || 0} label="Sessions/user" />
        </div>
        <div className={styles.result}>
          <KeyValue value={hoursPerUser?.hoursPerUser[0]?.value || 0} label="Avg hours spent" />
        </div>
        <div className={styles.result}>
          <KeyValue value={roomsVisited?.roomsVisited[0]?.value || 0} label="Avg rooms visited" />
        </div>
      </LoadingWrapper>
    </div>
  )
}

const DailyActiveUsers = ({
  sessionsPerUser,
  loadingSessionsPerUser,
  hoursPerUser,
  loadingHoursPerUser,
  roomsVisited,
  loadingRoomsVisited,
  usersCount,
  loadingUsersCount,
}: Props) => {
  return (
    <SolCard
      className={styles.dailyCard}
      header={
        <div>
          <Header className={styles.header} as="h3" textAlign="left" content="Daily Active Users" />
        </div>
      }
    >
      <div className={styles.content}>
        <SingleResultMetrics
          sessionsPerUser={sessionsPerUser}
          loadingSessionsPerUser={loadingSessionsPerUser}
          hoursPerUser={hoursPerUser}
          loadingHoursPerUser={loadingHoursPerUser}
          roomsVisited={roomsVisited}
          loadingRoomsVisited={loadingRoomsVisited}
          usersCount={usersCount}
          loadingUsersCount={loadingUsersCount}
        />
      </div>
    </SolCard>
  )
}

export default DailyActiveUsers
