import React, { useEffect, useRef } from 'react'
import styles from './route.module.scss'

/**
 * A route as a visual and draggable svg element on the pod grid.
 * Mostly a line between two pods (or the user's cursor as they drag)
 * representing a preconfigured routing of video content from one pod
 * to another.
 */


interface Props {
    x1: number
    y1: number
    x2: number
    y2: number
    color: string
    scale?: number
    index: number
    isAnimated?: boolean
    // The duration in seconds it will take an animated circle to travel the route 
    animationPeriod?: number
    animationDelay?: number
    handleRouteDown?: (index: number, evt: any) => void
}

export const Route = ({
  x1, x2, y1, y2,
  color,
  scale = 1.0,
  index,
  isAnimated = false,
  animationPeriod = 3,
  animationDelay = 0,
  handleRouteDown,
}: Props) => {

  /**
   * Here we're ensuring that any time a route's coordinates change, we pass
   * that along to some CSS variables (starts with --) driving animation
   * through CSS for performance
   */
  const circleRef = useRef(null as null| SVGCircleElement)
  useEffect(() => {
    circleRef?.current?.style.setProperty('--startX', x1.toString())
    circleRef?.current?.style.setProperty('--startY', y1.toString())
    circleRef?.current?.style.setProperty('--endX', x2.toString())
    circleRef?.current?.style.setProperty('--endY', y2.toString())
    circleRef?.current?.style.setProperty('--animationPeriod', animationPeriod + 's')
    circleRef?.current?.style.setProperty('--delay', animationDelay + 's')
  }, [x1, x2, y1, y2])


  return (
    <g>
      {/* invisible line on top that is easier to click */}
      <line
        key={'route_clickable' + x1 + y1 + x2 + y2}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="black"
        strokeOpacity="0.0"
        strokeWidth={16 * scale}
        onPointerDown={evt => {if (handleRouteDown)handleRouteDown(index, evt)}}
      ></line>

      {/* visible line, would be hard to click */}
      <line
        className={styles.routeLine}
        key={'route' + x1 + y1 + x2 + y2}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth={3 * scale}
        onPointerDown={evt => {if (handleRouteDown)handleRouteDown(index, evt)}}
      ></line>

      {/* animated circles travel down the route, source to sink */}
      {isAnimated && <circle
        ref={circleRef}
        className={styles.routeCircle}
        r={8 * scale}
        fill={color}
      ></circle>}
    </g>
  )
}
