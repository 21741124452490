import React, { useState } from 'react'
import styles from './TemplatePage.module.scss'
import { Dimmer, Icon, Loader, Message } from 'semantic-ui-react'
import { SettingsGroupType, Template } from 'graphql/__generated__/types'
import {
  useCreateTemplate,
  useDeleteTemplate,
  useGetTemplates,
  useUpdateTemplate,
  useAssignTemplates,
} from 'shared/hooks/management/useTemplates'
import LoadingBar from 'components/Loaders/LoadingBar'
import UniqueSettings, { Props as UniqueSettingsProps } from 'pages/Templates/UniqueSettings/UniqueSettings'
import { SolTemplateCard, SolNotificationCard } from 'SolComponents'
import { FormValidation } from 'shared/context/FormValidation'
import SolTooltipWithLink from '../../SolComponents/SolTooltipWithLink/SolTooltipWithLink'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import PlusCircleIcon from 'mdi-react/PlusCircleIcon'
import RefreshIcon from 'mdi-react/RefreshIcon'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useFlags } from 'launchdarkly-react-client-sdk'

type UniqueSettingsWorkflow = {
  drawerIsOpen: boolean
} & Omit<UniqueSettingsProps, 'onClose' | 'assignTemplates'>

export interface Props {
  defaultTemplate: Template
  settingsGroup: SettingsGroupType
}

const settingsGroupTitles = new Map([
  [SettingsGroupType.WelcomeScreen, 'Welcome Screen'],
  [SettingsGroupType.Ethernet, 'Ethernet'],
  [SettingsGroupType.Discovery, 'Discovery'],
  [SettingsGroupType.DigitalSignage, 'Digital Signage'],
  [SettingsGroupType.TimeLocale, 'Time/Locale'],
  [SettingsGroupType.Advanced, 'Advanced'],
  [SettingsGroupType.Proxy, 'Proxy'],
  [SettingsGroupType.Features, 'Features'],
  [SettingsGroupType.PowerManagement, 'Power Management'],
  [SettingsGroupType.MessageCenter, 'Message Center'],
  [SettingsGroupType.Calendar, 'Calendar'],
  [SettingsGroupType.Security, 'Security'],
  [SettingsGroupType.Wifi, 'WiFi'],
  [SettingsGroupType.RoomIntelligence, 'Room Intelligence'],
])

const learnMoreLinks = new Map([
  [
    SettingsGroupType.WelcomeScreen,
    'https://documentation.mersive.com/en/welcome-screen-template.html',
  ],
  [SettingsGroupType.Ethernet, 'https://documentation.mersive.com/en/ethernet-template.html'],
  [SettingsGroupType.Discovery, 'https://documentation.mersive.com/en/discovery-template.html'],
  [
    SettingsGroupType.DigitalSignage,
    'https://documentation.mersive.com/en/digital-signage-template.html',
  ],
  [SettingsGroupType.TimeLocale, 'https://documentation.mersive.com/en/time-locale-template.html'],
  [SettingsGroupType.Advanced, 'https://documentation.mersive.com/en/advanced-template.html'],
  [SettingsGroupType.Proxy, 'https://documentation.mersive.com/en/proxy-template.html'],
  [SettingsGroupType.Features, 'https://documentation.mersive.com/en/features-template.html'],
  [SettingsGroupType.PowerManagement, 'https://documentation.mersive.com/en/power-management-template.html'],
  [
    SettingsGroupType.MessageCenter,
    'https://documentation.mersive.com/en/message-center-template.html',
  ],
  [SettingsGroupType.Calendar, 'https://documentation.mersive.com/en/calendar-template.html'],
  [SettingsGroupType.Security, 'https://documentation.mersive.com/en/security-template.html'],
  [SettingsGroupType.Wifi, 'https://documentation.mersive.com/en/wifi-template.html'],
  [
    SettingsGroupType.RoomIntelligence,
    'https://documentation.mersive.com/en/room-intelligence-template.html',
  ],
])

const TemplatePage = React.memo(function(props: Props) {
  const { podConfigurationService } = useFlags() // Todo remove when config service released

  const [showNewTemplate, setShowNewTemplate] = useState(false)
  const [newTemplate, setNewTemplate] = useState<Template>(props.defaultTemplate)
  const [uniqueSettingsWorkflow, setUniqueSettingsWorkflow] = useState<UniqueSettingsWorkflow>({
    drawerIsOpen: false,
  })
  const [showUpdateNotification, setShowUpdateNotification] = useState(false)

  const { data, refetch, loading } = useGetTemplates(props.settingsGroup)

  const loaded = data || !loading

  const templates = data ?? []

  const { createTemplate, creatingTemplate } = useCreateTemplate()
  const { updateTemplate, updatingTemplate } = useUpdateTemplate()
  const { deleteTemplate, deletingTemplate } = useDeleteTemplate()
  const saving = creatingTemplate || updatingTemplate || deletingTemplate
  const { assignTemplates } = useAssignTemplates()

  const saveTemplate = async (template: Template) => {
    let response
    if (template.id === '') {
      response = await createTemplate({
        name: template.name,
        settingsGroupType: props.settingsGroup,
        configuration: template.revision.configuration,
      })

      refetch()
      setShowNewTemplate(false)
      setNewTemplate(props.defaultTemplate)
      return response.data?.createTemplate as Template
    }
    response = await updateTemplate({
      id: template.id,
      isCustom: template.isCustom,
      isDefault: template.isDefault,
      name: template.name,
      settingsGroupType: template.settingsGroup.type,
      previousRevisionId: template.revision.id,
      configuration: template.revision.configuration,
    })

    if (response.data?.updateTemplate.validation.incompatibleDisplays?.length !== 0) {
      setUniqueSettingsWorkflow({
        drawerIsOpen: true,
        ...response.data?.updateTemplate,
      } as any)
      return null
    }

    return response.data?.updateTemplate.template as Template
  }

  const handleDeleteTemplate = (id: string) => {
    return async () => {
      try {
        await deleteTemplate(id)
      } catch (err) {
        /* eslint-disable-next-line no-console */
        console.error(err.message)
      } finally {
        refetch()
      }
    }
  }

  const completeUniqueSettings = (completed: boolean) => {
    setUniqueSettingsWorkflow({ drawerIsOpen: false })
    // Todo Remove flag after config service is released
    if (podConfigurationService) {
      if (completed && uniqueSettingsWorkflow?.template) {
        saveTemplate(uniqueSettingsWorkflow.template)
      }
      if (!completed) {
        refetch()
      }
    }
  }

  const duplicateTemplate = (template: Template) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    setShowNewTemplate(false)
    setNewTemplate({
      ...template,
      name: template.name + ' - Copy',
      id: '',
      isDefault: false,
    })
    setShowNewTemplate(true)
  }

  const cancelNewTemplate = () => {
    setShowNewTemplate(false)
    setNewTemplate(props.defaultTemplate)
  }

  const settingsGroupTitle = settingsGroupTitles.get(props.settingsGroup)
  const learnMoreLink = learnMoreLinks.get(props.settingsGroup)

  return (
    <ContentWrapper>
      <LoadingBar visible={saving} header />
      {!loaded && (
        <Dimmer active>
          <Loader size="huge">Loading...</Loader>
        </Dimmer>
      )}
      {showUpdateNotification && (
        <SolNotificationCard onClose={() => setShowUpdateNotification(false)} className={styles.updateNotification}>
          <div className={styles.cardContent}>
            <RefreshIcon className={styles.refreshIcon} onClick={() => window.location.reload()} />
            <p>
              This page has been updated.&nbsp;&nbsp;
              <a onClick={() => window.location.reload()}>Refresh</a>
            </p>
          </div>
        </SolNotificationCard>
      )}
      {loaded && (
        <div className={styles.header}>
          <span className={styles.grey}>Templates</span>
          <Icon name="angle right" color="grey" />
          <span>{settingsGroupTitle}</span>
          <span className={styles.helpTooltip}>
            <SolTooltipWithLink
              trigger={<SolQuestionCircle className={styles.questionIcon} />}
              text={`Need help? Learn how to set up your
              ${settingsGroupTitle !== 'WiFi' ? settingsGroupTitle?.toLowerCase() : settingsGroupTitle}
              templates.`}
              buttonText="VIEW DOCUMENTATION"
              position="bottom left"
              isExternalLink
              linkUrl={learnMoreLink}
            />
          </span>
        </div>
      )}
      {loaded && !showNewTemplate && (
        <Message icon onClick={() => setShowNewTemplate(true)} className={styles.createNew} floating>
          <PlusCircleIcon size="35" className={styles.plus} />
          <Message.Content>
            <Message.Header>Create New {settingsGroupTitle} Template</Message.Header>
          </Message.Content>
        </Message>
      )}
      {loaded && showNewTemplate && (
        <SolTemplateCard
          view="Templates"
          expanded
          template={newTemplate}
          notifyUpdate={() => setShowUpdateNotification(true)}
          onDuplicateTemplate={duplicateTemplate}
          onCancel={cancelNewTemplate}
          onSave={saveTemplate}
        />
      )}
      {loaded
        && templates.map(template => (
          <SolTemplateCard
            key={template.id}
            view="Templates"
            template={template}
            notifyUpdate={() => setShowUpdateNotification(true)}
            onSave={saveTemplate}
            onDuplicateTemplate={duplicateTemplate}
            deleteTemplate={handleDeleteTemplate(template.id)}
            totalAssignedDisplays={template?.assignedDisplays?.totalRecords ?? undefined}
          />
        ))}
      <FormValidation>
        <UniqueSettings
          onClose={completeUniqueSettings}
          assignTemplates={assignTemplates}
          {...uniqueSettingsWorkflow}
        />
      </FormValidation>
    </ContentWrapper>
  )
})

export default TemplatePage
