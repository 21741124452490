import React from 'react'
import NumericInput, { NumericInputProps } from 'react-numeric-input'
import styles from './SolNumInput.module.scss'
import classNames from 'classnames'

export type Props = {
  required?: boolean
  disabled?: boolean
  label?: string
  value: number
  onChange: (num: number) => void
  inputSize?: 'medium' | 'large'
  className?: string
  containerClassName?: string
  error?: boolean
} & Omit<NumericInputProps, 'onChange'>

export default function SolNumInput({
  required,
  label,
  onChange,
  inputSize,
  className,
  containerClassName,
  error,
  ...rest
}: Props) {
  const labelComponent = (
    <label>
      {label}
      {required && <span className={styles.required}>*</span>}
    </label>
  )
  return (
    <div className={classNames(styles.numInput, containerClassName)}>
      {!!label && labelComponent}
      <div
        className={classNames({
          [styles.medium]: inputSize === 'medium',
          [styles.large]: inputSize === 'large',
        })}
      >
        <NumericInput
          strict
          className={classNames(styles.input, className, {
            [styles.medium]: inputSize === 'medium',
            [styles.large]: inputSize === 'large',
            [styles.error]: error,
          })}
          noStyle
          type="number"
          onChange={val => {
            onChange(val ?? 0)
          }}
          {...rest}
        />
      </div>
    </div>
  )
}
