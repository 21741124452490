import React from 'react'
import { Dropdown, Checkbox } from 'semantic-ui-react'
import styles from './SideFilterMenu.module.scss'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { FilterOption } from './SideFilterMenu'

type Props = {
  option: FilterOption
  tableId: string
  unassigned?: boolean
}

export function SideFilterMenuDropdownItem({
  option,
  tableId,
  unassigned,
}: Props) {
  const { setCategoryFilters, tableData } = useDataTable<any>(tableId)

  const { categoryFilters } = tableData || { categoryFilters: {} }

  const onOptionClick = () => {
    // HOLY DESTRUCTURING BATMAN
    // Split this category filter from the other category filters
    const {
      [option.categoryInternalName]: currentCategory = {
        categoryInternalName: option.categoryInternalName,
        optionMatches: {},
      },
      ...otherCategories
    } = categoryFilters
    // Split this category filter's options into this option and other options
    const { [option.optionInternalName]: currentOption, ...otherOptions } = currentCategory.optionMatches

    // If currentOption exists, recombobulate our options without it, otherwise recombobulate with a new option
    const newOptions = !!currentOption ? otherOptions : {
      [option.optionInternalName]: {
        value: option.optionInternalName,
        displayName: option.optionDisplayName,
        addressIds: option.addressIds,
      },
      ...otherOptions,
    }

    // If there are no more keys in options, recombobulate category filters without this category, otherwise smush the categories back together
    const newCategories = Object.keys(newOptions).length === 0 ? otherCategories : {
      [option.categoryInternalName]: {
        categoryInternalName: option.categoryInternalName,
        optionMatches: newOptions,
      },
      ...otherCategories,
    }

    setCategoryFilters(newCategories)
  }

  const empty = option?.displayCount === 0

  return (
    <Dropdown.Item
      onClick={onOptionClick}
      disabled={empty}
      content={
        <div className={`${styles.option} ${empty && styles.optionDisabled} ${unassigned && styles.unassigned}`}>
          <span><Checkbox
            checked={!!categoryFilters[option.categoryInternalName]?.optionMatches[option.optionInternalName]}
            disabled={empty}
          /></span>
          <span className={styles.optionDisplayName}>{option?.optionDisplayName || '-'}</span>
          <span>({option?.displayCount})</span>
        </div>
      }
    />
  )
}
