import React from 'react'
import styles from './EapSecurity.module.scss'
import { EapMethod, Phase2Auth, EnterpriseSettings } from 'graphql/__generated__/types'
import { SolButtonGroupProps, SolButtonGroup, SolDropdown, SolFileUpload } from 'SolComponents'
import { phase2PeapOptions, phase2TtlsOptions } from '../../Common/phaseTwoOptions'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import { useAlerts } from 'shared/hooks/useAlerts'

export interface Props {
  config: EnterpriseSettings
  setConfig: (config: EnterpriseSettings) => void
}

function EapSecurity({ config, setConfig }: Props) {
  const { showError } = useAlerts()
  const eapButtons: SolButtonGroupProps = [
    {
      primary: !config.eapMethod || config.eapMethod === EapMethod.Peap,
      value: EapMethod.Peap,
      content: 'PEAP',
      onClick: value =>
        setConfig({
          ...config,
          eapMethod: value,
        }),
    },
    {
      primary: config.eapMethod === EapMethod.Tls,
      value: EapMethod.Tls,
      content: 'TLS',
      onClick: value =>
        setConfig({
          ...config,
          eapMethod: value,
        }),
    },
    {
      primary: config.eapMethod === EapMethod.Ttls,
      value: EapMethod.Ttls,
      content: 'TTLS',
      onClick: value =>
        setConfig({
          ...config,
          eapMethod: value,
        }),
    },
    {
      primary: config.eapMethod === EapMethod.Pwd,
      value: EapMethod.Pwd,
      content: 'PWD',
      onClick: value =>
        setConfig({
          ...config,
          eapMethod: value,
        }),
    },
  ]

  let Phase2 = true
  let CACert = true
  let UserCert = true
  let showPassword = true

  switch (config.eapMethod) {
    case EapMethod.Tls:
      Phase2 = false
      showPassword = false
      break
    case EapMethod.Ttls:
      UserCert = false
      break
    case EapMethod.Pwd:
      Phase2 = false
      CACert = false
      UserCert = false
      break
    case null:
    case undefined:
    case EapMethod.None:
    case EapMethod.Peap:
    default:
      break
  }

  const setPhase2Options = () => {
    if (config.eapMethod === EapMethod.Peap) {
      return phase2PeapOptions
    }
    if (config.eapMethod === EapMethod.Ttls) {
      return phase2TtlsOptions
    }
    return
  }

  return (
    <div className={styles.enabledWrapper}>
      <div className={styles.eapWrapper}>
        <SolButtonGroup buttons={eapButtons} label="EAP Method" />
        {Phase2 && (
          <div className={styles.phase2Options}>
            <h3 className={styles.phase2}>Phase 2 Authentication</h3>
            <SolDropdown
              options={setPhase2Options()}
              value={config.phase2Auth}
              type="caret"
              direction="right"
              onChange={val => {
                setConfig({
                  ...config,
                  phase2Auth: val as Phase2Auth,
                })
              }}
            />
          </div>
        )}
        {CACert && (
          <div className={styles.block}>
            <SolFileUpload
              file={{
                name: config?.caCertificate?.name ?? '',
                contents: config?.caCertificate?.content ?? '',
              }}
              label="CA Certificate"
              fileTypes=".cer, .der, .crt, .pem, .pfx, .p12"
              onUpload={({ fileName, fileContents }) => {
                setConfig({
                  ...config,
                  caCertificate: {
                    name: fileName,
                    content: fileContents,
                  },
                })
              }}
              onFailure={showError}
            />
          </div>
        )}
        {config.eapMethod === EapMethod.Tls && UserCert && (
          <div className={styles.block}>
            <SolFileUpload
              label="User Certificate"
              fileTypes=".cer, .der, .crt, .pem, .pfx, .p12"
              file={{
                name: config?.userCertificate?.name ?? '',
                contents: config?.userCertificate?.content?.secret ?? '',
              }}
              onUpload={({ fileName, fileContents }) => {
                setConfig({
                  ...config,
                  userCertificate: {
                    name: fileName,
                    content: {
                      secret: fileContents,
                    },
                  },
                })
              }}
              onFailure={showError}
            />
          </div>
        )}

        <div className={styles.block}>
          <ValidationInput
            name="identity"
            rules={{ required: true }}
            label="Identity"
            value={config.identity}
            onChange={(e, value) =>
              setConfig({
                ...config,
                identity: value.value,
              })
            }
          />
          {showPassword && (
            <ValidationInput
              name="password"
              variant="password"
              rules={{ required: true }}
              label="Password"
              required
              value={config?.password?.secret}
              onClear={() => {
                setConfig({
                  ...config,
                  password: {
                    secret: '',
                  },
                })
              }}
              onChange={(e, value) =>
                setConfig({
                  ...config,
                  password: {
                    secret: value.value,
                  },
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EapSecurity
