import React from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Template,
  PowerManagementConfiguration,

} from 'graphql/__generated__/types'
import styles from './PowerManagement.module.scss'
import PowerSettings from './PowerSettings'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: PowerManagementConfiguration) => void
}

function OffHours({
  updateConfiguration,
  template,
}: Props) {
  return (
    <Grid.Row className={styles.rowTwo}>
      <Grid.Column
        mobile={8}
        className={styles.columnOne}
      >
        <div className={styles.week}>
          <h3 className={styles.header}>Weekdays</h3>
          <div className={styles.weekLayout}>
            <span className={styles.daysActive}>
              M&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              T&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              W&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              Th&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              F&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              Sa&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              Su
            </span>
          </div>
        </div>
        <PowerSettings
          template={template}
          weekType="weekday"
          updateConfiguration={updateConfiguration}
        />
      </Grid.Column>
      <Grid.Column
        mobile={8}
        className={styles.secondColumn}
      >
        <div className={styles.week}>
          <h3 className={styles.header}>Weekend</h3>
          <div className={styles.weekLayout}>
            <span className={styles.daysInactive}>
              M&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              T&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              W&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              Th&nbsp;&nbsp;
            </span>
            <span className={styles.daysInactive}>
              F&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              Sa&nbsp;&nbsp;
            </span>
            <span className={styles.daysActive}>
              Su
            </span>
          </div>
        </div>
        <PowerSettings
          template={template}
          weekType="weekend"
          updateConfiguration={updateConfiguration}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default OffHours
