import React from 'react'
import styles from './SolElementInfoMessage.module.scss'
import SolInfoCircle from '../Icons/SolInfoCircle'

export type Props = {
  message: String
}

export function SolElementInfoMessage({
  message,
}: Props) {
  return (
    <div className={styles.elementItemTextContainer}>
      <SolInfoCircle className={styles.infoIcon}/>
      <span className={styles.infoText}>
        {message}
      </span>
    </div>
  )
}

export default SolElementInfoMessage
