import React from 'react'
import styles from './MessageCenter.module.scss'
import {
  Template,
  DisplayConfiguration,
  MessageCenterRssFeed,
  PlatformConfigQuery,
  PlatformConfigDocument,
} from 'graphql/__generated__/types'
import { SolCheckbox, SolTextArea, SolButton, SolNumInput, SolRow, SolGrid, SolColumn } from 'SolComponents'
import { Icon } from 'semantic-ui-react'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: DisplayConfiguration['MESSAGE_CENTER']) => void
}

interface RssFeedProps {
  onChangeTitle?: (val: string) => void
  onChangeDuration?: (val: number) => void
  onChangeSource: (val: string) => void
  onToggleEnabled: (val: boolean) => void
  onDelete?: () => void
  title?: string
  duration?: number
  source: string
  enabled: boolean
  isCustomMessage?: boolean
  index: number
}

function RssFeed({
  index,
  onChangeTitle,
  onChangeDuration,
  onChangeSource,
  onToggleEnabled,
  onDelete,
  title,
  duration,
  source,
  enabled,
  isCustomMessage,
}: RssFeedProps) {
  return (
    <div className={styles.addRss}>
      <div className={styles.enableRss}>
        <SolCheckbox label="" checked={enabled === true} onClick={onToggleEnabled} toggle />
      </div>
      {!isCustomMessage && (
        <ValidationInput
          name={`rss_name_${index}`}
          rules={{ required: true }}
          value={title}
          size="large"
          onChange={(e, val) => onChangeTitle?.(val.value)}
        />
      )}
      {isCustomMessage && <div className={styles.customMessageField}>Custom Message</div>}
      <div className={styles.duration}>
        {!isCustomMessage && (
          <SolNumInput
            className={styles.numInput}
            size={9}
            min={1}
            max={60}
            format={num => `${num} mins`}
            value={duration!}
            onChange={onChangeDuration!}
          />
        )}
      </div>
      {!isCustomMessage && (
        <div className={styles.customMessage}>
          <ValidationInput
            name={`rss_url_${index}`}
            rules={{ required: true }}
            value={source}
            size="extraLarge"
            onChange={(e, val) => onChangeSource(val.value)}
          />
          <span className={styles.deleteRss}>
            <Icon name="trash alternate outline" color="blue" onClick={() => onDelete?.()} />
          </span>
        </div>
      )}
      <div className={styles.customMessage}>
        {isCustomMessage && (
          <SolTextArea
            value={source}
            placeholder="Hi! This is where you enter a custom message."
            onChange={e => onChangeSource(e.target.value)}
          />
        )}
      </div>
    </div>
  )
}

function MessageCenter({ template, updateConfiguration }: Props) {
  const configuration = template.revision.configuration as DisplayConfiguration['MESSAGE_CENTER']
  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const btnText = `Add an RSS Feed${hasElementPods ? '*' : ''}`

  return (
    <div className={styles.templateWrapper}>
      <div className={styles.addNewRss}>
        <SolButton
          text={btnText}
          primary
          handleClick={() => {
            updateConfiguration({
              ...configuration,
              rssFeeds: [
                ...(configuration?.rssFeeds ?? []),
                {
                  url: '',
                  duration: 3,
                  title: 'New RSS Feed',
                  enabled: false,
                },
              ],
            })
          }}
          basic={false}
          isLink={false}
        />
      </div>
      <SolGrid>
        <SolRow>
          <div className={styles.header}>
            <div className={styles.columnOne}>
              <SolColumn lg={1}>
                <strong>Enabled</strong>
              </SolColumn>
            </div>
            <div className={styles.columnTwo}>
              <SolColumn lg={4}>
                <strong>Name</strong>
              </SolColumn>
            </div>
            <div className={styles.columnThree}>
              <SolColumn lg={2}>
                <strong>Duration</strong>
              </SolColumn>
            </div>
            <div className={styles.columnFour}>
              <SolColumn lg={4}>
                <strong>Source</strong>
              </SolColumn>
            </div>
          </div>
        </SolRow>
      </SolGrid>
      <div>
        {(configuration?.rssFeeds ?? []).map((rss, index) => (
          <RssFeed
            index={index}
            key={index}
            title={rss?.title ?? ''}
            source={rss?.url ?? ''}
            duration={rss?.duration ?? 3}
            enabled={rss?.enabled ?? false}
            onChangeTitle={title => {
              const existingRss: MessageCenterRssFeed = configuration?.rssFeeds?.[index] ?? {}
              const rssFeeds = Object.assign([...(configuration?.rssFeeds ?? [])], {
                [index]: {
                  ...existingRss,
                  title,
                },
              })
              updateConfiguration({
                ...configuration,
                rssFeeds,
              })
            }}
            onDelete={() => {
              updateConfiguration({
                ...configuration,
                rssFeeds: (configuration?.rssFeeds ?? []).filter((_, i) => index !== i),
              })
            }}
            onChangeDuration={durationInMinutes => {
              const existingRss: MessageCenterRssFeed = configuration?.rssFeeds?.[index] ?? {}
              const rssFeeds = Object.assign([...(configuration?.rssFeeds ?? [])], {
                [index]: {
                  ...existingRss,
                  duration: durationInMinutes,
                },
              })
              updateConfiguration({
                ...configuration,
                rssFeeds,
              })
            }}
            onChangeSource={url => {
              const existingRss: MessageCenterRssFeed = configuration?.rssFeeds?.[index] ?? {}
              const rssFeeds = Object.assign([...(configuration?.rssFeeds ?? [])], {
                [index]: {
                  ...existingRss,
                  url,
                },
              })
              updateConfiguration({
                ...configuration,
                rssFeeds,
              })
            }}
            onToggleEnabled={enabled => {
              const existingRss: MessageCenterRssFeed = configuration?.rssFeeds?.[index] ?? {}
              const rssFeeds = Object.assign([...(configuration?.rssFeeds ?? [])], {
                [index]: {
                  ...existingRss,
                  enabled,
                },
              })
              updateConfiguration({
                ...configuration,
                rssFeeds,
              })
            }}
          />
        ))}
      </div>
      <div>
        <RssFeed
          index={1000}
          isCustomMessage
          source={configuration?.customMessage?.text ?? ''}
          enabled={configuration?.customMessage?.enabled === true}
          onToggleEnabled={enabled =>
            updateConfiguration({
              ...configuration,
              customMessage: {
                ...configuration?.customMessage,
                enabled,
              },
            })
          }
          onChangeSource={text =>
            updateConfiguration({
              ...configuration,
              customMessage: {
                ...configuration?.customMessage,
                text,
              },
            })
          }
        />
      </div>

      <div>
        <SolCheckbox
          label="Enable Emergency Message"
          checked={configuration?.emergencyMessage?.enabled === true}
          bold
          onClick={enabled => {
            updateConfiguration({
              ...configuration,
              emergencyMessage: {
                ...configuration?.emergencyMessage,
                enabled,
              },
            })
          }}
        />
        <div className={styles.emergencyTextArea}>
          <SolTextArea
            value={configuration?.emergencyMessage?.text}
            onChange={e => {
              updateConfiguration({
                ...configuration,
                emergencyMessage: {
                  ...configuration?.emergencyMessage,
                  text: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
      {hasElementPods
        && (
          <div className={styles.spanMargin}>
            <span>* Certain features are not available for Solstice Element.</span>
          </div>
        )
      }
    </div>
  )
}

export default MessageCenter
