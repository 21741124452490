import React from 'react'

import styles from './EmptyTab.module.scss'
const telescope = require('assets/telescope.png')

export interface Props {
    text: string
}

export default function EmptyTab(props: Props) {

  return (
    <div className={styles.wrapper}>
      <img
        src={telescope}
        alt="Telescope"
      />
      <div className={styles.text}>
        {props.text}
      </div>
    </div>
  )
}
