import React from 'react'
import { Dropdown, Label } from 'semantic-ui-react'
import styles from './Chart.module.scss'
import { MetricType } from 'graphql/__generated__/types'
import { metricLabels } from 'shared/domain/metric'
import { primaryOptions } from 'pages/UsageHistory/chartUtils'
import { Category } from 'shared/types/categories'
import SolElementInfoMessage from '../../SolElementInfoMessage/SolElementInfoMessage'

export interface Props {
  categories: Category[]
  category: string
  setCategory: any
  metricList: string[]
  metric: MetricType
  setMetric: any
  hasElementPods?: Boolean | null
}

export default function CategorySelection(props: Props) {
  const categoryDropDownOptions = props.categories.map(cat => {
    return {
      key: cat.id,
      text: cat.displayName ?? '',
      value: cat.id ?? '',
    }
  })

  return (
    <div className={styles.selectWrapper}>
      <Label color="black">
        <Dropdown
          className={styles.categorySelector}
          options={categoryDropDownOptions}
          value={props.category}
          onChange={(e, { value }) => props.setCategory(value)}
          selectOnBlur={false}
          id="category-filter"
        />
      </Label>
      <Label basic color="black">
        <Dropdown
          color="white"
          className="joyride-metric-selector"
          defaultValue={props.metric}
          options={primaryOptions}
          placeholder={props.metric === MetricType.Posts ? metricLabels.POSTS : props.metric}
          onChange={(_, e) => {
            return props.setMetric(e.value)
          }}
          selectOnBlur={false}
          id="compare-metric"
        />
      </Label>
      {props.hasElementPods
        && (
          <div className={styles.elementMessageContainer}>
            <SolElementInfoMessage
              message="Solstice Element pods are not included"
            />
          </div>
        )
      }
    </div>
  )
}
