import uuid from 'shared/core/uuid'
import { format, utcToZonedTime } from 'date-fns-tz'
import { PureQueryOptions, useMutation } from '@apollo/client'
import {
  CreateScheduledTaskMutation,
  CreateScheduledTaskMutationVariables,
  CreateScheduledTaskDocument,
  CreateScheduledTaskInput,
  ScheduledTaskUpdateType,
} from 'graphql/__generated__/types'

export const UPDATE_DURATION_LIMIT = 10800

type Options = Pick<CreateScheduledTaskInput, 'type' | 'displayIds'> & Partial<CreateScheduledTaskInput>

export const useCreateScheduledTask = (refetchQueries?: PureQueryOptions[]) => {
  const [createScheduledTask] = useMutation<CreateScheduledTaskMutation, CreateScheduledTaskMutationVariables>(
    CreateScheduledTaskDocument,
  )

  return (options: Options) => {
    const variables = {
      options: {
        id: uuid(),
        durationSecs: UPDATE_DURATION_LIMIT,
        updateType: ScheduledTaskUpdateType.Now,
        startDate: format( utcToZonedTime( new Date(), 'UTC' ), 'yyyy-MM-dd\'T\'HH:mm:ss'),
        data: {},
        ...options,
      },
    }
    return createScheduledTask({
      variables,
      refetchQueries,
    })
  }
}
