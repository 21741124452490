import React from 'react'
import Joyride from 'react-joyride'
import { useJoyride, styles, Ride } from 'shared/hooks/useJoyride'

export const PodActionsRide = () => {
  const { show, finishRide } = useJoyride(Ride.PodActions)
  return (
    <Joyride
      styles={styles}
      callback={event => {
        if (event.action === 'close' || event.type === 'tour:end') {
          finishRide()
        }
      }}
      run={show}
      continuous
      locale={{
        last: 'Got it',
      }}
      steps={[
        {
          disableBeacon: true,
          target: '.joyride-pod-actions',
          content:
            'Select one or multiple Pods in the table and select an option in the dropdown to perform an action.',
        },
      ]}
      disableScrollParentFix
      disableOverlayClose
    />
  )
}
