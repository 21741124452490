import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import React, { useState } from 'react'
import {
  // SwatchesPicker,
  // TwitterPicker,
  GithubPicker,
} from 'react-color'
import styles from './SolColorPalettePicker.module.scss'

export interface Props {
  color: string
  colors: string[] | object
  setter: (color: string) => void
}

export default function SolColorPalettePicker({
  color,
  colors,
  setter,
}: Props) {
  const [showPicker, setShowPicker] = useState(false)

  let colorStyle = {
    backgroundColor: color,
  }
  return (
    <React.Fragment>
      <div
        onClick={() => {setShowPicker(!showPicker)}}
      >
        <div
          className={styles.colorBox}
          style={colorStyle}
        />
        <ChevronRightIcon className={styles.colorBoxArrow}/>
      </div>
      {showPicker ? (
        <div
          className={styles.picker}
          onClick={() => {
            setShowPicker(!showPicker)
          }}
        >
          <div onClick={e => e.stopPropagation()}>
            {/* <SwatchesPicker */}
            <GithubPicker
              color={color}
              width="165px"
              triangle="hide"
              // colors={ typeof colors === Array  ? colors : []}
              colors={Object.values(colors).map(c => c)}
              onChange={c => {
                setter(c.hex)
              }}
            />
          </div>
          <div
            className={styles.clickOut}
            onClick={() => {
              setShowPicker(!showPicker)
            }}
          />
        </div>
      ) : null}
    </React.Fragment>
  )
}
