import React, { useState, useEffect } from 'react'
import {
  UniqueSettingsCompatibility,
  Template,
  SettingsGroup,
  SettingsGroupType,
  Display,
  AssignTemplateMutation,
} from 'graphql/__generated__/types'
import styles from './UniqueSettings.module.scss'
import { SolButton, SolConfirmationModal } from 'SolComponents'
import { Icon } from 'semantic-ui-react'
import { ServerSelectedItems } from 'components/DataTableSlim/types'
import SolDrawer from 'SolComponents/SolDrawer/SolDrawer'
import { FormValidation } from 'shared/context/FormValidation'
import UniqueSettingsForm from './UniqueSettingsForm'
import { ExecutionResult } from 'graphql'

export interface Props {
  drawerIsOpen: boolean
  onClose: (completed?: boolean) => void
  template?: Template & {
    settingsGroup: Pick<SettingsGroup, 'name'>
  }
  validation?: UniqueSettingsCompatibility
  assignTemplates?: (
    selectedItems: ServerSelectedItems,
    templateId: string,
    settingsGroupType: SettingsGroupType
  ) => Promise<ExecutionResult<AssignTemplateMutation>>
}

export default function UniqueSettings({ drawerIsOpen, onClose, template, validation, assignTemplates }: Props) {
  const incompatibleDisplays = validation?.incompatibleDisplays ?? []
  const [pods, setPods] = useState(incompatibleDisplays)
  useEffect(() => setPods(incompatibleDisplays), [validation])
  const setPod = (newPod: Display) => setPods(pods.map(pod => (pod?.id === newPod.id ? newPod : pod)))
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentPodNum, setCurrentPodNum] = useState(1)
  const currentPod = pods[currentPodNum - 1]
  const settingsGroupName = template?.settingsGroup?.name.toLowerCase()
  const headerText = `The "${template?.name}" ${settingsGroupName} template contains `
  const podsText = pods.length > 1 ? `${pods.length} Pods` : 'Pod'

  const handleNext = () => {
    setCurrentPodNum(currentPodNum + 1)
    if (currentPodNum === pods.length) {
      setCurrentPodNum(1)
      onClose(true)
    }
  }
  const handleBack = () => {
    setCurrentPodNum(currentPodNum - 1)
  }

  return currentPod ? (
    <>
      <SolConfirmationModal
        isOpen={modalIsOpen}
        modalText="Are you sure you want to leave?"
        onAcceptingAction={() => {
          setModalIsOpen(false)
          onClose()
        }}
        acceptingText="Leave"
        onCloseModal={() => setModalIsOpen(false)}
        cancelText="Cancel"
        subHeaderText={`You haven't completed entering the unique settings for the selected Pods`}
      />
      <SolDrawer isOpen={drawerIsOpen} onCloseDrawer={() => setModalIsOpen(true)} className={styles.drawer}>
        <div className={styles.header}>
          {headerText}
          <strong>required unique settings</strong>
          {' that you must enter before applying to the selected '}
          <strong>{podsText}</strong>.
        </div>
        <hr />
        <FormValidation>
          <UniqueSettingsForm
            fieldValidation={validation?.fieldValidation ?? {}}
            pod={currentPod}
            setPod={setPod}
            template={template}
            handleNext={handleNext}
            assignTemplates={assignTemplates}
            isFinalPod={currentPodNum >= pods.length}
          />
        </FormValidation>
        <div className={styles.progress}>
          <SolButton
            disabled={currentPodNum === 1}
            text={
              <>
                <Icon name="chevron left" />
                <strong>Back</strong>
              </>
            }
            handleClick={handleBack}
            basic
          />
          <span>
            {currentPodNum} of {pods.length}
          </span>
          <SolButton
            text={
              <>
                <strong>Skip</strong>
                <Icon name="chevron right" />
              </>
            }
            handleClick={handleNext}
            basic
          />
        </div>
      </SolDrawer>
    </>
  ) : null
}
