import React, { ComponentType } from 'react'
import SolCard from 'SolComponents/SolCard/SolCard'
import TelevisionIcon from 'mdi-react/TelevisionIcon'
import VideoIcon from 'mdi-react/VideoIcon'
import CellphoneLinkIcon from 'mdi-react/CellphoneLinkIcon'
import classNames from 'classnames'
import styles from './UsbDevicesCountCard.module.scss'
import { pluralize, toPercentage } from 'shared/core/utils'
import { MdiReactIconProps } from 'mdi-react'

type Props = {
  addAvDeviceFilter: (value: boolean, displayName: string) => void
  podsWithDevicesCount: number
  totalDevicesCount: number
  totalAvDevicesCount: number
  totalOtherDevicesCount: number
  totalPodsCount: number
}

const Count = (props: {
  count: number
  label: string
  icon: ComponentType<MdiReactIconProps>
  onClick?: () => void
}) => {
  const Icon = props.icon
  return (
    <div className={styles.metric}>
      <Icon color="#333333" size={20} />
      <div className={classNames(styles.label, { [styles.link]: props.onClick })} onClick={props.onClick}>
        <span className={styles.count}>{props.count}</span> {props.label}
      </div>
    </div>
  )
}

export const UsbDevicesCountCard = ({
  addAvDeviceFilter,
  podsWithDevicesCount,
  totalDevicesCount,
  totalAvDevicesCount,
  totalOtherDevicesCount,
  totalPodsCount,
}: Props) => {
  const usbDevicesText = pluralize(totalDevicesCount, 'USB Device', false)
  const podsWithDevicesText = pluralize(podsWithDevicesCount, 'Pod', false)
  let podsWithDevicesPercentage = `${toPercentage(podsWithDevicesCount, totalPodsCount)}`
  if (podsWithDevicesPercentage === '0' && podsWithDevicesCount > 0) {
    podsWithDevicesPercentage = '<1'
  }

  return (
    <SolCard className={styles.card}>
      <div className={styles.description} data-testid="usb-devices-count-card">
        <div className={styles.header}>
          <div className={styles.count}>{totalDevicesCount}</div>
          <div className={styles.label}>{usbDevicesText} Online</div>
        </div>
        <div className={styles.content}>
          <div className={styles.section}>
            <Count
              icon={TelevisionIcon}
              count={podsWithDevicesCount}
              label={`${
                podsWithDevicesText
              } of ${
                totalPodsCount
              } online (${
                podsWithDevicesPercentage
              }%) with USB devices`}
            />
          </div>
          <div className={styles.section}>
            <Count
              icon={VideoIcon}
              count={totalAvDevicesCount}
              label={pluralize(totalAvDevicesCount, 'Known Camera', false)}
              onClick={() => addAvDeviceFilter(true, 'Known Cameras')}
            />
            <Count
              icon={CellphoneLinkIcon}
              count={totalOtherDevicesCount}
              label={pluralize(totalOtherDevicesCount, 'Other', false)}
              onClick={() => addAvDeviceFilter(false, 'Others')}
            />
          </div>
        </div>
      </div>
    </SolCard>
  )
}
