import { SemanticCOLORS } from 'semantic-ui-react'
import { PageName } from 'shared/store/types'
import { ChartNames } from 'pages/UsageHistory/types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { DateRange } from 'shared/core/utils'

// TODO: clean this up:
// move types where they belong, and remove unused

export enum Period {
  '48HRS' = 2,
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90
}
export type SetPeriod = (period: Period) => void

export enum Bucket {
  HOURS = 1 / 24,
  DAYS = 1,
  WEEKS = 7,
  MONTHS = 30
}
export type SetBucket = (bucket: Bucket) => void

export const toBucket = (s: string) => {
  switch (s) {
    case 'HOUR':
      return Bucket.HOURS
    case 'DAY':
      return Bucket.DAYS
    case 'WEEK':
      return Bucket.WEEKS
    default:
      return Bucket.MONTHS
  }
}

export type ChartType = 'home' | 'usage'

export interface TimePeriod {
  start: number
  end: number
  period: Period
  range: DateRange
}

export type ChartId = DataTableInstance & PageName & ChartNames & ChartType

export interface ChartSubHeaderData {
  chartedDisplays: number
  totalDisplays: number
}

export interface BaseAxisOption {
  key: number
  text: string
}

export interface AxisOption extends BaseAxisOption {
  value: string
}

export interface ChartDropdownOption {
  key: number
  text: string
  value: string
}

export interface ChartDropdown {
  key: number
  name: string
  displayName: string
  options: ChartDropdownOption[]
  color?: SemanticCOLORS
}

export interface ChartDropdowns {
  [name: string]: ChartDropdown
}

export interface ChartDropdownValues {
  [name: string]: string | undefined
}

export type SetChartDropdown = (name: string, value: string) => void

export type SetValue = (value: string) => void

export type SetToggleValue = (
  name: string,
  toggled: boolean,
  info?: boolean | number | string
) => void

export type ChartCheckbox = {
  key: number
  category: string
  option: string
  displayName: string
  color?: string
}

export interface ChartCheckboxes {
  [internalName: string]: ChartCheckbox
}

export type ToggleChartCheckbox = (
  internalName: string,
  checked: boolean,
  info?: string
) => void

export interface ToggledChartCheckboxes {
  [internalName: string]: boolean | number | string
}

export interface ChartDataPoint {
  time: number
  timeFormatted: string
  values: {
    [key: string]: number
  }

  // hoursInUse?: number
  // hoursInUseRounded?: number
  // minutesInUse?: number
  // minutesInUseRounded?: number
  // totalUsers?: number
  // meetingCount?: number
  // postCount?: number
  // multiRoomSessionCount?: number
}

export interface ChartDataLine {
  dataKey: string
  color: string // hex code
}

export type ChartDomain = [number, number]
