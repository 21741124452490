import React from 'react'
import classNames from 'classnames'
import CircleIcon from 'mdi-react/CircleIcon'
import styles from './SolGpsTargetIcon.module.scss'

const MapDot = (props: {
  className?: string
  onClick?: () => void
  color?: string
}) => (
  <CircleIcon
    className={classNames(styles.mapDot, props.className)}
    onClick={props.onClick}
    color={props.color ?? 'black'}
  />
)

export default MapDot
