import { OrderBy, OrgAddressesBoolExp, ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useSearchParams } from 'components/DataTableSlim/Hooks/useSearchDisplays'
import { getFilterBoxFilter } from '../Utils/get-hasura-params'
import { useHasuraFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { getCategorySearchArg } from 'components/DataTableSlim/Utils/hasuraUtils'
import { Building } from './types'

const sortMapper = (field: string, orderBy: OrderBy) =>
  ({
    building: { nickname: orderBy },
    address: { building: orderBy },
    podCount: { assignedDisplays_aggregate: { count: orderBy } },
  }[field] ?? { [field]: orderBy })

const searchMapper = (searchTerm: string) => ({
  _or: [
    { nickname: { _ilike: `%${searchTerm}%` } },
    { building: { _ilike: `%${searchTerm}%` } },
    { city: { _ilike: `%${searchTerm}%` } },
    { country: { _ilike: `%${searchTerm}%` } },
  ],
})

const categoryArgsMapper = (args: ViewDisplaysBoolExp) => ({
  assignedDisplays: {
    _display: args,
  },
})

export const useBuildingLocationsVariables = ({ visiblePodsBox }: { visiblePodsBox?: number[][] }) => {
  const { searchValue, page, orderBy: orderByTable, setOrderBy } = useDataTable<Building>(DataTableInstance.Buildings)
  const searchParams = useSearchParams<OrgAddressesBoolExp>({
    tableInstance: DataTableInstance.Buildings,
    page,
    search: searchValue,
    orderBy: orderByTable,
    setOrderBy,
    sortMapper,
    searchMapper,
    categoryArgsMapper,
  })
  const categories = useHasuraFilters(DataTableInstance.Locations) ?? []
  return {
    ...searchParams,
    whereDisplay: {
      ...getFilterBoxFilter(visiblePodsBox),
      ...getCategorySearchArg(categories),
    },
  }
}
