import React from 'react'
import classNames from 'classnames'
import styles from './SolCancelSave.module.scss'
import { SolButton } from 'SolComponents'

export interface Props {
  cancelDisabled?: boolean
  acceptingDisabled?: boolean
  cancelText?: string
  acceptingText?: string
  cancelClick: (e: React.MouseEvent) => void
  acceptingClick: (e: React.MouseEvent) => void
  className?: string
  hideCancel?: boolean
  loading?: boolean
}

export const SolCancelSave = (props: Props) => (
  <div className={classNames(styles.buttonWrapper, props.className)} onClick={e => e.stopPropagation()}>
    {!props.hideCancel && (
      <SolButton
        primary={false}
        basic
        color="black"
        text={props.cancelText ?? 'Cancel'}
        disabled={!!props.cancelDisabled}
        handleClick={props.cancelClick}
        isLink
      />
    )}
    <SolButton
      primary
      basic={false}
      text={props.acceptingText ?? 'Save'}
      disabled={!!props.acceptingDisabled}
      handleClick={props.acceptingClick}
      isLink={false}
      loading={props.loading}
    />
  </div>
)

export default SolCancelSave
