import { MxRenewServiceInterface, MxRenewLeadInterface, MxRenewLeadId } from '../types'

import mockService from './mxRenewService.mock'

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const mockServiceDelay: MxRenewServiceInterface = {
  ...mockService,

  async createLead(lead: MxRenewLeadInterface) {
    const created = await mockService.createLead(lead)
    await delay(500)
    return created
  },

  async updateLead(id: MxRenewLeadId, update: MxRenewLeadInterface) {
    const updated = await mockService.updateLead(id, update)
    await delay(500)
    return updated
  },
}

export default mockServiceDelay
