import React, { useState, useEffect } from 'react'
import SingleOutPreview from './SingleOut/SingleOutPreview'
import DualOutPreview from './DualOut/DualOutPreview'
import WelcomeScreenBGImages, { ImagePreview } from './WelcomScreenBGImages'
import DualMirrorPreview from './DualMirror/DualMirrorPreview'
import {
  WelcomeScreenConfiguration,
  HdmiOutputModeType,
  WelcomeScreenImage,
} from 'graphql/__generated__/types'

export interface Props {
  settings: WelcomeScreenConfiguration
  onImageUpload: (
    hash: string,
    position: number,
    hdmiOutput: HdmiOutputModeType
  ) => void
  onCheckImage: (position: number, checked: boolean) => void
}

function WelcomeScreenPreview(props: Props) {
  const hdmiOutput = props.settings?.hdmiOutput ?? HdmiOutputModeType.Single
  const allImages = (props.settings?.images
    || []) as WelcomeScreenImage[]
  const bgImages: ImagePreview[] = allImages.map(i =>
    (hdmiOutput === HdmiOutputModeType.Single
    || hdmiOutput === HdmiOutputModeType.Mirror
      ? {
        src: i?._16x9?.hash || '',
        enabled: i.enabled,
        position: i.position,
      }
      : {
        src: i?._32x9?.hash || '',
        enabled: i.enabled,
        position: i.position,
      }),
  )
  const initialImage = bgImages[0]
  const [previewImage, setPreviewImage] = useState<{
    index: number
    src: string
  }>({
    src: initialImage.src,
    index: 0,
  })

  // Handle case when cancel gets clicked
  useEffect(() => {
    if (bgImages[previewImage.index].src !== previewImage.src) {
      setPreviewImage({
        src: bgImages[previewImage.index].src,
        index: previewImage.index,
      })
    }
  }, [bgImages])

  const [loadingImages, setLoadingImages] = useState<boolean[]>(
    Array(6).fill(true),
  )
  const setLoadingImage = (imageNum: number, isLoading: boolean) =>
    setLoadingImages(
      loadingImages.map((loadingImage, i) =>
        (i === imageNum ? isLoading : loadingImage),
      ),
    )

  return (
    <div>
      <div>
        {props.settings.hdmiOutput === HdmiOutputModeType.Single ? (
          <SingleOutPreview
            settings={props.settings}
            bgImage={previewImage.src}
            loadingImage={loadingImages[previewImage.index]}
          />
        ) : props.settings.hdmiOutput === HdmiOutputModeType.Mirror ? (
          <DualMirrorPreview
            settings={props.settings}
            bgImage={previewImage.src}
            loadingImage={loadingImages[previewImage.index]}
          />
        ) : (
          <DualOutPreview
            settings={props.settings}
            bgImage={previewImage.src}
            loadingImage={loadingImages[previewImage.index]}
          />
        )}
      </div>
      <WelcomeScreenBGImages
        hdmiOutput={hdmiOutput}
        bgImages={bgImages}
        setPreviewImage={setPreviewImage}
        loadingImages={loadingImages}
        setLoadingImage={setLoadingImage}
        selectedIndex={bgImages.findIndex(
          i => i.position === previewImage.index,
        )}
        onImageUpload={props.onImageUpload}
        onCheckImage={props.onCheckImage}
      />
    </div>
  )
}

export default WelcomeScreenPreview
