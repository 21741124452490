import React, {  useState } from 'react'
import { Button, Divider } from 'semantic-ui-react'
import {  SolInput, SolPassword } from 'SolComponents'
import styles from './SupportLogin.module.scss'
import { LoginCreds } from './SupportLoginController'
const solsticeCloudLoginLogo = require('../../assets/solstice-cloud-login.png')

type Props = {
  login: (creds: LoginCreds) => {}
  error: string
  loading: boolean
}

function SupportLogin({ login, error, loading }: Props) {
  const [email, setEmail] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [org, setOrg] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginContainer}>
        <img draggable={false} className={styles.loginLogo} src={solsticeCloudLoginLogo} />
        <SolInput
          size="fluid"
          required
          label="email"
          placeholder="email"
          value={email} onChange={(e, v) => {setEmail(v.value)}}
        />
        <SolPassword
          size="fluid"
          value={password}
          required
          label="password"
          placeholder="password"
          onClear={() => {}}
          onChange={(_, v) => setPassword(v.value)}
        />
        <div className={styles.orgIdentifiers}>
          <SolInput
            size="fluid"
            label="org"
            placeholder="org"
            value={org}
            onChange={(e, v) => {setOrg(v.value)}}
          />
          <Divider horizontal>or</Divider>
          <SolInput
            size="fluid"
            label="client email"
            placeholder="client email"
            value={clientEmail}
            onChange={(e, v) => {setClientEmail(v.value)}}
          />
        </div>
        {error ? <p className={styles.error}>{error}</p> : <div className={styles.errorSpace}></div>}

        <Button
          disabled={!password || !email || !(org || clientEmail)}
          size="large"
          fluid
          color="blue"
          onClick={() => login({ email, password, org, clientEmail })} loading={loading}
        >
          Login
        </Button>

      </div>
      
    </div>
  )
}

export default SupportLogin
