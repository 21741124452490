import React, { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import ErrorBoundary from './ErrorBoundary'
import CloudComponents from './CloudComponents'

const Container = React.memo(() => {
  const App = () => {
    return (
      <ErrorBoundary>
        <CloudComponents />
      </ErrorBoundary>
    )
  }
  return <App />
})

function AppContainer() {
  let ldClient = useLDClient()

  const currentUser = useCurrentUser()
  
  useEffect(() => {
    const newUser = {
      key: currentUser.org || 'unauthorized',
      name: currentUser.org || 'unauthorized',
    }
    ldClient?.identify(newUser)
  }, [currentUser])


  return <Container />
}

export default React.memo(AppContainer)
