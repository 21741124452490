import React from 'react'
import styles from './SolMenu.module.scss'

export const solsticeRoutingIcon = () => {
  return (
    <svg
      className={styles.svgMenuIcon}
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="#17C7F4"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        /* eslint-disable-next-line max-len */
        d="M7.5 17.5C8.17708 17.5 8.76302 17.2526 9.25781 16.7578C9.7526 16.263 10 15.6771 10 15C10 14.0625 9.58333 13.3464 8.75 12.8516V10.7812L13.75 8.28125V4.64844C14.5833 4.15365 15 3.4375 15 2.5C15 1.82292 14.7526 1.23698 14.2578 0.742188C13.763 0.247396 13.1771 0 12.5 0C11.8229 0 11.237 0.247396 10.7422 0.742188C10.2474 1.23698 10 1.82292 10 2.5C10 3.4375 10.4167 4.15365 11.25 4.64844V6.71875L7.5 8.59375L3.75 6.71875V4.64844C4.58333 4.15365 5 3.4375 5 2.5C5 1.82292 4.7526 1.23698 4.25781 0.742188C3.76302 0.247396 3.17708 0 2.5 0C1.82292 0 1.23698 0.247396 0.742188 0.742188C0.247396 1.23698 0 1.82292 0 2.5C0 3.4375 0.416667 4.15365 1.25 4.64844V8.28125L6.25 10.7812V12.8516C5.41667 13.3464 5 14.0625 5 15C5 15.6771 5.2474 16.263 5.74219 16.7578C6.23698 17.2526 6.82292 17.5 7.5 17.5ZM2.5 3.75C2.13542 3.75 1.83594 3.63281 1.60156 3.39844C1.36719 3.16406 1.25 2.86458 1.25 2.5C1.25 2.13542 1.36719 1.83594 1.60156 1.60156C1.83594 1.36719 2.13542 1.25 2.5 1.25C2.86458 1.25 3.16406 1.36719 3.39844 1.60156C3.63281 1.83594 3.75 2.13542 3.75 2.5C3.75 2.86458 3.63281 3.16406 3.39844 3.39844C3.16406 3.63281 2.86458 3.75 2.5 3.75ZM12.5 3.75C12.1354 3.75 11.8359 3.63281 11.6016 3.39844C11.3672 3.16406 11.25 2.86458 11.25 2.5C11.25 2.13542 11.3672 1.83594 11.6016 1.60156C11.8359 1.36719 12.1354 1.25 12.5 1.25C12.8646 1.25 13.1641 1.36719 13.3984 1.60156C13.6328 1.83594 13.75 2.13542 13.75 2.5C13.75 2.86458 13.6328 3.16406 13.3984 3.39844C13.1641 3.63281 12.8646 3.75 12.5 3.75ZM7.5 16.25C7.13542 16.25 6.83594 16.1328 6.60156 15.8984C6.36719 15.6641 6.25 15.3646 6.25 15C6.25 14.6354 6.36719 14.3359 6.60156 14.1016C6.83594 13.8672 7.13542 13.75 7.5 13.75C7.86458 13.75 8.16406 13.8672 8.39844 14.1016C8.63281 14.3359 8.75 14.6354 8.75 15C8.75 15.3646 8.63281 15.6641 8.39844 15.8984C8.16406 16.1328 7.86458 16.25 7.5 16.25Z"
      />
    </svg>
  )
}
