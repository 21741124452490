import React, { useEffect, useState } from 'react'
import Carousel from 'nuka-carousel'
import { Button, Icon } from 'semantic-ui-react'
import uuid from 'shared/core/uuid'
import { CategoryChart } from './AddCategoryFlow/CategoryChart'
import { AddCategoryFlow } from './AddCategoryFlow/AddCategoryFlow'
import ViewCategory from './ViewCategory/ViewCategory'
import { EditCategory } from './EditCategory/EditCategory'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { urlParams } from 'shared/core/utils'
import LoadingBar from 'components/Loaders/LoadingBar'
import styles from './Editor.module.scss'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { SolArrowButton } from 'SolComponents'
import { Category, Option, Pod, CategoryPieData } from '../CategoryTypes'
import { CategoryMutationResponse, CategoryOptionMutationResponse } from 'graphql/__generated__/types'
import sortBy from 'lodash/sortBy'
import { cool } from 'assets/palettes'
import { toPercentage } from 'shared/core/utils'
import { recommendedCategories } from './AddCategoryFlow/recommendedCategories'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'

const getCategoriesPieData = (categories: Category[], podCount: number): CategoryPieData[] =>
  categories.map(category => {
    const options = sortBy(category.options, o => o.appliedDisplayCount)

    const colors = cool(options.length).reverse()
    let data = options.map((option, index) => {
      const count = option.appliedDisplayCount ?? 0
      return {
        id: option.id ?? '',
        name: option.name ?? '',
        value: count,
        percentage: toPercentage(count, podCount),
        //  color: colors[findIndex(sorted, c => c.value === cat.value)] // primary color for most assigned category
        color: colors[index], // colored in order of categories
      }
    })

    const unassignedCount = podCount - options.reduce(
      (reduction, option) => reduction + (option.appliedDisplayCount ?? 0), 0)

    const unassigned = [
      {
        id: 'unassigned',
        name: 'Unassigned',
        value: unassignedCount,
        percentage: toPercentage(unassignedCount, podCount),
        color: '#e5e5e5',
      },
    ]

    if (unassigned[0].value > 0) {
      data = data.concat(unassigned)
    }

    return {
      data,
      editable: !['country', 'building', 'city'].includes(category.id),
      displayName: category.name ?? '',
      id: category.id ?? '',
      title: category.name ?? '',
    }
  })

export const newOption = (_options: Option[] = []) => ({
  displayName: '',
  id: uuid(),
})

enum CategoryViewState {
  ViewSingle,
  Edit,
  Add,
  ViewAll,
}

const slidesToShow = 3

const useAddColumn = () => {
  const { setAddedColumns, addedColumns } = useDataTable<Pod>(DataTableInstance.Categories)
  return (columnId: string) => {
    setAddedColumns([...addedColumns, `category_${columnId}`])
  }
}

interface Props extends RouteComponentProps {
  categories: Category[]
  totalPods: number
  loading: boolean
  createCategoryOption: (
    categoryId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  updateCategoryOption: (
    categoryId: string,
    optionId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  deleteCategoryOption: (
    categoryId: string,
    optionId: string,
  ) => Promise<number>
  createCategory: (
    categoryName: string,
  ) => Promise<CategoryMutationResponse>
  updateCategory: (
    categoryId: string,
    categoryName: string,
  ) => Promise<CategoryMutationResponse>
  deleteCategory: (
    categoryId: string,
  ) => Promise<number>
}

const Editor = ({
  location,
  categories,
  totalPods,
  loading,
  createCategoryOption,
  updateCategoryOption,
  deleteCategoryOption,
  createCategory,
  updateCategory,
  deleteCategory,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [viewState, setViewState] = useState(CategoryViewState.ViewAll)
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(null)
  const addColumn = useAddColumn()
  const pieData = getCategoriesPieData(categories, totalPods)

  useEffect(() => {
    // in case they navigated away while adding/editing a category
    dispatchUpdateByQueryParams()
  }, [])

  const currentUser = useCurrentUser()

  const createCategoryAndOptions = async (categoryName: string, optionNames: string[], refocus: boolean = true) => {
    const categoryId = (await createCategory(categoryName)).categoryId
    await Promise.all(optionNames.map(name => createCategoryOption(categoryId, name)))
    addColumn(categoryId)
    if (refocus) {
      setEditingCategoryId(categoryId)
      setViewState(CategoryViewState.Edit)
    }
  }

  // Recreate Room Type category upon page load if no custom categories exist
  const [canRepopulateCategories, setCanRepopulateCategories] = useState(true)
  useEffect(() => {
    if (!loading
    && canRepopulateCategories) {
      setCanRepopulateCategories(false)
      if (categories.filter(cat => !cat.location).length === 0) {
        const roomType = recommendedCategories.find(cat => cat.name === 'Room Type')!
        createCategoryAndOptions(roomType.name, roomType.options.map(option => option.name), false)
      }
    }
  }, [loading])

  const dispatchUpdateByQueryParams = () => {
    const colName = urlParams(location.search).get('active')
    if (colName) {
      addColumn(colName)
    }
  }

  const slideNext = () => {
    setSlideIndex(prevSlideIndex =>
      (prevSlideIndex + 1 > categories.length ? categories.length : prevSlideIndex + 1 || 0),
    )
  }

  const slidePrevious = () => {
    setSlideIndex(prevSlideIndex =>
      (prevSlideIndex - 1 > categories.length ? categories.length : prevSlideIndex - 1 || 0),
    )
  }

  const startAddingCategory = () => {
    setViewState(CategoryViewState.Add)
  }

  const viewCategory = (categoryId: string) => {
    addColumn(categoryId)

    setViewState(CategoryViewState.ViewSingle)
    setEditingCategoryId(categoryId)
  }

  const closeCategoryEdit = () => {
    setViewState(CategoryViewState.ViewAll)
  }

  const hasCategories = categories && categories.length > 0

  let elementToRender = <React.Fragment />

  switch (viewState) {
    case CategoryViewState.Add: {
      elementToRender = (
        <AddCategoryFlow
          onCancelled={closeCategoryEdit}
          onCategoryFinished={createCategoryAndOptions}
          categories={categories}
        />
      )
      break
    }
    case CategoryViewState.ViewSingle: {
      const category = categories.find(c => c.id === editingCategoryId)
      const data = pieData.find(c => c.id === editingCategoryId)
      if (!category || !data) {
        break
      }

      elementToRender = (
        <ViewCategory
          category={category}
          pieData={data}
          editClicked={() => {
            setViewState(CategoryViewState.Edit)
          }}
          onCancel={() => {
            setViewState(CategoryViewState.ViewAll)
          }}
        />
      )
      break
    }
    case CategoryViewState.Edit: {
      const category = categories.find(c => c.id === editingCategoryId)
      const data = pieData.find(c => c.id === editingCategoryId)
      if (!category || !data) {
        break
      }

      elementToRender = (
        <EditCategory
          category={category}
          pieData={data}
          loading={loading}
          onCancelEdit={() => {
            setViewState(CategoryViewState.ViewSingle)
          }}
          createCategoryOption={createCategoryOption}
          updateCategoryOption={updateCategoryOption}
          deleteCategoryOption={deleteCategoryOption}
          updateCategory={updateCategory}
          deleteCategory={async (categoryId: string) => {
            setViewState(CategoryViewState.ViewAll)
            const result = (await deleteCategory(categoryId))
            setSlideIndex(0)
            return result
          }}
        />
      )
      break
    }
    case CategoryViewState.ViewAll: {
      elementToRender = (
        <div className={styles.carouselWrapper}>
          <div className={styles.ctaContainer}>
            <Button
              className={styles.cta}
              primary
              icon
              labelPosition="right"
              onClick={startAddingCategory}
              disabled={!currentUser?.permissions.includes('create:categories')}>
              Add Category
              <Icon name="plus square" />
            </Button>
          </div>
          {hasCategories && (
            <Carousel
              dragging={false}
              framePadding="0px 0px 0px 0px"
              // initialSlideHeight={270}
              heightMode="first"
              renderCenterLeftControls={({ currentSlide }) => {
                const disabled = currentSlide === 0
                return !disabled && <SolArrowButton direction="left" onClick={slidePrevious} />
              }}
              renderCenterRightControls={({ currentSlide, slideCount }) => {
                const disabled
                  = slideCount <= slidesToShow
                  || currentSlide === slideCount - slidesToShow
                  || currentSlide === slideCount - 1
                return !disabled && <SolArrowButton direction="right" onClick={slideNext} />
              }}
              renderBottomCenterControls={() => null}
              slideIndex={slideIndex}
              slidesToShow={slidesToShow}
              className={styles.carousel}
            >
              {pieData.map((categoryPieData, key) => (
                <React.Fragment key={key}>
                  <div className={styles.title}>{categoryPieData.displayName}</div>
                  <CategoryChart
                    data={categoryPieData.data}
                    onClick={() => {
                      viewCategory(categoryPieData.id)
                    }}
                    clickable
                  />
                </React.Fragment>
              ))}
            </Carousel>
          )}
        </div>
      )
      break
    }
    default: {
      return <React.Fragment />
    }
  }

  return (
    <div className={styles.parent}>
      <LoadingBar visible={loading} header />
      {elementToRender}
    </div>
  )
}

export default withRouter(Editor)
