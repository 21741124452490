/**
 * Validates coordinates
 * @param {latitude} number
 * @param {longitude} number
 * @return {boolean}
 */
export const validateCoordinates = (latitude?: number, longitude?: number) => {
  if (!latitude || !longitude) {
    return false
  }
  const validLatitude = !!latitude && latitude >= -90 && latitude <= 90
  const validLongitude = !!longitude && longitude >= -180 && longitude <= 180
  return validLatitude && validLongitude
}
