export interface ChartDataPoint {
  [key: string]: string | number
}

export interface ChartData {
  data: ChartDataPoint[]
}
export enum ChartTimePeriod {
  HOURS = 'hours',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  CUSTOM = 'custom'
}

export enum ChartInterval {
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months'
}

export type Option = {
  internalName: string
  displayName: string
}

export interface ChartOption {
  id: string
  name: string
  color: string | undefined
  checked: boolean
  right?: boolean
}
