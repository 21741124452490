import React from 'react'
import { Header, Icon, Checkbox, Loader } from 'semantic-ui-react'
import DropZone from 'react-dropzone'
import classNames from 'classnames'
import styles from './WelcomeScreenBGImages.module.scss'
import { useAlerts } from 'shared/hooks/useAlerts'
import imageServer from '../../../../shared/services/asset/image.service'
import { HdmiOutputModeType } from 'graphql/__generated__/types'
import { applyImageServer } from 'shared/core/utils'

export interface ImagePreview {
  src: string
  enabled: boolean
  position: number
}
export interface Props {
  hdmiOutput: HdmiOutputModeType
  bgImages: ImagePreview[]
  setPreviewImage: (image: { src: string; index: number }) => void
  loadingImages: boolean[]
  setLoadingImage: (imageNum: number, isLoading: boolean) => void
  onImageUpload: (hash: string, position: number, outputMode: HdmiOutputModeType) => void
  onCheckImage: (position: number, checked: boolean) => void
  selectedIndex: number
}

export default function WelcomeScreenBGImages({
  hdmiOutput,
  bgImages,
  setPreviewImage,
  loadingImages,
  setLoadingImage,
  onImageUpload,
  onCheckImage,
  selectedIndex,
}: Props) {
  const { showError } = useAlerts()
  const imagesize
    = hdmiOutput === HdmiOutputModeType.Single
      ? 'singleOut'
      : hdmiOutput === HdmiOutputModeType.Mirror
        ? 'singleOut'
        : 'dualOut'

  const bgImageClasses = classNames(
    { [styles.singleImage]: imagesize === 'singleOut' },
    { [styles.dualImage]: imagesize === 'dualOut' },
  )
  const overlayClasses = classNames(
    { [styles.singleWrapper]: imagesize === 'singleOut' },
    { [styles.dualWrapper]: imagesize === 'dualOut' },
  )
  const checkboxClasses = classNames(
    { [styles.singleCheckbox]: imagesize === 'singleOut' },
    { [styles.dualCheckbox]: imagesize === 'dualOut' },
  )
  const imageIconOverlay = classNames(
    { [styles.iconOverlaySingle]: imagesize === 'singleOut' },
    { [styles.iconOverlayDual]: imagesize === 'dualOut' },
  )
  const imageConstraints = classNames(
    { [styles.imageConstraintsSingle]: imagesize === 'singleOut' },
    { [styles.imageConstraintsDual]: imagesize === 'dualOut' },
  )

  return (
    <div className={styles.sectionWrapper}>
      <Header size="small">Background Images</Header>
      <div className={styles.welcomeImagesWrapper}>
        {bgImages.map((img, i) => (
          <div className={imageConstraints} key={i}>
            <DropZone
              onDrop={async files => {
                const file = files[0]
                if (!['png', 'jpg', 'jpeg'].includes(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase())) {
                  return showError('Invalid file type')
                }
                setLoadingImage(i, true)
                const result = await imageServer.upload(file)
                onImageUpload(result.name, i, hdmiOutput)
                setPreviewImage({ src: result.name, index: i })
                if (result) setLoadingImage(i, false)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={overlayClasses} key={i}>
                  <img
                    className={classNames(bgImageClasses, {
                      [styles.activeImage]: selectedIndex === i,
                      [styles.disabledImage]: !img.enabled,
                    })}
                    src={`${applyImageServer(img.src)}&width=300`}
                    onClick={() => {
                      setPreviewImage({ src: img.src, index: i })
                    }}
                    onLoad={() => setLoadingImage(i, false)}
                  />
                  <div className={imageIconOverlay} {...getRootProps()}>
                    {loadingImages[i] ? (
                      <Loader active inverted />
                    ) : (
                      <Icon className={styles.plusIcon} name="cloud upload" alt="Upload New Image " size="big" />
                    )}
                  </div>
                  <input {...getInputProps()} accept=".png, .jpeg, .jpg" name="splash_image" multiple={false} />
                  <div className={checkboxClasses}>
                    <Checkbox
                      checked={img.enabled}
                      onClick={(e, _) => onCheckImage(img.position, _.checked || false)}
                    />
                  </div>
                </div>
              )}
            </DropZone>
          </div>
        ))}
      </div>
    </div>
  )
}
