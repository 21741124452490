import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styles from './InfoBanner.module.scss'
import InfoModal from './InfoModal'

function InfoBanner() {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <div className={styles.maintenanceBanner}>
      <Icon className={styles.infoIcon} name="info circle" color="blue" size="large"/>
      <span className={styles.text}>
        {/* eslint-disable-next-line */}
        Solstice Cloud is currently experiencing issues with under-reported analytics and are working on a fix.
        <Button active={false} className={styles.button} onClick={() => {setModalOpen(true)}}>
          Learn More
        </Button>
      </span>
      <InfoModal isOpen={modalOpen} onClose={() => {setModalOpen(false)}}/>
    </div>
  )
}

export default InfoBanner
