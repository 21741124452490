import { ManagementStatus } from 'graphql/__generated__/types'
import { useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDisplaySearchOptions } from './../useDisplaySearchOptions'
import { SemanticCOLORS } from 'semantic-ui-react'
import pick from 'lodash/pick'
import { SolDonutCard } from 'SolComponents'
import React from 'react'
import { getManagementStatusCounts } from './hooks'
import { toPercentage } from 'shared/core/utils'

export const StatusCard = () => {
  const { customFilters, setCustomFilter } = useCustomFilters<ManagementStatus>(DataTableInstance.AllPods)

  const displaySearchOptions = useDisplaySearchOptions()

  const addStatusFilter = (
    internalName: string,
    displayName: string,
    status: ManagementStatus,
    color: SemanticCOLORS,
  ) => {
    const values = {
      ...customFilters.managementStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomFilter('managementStatus', {
      values,
      color: Object.values(values).length > 1 ? 'blue' : color,
    })
  }

  const managementStatusCounts = getManagementStatusCounts(displaySearchOptions)
  const filterValues = customFilters.managementStatus?.values
  const pieDisplayCount = filterValues
    ? {
      online: filterValues.Online ? managementStatusCounts.online : 0,
      offline: filterValues.Offline ? managementStatusCounts.offline : 0,
      offlinePending: filterValues.OfflinePending ? managementStatusCounts.offlinePending : 0,
      notYetDeployed: filterValues.NotYetDeployed ? managementStatusCounts.notYetDeployed : 0,
      unsupported: filterValues.Unsupported ? managementStatusCounts.unsupported : 0,
    }
    : pick(managementStatusCounts, ['online', 'offline', 'offlinePending', 'notYetDeployed', 'unsupported'])

  const total
    = pieDisplayCount.online
    + pieDisplayCount.offline
    + pieDisplayCount.offlinePending
    + pieDisplayCount.notYetDeployed
    + pieDisplayCount.unsupported

  const pieData = [
    {
      name: 'Online',
      count: pieDisplayCount.online,
      percentage: toPercentage(pieDisplayCount.online, total),
      fill: '#66cc33',
      onClick: () => addStatusFilter('Online', 'Online', ManagementStatus.Online, 'green'),
    },
    {
      name: 'Offline',
      count: pieDisplayCount.offline,
      percentage: toPercentage(pieDisplayCount.offline, total),
      fill: '#bf1244',
      onClick: () => addStatusFilter('Offline', 'Offline', ManagementStatus.Offline, 'red'),
    },
    {
      name: `Offline - pending changes`,
      count: pieDisplayCount.offlinePending,
      percentage: toPercentage(pieDisplayCount.offlinePending, total),
      fill: '#fbb812',
      onClick: () =>
        addStatusFilter('OfflinePending', 'Offline - pending changes', ManagementStatus.OfflinePending, 'yellow'),
    },
    {
      name: `Not yet deployed`,
      count: pieDisplayCount.notYetDeployed,
      percentage: toPercentage(pieDisplayCount.notYetDeployed, total),
      fill: '#009fc9',
      onClick: () => addStatusFilter('NotYetDeployed', 'Not Yet Deployed', ManagementStatus.NotYetDeployed, 'blue'),
    },
    {
      name: `Unsupported`,
      count: pieDisplayCount.unsupported,
      percentage: toPercentage(pieDisplayCount.unsupported, total),
      fill: '#e7e8e9',
      onClick: () => addStatusFilter('Unsupported', 'Unsupported', ManagementStatus.Unsupported, 'grey'),
    },
  ]

  const emptyPieData = pieData.map(data => ({
    count: 1,
    fill: '#9B9B9B',
    name: data.name,
  }))

  return (
    <SolDonutCard
      data={total === 0 ? emptyPieData : pieData}
      isLoading={false}
      title="Pod Status"
      testId="pod-status-card"
    />
  )
}
