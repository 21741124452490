import React from 'react'

import {
  Link,
} from 'react-router-dom'

import {
  Header,
} from 'semantic-ui-react'

import { renewFlow as palette } from 'assets/palettes'
const SolsticeCloudSubscriptionLogo = require('assets/subscription_logo.png')

export const style = {
  wrapper: {
    width: '100%',
  } as React.CSSProperties,
  padding: {
    padding: '8px',
  } as React.CSSProperties,
  header: {
    fontWeight: 1000,
    lineHeight: '28px',
    display: 'inline',
  } as React.CSSProperties,
  link: {
    color: palette.black,
  } as React.CSSProperties,
  image: {
    width: '119px',
    height: '35px',
  } as React.CSSProperties,
}

export const PageHeader = () => (
  <div style={style.wrapper}>
    <div style={style.padding}>
      <Header
        as="h2"
        style={style.header}
      >
        <Link
          style={style.link}
          to="home"
        >
          <img style={style.image} src={SolsticeCloudSubscriptionLogo} />
        </Link>
      </Header>
    </div>
  </div>
)

export default PageHeader
