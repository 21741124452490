import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import FilterMenuItemLabel from './FilterMenuItemLabel'
import { AppliedCategoryFilters } from '../Hooks/useAppliedFilters'
import styles from './FilterMenu.module.scss'
import { TableFilterCategory } from '../../../shared/types/categories'
import { FilterMenuDropdown } from './FilterMenuDropdown'

export interface Props {
  key: number | string
  category: TableFilterCategory
  appliedFilters?: AppliedCategoryFilters
  handleMenuClick: (data: { name: string }) => void
  handleOptionClick: (data: { value: string }) => void
  totalDisplayCount: number
  open: object
}

export const style = {
  filterMenuIcon: {
    marginLeft: '0.25rem',
    marginRight: 0,
  } as React.CSSProperties,
}

export const FilterMenuItem = ({
  category,
  appliedFilters,
  handleMenuClick,
  handleOptionClick,
  totalDisplayCount,
  open,
}: Props) => {
  const categoryId = category.id ?? ''

  return (
    <Dropdown
      key={categoryId}
      name={categoryId}
      item
      text={category.display_name ?? ''}
      labeled
      icon={
        <>
          <FilterMenuItemLabel categoryInternalName={categoryId} appliedFilters={appliedFilters} />
          <Icon name="chevron right" style={style.filterMenuIcon} />
        </>
      }
      onClick={(_e: React.SyntheticEvent<HTMLElement>, data: { name: string }) => {
        handleMenuClick(data)
      }}
      open={open[categoryId]}
      className={styles.category}
    >
      <Dropdown.Menu className={styles.submenu}>
        {open[categoryId] && (
          <FilterMenuDropdown
            totalDisplayCount={totalDisplayCount}
            categoryId={categoryId}
            onOptionClick={handleOptionClick}
            appliedFilters={appliedFilters}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FilterMenuItem
