import React, { useState, useEffect, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Table, Input, Icon, Checkbox, Popup, Loader, StrictTableCellProps } from 'semantic-ui-react'
import { SolGrid, SolColumn, SolRow } from 'SolComponents'

import DataTableSlimPagination, { PaginationProps } from './DataTableSlimPagination'
import { SolEllipsesDropdown } from 'SolComponents'
import LoadingBar from 'components/Loaders/LoadingBar'
import FilteredTableHolder from './Holder/FilteredTableHolder'
import { AppliedFilters } from './AppliedFilters'
import classNames from 'classnames'
import moduleStyles from './DataTableSlim.module.scss'
import { toDisplayName } from './Utils'
import { AppliedCategory } from 'graphql/__generated__/types'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import SolTooltip from '../../SolComponents/SolTooltip/SolTooltip'
import { SolsticeElementEnum } from '../../shared/enums'

export type Direction = 'ascending' | 'descending'
export type OrderBy = {
  field: string
  direction: Direction
}

export interface TableAppliedCategories {
  [categoryId: string]: {
    name: string // optionId
    displayName: string // optionDisplayName
  }
}
// WARNING
// DO NOT RENAME THE RIGHT HAND STRING VALUES
// User config settings for tables are stored using them as keys
export enum DataTableInstance {
  ActiveLearningPods = 'active-learning-pods',
  AlertHistory = 'alert-history',
  AllPods = 'all-pods',
  Categories = 'categories',
  CategoryCompare = 'compare',
  Updates = 'updates',
  UsageHistory = 'usage-history',
  Availability = 'availability',
  Sharing = 'sharing',
  Deployment = 'deployment',
  Locations = 'locations',
  Buildings = 'buildings',
  RenewStep1 = 'renew-step-1',
  RenewStep2 = 'renew-step-2',
  Usb = 'usb-peripherals',
  Users = 'users',
  UsersSharing = 'users-sharing',
  Realtime = 'realtime',
  History = 'history',
  SolsticeRoutingSpaces = 'solstice-routing-spaces',
  SolsticeRoutingAddPods = 'sr-add-pods',
}

export type Categorizable<T extends {}> = T & {
  appliedCategories?: TableAppliedCategories
}

export type GraphQLCategorizable<T extends {}> = T & {
  categories?: AppliedCategory[]
}

export interface EditColumn<T> {
  visible: boolean
  render: (item: T) => React.ReactNode
}

export interface DataTableSlimColumn<T> extends Column<T> {
  isCategory?: boolean
  sortOrder?: number
  isIcon?: boolean
  category_type?: string
}

export interface Column<T> {
  className?: string
  name: Extract<keyof T, string> | string
  displayName?: React.ReactNode | string
  hidden?: boolean
  render?: (item: T, toCsv?: boolean) => React.ReactNode | string
  sortable?: boolean
  collapsing?: boolean
  addable?: boolean // defaults to true
  defaultAdded?: boolean // defaults to true
  centered?: boolean
  tooltipText?: string | JSX.Element
  // Returns a custom value to sort by for that column
  sort?: (item: T) => number | string | boolean
  isIcon?: boolean
  width?: StrictTableCellProps['width']
}

export interface CategoryColumn {
  name: string
  displayName: string
  defaultAdded?: boolean
}

export type SelectType = 'select' | 'deselect'

export type SelectQuantity = 'some' | 'all'

export type ValueOf<T> = T[keyof T]

export const SELECTABLE_COLUMN = '__selectableColumn'

export interface ColumnsOrder {
  emphasize?: boolean
  name: string
}

export interface DataTableSlimProps<T> {
  id: string
  title: string
  data: Categorizable<T>[]
  columns: DataTableSlimColumn<T>[]
  searchable?: boolean
  onSearch?: (val: string, clearSelectedItemsOnFilter?: boolean) => void
  pagination: PaginationProps
  selectable?: boolean | ((row: T) => boolean)
  onSort?: (name: string, direction: Direction) => void
  onSelect?: (selectType: SelectType, selectQuantity: SelectQuantity, items?: T[]) => void
  selectedIds?: T[keyof T][]
  includedIds?: string[]
  excludedIds?: string[]
  idSelector: (item: T) => ValueOf<T>
  defaultSelectAll?: boolean
  editColumn?: EditColumn<T>
  addableColumns?: boolean
  columnManager?: () => JSX.Element
  addedColumns: string[]
  onAddColumn: (columnName: string) => void
  onRemoveColumn: (columnName: string) => void
  scrollable?: boolean
  filterable?: boolean
  hasAppliedFilters?: boolean
  blur?: (columnName: string, item: T) => boolean
  orderBy?: OrderBy
  searchValue: string
  defaultNoDataMessage?: string
  allowExportToCsv?: boolean
  allowOrderBySelectable?: boolean
  onExportToCsv?: () => void
  striped?: boolean
  columnsOrder?: ColumnsOrder[]
  loading?: boolean
  addCategoryCols?: boolean
  // TODO remove this once LocalDataTable is removed
  clearSelectedItemsOnFilter?: boolean
  errorOnRow?: (e: {}) => boolean
  filterDrawer?: boolean
  wideHeader?: boolean
  fixed?: boolean
  tableHeaderColumnAutoWidth?: boolean
  padded?: boolean
}

export interface SortedKeys {
  [name: string]: Direction
}

interface SearchFilterProps {
  onSearch: (val: string) => void
  value: string
  placeholder?: string
}

const SearchFilter = React.memo((props: SearchFilterProps) => (
  <Input
    key="search-filter"
    onChange={(e, data) => props.onSearch(data.value)}
    value={props.value}
    placeholder="Search..."
    className={moduleStyles.searchInput}
    id="table-search"
  >
    <Icon name="search" className={moduleStyles.searchIcon} />
    <input key="search-filter-input" />
    {props.value && props.value.length && (
      <Icon name="remove circle" className={moduleStyles.clearSearch} onClick={() => props.onSearch('')} />
    )}
  </Input>
))

const sort = (
  orderBy: OrderBy | undefined,
  column: string,
  onSort: (column: string, direction: Direction) => void,
) => () => {
  const currentDirection = orderBy && orderBy.field === column ? orderBy.direction : undefined
  let nextDirection: Direction = 'ascending'
  if (currentDirection === 'ascending') {
    nextDirection = 'descending'
  }
  onSort(column, nextDirection)
}

const sortDirection = (orderBy: OrderBy | undefined, column: string) =>
  (orderBy && orderBy.field === column ? orderBy.direction : undefined)

const styles = {
  checkbox: {
    marginRight: '10px',
  },
  h3: {
    display: 'inline',
  },
  flexH3: {
    margin: 0,
  },
  selectableColumns: {
    cursor: 'pointer',
  } as React.CSSProperties,
  iconCursor: {
    cursor: 'pointer',
  } as React.CSSProperties,
}

function getCategoryColumns<T>({ column, item }: { column: DataTableSlimColumn<T>; item: Categorizable<T> }) {
  if (column.isCategory) {
    const category = item?.appliedCategories?.[column.name]?.displayName
    if (category) {
      return category
    }
    if (column.category_type === 'LOCATION') {
      return <Link to={`/manage/location?active=${column.name}`}>Not Set</Link>
    }
    return <Link to={`/categories?active=${column.name}`}>Not Set</Link>
  }
  return undefined
}

function DataTableSlim<T>({
  id,
  title,
  data,
  columns,
  pagination,
  selectable,
  idSelector,
  editColumn,
  addedColumns,
  columnManager,
  onAddColumn,
  onRemoveColumn,
  hasAppliedFilters,
  blur,
  orderBy,
  searchValue,
  defaultNoDataMessage,
  onSort = () => { },
  onSearch = () => { },
  onSelect = () => { },
  includedIds = [],
  excludedIds = [],
  selectedIds = [],
  searchable = true,
  defaultSelectAll = false,
  addableColumns = false,
  scrollable = true,
  filterable = true,
  striped = true,
  columnsOrder = [],
  loading,
  clearSelectedItemsOnFilter,
  allowOrderBySelectable,
  addCategoryCols = true,
  errorOnRow,
  filterDrawer = true,
  wideHeader,
  fixed,
  tableHeaderColumnAutoWidth,
  padded = false,
}: DataTableSlimProps<T>) {
  const [showAddableColumns, setShowAddableColumns] = useState(false)
  const [selectAllPopupIsOpen, setSelectAllPopupIsOpen] = useState(false)
  const [lastSelectedRow, setLastSelectedRow] = useState(0)
  const [shouldExpandBottom, setShouldExpandaBottom] = useState(false)

  useEffect(() => {
    if (defaultSelectAll && !selectedIds.length) {
      onSelect('select', 'all')
    }
  }, [])

  let colSpan = 0
  if (addableColumns) {
    colSpan += addedColumns.length + 1 || 1
  } else {
    colSpan += columns.length
  }
  colSpan += editColumn ? 1 : 0
  colSpan += selectable ? 1 : 0

  const selectAllChecked
    = includedIds.length > 0
    && pagination.totalItemsSelectable > 0
    && pagination.totalItemsSelected > 0
    && pagination.totalItemsSelected >= pagination.totalItemsSelectable

  const selectAllIndeterminate
    = !selectAllChecked
    && includedIds.length > 0
    && pagination.totalItemsSelectable > 0
    && pagination.totalItemsSelected > 0

  const headerTooltip = (tooltip?: string | JSX.Element) => {
    if (tooltip) {
      return (
        <SolTooltip
          text={tooltip}
          hoverable
          isInline
          trigger={<SolQuestionCircle size="small" className={moduleStyles.headerTooltips} />}
          position="bottom left"
        />
      )
    }
    return
  }

  const IconTooltip = ({ tooltip, trigger }: { tooltip?: string | JSX.Element; trigger: ReactNode }) => (
    <SolTooltip text={`${tooltip}`} isInline trigger={<>{trigger}</>} position="top center" />
  )

  const DefaultColumnManager = () => (
    <span
      style={styles.selectableColumns}
      onClick={() => setShowAddableColumns(!showAddableColumns)}
      className={moduleStyles.manageColumns}
      data-testid="table-manage-columns"
    >
      <h3 className={moduleStyles.manageText}>Manage Columns</h3>
      <Icon name={showAddableColumns ? 'chevron up' : 'chevron down'} size="large" style={styles.iconCursor} />
    </span>
  )

  const isSelectable = (item: Categorizable<T>) => typeof selectable !== 'function' || selectable(item)

  const selectSequence = (previousIndex: number, newIndex: number) => {
    const start = Math.min(newIndex, previousIndex)
    const end = Math.max(newIndex, previousIndex)
    return data.slice(start, end + 1).filter(isSelectable)
  }

  let ColumnManager = columnManager
  if (!columnManager && addableColumns) {
    ColumnManager = DefaultColumnManager
  }
  const leftColumnMd = wideHeader ? 12 : 3
  const middleColumnMd = wideHeader ? 12 : 2
  const middleColumnLg = wideHeader ? 6 : 3
  const rightColumnMd = wideHeader ? 12 : 4
  const rightColumnLg = wideHeader ? 12 : 4
  const tableHeader = (
    <div className={moduleStyles.tableHeaderWrapper} data-testid="table-header">
      <SolGrid>
        <SolRow className={moduleStyles.tableHeader}>
          <SolColumn
            md={leftColumnMd}
            lg={3}
            xl={3}
            className={classNames(
              tableHeaderColumnAutoWidth && moduleStyles.tableHeaderColumnAutoWidth,
              moduleStyles.searchBarInput,
            )}
          >
            {searchable !== false && (
              <SearchFilter
                value={searchValue}
                placeholder=""
                onSearch={val => {
                  onSearch(val, clearSelectedItemsOnFilter)
                }}
              />
            )}
          </SolColumn>
          <SolColumn
            className={classNames(
              tableHeaderColumnAutoWidth && moduleStyles.tableHeaderColumnAutoWidth,
              moduleStyles.tableName,
            )}
            md={middleColumnMd}
            lg={middleColumnLg}
            xl={4}
          >
            {title && title.length > 0 && <h3 style={searchable !== false ? styles.flexH3 : styles.h3}>{title}</h3>}
          </SolColumn>
          <SolColumn
            className={classNames(
              tableHeaderColumnAutoWidth && moduleStyles.tableHeaderColumnAutoWidth,
              moduleStyles.columnManager,
            )}
            md={rightColumnMd}
            lg={rightColumnLg}
            xl={5}
          >
            {!!ColumnManager && <ColumnManager />}
          </SolColumn>
        </SolRow>
        {showAddableColumns && (
          <SolRow>
            <div className={moduleStyles.columnSelect}>
              {columns
                .filter(c => !(!!c.isCategory && !addCategoryCols))
                .map(
                  col =>
                    col.addable !== false && (
                      <Checkbox
                        key={col.name}
                        label={toDisplayName(col)}
                        name={col.name}
                        className={moduleStyles.columnSelectCheckbox}
                        checked={addedColumns.includes(col.name)}
                        onClick={(_, b) => {
                          if (b.checked) {
                            onAddColumn(col.name)
                          } else {
                            onRemoveColumn(col.name)
                          }
                        }}
                        disabled={columnsOrder.some(item => item.name === col.name)}
                      />
                    ),
                )}
            </div>
          </SolRow>
        )}
        {filterable !== false && hasAppliedFilters && !filterDrawer && (
          <SolRow className={moduleStyles.filterPills}>
            <div>
              <AppliedFilters
                tableId={id}
                clearSelectedItemsOnFilter={clearSelectedItemsOnFilter}
              />
            </div>
          </SolRow>
        )}
      </SolGrid>
    </div>
  )

  const colEmphasizePositions = columnsOrder.reduce(
    (acc: { first?: number; last?: number }, nextCol: ColumnsOrder, index) => {
      if (!!!acc?.first && nextCol.emphasize) {
        return { first: index }
      }

      if (nextCol.emphasize) {
        return { ...acc, last: index }
      }

      return acc
    },
    {},
  )

  const hasOnlyOneEmphasiseCol = Object.keys(colEmphasizePositions).length === 1

  function getEmphasizeClass<ET>(column: DataTableSlimColumn<ET>, index: number) {
    const isFirst = index === colEmphasizePositions.first

    const isLast = hasOnlyOneEmphasiseCol ? isFirst : index === colEmphasizePositions?.last

    const isEmphasize = (isFirst || isLast) && !loading && data.length

    const result = {
      [`emphasize ${isFirst ? 'start' : ''} ${isLast ? 'end' : ''}`]: isEmphasize,
    }

    return result
  }

  const onDropdownOpen = (isLastItem: boolean) => () => {
    if (isLastItem) {
      setShouldExpandaBottom(true)
    }
  }

  const onDropdownClose = () => {
    setShouldExpandaBottom(false)
  }

  const table = (
    <div
      className={classNames(moduleStyles.table, {
        [moduleStyles.scrollable]: scrollable,
        [moduleStyles.expandBottom]: shouldExpandBottom,
      })}
      data-testid="table-body"
    >
      <Table sortable striped={striped !== false} className={moduleStyles.dataTableSlim} fixed={fixed}>
        <Table.Header>
          <Table.Row>
            {selectable && (
              <Table.HeaderCell
                collapsing
                className={
                  pagination.totalItemsSelectable === pagination.totalItems || pagination.totalItemsSelectable === 0
                    ? 'no-hover'
                    : ''
                }
                sorted={
                  orderBy
                    && orderBy.field === SELECTABLE_COLUMN
                    && pagination.totalItemsSelectable > 0
                    && pagination.totalItemsSelectable !== pagination.totalItems
                    ? orderBy.direction
                    : undefined
                }
                onClick={() => {
                  if (
                    pagination.totalItemsSelectable === 0
                    || pagination.totalItemsSelectable === pagination.totalItems
                    || !allowOrderBySelectable
                  ) {
                    return
                  }
                  const currentDirection
                    = orderBy && orderBy.field === SELECTABLE_COLUMN ? orderBy.direction : undefined
                  let nextDirection: Direction = 'ascending'
                  if (currentDirection === 'ascending') {
                    nextDirection = 'descending'
                  }
                  onSort(SELECTABLE_COLUMN, nextDirection)
                }}
              >
                <Popup
                  hoverable
                  open={pagination.totalItemsSelectable === 0 && selectAllPopupIsOpen}
                  onOpen={() => setSelectAllPopupIsOpen(true)}
                  onClose={() => setSelectAllPopupIsOpen(false)}
                  position="right center"
                  trigger={
                    <Checkbox
                      className="selectable-column-header"
                      disabled={pagination.totalItemsSelectable === 0}
                      checked={selectAllChecked}
                      indeterminate={selectAllIndeterminate}
                      onChange={(e, checkbox) => {
                        e.stopPropagation()
                        onSelect(checkbox.checked ? 'select' : 'deselect', 'all')
                      }}
                    />
                  }
                >
                  <Popup.Header>There are no selectable items.</Popup.Header>
                </Popup>
              </Table.HeaderCell>
            )}
            {columns
              .filter(c => {
                if (!addCategoryCols && c.isCategory) {
                  return false
                }
                return (c.addable === false || addedColumns.length === 0 || addedColumns.includes(c.name))
              })
              .map((c, index) => {
                const { className, name, collapsing, sortable = true } = c
                const onClick = sortable ? sort(orderBy, name, onSort) : undefined
                return (
                  <Table.HeaderCell
                    className={classNames(className, getEmphasizeClass<T>(c, index), {
                      ['no-hover']: !sortable,
                    })}
                    key={name}
                    sorted={sortDirection(orderBy, name)}
                    collapsing={collapsing}
                    onClick={onClick}
                    width={c.width}
                  >
                    <div
                      className={classNames(moduleStyles.headerCell, {
                        [moduleStyles.onsortIcon]: sortable,
                      })}
                    >
                      <div className={moduleStyles.displayName}>
                        {c.isIcon ? (
                          <IconTooltip tooltip={c.tooltipText} trigger={toDisplayName<T>(c)} />
                        ) : (
                          <span className="displayName">
                            {toDisplayName<T>(c)}
                            {headerTooltip(c.tooltipText)}
                          </span>
                        )}
                      </div>
                    </div>
                  </Table.HeaderCell>
                )
              })}
            {editColumn && editColumn.visible && (
              <Table.HeaderCell collapsing key="edit" className="no-hover">
                Edit
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item, index) => (
            <Table.Row key={index} error={errorOnRow?.(item)}>
              {selectable && (
                <Table.Cell collapsing>
                  {isSelectable(item) && (
                    <Checkbox
                      checked={
                        (includedIds.includes('*') && !excludedIds.includes(idSelector(item) + ''))
                        || selectedIds.includes(idSelector(item))
                      }
                      onClick={(e, event) => {
                        onSelect(
                          event.checked ? 'select' : 'deselect',
                          'some',
                          e.shiftKey ? selectSequence(lastSelectedRow, index) : [item],
                        )
                        setLastSelectedRow(index)
                      }}
                    />
                  )}
                </Table.Cell>
              )}
              {columns
                .filter(
                  column =>
                    !column.hidden
                    && (column.addable === false || addedColumns.length === 0 || addedColumns.includes(column.name)),
                )
                .map((column, idx) => (
                  <Cell
                    blur={blur}
                    key={column.name}
                    column={column}
                    className={classNames(column.className, getEmphasizeClass(column, idx))}
                    selectable={typeof selectable === 'function' ? selectable(item) : selectable}
                    item={item}
                    addCategoryCols={addCategoryCols}
                  />
                ))}
              {editColumn && editColumn.visible && (
                <Table.Cell key="edit-row">
                  <SolEllipsesDropdown
                    direction="left"
                    onOpen={onDropdownOpen(data.length === index + 1)}
                    onClose={onDropdownClose}
                  >
                    {editColumn.render(item)}
                  </SolEllipsesDropdown>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
          {data.length === 0 && !loading && (
            <Table.Row>
              <Table.Cell
                content={
                  !hasAppliedFilters && !searchValue && defaultNoDataMessage
                    ? defaultNoDataMessage
                    : 'No data to display.'
                }
                textAlign="center"
                colSpan={colSpan}
              />
            </Table.Row>
          )}
          {data.length === 0 && loading && (
            <Table.Row>
              <Table.Cell
                content={
                  <Loader active inline>
                    Loading...
                  </Loader>
                }
                textAlign="center"
                colSpan={colSpan}
              />
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )

  const tableFooter = (
    <div data-testid="table-footer">
      <SolRow className={moduleStyles.tableFooter}>
        {pagination && (
          <DataTableSlimPagination
            colSpan={colSpan}
            loading={loading}
            {...pagination}
            onPageChange={page => {
              pagination.onPageChange(page)
              setLastSelectedRow(0)
            }}
          />
        )}
      </SolRow>
    </div>
  )

  const fullTable = (
    <>
      {tableHeader}
      {table}
      {tableFooter}
    </>
  )

  const filteredTable = filterDrawer ? (
    fullTable
  ) : (
    <FilteredTableHolder tableId={id} clearSelectedItemsOnFilter={clearSelectedItemsOnFilter}>
      {fullTable}
    </FilteredTableHolder>
  )

  return (
    <div className={classNames(
      moduleStyles.wrapper,
      { [moduleStyles.padded]: padded },
    )}>
      <LoadingBar visible={loading === true} header />
      {filterable === false ? fullTable : filteredTable}
    </div>
  )
}

// Breaking out 'Cell' and memoizing it helps out with performance
// This way every cell on the page doesn't rerender if the parent does
// This really matters with expensive cells like dropdowns
const Cell = React.memo(({
  column,
  className,
  selectable,
  item,
  addCategoryCols,
  blur,
}: {
  column: DataTableSlimColumn<any>
  className: string
  selectable?: any
  item: any
  addCategoryCols: any
  blur: any
}) => {
  return (
    <Table.Cell
      key={column.name}
      collapsing={column.collapsing}
      className={className}
      textAlign={column.centered ? 'center' : undefined}
    >
      {!column.isCategory && blur && blur(column.name, item) && (
        <div className="greyBlur">
          {item?.license?.shortName === SolsticeElementEnum.licenseShortName
            ? 'Not Available'
            : 'Out of Subscription'
          }
        </div>
      )}
      {!column.isCategory && (!blur || !blur(column.name, item)) && (
        <span
          className={classNames({
            greyData: selectable,
          })}
        >
          {column.render ? column.render(item) : item[column.name + '']}
        </span>
      )}

      {/* Category Columns */}
      {addCategoryCols && getCategoryColumns({ column, item })}
    </Table.Cell>
  )
})

export default DataTableSlim
