import React from 'react'
import { Link } from 'react-router-dom'
import styles from './CreateOrgLink.module.scss'

const CreateOrgLink = () => {
  return (
    <div className={styles.createOrgLink}>
      <span>Don't have an account? </span>
      <Link
        to={{
          pathname: '/create-org',
        }}
      >
        <strong>Join Now.</strong>
      </Link>
    </div>
  )
}

export default CreateOrgLink
