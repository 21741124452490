import React, { useState } from 'react'
import { Segment } from 'semantic-ui-react'
import { SolButton } from 'SolComponents'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import styles from './WithHelp.module.scss'

interface Props {
  component: () => React.ReactChild
  helpText: string
}

export const WithHelp = ({ component, helpText }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <div className={styles.parent}>
      {component()}
      <div className={styles.helpButton}>
        {helpText && (
          <React.Fragment>
            <SolButton basic noBoxShadow text={<SolQuestionCircle />} handleClick={toggleExpanded} />
            {expanded && (
              <div className={styles.segmentDiv}>
                <Segment content={helpText} onClick={toggleExpanded} className={styles.segment} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
