import React from 'react'
import styles from './DualOutPreview.module.scss'
import classNames from 'classnames'
import { WelcomeScreenConfiguration, InstructionsDisplayType } from 'graphql/__generated__/types'
import { applyImageServer } from 'shared/core/utils'
import { Loader } from 'semantic-ui-react'
import SolWifiIcon from 'SolComponents/Icons/SolWifiIcon'

const calendar = require('../../../../../assets/preview_calendar.png')
const instructions = require('../../../../../assets/preview_instructions.png')
const miracast = require('../../../../../assets/preview_airplay_miracast.png')
const airplay = require('../../../../../assets/preview_airplay_miracast.png')

export interface Props {
  settings: WelcomeScreenConfiguration
  bgImage: string
  loadingImage: boolean
}

export default function DualOutPreview(props: Props) {
  return (
    <div
      className={styles.welcomePreview}
      style={props.settings?.textColor ? { color: props.settings?.textColor } : {}}
    >
      <Loader active={props.loadingImage} inverted />
      {/* Background Image */}
      <img className={styles.singleImage} src={`${applyImageServer(props.bgImage)}&width=800`} />
      <div className={styles.screenDivider} />
      {/* PresenceBar */}
      {props.settings.pbShowPresenceBar && (
        <div className={classNames([styles.presenceBar], [styles.flashing])} style={{ color: 'white' }}>
          {props.settings.pbShowRoomName && (
            <div className={classNames([styles.pbRoomName], [styles.flashing])}>Dual Name</div>
          )}
          {props.settings.pbShowIPAddress && (
            <div className={classNames([styles.ipAddress], [styles.flashing])}>IP Address</div>
          )}
          {props.settings.pbShowScreenKey && (
            <div className={classNames([styles.pbScreenKey], [styles.flashing])}>1234</div>
          )}
        </div>
      )}
      {/* Main Screen */}
      {props.settings.screenKey && <div className={classNames([styles.msScreenKey], [styles.flashing])}>1234</div>}
      {props.settings.showClock && <div className={classNames([styles.clock], [styles.flashing])}>12:45pm</div>}
      {props.settings.showWifi && (
        <div className={classNames([styles.wifi], [styles.flashing])}>
          WifiNetworkName <SolWifiIcon size="small" className={styles.wifiIcon} />
        </div>
      )}
      {props.settings.showRoomName && (
        <div className={classNames([styles.msRoomName], [styles.flashing])}>[Dual Name]</div>
      )}
      {/* SideBar */}
      <div className={styles.sideBar}>
        <img className={styles.calendar} src={calendar} />
        {props.settings.instructions !== InstructionsDisplayType.Hide && (
          <img className={classNames([styles.instructions], [styles.flashing])} src={instructions} />
        )}
        {props.settings.showAirplay && (
          <img className={classNames([styles.airplay], [styles.flashing])} src={airplay} />
        )}
        {props.settings.showMiracast && (
          <img className={classNames([styles.miracast], [styles.flashing])} src={miracast} />
        )}
      </div>
    </div>
  )
}
