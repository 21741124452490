import {
  OrderBy as HasuraOrderBy,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import { getCategorySearchArg, getDirection } from '../Utils/hasuraUtils'
import { OrderBy } from '../DataTableSlim'
import { useHasuraFilters } from './useAppliedFilters'
import { DataTableInstance } from '../DataTableSlim'
import { Page } from '../types'
import { useTablePagination } from './useTablePagination'
import { Direction } from 'graphql/__generated__/types'

export interface SearchParams<SearchType> {
  tableInstance: DataTableInstance
  search?: string
  orderBy?: OrderBy
  page: Page
  setOrderBy: (col: string, dir: string) => void
  sortMapper?: (field: string, direction: HasuraOrderBy | Direction) => any
  searchMapper?: (searchTerm: string) => SearchType
  categoryArgsMapper?: (args: ViewDisplaysBoolExp) => SearchType
}

const getSearchArgs = <SearchType>(props: {
  searchTerm?: string
  searchMapper?: (searchTerm: string) => SearchType
}) => {
  const { searchTerm, searchMapper } = props

  if (searchTerm?.length) {
    const searchTermClause = searchMapper?.(searchTerm)
    return searchTermClause
  }
  return undefined
}

export const searchByNameMapper = (searchTerm: string) => ({
  name: { _ilike: `%${searchTerm}%` },
})

/**
 * Creates GraphQL query options for a table instance interacting with Hasura that add nested filters to the sql's WHERE clause
 * @param o - object with parameters
 * @param o.searchTerm - A string on which to filter table results
 * @param o.tableInstance - An instance of a data table in the app Context 
 * @param o.searchMapper - Turns searchTerm into GraphQL query options for a table instance. WHY IS THIS DEPENDENCY INJECTED?!?! ಠ_ಠ
 * @param o.categoryArgsMapper - ?
 * @returns An object with searchTerm and a constructed GraphQL query options object for Hasura
 */
export function useWhereFilter<SearchType>({
  search: searchTerm,
  tableInstance,
  searchMapper,
  categoryArgsMapper,
}: {
  search?: string
  tableInstance: DataTableInstance
  searchMapper?: (searchTerm: string) => SearchType
  categoryArgsMapper?: (args: ViewDisplaysBoolExp) => SearchType
}) {
  const appliedCategories = useHasuraFilters(tableInstance)

  const categorySearchArg = getCategorySearchArg(appliedCategories ?? [])
  const categoryArgs = categoryArgsMapper
    ? categoryArgsMapper(categorySearchArg)
    : (categorySearchArg as SearchType)

  const where = {
    ...categoryArgs,
    ...getSearchArgs<SearchType>({
      searchTerm,
      searchMapper,
    }),
  }

  return {
    searchTerm,
    where,
  }
}

/**
 * Hook to get GraphQL query options for Hasura.
 * @param o - Param container object.
 * @param o.tableInstance - The instance of a DataTable in the app Context we are creating search terms for.
 * @param o.search - String to be filtered on.
 * @param o.orderBy - Field we will sort the query on.
 * @param o.page - Informs the offset and limit parameters of the SQL query
 * @param o.setOrderBy - ?
 * @param o.sortMapper - Dependency injected override for formatting the orderBy clause in the Hasura query.
 * @param o.searchMapper - Turns searchTerm into GraphQL query options for a table instance.
 * @param o.categoryArgsMapper - ?
 * 
 * WHY ARE THE MAPPER PARAMETERS DEPENDENCY INJECTED?!?! ಠ_ಠ
 */
export function useSearchParams<SearchType>({
  tableInstance,
  search,
  orderBy: orderByParam,
  page,
  setOrderBy,
  sortMapper,
  searchMapper,
  categoryArgsMapper,
}: SearchParams<SearchType>) {
  const direction: HasuraOrderBy | undefined = orderByParam?.direction
    ? getDirection(orderByParam?.direction)
    : undefined

  const orderBy
    = !!sortMapper && orderByParam && direction
      ? sortMapper(orderByParam.field, direction)
      : orderByParam

  const { where } = useWhereFilter<SearchType>({
    search,
    tableInstance,
    searchMapper,
    categoryArgsMapper,
  })

  const { pagination } = useTablePagination(page)

  const handleOrderBy = (params: OrderBy) => {
    setOrderBy(params.field, params.direction)
  }

  return {
    where,
    orderBy,
    offset: pagination.offset ?? 0,
    limit: pagination.limit ?? 25,
    handleOrderBy,
  }
}
