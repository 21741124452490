import React, { useState, useEffect } from 'react'
import styles from './SolPassword.module.scss'
import { Input, Icon, InputOnChangeData } from 'semantic-ui-react'
import classNames from 'classnames'

export interface Props {
  className?: string
  secret?: boolean
  error?: boolean
  label?: string | JSX.Element
  required?: boolean
  disabled?: boolean
  value?: string | null
  hash?: string | null
  salt?: string | null
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge' | 'fluid'
  name?: string
  onClear: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement> | null, data: InputOnChangeData) => void
  onFocus?: () => void
  placeholder?: string
  errorText?: string | null
  isDirty?: boolean
  optionalInput?: boolean
}

export default function SolPassword({
  label,
  className,
  required,
  disabled,
  onChange,
  error = false,
  name,
  onClear,
  onFocus,
  errorText,
  size = 'medium',
  isDirty,
  optionalInput,
  placeholder,
  ...props
}: Props) {
  const value = props.value ?? ''

  const [showEyeIcon, setShowEyeIcon] = useState(false)
  const [showClearIcon, setClearIcon] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  // A revert or a save happened outside of this components knowledge
  useEffect(() => {
    if (isDirty === false) {
      if (value) {
        setShowPassword(false)
        setShowEyeIcon(false)
        setClearIcon(true)
      }
    }
  }, [isDirty, value])

  const labelComponent = (
    <label>
      {label}
      {required && <span className={styles.required}>*</span>}
      {optionalInput && <span className={styles.optionalInput}>&nbsp;(Optional)</span>}
    </label>
  )
  return (
    <div
      className={classNames(styles.inputGroup, className,  {
        [styles.disabled]: disabled,
        [styles.fluid]: size === 'fluid',
      })}
    >
      {label && labelComponent}
      <Input
        className={classNames(
          { [styles.small]: size === 'small' },
          { [styles.defaultMedium]: size === 'medium' },
          { [styles.large]: size === 'large' },
        )}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={(e, v) => {
          onChange(e, v)
        }}
        disabled={disabled}
        icon
        error={error}
        name={name}
        onKeyDown={() => {
          if (value === undefined || (!isDirty && value?.length > 0)) {
            onClear()
          }
          setShowEyeIcon(true)
          setClearIcon(false)
        }}
        onFocus={() => {
          if (onFocus) {
            onFocus()
          }
          if (value === undefined || (!isDirty && value?.length > 0)) {
            setShowEyeIcon(false)
            setClearIcon(true)
          }
          if ((isDirty && value?.length === 0) || (isDirty && value?.length > 0)) {
            setShowEyeIcon(true)
            setClearIcon(false)
          }
        }}
        placeholder={placeholder}
        autoComplete="new-password"
        fluid={size === 'fluid'}
      >
        <input data-lpignore="true" data-testid="password-input" />
        {showEyeIcon && !showPassword && (
          <Icon
            data-testid="eye-icon"
            name="eye"
            className={styles.showPassword}
            link
            onClick={() => setShowPassword(prev => !prev)}
          />
        )}
        {showEyeIcon && showPassword && (
          <span
            data-testid="hide-icon"
            className={styles.hidePassword}
            onClick={() => setShowPassword(prev => !prev)}
          >
            HIDE
          </span>
        )}
        {value && showClearIcon && (
          <Icon
            data-testid="clear-icon"
            name="times circle"
            className={styles.showPassword}
            link
            onClick={() => {
              onClear()
              setShowEyeIcon(true)
              setClearIcon(false)
            }}
          />
        )}
      </Input>
      {typeof errorText === 'string' && <div className={styles.errorText}>{errorText}</div>}
    </div>
  )
}
