import React from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'
import styles from './MetricCards.module.scss'
import { SolButton, SolMetricCard } from 'SolComponents'
import pick from 'lodash/pick'
import { Role } from 'shared/types/Role'
import { useUserRoleCount, useDisplayCount, useRoomCount } from './hooks'
import { utm } from '../constants'
import { pluralize } from 'shared/core/utils'
import { emptyPieData } from 'SolComponents/SolDonutChart/SolDonutChart'
import { Link } from 'react-router-dom'
import { ActiveLearningStatus, DisplaySearchOptions } from 'graphql/__generated__/types'
import { useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'

const LearnMoreButton = ({ url }: { url: string }) => (
  <SolButton text="LEARN MORE" color="blue" basic isLink externalLink={url} />
)

const ReadyToEnableButton = ({ count, onClick }: { count: number; onClick: () => void }) => (
  <SolButton
    text={`${pluralize(count, 'Pod')} waiting assignment\u00A0>`}
    color="blue"
    basic
    isLink
    handleClick={onClick}
  />
)

const urls = {
  enabledPods: `https://www.mersive.com/active-learning/?${utm}`,
  rooms: `https://documentation.mersive.com/en/solstice-active-learning.html?${utm}`,
  designers: `https://documentation.mersive.com/en/active-learning-setup.html?${utm}`,
  facilitators: `https://documentation.mersive.com/en/al-settings-and-sessions.html?${utm}`,
}

export const MetricCards = ({
  displaySearchOptions,
}: {
  displaySearchOptions: Pick<DisplaySearchOptions, 'searchTerm' | 'categories' | 'locationFilters'>
}) => {
  const { customFilters, setCustomFilter } = useCustomFilters<ActiveLearningStatus>(
    DataTableInstance.ActiveLearningPods,
  )
  const addStatusFilter = (
    internalName: string,
    displayName: string,
    status: ActiveLearningStatus,
    color: SemanticCOLORS,
  ) => {
    const values = {
      ...customFilters.activeLearningStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomFilter('activeLearningStatus', {
      values,
      color: Object.values(values).length > 1 ? 'blue' : color,
    })
  }

  const roleCount = useUserRoleCount()
  const roomCount = useRoomCount()
  const displayCount = useDisplayCount(displaySearchOptions)
  const filterValues = customFilters.activeLearningStatus?.values
  const pieDisplayCount = filterValues
    ? {
      currentSubscription: filterValues.currentSubscription ? displayCount.currentSubscription : 0,
      trial: filterValues.trial ? displayCount.trial : 0,
      expiringSoon: filterValues.expiringSoon ? displayCount.expiringSoon : 0,
    }
    : pick(displayCount, ['currentSubscription', 'trial', 'expiringSoon'])

  const pieData = [
    {
      name: `${pieDisplayCount.currentSubscription} current subscription`,
      count: pieDisplayCount.currentSubscription,
      fill: '#22ba46',
      onClick: () =>
        addStatusFilter('currentSubscription', 'Current subscription', ActiveLearningStatus.Current, 'green'),
    },
    {
      name: `${pieDisplayCount.trial} trial`,
      count: pieDisplayCount.trial,
      fill: '#ea305d',
      onClick: () => addStatusFilter('trial', 'Trial', ActiveLearningStatus.TrialActive, 'pink'),
    },
    {
      name: `${pieDisplayCount.expiringSoon} expiring soon`,
      count: pieDisplayCount.expiringSoon,
      fill: '#fbbd0a',
      onClick: () => addStatusFilter('expiringSoon', 'Expiring soon', ActiveLearningStatus.ExpiringSoon, 'yellow'),
    },
  ]
  const totalEnabledDisplays
    = pieDisplayCount.currentSubscription + pieDisplayCount.trial + pieDisplayCount.expiringSoon
  return (
    <div className={styles.metricCards}>
      <SolMetricCard
        count={totalEnabledDisplays}
        label="Active Learning"
        unit="Enabled Pods"
        note="Enable Pods below to start using your free trial."
        button={totalEnabledDisplays === 0 ? <LearnMoreButton url={urls.enabledPods} /> : undefined}
        pieData={totalEnabledDisplays === 0 ? emptyPieData : pieData}
        hideTooltip={totalEnabledDisplays === 0}
        legendVerticalAlign="bottom"
        externalLegend
      />
      <SolMetricCard
        count={roomCount}
        label="Active Learning"
        unit="Rooms"
        note="Use the Active Studio App to design rooms and assign Pods."
        button={
          roomCount ? (
            <ReadyToEnableButton
              count={displayCount.readyToEnable}
              onClick={() =>
                addStatusFilter('readyToEnable', 'Waiting assignment', ActiveLearningStatus.ReadyToEnable, 'pink')
              }
            />
          ) : (
            <LearnMoreButton url={urls.rooms} />
          )
        }
      />
      <SolMetricCard
        count={roleCount?.[Role['Active Learning Designer']] ?? 0}
        label="Active Learning"
        unit="Designers"
        note={
          <>
            {'Invite others to set up Active Learning Rooms on the '}
            <Link to="/manage/organization/users">Users Page</Link>
            .
          </>
        }
        button={<LearnMoreButton url={urls.designers} />}
      />
      <SolMetricCard
        count={roleCount?.[Role['Active Learning Facilitator']] ?? 0}
        label="Active Learning"
        unit="Facilitators"
        note={
          <>
            {'Invite others to use Active Learning on the '}
            <Link to="/manage/organization/users">Users Page</Link>
            .
          </>
        }
        button={<LearnMoreButton url={urls.facilitators} />}
      />
    </div>
  )
}
