import React from 'react'
import Metric from './Metric'
import { pluralize } from 'shared/core/utils'

interface CurrentMeetingsProps {
  count: number
}

export function CurrentMeetings({ count = 0 }: CurrentMeetingsProps) {
  return (
    <Metric live count={count} caption={`${pluralize(count, 'Meeting', false)} underway`} dataTestId="meeting-card" />
  )
}
export default CurrentMeetings
