export interface Pod {
  id: string
  name: string
  lmsVersion: string
  mxExpires?: string
  ipEthernet?: string
  ipWifi?: string
  version: string | '(unknown)'
  status?: boolean
  timezone?: string
  utcOffset?: string
  // [key: string]: any
}

interface Auth0Role {
  id: number
  description: string
  name: string
}

export interface Auth0Authorization {
  permissions: string[]
  groups: string[]
  roles: Auth0Role[]
}

export interface AllAppliedCategories {
  [podId: string]: AppliedCategories
}

export interface AppliedCategories {
  [categoryId: string]: string
}

export interface Category {
  displayName: string
  internalName: string
  options: {
    [optionId: string]: Option
  }
}

export interface Option {
    displayName: string
    internalName: string
}

export interface Categories {
  [categoryId: string]: Category
}

export interface CategoryCount {
  [categoryId: string]: {
    [optionId: string]: number
    unassigned: number
  }
}

interface Auth0Role {
  id: number
  description: string
  name: string
}

export interface Auth0Authorization {
  permissions: string[]
  groups: string[]
  roles: Auth0Role[]
}

export enum UserPermissions {
  READ_USERS = 'read:users',
  CREATE_USERS = 'create:users',
  UPDATE_USERS = 'update:users',
  DELETE_USERS = 'delete:users',
  READ_DISPLAYS = 'read:displays',
  CREATE_DISPLAYS = 'create:displays',
  DELETE_DISPLAYS = 'delete:displays',
  UPDATE_DISPLAYS = 'update:displays',
  READ_CATEGORIES = 'read:categories',
  CREATE_CATEGORIES = 'create:categories',
  UPDATE_CATEGORIES = 'update:categories',
  DELETE_CATEGORIES = 'delete:categories',
  READ_ROLES = 'read:roles'
}
