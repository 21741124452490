import React from 'react'
import { SolNotification, SolTooltip } from 'SolComponents'
import styles from './SolIconNotification.module.scss'

interface Props {
  icon: (props: { className: string }) => JSX.Element
  text: string
  className?: string
}

const SolIconNotification = (props: Props) => (
  <SolNotification
    type="menu"
    notifications={
      <SolTooltip
        isInline
        trigger={
          <props.icon className={styles.icon} />
        }
        text={props.text}
        className={styles.tooltip}
      />
    }
    className={props.className}
  />
)

export default SolIconNotification
