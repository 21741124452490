import React, { useState }  from 'react'
import { useUpdateablePodsCount } from 'pages/Updates/hooks/useUpdateablePods'
import SolMenu, { ActiveIndex } from './SolMenu'
import { MenuDropdown, Props as MenuDropdownProps } from './MenuDropdown'
export interface State {
  activeIndex?: ActiveIndex
  isExpanded: boolean
}

export type SolMenuProps = {
  path: string
  updates: number
  manageEnabled: boolean
  userAnalyticsPage: boolean
  locationsPage: boolean
  realtimePage: boolean
  ssoAdminPage: boolean
  manageAccounts: boolean
  hasDisplayUpdates: boolean
  managementHistory: boolean
  solsticeRouting: boolean
  dropdown: (props: Omit<MenuDropdownProps, 'state' | 'setState'>) => JSX.Element
}

type SolMenuWrapperProps = Omit<SolMenuProps,
  | 'updates'
  | 'dropdown'
>

export default function SolMenuWrapper(wrapperProps: SolMenuWrapperProps) {
  const updateCount = useUpdateablePodsCount()

  const [state, setState] = useState<{
    activeIndex?: ActiveIndex
    isExpanded: boolean
  }>({
    isExpanded: false,
  })

  const dropdown = (dropdownProps: MenuDropdownProps & {
    state: State
    setState: (state: State) => void
  }) => (
    <MenuDropdown
      {...dropdownProps}
      state={state}
      setState={setState}
    />
  )

  return (
    <SolMenu
      {...wrapperProps}
      updates={updateCount}
      dropdown={dropdown}
    />
  )
}
