import { useLeaflet } from 'react-leaflet'
import React, { useState, useEffect } from 'react'
import { LatLngBounds } from 'leaflet'
import { Checkbox } from 'semantic-ui-react'
import styles from './Map.module.scss'

type Props = {
  setBoundingBoxFilter: (newFilter?: number[][]) => void
  setMapBounds: (newFilter?: number[][]) => void
  boundingBoxFilter?: number[][]
  filterCheckBoxValue: boolean
  setFilterCheckBoxValue: (el: boolean) => void
}

export function FilterByVisiblePodsCheckbox({
  setBoundingBoxFilter,
  filterCheckBoxValue,
  setFilterCheckBoxValue,
  setMapBounds,
}: Props) {
  const { map } = useLeaflet()
  const [showCheckBox, setshowCheckBox] = useState(false)
  const [zoom, setZoom] = useState(1)

  useEffect(() => {
    map?.addEventListener('zoom', () => {
      setZoom(map?.getZoom() ?? 1)
    })
  }, [])

  useEffect(() => {
    // instead of onViewPointChanged
    setshowCheckBox(zoom > 5)
    if (zoom > 5) {
      setMapBounds(getBoundingBox())
    } else {
      setMapBounds(undefined)
    }

    if (filterCheckBoxValue && zoom > 5) {
      setBoundingBoxFilter(getBoundingBox())
    } else {
      setBoundingBoxFilter(undefined)
      setFilterCheckBoxValue(false)
    }
  }, [zoom])

  const getBoundingBox = () => {
    let bounds: LatLngBounds | undefined = undefined
    if (map) {
      bounds = map?.getBounds()
    }

    if (bounds) {
      return [
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      ]
    }
    return [[]]
  }

  return (
    <>
      {showCheckBox && (
        <Checkbox
          label="Filter table by visible Pods"
          checked={filterCheckBoxValue}
          onChange={() => {
            setFilterCheckBoxValue(!filterCheckBoxValue)
            if (!filterCheckBoxValue) {
              setBoundingBoxFilter(getBoundingBox())
            } else {
              setBoundingBoxFilter(undefined)
            }
          }}
          className={styles.filterAllPods}
        />
      )}
    </>
  )
}

export default FilterByVisiblePodsCheckbox
