
import { Maybe, ScheduledTask, TaskStatus } from 'graphql/__generated__/types'
import { max, set, startOfHour, addHours, getHours } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { UPDATE_DURATION_LIMIT } from 'shared/hooks/useCreateScheduledTask'
import { DisplayUpdateTask } from 'graphql/__generated__/types'
import omit from 'lodash/omit'

export interface Pod extends DisplayUpdateTask {
  utcOffset: string
}

export const getPodsGroupedByOffset = (
  podArray: DisplayUpdateTask[],
): { [key: string]: Pod[] } => {
  
  return podArray
    .map(pod => {
      const utcOffset = formatInTimeZone( new Date(), pod.timezone || 'utc', 'xxxxx')
      return {
        ...pod,
        utcOffset: utcOffset,
      }
    })
    .sort((a, b) => {
      if (a.utcOffset && b.utcOffset) {
        return (
          parseFloat(a.utcOffset.replace(':', '.'))
        - parseFloat(b.utcOffset.replace(':', '.'))
        )
      }
      return 0
    })
    .reduce((acc: {}, obj) => {
      const key = obj.utcOffset || '+00:00'
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(omit(obj, ['utcOffset']))
      return acc
    }, {})
}


export const getNumberOfCurrentPods = (task: ScheduledTask): number =>
  task.displays?.filter(
    d => d?.status !== TaskStatus.Completed && d?.status !== TaskStatus.Failed,
  ).length ?? 0

export const getNumberOfCompletedPods = (task: ScheduledTask): number =>
  task.displays?.filter(d => d?.status === TaskStatus.Completed).length ?? 0

export const getNumberOfPodFailures = (task: ScheduledTask): number =>
  task.displays?.filter(d => d?.status === TaskStatus.Failed).length ?? 0

export function getPodStatus(pod: Maybe<DisplayUpdateTask>) {
  return pod?.status ?? TaskStatus.Pending
}

export function getTaskStatus(task: ScheduledTask): TaskStatus | 'running' {
  const numPods = task.displays?.length ?? 0
  const pods = task?.displays ?? []

  if (numPods === 0) {
    return TaskStatus.Pending
  }

  if (
    pods.every(p =>
      [TaskStatus.Completed, TaskStatus.Failed].includes(getPodStatus(p)),
    )
  ) {
    return TaskStatus.Completed
  }

  if (
    pods.some(p =>
      [
        TaskStatus.Completed,
        TaskStatus.Installing,
        TaskStatus.Installpending,
        TaskStatus.Downloading,
      ].includes(getPodStatus(p)),
    )
  ) {
    return 'running'
  }

  return TaskStatus.Pending
}

export function getUpdatePercent(pod: Maybe<DisplayUpdateTask>): number {
  if (!pod) {
    return 0
  }

  const status = getPodStatus(pod)
  if (
    [
      TaskStatus.Installing,
      TaskStatus.Installpending,
      TaskStatus.Completed,
    ].includes(status)
  ) {
    return 100
  }

  const downloadPercentage = pod.downloadPercentage ?? 0
  return Math.round(downloadPercentage * 100)
}

export function getFailureMessage(pod: Maybe<DisplayUpdateTask>): string {
  const errorMessage = pod?.errorMessage

  if (!errorMessage) {
    return ''
  }

  if (errorMessage.includes(UPDATE_DURATION_LIMIT.toString())) {
    return errorMessage.replace(
      `${UPDATE_DURATION_LIMIT} seconds`,
      `${UPDATE_DURATION_LIMIT / 60 / 60} hours`,
    )
  }

  return 'An error occurred during the update'
}

export function getActivityMessage(pod: Maybe<DisplayUpdateTask>): string {
  const status = getPodStatus(pod)
  switch (status) {
    case 'downloading': {
      return 'Downloading...'
    }
    case 'installpending':
    case 'installing':
      return 'Installing...'
    case 'failed': {
      return getFailureMessage(pod)
    }
    case 'pending': {
      return 'Pending...'
    }
    default: {
      return ''
    }
  }
}

export function getTaskCompletionPercentage(task: ScheduledTask) {
  const podIds = task.displays?.map(d => d?.displayId ?? '') ?? []

  if (podIds.length === 0) {
    return 0
  }

  const total = task.displays?.reduce((acc, pod) => {
    return acc + getUpdatePercent(pod)
  }, 0) ?? 0

  return Math.round(total / podIds.length)
}

export function getSuggestedStartDate(fromDate?: Date) {
  const currentDate = fromDate || new Date()
  const eightPM =  set(new Date(), { hours: 20, minutes: 0, seconds: 0, milliseconds: 0 })
  const currentHours = getHours(currentDate)
  const isInBusinessHours
    = ((currentHours > 6) && (currentHours < 20))
  if (isInBusinessHours) {
    return eightPM
  }
  return addHours(startOfHour(currentDate), 1 )
}

export function getDefaultStartDate(minStartDate: Date): Date {
  return max( [minStartDate, getSuggestedStartDate()] )
}
