import { useDispatch, useSelector } from 'react-redux'
import {
  setAddedColumns,
  setPage,
  setSelectedItems,
  setSearchValue,
  setOrderBy,
  setCategoryFilters,
  setCustomFilters,
  setCategorySorts,
} from 'shared/store/slices/table'
import { useDebounce } from 'use-debounce'
import { Data, defaultValue, Page } from '../types'
import { Direction, OrderBy } from '../DataTableSlim'
import { AppliedCategoryFilters, CustomFilters } from './useAppliedFilters'

interface SelectedItems<T> {
  items: T[]
  ids: T[keyof T][]
  includedIds?: string[]
  excludedIds?: string[]
}

interface DataTableHook<T, CustomFilterValueType> {
  selectedItems: SelectedItems<T>
  orderBy?: OrderBy
  searchValue: string
  page: Page
  addedColumns: string[]
  categoryFilters: AppliedCategoryFilters
  customFilters: CustomFilters<CustomFilterValueType>
  categorySorts: Record<string, string>
  tableData: Data<CustomFilterValueType>
  setSelectedItems: (value: SelectedItems<T>) => void
  setPage: (page: Page) => void
  setSearchValue: (searchValue: string) => void
  setOrderBy: (columnKey: string, direction: Direction) => void
  setAddedColumns: (columns: string[]) => void
  setCategoryFilters: (filters: AppliedCategoryFilters) => void
  setCustomFilters: (filters: CustomFilters<CustomFilterValueType>) => void
  setCategorySorts: (categorySorts: Record<string, string>) => void
}

interface UseDataTableOptions {
  searchDebounceMs?: number
  defaultSelectAll?: boolean
  clearSelectedItemsOnFilter?: boolean
}

export function useDataTable<T = void, CustomFilterValueType = {}>(
  tableId: string & (T extends void ? 'You need to pass in a generic type' : string),
  options: UseDataTableOptions = {},
): DataTableHook<T, CustomFilterValueType> {

  const allData = useSelector((state: any) => state.table)
  const user = useSelector((state: any) => state.user.name)

  const dispatch = useDispatch()

  const tableData = allData[user][tableId]

  let selectedItems = tableData ? tableData.selectedItems : defaultValue.selectedItems
  if (options.defaultSelectAll && !selectedItems.includedIds) {
    selectedItems = {
      ...selectedItems,
      includedIds: ['*'],
      excludedIds: [],
    }
  }

  const setters = {
    setSelectedItems: (items: SelectedItems<T>) => dispatch(setSelectedItems({ user, tableId, selectedItems: items })),
    setPage: (page: Page) => dispatch(setPage({ user, tableId, page })),
    setSearchValue: (searchValue: string) => dispatch(
      setSearchValue({ user, tableId, searchValue, clearSelectedItemsOnFilter: options.clearSelectedItemsOnFilter },
      )),
    setOrderBy: (columnKey: string, direction: Direction) => dispatch(
      setOrderBy({ user, tableId, columnKey, direction },
      )),
    setAddedColumns: (columns: string[]) => dispatch(setAddedColumns({ user, tableId, columns })),
    setCategoryFilters: (filters: AppliedCategoryFilters) => dispatch(
      setCategoryFilters({ user, tableId, filters, clearSelectedItemsOnFilter: options.clearSelectedItemsOnFilter },
      )),
    setCustomFilters: (filters: CustomFilters<CustomFilterValueType>) => dispatch(
      setCustomFilters({ user, tableId, filters, clearSelectedItemsOnFilter: options.clearSelectedItemsOnFilter },
      )),
    setCategorySorts: (categorySorts: Record<string, string>) => dispatch(
      setCategorySorts({ user, tableId, categorySorts },
      )),
  }
  const [searchTerm] = useDebounce(tableData?.searchValue ?? '', options.searchDebounceMs ?? 500)

  if (!tableData) {
    return {
      ...defaultValue,
      selectedItems,
      tableData,
      ...setters,
    }
  }

  return {
    selectedItems,
    page: tableData.page,
    searchValue: searchTerm,
    orderBy: tableData.orderBy,
    addedColumns: tableData.addedColumns,
    categoryFilters: tableData.categoryFilters,
    customFilters: tableData.customFilters,
    categorySorts: tableData.categorySorts,
    tableData,
    ...setters,
  }
}
