import {
  mapDataForCSV,
  transformAppliedCategories,
} from 'components/DataTableSlim/Utils'
import { download } from 'shared/core/csv-download'
import {
  DataTableSlimColumn,
  GraphQLCategorizable,
  Categorizable,
} from '../DataTableSlim'
import { GQLDoc } from 'shared/types/GQLDoc'
import { useGetAllItems } from './useGetAllItems'
import { useGetAllItemsProper } from './useGetAllItemsProper'
import { useState } from 'react'

interface Props<Result, Item> {
  query: GQLDoc
  title: string
  searchOptions: Object
  totalItems: number
  resultMapper: (data: Result) => Item[]
  addableColumns: boolean
  useProper?: boolean
}

/**
 * This hook only support hasura queries
 */
export function useTableExport<Result, Item>(props: Props<Result, Item>) {
  const getAllItems = props.useProper
    ? useGetAllItemsProper<Result, Item>()
    : useGetAllItems<Result, Item>()
  const {
    query,
    searchOptions,
    totalItems,
    resultMapper,
    addableColumns,
  } = props
  const [isLoading, setIsLoading] = useState(false)

  const onExport = async (
    columnList: DataTableSlimColumn<Categorizable<Item>>[],
    addedColumns: string[],
  ) => {
    setIsLoading(true)
    const items = await getAllItems(
      query,
      searchOptions,
      totalItems,
      resultMapper,
    )
    const mappedItems = transformAppliedCategories<Item>(
      items as GraphQLCategorizable<Item>[],
    )
    const csvReadyJSON = mapDataForCSV(
      mappedItems,
      columnList,
      addedColumns,
      addableColumns,
    )
    download(csvReadyJSON, props.title)
    setIsLoading(false)
  }

  return {
    onExport,
    isLoading,
  }
}
