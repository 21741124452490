import React, { useState, useEffect } from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { DataTableInstance, Column, Categorizable, OrderBy } from 'components/DataTableSlim/DataTableSlim'
import { ExpiredDisplaysQuery } from 'graphql/__generated__/hasura-types'
import { format } from 'date-fns'
import styles from './index.module.scss'
import { SolButtonGroup, SolButtonGroupProps } from 'SolComponents'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

type Data = ExpiredDisplaysQuery['_displays'][0]

type Props = {
  totalItems: number
  data: Data[]
  loading: boolean
  onSort: (orderBy: OrderBy) => void
  totalItemsSelectable?: number
  totalItemsSelected?: number
  handleToggle: (isExpiring: boolean) => void
  isExpiring: boolean
}

const getSubscriptionRow = (row: Categorizable<Data>) => {
  const subscription = row.subscription
  const flexeraEnd = subscription?.flexera_maintenance_end
  const maintenanceEnd = subscription?.maintenance_end
  const dateFormat = 'yyyy/MM/dd'

  if (flexeraEnd) {
    return format(new Date(flexeraEnd), dateFormat)
  }

  if (maintenanceEnd) {
    return format(new Date(flexeraEnd), dateFormat)
  }

  return 'N/A'
}

type ManagerProps = {
  handleToggle: (isExpiring: boolean) => void
  isExpiring: boolean
}

const TemplateManager = ({ handleToggle, isExpiring }: ManagerProps) => {
  const [templateCategory, setTemplateCategory] = useState(isExpiring ? 'expiring' : 'all')

  useEffect(() => {
    if (templateCategory === 'expiring') {
      handleToggle(true)
    } else {
      handleToggle(false)
    }
  }, [templateCategory])

  const templateCategoryButtons: SolButtonGroupProps = [
    {
      primary: templateCategory === 'expiring',
      value: 'expiring',
      content: 'Expiring Soon',
      onClick: setTemplateCategory,
    },
    {
      primary: templateCategory === 'all',
      value: 'all',
      content: 'All',
      onClick: setTemplateCategory,
    },
  ]
  return (
    <div className={styles.allPodsButtons}>
      <SolButtonGroup buttons={templateCategoryButtons} />
    </div>
  )
}

const columns: Column<Data>[] = [
  {
    name: 'is_in_subscription',
    displayName: 'Status',
    centered: true,
    addable: false,
    render: r => {
      if (!r?.is_in_subscription) {
        return <SolAlertIcon className={styles.exclamationTriangle} />
      }
      return ''
    },
  },
  {
    name: 'name',
    addable: false,
    render: row => row.name,
  },
  {
    name: 'maintenance',
    displayName: 'Expiration Date',
    addable: false,
    render: row => getSubscriptionRow(row),
  },
]

const ExpiringTable = ({
  totalItems,
  data,
  loading,
  onSort,
  totalItemsSelected,
  totalItemsSelectable,
  handleToggle,
  isExpiring,
}: Props) => {
  const renderTemplateManager = () => {
    return <TemplateManager handleToggle={handleToggle} isExpiring={isExpiring} />
  }
  return (
    <div data-testid="renew-step1-table" className={styles.renew}>
      {ServerDataTable<Categorizable<Data>>({
        id: DataTableInstance.RenewStep2,
        columns,
        data,
        title: 'Additional Pods',
        totalItems,
        onSort,
        loading,
        addableColumns: true,
        addCategoryCols: false,
        selectable: true,
        columnManager: renderTemplateManager,
        defaultSelectAll: true,
        totalItemsSelected,
        totalItemsSelectable,
        filterDrawer: false,
        padded: false,
      })}
    </div>
  )
}

ExpiringTable.defaultProps = {
  loading: false,
  isExpiring: false,
}

export default ExpiringTable
