import React, { useState, useRef, useLayoutEffect } from 'react'
import { Table } from 'semantic-ui-react'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import styles from './index.module.scss'
import { SdsHostPodCount } from 'graphql/__generated__/types'

interface SDSCardProps {
  data: SdsHostPodCount[]
  loading: boolean
}

const CellText = ({ content }: { content: string }) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  const textWidth = textRef?.current?.scrollWidth || 0

  useLayoutEffect(() => {
    if (textWidth > 220) {
      setIsOverflow(true)
    } else {
      setIsOverflow(false)
    }
  }, [textWidth])

  return (
    <SolTooltip
      text={content}
      position="top left"
      trigger={
        <div className={styles.tableText} ref={textRef}>
          {content}
        </div>
      }
      isInline
      disabled={!isOverflow}
    />
  )
}

const SDSCard = ({ data, loading = true }: SDSCardProps) => {
  return (
    <div className={styles.sdsCard} data-testid="sds-card">
      <h2>Solstice Discovery Service (SDS)</h2>
      <LoadingWrapper isEmpty={!!data && data.length === 0} isLoading={loading}>
        <div>
          <div className={styles.tablePadding}>
            <div className={styles.tableHeader}>
              <div>
                <strong>SDS Instance</strong>
              </div>
              <div className={styles.count}>
                <strong>Display Count</strong>
              </div>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <Table compact basic="very" className={styles.table} collapsing>
              <Table.Body>
                {data && data?.map((instance, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className="cell">
                      <CellText content={instance.host} />
                    </Table.Cell>

                    <Table.Cell textAlign="right" className="cell">
                      {instance.podCount}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default SDSCard
