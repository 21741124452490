import { NetworkStatus, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { Segment } from 'semantic-ui-react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import {
  RecentAlertsDocument,
  RecentAlertsQueryVariables,
  RecentAlertsQuery,
  TimeInterval,
} from 'graphql/__generated__/types'

import styles from './AlertChart.module.scss'
import { pluralize } from 'shared/core/utils'
import { DateRange } from 'pages/Home/TopRow'
import { formatTime } from 'SolComponents/SolChart/chartUtils'
import { TestingProps } from 'shared/types/TestingProps'

interface AlertsChartProps extends TestingProps {
  dateRange: DateRange
  doneLoading: Function
}

const barColor = '#FBB812'

const AlertChart = ({ dateRange, dataTestId, doneLoading }: AlertsChartProps) => {
  const { fromDate = '', toDate = '', interval = TimeInterval.Day } = dateRange
  const { data, networkStatus: graphStatus } = useQuery<RecentAlertsQuery, RecentAlertsQueryVariables>(
    RecentAlertsDocument,
    {
      variables: {
        options: {
          fromDate,
          toDate,
          timeInterval: interval,
        },
      },
      skip: fromDate.length === 0 || interval === undefined,
    },
  )

  const { alerts = [], totalDisplays, totalAlerts } = data?.recentAlerts || {}

  const alertsFormatted = alerts.map(alert => ({
    ...alert,
    date: formatTime(interval, new Date(alert?.date || 0), new Date(toDate)),
  }))

  useEffect(() => {
    if (graphStatus === NetworkStatus.ready) {
      doneLoading()
    }
  }, [graphStatus])

  return (
    <div data-testid="alerts-chart" className={styles.alertsChart}>
      <Segment raised className={styles.segment} data-testid={dataTestId}>
        <div className={styles.body}>
          <div className={styles.header}>Recent Alerts</div>
          <div className={styles.subheading}>
            {totalAlerts} from {pluralize(totalDisplays || 0, 'display')}
          </div>
          <div className={styles.chart}>
            <ResponsiveContainer width="100%">
              <BarChart data={alertsFormatted}>
                <Bar yAxisId="left" dataKey="totalAlerts" fill={barColor} stroke={barColor} barSize={19} />
                <XAxis interval="preserveStartEnd" dataKey="date" />
                <YAxis allowDecimals={false} yAxisId="left" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div>
          <div className={styles.link}>
            <Link to="/alerts?tab=history">
              ALERTS&nbsp;
              <Icon name="angle right" />
            </Link>
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default AlertChart
