import React from 'react'
import WifiIcon from 'mdi-react/WifiIcon'

type Props = {
  className?: string
  size?: 'default' | 'small' | 'large'
  onClick?: () => void
}

const SolWifiIcon = (props: Props) => (
  <WifiIcon
    className={props.className}
    fontSize={props.size ?? 'default'}
    onClick={props.onClick}
  />
)

export default SolWifiIcon
