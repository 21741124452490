import React, { useState } from 'react'
import {
  CurrentFloorInformationQuery,
  LocationFloorListDocument,
  OrgsDisplaysLocationsBoolExp,
  useLocationFloorListQuery,
  useUpdateFloorMutation,
} from 'graphql/__generated__/hasura-types'
import { Dropdown, Icon } from 'semantic-ui-react'
import classNames from 'classnames'
import styles from './FloorsDropdown.module.scss'
import { pluralize } from 'shared/core/utils'
import { ValidationInput, RegexPattern } from 'components/FormValidation/ValidationInput'
import { FormValidation } from '../../../shared/context/FormValidation'
import { BulkProps } from 'SolComponents/SolDropdown/SolDropdown'
import { ApolloQueryResult } from '@apollo/client'

interface Props {
  addressId?: number
  displayId: string
  selectedDisplays?: OrgsDisplaysLocationsBoolExp
  variant?: 'single'
  onFocus?: () => void
  bulk?: BulkProps
  disabled?: boolean
  onSelect?: (displayId: string, newFloor: number, addressId?: number, refetch?: () => void) => void
  currentFloor?: number | null
  refetchCurrentFloor?: () => Promise<ApolloQueryResult<CurrentFloorInformationQuery>>
  className?: string
}

export const FloorsDropdown = (props: Props) => {
  const [floor, setFloor] = useState<number | undefined | string>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(true)
  const [updateFloor, { loading: updateFloorLoading }] = useUpdateFloorMutation()

  const updateFloorList = (displayId: string, newFloor: number) => {
    if (!updateFloorLoading) {
      const where = props.selectedDisplays ?? {
        display: { _eq: displayId },
      }

      updateFloor({
        variables: {
          where,
          newFloor,
        },
        refetchQueries: [
          {
            query: LocationFloorListDocument,
            variables: {
              addressId: props.addressId,
            },
          },
        ],
        update: () => props.refetchCurrentFloor?.(),
        awaitRefetchQueries: true,
      })
    }
  }

  const { data, loading } = useLocationFloorListQuery({
    variables: {
      where: { location: { address_id: { _eq: props.addressId } } },
    },
  })

  const getFloorList = () => {
    if (!props.addressId) {
      return
    }

    if (!loading && data?._displays) {
      const uniqueFloors = [...data?._displays]
        .slice()
        .filter(
          (val, index, arr) =>
            arr.findIndex(
              t =>
                t.location?.address_id === val.location?.address_id
                && t.location?.floor_count?.floor === val.location?.floor_count?.floor,
            ) === index,
        )

      return uniqueFloors.map(i => {
        return (
          <Dropdown.Item
            className={styles.floorMenuItem}
            onClick={({}, d) => {
              if (typeof d.value === 'boolean') {
                return
              }
              const value = typeof d.value === 'string' ? parseInt(d.value) : d.value ?? 0
              if (props.onSelect) {
                props.onSelect(props.displayId, value, props.addressId, props.refetchCurrentFloor)
              }
              if (!props.onSelect) {
                updateFloorList(props.displayId, value)
              }
            }}
            key={Math.random()}
            value={i.location?.floor_count?.floor ?? ''}
            text={`${i.location?.floor_count?.floor} (${pluralize(i.location?.floor_count?.count_per_floor, 'Pod')})`}
          />
        )
      })
    }
    return
  }

  const handleSaveFloor = (newFloor: number) => {
    if (!loading) {
      setShowDropdown(true)
    }
    setFloor('')
    updateFloorList(props.displayId, newFloor)
  }

  return (
    <div className={classNames(styles.wrapper, props.className)} onFocus={props.onFocus}>
      {props.variant === 'single' && <label className={styles.floorLabel}>Floor</label>}
      {showDropdown ? (
        <Dropdown
          text={props?.currentFloor?.toString()}
          upward={false}
          fluid
          disabled={props.disabled}
          className={classNames({
            [styles.floorsTableDropdown]: props.variant !== 'single',
            [styles.floorsTableDropdownSingle]: props.variant === 'single',
            [styles.bulkDropDown]: props.bulk && props.bulk.itemsSelected > 1,
          })}
        >
          <Dropdown.Menu
            className={classNames({
              [styles.menuWrapper]: props.variant !== 'single',
              [styles.menuWrapperSingle]: props.variant === 'single',
            })}
            scrollable="false"
          >
            <Dropdown.Menu scrolling className={styles.mainMenu}>
              {props.bulk && props.bulk.itemsSelected > 1 && (
                <Dropdown.Header>
                  <Dropdown.Item disabled>
                    <div className={styles.podsSelected}>
                      {props.bulk.itemsSelected} {props.bulk.itemsLabel ?? 'items'} selected
                    </div>
                    <Icon className={`${styles.bulkIcon} times circle`} onClick={props.bulk.onCancel} />
                  </Dropdown.Item>
                </Dropdown.Header>
              )}
              {getFloorList()}
            </Dropdown.Menu>
            <Dropdown.Item className={styles.addFloorButton} onClick={() => setShowDropdown(false)}>
              Add New Floor
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className={styles.floorsWrapper}>
          <FormValidation>
            <ValidationInput
              name="floors"
              required={false}
              size="small"
              value={floor?.toString()}
              onChange={(e, d) => {
                setFloor(d.value)
              }}
              rules={{ pattern: RegexPattern.IntegersOnlyWithNegatives }}
              errorText="Must be number"
              onInlineSave={() => {
                const value = typeof floor === 'string' ? parseInt(floor) : floor ?? 0
                if (props.onSelect) {
                  props.onSelect(props.displayId, value, props.addressId, props.refetchCurrentFloor)
                }
                if (!props.onSelect) {
                  handleSaveFloor(value)
                }
              }}
              onBlur={() => {
                setShowDropdown(true)
                setFloor('')
              }}
              autoFocus
              maxLength={5}
            />
          </FormValidation>
        </div>
      )}
    </div>
  )
}
