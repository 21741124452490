import {
  Alignment,
  BrowserLookIn,
  QuickConnectAction,
  SettingsGroupType,
  Template,
  InputMode,
} from '../../../graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultFeaturesTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Features',
    type: SettingsGroupType.Features,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enableScreenKey: true,
      enableModeratorMode: false,
      enableMiracastWifiDirect: true,
      enableMiracastInfrastructure: true,
      enableAndroidMirroring: true,
      enableAirplay: true,
      enableAirplayProxy: false,
      enableAirplayBluetooth: false,
      enableWebRTC: true,
      quickConnectAction: QuickConnectAction.LaunchAndConnect,
      alignment: Alignment.DetermineAtRuntime,
      hdmiInputMode: InputMode.Standard,
      speakScreenKey: false,
      browserLookIn: BrowserLookIn.Disabled,
      enableOccupancyData: false,
      usbAudioToHdmiEnabled: false,
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
