import React from 'react'
import AlertIcon from 'mdi-react/AlertIcon'

type Props = {
  className?: string
  size?: 'default' | 'small' | 'large'
  onClick?: () => void
}

const sizeMaper = (size: string) => {
  if (size === 'small') {
    return 20
  }
  if (size === 'large') {
    return 35
  }
  return 25
}

const SolAlertIcon = (props: Props) => (
  <AlertIcon
    className={props.className}
    size={sizeMaper(props.size ?? 'default')}
    onClick={props.onClick}
  />
)

export default SolAlertIcon
