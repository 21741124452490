import omit from 'lodash/omit'
import { LatLng } from 'leaflet'
import { Popup } from 'react-leaflet'
import React, { useEffect, useState } from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

import { DisplayLocation } from '../Location'
import styles from './Map.module.scss'
import { pluralize } from 'shared/core/utils'
import SolDropdown from 'SolComponents/SolDropdown/SolDropdown'
import { useLocationFloorListQuery, ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'

type Props = {
  location?: DisplayLocation
  buildingFloorMap: { [key: number]: string }
  setBuildingFloorMap: (x: { [key: number]: string }) => void
  position: LatLng | null
  openItemsUpward?: (e: MouseEvent) => boolean
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
}

const defaultOptions: DropdownItemProps[] = [{ value: 'all', text: 'All Floors' }]

export function BuildingInfoPopUp({
  location,
  position,
  openItemsUpward,
  buildingFloorMap,
  setBuildingFloorMap,
  searchParamsWhere,
}: Props) {
  const [options, setOptions] = useState<DropdownItemProps[]>(defaultOptions)
  const { data, loading } = useLocationFloorListQuery({
    variables: {
      where: {
        _and: [
          { location: { address_id: { _eq: location?.addressID } } },
          ...(searchParamsWhere?._and ?? []).filter(
            el =>
              el && el?.location?._not?._and?.find(e => e?.address_id?._eq)?.address_id?._eq !== location?.addressID,
          ),
        ] as ViewDisplaysBoolExp[],
      },
    },
  })

  useEffect(() => {
    if (data && location?.addressID) {
      const optionsMap = data?._displays
        .filter(el => el.location?.floor_count?.floor != null)
        .reduce(
          (obj, el) => ({
            ...obj,
            [el?.location?.floor_count?.floor as number]: `Floor ${el.location?.floor_count?.floor} (${pluralize(
              el.location?.floor_count?.count_per_floor,
              'Pod',
            )})`,
          }),
          {},
        )

      setOptions([...defaultOptions, ...Object.entries(optionsMap).map(([value, text]) => ({ value, text }))])
    }
  }, [data, location])

  return (
    <>
      {location && !loading && position && (
        <Popup position={position} closeOnClick={false} closeButton={false} closeOnEscapeKey autoClose autoPan>
          <div className={styles.buildingPopUp}>
            <div className={styles.name}>{location?.nickname ?? ''}</div>
            <div>{location?.building ?? ''}</div>
            <div>{pluralize(location?.numOfPodsAtAddress ?? 0, 'Pod')} Total</div>
            {options.length > 1 && (
              <SolDropdown
                size="medium"
                openItemsUpward={openItemsUpward}
                className={styles.dropdownFloorCount}
                defaultValue={buildingFloorMap[location?.addressID ?? 0] ?? 'all'}
                options={options}
                onChange={val => {
                  if (location?.addressID) {
                    setBuildingFloorMap(
                      val !== 'all'
                        ? { ...buildingFloorMap, [location?.addressID]: val }
                        : omit(buildingFloorMap, [location?.addressID]),
                    )
                  }
                }}
              />
            )}
          </div>
        </Popup>
      )}
    </>
  )
}
export default BuildingInfoPopUp
