import { combineReducers } from 'redux'
import account from './account'
import alert from './alert'
import maintenance from './maintenance'
import core from './core'
import table from './table'

const rootReducer = combineReducers({
  alert,
  mxRenewal: maintenance,
  user: account,
  core,
  table,
})

export default rootReducer
