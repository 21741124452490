import React from 'react'
import { Color } from './types/Color'
import styles from './pod.module.scss'

/**
 * A Pod as a visual and draggable svg element on the pod grid.
 */

interface Props {
    name: string
    color: Color
    sourceColors?: Color[]
    active?: boolean
    isPrimary?: boolean
    isNewSource?: boolean
    disabled?: boolean
    audio?: boolean
    id: number
    x?: number
    y?: number
    width?: number
    height?: number
    handlePointerDown: (id1: number, e: React.PointerEvent) => void
    handlePointerUp: (id1: number, e: React.PointerEvent) => void
    handlePointerMove: (id1: number, e: React.PointerEvent) => void
}

export const Pod = ({
  name = '',
  x = 100,
  y = 100,
  width = 100,
  height = 100,
  color = Color.PinkRed,
  sourceColors,
  isPrimary = false,
  isNewSource = false,
  disabled = false,
  audio = false,
  id,
  handlePointerDown,
  handlePointerUp,
  handlePointerMove,
}: Props) => {

  // pre calc dimensions for drawing the little source displays inside the sink
  const colWidth = (sourceColors && sourceColors?.length > 1 ) ? 10 : 20
  const rowHeight = sourceColors ? (14 / Math.floor((sourceColors.length + 1) / 2)) : 14


  // DarkBluePod = '#21232F',
  // LightBluePod = '#55A3D8',

  return (
    <g transform={`translate(${x + width * .1}, ${y + height * .1})`}>
      <rect
        width={width * .8}
        height={height * .8}
        rx={Math.ceil(width * .01 * 15)}
        fill="#21232F"
        stroke={disabled ? '#21232F' : color}
        strokeWidth={3 * width * .01}
        strokeDasharray={isNewSource ? '5 3' : undefined}
        onPointerDown={evt => handlePointerDown(id, evt)}
        onPointerMove={evt => handlePointerMove(id, evt)}
        onPointerUp={evt => handlePointerUp(id, evt)}
      />


      {/* Speaker */}
      {audio && !isNewSource
          && <g transform={`translate(${6 * width * .01}, ${6 * height * .01}) scale(${width * .01})`}>
            <path
              /* eslint-disable-next-line max-len */
              d="M8.03333 11.6157C7.85556 11.6713 7.69444 11.6435 7.55 11.5324C7.40556 11.4213 7.33333 11.2713 7.33333 11.0824C7.33333 10.9935 7.35833 10.9129 7.40833 10.8407C7.45833 10.7685 7.52778 10.7213 7.61667 10.699C8.62778 10.3435 9.44445 9.73794 10.0667 8.88238C10.6889 8.02683 11 7.06016 11 5.98238C11 4.9046 10.6889 3.93516 10.0667 3.07405C9.44445 2.21294 8.62778 1.61016 7.61667 1.26571C7.52778 1.24349 7.45833 1.19349 7.40833 1.11571C7.35833 1.03794 7.33333 0.954604 7.33333 0.865715C7.33333 0.676826 7.40833 0.529604 7.55833 0.424048C7.70833 0.318493 7.86667 0.293493 8.03333 0.349048C9.22222 0.77127 10.1806 1.49627 10.9083 2.52405C11.6361 3.55183 12 4.7046 12 5.98238C12 7.26016 11.6361 8.41294 10.9083 9.44072C10.1806 10.4685 9.22222 11.1935 8.03333 11.6157ZM0.5 7.99905C0.355556 7.99905 0.236111 7.95183 0.141667 7.85738C0.0472223 7.76294 0 7.64349 0 7.49905V4.49905C0 4.3546 0.0472223 4.23516 0.141667 4.14071C0.236111 4.04627 0.355556 3.99905 0.5 3.99905H2.66667L5.15 1.51571C5.30556 1.36016 5.48611 1.32405 5.69167 1.40738C5.89722 1.49071 6 1.64349 6 1.86571V10.1324C6 10.3546 5.89722 10.5074 5.69167 10.5907C5.48611 10.674 5.30556 10.6379 5.15 10.4824L2.66667 7.99905H0.5ZM7 8.79905V3.18238C7.6 3.37127 8.08333 3.72683 8.45 4.24905C8.81667 4.77127 9 5.3546 9 5.99905C9 6.6546 8.81667 7.23794 8.45 7.74905C8.08333 8.26016 7.6 8.61016 7 8.79905Z"
              fill="white"
            />
          </g>
      }

      {/* Star for Primary Display */}
      {isPrimary && !isNewSource
              && <g transform={`translate(${width * .62}, ${6 * height * .01}) scale(${width * .01})`}>
                <path /* eslint-disable-next-line max-len */
                  d="M9.71053 12L6 9.64615L2.28947 12L3.27193 7.56L0 4.57846L4.31579 4.19077L6 0L7.68421 4.18154L12 4.56923L8.72807 7.55077L9.71053 12Z"
                  fill="white"
                />
              </g>
      }

      {/* Pod Name*/}
      {!isNewSource
        && <g transform={`translate(${width * .4}, ${height * .3})`}>
          <text
            textAnchor="middle"
            fill={disabled ? 'gray' : 'white'}
            fontFamily="Lato Bold"
            fontSize={Math.ceil(width * .08)}
            className={styles.podText}
            onPointerUp={evt => handlePointerUp(id, evt)}
          >{name}</text>
        </g>
      }

      {/* When a user is dragging a new route, the source's pod rectangle gets instructions */}
      {isNewSource
        && <g transform={`translate(${width * .4}, ${height * .25})`}>
          <text
            textAnchor="middle"
            fill="white"
            fontFamily="Lato Bold"
            fontSize={Math.ceil(width * .1)}
            className={styles.podText}
          >
            <tspan x="0mm" y="0mm" >Drag to a</tspan>
            <tspan x="0" y="5mm" >pod you</tspan>
            <tspan x="0" y="10mm" >want to</tspan>
            <tspan x="0" y="15mm" >route to</tspan>
          </text>
        </g>
      }

      {/* Four chevrons surround the instuction text 'Drag to a...', pointing cardinal directions */}
      {isNewSource && <g>
        {/* eslint-disable-next-line max-len */}
        <g transform={`scale(.6) translate(${width * .6}, ${height * .1 }) rotate(  0, 9, 9)`}><path d="M18.13 12.5098L19.9 10.7298L9.99999 0.839843L0.0999942 10.7398L1.86999 12.5098L9.99999 4.37984L18.13 12.5098Z" fill="white"/></g>
        {/* eslint-disable-next-line max-len */}
        <g transform={`scale(.6) translate(${width * .6}, ${height * 1.1}) rotate(180, 9, 9.5)`}><path d="M18.13 12.5098L19.9 10.7298L9.99999 0.839843L0.0999942 10.7398L1.86999 12.5098L9.99999 4.37984L18.13 12.5098Z" fill="white"/></g>
        {/* eslint-disable-next-line max-len */}
        <g transform={`scale(.6) translate(${width * .1}, ${height * .6 }) rotate(270, 9, 9)`}><path d="M18.13 12.5098L19.9 10.7298L9.99999 0.839843L0.0999942 10.7398L1.86999 12.5098L9.99999 4.37984L18.13 12.5098Z" fill="white"/></g>
        {/* eslint-disable-next-line max-len */}
        <g transform={`scale(.6) translate(${width * 1.1}, ${height * .6 }) rotate( 90, 9, 9)`}><path d="M18.13 12.5098L19.9 10.7298L9.99999 0.839843L0.0999942 10.7398L1.86999 12.5098L9.99999 4.37984L18.13 12.5098Z" fill="white"/></g>
      </g>}

      {/* Pod "Display" Icon */}
      {!isNewSource
          && <g transform={`translate(${width * .30}, ${height * .56}) scale(${width * .01})`}>
            {/* draw a little rect inside this sink display for every source, using their colors */}
            {sourceColors?.map((c, i) => {
              // eslint-disable-next-line react/jsx-key
              return (<rect
                x={2 + (i % 2) * colWidth}
                y={2 + Math.floor(i / 2) * rowHeight}
                width={colWidth}
                height={rowHeight}
                fill={c}
                onPointerUp={evt => handlePointerUp(id, evt)}
              />)
            })}

            <path /* eslint-disable-next-line max-len */
              d="M16.3399 20V17.76H21.8039C22.3965 17.76 22.902 17.5467 23.3203 17.12C23.7386 16.6933 23.9477 16.1778 23.9477 15.5733L24 2.24C24 1.63556 23.7821 1.11111 23.3464 0.666667C22.9107 0.222222 22.3965 0 21.8039 0H2.19608C1.60349 0 1.08932 0.222222 0.653595 0.666667C0.217865 1.11111 0 1.63556 0 2.24V15.5733C0 16.1778 0.217865 16.6933 0.653595 17.12C1.08932 17.5467 1.60349 17.76 2.19608 17.76H7.66013V20H16.3399ZM21.8041 15.5755H2.19629V2.24219H21.8041V15.5755Z"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
              onPointerUp={evt => handlePointerUp(id, evt)}
            />
          </g>
      }

    </g>
  )
}
