import React from 'react'
import { Checkbox, Icon } from 'semantic-ui-react'
import styles from './SolCheckbox.module.scss'
import classNames from 'classnames'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'

export interface Props {
  disabled?: boolean
  label: string
  notification?: JSX.Element | JSX.Element[]
  checked: boolean
  onClick: (val: boolean) => void
  bold?: boolean
  note?: string
  toggle?: boolean
  noPadding?: boolean
  className?: string | Record<string, any>
  mismatched?: boolean
}

export default function SolCheckbox(props: Props) {
  const label = (
    <label>
      {props.label}
      {!!props.notification && (
        <span className={styles.notification}>
          {props.notification}
        </span>
      )}
      {!!props.note && (
        <div className={styles.note}>
          {props.note}
        </div>
      )}
      {props.mismatched
      && <span className={styles.syncStateIcon}>
        <SolTooltip
          isInline
          trigger={<Icon  name="exclamation circle" color="red" />}
          text={`This setting does not match between Solstice Cloud and the 
          Pod. Please review this setting and try again.`}
          position="top left"
        />
      </span>
      }
    </label>
  )

  return (
    <div
      className={classNames(
        { [styles.noPadding]: props.noPadding },
        { [styles.checkbox]: !props.noPadding },
        props.className,
      )}
    >
      <Checkbox
        disabled={props.disabled}
        toggle={props.toggle}
        onClick={(_, value) => !props.disabled && props.onClick(!!value.checked)}
        className={classNames({ [styles.bold]: props.bold })}
        checked={props.checked}
        label={label}
      />
    </div>
  )
}
