import React from 'react'
import { Table, Dropdown, DropdownProps } from 'semantic-ui-react'
import classNames from 'classnames'
import { useCategoriesQuery } from 'graphql/__generated__/hasura-types'
import { DisplayUnreachableDelay, FilterType, UsbPlugEventType } from './types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styles from './Alerts.module.scss'
import { useAlertConfig } from './hooks/useAlertConfig'
import { useUpdateAlertConfig } from './hooks/useUpdateAlertConfig'
import { useFiltersDisplayCount } from './useFiltersDisplayCount'
import { AlertRow } from './AlertRow'

const DropdownColumn = ({ containerClassName, ...props }: { containerClassName?: string } & DropdownProps) => (
  <div className={classNames(containerClassName, styles.dropdownColumn)}>
    <Dropdown selection fluid {...props} />
  </div>
)

export interface Props {
  displayCount: number
}

export function AlertSettings({ displayCount }: Props) {
  const {
    displayUnreachableEnabled,
    displayRebootedEnabled,
    displayOutOfTemplateEnabled,
    displayUnreachableDelay,
    usbPlugEventType,
    usbPlugEventEnabled,
    displayUnreachableFilters,
    displayRebootedFilters,
    displayOutOfTemplateFilters,
    usbPlugEventFilters,
  } = useAlertConfig()
  const { setDisplayUnreachableDelay, setUsbPlugEventType, setConfigEnabled } = useUpdateAlertConfig()

  const {
    usbAlerts = false,
    disableTemplateAlerts : templateAlerts,
  } = useFlags()

  const { data: newCategories } = useCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      hidden: null,
    },
  })
  const categoriesHasura = newCategories?._categories ?? []

  const handleUnreachableDelayDropdown = ({}, data: { value: DisplayUnreachableDelay }) => {
    setDisplayUnreachableDelay(data.value)
  }

  const delays: DisplayUnreachableDelay[] = [5, 15, 30]
  const unreachableDelayDropdownOptions = delays.map(v => ({
    text: +v,
    value: +v,
  }))

  const handleUsbDeviceDropdown = ({}, data: { value: UsbPlugEventType }) => {
    setUsbPlugEventType(data.value)
  }

  const usbDeviceDropdownOptions = [
    { value: 'added', text: 'added' },
    { value: 'removed', text: 'removed' },
    { value: 'added-or-removed', text: 'added OR removed' },
  ]

  const filteredDisplaysCountUnreachable = useFiltersDisplayCount(displayUnreachableFilters)

  const filteredDisplaysCountRebooted = useFiltersDisplayCount(displayRebootedFilters)

  const filteredDisplaysCountOutOfTemplate = useFiltersDisplayCount(displayOutOfTemplateFilters)

  const filteredDisplaysCountUsbPlugEvent = useFiltersDisplayCount(usbPlugEventFilters)

  return (
    <div>
      <Table collapsing={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing />
            <Table.HeaderCell>Alert description</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <AlertRow
            enabled={displayUnreachableEnabled}
            filteredDisplaysCount={filteredDisplaysCountUnreachable}
            displayCount={displayCount}
            categories={categoriesHasura}
            columns={[
              'Email me when a Pod is unreachable for',
              <DropdownColumn
                key="unreachable-pod"
                value={displayUnreachableDelay}
                options={unreachableDelayDropdownOptions}
                onChange={handleUnreachableDelayDropdown}
              />,
              'minutes',
            ]}
            setEnabled={enabled => {
              // make sure there's a value set for the delay
              setDisplayUnreachableDelay(displayUnreachableDelay)
              setConfigEnabled(FilterType.DisplayUnreachable, enabled)
            }}
            filters={displayUnreachableFilters}
            filterType={FilterType.DisplayUnreachable}
          />
          <AlertRow
            enabled={displayRebootedEnabled}
            filteredDisplaysCount={filteredDisplaysCountRebooted}
            displayCount={displayCount}
            categories={categoriesHasura}
            columns={['Email me when a Pod is rebooted']}
            setEnabled={enabled => {
              setConfigEnabled(FilterType.DisplayRebooted, enabled)
            }}
            filters={displayRebootedFilters}
            filterType={FilterType.DisplayRebooted}
          />
          {templateAlerts && (
            <AlertRow
              enabled={displayOutOfTemplateEnabled}
              filteredDisplaysCount={filteredDisplaysCountOutOfTemplate}
              displayCount={displayCount}
              categories={categoriesHasura}
              columns={['Email me when a Pod is unassigned from a template']}
              setEnabled={enabled => {
                setConfigEnabled(FilterType.DisplayOutOfTemplate, enabled)
              }}
              filters={displayOutOfTemplateFilters}
              filterType={FilterType.DisplayOutOfTemplate}
            />
          )}
          {usbAlerts && (
            <AlertRow
              enabled={usbPlugEventEnabled}
              filteredDisplaysCount={filteredDisplaysCountUsbPlugEvent}
              displayCount={displayCount}
              categories={categoriesHasura}
              columns={[
                'Email me when a USB device is',
                <DropdownColumn
                  key="usb-device-unavailable"
                  value={usbPlugEventType}
                  options={usbDeviceDropdownOptions}
                  onChange={handleUsbDeviceDropdown}
                  containerClassName={styles.usbDeviceDropdown}
                />,
                `${usbPlugEventType === 'added' ? 'to' : 'from'} a Pod`,
              ]}
              setEnabled={enabled => {
                // make sure there's a value set for the event type
                setUsbPlugEventType(usbPlugEventType)
                setConfigEnabled(FilterType.UsbDevice, enabled)
              }}
              filters={usbPlugEventFilters}
              filterType={FilterType.UsbDevice}
            />
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

export default AlertSettings
