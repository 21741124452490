import React from 'react'
import {
  Template,
  FeaturesConfiguration,
  Alignment,
} from 'graphql/__generated__/types'
import { SolRadio } from 'SolComponents/index'
import styles from '../Features.module.scss'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: FeaturesConfiguration) => void
}

function AlignmentOptions({
  updateConfiguration,
  template,
}: Props) {

  const configuration = template.revision.configuration as FeaturesConfiguration

  return (
    <div className={styles.radioButtons}>
      <div>
        <SolRadio
          label="Grid"
          checked={configuration.alignment === Alignment.Grid}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              alignment: Alignment.Grid,
            })
          }}
        />
      </div>
      <div>
        <SolRadio
          label="Freeform"
          checked={configuration.alignment === Alignment.Freeform}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              alignment: Alignment.Freeform,
            })
          }}
        />
      </div>
      <div>
        <SolRadio
          label="Determine at runtime"
          checked={configuration.alignment === Alignment.DetermineAtRuntime}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              alignment: Alignment.DetermineAtRuntime,
            })
          }}
        />
      </div>
    </div>
  )
}

export default AlignmentOptions
