import React from 'react'
import classNames from 'classnames'
import styles from './SolChartLegend.module.scss'

type Label = {
  id: string
  text: string
  color: string
}

type Props = {
  labels: Label[]
  direction?: 'vertical' | 'horizontal'
}

const SolChartLegend = ({ labels, direction }: Props) => (
  <ul
    className={classNames(styles.legend, {
      [styles.horizontal]: direction === 'horizontal',
    })}
  >
    {labels.map(label => (
      <li key={label.id} className={styles.label}>
        <span
          className={styles.bullet}
          style={{ backgroundColor: label.color }}
        />{' '}
        <span>{label.text}</span>
      </li>
    ))}
  </ul>
)

export default SolChartLegend
