import {
  UniqueSettingsConfiguration,
  UniqueEthernetVlan,
  Template,
  SettingsGroupType,
} from 'graphql/__generated__/types'

export const defaultUniqueVlan: UniqueEthernetVlan = {
  ipAddress: '',
  hostname: '',
}

export const defaultUniqueSettingsConfiguration: UniqueSettingsConfiguration = {
  WELCOME_SCREEN: {
    displayName: '',
  },
  ETHERNET: {
    ipAddress: '',
    vlans: [defaultUniqueVlan, defaultUniqueVlan, defaultUniqueVlan],
  },
}

import { DefaultTemplate } from '../TemplateTypes'

export const defaultUniqueSettingsTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Unique Settings',
    type: SettingsGroupType.UniqueSettings,
    hidden: true,
  },
  assignedDisplays: {
    totalRecords: 0,
  },
  revision: {
    id: '',
    configuration: defaultUniqueSettingsConfiguration,
  },
}
