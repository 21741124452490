import React from 'react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import {
  AvgHoursSpentPerUserQuery,
  AvgRoomsVisitedQuery,
  SessionsPerUserQuery,
  UniqueUsersCountQuery,
  ViewDateAggregationResult,
} from 'graphql/__generated__/hasura-types'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'

import { CategoryFilter, TimeInterval } from 'graphql/__generated__/types'

import styles from './index.module.scss'
import { TestingProps } from 'shared/types/TestingProps'
import { SolCard } from 'SolComponents'
import { SingleResultMetrics } from '../DailyActiveUsers'
import { format } from 'date-fns'

interface DailyActiveChartProps extends TestingProps {
  data: ViewDateAggregationResult[]
  isLoading?: boolean
  interval: TimeInterval
  toDate?: Date
  dataTestId: string
  showSingleResult?: Boolean
  params?: {
    from_date: string
    to_date: string
    categories: CategoryFilter[] | undefined
    di: string
  }
  sessionsPerUser?: SessionsPerUserQuery
  loadingSessionsPerUser?: boolean
  hoursPerUser?: AvgHoursSpentPerUserQuery
  loadingHoursPerUser?: boolean
  roomsVisited?: AvgRoomsVisitedQuery
  loadingRoomsVisited?: boolean
  usersCount?: UniqueUsersCountQuery
  loadingUsersCount?: boolean
}

const teal = '#3BB6C5'

const formatXAxis: any = (tickItem: any) => {
  return tickItem && tickItem !== 'auto' ? format( new Date(tickItem), 'MMMM dd, yyyy' ) : ''
}

const DailyActiveChart = ({
  data,
  dataTestId,
  showSingleResult,
  sessionsPerUser,
  loadingSessionsPerUser,
  hoursPerUser,
  loadingHoursPerUser,
  roomsVisited,
  loadingRoomsVisited,
  usersCount,
  loadingUsersCount,
}: DailyActiveChartProps) => {
  return (
    <div className={styles.dailyActiveChart} data-testid={dataTestId}>
      <SolCard
        className={styles.card}
        header={
          <div className={styles.header}>
            Daily Active Users
            <SolTooltip
              isInline
              text={
                [
                  'Daily active users and other charts on this page',
                  'are from users engaging with the Solstice app',
                  'on their laptop or mobile device, data from airplay, miracast,',
                  'or browser-based sharing is not included.',
                ].join(' ')
              }
              position="top left"
              trigger={<SolQuestionCircle className={styles.circleIcon} />}
            />
          </div>
        }
      >
        <div className={styles.cardContent}>
          <div className={styles.chartContainer}>
            <div className={styles.chart}>
              <ResponsiveContainer width="100%" height={309}>
                <BarChart data={data} data-testid="bar-chart">
                  <Tooltip labelFormatter={formatXAxis} separator=": "/>
                  <Bar yAxisId="left" dataKey="total" fill={teal} stroke={teal} />
                  <XAxis interval="preserveStartEnd" dataKey="date" tickFormatter={formatXAxis} />
                  <YAxis allowDecimals={false} yAxisId="left" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          {showSingleResult && (
            <div>
              <div className={styles.singleResult}>
                <SingleResultMetrics
                  sessionsPerUser={sessionsPerUser}
                  loadingSessionsPerUser={loadingSessionsPerUser}
                  hoursPerUser={hoursPerUser}
                  loadingHoursPerUser={loadingHoursPerUser}
                  roomsVisited={roomsVisited}
                  loadingRoomsVisited={loadingRoomsVisited}
                  usersCount={usersCount}
                  loadingUsersCount={loadingUsersCount}
                />
              </div>
            </div>
          )}
        </div>
      </SolCard>
    </div>
  )
}

export default DailyActiveChart
