import React from 'react'
import SolNumInput, { Props as SolNumInputProps } from '../../SolComponents/SolNumInput/SolNumInput'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { useValidation } from './useValidation'

type Props = SolNumInputProps & {
  name: string
  rules: RegisterOptions
}

export const ValidationNumInput = React.memo(function(props: Props) {
  const { setValue, error } = useValidation(props.name, `${props.value}`, props.rules)

  return (
    <SolNumInput
      {...props}
      onChange={async value => {
        setValue(props.name, value, {
          shouldValidate: true,
          shouldDirty: true,
        })
        props.onChange(value)
      }}
      value={props.value}
      error={error}
    />
  )
})
