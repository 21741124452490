import React, { ComponentType } from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { format } from 'date-fns'
import { SolBarChart, SolCard } from 'SolComponents'
import styles from './UsbDeviceHistoryCard.module.scss'
import { Bar, XAxisProps, YAxisProps } from 'recharts'
import SolChartLegend from 'SolComponents/SolChartLegend/SolChartLegend'
import { cool } from 'assets/palettes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PowerPlugIcon from 'mdi-react/PowerPlugIcon'
import PowerPlugOffIcon from 'mdi-react/PowerPlugOffIcon'
import { pluralize } from 'shared/core/utils'
import { Link } from 'react-router-dom'
import { MdiReactIconProps } from 'mdi-react'
import { UsbDeviceHistory } from 'graphql/__generated__/types'

type Props = {
  usbDeviceHistory: UsbDeviceHistory[] | undefined
  plugCountSum: number
  unplugCountSum: number
}

const Count = (props: { count: number; label: string; icon: ComponentType<MdiReactIconProps>; color: string }) => {
  const Icon = props.icon
  return (
    <div className={styles.metric}>
      <Icon color={props.color} size={24} />
      <div className={styles.label}>
        <span className={styles.count}>{props.count}</span> {props.label}
      </div>
    </div>
  )
}

const [onlineColor, offlineColor] = cool(2)

const labels = [
  {
    id: 'offline',
    text: 'Plugged in',
    color: onlineColor,
  },
  {
    id: 'online',
    text: 'Unplugged',
    color: offlineColor,
  },
]

const xAxisProps: XAxisProps = {
  type: 'category',
  dataKey: 'date',
  minTickGap: 50,
}
const yAxisProps: YAxisProps = { type: 'number' }
const chartProps = {
  responsiveHeight: 280,
  xAxis: xAxisProps,
  yAxis: yAxisProps,
  margin: { left: -28, top: 8 },
  barSize: 8,
  barGap: 1,
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const UsbDeviceHistoryCard = ({
  usbDeviceHistory,
  plugCountSum,
  unplugCountSum,
}: Props) => {
  const { usbAlerts = false } = useFlags()
  const chartData = usbDeviceHistory ? usbDeviceHistory.map(day => ({
    'date': format(zonedTimeToUtc( Number.parseInt( day.date ), localTimezone), 'M/d'),
    'Unplugged': day?.unplugCount ?? 0,
    'Plugged in': day?.plugCount ?? 0,
  })) : []

  const totalCountText = `${pluralize(plugCountSum + unplugCountSum, 'USB plug event')} (last 30 days)`
  const onlineCountText = pluralize(plugCountSum, 'plug-in event', false)
  const offlineCountText = pluralize(unplugCountSum, 'unplug event', false)
  const link = (
    <Link to="/alerts" className={styles.link}>
      Alerts Page
    </Link>
  )
  return (
    <SolCard className={styles.card}>
      <div data-testid="usb-device-history-card">
        <div className={styles.header}>
          <h2 className={styles.title}>USB Plug Event History</h2>
          <div className={styles.legend}>
            <div className={styles.title}>Last 30 Days</div>
            <SolChartLegend labels={labels} direction="horizontal" />
          </div>
        </div>
        <SolBarChart data={chartData} {...chartProps}>
          <Bar dataKey="Plugged in" fill={onlineColor} />
          <Bar dataKey="Unplugged" fill={offlineColor} />
        </SolBarChart>
        <div className={styles.footer}>
          {usbAlerts && (
            <div className={styles.left}>
              <h2 className={styles.title}>USB alerts are disabled.</h2>
              <div className={styles.label}>{usbAlerts && <>Go to {link} to manage.</>}</div>
            </div>
          )}
          <div className={styles.right}>
            <h2 className={styles.title}>{totalCountText}</h2>
            <div className={styles.counts}>
              <Count icon={PowerPlugIcon} color={onlineColor} count={plugCountSum} label={onlineCountText} />
              <Count icon={PowerPlugOffIcon} color={offlineColor} count={unplugCountSum} label={offlineCountText} />
            </div>
          </div>
        </div>
      </div>
    </SolCard>
  )
}
