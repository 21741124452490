import React, { useState } from 'react'
import omit from 'lodash/omit'
import { format } from 'date-fns'
import formatDistance from 'date-fns/formatDistance'
import {
  Categorizable,
  Column,
  DataTableInstance,
  Direction,
} from 'components/DataTableSlim/DataTableSlim'
import Toggle from './Toggle'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  useDisplayAlertsTableSubscription,
  useDisplaysAlertsTableCountQuery,
  ViewDisplayAlerts,
  ViewDisplayAlertsBoolExp,
  ViewDisplayAlertsOrderBy,
  DisplayAlertsTableSubscription,
  DisplayAlertsTableQQuery,
  DisplaysAlertsTableCountQuery,
  DisplayAlertsTableQDocument,
  useAlertsChartSubscription,
  AlertsChartSubscription,
} from 'graphql/__generated__/hasura-types'
import {
  getCategorySearchArg,
  getOrderByParams,
} from 'components/DataTableSlim/Utils/hasuraUtils'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useHasuraFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import AlertsHistoryChart from './AlertsHistoryChart'
import { getSearchIds } from 'components/DataTableSlim/Utils/hasuraUtils'
import { useTableExport } from 'components/DataTableSlim/Hooks/useTableExport'
import { OptionDate } from 'shared/types/OptionDate'

const defaultSort = {
  direction: 'descending' as Direction,
  field: 'time',
}

type Display = DisplayAlertsTableSubscription['_alerts'][number]

type UseAlertHistory = {
  loading: boolean
  rows?: DisplayAlertsTableSubscription
  count?: DisplaysAlertsTableCountQuery
  chartInfo?: AlertsChartSubscription
  searchParams: {
    where?: ViewDisplayAlertsBoolExp | null
    orderBy?: ViewDisplayAlertsOrderBy[] | null
    limit?: number | null
    offset?: number | null
  }
}

type Props = {
  optionDate: OptionDate
}

export const useAlertHistory = (
  onlyShowEmailedAlerts: boolean,
  optionDate: OptionDate,
): UseAlertHistory => {
  const {
    searchValue: searchValueRaw,
    page,
    orderBy,
    selectedItems,
    searchValue,
  } = useDataTable<Display>(DataTableInstance.AlertHistory)
  const appliedCategories
    = useHasuraFilters(DataTableInstance.AlertHistory) ?? []

  const display = getCategorySearchArg(appliedCategories)
  const where: ViewDisplayAlertsBoolExp = { display }
  if (onlyShowEmailedAlerts) {
    where.is_email = { _eq: true }
  }
  if (searchValueRaw.length > 0) {
    where._and = [
      ...(where._and ?? []),
      {
        _or: [
          { display: { name: { _ilike: `%${searchValueRaw}%` } } },
          { description: { _ilike: `%${searchValueRaw}%` } },
        ],
      },
    ]
  }

  if (optionDate && optionDate.fromDate && optionDate.toDate) {
    where._and = [
      ...(where._and ?? []),
      {
        time: { _gte: optionDate.fromDate, _lte: optionDate.toDate },
      },
    ]
  }

  const {
    data: aggregateData,
    loading: aggregateLoading,
  } = useDisplaysAlertsTableCountQuery({
    variables: {
      where,
    },
  })

  const [orderByParam] = getOrderByParams(orderBy)
  const orderByTable = [
    orderByParam.display
      ? {
        display: {
          name: orderByParam.display,
        },
      }
      : omit(orderByParam, 'name'),
  ]
  const {
    data: tableData,
    loading: tableDataLoading,
  } = useDisplayAlertsTableSubscription({
    variables: {
      where,
      limit: page.size,
      offset: (page.number - 1) * page.size,
      orderBy: orderByTable,
    },
  })

  const result = getSearchIds({
    includedIds: selectedItems?.includedIds,
    excludedIds: selectedItems?.excludedIds,
  })
  const { data: chartInfo } = useAlertsChartSubscription({
    variables: {
      args: {
        categories: appliedCategories.length ? appliedCategories : undefined,
        search: searchValue,
        include_ids: selectedItems?.includedIds?.includes('*')
          ? selectedItems?.includedIds
          : result?.include_ids,
        exclude_ids: selectedItems?.excludedIds?.includes('*')
          ? selectedItems?.excludedIds
          : result?.exclude_ids,
        from_date: optionDate?.fromDate,
        to_date: optionDate?.toDate,
      },
    },
  })

  return {
    loading: aggregateLoading || tableDataLoading,
    rows: tableData,
    count: aggregateData,
    chartInfo,
    searchParams: {
      where,
      orderBy: orderByTable,
    },
  }
}

const resultMapper = (data: DisplayAlertsTableQQuery): Display[] => data._alerts

const AlertHistory = ({ optionDate }: Props) => {
  const [onlyShowEmailedAlerts, setOnlyShowEmailedAlerts] = useState(false)
  const {
    loading: loadingAlerts,
    rows,
    count,
    chartInfo,
    searchParams,
  } = useAlertHistory(onlyShowEmailedAlerts, optionDate)

  const alerts = (rows?._alerts as ViewDisplayAlerts[]) ?? []

  const totalItems = count?.view_display_alerts_aggregate.aggregate?.count ?? 0

  const columns: Column<Categorizable<Display>>[] = [
    {
      name: 'time',
      displayName: 'Date',
      render: r => {
        const to = r.time ? formatDistance(new Date(r.time), new Date(), { addSuffix: true }) : 'unknown'
        const from = format(new Date(r.time), 'MMMM do yyyy, h:mmaa')
        return `${from}- ${to}`
      },
    },
    {
      name: 'display',
      displayName: 'Display',
      render: r => r.display?.name,
    },
    {
      name: 'description',
      displayName: 'Description',
      render: r => r.description,
    },
  ]

  const EmailAlertsOnlyToggle = () => (
    <Toggle
      onlyShowEmailedAlerts={onlyShowEmailedAlerts}
      setOnlyShowEmailedAlerts={setOnlyShowEmailedAlerts}
    />
  )

  const chartData = chartInfo?._alerts_chart
  const { onExport, isLoading: loadingExport } = useTableExport<
    DisplayAlertsTableQQuery,
    Display
  >({
    title: 'Alerts',
    query: DisplayAlertsTableQDocument,
    searchOptions: searchParams,
    totalItems,
    resultMapper,
    addableColumns: false,
  })

  return (
    <div>
      <AlertsHistoryChart data={chartData} />
      {ServerDataTable<Categorizable<Display>>({
        id: DataTableInstance.AlertHistory,
        columns,
        data: alerts,
        title: 'Alert History',
        totalItems,
        defaultSort,
        loading: loadingAlerts,
        allowExportToCsv: true,
        exportProcessing: loadingExport,
        onExport,
        addableColumns: true,
        columnManager: EmailAlertsOnlyToggle,
        padded: false,
      })}
    </div>
  )
}

export default AlertHistory
