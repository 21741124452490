import React, { ReactNode, useEffect, useState } from 'react'
import { MetricsByDate, TimeInterval, MetricType } from 'graphql/__generated__/types'

import Chart from 'SolComponents/SolChart/Components/Chart'
import { ChartOption, Option, ChartTimePeriod } from 'SolComponents/SolChart/types'
import { formatTime } from 'SolComponents/SolChart/chartUtils'
import { ChartDataPoint } from 'SolComponents/SolChart/types'

import AxisPicker from './AxisPicker'
import { PrimaryAxis, SecondaryAxis } from './types'
import { metricLabels } from 'shared/domain/metric'

import styles from './UsageHistory.module.scss'
import SolChartFilter from 'SolComponents/SolChartFilter/SolChartFilter'

interface UsageChartProps {
  options: Option[]
  data: MetricsByDate[]
  interval: TimeInterval
  setInterval: (interval: TimeInterval) => void
  timePeriod?: ChartTimePeriod
  children?: ReactNode
  onChangeMetrics: (metrics: MetricType[]) => void
  toDate?: Date
  hasElementPods?: Boolean | null
}

export const getTotals = (timeInterval: TimeInterval, toDate?: Date) => (item: MetricsByDate): ChartDataPoint =>
  item.metrics.reduce(
    (acc, current) => {
      if (current) {
        const label = metricLabels[current?.name]
        acc[label] = current?.total
      }
      return acc
    },
    { interval: formatTime(timeInterval, new Date(item.date), toDate) },
  )

export const mapGraphqlData = (
  data: MetricsByDate[],
  timeInterval: TimeInterval,
  toDate?: Date,
): ChartDataPoint[] => {
  return data.map(item => getTotals(timeInterval, toDate)(item))
}

export const factoryChartLines = (opt: Option, index: number) => ({
  id: opt.internalName,
  name: opt.displayName,
  color: axisColors[index],
  checked: true,
  right:
    opt.internalName === MetricType.AverageMeetingDuration || opt.internalName === MetricType.ContentItemsPerMeeting,
})

const axisColors = ['#2185d0', '#00b5ad']

const UsageChart = ({
  options,
  data,
  onChangeMetrics,
  interval,
  setInterval,
  timePeriod,
  toDate,
  ...props
}: UsageChartProps) => {
  const [chartData, setChartData] = useState<ChartDataPoint[]>([])
  const [chartLines, setChartLines] = useState<ChartOption[]>([])

  useEffect(() => {
    setChartLines(options.map(factoryChartLines).reverse())

    setChartData(mapGraphqlData(data || [], interval, toDate))
  }, [options])

  return (
    <div>
      {props?.children}
      <div className={styles.chartContainer}>
        {timePeriod && (
          <div className={styles.dropdownContainer}>
            <SolChartFilter
              value={interval}
              onChange={(event, { value }: { value: TimeInterval }) => setInterval(value)}
              timePeriod={timePeriod}
            />
          </div>
        )}
        <Chart data={chartData} options={chartLines} />
      </div>
      <div>
        <AxisPicker
          initPrimaryAxis={options[0].internalName as PrimaryAxis}
          initSecondaryAxis={options[1]?.internalName as SecondaryAxis}
          setAxis={onChangeMetrics}
          hasElementPods={props.hasElementPods}
        />
      </div>
    </div>
  )
}

export default UsageChart
