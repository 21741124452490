import React from 'react'
import styles from './DeleteAddressMode.module.scss'
import { SolCancelSave } from 'SolComponents'
import { CardModal } from '../CardModal/CardModal'

export interface Props {
  onClose: () => void
  isOpen: boolean
  onAccept: (addressToDelete: AddressToDelete) => void
  currentAddress: AddressToDelete
}

type AddressToDelete = {
  description: string
  editable: boolean
  key: number
  text: string
  value: string
}

export const DeleteAddressModal = ({ currentAddress, isOpen, onAccept, onClose }: Props) => {
  return (
    <>
      <CardModal
        className={styles.modalContent}
        isOpen={isOpen}
        header="Delete this address from your organization?"
        modalInfo={
          <>
            <p>All associated Pods’ locations will be unassigned from '{currentAddress.text}'</p>
          </>
        }
        actionButtons={
          <SolCancelSave
            acceptingText="Confirm"
            cancelClick={onClose}
            acceptingClick={() => {
              onAccept(currentAddress)
            }}
          />
        }
        close={onClose}
      />
    </>
  )
}
