import React from 'react'
import Metric from './Metric'
import { pluralize } from 'shared/core/utils'

interface MedianMeetingProps {
  count: number
}

const MedianMeeting = ({ count }: MedianMeetingProps) => {
  const inHours = count / 60 / 60
  const inMinutes = count / 60
  const inSeconds = count

  let displayValue = inHours
  let displayUnit = 'hr'

  if (inHours < 1) {
    displayValue = inMinutes
    displayUnit = 'min'
    if (inMinutes < 1) {
      displayValue = inSeconds
      displayUnit = 'sec'
    }
  }

  return (
    <Metric
      live={false}
      count={Math.round(displayValue)}
      unit={pluralize(displayValue, displayUnit, false)}
      caption="Median meeting length"
      dataTestId="length-card"
      url="/usage-history"
      linkName="USAGE"
    />
  )
}

export default MedianMeeting
