import React, { useCallback, useMemo } from 'react'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import styles from './index.module.scss'
import { SolArrowButton } from 'SolComponents'
import Donut, { DonutColors, labelColor } from './Donuts'
import { toPercentage } from 'shared/core/utils'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { CustomFilters, CustomFilter } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import SDSCard from './SDSCard'
import DeploymentTable from './Table'
import { SolsticeSubscriptionStatus, SolsticeVersionStatus } from './DeploymentController'
import {
  DeploymentsByDisplay,
  DeploymentsQueryResult,
  SdsHostPodCount,
} from 'graphql/__generated__/types'
import SolElementInfoMessage from '../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'
import { SolsticeElementEnum } from '../../shared/enums'

type Props = {
  aspectRatio?: number
  totalPods: number
  totalPodsExpired: number
  totalPodsExpiring: number
  totalPodsCurrentLmsVersion: number
  shortVersion: string
  customVersionFilters: CustomFilters<SolsticeVersionStatus>
  setCustomVersionFilter: (
    filterId: string,
    newCustomFilter: Partial<CustomFilter<SolsticeVersionStatus>>
  ) => void
  customSubscriptionFilters: CustomFilters<SolsticeSubscriptionStatus>
  setCustomSubscriptionFilter: (
    filterId: string,
    newCustomFilter: Partial<CustomFilter<SolsticeSubscriptionStatus>>
  ) => void

  sdsData?: SdsHostPodCount[]
  tableData: DeploymentsByDisplay[]
  loading: boolean
  isPageLoading: boolean
  initFlow: () => void
  fetchCsvData: () => Promise<DeploymentsQueryResult>
  userRole?: number
  handleDeletePod: (podId: string) => void
  hasElementPods?: boolean | null
}

const carouselResponsiveWidths: ResponsiveType = {
  desktop: {
    breakpoint: { max: 9999, min: 1390 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1390, min: 970 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 970, min: 100 },
    items: 1,
    partialVisibilityGutter: 0,
  },
}

const DeploymentPage = ({
  userRole,
  aspectRatio,
  totalPods,
  totalPodsExpired,
  totalPodsExpiring,
  totalPodsCurrentLmsVersion,
  shortVersion,
  customVersionFilters,
  setCustomVersionFilter,
  customSubscriptionFilters,
  setCustomSubscriptionFilter,
  sdsData,
  tableData,
  loading,
  isPageLoading,
  initFlow,
  fetchCsvData,
  handleDeletePod,
  hasElementPods,
}: Props) => {

  // Need to exclude element pods for metrics calculations
  const totalPodsMetrics = useMemo(() => {
    let result = totalPods
    if (hasElementPods) {
      const elementPodsCount = tableData.filter(p => p.licenseShortName === SolsticeElementEnum.licenseShortName).length
      result -= elementPodsCount
    }
    return result
  }, [totalPods, tableData, hasElementPods])

  const totalPodsUpgradeable = (totalPodsMetrics !== null && totalPodsCurrentLmsVersion !== null)
    ? +totalPodsMetrics - +totalPodsCurrentLmsVersion : undefined

  const addVersionFilter = (internalName: string, displayName: string, status: SolsticeVersionStatus) => {
    const values = {
      ...customVersionFilters.SolsticeVersionStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomVersionFilter('SolsticeVersionStatus', {
      values,
      color: 'blue',
    })
  }
  const addLatest = () => addVersionFilter('LatestVersion', 'Latest Version', SolsticeVersionStatus.LatestVersion)
  const addUpgradeable = () => addVersionFilter('Upgradeable', 'Upgradeable', SolsticeVersionStatus.Upgradeable)

  const versionDeps = [totalPodsCurrentLmsVersion, totalPodsUpgradeable, totalPodsMetrics, customVersionFilters]
  const transformVersionData = useCallback((currentVersionTotalPods, upgradeableTotalPods, podsTotal): PieSector[] => {
    return [
      {
        name: 'Latest Version',
        count: currentVersionTotalPods,
        percentage: toPercentage(currentVersionTotalPods, podsTotal, 1),
        fill: DonutColors.SUCCESS,
        labelColor: labelColor,
        onClick: addLatest,
      },
      {
        name: 'Upgradeable',
        count: totalPodsUpgradeable,
        percentage: toPercentage(upgradeableTotalPods, podsTotal, 1),
        fill: DonutColors.ERROR,
        labelColor: labelColor,
        onClick: addUpgradeable,
      },
    ]
  }, versionDeps)

  const versionData = useMemo(
    () =>
      (!(totalPodsMetrics == null) && !(totalPodsCurrentLmsVersion == null) && !(totalPodsUpgradeable == null)
        ? transformVersionData(totalPodsCurrentLmsVersion, totalPodsUpgradeable, totalPodsMetrics)
        : undefined),
    versionDeps,
  )

  const totalPodsSafeFromExpiration = useMemo(() => {
    return !(totalPodsMetrics == null) && !(totalPodsExpiring == null) && !(totalPodsExpired == null)
      ? +totalPodsMetrics - +totalPodsExpired - +totalPodsExpiring
      : undefined
  }, [totalPodsMetrics, totalPodsExpiring, totalPodsExpired])

  const addSubscriptionFilter = (internalName: string, displayName: string, status: SolsticeSubscriptionStatus) => {
    const values = {
      ...customSubscriptionFilters.SolsticeSubscriptionStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomSubscriptionFilter('SolsticeSubscriptionStatus', {
      values,
      color: 'blue',
    })
  }

  const addExpiringSubscription = () =>
    addSubscriptionFilter('ExpiringSoon', 'Expiring Soon', SolsticeSubscriptionStatus.ExpiringSoon)
  const addCurrentSubscription = () => addSubscriptionFilter('Current', 'Current', SolsticeSubscriptionStatus.Current)
  const addExpiredSubscription = () => addSubscriptionFilter('Expired', 'Expired', SolsticeSubscriptionStatus.Expired)

  const subscriptionDeps = [
    totalPodsMetrics,
    totalPodsExpired,
    totalPodsExpiring,
    customVersionFilters,
    customSubscriptionFilters,
  ]

  const getSubscriptionData = useCallback(
    (displaysExpired, displaysExpiring, currentDisplays, displaysTotalCount): PieSector[] => {
      return [
        {
          name: 'Current',
          count: currentDisplays,
          percentage: toPercentage(currentDisplays, displaysTotalCount, 1),
          fill: DonutColors.SUCCESS,
          labelColor: labelColor,
          onClick: addCurrentSubscription,
        },
        {
          name: 'Expiring Soon',
          count: displaysExpiring,
          percentage: toPercentage(displaysExpiring, displaysTotalCount, 1),
          fill: DonutColors.WARNING,
          labelColor: labelColor,
          onClick: addExpiringSubscription,
        },
        {
          name: 'Expired',
          count: displaysExpired,
          percentage: toPercentage(displaysExpired, displaysTotalCount, 1),
          fill: DonutColors.ERROR,
          labelColor: labelColor,
          onClick: addExpiredSubscription,
        },
      ]
    },
    subscriptionDeps,
  )

  const subscriptionData = useMemo(
    () =>
      (totalPodsMetrics != null && totalPodsExpired != null
      && totalPodsExpiring != null && totalPodsSafeFromExpiration != null
        ? getSubscriptionData(totalPodsExpired, totalPodsExpiring, totalPodsSafeFromExpiration, totalPodsMetrics)
        : undefined),
    subscriptionDeps,
  )

  return (
    <div>
      <SolFilterHeaderCard
        header="Deployment"
        tableId={DataTableInstance.Deployment}
        filteredItems={totalPods}
      ></SolFilterHeaderCard>
      <ContentWrapper>
        <div data-testid="deployment-carousel" className={styles.cards}>
          <Carousel
            draggable={false}
            responsive={carouselResponsiveWidths}
            ssr={false}
            deviceType={aspectRatio ? 'desktop' : ''}
            containerClass={styles.topRow}
            partialVisible
            customLeftArrow={<SolArrowButton className="arrow-btn" direction="left" />}
            customRightArrow={<SolArrowButton className="arrow-btn" direction="right" />}
          >
            <Donut
              data={subscriptionData}
              loading={loading}
              dataTestId="subscription-card"
              title="Solstice Subscription"
              handleClick={initFlow}
              aspectRatio={aspectRatio}
            />
            <Donut
              data={versionData}
              loading={loading}
              dataTestId="version-card"
              title={`On Latest Version (v${shortVersion})`}
              url="/manage/updates"
              aspectRatio={aspectRatio}
            />
            <SDSCard data={sdsData || []} loading={loading} />
          </Carousel>
          {hasElementPods
            && (
              <SolElementInfoMessage
                message="Solstice Element pods are not included"
              />
            )
          }
        </div>
        <DeploymentTable
          userRole={userRole}
          tableData={tableData || []}
          loading={isPageLoading}
          totalItems={totalPods}
          fetchCsvData={fetchCsvData}
          onDeletePod={handleDeletePod}
        />
      </ContentWrapper>
    </div>
  )
}

export default DeploymentPage
