import React from 'react'
import { Grid } from 'semantic-ui-react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { format, endOfMonth, addYears } from 'date-fns'

export const style = {
  page: {
    padding: '10px',
  } as React.CSSProperties,
  hintBox: {
    marginLeft: '15px',
    borderLeft: '4px solid #1991EB',
    fontSize: '16px',
  } as React.CSSProperties,
  expiration: {
    color: '#1991EB',
  },
}

export interface Props {
  latestPodExpirationDate: Date
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Hintbox
 * @param props { automaticExpirationDate }
 */
export const HintBox = (props: Props) => {
  const { latestPodExpirationDate } = props
  return (
    <Grid style={style.page}>
      <Grid.Row>
        <Grid.Column style={style.hintBox} width={12}>
          <p>
            We've automatically selected{' '}
            <span style={style.expiration}>
              { format(
                endOfMonth(addYears(zonedTimeToUtc( new Date(latestPodExpirationDate), localTimezone), 1)),
                'MMMM yyyy')
              }
            </span>
            (which is 1 year from your latest Pod expiration), but you can
            change it to the date that works for you. (Please note: Pod
            extensions are good through the last day of the month selected.)
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default HintBox
