import React from 'react'
import { AdvancedConfiguration } from 'graphql/__generated__/types'
import styles from '../Advanced.module.scss'
import { SolCheckbox } from 'SolComponents'
import { ValidationInput, RegexPattern } from 'components/FormValidation/ValidationInput'

export interface Props {
  labelText: string
  configuration: AdvancedConfiguration
  templateDiff?: any
  updateConfiguration: (newSettings: AdvancedConfiguration) => void
}

export function QoSSettings({
  labelText,
  configuration,
  updateConfiguration,
  templateDiff,
}: Props) {
  return (
    <>
      <SolCheckbox
        label={labelText}
        checked={configuration.enableQos ?? false}
        mismatched={templateDiff?.enableQos === 'mismatched'}
        onClick={() => {
          updateConfiguration({
            ...configuration,
            enableQos: !configuration.enableQos,
          })
        }}
        bold={false}
      />
      <div className={styles.subItem}>
        <ValidationInput
          name="videoStream"
          mismatched={templateDiff?.qosVideoDscp  ===  'mismatched'}
          rules={{
            required: configuration?.enableQos ?? false,
            pattern: RegexPattern.Binary,
          }}
          label="Video Stream DSCP Field"
          value={configuration.qosVideoDscp ?? ''}
          size="tiny"
          disabled={!configuration.enableQos ?? true}
          maxLength={6}
          onChange={(_, value) => {
            updateConfiguration({
              ...configuration,
              qosVideoDscp: value.value,
            })
          }}
        />
      </div>
      <div className={styles.subItem}>
        <ValidationInput
          name="audioStream"
          rules={{
            required: configuration?.enableQos ?? false,
            pattern: RegexPattern.Binary,
          }}
          label="Audio Stream DSCP Field"
          value={configuration.qosAudioDscp ?? ''}
          mismatched={templateDiff?.qosAudioDscp  ===  'mismatched'}
          size="tiny"
          disabled={!configuration.enableQos ?? true}
          maxLength={6}
          minLength={6}
          onChange={(_, value) =>
            updateConfiguration({
              ...configuration,
              qosAudioDscp: value.value,
            })
          }
        />
      </div>
    </>
  )
}
