import React, { ReactNode } from 'react'
import { Loader } from 'semantic-ui-react'
import NoResults from './NoResults'
import styles from './index.module.scss'

type Props = {
  children: ReactNode
  isEmpty?: boolean
  isLoading: boolean
}
const LoadingWrapper = ({ children, isEmpty, isLoading }: Props) => {
  return (
    <>
      {isLoading
       && <div className={styles.loaderWrapper}>
         <Loader active size="big" className={styles.loader}>
           Loading...
         </Loader>
       </div>
      }
      {(isEmpty && isLoading === false)
        && <NoResults />
      }
      {(!isEmpty && !isLoading)
        && <>{children}</>
      }
    </>
  )
}

export default LoadingWrapper
