import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

import SolStickyBanner from '../../SolComponents/SolStickyBanner/SolStickyBanner'
import styles from './styles.module.scss'
import { SolCloseIcon } from 'SolComponents/Icons'

export interface Props {
  onButtonClick: () => void
  closeBanner: () => void
  updateablePods: any
}

function StickyBanner({ onButtonClick, closeBanner, updateablePods }: Props) {
  const version = '6.1'
  return (
    <SolStickyBanner
      pure
      color="green"
    >
      <span className="renewText">
        <Icon name="star"></Icon>
        <strong>Solstice {version} is now available for {updateablePods?.updateablePodsCount} of your Pods!
        </strong> This is an important update for stability, usability, and performance.
      </span>
      <div className={styles.fixButton}>
        <Button
          className={styles.fixingBasic}
          onClick={onButtonClick}
        >
          VISIT UPDATES PAGE
        </Button>
      </div>
      <span className={styles.closeBanner}>
        <SolCloseIcon onClick={() => closeBanner()} />
      </span>
    </SolStickyBanner>
  )
}

export default StickyBanner
