import React, { useState } from 'react'
import { ChartDataPoint, ChartTimePeriod, ChartOption } from 'SolComponents/SolChart/types'
import CategorySelection from 'SolComponents/SolChart/Components/CategorySelection'
import ChartOptions from 'SolComponents/SolChart/Components/ChartOptions'
import SolChartFilter from 'SolComponents/SolChartFilter/SolChartFilter'
import Chart from 'SolComponents/SolChart/Components/Chart'
import { defaultPalette } from 'SolComponents/SolChart/chartUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  CategoryMetricsByDate,
  MetricType,
  TimeInterval,
  TotalByOption,
} from 'graphql/__generated__/types'

import { formatTime } from 'SolComponents/SolChart/chartUtils'
import { Category } from 'shared/types/categories'
import styles from '../ComparePage.module.scss'

interface CompareChartProps {
  data: CategoryMetricsByDate[]
  categories: Category[]
  category: string
  totalDisplays: number
  selectedDisplays: number
  timePeriod: ChartTimePeriod
  metric: MetricType
  onChangeMetric: (metric: MetricType) => void
  onChangeCategory?: (categoryId: string) => void
  interval: TimeInterval
  onChangeTimeInterval: (timeInterval: TimeInterval) => void
  hasElementPods?: Boolean | null
}

function mapGraphqlData(
  data: CategoryMetricsByDate[],
  timeInterval: TimeInterval,
): ChartDataPoint[] {
  return data.map(metric => {
    const options = (metric?.options || []) as TotalByOption[]
    return options.reduce(
      (acc, current) => {
        const option = current?.option
        if (option) {
          acc[option.name] = current.total
        }
        return acc
      },
      {
        interval: formatTime(timeInterval, new Date(metric.date)),
      },
    )
  })
}

export default React.memo(function CompareChart({
  data,
  categories,
  metric,
  timePeriod,
  interval,
  category,
  onChangeCategory,
  onChangeMetric,
  onChangeTimeInterval,
  hasElementPods,
}: CompareChartProps) {
  const options
    = categories.filter(cat => cat.id === category)[0]?.options || []

  const chartData = mapGraphqlData(data, interval)
  const [colorPalette, setColorPalette] = useState<string[]>(defaultPalette)
  const [checkboxes, setCheckboxes] = useState<ChartOption[]>([])
  const [maxOptions, setMaxOptions] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    if (options.length) {
      const initPalette = colorPalette.slice(0, 3)
      setColorPalette(defaultPalette.filter(p => !initPalette.includes(p)))
      setCheckboxes(
        options.map(({ id, displayName }, index) => {
          return {
            id: id ?? '',
            name: displayName ?? '',
            color: index <= 2 ? initPalette[index] : undefined,
            checked: index <= 2,
          }
        }),
      )
    }
  }, [options])

  function getNextColor() {
    const nextPalette = defaultPalette.filter(c => colorPalette.includes(c))
    const nextColor = nextPalette[0]
    return nextColor
  }

  function optionClicked(optName: string) {
    const newOptions = checkboxes.map(opt => {
      if (opt.name === optName) {
        if (opt.checked && opt.color) {
          setColorPalette([opt.color].concat(colorPalette))
          return {
            ...opt,
            color: undefined,
            checked: false,
          }
        } else if (!opt.checked && !maxOptions) {
          const color = getNextColor()
          setColorPalette(colorPalette.filter(c => c !== color))
          return {
            ...opt,
            color,
            checked: true,
          }
        }
      }
      return opt
    })
    setMaxOptions(newOptions.filter(o => o.checked).length >= 8)
    setCheckboxes(newOptions)
  }

  return (
    <div>
      <ChartOptions
        options={checkboxes}
        handleClick={optionClicked}
        maxOptions={maxOptions}
      />
      <div className={styles.chartContainer}>
        {timePeriod && (
          <div className={styles.dropdownContainer}>
            <SolChartFilter
              value={interval}
              onChange={(event, { value }: { value: TimeInterval }) => onChangeTimeInterval(value)}
              timePeriod={timePeriod}
            />
          </div>
        )}
        <Chart
          data={chartData}
          metric={metric}
          options={checkboxes.filter(val => val.checked)}
        />
      </div>

      <CategorySelection
        categories={categories}
        category={category}
        setCategory={onChangeCategory}
        metricList={Object.values(MetricType)}
        metric={metric}
        setMetric={onChangeMetric}
        hasElementPods={hasElementPods}
      />
    </div>
  )
})
