import {
  AuthenticationType,
  CalendarType,
  MailboxType,
  SettingsGroupType,
  Template,
} from 'graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultCalendarTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Calendar',
    type: SettingsGroupType.Calendar,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enabled: false,
      type: CalendarType.Exchange,
      exchangeSettings: {
        url: '',
        ntlmDomain: '',
        authenticationType: AuthenticationType.Basic,
        mailboxType: MailboxType.Default,
        userAccount: '',
        userPassword: {
          secret: '',
        },
      },
      showOverlay: true,
      showTitles: true,
      showOrganizers: true,
      updateInterval: 120,
      office365Settings: {
        clientId: '',
        tenant: '',
        clientSecret: {
          secret: '',
        },
      },
      googleSettings: {
        credentials: {
          name: '',
          data: {
            secret : '',
          },
        },
      },
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
