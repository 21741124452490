import { TimeInterval } from 'graphql/__generated__/types'
import React, { SyntheticEvent, useMemo } from 'react'

import { Dropdown, DropdownProps, Label } from 'semantic-ui-react'
import { ChartTimePeriod } from 'SolComponents/SolChart/types'

type Props = {
  value: string
  onChange: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void
  className?: string
  timePeriod: ChartTimePeriod
}

const SolChartFilter = ({ value, onChange, className, timePeriod }: Props) => {
  const timeOptions = useMemo(
    () => [
      {
        value: TimeInterval.Hour,
        text: 'Hours',
        disabled: timePeriod !== ChartTimePeriod.HOURS && timePeriod !== ChartTimePeriod.CUSTOM,
      },
      {
        value: TimeInterval.Day,
        text: 'Days',
        disabled: timePeriod === ChartTimePeriod.HOURS,
      },
      {
        value: TimeInterval.Week,
        text: 'Weeks',
        disabled: timePeriod === ChartTimePeriod.HOURS || timePeriod === ChartTimePeriod.WEEK, // Weeks disable if time period is "Last 48 Hours" OR "Last Week"
      },
      {
        value: TimeInterval.Month,
        text: 'Months',
        disabled: timePeriod !== ChartTimePeriod.QUARTER && timePeriod !== ChartTimePeriod.CUSTOM, // Month disabled if time period is NOT "Last Quarter"
      },
    ],
    [value, timePeriod],
  )

  return (
    <Label color="blue">
      <Dropdown
        options={timeOptions}
        onChange={onChange}
        value={value}
        selectOnBlur={false}
        name="chart-filter"
        className={className}
        upward={false}
      />
    </Label>
  )
}

export default SolChartFilter
