import React, { useRef } from 'react'
import classNames from 'classnames'
// @ts-ignore
import { useDispatch } from 'react-redux'
import { Button, Header } from 'semantic-ui-react'
import Dialog from 'react-modal'
import Carousel, { CarouselProps, CSSProperties } from 'nuka-carousel'
import { MxModalSlideKey, MxModalSlides } from 'shared/store/types'
import { dispatchInitMxRenew } from 'shared/store/helpers/maintenance'
import carouselStyles from './carousel.module.scss'
import styles from './styles.module.scss'
import { SolCloseIcon } from 'SolComponents/Icons'

type Style = {
  [property: string]: CSSProperties
}

const modalStyleOne: Style = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 600,
    backgroundColor: 'rgba(0,0,0,0.45)',
  },
  subHeader: {
    textAlign: 'left',
    fontSize: '16px',
  },
  image: {
    maxWidth: '85%',
  },
  title: {
    textAlign: 'left',
    marginTop: '0px',
  },
  fixThis: {
    textAlign: 'center',
  },
}

const modalStyleTwo: Style = {
  ...modalStyleOne,
  image: {
    maxWidth: '755px',
    width: '100%',
  },
  title: {
    textAlign: 'left',
  },
  fixThis: {
    marginTop: '50px',
    textAlign: 'center',
    zIndex: 1000,
  },
  carousel: {
    zIndex: 500,
  },
}

const slides: MxModalSlides = {
  availability: require('shared/images/availability.png'),
  usage: require('shared/images/usage.png'),
  proVsBase: require('shared/images/SolsticeCloudIncluded.png'),
}

const slidesHeader = {
  availability: 'Why Am I Seeing This?',
  usage: 'Why Am I Seeing This?',
  proVsBase: `What's Included With Solstice Subscription?`,
}

const slidesSubheader = {
  availability:
    `Solstice Cloud is offered at no additional charge for Pods with current Solstice
  Subscriptions. If a Pod\'s subscription lapses, it will remain in Solstice Cloud but certain features `
    + `will not be available. Click "Fix This" to get a quote for renewal.`,
  usage:
    `Solstice Cloud is offered at no additional charge for Solstice pods with current Solstice Subscriptions.
   If a Pod\'s subscription lapses, it will remain in Solstice Cloud but certain features `
    + `will not be available. Click "Fix This" to get a quote for renewal.`,
  proVsBase: `Solstice Subscription provides access to new features and improvements via software updates after
  the initial purchase date as well as full access to Solstice Cloud and customer support.`,
}

export interface Props {
  slideKeys: MxModalSlideKey[]
  isOpen: boolean
  onClose: () => void
}

function MaintenanceModal({ isOpen, onClose, slideKeys }: Props) {
  const carouselRef = useRef<Carousel & CarouselProps>(null)
  const dispatch = useDispatch()
  const renderCarousel = () => (
    <div className="carouselWrapper">
      <span>
        <SolCloseIcon className={styles.closeIcon} onClick={onClose} />
      </span>
      <Carousel
        dragging={false}
        renderCenterRightControls={() => null}
        renderCenterLeftControls={() => null}
        style={modalStyleTwo.carousel}
        className={carouselStyles.carouselWrapper}
        ref={carouselRef}
      >
        {slideKeys.map((slideKey, index) => (
          <div className={styles.carouselContainer} key={index}>
            <Header style={modalStyleTwo.title}>{slidesHeader[slideKey]}</Header>
            <p style={modalStyleTwo.subHeader}>{slidesSubheader[slideKey]}</p>
            <img style={modalStyleTwo.image} src={slides[slideKey]} onLoad={() => carouselRef.current?.onResize?.()} />
          </div>
        ))}
      </Carousel>
    </div>
  )

  const renderSingleImage = (slideKey: MxModalSlideKey) => (
    <div>
      <span>
        <SolCloseIcon className={styles.closeIcon} onClick={onClose} />
      </span>
      <Header style={modalStyleOne.title}>{slidesHeader[slideKey]}</Header>
      <p style={modalStyleOne.subHeader}>{slidesSubheader[slideKey]}</p>
      <img style={modalStyleTwo.image} src={slides[slideKey]} />
    </div>
  )

  const carousel: boolean = slideKeys.length > 1
  const modalStyle = carousel ? modalStyleTwo : modalStyleOne
  const modalClassName = classNames(styles.contentReactModal, {
    [styles.contentReactModalOne]: !carousel,
  })

  return (
    <Dialog onRequestClose={onClose} isOpen={isOpen} style={modalStyle} className={modalClassName}>
      <div>{carousel ? renderCarousel() : renderSingleImage(slideKeys[0])}</div>
      <div className={carousel ? styles.twoModals : styles.oneModal}>
        <Button
          style={modalStyle}
          color="blue"
          onClick={() => {
            onClose()
            dispatchInitMxRenew(dispatch)()
          }}
        >
          Fix This
        </Button>
      </div>
    </Dialog>
  )
}

export default MaintenanceModal
