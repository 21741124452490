import {
  useDailyActiveUsersQuery,
  useDisplayCountQuery as usePodsCount,
  DailyActiveUsersGraphArgs,
  useSessionsPerUserQuery,
  useAvgHoursSpentPerUserQuery,
  useAvgRoomsVisitedQuery,
  useUniqueUsersCountQuery,
  useUserCollaborationQuery,
  useMostCollaborativeSpacesQuery,
} from 'graphql/__generated__/hasura-types'
import { format, parseISO } from 'date-fns'
import { getCategorySearchArg } from 'components/DataTableSlim/Utils/hasuraUtils'

export const useCards = (dailyActiveGraphArgs: DailyActiveUsersGraphArgs) => {
  const dailyArgs: Record<string, any> = {
    variables: {
      args: dailyActiveGraphArgs,
    },
  }
  const dagaToDate = parseISO(dailyActiveGraphArgs?.to_date)
  const singleResultArgs: Record<string, any> = {
    variables: {
      args: {
        from_date: dailyActiveGraphArgs.from_date,
        to_date: format( new Date(dagaToDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX' ),
        categories: dailyActiveGraphArgs.categories,
      },
    },
  }

  const { data: dailyActiveUsers, loading: isDailyActiveUsersLoading } = useDailyActiveUsersQuery(dailyArgs)
  const { data: sessionsPerUser, loading: loadingSessionsPerUser } = useSessionsPerUserQuery(singleResultArgs)
  const { data: hoursPerUser, loading: loadingHoursPerUser } = useAvgHoursSpentPerUserQuery(singleResultArgs)
  const { data: roomsVisited, loading: loadingRoomsVisited } = useAvgRoomsVisitedQuery(singleResultArgs)
  const { data: usersCount, loading: loadingUsersCount } = useUniqueUsersCountQuery(singleResultArgs)
  const { data: usersCollaboration, loading: loadingUsersCollaboration } = useUserCollaborationQuery(singleResultArgs)
  const { data: mostCollaborativeSpaces, loading: loadingMostCollaborativeSpaces } = useMostCollaborativeSpacesQuery(
    singleResultArgs,
  )

  const categorySearchArg = getCategorySearchArg(dailyActiveGraphArgs.categories ?? [])
  const where = {
    ...categorySearchArg,
  }
  const { data: totalPodsQuery, loading: loadingPods } = usePodsCount({
    variables: {
      where,
    },
  })

  const totalDisplaysResult = totalPodsQuery?.totalDisplays.aggregate?.count || 0

  return {
    dailyActiveUsers: dailyActiveUsers?.dailyActiveUsers,
    isDailyActiveUsersLoading,
    sessionsPerUser,
    loadingSessionsPerUser,
    hoursPerUser,
    loadingHoursPerUser,
    roomsVisited,
    loadingRoomsVisited,
    usersCount,
    loadingUsersCount,
    usersCollaboration,
    loadingUsersCollaboration,
    mostCollaborativeSpaces,
    loadingMostCollaborativeSpaces,
    loadingPods,
    totalDisplaysResult,
  }
}
