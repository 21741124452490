import {
  useActivityUserMobilityQuery,
  SearchActivityUserMobilityArgs,
} from 'graphql/__generated__/hasura-types'

export const useMobilityCards = (activeUserMobilityArgs: SearchActivityUserMobilityArgs) => {

  const activeArgs: Record<string, any> = {
    variables: {
      args: activeUserMobilityArgs,
    },
  }
  const {
    data: activeUserMobilityData,
    loading: isActiveUserMobilityLoading,
  } = useActivityUserMobilityQuery(activeArgs)

  return {
    activeUserMobility: activeUserMobilityData?.activities,
    isActiveUserMobilityLoading,
  }
}
