import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

interface Props extends RouteComponentProps<any> {
  children: React.ReactNode
}

export function AuthWrapper({ children }: Props) {
  const isAuthenticated = useSelector((state: any) => !!state?.user?.token)
  return (
    <>
      {isAuthenticated ? children : <Redirect to="/login" />}
    </>
  )
}

export default AuthWrapper
