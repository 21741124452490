import React, { useEffect, useState } from 'react'
import SupportLogin from './SupportLogin'
import { AxiosError } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { supportLogin } from 'shared/store/actions/authenticate'

export type LoginCreds = {
  email: string
  password: string
  clientEmail: string
  org: string
}

const SupportLoginController = (props: any) => {
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.user)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (user.token) {
      props.history.push('/')
    }
  })

  const login = ({ email, password, clientEmail, org }: LoginCreds) => {
    setLoading(true)
    dispatch(supportLogin({
      email,
      password,
      clientEmail,
      org,
    })).catch((err: AxiosError) => {
      if (err.response?.status === 403 || err.response?.status === 500) {
        setError('Invalid email and/or password')
      }
      if (err.response?.status === 429) {
        setError('Too many tries with an invalid email and/or password')
      }
      setLoading(false)
    })
  }
  return (
    <SupportLogin
      login={login}
      error={error}
      loading={loading}
      {...props}
    />
  )
}

export default SupportLoginController
