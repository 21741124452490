import React from 'react'
import styles from './index.module.scss'
import { DateRange } from '../TopRow'
import UserDevices from './UserDevices'
import RoomTypeCard from './RoomType'

type Props = {
  setDevicesReady: (ready: boolean) => void
  dateRange: DateRange
}
const HomePageCards = (props: Props) => {
  return (
    <div className={styles.cards}>
      <div className={styles.roomType}>
        <RoomTypeCard doneLoading={() => props.setDevicesReady(true)} dateRange={props.dateRange}/>
      </div>
      <div className={styles.userDevices}>
        <UserDevices doneLoading={() => props.setDevicesReady(true)} dateRange={props.dateRange} />
      </div>
    </div>
  )
}

export default HomePageCards
