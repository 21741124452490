import { ApolloQueryResult, useQuery } from '@apollo/client'
import { NetworkStatus } from '@apollo/client'
import {
  CurrentScheduledTasksDocument,
  CurrentScheduledTasksQuery,
  CurrentScheduledTasksQueryVariables,
  DisplayUpdateTask,
  HistoricalScheduledTasksDocument,
  HistoricalScheduledTasksQuery,
  HistoricalScheduledTasksQueryVariables,
  ScheduledTask,
  TaskStatus,
} from 'graphql/__generated__/types'

const setDefaultStatus = (scheduledTask: ScheduledTask): ScheduledTask => ({
  ...scheduledTask,
  displays: scheduledTask?.displays?.map((display: DisplayUpdateTask) => ({
    ...display,
    status:
      display?.status === TaskStatus.Unknown
        ? TaskStatus.Failed
        : display?.status,
  })),
})

const byDate = (a: ScheduledTask, b: ScheduledTask) =>
  new Date(b.startDate).getTime() - new Date(a.startDate).getTime()

export const usePollCurrentTasks = (onCompleted?: () => void): {
  data: ScheduledTask[]
  networkStatus: NetworkStatus
  refetch: (variables?: CurrentScheduledTasksQueryVariables) => Promise<ApolloQueryResult<CurrentScheduledTasksQuery>>
} => {
  const { data, refetch, networkStatus } = useQuery<CurrentScheduledTasksQuery>(
    CurrentScheduledTasksDocument,
    {
      pollInterval: 10000,
      onCompleted: onCompleted,
    },
  )

  const tasks = (data?.scheduledTasks ?? []) as ScheduledTask[]

  return {
    networkStatus,
    data: [...tasks].sort(byDate),
    refetch,
  }
}

export const usePollHistoricalTasks = (): {
  data: ScheduledTask[]
  networkStatus: NetworkStatus
  refetch: (variables?: HistoricalScheduledTasksQueryVariables) =>
    Promise<ApolloQueryResult<HistoricalScheduledTasksQuery>>
} => {
  const { data, networkStatus, refetch } = useQuery<HistoricalScheduledTasksQuery>(
    HistoricalScheduledTasksDocument,
    { pollInterval: 30000 },
  )

  const tasks = (data?.scheduledTasks ?? []) as ScheduledTask[]

  return {
    networkStatus,
    data: tasks.map(setDefaultStatus).sort(byDate),
    refetch,
  }
}
