import React from 'react'
import { ScheduledTask, UpdateScheduledTaskInput, UpdateScheduledTaskMutation } from 'graphql/__generated__/types'
import Task from '../Task'
import styles from './TasksTab.module.scss'
import EmptyTab from '../EmptyTab'
import { TabLoader } from '../TabLoader'
import { FetchResult } from '@apollo/client'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'

interface Props {
  tasks: ScheduledTask[]
  loading: boolean
  onTaskDelete?: () => void
  versionUtils: VersionUtils
  updateScheduledTask: (options: UpdateScheduledTaskInput) =>
    Promise<FetchResult<UpdateScheduledTaskMutation, Record<string, any>, Record<string, any>>>
}

export function TasksTab({ tasks, loading, onTaskDelete, versionUtils, updateScheduledTask }: Props) {
  if (loading) {
    return <TabLoader />
  } else if (tasks.length === 0) {
    return <EmptyTab text="Scheduled and in progress tasks will appear here." />
  }
  const taskCards = tasks.map((task, i) => {
    return (<Task
      key={i}
      task={task}
      onDelete={onTaskDelete}
      versionUtils={versionUtils}
      updateScheduledTask={updateScheduledTask}
    />)
  })
  return <div className={styles.wrapper}>{taskCards}</div>
}
