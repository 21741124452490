import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { SolsticeRoutingSpace } from 'graphql/__generated__/types'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { SolButton, SolCheckbox, SolRadio, SolTooltip } from 'SolComponents'
import styles from './roomtype.tab.module.scss'

const singleRoomImg = require('assets/room_type_images/single_room.png')
const multiRoomImg = require('assets/room_type_images/multi_room_2.png')
const presentationMode = require('assets/room_type_images/presentation_mode.png')
const comparisonMode = require('assets/room_type_images/comparison_mode.png')
const routingBannerImg = require('assets/room_type_images/routing_banner.png')
const noRoutingBannerImg = require('assets/room_type_images/no_routing_banner.png')

export interface Props {
    setCurrentTab: Dispatch<SetStateAction<string>>
    space: SolsticeRoutingSpace | undefined
    setSpace: Dispatch<SetStateAction<SolsticeRoutingSpace | undefined>>
    saveSpace: any
}
export const RoomTypeTab = ({
  setCurrentTab,
  space,
  setSpace, // pre existing spaces have an id, new spaces do not
  saveSpace,
}: Props) => {
  const [verify, setVerify] = useState(true)
  const onSubmit = () => {
    if (!space?.name) {
      setVerify(false)
    } else {
      if (!space?.id) setCurrentTab('pods')
      else saveSpace()
    }
  }

  const nameChange = (e: string) => {
    setVerify(!!e)
    setSpace((oldSpace: SolsticeRoutingSpace) => ({
      ...oldSpace,
      name: e,
    }))
  }
  const RoomTypeRadio = ({ text, value }: {text: string; value: string}) => (
    <div>
      <SolRadio
        label={text}
        checked={space?.space_type === value}
        onClick={() => setSpace((oldSpace: SolsticeRoutingSpace) => ({
          ...oldSpace,
          space_type: value,
        }))} />
    </div>
  )
  const ModeratorTypeRadio = ({ text, value }: {text: string; value: string}) => (
    <div>
      <SolRadio
        label={text}
        checked={space?.moderator_sharing_mode === value}
        onClick={() => {
          setSpace((oldSpace: SolsticeRoutingSpace) => ({
            ...oldSpace,
            moderator_sharing_mode: value,
          }))
        }
        } />
    </div>
  )
  const SingleRoom = () => (
    <div className={styles.roomType}>
      <i>Route content to multiple displays within the same space</i>
      <img className={styles.singleRoomImg} src={singleRoomImg} />
      <p className={styles.headerText}>Describe your space: </p>
    </div>
  )
  const MultiRoom = () => (
    <div className={styles.roomType}>
      <i>Route content to multiple displays in different locations across your organiztion</i>
      <img src={multiRoomImg} className={styles.multiRoomImage}/>
      <p className={styles.headerText}>Describe your space: </p>
    </div>
  )
  const screenKeyTip = (
    <div className={styles.tipText} id="screen_key_tooltip">
      <p>Screen key verification requires Solstice Routing moderators to enter a display’s
        screen key to route content from the display.
      </p>
      <p>
        Note: “Enable Screen Key” must be enabled in the Pod’s
        Features template for the screen key verification to display.</p>
    </div>
  )
  const moderatorTip = (
    <div className={styles.tipText} id="moderatorTip">
      <p id="modtip1">Sets how content from the primary display shows when routed to other displays.
        <b id="modtip2"> Presentation</b> shows primary content full screen and covers posts shared on other displays.
        <b id="modtip3"> Comparison</b> shows primary content next to posts shared on other displays.
      </p>
      <p id="modtip4">
        Note: These are the default settings when a user opens a space.
        The app user may change them during a routing session.</p>
    </div>
  )
  const bannerTip = (
    <div className={styles.tipText} id="bannertip">
      <p>Routing banners tell participants where content is being routed to and from.
        Routing banners show when Moderator Sharing is set to Presentation mode.</p>
      <a
        className={styles.routingButton}
        href="https://documentation.mersive.com/en/al-settings-and-sessions.html"
        target="_blank"
        rel="noreferrer">
        Learn More
      </a>
    </div>
  )
  return (
    <ContentWrapper>
      <div>
        <div className={styles.spaceType}>
          <Form>
            <Form.Field required>
              <p className={styles.headerText}>Type of Space: </p>
            </Form.Field>
            <div className={styles.rowGroup}>
              <Form.Field className={styles.radioButton}>
                <RoomTypeRadio
                  text="Single Room"
                  value="SINGLE"
                />
              </Form.Field>
              <Form.Field className={styles.radioButton}>
                <RoomTypeRadio
                  text="Multi-Room"
                  value="MULTI"
                />
              </Form.Field>
            </div>
            {space?.space_type === 'SINGLE' ? <SingleRoom /> : <MultiRoom />}
            <Form.Input
              className={styles.inputStyle}
              placeholder="Biology Lab"
              error={!verify}
              label={
                <React.Fragment>
                  <span className={verify ? styles.inputStyle : styles.inputStyleError}>Name </span>
                  <span className={styles.asterisk}>*</span>
                </React.Fragment>
              }
              value={space?.name ?? ''}
              onChange={e => nameChange(e.target.value)}
            />
            {!verify && <p className={styles.error}>Please enter a name</p>}
            {space?.space_type === 'SINGLE'
          && <React.Fragment>
            <Form.Input
              className={styles.inputStyle}
              placeholder="Science Buillding"
              label="Building Name"
              value={space?.building_name ?? ''}
              onChange={e => setSpace((oldSpace: SolsticeRoutingSpace) => ({
                ...oldSpace,
                building_name: e.target.value,
              }))}
            />
            <Form.Input
              className={styles.inputStyle}
              placeholder="237"
              label="Room Number"
              value={space?.room_number ?? ''}
              onChange={e => setSpace((oldSpace: SolsticeRoutingSpace) => ({
                ...oldSpace,
                room_number: e.target.value,
              }))}
            />
          </React.Fragment>}
            <p className={styles.headerText}>Default settings for your space: </p>
            <div className={styles.rowGroup}>
              <SolCheckbox
                label="Require screen key verification"
                checked={!!space?.require_screen_key_verification}
                onClick={e => setSpace((oldSpace: SolsticeRoutingSpace) => ({
                  ...oldSpace,
                  require_screen_key_verification: e,
                }))}
              />
              <SolTooltip text={screenKeyTip} />
            </div>
            <div className={styles.rowGroup}>
              <SolCheckbox
                label="Require password on room exit"
                checked={!!space?.require_password_on_exit}
                onClick={e => setSpace((oldSpace: SolsticeRoutingSpace) => ({
                  ...oldSpace,
                  require_password_on_exit: e,
                }))}
              />
              <SolTooltip
                text="This will not apply if users login with SSO."
              />
            </div>
            <div className={styles.rowGroup}>
              <b>Moderator sharing mode</b>
              <SolTooltip
                text={moderatorTip} />
            </div>
            <div className={styles.modRadioGroup}>
              <Form.Field className={styles.radioButton}>
                <ModeratorTypeRadio
                  text="Presentation"
                  value="presentation"
                />
              </Form.Field>
              <Form.Field className={styles.radioButton}>
                <ModeratorTypeRadio
                  text="Comparison"
                  value="comparison"
                />
              </Form.Field>
            </div>
            <p className={styles.previewText}>[Thumbnail Preview]</p>
            <img className={styles.previewImg}
              src={space?.moderator_sharing_mode === 'presentation' ? presentationMode : comparisonMode} />
            <div className={styles.rowGroup}>
              <SolCheckbox
                className={styles.routingCheckbox}
                label="Show routing banner"
                checked={!!space?.show_routing_banner}
                onClick={e => setSpace((oldSpace: SolsticeRoutingSpace) => ({
                  ...oldSpace,
                  show_routing_banner: e,
                }))}
              />
              <SolTooltip
                hoverable
                text={bannerTip} />
            </div>
            <p className={styles.previewText}>[Thumbnail Preview]</p>
            <img
              className={styles.previewImg}
              src={
                space?.show_routing_banner
                  ? routingBannerImg : noRoutingBannerImg
              }
            />
          </Form>
        </div>

        <div className={styles.buttonHolder}>
          <div></div>
          <SolButton
            text={space?.id ? 'Save' : 'Next'}
            handleClick={onSubmit}
            color="blue"
            className={styles.nextButton}
          />
        </div>

      </div>
    </ContentWrapper>
  )
}
