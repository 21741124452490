import React from 'react'
import { Label as SemanticLabel } from 'semantic-ui-react'

type Props = {
  bgColor: string
  textColor?: string
  children: React.ReactNode
}

const Label = ({ bgColor, textColor = '#fff', children }: Props) => (
  <SemanticLabel horizontal style={{ backgroundColor: bgColor, color: textColor }}>
    {children}
  </SemanticLabel>
)

export default Label
