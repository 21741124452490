import React from 'react'
import { Label } from 'semantic-ui-react'
import { AppliedCategoryFilters } from '../Hooks/useAppliedFilters'

export interface Props {
  categoryInternalName: string
  appliedFilters?: AppliedCategoryFilters
}

export const FilterMenuItemLabel = (props: Props) => {
  const { categoryInternalName, appliedFilters } = props

  const applied = Object.values(appliedFilters || {}).filter(appliedFilter => {
    const filterCategoryInternalName = appliedFilter.categoryInternalName
    return filterCategoryInternalName === categoryInternalName
  })

  let categoryFilterCount = 0

  applied.forEach(filter => {
    const filterCategoryInternalName = filter.categoryInternalName
    const customFilterOptionMatches = filter.optionMatches
    if (
      filterCategoryInternalName
      && filterCategoryInternalName.toString() === categoryInternalName
    ) {
      if (
        customFilterOptionMatches
        && Object.keys(customFilterOptionMatches).length > 0
      ) {
        categoryFilterCount
          = categoryFilterCount + Object.keys(customFilterOptionMatches).length
      } else {
        categoryFilterCount++
      }
    }
  })

  return categoryFilterCount ? (
    <Label color="blue">{categoryFilterCount}</Label>
  ) : null
}

export default FilterMenuItemLabel
