import { DisplayUnreachableDelay, UsbPlugEventType, Filter } from '../types'
import { useAlertConfigQuery } from 'graphql/__generated__/hasura-types'

export const useAlertConfig = () => {
  const { data } = useAlertConfigQuery()

  const config = data?.users[0]
  const displayUnreachableEnabled = config?.displayUnreachableEnabled ?? false
  const displayRebootedEnabled = config?.displayRebootedEnabled ?? false
  const displayOutOfTemplateEnabled
    = config?.displayOutOfTemplateEnabled ?? false
  const usbPlugEventEnabled = config?.usbPlugEventEnabled ?? false

  const displayUnreachableDelay: DisplayUnreachableDelay
    = config?.displayUnreachableDelay ?? 5
  const usbPlugEventType = (config?.usbPlugEventType
    ?? 'added') as UsbPlugEventType

  const displayUnreachableFilters: Filter[] = data?.unreachableFilters ?? []
  const displayRebootedFilters: Filter[] = data?.rebootedFilters ?? []
  const displayOutOfTemplateFilters: Filter[] = data?.templateFilters ?? []
  const usbPlugEventFilters: Filter[] = data?.usbFilters ?? []

  return {
    displayUnreachableEnabled,
    displayRebootedEnabled,
    displayOutOfTemplateEnabled,
    usbPlugEventEnabled,
    displayUnreachableDelay,
    usbPlugEventType,
    displayUnreachableFilters,
    displayRebootedFilters,
    displayOutOfTemplateFilters,
    usbPlugEventFilters,
  }
}
