import React from 'react'
import classNames from 'classnames'
import './LoadingBar.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { showMxRenewalBanner } from 'shared/store/selectors/maintenance'

interface ProgressProps {
  visible: boolean
  className?: string
  header?: boolean
}

// Be warned. This component has absolute positioning.
// It will show in the same place on the page no matter where it is called from (ie. from  dataTabelSlim)

// code lifted from https://codepen.io/holdencreative/pen/vEVbwv
export function LoadingBar(props: ProgressProps) {
  const mxBannerOpen = useSelector(state => showMxRenewalBanner(state))
  const endOfLifeBanner = useSelector((state: any) => state?.core?.endOfLifeBanner)
  const showUpdatesBanner = useSelector((state: any) => state?.core?.updatesBanner)

  const {
    visible,
    className,
    header = false,
  } = props
  const { outageWarning } = useFlags()

  const numOpen = outageWarning + mxBannerOpen + showUpdatesBanner + endOfLifeBanner // js magic, booleans are converted to numbers

  const classes = classNames(
    'progressComponent',
    { ['oneOpen']: numOpen === 1 },
    { ['twoOpen']: numOpen === 2 },
    { ['threeOpen']: numOpen === 3 },
    { visible, header },
    className,
  )

  const outageOverride = outageWarning ? 'outageBannerOn' : ''
  return (
    <div id={outageOverride} className={classes}>
      {visible && (
        <div className="indeterminate" />
      )}
    </div>
  )
}

export default LoadingBar
