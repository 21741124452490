import React from 'react'
import {
  Template,
  FeaturesConfiguration,
  BrowserLookIn,
} from 'graphql/__generated__/types'
import { SolRadio } from 'SolComponents'
import styles from '../Features.module.scss'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: FeaturesConfiguration) => void
}

function BrowserLookInOptions({
  updateConfiguration,
  template,
}: Props) {

  const configuration = template.revision.configuration as FeaturesConfiguration

  return (
    <div className={styles.radioButtons}>
      <div>
        <SolRadio
          label="Enabled"
          checked={configuration.browserLookIn === BrowserLookIn.Enabled}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              browserLookIn: BrowserLookIn.Enabled,
            })
          }}
        />
      </div>
      <div>
        <SolRadio
          label="Disabled"
          checked={configuration.browserLookIn === BrowserLookIn.Disabled}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              browserLookIn: BrowserLookIn.Disabled,
            })
          }}
        />
      </div>
      <div>
        <SolRadio
          label="Determine at runtime"
          checked={configuration.browserLookIn === BrowserLookIn.DetermineAtRuntime}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              browserLookIn: BrowserLookIn.DetermineAtRuntime,
            })
          }}
        />
      </div>
    </div>
  )
}

export default BrowserLookInOptions
