import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: null,
  alertType: '',
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    dismissAlert : state => {
      state.message = null
    },
    showSuccess : (state, action) => {
      state.message = action.payload
      state.alertType = 'success'
    },
    showError : (state, action) => {
      state.message = action.payload
      state.alertType = 'error'
    },
  },
})

export const { dismissAlert, showSuccess, showError } = alertSlice.actions


export default alertSlice.reducer
