
import { createSelectorWithDependencies as createSelector, registerSelectors } from 'reselect-tools'
import { MxModalSlideKey } from 'shared/store/types'

import { MxRenewLeadInterface, MxRenewLeadDisplayInterface } from 'shared/services/mxRenew/types'
import { generatePricePerPod } from '../helpers/maintenance'
import { State } from '../slices/reducerUtils'

export const showMxRenewalBannerFn = (state: any) =>  state?.mxRenewal?.showBanner

export const showMxRenewalBanner = createSelector([showMxRenewalBannerFn], (showBanner: State) => showBanner)

const getRenewFlowCurrentLeadFn = (state: State) => {
  return state.mxRenewal?.lead
}

export const getRenewFlowCurrentLead = createSelector([getRenewFlowCurrentLeadFn], (currentLead: State) => {
  return currentLead || {}
})

export const getOnlyShowExpiringSoonToggle = createSelector([(state: State) => state], (state: State) =>
  state.mxRenewal.showExpiringSoon || true,
)

const getRenewModalSlideKeysFn = (state: any) => {
  const pathname: string = state?.router?.location?.pathname
  switch (pathname) {
    // exact match, does not include child routes
    case '/availability':
      return ['availability' as MxModalSlideKey, 'proVsBase' as MxModalSlideKey]
    case '/usage-history':
      return ['usage' as MxModalSlideKey, 'proVsBase' as MxModalSlideKey]
    default:
      return ['proVsBase' as MxModalSlideKey]
  }
}

export const getRenewModalSlideKeys = createSelector([getRenewModalSlideKeysFn], (slides: State) => slides)

/*
 * renewal flow: is initiated (boolean)
 */
const getRenewFlowIsInitiatedFn = (state: State) => {
  return state.mxRenewal.initiated || false
}

export const getRenewFlowIsInitiated = createSelector(
  [getRenewFlowIsInitiatedFn],
  (isInitiated: boolean) => isInitiated,
)

/*
 * renewal flow: is active (boolean)
 */

const getRenewFlowIsActiveFn = (state: State) => {
  return state.mxRenewal?.active
}

export const getRenewFlowIsActive = createSelector([getRenewFlowIsActiveFn], (isActive: boolean) => isActive)

/*
 * renewal flow: is waiting for async (boolean)
 */

const getRenewFlowIsWaitingFn = (state: State) => {
  return state.mxRenewal?.waiting || false
}

export const getRenewFlowIsWaiting = createSelector([getRenewFlowIsWaitingFn], (isWaiting: boolean) => isWaiting)

/*
 * renewal flow: current step (number)
 */

const getRenewFlowCurrentStepFn = (state: State) => {
  return state.mxRenewal?.currentStep || null
}

export const getRenewFlowCurrentStep = createSelector(
  [getRenewFlowCurrentStepFn],
  (currentStep: number | null) => currentStep,
)

/*
 * renewal flow: current errors (collection)
 */

const getRenewFlowCurrentErrorsFn = (state: State) => {
  return state.mxRenewal?.currentErrors || null
}

export const getRenewFlowCurrentErrors = createSelector(
  [getRenewFlowCurrentErrorsFn],
  (currentErrors: any[]) => currentErrors,
)

export const getRenewFlowSelectedPods = createSelector(
  [getRenewFlowCurrentLead],
  (lead: MxRenewLeadInterface = { id: '123', step: 0, maxStep: 0 }) => {
    const pods = {
      ...lead.selectedExpiredPods,
      ...lead.selectedOtherPods,
    }

    return Object.keys(pods).reduce((acc: { [key: string]: MxRenewLeadInterface }, key: string) => {
      const currentPod = pods[key] as any
      acc[key] = {
        ...currentPod,
        price: lead.newPodExpiration
          ? generatePricePerPod(
            new Date(currentPod.mxExpires),
            new Date(lead.newPodExpiration),
            currentPod.hardware,
            currentPod.edition,
            currentPod.license,
          )
          : 0,
      } as any
      return acc
    }, {})
  },
)

export const getRenewFlowEarliestPodExpiration = createSelector(
  [getRenewFlowSelectedPods],
  (selectedPods: { [key: string]: MxRenewLeadDisplayInterface }) => {
    if (Object.keys(selectedPods).length === 0) {
      return new Date()
    }
    return new Date(
      Object.values(selectedPods).sort(
        (a, b) => new Date(a.mxExpires).getTime() - new Date(b.mxExpires).getTime(),
      )[0].mxExpires,
    )
  },
)

export const getRenewFlowLatestPodExpiration = createSelector(
  [getRenewFlowSelectedPods],
  (selectedPods: { [key: string]: MxRenewLeadDisplayInterface }) => {
    if (Object.keys(selectedPods).length === 0) {
      return new Date()
    }

    return new Date(
      Object.values(selectedPods).sort(
        (a, b) => new Date(b.mxExpires).getTime() - new Date(a.mxExpires).getTime(),
      )[0].mxExpires,
    )
  },
)

registerSelectors({
  showMxRenewalBannerFn,
  showMxRenewalBanner,
  getRenewModalSlideKeysFn,
  getRenewModalSlideKeys,
  getRenewFlowIsInitiatedFn,
  getRenewFlowIsInitiated,
  getRenewFlowIsActiveFn,
  getRenewFlowIsActive,
  getRenewFlowIsWaitingFn,
  getRenewFlowIsWaiting,
  getRenewFlowCurrentStepFn,
  getRenewFlowCurrentStep,
  getRenewFlowCurrentLeadFn,
  getRenewFlowCurrentLead,
  getRenewFlowLatestPodExpiration,
  getRenewFlowEarliestPodExpiration,
})
