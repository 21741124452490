import React from 'react'
import classNames from 'classnames'
import styles from './SolArrowButton.module.scss'

interface Props {
  direction: 'left' | 'right'
  onClick?: () => void
  className?: string
}

const SolArrowButton = (props: Props) => (
  <button
    className={classNames(styles.button, props.className, {
      [styles.left]: props.direction === 'left',
      [styles.right]: props.direction === 'right',
    })}
    onClick={props.onClick}
  />
)

export default SolArrowButton
