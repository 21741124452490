import intersection from 'lodash/intersection'
import { recommendedCategories } from './recommendedCategories'
import { Category } from '../../CategoryTypes'

export const hasAllRecommendedCategories = (categories: Category[]): boolean => {
  const existingCategoryDisplayNames: string[] = categories.map(
    c => c.name ?? '',
  )
  const recommendedCategoriesDisplayNames: string[] = recommendedCategories.map(
    c => c.name ?? '',
  )
  // if they don't have as many cats as recommended
  if (
    existingCategoryDisplayNames.length
    < recommendedCategoriesDisplayNames.length
  ) {
    return false
  }
  // returns intersection of values present in both arrays, their actual cats vs our recommended
  const commonDisplayNames = intersection(
    existingCategoryDisplayNames,
    recommendedCategoriesDisplayNames,
  )
  if (commonDisplayNames.length === recommendedCategoriesDisplayNames.length) {
    return true
  }
  return false
}
