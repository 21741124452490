import React from 'react'
import { SolFileUpload, SolTextArea, SolTooltip } from 'SolComponents'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import styles from './SSO.module.scss'

type Props = {
  cert: string | null
  setCert: (cert: string) => void
  setCertName: (name: string) => void
  placeHolder: string
  certName: string
  invalidCert: boolean
}

const VerificationCert = (props: Props) => {

  const tooltip = `Upload or enter the public x509 certificate provided by your SAML provider.`

  return (
    <div>
      <label>
        Verification Certificate
        <span className={styles.required}>*</span>
        <span className={styles.icon}>
          <SolTooltip text={tooltip} trigger={<SolQuestionCircle size="small" />} />
        </span>
      </label>
      <div className={styles.input}>
        <SolFileUpload
          file={{
            name: props.certName,
            contents: props.certName || '', // This is a hack to get the button to display properly
          }}
          fileTypes=".cer, .der, .crt, .pem, .pfx, .p12, .cert"
          label="certificate"
          onUpload={({ fileName, fileContents }) => {
            props.setCertName(fileName)
            props.setCert(fileContents)
          }}
          onFailure={() => { }}
        />
      </div>
      {!props.certName
        && <>
          <div className={styles.input}>or</div>
          <SolTextArea
            placeholder={props.placeHolder}
            value={props.cert}
            onChange={e => props.setCert(e.target.value)}
            growable
          />
        </>
      }
      {props.invalidCert
        && <div className={styles.error}>Certificate is Not Valid</div>
      }
    </div>
  )
}

export default VerificationCert
