import axios from 'axios'

import { baseURL, isProduction } from 'shared/core/config'

import { versionFromBundleName, getJSBundleName } from 'shared/core/utils'

export const CLIENT_UPDATE_INTERVAL = 1000 * 60 * 5 // 5 minutes
const ASSET_MANIFEST_URL: string = `https://${baseURL()}/asset-manifest.json`
const CURRENT_VERSION: string = versionFromBundleName(getJSBundleName())

export const reload = () => {
  window.location.reload()
}

export const updateClient = (
  currentVersion: string,
  latestVersion: string,
  reloader: () => void = reload,
): void => {
  const TIMEOUT = 1000 * 5 // 5 seconds
  setTimeout(() => reloader(), TIMEOUT)
}

export const checkForClientUpdate = (
  URL: string,
  currentVersion: string,
  update: (a: string, b: string, c: () => void) => void,
): Promise<any> => {
  const AXIOS_CONFIG = {
    headers: {
      'Cache-Control': 'no-cache',
    },
  }

  return axios
    .get(URL, AXIOS_CONFIG)
    .then(json => {
      // both currentVersion and latestVersion are an 8 char hash generated by webpack
      const latestVersion: string = versionFromBundleName((json.data || {})['main.js'])
      if (currentVersion.length === 8 && latestVersion.length === 8 && currentVersion !== latestVersion) {
        update(currentVersion, latestVersion, reload)
      }
    })
    .catch(err => {
      /* eslint-disable-next-line no-console */
      console.error('checkForClientUpdate failed: ', err)
    })
}

if (isProduction()) {
  setInterval(() => checkForClientUpdate(ASSET_MANIFEST_URL, CURRENT_VERSION, updateClient), CLIENT_UPDATE_INTERVAL)
}
