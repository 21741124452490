import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

require('./modal.css')

export interface Props {
  isOpen: boolean
  close: () => void
  confirm: () => void
}

export const style = {
  header: {
    textAlign: 'center',
    borderBottom: 'none',
  } as React.CSSProperties,
  content: {
    textAlign: 'center',
    marginTop: '0px',
    paddingTop: '0px',
  } as React.CSSProperties,
  actions: {
    borderTop: 'none',
    background: 'white',
    textAlign: 'center',
  } as React.CSSProperties,
  cancel: {
    color: 'black',
    cursor: 'pointer',
  } as React.CSSProperties,
}

const FlowCancelModal = (props: Props) => (
  <div id="renewModal">
    <Modal open={props.isOpen} size="mini" id="renewModal">
      <Modal.Header style={style.header}>Are you sure you want to leave?</Modal.Header>
      <Modal.Content style={style.content}>Your progress won't be saved.</Modal.Content>
      <Modal.Actions style={style.actions}>
        <a style={style.cancel} onClick={props.confirm}>
          {' '}
          Yes, Cancel
        </a>
        <Button color="blue" onClick={props.close}>
          Take Me Back
        </Button>
      </Modal.Actions>
    </Modal>
  </div>
)

export default FlowCancelModal
