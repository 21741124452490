import React from 'react'
import { SolButton } from 'SolComponents'
import styles from './SolCardHeader.module.scss'

type Props = {
  submit: () => void
  cancel: () => void
  cancelButtonDisabled: boolean
  saveButtonDisabled: boolean
  name: string
}

const SolCardHeader = (props: Props) => {
  return (
    <div className={styles.cardHeader}>
      <div className={styles.leftContent}>{props.name}</div>
      <div className={styles.rightContent}>
        <SolButton
          className={styles.actionButtons}
          primary={false}
          basic
          color="black"
          text="Cancel"
          disabled={props.cancelButtonDisabled}
          handleClick={e => {
            e.stopPropagation()
            props.cancel()
          }}
          isLink
        />
        <SolButton
          className={styles.actionButtons}
          primary
          basic={false}
          text="Save"
          disabled={props.saveButtonDisabled}
          handleClick={e => {
            e.stopPropagation()
            props.submit()
          }}
          isLink={false}
        />
      </div>
    </div>
  )
}

export default SolCardHeader
