import React, { useEffect, useState } from 'react'
import { TimeInterval } from 'graphql/__generated__/types'
import { ChartTimePeriod } from 'SolComponents/SolChart/types'
import { getPreviousDate } from 'SolComponents/SolChart/chartUtils'
import { format } from 'date-fns'
import TimeIntervalMenu, { DateRangeOptions } from 'SolComponents/SolChart/Components/TimeInterval'
import { Props as SolFilterHeaderCardProps } from './SolFilterHeaderCard'
import { OptionDate } from 'shared/types/OptionDate'

interface Props {
  handleIntervalChange: SolFilterHeaderCardProps['handleIntervalChange']
  options?: DateRangeOptions
  setInterval: (interval: TimeInterval) => void
  timeInterval: TimeInterval
}

const DateRange = function(props: Props) {
  const [timePeriod, setTimePeriod] = useState<ChartTimePeriod>(ChartTimePeriod.MONTH)
  const [optionDate, setDate] = useState<OptionDate>({
    fromDate: format(getPreviousDate(timePeriod, new Date()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
  }) // how do we pass up the selected date range to the rest of the page?  should we add it into the context

  const handleChangeTimePeriod = (period: ChartTimePeriod, range?: OptionDate) => {
    let newOptionDate: OptionDate | undefined = undefined
    if (range) {
      newOptionDate = { ...range }
      if (newOptionDate) {
        setDate(newOptionDate)
      }
    }
    setTimePeriod(period)
    if (newOptionDate) {
      props.handleIntervalChange?.(period, newOptionDate)
    }
  }

  useEffect(() => {
    props.handleIntervalChange?.(timePeriod, optionDate)
  }, [])

  return (
    <TimeIntervalMenu
      timePeriod={timePeriod}
      interval={props.timeInterval}
      setTimePeriod={handleChangeTimePeriod}
      setInterval={props.setInterval}
      testId="time-interval"
      options={props.options}
    />
  )
}

DateRange.defaultProps = {
  timeInterval: TimeInterval.Day,
  setInterval: () => {},
}

export { DateRange }
