import React from 'react'

import { Dropdown } from 'semantic-ui-react'

type direction = 'left' | 'right'

export interface Props {
  className?: string
  direction: direction
  children: any
  onOpen?: () => void
  onClose?: () => void
  onBlur?: () => void
  open?: boolean
}

export const SolEllipsesDropdown = (props: Props) => {
  return (
    <Dropdown
      icon="ellipsis vertical"
      direction={props.direction}
      className={props.className}
      onOpen={props.onOpen}
      onClose={props.onClose}
      onBlur={props.onBlur}
      open={props.open}
    >
      {props.children}
    </Dropdown>
  )
}
export default SolEllipsesDropdown
