import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { State } from 'shared/store/slices/reducerUtils'
import EndOfLifeBanner, { StateFromProps } from './EndOfLifeBanner'
import { setEndOfLifeBanner } from '../../shared/store/slices/core'

const mapStateToProps = (state: State): any => ({
  showBanner: state.core.endOfLifeBanner,
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setBannerOpen: (open: boolean) => dispatch(setEndOfLifeBanner(open)),
  onButtonClick: () => onClick(),
})

const onClick = () => {
  window.open('https://www.mersive.com/gen2-eol/', '_blank')
}

export default connect<StateFromProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EndOfLifeBanner)
