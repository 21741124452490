import React from 'react'
import styles from './SolRealtimeStatus.module.scss'
import { Icon } from 'semantic-ui-react'
import { SolTooltip } from 'SolComponents'
import classNames from 'classnames'
import { toTitleCase } from 'shared/core/utils'

export enum StatusEnum {
  BOOKED_EMPTY = 'booked, empty',
  SINGLE_OCCUPANT = 'single occupant',
  AD_HOC = 'ad hoc',
  IN_USE = 'in use',
  SUBSCRIPTION_EXPIRED = 'subscription expired',
  BOOKED = 'booked',
  UNKNOWN = 'unknown',
}

export interface Props {
  status: StatusEnum
  iconSize?: 'big' | 'small' | 'tiny' | 'mini' | 'large' | 'huge' | 'massive' | undefined
  isInline?: boolean
  className?: string
  containerClassName?: string
  tooltipText?: string
}

export const determineColor = (status: StatusEnum) => {
  if (status === StatusEnum.BOOKED_EMPTY) {
    return 'orange'
  }
  if (status === StatusEnum.SINGLE_OCCUPANT) {
    return 'purple'
  }
  if (status === StatusEnum.AD_HOC) {
    return 'blue'
  }
  if (status === StatusEnum.IN_USE) {
    return 'teal'
  }
  if (status === StatusEnum.SUBSCRIPTION_EXPIRED) {
    return 'yellow'
  }
  if (status === StatusEnum.BOOKED) {
    return 'green'
  }
  return
}

export const determineHexColor = (status?: StatusEnum) => {
  if (status === StatusEnum.BOOKED_EMPTY) {
    return '#F0712C'
  }
  if (status === StatusEnum.SINGLE_OCCUPANT) {
    return '#A23BC5'
  }
  if (status === StatusEnum.AD_HOC) {
    return '#2987CD'
  }
  if (status === StatusEnum.IN_USE) {
    return '#0AC299'
  }
  if (status === StatusEnum.SUBSCRIPTION_EXPIRED) {
    return '#FBB812'
  }
  if (status === StatusEnum.BOOKED) {
    return '#2DB84B'
  }
  return
}

export const SolRealtimeStatus = (props: Props) => {
  const statusIcon = (
    <span className={classNames(styles.icon, props.className)}>
      <Icon name="circle" color={determineColor(props.status)} size={props.iconSize} />
    </span>
  )

  return (
    <SolTooltip
      hoverable
      position="bottom center"
      trigger={statusIcon}
      text={toTitleCase(props.status || '')}
      isInline={props.isInline !== false}
      className={classNames(styles.tooltip, props.containerClassName)}
    />
  )
}

export default SolRealtimeStatus
