import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  SecretValidationDocument,
  SecretValidationQuery,
  SecretValidationQueryVariables,
} from 'graphql/__generated__/types'
import { useState } from 'react'
import { SolConfirmationModal, SolPassword } from 'SolComponents'
import { Form, InputOnChangeData } from 'semantic-ui-react'
import styles from './PasswordContainer.module.scss'

export const PasswordValidationModal = (props: {
  isOpen: boolean
  close: () => void
  setPassword: (password: string) => void
  hash: string
  salt: string
}) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [validateSecret, { loading, data }] = useLazyQuery<SecretValidationQuery, SecretValidationQueryVariables>(
    SecretValidationDocument,
  )
  const variables = {
    options: {
      secret: value,
      secretHash: props.hash,
      secretSalt: props.salt,
    },
  }

  const verified = data?.secretVerification.verified
  useEffect(() => {
    if (verified) {
      props.setPassword(value)
      props.close()
    } else if (verified === false) {
      setError('Incorrect password')
    }
  }, [verified])

  return (
    <SolConfirmationModal
      isOpen={props.isOpen}
      modalText="Enter password"
      onAcceptingAction={() =>
        validateSecret({
          variables,
        })
      }
      acceptingText="Continue"
      onCloseModal={() => {
        setError(null)
        setValue('')
        props.close()
      }}
      acceptingDisabled={loading || !value}
    >
      <Form
        onSubmit={async e => {
          e.preventDefault()
          validateSecret({
            variables,
          })
        }}
        className={styles.modalPassword}
      >
        <Form.Field
          control={SolPassword}
          error={!!error}
          label="Dashboard password"
          value={value}
          onChange={({}, v: InputOnChangeData) => {
            setError(null)
            setValue(v.value)
          }}
          onClear={() => setValue('')}
          errorText={error}
          isDirty
          size="fluid"
        />
      </Form>
    </SolConfirmationModal>
  )
}
