import {
  Phase2Auth,
} from '../../../graphql/__generated__/types'

export const phase2PeapOptions = [
  {
    key: 0,
    value: Phase2Auth.None,
    text: 'NONE',
  },
  {
    key: 1,
    value: Phase2Auth.Mschapv2,
    text: 'MSCHAPV2'
    ,
  },
  {
    key: 2,
    value: Phase2Auth.Gtc,
    text: 'GTC',
  },
]
export const phase2TtlsOptions = [
  {
    key: 0,
    value: Phase2Auth.None,
    text: 'NONE',
  },
  {
    key: 1,
    value: Phase2Auth.Pap,
    text: 'PAP'
    ,
  },
  {
    key: 2,
    value: Phase2Auth.Mschap,
    text: 'MSCHAP'
    ,
  },
  {
    key: 3,
    value: Phase2Auth.Mschapv2,
    text: 'MSCHAPV2'
    ,
  },
  {
    key: 4,
    value: Phase2Auth.Gtc,
    text: 'GTC',
  },
]
