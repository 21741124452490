import React, { useState, useEffect } from 'react'
import { Polygon, useLeaflet } from 'react-leaflet'
import { Point, Map, Circle, LatLng, LatLngBounds, latLngBounds, LatLngExpression } from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { DisplayLocation } from '../Location'
import styles from './Map.module.scss'
import { BuildingInfoPopUp } from './BuildingInfoPopUp'
import { useLocationsPageMapQuery, ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
  reLoadData?: boolean
  clusterType: 'Pods' | 'Buildings'
  zoom: number
  setZoom: (z: number) => void
  mapBounds: number[][] | undefined
  buildingFloorMap: { [key: number]: string }
  setBuildingFloorMap: (x: { [key: number]: string }) => void
}

type UniqueAddress = {
  key: number
  bounds: LatLngBounds
  location?: DisplayLocation
  polygon: LatLngExpression[] | null
  position: LatLng | null
}

type AddressLocation = {
  [key: number]: LatLng
}

function filterPoints(addressId: number, points: LatLng[], addressLocations: AddressLocation): LatLng[] {
  return points.filter(
    point =>
      Math.abs(point.lat) > 0.0000001
      && Math.abs(point.lng) > 0.0000001
      && point.distanceTo(addressLocations[addressId]) < 500, // within 500 meters of the building
  )
}

const comparison = (a: LatLng, b: LatLng) => {
  return a.lat === b.lat ? a.lng - b.lng : a.lat - b.lat
}

const cross = (a: LatLng, b: LatLng, o: LatLng) => {
  return (a.lat - o.lat) * (b.lng - o.lng) - (a.lng - o.lng) * (b.lat - o.lat)
}

function convexHull(addressId: number, points: LatLng[], addressLocations: AddressLocation): LatLng[] {
  const pointsNearLocation = filterPoints(addressId, points, addressLocations).sort(comparison)

  let LL: LatLng[] = []
  for (let i = 0; i < pointsNearLocation.length; i++) {
    while (LL.length >= 2 && cross(LL[LL.length - 2], LL[LL.length - 1], pointsNearLocation[i]) <= 0) {
      LL.pop()
    }
    LL.push(pointsNearLocation[i])
  }

  let U: LatLng[] = []
  for (let j = pointsNearLocation.length - 1; j >= 0; j--) {
    while (U.length >= 2 && cross(U[U.length - 2], U[U.length - 1], pointsNearLocation[j]) <= 0) {
      U.pop()
    }
    U.push(pointsNearLocation[j])
  }
  LL.pop()
  U.pop()
  return LL.concat(U)
}

function averageGeolocation(addressId: number, coords: LatLng[], addressLocations: AddressLocation): LatLng | null {
  const pointsNearLocation = filterPoints(addressId, coords, addressLocations)
  let total = pointsNearLocation.length

  if (total === 1) {
    return pointsNearLocation[0]
  }

  let x = 0.0
  let y = 0.0
  let z = 0.0

  for (let i = 0; i < total; i++) {
    const { lat, lng } = pointsNearLocation[i]
    const latitude = (lat * Math.PI) / 180.0
    const longitude = (lng * Math.PI) / 180.0

    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }

  x = x / total
  y = y / total
  z = z / total

  let centralLongitude = Math.atan2(y, x)
  let centralSquareRoot = Math.sqrt(x * x + y * y)
  let centralLatitude = Math.atan2(z, centralSquareRoot)
  if (centralLatitude && centralLongitude) {
    return new LatLng((centralLatitude * 180.0) / Math.PI, (centralLongitude * 180.0) / Math.PI)
  }
  return null

}

function makeSquare(
  addressId: number,
  coords: LatLng[],
  addressLocations: AddressLocation,
  map?: Map,
): LatLng[] | null {
  const pointsNearLocation = filterPoints(addressId, coords, addressLocations)
  const newLoc = averageGeolocation(addressId, coords, addressLocations)
  if (newLoc) {
    const circle = new Circle(newLoc, 10 * (pointsNearLocation.length > 6 ? 6 : pointsNearLocation.length))
    map?.addLayer(circle)
    const bounds = circle.getBounds()
    map?.removeLayer(circle)
    return [bounds.getNorthEast(), bounds.getSouthEast(), bounds.getSouthWest(), bounds.getNorthWest()]
  }
  return null

}

function getPolygon(
  addressId: number,
  point: LatLng[],
  addressLocations: AddressLocation,
  map?: Map,
): LatLngExpression[] | null {
  const polygon = convexHull(addressId, point, addressLocations) as LatLngExpression[]
  const square = makeSquare(addressId, point, addressLocations, map) as LatLngExpression[]
  if (polygon && square) {
    return polygon.length < 4 ? square : polygon
  }
  return null

}

function getBottomRight(
  addressId: number,
  points: LatLng[],
  addressLocations: AddressLocation,
  map?: Map,
): LatLng | null {
  const newLoc = averageGeolocation(addressId, points, addressLocations)
  if (newLoc) {
    const circle = new Circle(newLoc, points.length < 4 ? 10 * points.length : 40)
    map?.addLayer(circle)
    const bounds = circle.getBounds()
    map?.removeLayer(circle)
    return bounds.getSouthEast()
  }
  return null

}

function getBoundsFromList(coords: LatLng[]): LatLngBounds {
  const lats = coords.map(c => c.lat)
  const lngs = coords.map(c => c.lng)

  const latMax = Math.max(-90.0, ...lats)
  const lngMax = Math.max(-180.0, ...lngs)
  const latMin = Math.min(90.0, ...lats)
  const lngMin = Math.min(180.0, ...lngs)

  return latLngBounds(
    new LatLng(latMin, lngMin) as LatLngExpression, // southWest
    new LatLng(latMax, lngMax) as LatLngExpression, // northEast
  )
}

function shouldDropdownOpenUpward(map?: Map) {
  return (e: MouseEvent) => {
    const point = map?.mouseEventToContainerPoint(e) as Point
    const dimensions = map?.getSize() as Point
    return dimensions.y > 0 && point?.y >= 0 && (point?.y / dimensions.y) * 100 >= 50
  }
}

export function AddressesOnMap({
  zoom,
  setZoom,
  buildingFloorMap,
  searchParamsWhere,
  setBuildingFloorMap,
}: Props) {
  const { map } = useLeaflet()

  const [uniqueAddresses, setUniqueAddresses] = useState<UniqueAddress[]>([])

  const { data, loading } = useLocationsPageMapQuery({
    variables: {
      where:
        searchParamsWhere?._and?.length === 3
          ? { _and: searchParamsWhere?._and.filter((_, i) => i < 2) }
          : searchParamsWhere,
    },
    pollInterval: 5000,
  })

  useEffect(() => {
    map?.addEventListener('zoomend', () => {
      setZoom(map?.getZoom() ?? zoom)
    })
  }, [])

  useEffect(() => {
    if (!loading && data) {
      const locs = data?._displays
        .filter(e => e.location?.address_id && e.location?.address?.lat != null && e.location?.address?.long != null)
        .reduce(
          (dict, e) => ({
            ...dict,
            [e.location?.address_id as number]: new LatLng(e.location?.address?.lat, e.location?.address?.long),
          }),
          {},
        )

      const locations: DisplayLocation[] = data?._displays.map(
        display =>
          ({
            id: display.id,
            name: display.name ?? '',
            latlng: new LatLng(display.location?.precise_lat, display.location?.precise_long),
            addressID: display.location?.address?.id ?? null,
            city: display.location?.address?.city ?? null,
            country: display.location?.address?.country ?? null,
            building: display.location?.address?.building ?? null,
            nickname: display.location?.address?.nickname ?? null,
            floor: display.location?.floor,
            numOfPodsAtAddress: display.location?.address?.assignedDisplays_aggregate?.aggregate?.count ?? null,
          } as DisplayLocation),
      )

      const addresses = Array.from(
        new Set((locations || []).filter(e => e.addressID != null).map(e => e.addressID as number)),
      )

      if (addresses.length) {
        const points = addresses.reduce(
          (acc, addressID) => ({
            ...acc,
            [addressID]: locations
              .filter(e => e.addressID === addressID)
              .map(e =>
                (Array.isArray(e.latlng) ? new LatLng(e.latlng[0], e.latlng[1], e.latlng[2]) : e.latlng),
              ) as LatLng[],
          }),
          {},
        )

        setUniqueAddresses(
          addresses
            .map(key => ({
              key,
              bounds: getBoundsFromList(points[key]),
              location: locations.find(e => e.addressID === key),
              polygon: getPolygon(key, points[key], locs, map),
              position: getBottomRight(key, points[key], locs, map),
            }))
            .filter(addr => addr.polygon),
        )
      } else {
        setUniqueAddresses([])
      }
    }
  }, [data])

  return (
    <div>
      {uniqueAddresses.map(({ key, bounds, location, polygon, position }) => (
        <Polygon
          className={styles.buildingCover}
          key={key}
          positions={polygon as LatLng[]}
          onClick={() => {
            if (zoom < 17 && map) {
              map.fitBounds(bounds, { maxZoom: 19 })
            }
          }}
        >
          {zoom >= 17 && position && (
            <BuildingInfoPopUp
              position={position}
              buildingFloorMap={buildingFloorMap}
              openItemsUpward={shouldDropdownOpenUpward(map)}
              setBuildingFloorMap={setBuildingFloorMap}
              location={location}
              searchParamsWhere={searchParamsWhere}
            />
          )}
        </Polygon>
      ))}
    </div>
  )
}

export default AddressesOnMap
