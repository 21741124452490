import React, { ReactNode } from 'react'
import { Button } from 'semantic-ui-react'

export type Props = {
  cancelDisabled?: boolean
  cancelText?: string
  cancelVisible: boolean
  ctaAction?: (args?: any) => void
  ctaDisabled?: boolean
  ctaText?: string
  ctaVisible: boolean
  modalComponent?: JSX.Element
  onCancelClicked?: () => void
  modalVisible: boolean
  props?: any
  previousAction?: () => any
  previousDisabled?: boolean
  previousText?: string
  previousVisible: boolean
  topText?: string
  children?: ReactNode
}

export const TransitionButtons = ({
  cancelDisabled,
  cancelText = 'cancel',
  cancelVisible,
  ctaAction,
  ctaDisabled,
  ctaText = 'next',
  ctaVisible,
  modalComponent,
  onCancelClicked: onCancelClicked,
  modalVisible,
  previousAction,
  previousDisabled,
  previousText = 'previous',
  previousVisible,
  topText,
  children,
}: Props) => {
  const style = {
    parent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
    } as React.CSSProperties,
    content: {
      flex: '1',
    },
    top: {
      backgroundColor: '#0087b8',
      color: 'white',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
    },
    bottom: {
      display: 'flex',
      height: '50px',
      padding: '15px',
    },
    left: {
      display: 'flex',
      alignItems: 'center',
    },
    right: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '2rem 0',
    },
  }

  return (
    <div style={style.parent}>
      {!modalVisible && topText !== undefined && (
        <div style={style.top}>
          <span>{topText}</span>
        </div>
      )}
      {modalVisible && <div style={style.modal}>{modalComponent}</div>}
      {!modalVisible && <div style={style.content}>{children}</div>}
      {!modalVisible && (
        <div style={style.bottom}>
          <span style={style.left}>
            {cancelVisible && (
              <Button basic content={cancelText.toUpperCase()} disabled={cancelDisabled} onClick={onCancelClicked} />
            )}
          </span>
          <span style={style.right}>
            {previousVisible && (
              <Button
                basic
                content={previousText.toUpperCase()}
                disabled={previousDisabled}
                onClick={previousAction}
              />
            )}
            {ctaVisible && (
              <Button color="blue" content={ctaText.toUpperCase()} disabled={ctaDisabled} onClick={ctaAction} />
            )}
          </span>
        </div>
      )}
    </div>
  )
}
