import React from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { endOfMonth, addYears } from 'date-fns'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'
import { StepHeader } from 'components/Maintenance/Renewal/Partials'
import DatePicker from 'components/Maintenance/Renewal/Steps/SelectTermDate/DatePicker/DatePicker'
import HintBox from 'components/Maintenance/Renewal/Steps/SelectTermDate/HintBox/HintBox'
import { Grid } from 'semantic-ui-react'
import { MxRenewLeadDisplayInterface } from 'shared/services/mxRenew/types'

export interface Props {
  cancelFlow: () => void
  rewindFlow: () => void
  updateLead: (
    id: MxRenewLeadId,
    update: Partial<MxRenewLeadInterface>,
    nextStep: number
  ) => void
  nextStep: number
  currentLead: MxRenewLeadInterface
  selectedPods: { [field: string]: MxRenewLeadDisplayInterface }
  numRawPods: number
  latestPodExpirationDate: Date
  earliestPodExpirationDate: Date
}

export const style = {
  wrapper: {
    // width: '100%',
  } as React.CSSProperties,
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

class SelectTermDate extends React.Component<Props> {
  render() {
    const {
      rewindFlow,
      updateLead,
      nextStep,
      cancelFlow,
      currentLead,
      selectedPods,
      numRawPods,
      latestPodExpirationDate,
      earliestPodExpirationDate,
    } = this.props

    return (
      <div style={style.wrapper}>
        <StepHeader
          rewind={rewindFlow}
          title="Select a Term Date"
          subHeader={`${
            Object.keys(selectedPods).length
          } out of ${numRawPods} eligible Pods selected for renewal.`}
          subTitle={`
          How long do you want to extend your Solstice Subscription? Choose the month
           and year and all your devices will be
          renewed to that date (we call this co-terming).
          `}
        />
        <Grid columns={2}>
          {(
            !currentLead.newPodExpiration
            || (
              endOfMonth( zonedTimeToUtc( new Date(currentLead.newPodExpiration), localTimezone) )
              < addYears(endOfMonth(zonedTimeToUtc(new Date(latestPodExpirationDate), localTimezone)), 1)
            )
          ) && (
            <Grid.Row>
              <Grid.Column width={2}>
                <div>&nbsp;</div>
              </Grid.Column>
              <Grid.Column width={14}>
                <HintBox latestPodExpirationDate={latestPodExpirationDate} />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={2}>
              <div>&nbsp;</div>
            </Grid.Column>
            <Grid.Column width={14}>
              <DatePicker
                currentLead={currentLead}
                latestExpirationDate={latestPodExpirationDate.getTime()}
                earliestExpirationDate={earliestPodExpirationDate.getTime()}
                cancelFlow={cancelFlow}
                nextStep={nextStep}
                updateLead={updateLead}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default SelectTermDate
