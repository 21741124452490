import React, { useState } from 'react'
import { SolCancelSave } from 'SolComponents'
import { SolConfirmationModal } from 'SolComponents'

export interface Props {
  next: () => void
  cancel: () => void
  nextText?: string
  disabled?: boolean
}

export interface State {
  modalOpen: boolean
}

export const style = {
  wrapper: {
    width: '100%',
  } as React.CSSProperties,
  padding: {
    paddingTop: '70px',
  } as React.CSSProperties,
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  } as React.CSSProperties,
}

function FlowButtons({ cancel, next, disabled, nextText }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div style={style.wrapper}>
      <SolConfirmationModal
        isOpen={isOpen}
        modalText="Are you sure you want to leave?"
        onAcceptingAction={() => {
          cancel()
        }}
        acceptingText="Leave"
        onCloseModal={() => setIsOpen(false)}
        cancelText="Cancel"
        subHeaderText={`Your progress won't be saved`}
      />
      <div style={style.padding}>
        <div style={style.flex}>
          <SolCancelSave
            acceptingDisabled={disabled}
            acceptingText={nextText || 'Next'}
            cancelClick={() => {
              setIsOpen(true)
            }}
            acceptingClick={next}
          />
        </div>
      </div>
    </div>
  )
}

export default FlowButtons
