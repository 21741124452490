import React from 'react'
import {
  Template,
  AdvancedConfiguration,
  UniqueSettingsConfiguration, PlatformConfigQuery, PlatformConfigDocument,
} from 'graphql/__generated__/types'
import styles from './Advanced.module.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { PreferredHDMIInputSettings } from './settings/PreferredHDMIInputSettings'
import { LLDPSettings } from './settings/LLDPSettings'
import { HDCPForHDMISettings } from './settings/HDCPForHDMISettings'
import { QoSSettings } from './settings/QoSSettings'
import { DailyRebootSettings } from './settings/DailyRebootSettings'
import { GatewayCheckSettings } from './settings/GatewayCheckSettings'
import { SolInput, SolNotification, SolTooltip } from 'SolComponents'
import UniqueSettingsInput from '../UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  templateDiff?: any
  updateConfiguration: (newSettings: AdvancedConfiguration) => void
  view?: 'Pod' | 'Templates'
  podId?: string
}

function Advanced({
  updateConfiguration,
  template,
  templateDiff,
  view,
  podId,
}: Props) {
  const {
    advancedPreferredHdmiInput: preferredHdmiInputEnabled = false,
    settings54: lldpEnabled = false,
  } = useFlags()

  const getUniqueHostName = (uSettings: UniqueSettingsConfiguration) => {
    return uSettings?.ADVANCED?.host_name ?? ''
  }

  const setUniqueHostName = (
    uSettings: UniqueSettingsConfiguration,
    host_name: string,
  ) => {
    return {
      ADVANCED: {
        ...uSettings.ADVANCED,
        host_name,
      },
    }
  }

  const configuration = template.revision.configuration as AdvancedConfiguration

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const qosSettingsLabel = `Implement QoS for Solstice Traffic${hasElementPods ? '*' : ''}`

  // Hostname is limited to a-z, A-Z, 0-9, and - (hyphen)
  const regexPatternNoSpecialChar  = /^[A-Za-z0-9-]*$/

  return (
    <div className={styles.wrapper}>
      <GatewayCheckSettings
        mismatched={templateDiff?.enableGatewayCheck === 'mismatched'}
        enableGatewayCheck={configuration?.enableGatewayCheck ?? true}
        toggleGatewayCheck={() => (
          updateConfiguration({
            ...configuration,
            enableGatewayCheck: !configuration.enableGatewayCheck,
          })
        )}
      />
      <QoSSettings
        labelText={qosSettingsLabel}
        templateDiff={templateDiff}
        configuration={configuration}
        updateConfiguration={updateConfiguration}
      />
      <DailyRebootSettings
        templateDiff={templateDiff}
        configuration={configuration}
        updateConfiguration={updateConfiguration}
      />
      {preferredHdmiInputEnabled && (
        <PreferredHDMIInputSettings
          templateDiff={templateDiff?.preferredHDMIInput}
          preferredHDMIInput={configuration.preferredHDMIInput ?? undefined}
          updatePreferredHDMIInput={preferredHDMIInput => (
            updateConfiguration({
              ...configuration,
              preferredHDMIInput,
            })
          )}
        />
      )}
      <HDCPForHDMISettings
        templateDiff={templateDiff}
        configuration={configuration}
        updateConfiguration={updateConfiguration}
      />
      {lldpEnabled && (
        <LLDPSettings
          templateDiff={templateDiff?.lldp}
          lldp={configuration.lldp ?? undefined}
          updateLLDP={lldp => (
            updateConfiguration({
              ...configuration,
              lldp,
            })
          )}
        />
      )}

      {/* "view" was supposed to drive whether the comp was presenting
      the modification of a Template or a Pod's Unique Settings */}
      {view === 'Templates' ? (
        <SolTooltip
          hoverable
          text="Hostname is a Unique Setting in the Advanced Template,
          and needs to be configured individually for every Pod."
          trigger={
            <div>
              <SolInput
                disabled
                label="Custom Hostname"
                placeholder="Unique to Pod"
              ></SolInput>
              <SolNotification type="menu" notifications="v6.1+" />
            </div>
          }
        />
      )

        : <UniqueSettingsInput // UniqueSettingsInput is equiv to  ValidationInput b/c presence of podId + name + rules
          podId={podId}
          label="Custom Hostname"
          name="host_name"
          placeholder="Unique to Pod"
          updateConfig={setUniqueHostName}
          getValue={getUniqueHostName}
          requiredIfPopulated
          rules={{
            maxLength: 32,
            pattern: regexPatternNoSpecialChar,
          }}
          notifications={[
            <SolNotification key={0} type="menu" notifications="v6.1+" />,
          ]}
        />
      }
      {hasElementPods
        && (
          <div className={styles.spanMargin}>
            <span>* Certain features are not available for Solstice Element.</span>
          </div>
        )
      }
    </div>
  )
}

export default Advanced
