// Pulled from 'uuid' in npm:

const rnds8 = new Uint8Array(16)
const bth: string[] = []
for (let i = 0; i < 256; ++i) {
  bth[i] = (i + 0x100).toString(16).substr(1)
}

function rng() {
  crypto.getRandomValues(rnds8)
  return rnds8
}

function bytesToUuid(buf: Uint8Array) {
  let i = 0
  return ([
    bth[buf[i++]], bth[buf[i++]],
    bth[buf[i++]], bth[buf[i++]], '-',
    bth[buf[i++]], bth[buf[i++]], '-',
    bth[buf[i++]], bth[buf[i++]], '-',
    bth[buf[i++]], bth[buf[i++]], '-',
    bth[buf[i++]], bth[buf[i++]],
    bth[buf[i++]], bth[buf[i++]],
    bth[buf[i++]], bth[buf[i++]],
  ]).join('')
}

function v4() {
  const rnds = rng()
  rnds[6] = (rnds[6] & 0x0f) | 0x40
  rnds[8] = (rnds[8] & 0x3f) | 0x80
  return bytesToUuid(rnds)
}

export default v4
