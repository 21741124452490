import React from 'react'
import classNames from 'classnames'
import {
  Template,
  RoomIntelligenceConfiguration,
  PlatformConfigQuery,
  PlatformConfigDocument,
} from 'graphql/__generated__/types'
import styles from './RoomIntelligence.module.scss'
import {
  SolCheckbox,
  SolLink,
  SolNotification,
  SolTooltip,
  SolTooltipWithLink,
} from 'SolComponents'
import { externalLinks } from '../../../shared/core/constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import { EndOfMeetingNotifications } from './EndOfMeetingNotifications'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: RoomIntelligenceConfiguration) => void
  podId?: string
}

function RoomIntelligence({ updateConfiguration, template }: Props) {
  const configuration = template.revision.configuration as RoomIntelligenceConfiguration
  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const {
    roomIntelligenceAutoReserve: autoReserve,
    roomIntelligenceEndOfMeeting: endOfMeeting,
  } = useFlags()

  const conferencingLabel = `Enable Solstice Conferencing${hasElementPods ? '*' : ''}`

  return (
    <div className={styles.wrapper}>
      <SolCheckbox
        label={conferencingLabel}
        checked={!!configuration.enableSolsticeConferencing}
        notification={[
          <SolTooltipWithLink
            key={0}
            trigger={<SolQuestionCircle size="small" />}
            text={[
              'Solstice Conference allows meeting hosts to wirelessly connect to the',
              'Room Camera + Mic ',
              'and share the room display with remote participants via third-party video call apps.',
            ]}
            buttonText={
              <SolLink label="LEARN MORE" url={externalLinks.roomServices} />
            }
            className={styles.questionTooltip}
          />,
          <SolNotification
            key={1}
            type="menu"
            notifications="v5.0+"
            className={styles.pill}
          />,
          <SolNotification
            key={2}
            type="menu"
            notifications="Gen 3+"
            className={classNames(styles.pill, styles.gen3)}
          />,
          ...(hasElementPods ? [<SolNotification
            key={3}
            type="menu"
            notifications="Element"
            className={classNames(styles.pill, styles.gen3)}
          />] : []),
        ]}
        onClick={() => updateConfiguration({
          ...configuration,
          enableSolsticeConferencing: !configuration.enableSolsticeConferencing,
        })}
        bold={false}
      />
      <SolCheckbox
        label="Enable occupancy sensing"
        checked={!!configuration?.occupancySettings?.enableOccupancyData ?? false}
        notification={[
          <SolTooltipWithLink
            key={0}
            trigger={<SolQuestionCircle size="small" />}
            text={[
              'When a USB camera is attached to a Pod,',
              'this feature will enable the camera to act as an occupancy sensor,',
              'Works with any USB-enabled camera.',
            ]}
            buttonText={
              <SolLink label="LEARN MORE" url={externalLinks.occupancyData} />
            }
            className={styles.questionTooltip}
          />,
          <SolNotification
            key={1}
            type="menu"
            notifications="v5.0+"
            className={styles.pill}
          />,
        ]}
        onClick={val => {
          if (!val) {
            updateConfiguration({
              ...configuration,
              occupancySettings: {
                ...configuration.occupancySettings,
                enableOccupancyData: val,
                enableAutoReserve: false,
              },
            })
          } else {
            updateConfiguration({
              ...configuration,
              occupancySettings: {
                ...configuration.occupancySettings,
                enableOccupancyData: val,
              },
            })
          }
        }}
        bold={false}
      />
      {autoReserve && (
        <SolCheckbox
          className={styles.children}
          label="Enable Auto Reserve"
          checked={!!configuration?.occupancySettings?.enableAutoReserve ?? false}
          notification={[
            <SolNotification
              key={0}
              type="beta"
              notifications="BETA"
              className={styles.pill}
            />,
            <SolTooltip
              key={1}
              isInline
              trigger={<SolQuestionCircle size="small" />}
              text={[
                'When enabled, Solstice will automatically create a calendar reservation',
                'when this room is occupied but not already reserved.',
                'When it becomes unoccupied, the calendar reservation will end automatically.',
                'This allows ad hoc meetings to be accurately represented in your calendar system',
                'reducing conflicts with other users.',
              ]}
              note="Note: this feature requires a camera plugged into the Pod and Occupancy Data enabled."
              className={styles.questionTooltip}
            />,
            <SolNotification
              key={2}
              type="menu"
              notifications="v5.3+"
              className={styles.pill}
            />,
          ]}
          disabled={!configuration?.occupancySettings?.enableOccupancyData}
          onClick={val => {
            if (configuration?.occupancySettings?.enableOccupancyData) {
              updateConfiguration({
                ...configuration,
                occupancySettings: {
                  ...configuration.occupancySettings,
                  enableAutoReserve: val,
                },
              })
            }
          }}
          bold={false}
        />
      )}
      {endOfMeeting
      && <EndOfMeetingNotifications
        configuration={configuration}
        updateConfiguration={updateConfiguration}
      />
      }
      {hasElementPods
        && (
          <div className={styles.spanMargin}>
            <span>* Certain features are not available for Solstice Element.</span>
          </div>
        )
      }
    </div>
  )
}

export default RoomIntelligence
