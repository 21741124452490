import { useState, useEffect } from 'react'
import { Page } from '../types'

export const useTablePagination = (page: Page) => {
  const params = { limit: page.size, offset: (page.number - 1) * page.size }
  const [pagination, setPagination] = useState(params)
  
  useEffect(() => {
    setPagination(params)
  }, [page.number, page.size])
  
  return {
    pagination, setPagination,
  }
}
