import React, { useState } from 'react'
import {
  SolExpandableCard,
  SolConfirmationModal,
  SolRouteGuard,
} from 'SolComponents'
import {
  Template,
  SettingsGroupType,
  CancelPendingMutation,
  TemplateCompatibility,
} from 'graphql/__generated__/types'
import SolTemplateHeader from './SolTemplateHeader'
import styles from './SolTemplateCard.module.scss'
import Calendar from 'pages/Templates/Calendar/Calendar'
import WelcomeScreen from 'pages/Templates/WelcomeScreen/WelcomeScreen'
import Ethernet from 'pages/Templates/Ethernet/Ethernet'
import Proxy from 'pages/Templates/Proxy/Proxy'
import Discovery from 'pages/Templates/Discovery/Discovery'
import TimeLocale from 'pages/Templates/TimeLocale/TimeLocale'
import Advanced from 'pages/Templates/Advanced/Advanced'
import Features from 'pages/Templates/Features/Features'
import PowerManagement from '../../../pages/Templates/PowerMgmt/PowerMgmt'
import { useDirtyTemplate } from 'shared/hooks/management/useDirtyTemplate'
import { FormValidation } from 'shared/context/FormValidation'
import { useFormContext } from 'react-hook-form'
import DigitalSignage from 'pages/Templates/DigitalSignage/DigitalSignage'
import MessageCenter from 'pages/Templates/MessageCenter/MessageCenter'
import Security from '../../../pages/Templates/Security/Security'
import Wifi from '../../../pages/Templates/WiFi/Wifi'
import { ExecutionResult } from 'graphql'
import { pluralize } from 'shared/core/utils'
import useDeepEffect from 'use-deep-compare-effect'
import RoomIntelligence from '../../../pages/Templates/RoomIntelligence/RoomIntelligence'

export interface SolTemplateCardProps {
  displayName?: string
  view: 'Pod' | 'Templates'
  locked?: boolean
  expanded?: boolean
  isPending?: boolean
  isOnline?: boolean
  template: Template
  totalAssignedDisplays?: number
  uniqueSettingsTemplate?: Template
  onDuplicateTemplate: (template: Template) => void
  deleteTemplate?: () => Promise<void>
  onCancel?: (e: React.MouseEvent) => void
  cancelPending?: (
    options?: any
  ) => Promise<ExecutionResult<CancelPendingMutation>>
  onSave: (template: Template, saveAsNewTemplate?: boolean) => Promise<Template | null>
  notifyUpdate?: () => void
  podId?: string
  refetchDisplay?: () => void
  syncState?: string
  templateCompatibility?: (Partial<TemplateCompatibility> | null)[]
  templateOptions?: Template[]
  templateDiff?: any
  loadingTemplateOptions?: boolean
}

export function SolTemplateCard(props: SolTemplateCardProps) {
  const {
    isDirty,
    resetOriginalTemplate,
    onCancel,
    updateTemplate,
    currentTemplate,
    cleanConfiguration,
  } = useDirtyTemplate(props.template)
  const [cardOpen, setCardOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [locked, setLocked] = useState(
    props.locked ?? (props.view === 'Pod' && !props.template.isCustom),
  )
  useDeepEffect(() => {
    if (!locked && cardOpen && !isSaving) {
      props.notifyUpdate?.()
    } else {
      updateTemplate(props.template)
      resetOriginalTemplate(props.template)
      setLocked(props.view === 'Pod' && !props.template.isCustom)
      setIsSaving(false)
    }
  }, [props.template])
  const isInModern
    = props?.template?.revision?.configuration?.isModern
    && props.template.settingsGroup.type === SettingsGroupType.WelcomeScreen
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [savingError, setSavingError] = useState<string | null>(null)
  const { errors } = useFormContext()
  const isFormValid
    = Object.keys(errors).filter(
      key => errors[key].types?.type !== 'unknownPassword',
    ).length === 0

  const expandedCheck = (expanded: boolean) => {
    if (!expanded && props.view === 'Pod' && !props.template.isCustom) {
      updateTemplate(props.template)
      resetOriginalTemplate(props.template)
      setLocked(true)
    }
    setCardOpen(expanded)
  }

  const handleSettingsChange = (configuration: any) => {
    const cleanedConfiguration = cleanConfiguration(configuration)
    updateTemplate({
      ...currentTemplate,
      revision: {
        ...currentTemplate.revision,
        configuration: cleanedConfiguration,
      },
    })
  }
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (props.view === 'Pod' && !props.template.isCustom) {
      setLocked(true)
    }
    onCancel()
  }

  const handleCancelPending = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation()
    const result = await props?.cancelPending?.({
      variables: {
        options: {
          displayId: props.podId + '',
          settingsGroupType: currentTemplate.settingsGroup.type,
        },
      },
    })
    const template = result?.data?.cancelPendingChanges?.assignedTemplates?.find(
      at => at?.settingsGroup?.type === props.template.settingsGroup.type,
    )?.template as Template

    if (template) {
      if (template.isCustom) {
        setLocked(false)
      }
      updateTemplate(template)
      resetOriginalTemplate(template)
    }
  }

  const saveTemplate = async ({
    saveAsNewTemplate,
    template = currentTemplate,
  }: {
    saveAsNewTemplate?: boolean
    template?: Template
  }) => {
    try {
      setIsSaving(true)
      const response = await props.onSave(template, saveAsNewTemplate)
      if (response) {
        resetOriginalTemplate(response)
        updateTemplate(response)
      } else {
        setIsSaving(false)
      }
    } catch (e) {
      setIsSaving(false)
      setSavingError(e.message)
    }
  }

  const handleSaveTemplate = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>,
  ) => {
    e.stopPropagation()
    saveTemplate({})
  }

  const handleSaveDisplayAsNewTemplate = async (newTemplateName: string) => {
    const updatedTemplate = {
      ...currentTemplate,
      name: newTemplateName,
      isCustom: false,
    }
    await saveTemplate({
      saveAsNewTemplate: true,
      template: updatedTemplate,
    })
  }

  const handleSaveAsUnassigned = async (
    e: React.MouseEvent<HTMLButtonElement>,
    configuration?: any,
  ) => {
    e.stopPropagation()
    const updatedTemplate = {
      ...currentTemplate,
      name: 'Unassigned',
      isCustom: true,
      configuration: configuration ?? currentTemplate.revision.configuration,
    }
    await saveTemplate({ template: updatedTemplate, saveAsNewTemplate: false })
  }

  const header = (
    <SolTemplateHeader
      displayName={props.displayName}
      podId={props.podId}
      isPending={props.isPending}
      syncState={props.syncState}
      handleCancelPending={handleCancelPending}
      view={props.view}
      open={cardOpen}
      locked={locked}
      isInModern={isInModern}
      isValid={isFormValid}
      setLocked={setLocked}
      dirty={isDirty}
      totalAssignedDisplays={props.totalAssignedDisplays}
      template={currentTemplate}
      updateTemplate={updateTemplate}
      handleCancel={props.onCancel || handleCancel}
      handleSaveDisplayAsNewTemplate={handleSaveDisplayAsNewTemplate}
      handleSaveDisplayAsUnassigned={handleSaveAsUnassigned}
      handleSaveTemplate={handleSaveTemplate}
      handleCreateDuplicate={props.onDuplicateTemplate}
      handleDelete={() => setDeleteModalIsOpen(true)}
      isSaving={isSaving}
      savingError={savingError}
      clearSavingError={() => setSavingError(null)}
      refetchDisplay={props.refetchDisplay}
      templateCompatibility={props.templateCompatibility}
      templateOptions={props.templateOptions}
      loadingTemplateOptions={props.loadingTemplateOptions}
      isOnline={props.isOnline}
    />
  )

  const expandableCard = (
    <SolExpandableCard
      expanded={props.expanded || false}
      content={header}
      checkExpanded={expandedCheck}
      locked={locked}
      static={(props.expanded && isDirty) || currentTemplate.id === ''}
    >
      {locked && <div className={styles.locked} />}
      {props.template.settingsGroup.type
        === SettingsGroupType.WelcomeScreen && (
        <WelcomeScreen
          template={currentTemplate}
          saveTemplate={handleSaveAsUnassigned}
          view={props.view}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Ethernet && (
        <Ethernet
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
          podId={props.podId}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Proxy && (
        <Proxy
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Advanced && (
        <Advanced
          templateDiff={props.templateDiff}
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
          view={props.view}
          podId={props.podId}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Features && (
        <Features
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Discovery && (
        <Discovery
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type
        === SettingsGroupType.DigitalSignage && (
        <DigitalSignage
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.TimeLocale && (
        <TimeLocale
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type
        === SettingsGroupType.PowerManagement && (
        <PowerManagement
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Calendar && (
        <Calendar
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
          podId={props.podId}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Security && (
        <Security
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type
        === SettingsGroupType.MessageCenter && (
        <MessageCenter
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
        />
      )}
      {props.template.settingsGroup.type === SettingsGroupType.Wifi && (
        <Wifi
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
          podId={props.podId}
        />
      )}
      {props.template.settingsGroup.type
        === SettingsGroupType.RoomIntelligence && (
        <RoomIntelligence
          template={currentTemplate}
          updateConfiguration={handleSettingsChange}
          podId={props.podId}
        />
      )}
    </SolExpandableCard>
  )

  return (
    <>
      <SolConfirmationModal
        isOpen={deleteModalIsOpen}
        modalText={
          <div className={styles.deleteModalText}>
            Are you sure you want to delete{' '}
            <strong>{props.template.name}?</strong>
          </div>
        }
        subHeaderText={`This will unassign ${pluralize(
          props.totalAssignedDisplays ?? 0,
          'pod',
        )} from "${props.template.name}" and can't be undone`}
        onAcceptingAction={() => {
          if (props.deleteTemplate) {
            props.deleteTemplate()
          }
          setDeleteModalIsOpen(false)
        }}
        onCloseModal={() => setDeleteModalIsOpen(false)}
        acceptingText="Delete"
        cancelText="Cancel"
      />
      <SolRouteGuard
        modalProps={{
          modalText: 'Are you sure you want to leave?',
          cancelText: 'Cancel',
        }}
        when={isDirty}
      />
      {expandableCard}
    </>
  )
}

export default (props: SolTemplateCardProps) => (
  <FormValidation>
    <SolTemplateCard {...props} />
  </FormValidation>
)
