import React, { useEffect } from 'react'
import L, { divIcon, DivIcon, MarkerClusterGroup } from 'leaflet'
import 'leaflet.heat'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import './styles/MarkerCluster.css'
import { useLeaflet } from 'react-leaflet'
import { abbreviateNumber, pluralize } from 'shared/core/utils'
import { renderToString } from 'react-dom/server'
import MapDot from 'SolComponents/Icons/SolGpsIcon/MapDot'
import { determineHexColor, StatusEnum } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'
import styles from './styles/Map.module.scss'
import { MarkerObject } from './MarkerObjectTypes'
import { spreadOverlappingMarkers } from './helpers'
import { getMarkerSize } from './ClusterTypes'

type MarkerType = 'Pod' | 'Building' | 'Alerts' | 'StatusPod'

const defaultMarkerIcon = renderToString(<MapDot color="#3388ff" />)

type Props = {
  markersList: MarkerObject[]
  buttonPopUp: boolean
  icon?: DivIcon
  fitBounds: boolean
  showCluster: boolean
  showAlerts?: boolean
  refreshAlertsOnZoom?: boolean
  mcg: MarkerClusterGroup
  type?: MarkerType
  onClick?: Function
}

const getDot = (status?: StatusEnum) => {
  const color = determineHexColor(status)
  return divIcon({
    html: renderToString(<MapDot color={color} />),
    className: 'dummy-class-removes-default-box',
  })
}

const createToolTip = (element: MarkerObject, markerType?: MarkerType) => {
  let tooltip: L.Tooltip = new L.Tooltip({
    direction: 'center',
    className: styles.dotTooltip,
  })
  if (markerType === 'Building') {
    tooltip = new L.Tooltip({
      direction: 'bottom',
      className: styles.buildingTooltip,
    })

    let buildingTooltip: string
    if (element.building !== element.name) {
      buildingTooltip = `<div>
                              <p><b>${element.name ?? ''}</b></p>
                              <p>${element.building ?? ''}</p>
                              <p>${pluralize(element.numOfPodsAtAddress ?? 0, 'Pod')} </p>
                        </div>`
    } else {
      buildingTooltip = `<div>
                                <p><b>${element.name ?? ''}</b></p>
                                <p>${pluralize(element.numOfPodsAtAddress ?? 0, 'Pod')} </p>
                          </div>`
    }
    tooltip.setContent(buildingTooltip)
  } else if (markerType === 'Alerts') {
    tooltip = new L.Tooltip({
      direction: 'right',
      className: styles.alertsTooltip,
    })

    /* eslint-disable-next-line max-len */
    let tooltipWithAlerts = `<div style="margin: 5px; margin-bottom: 7.5px; font-size: 13px; font-weight: bold;">${element.name ?? ''}</div>`
    if ((element.alertsOutOfTemplateCount ?? 0) > 0) {
      tooltipWithAlerts += `<div style="margin: 5px;">${element.alertsOutOfTemplateCount
      } Unassigned from Template </div>`
    }
    if ((element.alertsRestartCount ?? 0) > 0) {
      tooltipWithAlerts += `<div style="margin: 5px;">${pluralize(element.alertsRestartCount ?? 0, 'Restart')} </div>`
    }
    if ((element.alertsUnreachableCount ?? 0) > 0) {
      tooltipWithAlerts += `<div style="margin: 5px;">${pluralize(
        element.alertsUnreachableCount ?? 0,
        'Unreachable Alert',
      )}  </div>`
    }
    if ((element.alertsUsbAddedCount ?? 0) > 0) {
      tooltipWithAlerts += `<div style="margin: 5px;">${element.alertsUsbAddedCount} USB Added </div>`
    }
    if ((element.alertsUsbRemovedCount ?? 0) > 0) {
      tooltipWithAlerts += `<div style="margin: 5px;">${element.alertsUsbRemovedCount} USB Removed </div>`
    }
    tooltip.setContent(tooltipWithAlerts)
  } else {
    tooltip.setContent(element.name)
  }
  return tooltip
}

const getIcon = (element: MarkerObject, markerType?: MarkerType) => {
  if (markerType === 'Pod') {
    return divIcon({
      html: defaultMarkerIcon,
      className: 'dummy-class-removes-default-box',
    })
  } else if (markerType === 'StatusPod') {
    return getDot(element.status)
  } else if (markerType === 'Alerts') {
    const num = element.numberOfAlerts ?? 0
    return new L.DivIcon({
      html:
        '<div class=\'alerts-'
        + getMarkerSize(num)
        + '\'> <span>'
        + abbreviateNumber(num)
        + ' </span></div>',
      className: 'marker-cluster-alerts ',
    })
  }
  return undefined
}

const makeMarker = (element: MarkerObject, markerType?: MarkerType, fallbackIcon?: DivIcon) => {
  return L.marker(new L.LatLng(element.latlng.lat, element.latlng.lng), {
    icon: getIcon(element, markerType) || fallbackIcon,
    interactive: true,
    bubblingMouseEvents: true,
  })
}

const MarkerCluster = (props: Props) => {
  const { mcg } = props
  const { map } = useLeaflet()

  useEffect(() => {
    if (mcg && map) {
      if (props.showCluster) {
        mcg.clearLayers()

        spreadOverlappingMarkers(props.markersList, props.type === 'Alerts' && !props.refreshAlertsOnZoom).forEach(
          layer => {
            const marker = makeMarker(layer, props.type, props.icon)

            if (props.buttonPopUp && props.type === 'Pod') {
              marker.on('click', function() {
                window.open('/manage/pod/' + layer.id)
              })
            }
            if (!(props.type === 'Pod' && props.showAlerts)) {
              marker.bindTooltip(createToolTip(layer, props.type))
            }

            mcg.addLayer(marker)
          },
        )

        const bounds = mcg.getBounds()
        if (props.fitBounds && bounds && !props.showAlerts) {
          map?.fitBounds(bounds)
        }
        map.addLayer(mcg)
      } else {
        map?.removeLayer(mcg)
      }
    }
  }, [props.markersList, props.showCluster, props.showAlerts, props.refreshAlertsOnZoom])

  return null
}

export default MarkerCluster
