import { useDispatch } from 'react-redux'
import { showError, showSuccess } from 'shared/store/slices/alert'

interface UseAlertInterface {
  showError: (msg: string) => void
  showSuccess: (msg: string) => void
}

export function useAlerts(): UseAlertInterface {
  const dispatch = useDispatch()
  return {
    showError: (msg: string) => dispatch(showError(msg)),
    showSuccess: (msg: string) => dispatch(showSuccess(msg)),
  }
}
