import React from 'react'
import { Template, SecurityConfiguration } from 'graphql/__generated__/types'
import styles from './Security.module.scss'

import { SolGrid, SolRow, SolColumn } from 'SolComponents'
import SecurityConfig from './SecurityConfig'
import SecurityCerts from './SecurityCerts'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: SecurityConfiguration) => void
}

function Security({ updateConfiguration, template }: Props) {
  const config = template.revision.configuration as SecurityConfiguration
  return (
    <div>
      <SolGrid>
        <SolRow>
          <SolColumn lg={6} sm={12}>
            <div className={styles.configWrapper}>
              <SecurityConfig
                config={config}
                updateConfiguration={updateConfiguration}
              />
            </div>
          </SolColumn>
          <SolColumn lg={6} sm={12}>
            <div className={styles.certWrapper}>
              <SecurityCerts
                config={config}
                updateConfiguration={updateConfiguration}
              />
            </div>
          </SolColumn>
        </SolRow>
      </SolGrid>
    </div>
  )
}

export default Security
