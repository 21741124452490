import {
  Display,
  AllDisplayIdsQuery,
  AllDisplayIdsQueryVariables,
  AllDisplayIdsDocument,
} from 'graphql/__generated__/types'
import { useSelectedItems } from 'components/DataTableSlim/Hooks/useSelectedItems'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDisplaySearchOptions } from 'pages/AllPods/useDisplaySearchOptions'
import { useApolloClient } from '@apollo/client'

export const useFetchSelectedDisplayIds = () => {
  const selectedItems = useSelectedItems<Display>(DataTableInstance.AllPods)
  const displaySearchOptions = useDisplaySearchOptions()
  const { categories, searchTerm, locationFilters } = displaySearchOptions
  const client = useApolloClient()
  return async () => {
    const { data } = await client.query<
      AllDisplayIdsQuery,
      AllDisplayIdsQueryVariables
    >({
      query: AllDisplayIdsDocument,
      variables: {
        options: {
          excludeDisplayIds: selectedItems.excludedIds,
          displayIds: selectedItems.includedIds,
          searchTerm,
          categories,
          locationFilters,
          isManageable: true,
        },
      },
    })
    return data.displays?.items?.map(d => d?.id ?? '') ?? []
  }
}
