import { CategoryFilter, LocationFilter } from './../../../graphql/__generated__/types'
import { useDataTable } from './useDataTable'
import { SemanticCOLORS } from 'semantic-ui-react'
import omit from 'lodash/omit'

export interface AppliedCategoryFilter {
  categoryInternalName: string
  optionMatches: {
    [optionId: string]: { value: string; displayName?: string; addressIds?: number[] }
  }
  customFilterType?: string
}

export interface AppliedCategoryFilters {
  [dateTimestamp: string]: AppliedCategoryFilter
}

interface CustomFilterValue<CustomFilterValueType> {
  displayName: string
  value: CustomFilterValueType
}

export interface CustomFilter<CustomFilterValueType> {
  values: { [value: string]: CustomFilterValue<CustomFilterValueType> }
  color?: SemanticCOLORS
}

export interface CustomFilters<CustomFilterValueType> {
  [filterId: string]: CustomFilter<CustomFilterValueType>
}

export const useAppliedFilterOptions = (id: string) => {
  // Split location filters off from category filters!
  const { categoryFilters: { building, city, country, ...customCategories } } = useDataTable<any>(id)
    
  // Create query options from building/city/country
  const getLocationOption = (option: AppliedCategoryFilter) => {
    const matches = Object.values(option.optionMatches)
        
    const options = matches.reduce((acc: LocationFilter, match): LocationFilter => {
      if (match.value === 'unassigned') {
        acc.includeNull = true
      } else if (match.addressIds) {
        acc.idIn = [...(acc.idIn ?? []), ...match.addressIds]
          .filter((val, index, array) => index === array.indexOf(val))// send only unique
          .sort()
      }
      return acc
    }, {})
    
    return options
  }
  const locationFilters: {
        building?: LocationFilter
        city?: LocationFilter
        country?: LocationFilter
      } = (() => {
        const options: Record<string, LocationFilter> = {}
        if (building) {
          options.building = getLocationOption(building)
        }
        if (city) {
          options.city = getLocationOption(city)
        }
        if (country) {
          options.country = getLocationOption(country)
        }
        return options
      })()
    
  // Create query options from the custom (non-location) categories
  const categoryFilters = (() => {
    const categories = Object.values(customCategories).map((customCategory): CategoryFilter => ({
      id: customCategory.categoryInternalName,
      optionIds: Object.values(customCategory.optionMatches)
        .filter(match => match.value !== 'unassigned').map(match => match.value),
      includeUnassigned: !!customCategory.optionMatches.unassigned,
    }))
    return categories
  })()

  return {
    ...(categoryFilters.length > 0 ? { categoryFilters } : {}),
    ...((locationFilters.building || locationFilters.city || locationFilters.country) ? { locationFilters } : {}),
  }
}


export const useHasuraFilters = (id: string): CategoryFilter[] | undefined => {
  const table = useDataTable<any>(id)
  const { categoryFilters } = table
  if (!Object.keys(categoryFilters)?.length) {
    return undefined
  }

  return Object.keys(categoryFilters).map(categoryId => {
    const optionIds = Object.keys(
      categoryFilters[categoryId].optionMatches || {},
    )
    return {
      id: categoryId,
      optionIds: optionIds.filter(o => o !== 'unassigned'),
      includeUnassigned: optionIds.includes('unassigned'),
    }
  })
}

export const useCustomFilters = <CustomFilterValueType>(tableId: string) => {
  const customFilters = useDataTable<any, CustomFilterValueType>(tableId)
    .customFilters as CustomFilters<CustomFilterValueType>
  const { setCustomFilters } = useDataTable<any>(tableId)

  const removeCustomFilter = (filterId: string) =>
    setCustomFilters(omit(customFilters, filterId))

  const setCustomFilter = (
    filterId: string,
    newCustomFilter: Partial<CustomFilter<CustomFilterValueType>>,
  ) =>
    setCustomFilters({
      ...customFilters,
      [filterId]: {
        ...customFilters[filterId],
        ...newCustomFilter,
      },
    })

  return {
    customFilters,
    removeCustomFilter,
    setCustomFilter,
  }
}
