import React, { useState } from 'react'
import { TimeInterval } from 'graphql/__generated__/types'
import TopRow, { DateRange } from './TopRow'
import HomePageUsageHistory from './Charts/HomePageUsageHistory'
import AlertsChart from './Charts/AlertsChart/AlertsChart'
import { HomeMap } from './Map/Map'
import { Period } from 'components/Charts/HistoryChart/types'
import LoadingBar from 'components/Loaders/LoadingBar'
import styles from './index.module.scss'
import HomePageCards from './Cards'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { format, subDays, startOfDay } from 'date-fns'

export const id = 'home'

const HomePage = () => {
  const [usageReady, setUsageReady] = useState(false)
  const [devicesReady, setDevicesReady] = useState(false)
  const [alertsReady, setAlertsReady] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>({
    fromDate: format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    interval: TimeInterval.Week,
  })

  const handleIntervalChange = (days: number) => {
    let interval = TimeInterval.Day
    let fromDate = format(startOfDay(subDays(new Date(), days)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')

    const toDate = format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')

    if (days === Period.MONTH) {
      interval = TimeInterval.Week
    } else {
      if (days !== Period.WEEK) {
        interval = TimeInterval.Month
        fromDate = format(startOfDay(subDays(new Date(), 90)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
      }
    }

    setDateRange({
      fromDate,
      toDate,
      interval,
    })
  }

  return (
    <div>
      <LoadingBar visible={!usageReady || !devicesReady || !alertsReady} />
      <ContentWrapper>
        <TopRow dateRange={dateRange} onChange={handleIntervalChange} />
        <div className={styles.roomLocationsMap}>
          <HomeMap doneLoading={setDevicesReady} dateRange={dateRange} />
        </div>

        <HomePageCards setDevicesReady={setDevicesReady} dateRange={dateRange} />

        <div className={styles.charts}>
          <div className={styles.usageHistory}>
            <HomePageUsageHistory dateRange={dateRange} doneLoading={() => setUsageReady(true)} />
          </div>
          <div className={styles.alerts}>
            <AlertsChart doneLoading={() => setAlertsReady(true)} dateRange={dateRange} />
          </div>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default HomePage
