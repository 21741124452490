import { Display, ManagementStatus } from 'graphql/__generated__/types'
import {
  useDisplaysActionsEligibilityQuery,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import {
  searchByNameMapper,
  useWhereFilter,
} from 'components/DataTableSlim/Hooks/useSearchDisplays'
import { getSearchIdArgs } from 'components/DataTableSlim/Utils/hasuraUtils'
import { useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'

export enum ActionsEligibility {
  Loading = 'Loading',
  Offline = 'Offline',
  UnsupportedFirmware = 'UnsupportedFirmware',
  Eligible = 'Eligible'
}

export const usePodActionsEligibility = (podId?: string) => {
  const { searchValue, selectedItems } = useDataTable<Display>(
    DataTableInstance.AllPods,
  )
  const { where: whereFilter } = useWhereFilter({
    search: searchValue,
    searchMapper: searchByNameMapper,
    tableInstance: DataTableInstance.AllPods,
  })
  const { customFilters } = useCustomFilters<ManagementStatus>(
    DataTableInstance.AllPods,
  )
  const searchIdArgs = getSearchIdArgs(selectedItems)
  let where: ViewDisplaysBoolExp = {
    _and: [whereFilter, searchIdArgs],
  }
  if (customFilters.managementStatus) {
    const statuses = Object.values(customFilters.managementStatus.values).map(
      v => v.value,
    )
    where._and?.push({
      management_status: {
        status: {
          _in: statuses,
        },
      },
    })
  }
  if (podId) {
    where = { id: { _eq: podId } }
  }

  const { data, loading } = useDisplaysActionsEligibilityQuery({
    variables: {
      where,
    },
    pollInterval: 10000,
  })

  if (loading) {
    return { eligibility: ActionsEligibility.Loading }
  }
  if (data?.offline.aggregate?.count ?? 0 > 0) {
    const podName = data?.offlinePod?.[0]?.name ?? undefined
    return { eligibility: ActionsEligibility.Offline, podName }
  }
  if (data?.unsupportedFirmware.aggregate?.count ?? 0 > 0) {
    return { eligibility: ActionsEligibility.UnsupportedFirmware }
  }
  return { eligibility: ActionsEligibility.Eligible }
}
