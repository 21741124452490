import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  UpdateablePodsQuery,
  UpdateablePodsOptions,
  useUpdateablePodsQuery,
} from 'graphql/__generated__/types'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { UpdateablePod } from '../types'
import { QueryResult } from '@apollo/client'
import { useSelectedPodsOptions } from 'components/DataTableSlim/Hooks/useSelectedItems'
import { useUpdateablePodsOptions } from './useUpdatablePodsOptions'

/**
 * Constructs a barebones and unpaginated UpdateablePodsOptions from the current state of the filters on the updates table.
 * Used to construct queries for an Apollo UpdateablePodsQuery
 * @returns
 */
export const useSelectedPodsArgs = (): UpdateablePodsOptions => {
  const { // rip out orderBy, offset, and limit clauses because we don't want those
    orderBy: _orderBy,
    offset: _offset,
    limit: _limit,
    ...updateablePodsOptions
  } = useUpdateablePodsOptions()

  const options: UpdateablePodsOptions = {
    updateable: true,
    isIncludedElementPods: true,
    ...useSelectedPodsOptions(DataTableInstance.Updates),
    ...updateablePodsOptions,
  }

  return options
}

export const useNumSelectedPods = (totalSelectablePods: number): number => {
  const { selectedItems } = useDataTable<UpdateablePod>(DataTableInstance.Updates)

  const idsIn = selectedItems?.includedIds ?? []
  const idsNotIn = selectedItems?.excludedIds ?? []

  if (idsNotIn.includes('*')) {
    return 0
  }

  if (idsIn.includes('*')) {
    return Math.max(0, totalSelectablePods - idsNotIn.length)
  }

  return idsIn.filter(i => !idsNotIn.includes(i)).length
}

export const useGetAllSelectedPods = (): QueryResult<UpdateablePodsQuery> => {
  const options = useSelectedPodsArgs()

  return useUpdateablePodsQuery({
    variables: {
      options,
    },
  })
}
