import React, { useState } from 'react'
import { StepHeader, FlowButtons, StepSpinner } from 'components/Maintenance/Renewal/Partials'
import Table from './Table/index'
import { useTable } from './useTable'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'
import { getRenewFlowSelectedPods } from '../getSelectedPods'
import { useApolloClient } from '@apollo/client'
import {
  ExpiredDisplaysDocument,
  ExpiredDisplaysQuery,
  ExpiredDisplaysQueryVariables,
} from 'graphql/__generated__/hasura-types'
import styles from './index.module.scss'

type Props = {
  hasOtherPods: boolean
  hasExpiredPods: boolean
  hasSelectedExpiredPods: boolean
  cancelFlow: () => void
  rewindFlow: () => void
  updateLead: (id: MxRenewLeadId, update: MxRenewLeadInterface, nextStep: number) => void
  nextStep: number
  currentLead: MxRenewLeadInterface
}

const SelectOtherDisplay = ({
  rewindFlow,
  hasExpiredPods,
  hasSelectedExpiredPods,
  cancelFlow,
  updateLead,
  currentLead,
  nextStep,
}: Props) => {
  const { query } = useApolloClient()

  const [isExpiring, setIsExpiring] = useState(true)
  const [isLoadingPods, setIsLoadingPods] = useState(false)

  const { tableData, isLoading, totalItems, totalItemsSelected, handleOrderBy, selectedItemsWhere } = useTable(
    isExpiring,
  )

  const getSelectedOtherPods = async () => {
    if (isExpiring) {
      selectedItemsWhere._and?.push({
        subscription: {
          is_in_subscription: { _eq: true },
          is_expiring_soon: { _eq: true },
        },
      })
    } else {
      selectedItemsWhere._and?.push({
        subscription: { is_in_subscription: { _eq: true } },
      })
    }
    const { data: expiredSelectedData } = await query<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>({
      query: ExpiredDisplaysDocument,
      variables: {
        where: selectedItemsWhere,
      },
    })
    const selectedOtherPods = getRenewFlowSelectedPods(expiredSelectedData?._displays)
    return selectedOtherPods
  }

  const handleNext = async () => {
    setIsLoadingPods(true)
    const selectedOtherPods = await getSelectedOtherPods()
    await updateLead(
      currentLead.id,
      {
        ...currentLead,
        selectedOtherPods: selectedOtherPods,
        step: nextStep,
      },
      nextStep,
    )
  }

  const handleCancel = async () => {
    const selectedOtherPods = await getSelectedOtherPods()
    await updateLead(
      currentLead.id,
      {
        ...currentLead,
        orderStatus: 'cancelled',
        selectedOtherPods: selectedOtherPods,
      },
      nextStep - 1,
    )
    cancelFlow()
  }

  const handleExpiringToggle = (isExp: boolean) => {
    setIsExpiring(isExp)
  }

  let subTitle = `It looks like some Pods are nearing their Solstice Subscription expiration date.
              Select any of the Pods below to add them to your quote.`

  if (!isExpiring) {
    subTitle = `Select any of the devices below to add them to your Solstice Subscription renewal quote.`
  }

  if (isLoadingPods) {
    return <StepSpinner />
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.leftCol}>
          <StepHeader
            showBackButton={hasExpiredPods}
            rewind={rewindFlow}
            title="Co-Term with Additional Pods"
            subTitle={subTitle}
          />
        </div>
        <div className={styles.actions}>
          <FlowButtons
            disabled={totalItemsSelected === 0 && !hasSelectedExpiredPods}
            next={handleNext}
            cancel={handleCancel}
          />
        </div>
      </div>
      <Table
        data={tableData || []}
        loading={isLoading}
        totalItems={totalItems}
        totalItemsSelectable={totalItems}
        totalItemsSelected={totalItemsSelected}
        onSort={handleOrderBy}
        handleToggle={handleExpiringToggle}
        isExpiring={isExpiring}
      />
    </div>
  )
}

SelectOtherDisplay.defaultProps = {
  hasOtherPods: false,
  hasExpiredPods: false,
  hasSelectedExpiredPods: false,
}

export default SelectOtherDisplay
