import React, { useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import {
  TimeInterval,
  MetricType,
  MetricsByDate,
  Display,
  DisplayOrderableField,
  DisplaySearchOptions, PlatformConfigQuery, PlatformConfigDocument,
} from 'graphql/__generated__/types'

import { Option, ChartTimePeriod } from 'SolComponents/SolChart/types'
import { getPreviousDate } from 'SolComponents/SolChart/chartUtils'
import MetricTable from './MetricTable'
import UsageChart from './UsageChart'
import { secondaryOptions as allMetricsAvailable } from './chartUtils'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { AxisOptions } from './types'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { ComputeMetrics } from 'shared/domain/metric'
import { useTable } from './useTable.hook'
import { OptionDate } from 'shared/types/OptionDate'
import { SolFilterHeaderCard } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

interface InitialState {
  metrics: AxisOptions[]
}

export const selectOptions = (metrics: AxisOptions[]): Option[] =>
  metrics.map(
    metric =>
      allMetricsAvailable
        .filter(metricAvailable => metric === metricAvailable.value)
        .map(
          metricAvailable =>
            ({
              internalName: metricAvailable.value,
              displayName: metricAvailable.text,
            } as Option),
        )[0],
  )

export const initialState: InitialState = {
  metrics: [MetricType.Meetings, MetricType.Users],
}

const metricTypeValues = Object.values<string>({
  ...MetricType,
  ...ComputeMetrics,
})

/**
 * Metric by Hour does not exist in the BE. This function replaces goes through the result and
 * replace the metric result that has MINUTES_IN_USE as name for HOURS_IN_USE
 */
const standarizeChartData = (data: MetricsByDate[] = [], optionSelected: AxisOptions[]) => {
  if (optionSelected.includes(ComputeMetrics.Hour)) {
    return data.map(res => {
      const updateMetrics = res.metrics.map(metric => {
        if (metric?.name === MetricType.MinutesInUse) {
          return {
            ...metric,
            name: ComputeMetrics.Hour,
            total: metric.total / 60,
          }
        }
        return metric
      })
      return { ...res, metrics: updateMetrics }
    })
  }
  return data
}

const getEmphasizeList = (metrics: AxisOptions[]) =>
  metrics.reduce((acc, met) => {
    if (metricTypeValues.includes(met)) {
      return [...acc, { name: met, emphasize: true }]
    }
    return acc
  }, [])

const UsageHistoryPage = () => {
  const [state, setState] = useState(initialState)
  const { metrics } = state
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(TimeInterval.Day)
  const [timePeriod, setTimePeriod] = useState<ChartTimePeriod>(ChartTimePeriod.MONTH)
  const [optionDate, setDate] = useState({
    fromDate: format( getPreviousDate( timePeriod, new Date() ), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format( new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX' ),
  })

  const { page, searchValue: searchTerm } = useDataTable<Display>(DataTableInstance.UsageHistory, {
    defaultSelectAll: true,
  })

  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.UsageHistory)

  const updateState = (newState: Partial<InitialState>) => setState(current => ({ ...current, ...newState }))

  const handleChangeMetric = (axisSelected: AxisOptions[]) => updateState({ metrics: axisSelected })

  const handleChangeTimePeriod = (period: ChartTimePeriod, range?: OptionDate) => {
    if (range) {
      if (period === ChartTimePeriod.CUSTOM) {
        setDate({ fromDate: range.fromDate, toDate: range.toDate })
      }
      if (period !== ChartTimePeriod.CUSTOM) {
        setDate({ ...range })
      }
    }
    setTimePeriod(period)
  }

  const {
    totalItemsSelectable,
    totalItemsSelected,
    totalFilteredItems,
    chartData,
    graphStatus,
    tableQueryResponse,
    tableStatus,
    totalDisplayStatus,
    handleOnSort,
    orderByParam,
  } = useTable({
    optionDate: {
      fromDate: optionDate.fromDate,
      toDate: optionDate.toDate,
    },
    metrics,
    timeInterval,
  })

  const displaySearchOptions: DisplaySearchOptions = {
    records: page.size,
    searchTerm,
    page: page.number,
    orderBy: orderByParam,
    metrics: optionDate,
    categories: categoryFilters,
    locationFilters: locationFilters,
  }

  const netStatus = [tableStatus, graphStatus, totalDisplayStatus]
  const isLoading = netStatus.includes(NetworkStatus.loading) || netStatus.includes(NetworkStatus.setVariables)

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).
    data?.
    platformConfig?.
    hasElementPods

  return (
    <div>
      <SolFilterHeaderCard
        header="Usage History"
        filteredItems={totalItemsSelected}
        tableId={DataTableInstance.UsageHistory}
        dateRange={{ include: true }}
        setTimeInterval={setTimeInterval}
        timeInterval={timeInterval}
        handleIntervalChange={handleChangeTimePeriod}
      />
      <UsageChart
        data={standarizeChartData((chartData?.metricsByDate || []) as MetricsByDate[], metrics) as MetricsByDate[]}
        interval={timeInterval}
        setInterval={setTimeInterval}
        options={selectOptions(metrics)}
        timePeriod={timePeriod}
        onChangeMetrics={handleChangeMetric}
        hasElementPods={hasElementPods}
      />
      <ContentWrapper>
        <MetricTable
          id={DataTableInstance.UsageHistory}
          columnsOrder={[{ name: DisplayOrderableField.Subscription }, { name: 'name' }, ...getEmphasizeList(metrics)]}
          onSort={handleOnSort}
          totalItems={totalFilteredItems}
          data={(tableQueryResponse?.displays?.items || []) as Display[]}
          displaySearchOptions={displaySearchOptions}
          loading={isLoading}
          totalItemsSelectable={totalItemsSelectable}
          totalItemsSelected={totalItemsSelected}
          selectable
        />
      </ContentWrapper>
    </div>
  )
}

export default UsageHistoryPage
