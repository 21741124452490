import {
  Template,
  WifiMode,
  SettingsGroupType,
  IpType,
  WapSecurityProtocol,
  EapMethod,
  Phase2Auth,
  WifiConfiguration,
} from 'graphql/__generated__/types'

export enum SecurityProtocol {
  Open = 'open',
  Wep = 'wep',
  Wpa = 'wpa',
  '802.1x' = '802.1x'
}

import { DefaultTemplate } from '../TemplateTypes'

export const defaultWifiTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Wifi',
    type: SettingsGroupType.Wifi,
    hidden: false,
  },
  assignedDisplays: {
    totalRecords: 0,
  },
  revision: {
    id: '',
    configuration: {
      enabled: true,
      allowAdmin: true,
      mode: WifiMode.WifiClient,
      wapSettings: {
        hideSsid: false, // not gen 2 supported
        securityProtocol: WapSecurityProtocol.Open,
        wpa2password: {
          secret: '',
        },
        channel: 6,
      },
      wifiClientSettings: {
        ssid: '',
        securityProtocol: SecurityProtocol.Open,
        ipConfiguration: {
          ipType: IpType.Dhcp,
          gateway: '',
          networkPrefixLength: 24,
          dns1: '',
          dns2: '',
        },
        wepPassword: {
          secret: '',
        },
        enterpriseSettings: {
          eapMethod: EapMethod.Peap,
          phase2Auth: Phase2Auth.None,
          caCertificate: {
            name: '',
            content: '',
          },
          userCertificate: {
            name: '',
            content: {
              secret: '',
            },
          },
          identity: '',
          password: {
            secret: '',
          },
        },
      },
    } as WifiConfiguration,
  },
}
