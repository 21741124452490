import React from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  Display,
  SubscriptionStatus,
  DisplayOrderableField,
} from 'graphql/__generated__/types'
import {
  Categorizable,
  Column,
  DataTableInstance,
  OrderBy,
} from 'components/DataTableSlim/DataTableSlim'
import classNames from 'classnames'
import { roundNumber } from 'shared/core/utils'
import { ComputeMetrics, metricLabels } from 'shared/domain/metric'
import { OutOfSubscriptionCell } from 'components/DataTableSlim/Custom/OutOfSubscriptionCell'
import styles from './index.module.scss'
import { SolsticeElementEnum } from '../../../shared/enums'
import { SolsticeElementCell } from '../../../components/DataTableSlim/Custom/SolsticeElementCell'

interface SharingTableProps {
  totalItems: number
  totalItemsSelectable?: number
  totalItemsSelected?: number
  data: Display[]
  loading: boolean
  onSort: (orderBy: OrderBy) => void
}

const getColumns = () =>
  [
    {
      name: DisplayOrderableField.Subscription,
      displayName: 'Status',
      centered: true,
      collapsing: true,
      addable: false,
      render: r => {
        if (r?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return (
            <SolsticeElementCell
              message="Certain features are not available for Solstice Element"
              position="bottom left"
            />
          )
        }

        if (r?.subscription?.status === SubscriptionStatus.Expired) {
          return <OutOfSubscriptionCell />
        }
        return ''
      },
    },
    {
      name: 'name',
      addable: false,
      render: row => (
        <span
          className={classNames({
            greyData: row?.subscription?.status === SubscriptionStatus.Expired,
          })}
        >
          {row.name}
        </span>
      ),
    },
    {
      name: DisplayOrderableField.Cam1,
      displayName: 'Camera',
      addable: false,
      render: row => (
        <span
          className={classNames({
            greyData: row?.subscription?.status === SubscriptionStatus.Expired,
          })}
        >
          {row?.displayCam?.cam1}
        </span>
      ),
    },
    {
      name: DisplayOrderableField.ConfSessions,
      displayName: 'Conferencing Sessions',
      render: row => {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return <div className="greyBlur">Not Available</div>
        }

        if (row?.subscription?.status === SubscriptionStatus.Expired) {
          return <div className="greyBlur">Out of Subscription</div>
        }

        return row?.metrics?.totalConfSessions || 0
      },
    },
    {
      name: DisplayOrderableField.Meetings,
      displayName: 'Meetings',
      render: row => {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return <div className="greyBlur">Not Available</div>
        }

        if (row?.subscription?.status === SubscriptionStatus.Expired) {
          return <div className="greyBlur">Out of Subscription</div>
        }

        return row?.metrics?.totalMeetings || 0
      },
    },
    {
      name: ComputeMetrics.Hour,
      displayName: metricLabels[ComputeMetrics.Hour],
      render: row => {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return <div className="greyBlur">Not Available</div>
        }

        if (row?.subscription?.status === SubscriptionStatus.Expired) {
          return <div className="greyBlur">Out of Subscription</div>
        }

        return roundNumber((row?.metrics?.totalMinutesInUse || 0) / 60, 2)
      },
    },
  ] as Column<Categorizable<Display>>[]

const selectableFunction = (row: Categorizable<Display>) => {
  return row?.license?.shortName !== SolsticeElementEnum.licenseShortName
}

const SharingTable = ({
  totalItems,
  data,
  loading,
  totalItemsSelected,
  totalItemsSelectable,
  onSort,
}: SharingTableProps) => {
  return (
    <div className={styles.table}>
      {ServerDataTable<Categorizable<Display>>({
        id: DataTableInstance.Sharing,
        columns: getColumns(),
        data,
        title: 'In-Room Tech',
        totalItems,
        onSort,
        loading,
        addableColumns: false,
        allowExportToCsv: false,
        selectable: selectableFunction,
        defaultSelectAll: true,
        totalItemsSelected,
        totalItemsSelectable,
      })}
    </div>
  )
}

export default SharingTable
