import React, { useState } from 'react'
import SolDropdown from 'SolComponents/SolDropdown/SolDropdown'
import FlashIcon from 'mdi-react/FlashIcon'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styles from './SolPodActions.module.scss'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import { DisplayVersions, TaskType } from 'graphql/__generated__/types'
import { SolPodActionConfirmationModal, getSolPodActionOptions } from './solPodActionOptions'
import {
  ActionsEligibility,
  usePodActionsEligibility,
} from 'SolComponents/SolDropdown/SolPodActions/usePodActionsEligibility'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { Display } from 'graphql/__generated__/types'
import { resetOnSelectState } from '../../../components/DataTableSlim/Utils'

const getTooltipText = (actionsEligibility: ActionsEligibility, podName?: string) => {
  if (actionsEligibility === ActionsEligibility.Offline) {
    return podName === undefined
      ? 'One or more Pods selected are offline and are ineligible for most actions.'
      : `${podName} is currently offline and is ineligible for most actions.`
  }
  if (actionsEligibility === ActionsEligibility.UnsupportedFirmware) {
    return 'Unsupported firmware for initiating actions.'
  }
  return null
}

type Props = {
  // if individual assignment via Pod Details page
  podId?: string
  podName?: string
  versions?: DisplayVersions
  serialId?: string
  // if bulk assignment via All Pods page
  totalItemsSelected?: number

  taskTypes: Array<TaskType>
}

const Trigger = () => (
  <div className={classNames(styles.trigger, 'joyride-pod-actions')}>
    <FlashIcon className={styles.icon} />
    Pod Actions
  </div>
)

export const SolPodActions = (props: Props) => {
  const { forgetWifiNetworks = false } = useFlags()
  const [confirmationModalType, setConfirmationModalType] = useState<TaskType | undefined>(undefined)
  const { eligibility: selectedPodsEligibility, podName: ineligiblePodName } = usePodActionsEligibility(props.podId)

  const selectAction = (value: TaskType) => {
    setConfirmationModalType(value)
  }

  const { setSelectedItems, selectedItems } = useDataTable<Display>(DataTableInstance.AllPods)
  const unselectRows = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  const tooltip = getTooltipText(selectedPodsEligibility, ineligiblePodName)
  const noneSelected = (!props.podId && !selectedItems.includedIds?.length) || props.totalItemsSelected === 0

  const dropdown = (
    <SolDropdown
      itemsLabel="Pods"
      type={props.podId ? 'caret' : 'bulk'}
      direction="left"
      itemsSelected={props.totalItemsSelected}
      options={
        getSolPodActionOptions({
          taskTypes: props.taskTypes,
          podVersion: props.versions?.software?.toString(),
          count: props.totalItemsSelected,
          disabled: selectedPodsEligibility !== ActionsEligibility.Eligible,
        })
          .filter(option => forgetWifiNetworks || option.value !== TaskType.ForgetWifiNetworks)
      }
      containerClassName={styles.dropdownContainer}
      className={styles.dropdown}
      trigger={<Trigger />}
      menu
      value={null}
      disabled={noneSelected}
      onChange={selectAction}
      onCancel={unselectRows}
    />
  )

  return (
    <>
      {confirmationModalType !== undefined && (<SolPodActionConfirmationModal
        podId={props.podId}
        podName={props.podName}
        podVersion={props.versions?.software?.toString()}
        serialId={props.serialId}
        podCount={props.totalItemsSelected}
        taskType={confirmationModalType}
        onClose={() => setConfirmationModalType(undefined)}
        onAccept={() => {
          setSelectedItems(
            resetOnSelectState(),
          )
          setConfirmationModalType(undefined)
        }}
      />)}
      {!!tooltip && !noneSelected ? (
        <SolTooltip text={tooltip} trigger={dropdown} className={styles.tooltip} />
      ) : (
        dropdown
      )}
    </>
  )
}
