import React from 'react'
import styles from './index.module.scss'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import Filter from 'components/DataTableSlim/Filter'

type Props = {
  clearVisiblePods: () => void
  filterbyVisiblePods: boolean
  selectedPodCount: number
  totalPodCount: number
}

const FilterHeader = (props: Props) => {
  const VisiblePodsFilter = () => {
    if (props.filterbyVisiblePods) {
      return (
        <>
          <Filter
            key="VisibleOnMap"
            optionDisplayName="Visible on Map"
            color="grey"
            className={styles.pill}
            onRemoveFilter={() => props.clearVisiblePods()}
          />
        </>
      )
    }
    return <div></div>
    
  }

  return (
    <SolFilterHeaderCard
      header="Realtime"
      filteredItems={props.selectedPodCount}
      tableId={DataTableInstance.Realtime}
      customFilter={<VisiblePodsFilter />}
      className={styles.header}
    />
  )
}

export default FilterHeader
