import React from 'react'
import { Button, Segment } from 'semantic-ui-react'

interface Props {
  modalHide: (cancel: boolean) => void
  cancelAction: () => void
}

export const CancelModal = ({
  cancelAction,
  modalHide,
}: Props) => {
  const style = {
    segment: {
      height: '160px',
      width: '340px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    } as React.CSSProperties,
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    } as React.CSSProperties,
    flexy: {
      flex: '1',
    },
  }

  return (
    <Segment color="blue" style={style.segment}>
      <h3>Are you sure you want to cancel?</h3>
      <p>Your changes haven't been applied yet.</p>
      <div style={style.flexy} />
      <div style={style.buttons}>
        <Button basic color="grey" content="CANCEL" onClick={cancelAction} style={style.flexy} />
        <Button color="blue" content="CONTINUE" onClick={() => modalHide(true)} style={style.flexy} />
      </div>
    </Segment>
  )

}

export default CancelModal
