import React from 'react'
import { Header } from 'semantic-ui-react'

import { SolCard } from 'SolComponents'
import Chart from 'SolComponents/SolChart/Components/Chart'
import styles from './index.module.scss'
import { ViewHybridMeetingAggregationResult } from 'graphql/__generated__/hasura-types'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import { TimeInterval } from 'graphql/__generated__/types'
import { ChartDataPoint } from 'SolComponents/SolChart/types'
import { formatTime } from 'SolComponents/SolChart/chartUtils'
import SolChartLegend from 'SolComponents/SolChartLegend/SolChartLegend'

interface Props {
  data: ViewHybridMeetingAggregationResult[]
  aspectRatio?: number
  testId: string
  isLoading: boolean
  interval: TimeInterval
  toDate?: Date
}

const HYBRID_COLOR = '#EE8695'
const PROXIMATE_COLOR = '#A73B8F'

const lines = [
  {
    id: 'HYBRID',
    name: 'Hybrid',
    color: HYBRID_COLOR,
    checked: true,
    right: false,
  },
  {
    id: 'PROXIMATE',
    name: 'Proximate',
    color: PROXIMATE_COLOR,
    checked: true,
    right: false,
  },
]

export const mapGraphqlData = (
  data: ViewHybridMeetingAggregationResult[],
  timeInterval: TimeInterval,
  toDate?: Date,
): ChartDataPoint[] => {
  return data.map(item => ({
    interval: formatTime(timeInterval, (new Date(item.date)), toDate),
    Hybrid: item.total_remote_meetings,
    Proximate: item.total_proximate_meetings,
  }))
}

const HybridProximateCard = ({ data, testId, isLoading, interval, toDate }: Props) => {
  return (
    <div data-testid={testId} className={styles.confCard}>
      <SolCard className={styles.proximateCard}>
        <div className={styles.header}>
          <Header as="h3" textAlign="left" content="Proximate vs. Hybrid Meetings" />
        </div>
        <LoadingWrapper isEmpty={!data || data.length === 0} isLoading={isLoading}>
          <div className={styles.meetingsContent}>
            <Chart
              data={mapGraphqlData(data, interval, toDate)}
              options={lines}
              lineType="linear"
              strokeWidth={1.5}
              className="modified-position"
            />
          </div>
          <div className={styles.meetingsLegend}>
            <SolChartLegend
              labels={[
                { id: 'HYBRID', text: 'Hybrid', color: HYBRID_COLOR },
                { id: 'PROXIMATE', text: 'Proximate', color: PROXIMATE_COLOR },
              ]}
            />
          </div>
        </LoadingWrapper>
      </SolCard>
    </div>
  )
}

export default HybridProximateCard
