import React, { useState } from 'react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import classNames from 'classnames'
import { cool, grays } from 'assets/palettes'
import styles from './SolDonut.module.scss'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

export const emptyPieData = grays.map(fill => ({
  count: 1,
  fill,
}))

export interface PieSector {
  name?: string
  count: number
  percentage?: number
  fill?: string
  onClick?: () => void
  labelColor?: string
}

export interface Props {
  pieData: PieSector[]
  verticalAlign: 'top' | 'middle' | 'bottom'
  chartHeight: number
  aspectRatio?: number
  innerRadius?: number | string // recharts prop that controls white space inside pie chart
  outerRadius?: number | string
  xPosition: number | string // position of pie on page in relation to container
  yPosition?: number | string
  legendWidth?: number | string
  legendAlignment: 'left' | 'center' | 'right'
  legendTitle?: string | JSX.Element
  responsiveWidth?: string
  showCount?: boolean
  layout?: 'horizontal' | 'vertical'
  isAnimationActive?: boolean
  tooltip?: { formatter?: any }
  hideTooltip?: boolean
  paging?: boolean
  staticChart?: boolean
  minWidth?: number
  externalLegend?: boolean
}

export interface LegendProps {
  legendData: PieSector[]
  showCount: boolean
  paging?: boolean
  sliceData: any[]
  externalLegend: boolean
}

const CustomLegend = ({
  legendData, showCount, sliceData, externalLegend,
}: LegendProps) => {

  const getTextColor = (item: PieSector, key: number, label: 'labelColor' | 'fill') => {
    return item[label]
  }

  const classes = classNames(
    'legend',
    styles.legend,
    (externalLegend ? 'externalLegend' : null),
  )

  return (
    <div className={styles.infoSection}>
      <div data-testid="legend" className={classes}>
        {sliceData.length > 0
          && (sliceData || []).map((item, key) => {
            return (
              <div key={key} className={styles.item} onClick={item.onClick}>
                {item.name !== undefined && (
                  <>
                    <div
                      className={styles.circleIcon}
                      style={Object.assign({}, { backgroundColor: getTextColor(item, key, 'fill') })}
                    />
                    <div
                      className={classNames(styles.text, {
                        [styles.clickable]: !!item.onClick,
                      })}
                    >
                      <div
                        className={styles.metric}
                        style={{
                          color: getTextColor(item, key, 'labelColor'),
                        }}
                      >
                        {item.label || item.name}
                      </div>
                    </div>
                    {showCount && (
                      <div className={styles.rightSide}>
                        {item.count}
                        {item.percentage !== undefined && ` (${item.percentage}%)`}
                      </div>
                    )}
                  </>
                )}
              </div>
            )
          })}
        {legendData.length === 0
          && (legendData || []).map((item, key) => {
            return (
              <div key={key} className={styles.item}>
                <div className={styles.circleIcon} style={Object.assign({}, { backgroundColor: '#28AF42' })} />
                <div className={styles.text}>
                  <div className={styles.metric}>{item.name}</div>
                </div>
                {showCount && <div className={styles.rightSide}>{item.count} (100%)</div>}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export const SolDonutChart = (props: Props) => {
  // data comes from selectors, set fill per section based on length of array.

  const [chartIndex, setChartIndex] = useState(0)
  // Hovering in chart

  const onPieEnter = (data: object, cIndex: number) => {
    setChartIndex(cIndex)
  }

  const {
    verticalAlign,
    pieData,
    chartHeight,
    aspectRatio,
    xPosition,
    yPosition,
    legendWidth,
    legendAlignment,
    legendTitle,
    responsiveWidth,
    showCount = true,
    layout = 'vertical',
    isAnimationActive = true,
    tooltip,
    paging,
    minWidth,
    hideTooltip,
    externalLegend,
  } = props

  const allUpdated = ['#c3EACC']
  const getPalette = pieData.length === 0 ? allUpdated : cool(pieData.length)

  pieData.forEach((pie, index) => {
    if (!pie.fill) {
      Object.assign(pie, { fill: getPalette[index % getPalette.length] })
    }
  })

  pieData.sort((a: PieSector, b: PieSector) => {
    return b.count - a.count
  })

  const [pageNumber, setPageNumber] = useState(1)
  const pagingNumber = 7
  const totalPages = Math.ceil(pieData.length / pagingNumber)
  let sliceData: PieSector[] = pieData
  if (paging) {
    if (pieData.length > pagingNumber && totalPages > 1) {
      sliceData = pieData.slice((pageNumber - 1) * pagingNumber, pageNumber * pagingNumber)
    }
  }

  return (
    <>
      <div className={styles.titleContainer}>
        {typeof legendTitle === 'string' ? <span className={styles.title}>{legendTitle}</span> : legendTitle}
        {paging && totalPages > 1 && (
          <div className={styles.pagination}>
            <div className={styles.pagingButtons}>
              <ChevronLeftIcon
                className={classNames(pageNumber === 1 ? styles.disabled : styles.enbled, styles.iconLeft)}
                onClick={() => (pageNumber !== 1 ? setPageNumber(pageNumber - 1) : ' ')}
              />
              <span className={styles.words}>
                {pageNumber} of {totalPages}
              </span>
              <ChevronRightIcon
                className={classNames(totalPages === pageNumber ? styles.disabled : styles.enbled, styles.iconRight)}
                onClick={() => {
                  if (totalPages !== pageNumber) {
                    setPageNumber(pageNumber + 1)
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <ResponsiveContainer height={chartHeight} width={responsiveWidth} aspect={aspectRatio} minWidth={minWidth}>
        <PieChart width={100}>
          <Pie
            data={pieData} // Data in which to split graph up by
            innerRadius={props.innerRadius ?? 72} // the larger the number, the more whitespace inside the pie
            outerRadius={props.outerRadius ?? 90} // percent width of pie in relation to container
            paddingAngle={0} // Padding between each section
            dataKey="count"
            activeIndex={chartIndex}
            cx={xPosition}
            cy={yPosition}
            onMouseEnter={(data: any, index: number) => onPieEnter(data, index)}
            onMouseLeave={() => onPieEnter({}, 0)}
            isAnimationActive={isAnimationActive}
          >
            {pieData.map((entry, index) => (
              <Cell
                key={index}
                fill={entry.fill}
                onClick={entry.onClick}
                className={classNames({
                  [styles.clickable]: !!entry.onClick,
                })}
              />
            ))}
          </Pie>
          {!hideTooltip && <Tooltip formatter={tooltip?.formatter} separator=": "/>}
          <Legend
            align={legendAlignment}
            // @ts-ignore The Recharts type definition is wrong for this prop.
            // Looking through their source code (at node_modules/recharts/src/component/Legend.js)
            // shows that passing in a `width` just gets fed through to the div's
            // `style` attribute - which is  just a light [React abstraction over the native DOM attribute](https://reactjs.org/docs/dom-elements.html#style)
            // So since that's just raw CSS, string values like percentages are valid
            width={legendWidth}
            verticalAlign={verticalAlign}
            layout={layout}
            content={
              <CustomLegend
                legendData={pieData}
                showCount={showCount}
                sliceData={sliceData}
                externalLegend={externalLegend ?? false}
              />
            }
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default SolDonutChart
