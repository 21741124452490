import React, { useState } from 'react'
import { SecurityConfiguration } from 'graphql/__generated__/types'
import { Icon } from 'semantic-ui-react'
import { SolCheckbox, SolTooltip, SolConfirmationModal, SolNotification } from 'SolComponents'
import styles from './Security.module.scss'
import { RegexPattern, ValidationInput } from '../../../components/FormValidation/ValidationInput'

export interface Props {
  config: SecurityConfiguration
  updateConfiguration: (newSettings: SecurityConfiguration) => void
}

function SecurityConfig({ updateConfiguration, config }: Props) {
  const determineValidationType = () => {
    if (config?.enforcePasswordValidation) {
      return {
        required: false,
        pattern: RegexPattern.PasswordStrength,
        minLength: 8,
      }
    }
    return { required: false }
  }
  const [redirectModal, setRedirectModal] = useState(false)

  return (
    <div>
      <ValidationInput
        name="security_pw"
        rules={determineValidationType()}
        variant="password"
        label="Set Admin Password"
        value={config?.adminPassword?.secret}
        hash={config?.adminPassword?.secretHash}
        salt={config?.adminPassword?.secretSalt}
        onChange={(e, value) => {
          updateConfiguration({
            ...config,
            adminPassword: {
              secret: value.value,
            },
          })
        }}
        onClear={() => {
          updateConfiguration({
            ...config,
            adminPassword: {
              secret: '',
            },
          })
        }}
        errorText="Password does not meet requirements."
      />
      <div>
        <SolCheckbox
          label="Enforce password validation rules"
          checked={config?.enforcePasswordValidation ?? false}
          onClick={val => {
            updateConfiguration({
              ...config,
              enforcePasswordValidation: val,
            })
          }}
        />
        {config.enforcePasswordValidation && (
          <div className={styles.passwordRules}>
            <p className={styles.rule}>At least eight characters long.</p>
            <p className={styles.rule}>Contain at least one uppercase character.</p>
            <p className={styles.rule}>Contain at least one lowercase character.</p>
            <p className={styles.rule}>Contain at least one number or special character.</p>
          </div>
        )}
      </div>
      <SolCheckbox
        label="Allow local configuration (in room using keyboard/mouse)"
        checked={config?.allowLocalConfiguration ?? true}
        onClick={val => {
          updateConfiguration({
            ...config,
            allowLocalConfiguration: val,
          })
        }}
      />
      <SolCheckbox
        label="Allow configuration from web browser"
        checked={config?.allowWebBrowserConfiguration ?? true}
        onClick={val => {
          updateConfiguration({
            ...config,
            allowWebBrowserConfiguration: val,
          })
        }}
      />
      <SolTooltip
        text={[
          'Enable to redirect to an HTTPS hostname when the certificate is validated by the browser.',
          'Reverse DNS lookup will be attempted.',
          'If not resolved to a hostname, then manually set DNS names will be attempted.',
          'You may have to manually set DNS names for this functionality to work.',
          'Enter a DNS hostname in the Pod network settings',
          'and install a valid client-to-server certificate on the Pod.',
        ]}
        trigger={
          <SolCheckbox
            label="Redirect to HTTPS hostname"
            checked={config?.redirectHTTPS ?? false}
            notification={<SolNotification type="menu" notifications="v5.2+" />}
            onClick={() => {
              if (config.redirectHTTPS) {
                updateConfiguration({
                  ...config,
                  redirectHTTPS: !config.redirectHTTPS,
                })
              } else {
                setRedirectModal(!redirectModal)
              }
            }}
          />
        }
      />
      <SolTooltip
        text="Disables additional network traffic from checking for Captive Portals on the attached network(s)."
        trigger={
          <SolCheckbox
            label="Disable captive portal checking"
            checked={config?.disableCaptivePortal ?? false}
            notification={<>
              <SolTooltip
                isInlineThin
                trigger={<Icon name="question circle" />}
                text={'Support for Captive Portal Checking was removed in Solstice 6.1.'
                +  ' This setting will be ignored on 6.1+ Pods.'}
                position="top left"
              />
              <SolNotification type="menu" notifications="v5.3+" /></>}
            onClick={() => {
              updateConfiguration({
                ...config,
                disableCaptivePortal: !config.disableCaptivePortal,
              })
            }}
          />
        }
      />
      <SolTooltip
        text="Disables external ICMP pings to the Pod on any network interface (e.g. WAP, Wireless, ethernet)."
        trigger={
          <SolCheckbox
            label="Disable ICMP pings to the Pod"
            checked={config?.disableIcmpPingReplies ?? false}
            notification={<SolNotification type="menu" notifications="v5.0+" />}
            onClick={() => {
              updateConfiguration({
                ...config,
                disableIcmpPingReplies: !config.disableIcmpPingReplies,
              })
            }}
          />
        }
      />

      <SolConfirmationModal
        isOpen={redirectModal}
        modalText={<div className={styles.modal}>Reverse DNS lookup will be utilized to find the hostname.</div>}
        subHeaderText={[
          'If a DNS server cannot return a hostname, Manual DNS settings on the Pod will be used.',
          'You may have to manually provide a DNS hostname for the relevant interface',
          'within the Ethernet or WiFi template.',
        ]}
        acceptingText="OK"
        onCloseModal={() => setRedirectModal(false)}
        hideCancel
        onAcceptingAction={() => {
          updateConfiguration({
            ...config,
            redirectHTTPS: true,
          })
          setRedirectModal(false)
        }}
      />
    </div>
  )
}

export default SecurityConfig
