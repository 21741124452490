import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EthernetConfiguration, Template, Vlan } from './../../../graphql/__generated__/types'

interface UseDirtyTemplate {
  isDirty: boolean
  currentTemplate: Template
  resetOriginalTemplate: (template: Template) => void
  onCancel: () => void
  updateTemplate: (template: Template) => void
  cleanConfiguration: (template: EthernetConfiguration) => EthernetConfiguration
}

type Maybe<T> = T | undefined

export function useDirtyTemplate(initTemplate: Template): UseDirtyTemplate {
  const { clearErrors } = useFormContext()
  const [originalTemplate, setOriginalTemplate] = useState<Template>(initTemplate)
  const [latestTemplate, setLatestTemplate] = useState<Template>(initTemplate)


  const cleanConfiguration = (config: EthernetConfiguration): EthernetConfiguration => {
    const cleanVlans = config?.vlans?.map(vlan => {
      if (!vlan?.enabled) {
        return {
          ...vlan,
          label: '',
          tag: 100,
          adminAccess: false,
          networkConfig: {
            dns1: '',
            dns2: '',
            gateway: '',
            ipType: 'dhcp',
            networkPrefixLength: 24,
          },
        }
      }
      return vlan
    }) as Maybe<Vlan[]>
    return {
      ...config,
      vlans: cleanVlans,
    }
  }

  const isEqualCustom = (obj1: EthernetConfiguration, obj2: EthernetConfiguration): boolean => {
    const cleanObj1 = cleanConfiguration(obj1)
    const cleanObj2 = cleanConfiguration(obj2)

    return !isEqual(cleanObj1, cleanObj2)
  }

  const isNewTemplate = !latestTemplate.id
  const isDirty
    = isEqualCustom(originalTemplate.revision.configuration, latestTemplate.revision.configuration)
    || originalTemplate.name !== latestTemplate.name
    || isNewTemplate

  return {
    isDirty,
    resetOriginalTemplate: (template: Template) => {
      setOriginalTemplate(template)
    },
    onCancel: () => {
      setLatestTemplate(originalTemplate)
      clearErrors()
    },
    updateTemplate: (template: Template) => {
      setLatestTemplate(template)
    },
    cleanConfiguration,
    currentTemplate: latestTemplate,
  }
}
