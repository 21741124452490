import { Release } from '../../../shared/hooks/versions/useVersionUtils'
import compareVersions from 'compare-versions'
import { useMemo } from 'react'

type SelectedPodVersion = {
  version: string
  subscriptionEndDate: Date
  hardware: string
}

export const useUpdateEligibility = (releases: Release[], selectedVersions: SelectedPodVersion[]) => {
  return useMemo(() => {
    if (!selectedVersions.length) {
      return []
    }

    // Check if there are common updates available for all selected versions
    const commonUpdates = releases.filter(release =>
      selectedVersions.every(selectedVersion =>
        canUpdate(release, selectedVersion)),
    )

    // If there are no common updates available, return an empty array
    if (!commonUpdates.length) {
      return []
    }

    return commonUpdates
  }, [releases, selectedVersions])
}

const canUpdate = (release: Release, selectedVersion: SelectedPodVersion): boolean => {
  const releaseDate = new Date(release.date)
  const subscriptionEndDate = new Date(selectedVersion.subscriptionEndDate)
  const isReleaseBeforeSubscriptionEnd = releaseDate <= subscriptionEndDate
  const [releaseMajor, releaseMinor, releasePatch] = release.key.split('.').map(Number)

  // Check for Gen2 pod (see https://mersive.atlassian.net/browse/DMX-2483)
  if (selectedVersion.hardware === 'Pod Gen2' || selectedVersion.hardware === 'Pod Gen2i') {
    const isAllowedVersion = releaseMajor === 6 && releaseMinor <= 1
    if (!isAllowedVersion) {
      return false
    }
  }

  // If the release was released before the end of the subscription and is higher than the current version,
  // it is suitable.
  if (isReleaseBeforeSubscriptionEnd && compareVersions(release.key, selectedVersion.version) === 1) {
    return true
  }

  // If the subscription is expired, we check whether the release is a patch for the current version.
  if (!isReleaseBeforeSubscriptionEnd) {
    const [currentMajor, currentMinor, currentPatch] = selectedVersion.version.split('.').map(Number)
    const isSameMajor = releaseMajor === currentMajor
    const isSameMinor = releaseMinor === currentMinor
    const isHigherPatch = releasePatch > currentPatch

    return (isSameMajor && isSameMinor) && isHigherPatch
  }

  // If none of the conditions are met, the release is not eligible.
  return false
}
