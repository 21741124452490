import React from 'react'
import { SolTooltip } from 'SolComponents'
import { SolQuestionCircle } from 'SolComponents/Icons'
import styles from './SSO.module.scss'

type Props = {
  labelTitle: string
  tooltip: string
  required?: boolean
}

const SsoLabel = ({ labelTitle, tooltip, required }: Props) => {
  return (
    <label>
      {labelTitle}
      {required && <span className={styles.required}>*</span>}
      {tooltip
      && <span className={styles.icon}>
        <SolTooltip text={tooltip} trigger={<SolQuestionCircle size="small" />} />
      </span>
      }
    </label>
  )
}

export default SsoLabel
