import { OrderBy } from 'components/DataTableSlim/DataTableSlim'
import { getCategorySearchArg, getDirection, getSearchIdArgs } from 'components/DataTableSlim/Utils/hasuraUtils'
import { Page, ServerSelectedItems } from 'components/DataTableSlim/types'
import {
  LocationsPageTableQueryVariables,
  OrderBy as HasuraOrderBy,
  ViewDisplaysBoolExp,
  ViewDisplaysOrderBy,
  Maybe,
} from './../../../graphql/__generated__/hasura-types'
import { CategoryFilter } from './../../../graphql/__generated__/types'

type DataTableFilters = {
  searchValue: string
  orderBy?: OrderBy
  // TODO make this be internal categories....
  categories?: CategoryFilter[]
  selectedItems: ServerSelectedItems
  page: Page
  visiblePodsBox?: number[][]
  buildingFloorMap?: { [key: number]: string }
  reload?: boolean
}

// TODO represent the selected items only for the aggregate count...
export type LocationPageFilters = {
  // contains order by, limit, offset, does not include selected items
  tableFilters: LocationsPageTableQueryVariables

  // contains all filter plus selected items
  selectedItemsFilters: ViewDisplaysBoolExp

  // contains all filter plus selected items EXCEPT filterBoxFilter - so that the map dose not replot all the points when the bounding box changes
  mapFilters: ViewDisplaysBoolExp
}

export function getOrderByParams(orderBy: OrderBy): ViewDisplaysOrderBy[] {
  let orderByFields: ViewDisplaysOrderBy = {}
  const direction = getDirection(orderBy.direction)
  switch (orderBy.field) {
    case 'status':
      orderByFields.is_online = direction
      break
    case 'building':
      orderByFields = {
        location: {
          address: {
            nickname: direction,
            building: direction,
          },
        },
      }
      break
    case 'city':
      orderByFields = {
        location: {
          address: {
            city: direction,
          },
        },
      }
      break
    case 'country':
      orderByFields = {
        location: {
          address: {
            country: direction,
          },
        },
      }
      break
    case 'floor':
      orderByFields = {
        name: direction,
        location: {
          floor_count: {
            floor: direction,
          },
        },
      }
      break
    case 'name':
    default:
      orderByFields.name = direction
  }
  return [orderByFields, { name: HasuraOrderBy.Asc }]
}

export const getFilterBoxFilter = (visiblePodsBox?: number[][]) =>
  (visiblePodsBox && visiblePodsBox.length === 5
    ? {
      location: {
        precise_location: {
          _st_intersects: {
            type: 'Polygon',
            coordinates: [visiblePodsBox],
          },
        },
      },
    }
    : {})

export function buildHasuraSearchFilters(params: DataTableFilters): LocationPageFilters {
  let selectedIdsFilter: ViewDisplaysBoolExp = {}
  let searchTermFilter: ViewDisplaysBoolExp = {}
  let filterBoxFilter: ViewDisplaysBoolExp = getFilterBoxFilter(params.visiblePodsBox)
  let categoriesFilter: ViewDisplaysBoolExp = {}
  let floorFilter: ViewDisplaysBoolExp[] = []

  if (params.selectedItems?.includedIds || params.selectedItems?.excludedIds) {
    selectedIdsFilter = getSearchIdArgs({
      includedIds: params.selectedItems.includedIds,
      excludedIds: params.selectedItems.excludedIds,
    })
  }

  if (params.searchValue) {
    searchTermFilter = {
      _or: [
        {
          name: {
            _ilike: `%${params.searchValue}%`,
          },
        },
        {
          ip_addresses_primary: {
            _ilike: `%${params.searchValue}%`,
          },
        },
        {
          location: {
            address: {
              _or: [
                {
                  nickname: {
                    _ilike: `%${params.searchValue}%`,
                  },
                },
                {
                  building: {
                    _ilike: `%${params.searchValue}%`,
                  },
                },
                {
                  city: {
                    _ilike: `%${params.searchValue}%`,
                  },
                },
                {
                  country: {
                    _ilike: `%${params.searchValue}%`,
                  },
                },
              ],
            },
          },
        },
      ],
    }
  }

  if (params.categories && params.categories.length > 0) {
    categoriesFilter = getCategorySearchArg(params.categories)
  }

  if (params.buildingFloorMap && Object.keys(params.buildingFloorMap).length !== 0) {
    Object.keys(params.buildingFloorMap).forEach(addressID => {
      const floor = (params.buildingFloorMap || {})[addressID]

      floorFilter.push({
        location: {
          _not: {
            _and: [
              { address_id: { _eq: parseInt(addressID) } }, // address
              { floor: { _neq: floor } }, // floor you want to see
            ],
          },
        },
      })
    })
  }

  const andTable: Maybe<ViewDisplaysBoolExp>[] = [searchTermFilter, filterBoxFilter].filter(
    f => !!Object.values(f).length,
  )
  const tableWHERE: ViewDisplaysBoolExp
    = categoriesFilter._and || andTable.length > 0
      ? {
        _and: [...(categoriesFilter._and || []), ...andTable, { org: { _eq: localStorage.getItem('org') } }],
      }
      : { org: { _eq: localStorage.getItem('org') } }

  const andSelected = [selectedIdsFilter, searchTermFilter, filterBoxFilter].filter(f => !!Object.values(f).length)

  return {
    tableFilters: {
      orderBy: params.orderBy ? getOrderByParams(params.orderBy) : [{ name: HasuraOrderBy.Asc }],
      limit: params.page.size ?? 25,
      offset: (params.page.number - 1) * params.page.size,
      where: tableWHERE,
    },
    selectedItemsFilters: {
      _and: [...(categoriesFilter._and ?? []), ...andSelected],
    },
    mapFilters: {
      _and: [
        ...(categoriesFilter._and ?? []),
        searchTermFilter,
        { location: { precise_location: { _is_null: false } } },
        ...floorFilter,
      ],
    },
  }
}
