import { configureStore, Store } from '@reduxjs/toolkit'
import * as ReselectTools from 'reselect-tools'
import { isAuthenticated } from 'shared/core/auth'
import reducers from 'shared/store/slices'
import { Dispatch } from 'shared/types/redux'
import { setUserInfo } from './actions/account'

let store: Store<any> | null = null

// our own version of store.subscribe that we can notify selectively
// in our websocket middleware

export default function getStore() {
  return store!
}

export function makeStore() {

  store = configureStore({
    reducer: reducers,
  })

  ReselectTools.getStateWith(() => getStore().getState())

  store.dispatch(initApp() as any)
  return store
}

function initApp() {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'INIT_APP' })
    if (isAuthenticated()) {
      dispatch(setUserInfo())
    }
  }
}
