import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { withRouter } from 'react-router-dom'

import {
  authenticateAuth0,
  authenticateWithSSO,
  resetPassword,
  completeSSO,
  getSsoStatus,
} from 'shared/store/actions/authenticate'

import Login, { Props } from './Login'
import { AuthCredentialInterface } from 'shared/services/auth'

function mapStateToProps(state: any) {
  // from the store
  return {
    // TODO: selector
    me: state?.user || {},
  }
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  // change the store
  return {
    authenticate: (data: any) => dispatch(authenticateAuth0(data)),
    resetPassword: (email: string) => dispatch(resetPassword(email)),
    authenticateWithSSO: (data: AuthCredentialInterface) => dispatch(authenticateWithSSO(data)),
    completeSSO: (token: string) => dispatch(completeSSO(token)),
    getSsoStatus: (email: string) => dispatch(getSsoStatus(email)),
  }
}

export default withRouter(connect<{}, {}, Props, any>(mapStateToProps, mapDispatchToProps)(Login))
