import React, { useCallback } from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { SolDonutChart, SolCard } from 'SolComponents'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { isEmpty } from 'SolComponents/SolDonutCard/SolDonutCard'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import { TestingProps } from 'shared/types/TestingProps'
import styles from './Donut.module.scss'

interface DonutProps extends TestingProps {
  title: string
  data: PieSector[] | undefined
  loading: boolean
  handleClick?: () => void
  url?: string
  aspectRatio?: number // testing purpose
}

export enum DonutColors {
  ERROR = '#1B2B86',
  WARNING = '#0085BB',
  SUCCESS = '#6BCDCF',
}

export const labelColor = '#1279C6'

const Donut = ({
  data,
  title,
  loading = true,
  dataTestId,
  handleClick,
  url,
  aspectRatio,
}: DonutProps) => {
  const onButtonClick = useCallback(() => {
    handleClick?.()
  }, [])

  return (
    <SolCard className={styles.card}>
      <div data-testid={dataTestId}>
        <Header
          as="h2"
          textAlign="center"
          content={title}
          className={styles.header}
        />
        <LoadingWrapper
          isEmpty={isEmpty(data) && loading === false}
          isLoading={loading}
        >
          <SolDonutChart
            pieData={data || []}
            verticalAlign="bottom"
            chartHeight={260}
            xPosition="50%"
            yPosition="50%"
            legendWidth={180}
            legendAlignment="center"
            responsiveWidth="100%"
            isAnimationActive={false}
            aspectRatio={aspectRatio}
          />
          <div className={styles.buttonContainer}>
            <Button
              primary={!url}
              onClick={onButtonClick}
            >
              {url ? <Link to={url}>Visit Updates Page</Link> : 'Renew Now'}
            </Button>
          </div>
        </LoadingWrapper>
      </div>
    </SolCard>
  )
}

export default React.memo(Donut)
