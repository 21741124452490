import React from 'react'
import styles from './SolGrid.module.scss'

export interface Props {
    children?: any
}

export default function SolGrid({
  children,
}: Props) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}
