import React from 'react'
import { Icon } from 'semantic-ui-react'
import compareVersions from 'compare-versions'
import { Link } from 'react-router-dom'
import styles from './UsbTable.module.scss'
import { SolTooltip } from 'SolComponents'
import { UsbConnection } from './types'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import {
  DisplayDocument,
  DisplayQuery,
  DisplayQueryVariables,
  SettingsGroupType,
  UsbDevice,
} from 'graphql/__generated__/types'
import { useQuery } from '@apollo/client'

const Requirement = (props: { value: boolean; label: string | JSX.Element }) => (
  <div className={styles.requirement}>
    {props.value ? <Icon name="check" color="green" /> : <Icon name="x" color="red" />}
    {props.label}
  </div>
)

const hasMinVersion = (connection: UsbConnection | UsbDevice) => {
  const version = Array.isArray(connection.display?.version) ? connection.display?.version.join('.')
    : connection.display?.version
  return compareVersions(version, '5.0') >= 0
}

const Readiness = ({ requirements }: { requirements: { ready: boolean; label: string; link?: string }[] }) => {
  const tooltip = requirements.map((requirement, i) => (
    <Requirement
      key={i}
      value={requirement.ready}
      label={requirement.link ? <Link to={requirement.link}>{requirement.label}</Link> : requirement.label}
    />
  ))

  const icon = <SolQuestionCircle size="small" />

  return requirements.every(r => r.ready) ? (
    <Icon name="check" color="green" />
  ) : (
    <>
      No&nbsp;
      <SolTooltip hoverable trigger={icon} isInline text={tooltip} />
    </>
  )
}

export const ConferenceReadiness = (usbDevice: UsbDevice) => {
  const { data } = useQuery<DisplayQuery, DisplayQueryVariables>(DisplayDocument, {
    variables: { id: usbDevice?.displayId ?? '' },
    fetchPolicy: 'cache-and-network',
  })
  const roomIntelligenceTemplate = data?.display?.assignedTemplates
    .find(template => template?.settingsGroup?.type === SettingsGroupType.RoomIntelligence)

  const enableSolsticeConferencing = roomIntelligenceTemplate?.
    template?.
    revision.
    configuration.
    enableSolsticeConferencing

  const requirements = [
    {
      ready: usbDevice.display?.hardware === 'Pod Gen3',
      label: 'Pod is gen3 hardware',
    },
    {
      ready: hasMinVersion(usbDevice),
      label: 'Pod is on 5.0 or greater Solstice version',
    },
    {
      ready: enableSolsticeConferencing ?? false,
      label: `Conference toggle is ${
        enableSolsticeConferencing ? 'enabled' : 'disabled'
      } (in the Room Intelligence template)`,
      link: `/manage/pod/${usbDevice.display?.id}`,
    },
    {
      ready: usbDevice.display?.isInSubscription ?? false,
      label: 'Solstice Subscription is current',
    },
  ]

  return usbDevice.avDevice ? <Readiness requirements={requirements} /> : <>-</>
}

export const OccupancyReadiness = (usbDevice: UsbDevice) => {
  const { data } = useQuery<DisplayQuery, DisplayQueryVariables>(DisplayDocument, {
    variables: { id: usbDevice?.displayId ?? '' },
    fetchPolicy: 'cache-and-network',
  })
  const roomIntelligenceTemplate = data?.display?.assignedTemplates
    .find(template => template?.settingsGroup?.type === SettingsGroupType.RoomIntelligence)

  const enableOccupancySettings = roomIntelligenceTemplate?.
    template?.
    revision.
    configuration.
    occupancySettings.
    enableOccupancyData

  const requirements = [
    {
      ready: hasMinVersion(usbDevice),
      label: 'Pod is on v5.0 or greater Solstice version',
    },
    {
      ready: enableOccupancySettings ?? false,
      label: `Occupancy Data is ${
        enableOccupancySettings ? 'enabled' : 'disabled'
      } (in the Room Intelligence template)`,
      link: `/manage/pod/${usbDevice.display?.id}`,
    },
  ]

  return usbDevice.avDevice ? <Readiness requirements={requirements} /> : <>-</>
}
