import React from 'react'
import classNames from 'classnames'
import { Button } from 'semantic-ui-react'
import styles from './SolButton.module.scss'
import { Link } from 'react-router-dom'

export interface Props {
  className?: string
  containerClassName?: string
  text: string | React.ReactNode
  primary?: boolean
  disabled?: boolean
  color?: 'blue' | 'black' | 'green'
  loading?: boolean
  basic?: boolean
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isLink?: boolean
  externalLink?: string
  internalLink?: string
  dataTestId?: string
  onMouseDown?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  noBoxShadow?: boolean
}

export const SolButton = (props: Props) => {
  const classes = classNames(props.containerClassName, {
    [styles.linkButton]: props.isLink,
    [styles.noBoxShadow]: props.noBoxShadow,
  })
  
  return (
    <div className={classes} data-testid={props.dataTestId}>
      <Button
        className={props.className}
        primary={props.primary}
        color={props.color}
        basic={props.basic}
        content={props.text}
        disabled={props.disabled}
        onClick={e => {
          if (props.handleClick) {
            props.handleClick(e)
          }
          if (props.externalLink) {
            window.location.href = props.externalLink
          }
        }}
        onMouseDown={() => {
          if (props.onMouseDown) {
            props.onMouseDown()
          }
        }}
        loading={props.loading}
        as={props.internalLink ? Link : undefined}
        to={props.internalLink}
        type={props.type}
      />
    </div>
  )
}

export default SolButton
