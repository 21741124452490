import React from 'react'
import styles from './Calendar.module.scss'
import {
  AuthenticationType,
  CalendarType,
  ExchangeSettings,
  MailboxType,
  Office365Settings,
  Template,
} from 'graphql/__generated__/types'
import { SolDropdown, SolRadio, SolTooltipWithLink } from 'SolComponents'
import { RegexPattern, validateUrlOrIpAddress, ValidationInput } from 'components/FormValidation/ValidationInput'
import { DropdownItemProps, Header } from 'semantic-ui-react'
import UniqueSettingsInput, { getTooltipText } from '../UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import {
  getExchangeDelegationMailbox,
  getExchangeImpersonationMailbox,
  getExchangeUserAccount,
  getExchangeUserPassword,
  getOffice365Username,
  setExchangeDelegationMailbox,
  setExchangeImpersonationMailbox,
  setExchangeUserAccount,
  setExchangeUserPassword,
  setOffice365Username,
} from 'shared/config/calendar'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'

export interface Props {
  calendarType?: CalendarType | null
  exchangeSettings: ExchangeSettings
  setExchangeSettings: (newSettings: Partial<ExchangeSettings>) => void
  podId?: string
  setOffice365Settings: (newSettings: Partial<Office365Settings>) => void
  office365Settings: Office365Settings
  template: Template
}

function ExchangeSettingsCmp({
  calendarType,
  exchangeSettings,
  setExchangeSettings,
  podId,
  office365Settings,
  setOffice365Settings,
}: Props) {
  const authenticationTypeOptionsExchange: DropdownItemProps[] = [
    { value: AuthenticationType.Basic, text: 'Basic' },
    { value: AuthenticationType.Ntlm, text: 'NTLM' },
  ]
  const authenticationTypeOptionsOffice: DropdownItemProps[] = [
    ...authenticationTypeOptionsExchange,
    {
      value: AuthenticationType.Oauth,
      text: 'Oauth (v5.1+)',
    },
  ]

  const MailboxTypeRadio = ({ text, value }: { text: string; value: MailboxType }) => (
    <div>
      <SolRadio
        label={text}
        /* eslint-disable-next-line react/prop-types */
        checked={(exchangeSettings.mailboxType ?? MailboxType.Default) === value}
        onClick={() =>
          setExchangeSettings({
            ...exchangeSettings,
            mailboxType: value,
          })
        }
      />
    </div>
  )

  const userAccountPlaceholder
    = exchangeSettings?.authenticationType === AuthenticationType.Basic ? 'user@example.com' : 'Windows account'

  return (
    <React.Fragment>
      {calendarType === CalendarType.Exchange && (
        <ValidationInput
          label="Server URL"
          name="server_url"
          size="extraLarge"
          rules={{ required: true, validate: validateUrlOrIpAddress }}
          value={exchangeSettings?.url ?? ''}
          onChange={(_, v) =>
            setExchangeSettings({
              ...exchangeSettings,
              url: v.value,
            })
          }
          placeholder="https://example.server.com/path/to/exchange.asmx"
        />
      )}
      <div className={styles.newRow}>
        <SolDropdown
          type="caret"
          direction="left"
          options={
            (calendarType === CalendarType.Office365 || calendarType === CalendarType.Office365Oauth)
              ? authenticationTypeOptionsOffice
              : authenticationTypeOptionsExchange
          }
          label="Authentication Type"
          value={
            calendarType === CalendarType.Office365Oauth
              ? AuthenticationType.Oauth
              : exchangeSettings.authenticationType
          }
          onChange={(authenticationType: AuthenticationType) => setExchangeSettings({ authenticationType })}
          size="medium"
        />
        {exchangeSettings?.authenticationType === AuthenticationType.Ntlm && (
          <ValidationInput
            label="Domain"
            name="domain"
            value={exchangeSettings?.ntlmDomain ?? ''}
            rules={{ required: true }}
            onChange={(_, v) => {
              setExchangeSettings({
                ...exchangeSettings,
                ntlmDomain: v.value,
              })
            }}
          />
        )}
      </div>
      {calendarType === CalendarType.Office365Oauth && (
        <div>
          <p>
            Login to your{' '}
            <a href="https://portal.azure.com/" target="_blank" rel="noreferrer">
              Azure Portal
            </a>
            , generate the required fields, and paste them below.
            <SolTooltipWithLink
              trigger={<SolQuestionCircle className={styles.questionIcon} />}
              text="Need help? Click the link below for more instructions."
              buttonText="VIEW DOCUMENTATION"
              position="bottom left"
              isExternalLink
              linkUrl="https://documentation.mersive.com/en/ms365-calendar.html"
            />
          </p>
          <ValidationInput
            name="clientId"
            value={office365Settings.clientId}
            rules={{ required: true }}
            label="Client ID"
            onChange={(e, v) => {
              setOffice365Settings({ clientId: v.value })
            }}
          />
          <ValidationInput
            name="tenantId"
            value={office365Settings.tenantId ?? ''}
            rules={{ required: true }}
            label="Tenant ID"
            onChange={(_, v) => setOffice365Settings({ tenantId: v.value })}
          />
          <ValidationInput
            name="clientSecret"
            value={office365Settings?.clientSecret?.secret}
            hash={office365Settings?.clientSecret?.secretHash}
            salt={office365Settings?.clientSecret?.secretSalt}
            rules={{ required: true }}
            label="Client Secret"
            variant="password"
            onChange={(_, v) =>
              setOffice365Settings({
                clientSecret: {
                  secret: v.value,
                },
              })
            }
            onClear={() =>
              setOffice365Settings({
                clientSecret: {
                  secret: '',
                },
              })
            }
          />
          <div>
            <UniqueSettingsInput
              label="Username"
              tooltipText={getTooltipText({
                setting: 'Office 365 OAuth',
                template: 'Calendar',
              })}
              name="office_365"
              rules={{ required: true }}
              getValue={getOffice365Username}
              updateConfig={setOffice365Username}
              podId={podId}
            />
          </div>
        </div>
      )}
      {exchangeSettings?.authenticationType !== AuthenticationType.Oauth
        && calendarType !== CalendarType.Office365Oauth && (
        <div className={styles.newRow}>
          <Header size="small">Are you using an impersonation or delegation mailbox?</Header>
          <div className={styles.mailboxTypes}>
            <MailboxTypeRadio text="No" value={MailboxType.Default} />
            <MailboxTypeRadio text="Impersonation" value={MailboxType.Impersonation} />
            <MailboxTypeRadio text="Delegation" value={MailboxType.Delegation} />
            {exchangeSettings?.mailboxType === MailboxType.Impersonation && (
              <UniqueSettingsInput
                label="Impersonation Mailbox"
                tooltipText={getTooltipText({
                  setting: 'Impersonation Mailbox',
                  template: 'Calendar',
                })}
                name="impersonation_mailbox"
                rules={{ required: true, pattern: RegexPattern.Email }}
                getValue={getExchangeImpersonationMailbox}
                updateConfig={setExchangeImpersonationMailbox}
                podId={podId}
              />
            )}
            {exchangeSettings?.mailboxType === MailboxType.Delegation && (
              <UniqueSettingsInput
                label="Delegation Mailbox"
                tooltipText={getTooltipText({
                  setting: 'Delegation Mailbox',
                  template: 'Calendar',
                })}
                name="delegation_mailbox"
                rules={{ required: true, pattern: RegexPattern.Email }}
                getValue={getExchangeDelegationMailbox}
                updateConfig={setExchangeDelegationMailbox}
                podId={podId}
              />
            )}
          </div>
        </div>
      )}

      {exchangeSettings?.mailboxType === MailboxType.Default
        && exchangeSettings.authenticationType !== AuthenticationType.Oauth
        && calendarType !== CalendarType.Office365Oauth && (
        <React.Fragment>
          <UniqueSettingsInput
            label="Username"
            tooltipText={getTooltipText({
              setting: 'User Name',
              template: 'Calendar',
            })}
            name="exchange_user_account"
            rules={{ required: true }}
            getValue={getExchangeUserAccount}
            updateConfig={setExchangeUserAccount}
            podId={podId}
          />
          <UniqueSettingsInput
            label="Password"
            tooltipText={getTooltipText({
              setting: 'Password',
              template: 'Calendar',
            })}
            variant="password"
            name="exchange_user_password"
            rules={{ required: true }}
            getValue={getExchangeUserPassword}
            updateConfig={setExchangeUserPassword}
            podId={podId}
          />
        </React.Fragment>
      )}
      {exchangeSettings?.mailboxType !== MailboxType.Default
        && exchangeSettings.authenticationType !== AuthenticationType.Oauth
        && calendarType !== CalendarType.Office365Oauth && (
        <React.Fragment>
          <ValidationInput
            label="Username"
            name={`exchange_user_account_${exchangeSettings.authenticationType}`}
            rules={{
              required: true,
              pattern:
                  exchangeSettings.authenticationType === AuthenticationType.Basic ? RegexPattern.Email : undefined,
            }}
            value={exchangeSettings.userAccount ?? ''}
            onChange={(_, v) =>
              setExchangeSettings({
                ...exchangeSettings,
                userAccount: v.value,
              })
            }
            placeholder={userAccountPlaceholder}
          />
          <ValidationInput
            variant="password"
            name="exchange_password"
            label="Password"
            value={exchangeSettings.userPassword?.secret}
            hash={exchangeSettings.userPassword?.secretHash}
            salt={exchangeSettings.userPassword?.secretSalt}
            rules={{ required: true }}
            onChange={(_, v) =>
              setExchangeSettings({
                ...exchangeSettings,
                userPassword: { secret: v.value },
              })
            }
            onClear={() => setExchangeSettings({ userPassword: { secret: '' } })}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ExchangeSettingsCmp
