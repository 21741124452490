import React, { useState, Dispatch, SetStateAction } from 'react'
import styles from './SolTemplateHeader.module.scss'
import { SettingsGroupType, Template, TemplateCompatibility } from 'graphql/__generated__/types'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import SolButton from 'SolComponents/SolButton/SolButton'
import { Icon, Dropdown } from 'semantic-ui-react'
import SolEllipsesDropdown from 'SolComponents/SolEllipsesDropdown/SolEllipsesDropdown'
import { useFormContext } from 'react-hook-form'
import { TemplateNameModal, UnknownPasswordModal } from './modals'

export interface TemplateHeaderProps {
  displayName?: string
  podId?: string
  isPending?: boolean
  isOnline?: boolean
  open?: boolean
  locked: boolean
  totalAssignedDisplays?: number
  setLocked: Dispatch<SetStateAction<boolean>>
  isInModern: boolean
  view: 'Pod' | 'Templates'
  template: Template
  dirty: boolean
  isValid: boolean
  isSaving: boolean
  handleCancel: (e: React.MouseEvent) => void
  handleCancelPending: (e: React.MouseEvent) => void
  handleCreateDuplicate: (template: Template) => void
  handleSaveTemplate: (e: React.MouseEvent | React.FormEvent<HTMLFormElement>) => void
  handleSaveDisplayAsNewTemplate: (newTemplateName: string) => Promise<void>
  handleSaveDisplayAsUnassigned: (e: React.MouseEvent, configuration?: any) => void
  handleDelete: (e: React.MouseEvent) => void
  updateTemplate: (t: Template) => void
  savingError: string | null
  clearSavingError: () => void
  refetchDisplay?: () => void
  syncState?: string
  templateCompatibility?: (Partial<TemplateCompatibility> | null)[]
  templateOptions?: Template[]
  loadingTemplateOptions?: boolean
}

export function ActionButtons({
  view,
  locked,
  dirty,
  open,
  template,
  setLocked,
  handleCancel,
  isValid,
  isSaving,
  handleCreateDuplicate,
  handleDelete,
  handleSaveDisplayAsUnassigned,
  handleSaveDisplayAsNewTemplate,
  handleSaveTemplate,
  savingError,
  clearSavingError,
  isInModern,
}: TemplateHeaderProps) {
  if (
    (!open && (view === 'Pod' || (view === 'Templates' && template.id === '')))
    || (!isInModern && view === 'Pod' && template.settingsGroup.type === SettingsGroupType.WelcomeScreen)
  ) {
    return null
  }

  const ellipsesDropdown = (
    <SolEllipsesDropdown direction="left">
      <Dropdown.Menu>
        <Dropdown.Item
          icon="copy outline"
          text="Duplicate Template"
          onClick={() => {
            handleCreateDuplicate(template)
          }}
        />
        {!template.isDefault && (
          <Dropdown.Item
            icon="trash alternate outline"
            text="Delete Template"
            onClick={e => {
              handleDelete(e)
            }}
          />
        )}
      </Dropdown.Menu>
    </SolEllipsesDropdown>
  )

  if (!open) {
    return ellipsesDropdown
  }
  const { errors } = useFormContext()
  const [templateNameModalOpen, setTemplateNameModalOpen] = useState(false)
  const [unknownPasswordModalOpen, setUnknownPasswordModalOpen] = useState(false)
  const showPodConfigActionButtonGroup = view === 'Pod' && !locked && open
  const cancelButtonDisabled = ((view === 'Templates' || template.isCustom) && open && !dirty) || isSaving
  const showEditButton = locked && open && !template.isCustom
  const saveAsUnassignedButtonDisabled = !isValid || (template.isCustom && !dirty) || isSaving
  const saveAsNewTemplateButtonDisabled = !isValid || isSaving
  const saveTemplateButtonDisabled = !isValid || isSaving || !dirty || template.name === ''
  const containsUnknownPassword = Object.values(errors || {}).some(error => error?.types?.type === 'unknownPassword')

  if (view === 'Templates') {
    return (
      <div className={styles.actionButtons} onClick={e => e.stopPropagation()}>
        <SolButton
          primary={false}
          basic
          color="black"
          text="Cancel"
          disabled={cancelButtonDisabled}
          handleClick={e => {
            e.stopPropagation()
            handleCancel(e)
          }}
          isLink
        />
        <SolButton
          primary
          basic={false}
          text="Save"
          disabled={saveTemplateButtonDisabled}
          handleClick={e => {
            e.stopPropagation()
            handleSaveTemplate(e)
          }}
          isLink={false}
        />
        {template.id !== '' && ellipsesDropdown}
      </div>
    )
  }

  return (
    <div className={styles.actionButtons} onClick={e => e.stopPropagation()}>
      <UnknownPasswordModal
        isOpen={unknownPasswordModalOpen}
        close={() => setUnknownPasswordModalOpen(false)}
        confirm={() => setTemplateNameModalOpen(true)}
      />
      <TemplateNameModal
        isOpen={templateNameModalOpen}
        close={() => setTemplateNameModalOpen(false)}
        clearSavingError={clearSavingError}
        handleSaveDisplayAsNewTemplate={handleSaveDisplayAsNewTemplate}
        isSaving={isSaving}
        savingError={savingError}
      />
      {showEditButton && (
        <SolTooltip
          position="top left"
          hoverable
          trigger={
            <SolButton
              className={styles.editButton}
              text={
                <React.Fragment>
                  <Icon name="lock" />
                  <span>Edit</span>
                </React.Fragment>
              }
              primary
              disabled={false}
              basic={false}
              handleClick={e => {
                e.stopPropagation()
                setLocked(false)
              }}
              isLink={false}
              dataTestId="edit-template-button"
            />
          }
          text={
            `If you make changes to any options below, it will unhook this pod from ${
              template.name
            }, and will become Unassigned.`
          }
        />
      )}
      {showPodConfigActionButtonGroup && (
        <React.Fragment>
          <SolButton
            primary={false}
            basic
            color="black"
            text="Cancel"
            disabled={cancelButtonDisabled}
            handleClick={e => {
              e.stopPropagation()
              if (!template.isCustom) {
                setLocked(true)
              }
              handleCancel(e)
            }}
            isLink
          />
          <SolButton
            primary
            basic
            text="Save as Unassigned"
            disabled={saveAsUnassignedButtonDisabled}
            handleClick={e => handleSaveDisplayAsUnassigned(e)}
            isLink={false}
          />
          <SolButton
            primary
            basic={false}
            text="Save as New Template"
            disabled={saveAsNewTemplateButtonDisabled}
            handleClick={e => {
              e.stopPropagation()
              if (containsUnknownPassword) {
                setUnknownPasswordModalOpen(true)
              } else {
                setTemplateNameModalOpen(true)
              }
            }}
            isLink={false}
          />
        </React.Fragment>
      )}
    </div>
  )
}
