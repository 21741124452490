import { Maybe, SolsticeRoutingPod } from 'graphql/__generated__/types'
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Pod } from './Pod'
import { Route } from './Route'
import { Color } from './types/Color'

import styles from './pod.grid.module.scss'
import { SolsticeRoutingRoute as RouteType } from 'graphql/__generated__/types'

interface Props {
    pods: Maybe<Maybe<SolsticeRoutingPod>[]> | undefined
    routes: Maybe<Maybe<RouteType>[]> | undefined
    cols: number
    rows: number
    showRoutes?: boolean
    showGridLines?: boolean
    allowDraggingPods?: boolean
    setPods?: (pods: any) => void
    addRoute?: (route: RouteType) => void
    removeRoute?: (route: RouteType) => void
    primaryPodId?: string
}

interface DraggablePod {
    index: number
    id: string
    x: number
    y: number
    column: number
    row: number
    active: boolean
    xOffset: number
    yOffset: number
    name?: string
    isAudioEnabled: boolean
    isPrimary: boolean
    color: string
    isSource?: boolean
}

interface DraggableRoute {
    index: number
    x: number | null
    y: number | null
    source_pod: DraggablePod | undefined
    sink_pod: DraggablePod | undefined
    color: string
}

export const PodGrid = ({
  pods,
  cols = 9,
  rows = 6,
  showRoutes = false,
  showGridLines = false,
  allowDraggingPods = false,
  setPods,
  routes,
  addRoute,
  removeRoute,
  primaryPodId,
}: Props) => {
  const containerRef = useRef(null as null| HTMLDivElement)
  const [cellSize, setCellSize] = useState(100)
  const [newRoute, setNewRoute] = useState<DraggableRoute | undefined>()
  const [dimensions, setDimensions] = useState({ width: 10, height: 10 })

  // figure out the cellSize based on the dimensions of the layout container
  useLayoutEffect( () => {
    const h = containerRef?.current?.clientHeight
    const w = containerRef?.current?.clientWidth
    if ( containerRef && containerRef?.current && w && h ) {
      const newSize = Math.min(w / cols, h / rows)
      if (newSize !== cellSize) {
        setCellSize(newSize)
        setDimensions({ width: w, height: h })
      }
    }
  }, [])

  const [draggablePods, setDraggablePods] = useState<DraggablePod[] | undefined>(
    pods?.map((pod, index) => {
      const takenPositions = pods?.map(p => (p?.row || 0) * cols + (p?.column || 0)).sort()
      const remaining  = Array.from({ length: rows * cols }, (x, i) => i)
        .filter(pos => !takenPositions?.find(tp => tp === pos))
      return {
        index: index,
        id: pod?.id ?? '',
        // pods without existing positions get assigned the 'next' available cell
        column: pod?.column ?? remaining[index] % cols,
        row: pod?.row ?? Math.floor(remaining[index] / cols),
        x: cellSize * (pod?.column ?? remaining[index] % cols),
        y: cellSize * (pod?.row ?? Math.floor(remaining[index] / cols)),
        active: false,
        xOffset: 0,
        yOffset: 0,
        name: pod?.name ?? '',
        isAudioEnabled: pod?.audio_routing_enabled ?? false,
        isPrimary: pod?.id === primaryPodId,
        color: pod?.color ?? '#b8bdbf',
        isSource: !!routes?.find(r => r?.source_pod === pod?.id),
      }
    }),
  )

  const [draggableRoutes, setDraggableRoutes] = useState<DraggableRoute[] | undefined>(
    routes?.map( (r, index) => {
      const sink = draggablePods?.find(dp => dp?.id === r?.sink_pod?.id)
      const source = draggablePods?.find(dp => dp?.id === r?.source_pod?.id)
      return {
        index: index,
        sink_pod: sink,
        source_pod: source,
        x: -1,
        y: -1,
        color: source?.color ?? '#ffff00',
      }
    } ),
  )

  const refreshDraggableRoutes = () => {
    setDraggableRoutes(
      routes?.map( (r, index) => {
        const sink = draggablePods?.find(dp => dp?.id === r?.sink_pod?.id)
        const source = draggablePods?.find(dp => dp?.id === r?.source_pod?.id)
        return {
          index: index,
          sink_pod: sink,
          source_pod: source,
          x: -1,
          y: -1,
          color: source?.color ?? '#ffff00',
        }
      }).filter(r =>
        r?.sink_pod?.row !== undefined
          && r?.sink_pod?.column !== undefined
          && r?.source_pod?.row !== undefined
          && r?.source_pod?.column !== undefined,
      ).sort((r1, r2) => {
        // We're sorting routes by distance-squared so that short routes are on top of long routes
        // That way all routes are clickable
        /* eslint-disable-next-line max-len */
        return (
          ( (r2.source_pod!.column - r2.sink_pod!.column) ** 2
                  + (r2.source_pod!.row - r2.sink_pod!.row) ** 2 )
                - ( (r1.source_pod!.column - r1.sink_pod!.column) ** 2
                + (r1.source_pod!.row - r1.sink_pod!.row) ** 2 )
        )
      },
      ),
    )
  }

  useEffect(() => {
    refreshDraggableRoutes()
  }, [routes, draggablePods])

  useEffect(() => {
    setDraggablePods(
      pods?.map((pod, index) => {
        const takenPositions = pods?.map(p => (p?.row || 0) * cols + (p?.column || 0)).sort()
        const remaining  = Array.from({ length: rows * cols }, (x, i) => i)
          .filter(pos => !takenPositions?.find(tp => tp === pos))
  
        return {
          index: index,
          id: pod?.id ?? '',
          // pods without existing positions get assigned the 'next' available cell
          column: pod?.column ?? remaining[index] % cols,
          row: pod?.row ?? Math.floor(remaining[index] / cols),
          x: cellSize * (pod?.column ?? remaining[index] % cols),
          y: cellSize * (pod?.row ?? Math.floor(remaining[index] / cols)),
          active: false,
          xOffset: 0,
          yOffset: 0,
          name: pod?.name ?? '',
          isAudioEnabled: pod?.audio_routing_enabled ?? false,
          isPrimary: pod?.id === primaryPodId,
          color: pod?.color ?? '#b8bdbf',
          isSource: !!routes?.find(r => r?.source_pod === pod?.id),
        }
      }),
    )
  }, [pods])

  function handlePodDown(index1: number, e: React.PointerEvent<SVGElement>) {
    if (allowDraggingPods) {
      let newDraggablePods = draggablePods?.map(function(item: DraggablePod, index2: number): DraggablePod {
        if (index1 === index2) {
          const el = e.currentTarget
          const bbox = e.currentTarget.getBoundingClientRect()
          const x = e.clientX - bbox.left
          const y = e.clientY - bbox.top
          el.setPointerCapture(e.pointerId)
          return { ...item, xOffset: x, yOffset: y, active: true }
        }
        return item
      })
      setDraggablePods(newDraggablePods)
    }
        
    if (showRoutes) {
      const clickedPod = draggablePods?.find((pod, index) => index === index1)
      const isAlreadySinkPod = !!routes?.find(r => {return (r?.sink_pod?.id === clickedPod?.id)})

      if (!clickedPod || isAlreadySinkPod) return
      setNewRoute( {
        x: clickedPod?.x + halfCell,
        y: clickedPod?.y + halfCell,
        source_pod: clickedPod,
        sink_pod: undefined,
        index: draggablePods?.length ?? 0,
        color: clickedPod?.color ?? '#fd0909',
      } )
    }
  }

  function handleRouteDown(index: number) {
    const routeToRemove = draggableRoutes?.find(r => r?.index === index)
    if (removeRoute && routeToRemove) removeRoute( routeToRemove )
  }

  function handleMove(e: React.PointerEvent<SVGElement>) {
    if (showRoutes) {
      const bbox = e.currentTarget.getBoundingClientRect()
      if (!newRoute || newRoute === undefined) return
      setNewRoute({
        ...newRoute,
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      })
    }
  }
      
  function handlePodMove(index1: number, e: React.PointerEvent<SVGElement>) {
    if (allowDraggingPods) {
      let newDraggablePods = draggablePods?.map(
        (dpod: DraggablePod, index2: number) => {
          if (index1 === index2 && dpod.active === true) {
            const bbox = e.currentTarget.getBoundingClientRect()
            const x = e.clientX - bbox.left + .1 * cellSize
            const y = e.clientY - bbox.top  + .1 * cellSize
          
            const col = Math.max(Math.min(Math.floor( (dpod.x + x) / cellSize), cols - 1), 0)
            const row = Math.max(Math.min(Math.floor( (dpod.y + y) / cellSize), rows - 1), 0)

            // make sure we don't drag a pod into another pod's location
            const collisionPod = draggablePods.find( dp => {
              return ( dp.index !== index1 && dp.column === col && dp.row === row )
            })
            if (collisionPod) return dpod
      
            const newX = cellSize * col
            const newY = cellSize * row
          
            return {
              ...dpod,
              x: newX,
              y: newY,
              column: col,
              row: row,
            }
          }
          return dpod
        })
      setDraggablePods(newDraggablePods)
    }
  }
      
  async function handlePodUp(index1: number) {
    if (allowDraggingPods) {
      let newDraggablePods = draggablePods?.map(function(el: DraggablePod, index2: number): DraggablePod {
        if (index1 === index2) {
          return { ...el, active: false }
        }
        return el
      })
      setDraggablePods(newDraggablePods)
      if (setPods) {
        setPods( pods?.map(p => {
          const draggablePod = newDraggablePods?.find(dp => dp.id === p?.id)
          return {
            ...p,
            audio_routing_enabled: draggablePod?.isAudioEnabled,
            column: draggablePod?.column,
            row: draggablePod?.row,
          }
        }),
        )
      }
    }

    if (showRoutes) {
      if (!newRoute || !addRoute) return
      const to = {
        row: Math.floor((newRoute.y ?? -1) / cellSize),
        col: Math.floor((newRoute.x ?? -1) / cellSize),
      }
      const sinkPod = draggablePods?.find(p => p.row === to.row && p.column === to.col)

      // user was in the middle of drawing a route, and lifted above a pod,
      // if that pod is a valid source for this route, add it
      if (newRoute && newRoute?.source_pod && sinkPod && sinkPod?.index !== newRoute?.source_pod?.index) {
        // is the potential new sink pod already a source?  that isn't allowed
        const existingRouteSource = routes?.find(r => r?.source_pod?.id === sinkPod.id)
        if (!existingRouteSource) await addRoute({ ...newRoute, sink_pod: sinkPod })
      }
      setNewRoute(undefined)
    }
  }

  function handleUp() {
    setNewRoute(undefined)
  }

  const halfCell = cellSize * .5

  const colInts = Array(cols).fill(1).map((element, index) => index)
  const rowInts = Array(rows).fill(1).map((element, index) => index)

  return (<div className={styles.layout_container} ref={containerRef} id="podGridWrapper">
    <svg
      width={dimensions?.width ?? 1900}
      height={dimensions?.height ?? 600}
      className="podGridSvg"
      onPointerMove={e => handleMove(e)}
      onPointerUp={handleUp}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" className={styles.gradientRectStop1} />
          <stop offset="100%" className={styles.gradientRectStop2} />
        </linearGradient>
      </defs>

      <rect
        width={dimensions?.width ?? 100}
        height={dimensions?.height ?? 100}
        fill="url(#grad1)"
      />
            
      {showGridLines && rowInts.map(r => {
        return (<line
          key={' ' + r}
          x1={0}
          y1={r * cellSize}
          x2={cellSize * cols}
          y2={r * cellSize}
          stroke="#aaaaaa"
          strokeWidth={1}
        ></line>)
      })}

      {showGridLines && colInts.map(c => {
        return (<line
          key={' ' + c}
          x1={c * cellSize}
          y1={0}
          x2={c * cellSize}
          y2={cellSize * rows}
          stroke="#aaaaaa"
          strokeWidth={1}
        ></line>)
      })}

      {newRoute !== undefined && newRoute?.source_pod?.x !== undefined && newRoute?.x !== undefined
      && (<Route
        index={9999}
        key="newRouteKey"
        x1={(newRoute?.source_pod?.column ?? 0)   * cellSize + halfCell}
        y1={(newRoute?.source_pod?.row ?? 0)      * cellSize + halfCell}
        x2={(newRoute?.x ?? 0)}
        y2={(newRoute?.y ?? 0)}
        color={newRoute?.source_pod?.color ?? '#00ffff'}
      />
      )}

      {showRoutes && draggableRoutes && ( draggableRoutes.map((route, index) => {
        return route?.source_pod?.column !== undefined && route?.source_pod?.row !== undefined
        && route?.sink_pod?.column !== undefined && route?.sink_pod?.row !== undefined
        && (<Route
          key={'routes' + index}
          index={route.index}
          x1={(route?.source_pod?.column)   * cellSize + halfCell}
          y1={(route?.source_pod?.row)      * cellSize + halfCell}
          x2={(route?.sink_pod?.column)     * cellSize + halfCell}
          y2={(route?.sink_pod?.row)        * cellSize + halfCell}
          color={route?.source_pod?.color ?? '#00ffff'}
          isAnimated
          animationPeriod={3}
          animationDelay={route?.source_pod?.column * .2 / draggableRoutes.length}
          handleRouteDown={handleRouteDown}
        ></Route>)
      })
      )}

      {draggablePods?.map(function(el: DraggablePod, index: number) {
        return (<Pod
          id={index}
          key={'pods' + index}
          name={el.name ?? ''}
          x={el.column * cellSize}
          y={el.row * cellSize}
          width={cellSize}
          height={cellSize}
          color={el.color as Color ?? '#000000'}
          sourceColors={draggableRoutes?.filter(r => r?.sink_pod?.id === el.id).map(r => r.color as Color)}
          audio={el.isAudioEnabled}
          isNewSource={showRoutes && newRoute?.source_pod?.id === el.id}
          disabled={showRoutes && !!newRoute && !!routes?.find(r => r?.source_pod?.id === el.id)}
          active={el.active}
          isPrimary={el.isPrimary}
          handlePointerDown={handlePodDown}
          handlePointerUp={handlePodUp}
          handlePointerMove={handlePodMove}
        ></Pod>)
      })}
    </svg>

  </div>)
}
