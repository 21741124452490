import { logout } from 'shared/core/authenticate'
import authService from 'shared/services/auth'
import { parseUserDetailsFromToken } from 'shared/services/auth/helpers'
import { Dispatch } from 'shared/types/redux'
import { Role } from 'shared/types/Role'
import { AuthenticatedUserClaims } from 'shared/types/AuthenticatedUserClaims'
import { showError } from '../slices/alert'
import { State } from '../slices/reducerUtils'
import { getMe } from '../selectors/account'
import { setUser } from '../slices/account'

export const SET_USER = 'SET_USER'

/// /////////////////////////////////////////////////////////

export const RECEIVE_AUTHORIZATION = 'RECEIVE_AUTHORIZATION'

export const fetchUserAuthorization = () => async (dispatch: Dispatch, getState: () => State) => {
  try {
    const { authorization } = await authService.getUserAuthorization()
    if (!authorization || !authorization.roles || !authorization.roles.length) {
      throw 'Role did not come back from api'
    }
    const { role } = getMe(getState())
    const myCurrentRole: Role = role === 100 as Role ? Role['Super Admin'] : role // 100/"Admin" has been removed
    const fetchedRole: number = authorization.roles[0].id

    // Compare the latest role with the one stored in the auth token
    if (myCurrentRole && fetchedRole !== myCurrentRole) {
      return logout()
    }
    dispatch({ type: RECEIVE_AUTHORIZATION, data: { authorization } })
  } catch {
    dispatch(showError('Error fetching user details'))
  }
}

/// /////////////////////////////////////////////////////////

export const setUserInfo = (accessToken?: string) => (dispatch: Dispatch) => {
  const token = accessToken || localStorage.getItem('access_token')
  const {
    userEmail,
    roles,
    permissions,
    orgId,
    ssoOnly,
  } = parseUserDetailsFromToken(token as string) as AuthenticatedUserClaims
  const role = roles?.[0].id ?? Role.Viewer
  dispatch(setUser(
    {
      name: userEmail,
      showBanner: true,
      token,
      permissions,
      role,
      org: orgId,
      ssoOnly,
    },
  ))
  dispatch(fetchUserAuthorization())
}
