import React, { useState, useEffect } from 'react'
import { SolDonutChart, SolCard } from 'SolComponents'
import styles from './Status.module.scss'
import { Header } from 'semantic-ui-react'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { isEmpty } from 'SolComponents/SolDonutCard/SolDonutCard'
import { TestingProps } from 'shared/types/TestingProps'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'

interface ChartProps extends TestingProps {
  title: string
  data: PieSector[] | undefined
  loading: boolean
}

export enum StatusColors {
  ERROR = '#bf1244',
  WARNING = 'TBD',
  SUCCESS = '#66cc33'
}

export const labelColor = '#1279C6'

const Chart = ({ data, title, loading = true, dataTestId }: ChartProps) => {
  const [hasLoaded, setHasLoaded] = useState(0)

  useEffect(() => {
    if (loading === false && hasLoaded === 0) {
      setHasLoaded(1)
    }
  }, [loading])

  return (
    <SolCard className={styles.card}>
      <div data-testid={dataTestId}>
        <Header as="h2" textAlign="center" content={title} />
        <LoadingWrapper
          isEmpty={!loading && isEmpty(data)}
          isLoading={hasLoaded !== 1}
        >
          <SolDonutChart
            pieData={data || []}
            verticalAlign="bottom"
            chartHeight={260}
            xPosition="50%"
            yPosition="50%"
            legendWidth={180}
            legendAlignment="center"
            responsiveWidth="100%"
            isAnimationActive={false}
          />
        </LoadingWrapper>
      </div>
    </SolCard>
  )
}

export default React.memo(Chart, (prev, next) => {
  return (
    JSON.stringify(prev.data) === JSON.stringify(next.data)
    && prev.loading === next.loading
  )
})
