import React from 'react'
import { IpType, NetworkConfig } from 'graphql/__generated__/types'
import StaticOptions from '../../Common/StaticOptions'
import { SolButtonGroup, SolButtonGroupProps, SolTooltip } from 'SolComponents'
import { getIpAddress, setIpAddress } from 'shared/config/wifi'
import styles from '../Wifi.module.scss'

export interface Props {
  config: NetworkConfig
  updateConfiguration: (newSettings: NetworkConfig) => void
  podId?: string
  isDefault: boolean
}
function IpConfiguration({ updateConfiguration, config, podId, isDefault }: Props) {
  const IpTypeButtons: SolButtonGroupProps = [
    {
      primary: config?.ipType === IpType.Dhcp,
      value: IpType.Dhcp,
      content: 'DHCP',
      onClick: (value: any) => {
        updateConfiguration({
          ...config,
          ipType: value,
        })
      },
    },
    {
      disabled: isDefault,
      primary: config?.ipType === IpType.Static,
      value: IpType.Static,
      content: 'Static IP',
      onClick: (value: any) => {
        updateConfiguration({
          ...config,
          ipType: value,
        })
      },
    },
  ]

  return (
    <div>
      {isDefault ? (
        <SolTooltip
          hoverable
          position="top left"
          trigger={<SolButtonGroup buttons={IpTypeButtons} />}
          /* eslint-disable-next-line max-len */
          text="Static IP is not available for default templates. Create a custom template if you need to use static IPs."
          isInline
          className={styles.staticIpTooltip}
        />
      ) : (
        <SolButtonGroup buttons={IpTypeButtons} />
      )}
      {config?.ipType === IpType.Static && (
        <StaticOptions
          section="Ethernet"
          networkConfig={config}
          getIpAddress={getIpAddress}
          updateUniqueSettingsConfig={setIpAddress}
          updateNetworkConfig={(c: NetworkConfig) => {
            updateConfiguration(c)
          }}
          podId={podId}
        />
      )}
    </div>
  )
}

export default IpConfiguration
