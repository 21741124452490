import React from 'react'
import { Message } from 'semantic-ui-react'
import root from 'window-or-global'
import ieDetect from 'shared/core/ieDetect'

const isIE = ieDetect()
const self = root.location && root.location.href

class IEWarning extends React.Component {
  state = { visible: isIE }

  handleDismiss = () => {
    this.setState({ visible: false })
  }

  content = () => (
    <div
      style={{
        marginTop: '1em',
      }}
    >
      <p>
        Solstice Cloud is optimized for use with&nbsp;
        <a href="https://www.google.com/chrome" target="_blank" rel="noreferrer">
          Chrome
        </a>
        ,&nbsp;
        <a href="https://www.mozilla.org/en-US/firefox" target="_blank" rel="noreferrer">
          Firefox
        </a>
        ,&nbsp; and{' '}
        <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noreferrer">
          Edge
        </a>
        . We highly recommend using one of these supported browsers when visiting the site.
      </p>
      Already installed? <a href={`microsoft-edge:${self}`}>Click here</a> to open this site in Edge.
    </div>
  )

  render() {
    const { visible } = this.state
    if (visible) {
      return (
        <Message
          style={{
            marginBottom: '-1em',
          }}
          warning
          onDismiss={this.handleDismiss}
          header="Internet Explorer Detected"
          content={this.content()}
        />
      )
    }

    return null
  }
}

export default IEWarning
