import React, { createRef } from 'react'
import Carousel, { ResponsiveType } from 'react-multi-carousel'

import styles from './SolThreeCardCarousel.module.scss'
import { SolArrowButton } from 'SolComponents'

type Props = {
  aspectRatio?: number
  draggable?: boolean
  children: React.ReactNode
}

export const topCarousel: ResponsiveType = {
  desktop: {
    breakpoint: { max: 9999, min: 1575 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1575, min: 1140 },
    items: 2,
    partialVisibilityGutter: 10,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 1140, min: 0 },
    items: 1,
    partialVisibilityGutter: 44,
    slidesToSlide: 1,
  },
}

const SolThreeCardCarousel = (props: Props) => {
  const carouselRef = createRef<Carousel>()

  function onResize() {
    if (carouselRef && carouselRef.current) {
      carouselRef?.current?.goToSlide(0)
    }
  }
  let doit = setTimeout(onResize, 500)
  window.addEventListener('resize', function() {
    clearTimeout(doit)
    doit = setTimeout(onResize, 500)
  })

  return (
    <Carousel
      ref={carouselRef}
      autoPlay={false}
      shouldResetAutoplay={false}
      responsive={topCarousel}
      ssr={!!props?.aspectRatio}
      draggable={props.draggable}
      deviceType={props?.aspectRatio ? 'desktop' : ''}
      containerClass={styles.topRow}
      partialVisible
      customLeftArrow={<SolArrowButton direction="left" />}
      customRightArrow={<SolArrowButton direction="right" />}
    >
      {props.children}
    </Carousel>
  )
}

SolThreeCardCarousel.defaultProps = {
  draggable: false,
}

export default SolThreeCardCarousel
