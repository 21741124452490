import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { Column, DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  CreateSolsticeRoutingSpaceInput,
  SolsticeRoutingPodInput,
  SolsticeRoutingRoutePresetInput,
  SolsticeRoutingSpace,
  useCreateSolsticeRoutingSpaceMutation,
  useDeleteSolsticeRoutingSpaceMutation,
  useSolsticeRoutingSpaceLazyQuery,
} from 'graphql/__generated__/types'
import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import styles from './style.module.scss'
import { capitalize } from 'lodash'
import {
  SolEllipsesDropdown,
  SolAlertMessage,
  SolConfirmationModal,
} from 'SolComponents'
import { Link } from 'react-router-dom'
import { omit, pick } from 'lodash'

/**
 * The Solstice Routing Spaces List is a typical CRUD table of Spaces, 
 * including a duplication action.
 */

type Props = {
    spaces: SolsticeRoutingSpace[]
    refetchSpaces: () => void
    totalSpaces: number
    spacesLoading: boolean
}

const SpacesList = ({
  spaces,
  refetchSpaces,
  totalSpaces,
  spacesLoading,
  history,
}: Props & RouteComponentProps) => {
  const [toast, setToast] = useState<string | null>(null)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)

  const [deleteSolsticeRoutingSpaceMutation] = useDeleteSolsticeRoutingSpaceMutation()
  const [createSolsticeRoutingSpaceMutation] = useCreateSolsticeRoutingSpaceMutation()
  const [lazySpaceQuery] = useSolsticeRoutingSpaceLazyQuery()
  const createSpace = async (options: CreateSolsticeRoutingSpaceInput) => {
    const createdSpace = (await createSolsticeRoutingSpaceMutation(
      { variables: { options } },
    )).data!.createSolsticeRoutingSpace
    return createdSpace
  }

  const dupeSpace = async (space: SolsticeRoutingSpace) => {
    if (!space || !space?.id) return
    const spaceId = space.id
    lazySpaceQuery({ variables: {
      spaceId: spaceId.toString(),
    } }).then(res => {
      const sp = res.data?.solsticeRoutingSpace!
      createSpace(omit({
        ...sp,
        name: sp.name + ' - Copy',
        route_presets: sp.route_presets!.map( rp =>
          omit({ ...rp, routes: rp?.routes?.map( r =>
            omit({
              ...r,
              sink_pod: pick(r?.sink_pod, ['id']),
              source_pod: pick(r?.source_pod, ['id']),
            }, '__typename'),
          ) }, '__typename')) as SolsticeRoutingRoutePresetInput[],
        pods: sp?.pods!.map(p => pick(p, [
          'audio_routing_enabled', 'color', 'column', 'id', 'row',
        ])) as SolsticeRoutingPodInput[],
      }, ['id', 'updated_at', 'last_used', 'created_at', '__typename'])).then(() => {
        setToast('Duplicate created')
        refetchSpaces()
      })
    })
  }


  const spaceEditDropdown = (row: SolsticeRoutingSpace) => {
    if (!row) return
    return (
      <>
        <SolConfirmationModal
          isOpen={deleteModalIsOpen}
          modalText={
            <div className={styles.deleteModalText}>
              Are you sure you want to delete{' '}
              <strong>{row.name}?</strong>
            </div>
          }
          subHeaderText={`This will unassign all pods from this space, and can't 
          be undone`}
          onAcceptingAction={() => {
            deleteSolsticeRoutingSpaceMutation({
              variables: { spaceId: row.id!.toString() },
            }).then(res => {
              if (res.data?.deleteSolsticeRoutingSpace.success) {
                setToast('Space deleted')
                refetchSpaces()
              }
            })
            setDeleteModalIsOpen(false)
          }}
          onCloseModal={() => setDeleteModalIsOpen(false)}
          acceptingText="Delete"
          cancelText="Cancel"
        />
        <SolEllipsesDropdown
          direction="left"
        >
          <Dropdown.Menu className="direction">
            <Dropdown.Item icon="pencil alternate" text="Edit Space" onClick={() => {
              history.replace('/solstice-routing/space/' + row?.id)
            }} />
            <Dropdown.Item icon="copy outline" text="Duplicate Space" onClick={() => dupeSpace(row)} />
            <Dropdown.Item
              icon="trash alternate outline"
              text="Delete Space"
              onClick={() => setDeleteModalIsOpen(true)}
            />
          </Dropdown.Menu>
        </SolEllipsesDropdown>
      </>
    )
  }

  const columns: Column<SolsticeRoutingSpace>[] = [
    {
      name: 'name',
      displayName: 'Name',
      centered: false,
      render: (row: SolsticeRoutingSpace) => {
        return (
          <Link to={`/solstice-routing/space/${row?.id}`}>{row?.name}</Link>
        )
      },
    },
    {
      name: 'building_name',
      displayName: 'Building',
      centered: false,
      render: (row: SolsticeRoutingSpace) => row?.building_name,
    },
    {
      name: 'room_number',
      displayName: 'Room',
      centered: false,
      render: (row: SolsticeRoutingSpace) => row?.room_number,
    },
    {
      name: 'space_type',
      displayName: 'Type',
      centered: false,
      render: (row: SolsticeRoutingSpace) => capitalize( row?.space_type ?? ''),
    },
    {
      name: 'edit-space',
      displayName: 'Edit',
      centered: false,
      render: (row: SolsticeRoutingSpace) => spaceEditDropdown(row),
      sortable: false,
    },
  ]

  const newSpaceButton = (
    <Button
      primary
      disabled={spacesLoading}
      onClick={() => { history.replace('/solstice-routing/space/') }}
    >
      <Icon name="add circle"/>
      Create New Space
    </Button>
  )

  return (<div>
    <SolAlertMessage message={toast ?? ''} show={!!toast} type="success" onDismiss={() => setToast(null)} />
    <ContentWrapper>
      <div className={styles.header}>
        <span className={styles.grey}>Solstice Routing</span>
        <Icon name="angle right" color="grey" />
        <span>Spaces</span>
      </div>

      <div className={styles.solsticeRoutingDescription}>
        Solstice Routing is the future of Solstice Active Learning! Make sure
        you have the new Solstice Routing app (separate from the Active
        Learning app). Then come to this page to create, configure, and manage
        your routing spaces (similar to rooms in Active Learning).
        <Link to="/"> Learn more</Link>
      </div>

      <div data-testid="spaces-table" className={styles.table}>
        {ServerDataTable<SolsticeRoutingSpace>({
          addCategoryCols: false,
          addableColumns: false,
          allowExportToCsv: false,
          columns: columns,
          columnManager: () => newSpaceButton,
          data: spaces,
          loading: spacesLoading,
          padded: false,
          id: DataTableInstance.SolsticeRoutingSpaces,
          scrollable: false,
          title: 'Spaces',
          totalItems: totalSpaces,
        })}
      </div>

    </ContentWrapper>
  </div>)
}

export default withRouter( SpacesList )
