import React from 'react'
import PencilIcon from 'mdi-react/PencilIcon'
import styles from './SolEditIcon.module.scss'
import classNames from 'classnames'
type Props = {
  className?: string
  onClick?: () => void
}

const SolEditIcon = ({ className, onClick }: Props) => (
  <PencilIcon size="20" className={classNames(styles.pencil, className)} onClick={onClick} />
)

export default SolEditIcon
