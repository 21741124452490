import {
  SettingsGroupType,
  Template,
} from '../../../graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultProxyTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Proxy',
    type: SettingsGroupType.Proxy,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      http: {
        enabled: false,
        ipAddress: '',
        port: 1,
        username: '',
        password: '',
        exclusionList: '',
        bypassOnSameSubnet: false,
      },
      https: {
        enabled: false,
        ipAddress: '',
        port: 2,
        username: '',
        password: '',
        exclusionList: '',
        bypassOnSameSubnet: false,
      },
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
