import { Store } from 'redux'
import { Dispatch } from 'react'

import {
  State,
} from 'shared/store/slices/reducerUtils'

import {
  MxRenewLeadId,
  MxRenewLeadInterface,
  MxRenewServiceInterface,
  CreateMxRenewLeadInterface,
} from 'shared/services/mxRenew/types'

import mxRenewService from 'shared/services/mxRenew'
import {
  createMxRenewLeadSuccess,
  updateMxRenewLeadSuccess,
} from '../slices/maintenance'

/*
 * CREATE_MX_RENEW_LEAD
 */

export const CREATE_MX_RENEW_LEAD = 'CREATE_MX_RENEW_LEAD'
type CreateMxRenewLeadActionType = 'CREATE_MX_RENEW_LEAD'

export interface CreateMxRenewLeadAction {
  type: CreateMxRenewLeadActionType
  data: {
    lead: CreateMxRenewLeadInterface
    promise: Promise<any>
  }
}

export const createMxRenewLead = (
  lead: MxRenewLeadInterface,
  nextStep: number,
  service: MxRenewServiceInterface = mxRenewService,
) => (
  dispatch: Dispatch<Store<State>>,
): CreateMxRenewLeadAction => {
  const promise = createMxRenewLeadRequest(
    lead,
    nextStep,
    service,
  )(dispatch)
  return {
    type: CREATE_MX_RENEW_LEAD,
    data: {
      lead,
      promise,
    },
  }
}

export const createMxRenewLeadRequest = (
  lead: MxRenewLeadInterface,
  nextStep: number,
  service: MxRenewServiceInterface = mxRenewService,
) => (
  dispatch: Dispatch<any>,
): Promise<any> => {
  return service
    .createLead(lead)
    .then(createdLead => dispatch(
      createMxRenewLeadSuccess({ createdLead, nextStep }),
    ))
  // TODO DONT COMMMIT
    .catch(() => dispatch(
      createMxRenewLeadSuccess({
        currentLead: { id: '123', step: nextStep, maxStep: 0 },
        nextStep,
      }),
    ))
}

/*
 * UPDATE_MX_RENEW_LEAD
 */

export const UPDATE_MX_RENEW_LEAD = 'UPDATE_MX_RENEW_LEAD'
type UpdateMxRenewLeadActionType = 'UPDATE_MX_RENEW_LEAD'

export interface UpdateMxRenewLeadAction {
  type: UpdateMxRenewLeadActionType
  data: {
    leadId: MxRenewLeadId
    update: Partial<MxRenewLeadInterface>
    promise: Promise<any>
  }
}

export const updateMxRenewLead = (
  leadId: MxRenewLeadId,
  update: MxRenewLeadInterface,
  nextStep: number,
  service: MxRenewServiceInterface = mxRenewService,
) => (
  dispatch: Dispatch<Store<State>>,
): UpdateMxRenewLeadAction => {

  const promise = updateMxRenewLeadRequest(
    leadId,
    update,
    nextStep,
    service,
  )(dispatch)
  return {
    type: UPDATE_MX_RENEW_LEAD,
    data: {
      leadId,
      update,
      promise,
    },
  }
}

export const updateMxRenewLeadRequest = (
  leadId: MxRenewLeadId,
  update: MxRenewLeadInterface,
  nextStep: number,
  service: MxRenewServiceInterface = mxRenewService,
) => (
  dispatch: Dispatch<any>,
): Promise<any> => {
  return service
    .updateLead(leadId, update)
    .then(updatedLead => dispatch(
      updateMxRenewLeadSuccess({ updatedLead, nextStep }),
    ))
    .catch(() => dispatch(
      updateMxRenewLeadSuccess({
        updatedLead: { step: update.step, ...update as any },
        nextStep,
      }),
    ))
}
