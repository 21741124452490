import { decode } from 'jsonwebtoken'

import getStore from 'shared/store/store'

export const getEmail = (): string =>
  (decode(localStorage.access_token) || {})['https://mersive.com/person'] || ''

export const logout = (redirect: boolean = true) => {
  localStorage.removeItem('id_token')
  localStorage.removeItem('access_token')
  localStorage.removeItem('expires_at')

  getStore().dispatch({
    type: 'user/setUser',
    payload: { token: null },
  })

  const { hostname, port = '', protocol } = window.location

  if (redirect) {
    const loginUrl = `${protocol}//${hostname}${port ? ':' + port : ''}/login`
    window.location.assign(loginUrl)
  }
}
