import React from 'react'

import getHistory from 'shared/core/history'
import { sign } from 'jsonwebtoken'

// dummy key that shouldn't be used for anything serious:
const key = `
-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEAqo0piLq6/kNFBaeYhjaU2s+L30LTmKkb3JTJpqU+S4ygeIRq
mfYXkhucalNkkv29q0Rfje56UaCOKxTZ0dSMVLH++fCjVj06zWrQZdZzNbsuqjj2
Op9iPu47E5DwhAgkF2ZAtQGVToOilBa8erWBqAO9fa3nJKDQCUU5iZaiYhC5INZD
Cbc7ezf93wN+XrhSiCUX8DKa04PrM2BW8DzfPsJr5HWPAZO5v9zAHgb96hlW0T8A
F20SWFGG/TUKmd9HUPxDjOAT0xrouv+wf2DNW+nS969I4F9ZfpCsJ7BSMzxvGoXJ
nEuCaxImNxW/UHV8XnIiNbMYwlUXMJmhZVSbSwIDAQABAoIBAArRa2oF1iicobsr
F0ykF6sNcSTXO066o07JjUTpxFrMb71VQOHFL1eQMsq3wXBlcet0Kc956f5Vfc8T
woFBxyj0JWU7M1EcDyfKd6+N+77TwbEAbfXn+DZfrBWgovDtB0UIsmAI2qyhQuW7
z3bFHpVGr3VSW5ACN0/vOSgWCvTHWkE7liLbojFm+JHSzy5mvWf+4nY0vwtbBiuT
PRwYMUh9oOOI+L/lFxKvRu5hAbeSMYC3968BRAbHsiodEtG0+96N038wN3YVD/jz
chmNTb6w/3uoO4su46JiFbQ5zdZvHfRUh0w75phRYS80sgVnPQ7NmSvor4JGjcgO
wIgZFnECgYEA18qcv6OaW7xNr42M/v+bBh/KwrI9RqsvF/z0GOvT2p16U89bXMMW
EzdjdyCj3LD7EXqoSen1BmU23VXcNNf6Gk1zu1AEBMyHgMfRV8NXPBIZLMF+UECy
/dUyFIFDDEps7jGEHDYq0Ejy0NhBHES9kDF7GpsYnvZ/nBE7JZyG5ZkCgYEAylSS
WsnUY0jZza8rLnBNCXu7yl339h0+Acs+UiJh6a0caI+pLJYx2A9B0pZNI4cWcf3B
20CQD+NbJLVABW+h9BP+pR6EUx4w/kXccDKfAskvKxEyj1pdqpgU/tgIBuU91g0/
xpnYWLOVLBLoBYK9/amhPw8QwDAWQzqt09tszoMCgYEAqmEGbGVffejqqZ6o4bmt
jCNT82/J499LWrRVgsvIxQarzDqfl1lNN7T26tsxphFy2rUu5NU9qmQF8rJVIMAa
b+QRaabe1W8r2elrB9FrMjnT+Lt54SGEcdiAJyJ8btrgsR0VgzTdjwDZ/z2QAyLf
TVdULIii4vgbf303ZxX0iJkCgYB0774KdL2oiDFBXC0T0NnlUjrtsbOe7bWpqcKi
C7cVK/TQRb9bwo6aNe8v+6gnkWqahTOmOUFJvXgu1gZjXep3O5sf6WxG8ZsVSmEm
A0u9e3Mka/MRwQODlhQEWe0Btzou3Udvy/irA1eCuoaYsMCHu6My480llmU3g9F1
MUtIawKBgQCRENG6/oc6kgDizfQmN8b9/kyl6GnZAiwVWc5GU6KPLrcBurpWrsdg
YAq4R7RVJvleAScfndK6LJSNjfxGeT67O0aG/3oKHmRPBv8RPHFWC8jDo8D593Zc
EYsbmBG2f1PVyPt/sVwsMvHy16Fh+XvRi8C4OPfynMfghdDfagcyAg==
-----END RSA PRIVATE KEY-----
`

class SelfAuthPage extends React.Component<{}, { email: string; org: string }> {
  constructor(props: {}) {
    super(props)
    this.state = { email: '', org: 'mersive' }
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleOrgChange = this.handleOrgChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ email: event.target.value, org: this.state.org })
  }

  handleOrgChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ email: this.state.email, org: event.target.value })
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const token = sign(
      {
        'https://mersive.com/org': this.state.org,
        'https://mersive.com/person': this.state.email,
        'https://mersive.com/consume': 'true',
        'https://mersive.com/produce': 'true',
        exp: 9999999999999999999999999,
      },
      key,
      { algorithm: 'RS256' },
    )

    localStorage.setItem('access_token', token)
    localStorage.setItem('org', this.state.org)
    localStorage.setItem('email', this.state.email)
    localStorage.setItem('expires_at', JSON.stringify(2000000000000))

    getHistory().push('/home')

    event.preventDefault()
  }

  render() {
    const style = {
      page: {
        width: '100%',
        padding: '15px',
        overflowY: 'scroll',
      } as React.CSSProperties,
    }

    return (
      <form onSubmit={this.handleSubmit} style={style.page}>
        <label>
          Email Address:
          <input
            type="text"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
        </label>
        <label>
          Organization:
          <input
            type="text"
            value={this.state.org}
            onChange={this.handleOrgChange}
          />
        </label>
        <button onClick={(e: any) => this.handleSubmit(e)}>Login</button>
      </form>
    )
  }
}

export default SelfAuthPage
