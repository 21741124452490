import { useSearchParams } from 'components/DataTableSlim/Hooks/useSearchDisplays'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import {
  ViewDisplays,
  useExpiredDisplaysQuery,
  useDisplayCountQuery,
  OrderBy,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import cloneDeep from 'lodash/cloneDeep'
import { getSearchIdArgs } from 'components/DataTableSlim/Utils/hasuraUtils'

export const sortMapper = (field: string, direction: OrderBy) => {
  const orderStatement = { [field]: direction }
  if (field === 'maintenance') {
    return {
      subscription: {
        subscription_date: direction,
      },
    }
  }
  return orderStatement
}

export const searchMapper = (searchTerm: String) => {
  const term = `%${searchTerm}%`

  const cb = (field: string) => ({ [field]: { _ilike: term } })

  const displayColumns = ['name'].map(cb)

  return { _or: [...displayColumns] }
}

export const useTable = () => {
  const {
    searchValue,
    page,
    orderBy: orderByTable,
    setOrderBy,
    selectedItems,
  } = useDataTable<ViewDisplays>(DataTableInstance.RenewStep1)

  const {
    handleOrderBy,
    ...searchOptions
  } = useSearchParams<ViewDisplaysBoolExp>({
    tableInstance: DataTableInstance.RenewStep1,
    page,
    search: searchValue,
    orderBy: orderByTable,
    setOrderBy,
    sortMapper,
    searchMapper,
  })

  let selectedIdsFilter: ViewDisplaysBoolExp = {}
  if (selectedItems?.includedIds || selectedItems?.excludedIds) {
    selectedIdsFilter = getSearchIdArgs({
      includedIds: selectedItems.includedIds,
      excludedIds: selectedItems.excludedIds,
    })
  }

  searchOptions.where._and?.push(
    {
      subscription: {
        is_in_subscription: { _eq: false },
        _or: [
          { flexera_maintenance_end: { _is_null: false } },
          { maintenance_end: { _is_null: false } },
        ],
      },

    },
    {
      deployment: {
        license: { _neq: 'Element' },
      },
    },
  )

  const selectedItemsWhere = cloneDeep(searchOptions.where)
  selectedItemsWhere._and?.push(selectedIdsFilter)

  const {
    data: expiredData,
    loading: isTableLoading,
  } = useExpiredDisplaysQuery({ variables: searchOptions })

  const {
    data: totalDisplaySelected,
    loading: isTotalDisplaysSelectedLoading,
  } = useDisplayCountQuery({ variables: { where: selectedItemsWhere } })

  const {
    data: totalDisplay,
    loading: isTotalDisplaysLoading,
  } = useDisplayCountQuery({ variables: { where: searchOptions.where } })

  const totalItems = totalDisplay?.totalDisplays.aggregate?.count || 0

  const totalItemsSelected = totalDisplaySelected?.totalDisplays.aggregate?.count || 0

  const isLoading
    = isTableLoading || isTotalDisplaysLoading || isTotalDisplaysSelectedLoading

  return {
    tableData: expiredData?._displays,
    isLoading,
    totalItems,
    totalItemsSelected,
    handleOrderBy,
    selectedItemsWhere,
  }
}
