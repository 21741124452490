import React, { useState, useEffect } from 'react'
import { LatLng, divIcon } from 'leaflet'
import { useLeaflet } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { RealtimeDisplaysQuery } from 'graphql/__generated__/types'
import styles from './Map.module.scss'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import MapDot from 'SolComponents/Icons/SolGpsIcon/MapDot'
import { renderToString } from 'react-dom/server'
import { validateCoordinates } from 'shared/helpers/coordinates'
import MarkerCluster from 'components/Location/Clustering/MarkerCluster'
import { PodsClusterGroup } from 'components/Location/Clustering/ClusterTypes'

type Props = {
  data?: RealtimeDisplaysQuery
  zoomToBounds: boolean
  showCluster: boolean
}

export function PodsOnMap(props: Props) {
  const [orgPods, setOrgPods] = useState<MarkerObject[]>([])

  useEffect(() => {
    if (props?.data) {
      const locationData = props.data.realtimeDisplays

      const locations: MarkerObject[] = locationData
        .filter(display => {
          const lat = display.preciseLat
          const long = display.preciseLong
          return validateCoordinates(lat, long)
        })
        .map(
          display =>
            ({
              id: display.display,
              name: display.name ?? '',
              latlng: new LatLng(display.preciseLat, display.preciseLong),
              status: display.realtimeStatus,
            } as MarkerObject),
        )
      setOrgPods(locations)
    } else {
      setOrgPods([])
    }
  }, [props.data])

  const dot = divIcon({
    html: renderToString(<MapDot color="#3388ff" />),
    className: `${styles.podDot}`,
  })

  const [zoom, setZoom] = useState(5)
  const leaflet = useLeaflet()

  useEffect(() => {
    leaflet.map?.addEventListener('zoomend', () => {
      setZoom(leaflet.map?.getZoom() ?? zoom)
    })
  }, [])

  return (
    <div>
      <MarkerCluster
        mcg={PodsClusterGroup}
        markersList={orgPods}
        showCluster
        buttonPopUp
        fitBounds={false}
        type="StatusPod"
        icon={dot}
      />
    </div>
  )
}
export default PodsOnMap
