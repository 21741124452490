import React from 'react'
import classNames from 'classnames'
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon'
import styles from './SolGpsTargetIcon.module.scss'

const SolGpsTargetIcon = (props: {
  className?: string
  onClick?: () => void
  color?: string
}) => (
  <CrosshairsGpsIcon
    className={classNames(styles.gpsTargetIcon, props.className)}
    onClick={props.onClick}
    color={props.color ?? 'black'}
  />
)

export default SolGpsTargetIcon
