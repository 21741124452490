import React from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { toPercentage } from 'shared/core/utils'
import { SolDonutCard } from 'SolComponents'
import styles from './UsbDeviceAvailabilityCard.module.scss'
import { FillColor } from 'SolComponents/SolChart/chartUtils'

type Props = {
  addAvailabilityFilter: (
    availability: boolean, displayName: string, color: SemanticCOLORS
    ) => void
    usbOnlineDeviceCount: number
    usbOfflineDeviceCount: number
    usbDevicesDataLoading: boolean
}

export const UsbDeviceAvailabilityCard = ({
  addAvailabilityFilter,
  usbOnlineDeviceCount,
  usbOfflineDeviceCount,
  usbDevicesDataLoading,
}: Props) => {
  const totalCount = usbOnlineDeviceCount + usbOfflineDeviceCount

  const chartData: PieSector[] = [
    {
      name: 'Online',
      count: usbOnlineDeviceCount,
      percentage: toPercentage(usbOnlineDeviceCount, totalCount),
      fill: FillColor.Green,
      onClick: () => addAvailabilityFilter(true, 'Online', 'green'),
    },
    {
      name: 'Offline',
      count: usbOfflineDeviceCount,
      percentage: toPercentage(usbOfflineDeviceCount, totalCount),
      fill: FillColor.Red,
      onClick: () => addAvailabilityFilter(false, 'Offline', 'red'),
    },
  ]

  const title = <div className={styles.title}>Device Status</div>

  return (
    <SolDonutCard
      className={styles.card}
      data={chartData}
      isLoading={usbDevicesDataLoading}
      chartProps={{
        aspectRatio: 0.835,
        minWidth: 100,
        legendWidth: 140,
        verticalAlign: 'bottom',
        legendAlignment: 'center',
        chartHeight: 205,
        innerRadius: '80%',
        outerRadius: '100%',
      }}
      title={title}
      testId="usb-device-availability-card"
      paging={false}
    />
  )
}
