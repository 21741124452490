import React, { useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DispatchProp, useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { menu as palette } from 'assets/palettes'
import requireAuth from 'components/RequireAuth'
import Content from 'components/Content'
import InfoBanner from 'components/InfoBanner/InfoBanner'
import { ConnectedSolAlertMessage,  SolMenuWrapper, SolTooltipWithLink } from 'SolComponents'
import { UserPermissions } from 'shared/types/server'
import { useHasPermission } from 'shared/hooks/useHasPermission'

import styleModules from './WithMenuAuthenticated.module.scss'
import { useIsSidebarOpen } from 'shared/hooks/useIsSidebarOpen'
import useClickOutside from 'shared/hooks/useClickOutside'
import { closeSidebar } from 'shared/store/slices/core'
import classNames from 'classnames'

import { routesWithMenuAuth, RouteObj } from '../../routes/routes'
import { Button, Icon } from 'semantic-ui-react'
import { getDeployDocumentationUrl, getSolsticeDeployUrl } from 'shared/core/config'
import { useAlerts } from 'shared/hooks/useAlerts'
import { Role } from 'shared/types/Role'
import { DownloadButtonRide } from './DeployDownloadJoyride'

// require auth for anything but the above routes in FullWidth
const WithAuthContent = requireAuth(Content)

export const style = {
  sidebar: {
    backgroundColor: palette.blueBlack,
  } as React.CSSProperties,
}

export interface Props extends DispatchProp<any>, RouteComponentProps<any> {
  activeItem: string
  isMersive: boolean
}

export interface State {
  activeItem: string
  activeAccordion?: number
}

const renderRoute = ({ path, render, component, exact = true }: RouteObj) => {
  return <Route key={path} exact={exact} path={path} component={component} render={render} />
}

export function WithMenuAuthenticated(props: Props) {
  const {
    solsticeUpdates: SOLSTICE_UPDATES_ENABLED = true,
    locationsPage = true,
    userAnalyticsPage = true,
    realtimePage = true,
    ssoAdminPage = true,
    manageAccounts = true,
    managementHistory = true,
    maintenanceBanner = true,
    solsticeRouting = true,
  } = useFlags()
  const { showError } = useAlerts()
  const hasDisplayUpdates = useHasPermission(UserPermissions.UPDATE_DISPLAYS)
  const MANAGE_ENABLED = SOLSTICE_UPDATES_ENABLED && hasDisplayUpdates

  const sidebarRef = useRef(null)

  const sidebarOpen = useIsSidebarOpen()

  const { role } = useSelector((state: any) => state.user)
  
  const download = () => {
    const url = getSolsticeDeployUrl()
    let extension
    if (navigator.platform.toUpperCase().includes('MAC')) {
      extension = '.dmg'
    } else if (navigator.platform.toUpperCase().includes('WIN')) {
      extension = '.exe'
    } else if (navigator.platform.toUpperCase().includes('LINUX')) {
      extension = '.AppImage'
    } else {
      showError('Unknown OS')
    }
    window.location.assign(`${url}${extension}`)
  }

  const dispatch = useDispatch()
  useClickOutside(
    sidebarRef,
    () => {
      if (sidebarOpen) {
        dispatch(closeSidebar())
      }
    },
    ['sidebar large icon'],
  )
  // list of routes to show with unEnabled routes removed
  const routesWithMenu = routesWithMenuAuth({
    locationsPage,
    userAnalyticsPage,
    realtimePage,
    MANAGE_ENABLED,
    ssoAdminPage,
  }).filter(({ enabled = true }) => enabled)

  const downloadButton = () => {
    return (
      <div className={styleModules.downloadButton}>
        <SolTooltipWithLink
          text="Download Solstice Deploy to enroll Pods into Solstice Cloud from Windows or macOS."
          buttonText="LEARN MORE"
          isExternalLink
          linkUrl={getDeployDocumentationUrl()}
          isInline={false}
          position="top left"
          offset={[0, 10]}
          trigger={
            <Button
              id="solstice-deploy-download"
              onClick={download}
              color="blue"
              size="tiny"
            >
              <span className={styleModules.buttonText}>
                Get Solstice Deploy
              </span>
              <Icon name="download" />
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <div className={styleModules.container}>
      <div
        ref={sidebarRef}
        className={classNames(styleModules.sidebar, {
          [styleModules.sidebarOpen]: sidebarOpen,
        })}
        style={style.sidebar}
      >
        <SolMenuWrapper
          manageEnabled={MANAGE_ENABLED}
          hasDisplayUpdates={hasDisplayUpdates}
          path={props.location.pathname}
          locationsPage={locationsPage}
          userAnalyticsPage={userAnalyticsPage}
          realtimePage={realtimePage}
          ssoAdminPage={ssoAdminPage}
          manageAccounts={manageAccounts}
          managementHistory={managementHistory}
          solsticeRouting={solsticeRouting}
        />
        {(role === Role['Super Admin'])
          && <>
            <DownloadButtonRide/>
            {downloadButton()}
          </>
        }
      </div>
      <div className={styleModules.content}>
        <WithAuthContent>
          { maintenanceBanner
            && <>
              <InfoBanner />
            </>
          }
          <ConnectedSolAlertMessage />
          <div className={`main-content ${styleModules.page}`}>
            <Switch>
              {routesWithMenu.map(r => renderRoute(r))}

              {/* redirect to home for invalid routes (SAAS-369) */}
              <Redirect to="/home" />
            </Switch>
          </div>
        </WithAuthContent>
      </div>
    </div>
  )
}

export default WithMenuAuthenticated
