import React, { useState } from 'react'
import SolDrawer, { Props as SolDrawerProps } from 'SolComponents/SolDrawer/SolDrawer'
import { SolButton, SolConfirmationModal } from 'SolComponents'
import { FormValidation } from 'shared/context/FormValidation'
import { ContactForm } from './ContactForm'
import styles from './TrialDrawer.module.scss'
import {
  CreateActiveLearningTrialLicenseInput,
  CreateActiveLearningTrialLicenseMutation,
  CreateActiveLearningTrialLicenseMutationVariables,
  CreateActiveLearningTrialLicenseDocument,
  DisplaySearchOptions,
} from 'graphql/__generated__/types'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { useMutation } from '@apollo/client'
import { useAlerts } from 'shared/hooks/useAlerts'
import { pluralize } from 'shared/core/utils'
import { utm } from '../constants'
const logo = require('../../../assets/active_learning/logo.png')

export type Props = Omit<SolDrawerProps, 'children'> & {
  pods: number
  displaySearchOptions: DisplaySearchOptions
  onSubmitForm: () => void
}

export type ContactInfo = Omit<CreateActiveLearningTrialLicenseInput, 'displaySearchOptions'>

interface PageProps {
  setPage: (page: Page) => void
  closeDrawer: () => void
  pods: number
  contactInfo: ContactInfo
  setContactInfo: (info: ContactInfo) => void
  createTrialLicense: () => void
  creatingTrialLicense: boolean
}

const ConfirmationPage = (props: PageProps) => (
  <>
    <p className={styles.text}>
      Are you sure you want to enable the trial for the {pluralize(props.pods, 'selected Pod')} which will start
      immediately?
    </p>
    <p className={styles.text}>This requires a restart of selected Pods which will trigger automatically.</p>
    <SolButton
      text="Confirm"
      color="blue"
      handleClick={props.createTrialLicense}
      disabled={props.creatingTrialLicense}
    />
    <SolButton text="Nevermind, I'll start my trial later." color="blue" basic isLink handleClick={props.closeDrawer} />
  </>
)

const ContactInfoPage = (props: PageProps) => (
  <>
    <h1>Let's get started.</h1>
    <p className={styles.note}>Starting your Active Learning trial on {pluralize(props.pods, 'selected Pod')}.</p>
    <p className={styles.text}>
      We want to make sure your experience is a good one. We'll be in touch to discuss best practices, setting up users,
      and pricing.
    </p>
    <FormValidation>
      <ContactForm
        goNext={() => props.setPage(Page.Confirmation)}
        contactInfo={props.contactInfo}
        setContactInfo={props.setContactInfo}
      />
    </FormValidation>
  </>
)

const DownloadPage = (props: PageProps) => (
  <>
    <h1>You're ready to go.</h1>
    <SolButton
      text="Download the app to get started"
      color="blue"
      handleClick={props.closeDrawer}
      externalLink={`https://www.mersive.com/download/?${utm}`}
      className={styles.download}
    />
  </>
)

enum Page {
  Confirmation = 'confirmation',
  ContactInfo = 'contactInfo',
  Download = 'download',
}

const pages = {
  [Page.Confirmation]: ConfirmationPage,
  [Page.ContactInfo]: ContactInfoPage,
  [Page.Download]: DownloadPage,
}

export const TrialDrawer = ({ pods, displaySearchOptions, onSubmitForm, ...props }: Props) => {
  const { showError } = useAlerts()
  const { name: email } = useCurrentUser()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [page, setPage] = useState<Page>(Page.ContactInfo)
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    jobTitle: '',
    email,
    emailTwo: '',
  })
  const [createTrialLicense, { loading }] = useMutation<
    CreateActiveLearningTrialLicenseMutation,
    CreateActiveLearningTrialLicenseMutationVariables
  >(CreateActiveLearningTrialLicenseDocument, {
    variables: {
      options: { ...contactInfo, displaySearchOptions },
    },
    onError: err => {
      /* eslint-disable-next-line no-console */
      console.error(err.message)
      showError('There was an issue enabling the trial')
    },
    onCompleted: () => {
      onSubmitForm()
      setPage(Page.Download)
    },
  })
  const CurrentPage = pages[page]
  const closeDrawer = () => {
    props.onCloseDrawer()
    setPage(Page.ContactInfo)
  }
  const confirmCloseDrawer = () => {
    if (page === Page.Download) {
      closeDrawer()
    } else {
      setModalIsOpen(true)
    }
  }
  return (
    <SolDrawer {...props} onCloseDrawer={confirmCloseDrawer}>
      <SolConfirmationModal
        isOpen={modalIsOpen}
        modalText="Are you sure you want to leave?"
        onAcceptingAction={() => {
          setModalIsOpen(false)
          closeDrawer()
        }}
        acceptingText="Leave"
        onCloseModal={() => setModalIsOpen(false)}
        cancelText="Cancel"
      />
      <div className={styles.trialDrawer}>
        <img src={logo} className={styles.logo} draggable="false" />
        <CurrentPage
          setPage={setPage}
          closeDrawer={confirmCloseDrawer}
          pods={pods}
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
          createTrialLicense={createTrialLicense}
          creatingTrialLicense={loading}
        />
      </div>
    </SolDrawer>
  )
}
