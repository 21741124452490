import React from 'react'
import { Progress } from 'semantic-ui-react'

export interface Props {
  currentStep: number
  totalSteps: number
}

export const style = {
  wrapper: {
    width: '100%',
  } as React.CSSProperties,
  padding: {
    padding: '8px',
  } as React.CSSProperties,
}

const FlowProgress = (props: Props) => (
  <div style={style.wrapper}>
    <div style={style.padding}>
      <Progress
        value={props.currentStep}
        total={props.totalSteps}
        active
        // progress={`ratio`}
        color="blue"
        size="tiny"
      />
    </div>
  </div>
)

export default FlowProgress
