import React from 'react'
import { Table, Icon } from 'semantic-ui-react'

const CheckedCell = () => (
  <Table.Cell textAlign="center">
    <Icon name="checkmark" color="green" size="large" />
  </Table.Cell>
)

export const RolesTable = () => (
  <Table basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>Admin</Table.HeaderCell>
        <Table.HeaderCell>Guest</Table.HeaderCell>
        <Table.HeaderCell>Viewer</Table.HeaderCell>
        <Table.HeaderCell>AL Designer</Table.HeaderCell>
        <Table.HeaderCell>AL Facilitator</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Edit Pods</Table.Cell>
        <CheckedCell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
      <Table.Row>
        <Table.Cell>Web App Client</Table.Cell>
        <CheckedCell />
        <CheckedCell />
        <CheckedCell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
      <Table.Row>
        <Table.Cell>View Solstice Cloud Data</Table.Cell>
        <CheckedCell />
        <Table.Cell />
        <CheckedCell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
      <Table.Row>
        <Table.Cell>Set up AL Rooms</Table.Cell>
        <CheckedCell />
        <Table.Cell />
        <Table.Cell />
        <CheckedCell />
        <Table.Cell />
      </Table.Row>
      <Table.Row>
        <Table.Cell>Use AL Rooms</Table.Cell>
        <CheckedCell />
        <Table.Cell />
        <Table.Cell />
        <CheckedCell />
        <CheckedCell />
      </Table.Row>
    </Table.Body>
  </Table>
)
