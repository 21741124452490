import React, { useCallback } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Form } from 'semantic-ui-react'
import styles from '../../Login.module.scss'
import { SolButton } from 'SolComponents'
import CreateOrgLink from '../CreateOrgLink/CreateOrgLink'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface Props {
  error: string
  handleOnSubmit: () => void
  email: string
  setEmail: (email: string) => void
  ssoSignIn: () => void
  passwordLoading: boolean
  ssoLoading: boolean
}

function LoginForm(props: Props & RouteComponentProps) {
  const { createOrg } = useFlags()

  const {
    error,
    email,
    setEmail,
    passwordLoading,
    handleOnSubmit: onSubmit,
  } = props

  const handleOnChange = useCallback((_e, data: { value: string }) => setEmail(data.value), [setEmail])

  const handleOnSubmit = useCallback(
    evt => {
      evt.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className={styles.group}>
        <div className={styles.email}>
          <Form.Input autoFocus className={styles.email} type="text" value={email} onChange={handleOnChange}>
            <input autoComplete="username" id="username" name="username" placeholder="Email address" />
          </Form.Input>
        </div>
        <input
          type="password"
          className={styles.hidden}
          autoComplete="current-password"
          id="password"
          name="password"
          value=""
          onChange={() => { }} /> {/* this field works to tell the browser that this is a user/password form so that the browser autofills with correct information */}
        <div className={`${styles.loginButton} ${styles.button}`} >
          <SolButton
            text="Login"
            disabled={email.length === 0}
            primary
            basic={false}
            isLink={false}
            loading={passwordLoading}
            type="submit"
          />
        </div>
      </div>
      {error ? <p className={styles.error}>{error}</p> : null}
      {
        createOrg && <CreateOrgLink/>
      }
    </Form>
  )
}

export default withRouter(LoginForm as any) as any
