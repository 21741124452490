export function focusAndSelectElement(element: HTMLElement) {
  element.focus()

  const htmlInputElement = element as HTMLInputElement
  if (
    htmlInputElement
    && htmlInputElement.value
    && htmlInputElement.value !== ''
    && htmlInputElement.select
  ) {
    htmlInputElement.select()
  }
}
