import React from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Template,
  PowerManagementConfiguration,
} from 'graphql/__generated__/types'
import styles from './PowerManagement.module.scss'
import PowerManagement from './PowerManagement'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: PowerManagementConfiguration) => void
}

function PowerMgmt({ updateConfiguration, template }: Props) {
  return (
    <div>
      <Grid className={styles.columnWrapper}>
        <PowerManagement
          template={template}
          updateConfiguration={updateConfiguration}
        />
      </Grid>
    </div>
  )
}

export default PowerMgmt
