import React from 'react'
import FilterMenu from '../Menu/FilterMenu'
import styles from './FilteredTableHolder.module.scss'
import classNames from 'classnames'

export interface Props {
  tableId: string
  clearSelectedItemsOnFilter?: boolean
  children: React.ReactNode
}

export default function FilteredTableHolder(props: Props) {
  return (
    <div className={classNames(styles.parent, 'filtered-table-container')}>
      <div className={styles.leftCol}>
        <FilterMenu
          tableId={props.tableId}
          clearSelectedItemsOnFilter={props.clearSelectedItemsOnFilter}
        />
      </div>
      <div className={styles.rightCol}>
        <div className={styles.cell}>{props.children}</div>
      </div>
    </div>
  )
}
