import React from 'react'
import { SolDonutChart, SolCard } from 'SolComponents'
import {
  PieSector,
  Props as SolDonutChartProps,
} from 'SolComponents/SolDonutChart/SolDonutChart'
import { Icon } from 'semantic-ui-react'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import styles from './SolDonutCard.module.scss'
import { Link } from 'react-router-dom'

type Props = {
  data?: PieSector[]
  aspectRatio?: number // testing purpose
  title: string | JSX.Element
  testId?: string
  isLoading: boolean
  paging?: boolean
  className?: string
  chartProps?: Partial<SolDonutChartProps>
  url?: string
  linkName?: string
}

export const isEmpty = (data?: PieSector[]) => {
  if (!data || data.length === 0) {
    return true
  }
  const total = data.reduce((acc, val) => acc + val.count, 0)
  if (total === 0) {
    return true
  }
  return false
}

export const DonutCard = ({
  data,
  aspectRatio,
  testId,
  title,
  paging,
  isLoading = true,
  className,
  chartProps,
  url,
  linkName,
}: Props) => (
  <SolCard className={className}>
    <div data-testid={testId} className={styles.chartContainer}>
      <LoadingWrapper isEmpty={isEmpty(data)} isLoading={isLoading}>
        <SolDonutChart
          pieData={data ?? []}
          responsiveWidth="100%"
          aspectRatio={aspectRatio}
          isAnimationActive={false}
          paging={paging}
          legendTitle={title}
          staticChart
          minWidth={350}
          xPosition="57%"
          legendWidth={241}
          verticalAlign="top"
          legendAlignment="left"
          chartHeight={180}
          innerRadius="70%"
          outerRadius="90%"
          {...chartProps}
        />
      </LoadingWrapper>
      {url && linkName && (
        <div className={styles.link}>
          <Link to={url}>
            {linkName}&nbsp;
            <Icon name="angle right" />
          </Link>
        </div>
      )}
    </div>
  </SolCard>
)

export default React.memo(DonutCard, (prev, next) => {
  return (
    JSON.stringify(prev.data) === JSON.stringify(next.data)
    && prev.isLoading === next.isLoading
  )
})
