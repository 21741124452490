import React from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  Column,
  DataTableInstance,
} from 'components/DataTableSlim/DataTableSlim'
import { zonedTimeToUtc } from 'date-fns-tz'
import { format, differenceInHours } from 'date-fns'
import styles from './UsbTable.module.scss'
import { SolPodName, SolStatus } from 'SolComponents'
import { pluralize } from 'shared/core/utils'
import { ConferenceReadiness, OccupancyReadiness } from './Readiness'
import { UsbDevice } from 'graphql/__generated__/types'

type Props = {
  tableData?: UsbDevice[] | undefined
  tableDataLoading: boolean
  totalItems: number
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

// const columns: Column<UsbConnection>[] = [
const columns: Column<UsbDevice>[] = [
  {
    name: 'name',
    className: styles.widthSize,
    render: row => <SolPodName id={row.display?.id ?? ''} name={row.display?.name ?? ''} />,
    displayName: 'Pod',
    collapsing: true,
  },
  {
    name: 'fullProductName',
    displayName: 'USB Device',
    render: row => row.fullProductName,
  },
  {
    name: 'usbStatus',
    centered: true,
    displayName: 'USB Status',
    render: row => <SolStatus status={row.isOnline ? 'Online' : 'Offline'} />,
    collapsing: true,
  },
  {
    name: 'usbPlugTimestamp',
    displayName: 'Since',
    render: row => {
      if (row?.usbPlugTimestamp) {
        const date = zonedTimeToUtc( new Date( Number.parseInt(row?.usbPlugTimestamp) ), localTimezone )
        const hoursSince = differenceInHours( new Date(), date )
        return hoursSince < 24
          ? pluralize(hoursSince, 'hour')
          : format(date, 'MM/dd/yyyy hh:mma')
      } return ''
    },
  },
  {
    name: 'isConferenceReady',
    displayName: 'Conference Ready',
    render: row => <ConferenceReadiness {...row} />,
  },
  {
    name: 'isOccupancyReady',
    displayName: 'Occupancy Ready',
    render: row => <OccupancyReadiness {...row} />,
  },
]

export const UsbTable = ({
  tableData,
  tableDataLoading: loading,
  totalItems,
}: Props) => {
  const data = tableData ?? []
  return (
    <div className={styles.wrapper}>
      {ServerDataTable<UsbDevice>({
        id: DataTableInstance.Usb,
        title: 'Devices',
        addableColumns: false,
        data,
        defaultSort: { field: 'name', direction: 'ascending' },
        errorOnRow: () => false,
        columns,
        loading,
        totalItems,
        selectable: false,
        padded: false,
      })}
    </div>
  )
}
