const packageName = process.env.REACT_APP_NAME
const packageVersion = process.env.REACT_APP_VERSION

const config: Function = () => ({
  googleAnalytics: process.env.GOOGLE_ANALYTICS_ID as string,
  hotjar: {
    id: Number.parseInt(process.env.HOTJAR_ID as string),
    snippetVersion: Number.parseInt(process.env.HOTJAR_SNIPPET_VERSION as string),
  },
})

let configEnv: Function = () => process.env.REACT_APP_SERVER_ENV || 'develop'

// defaults: interpret config from hostname
// let config = defaultConfig
let baseURL: Function = () => process.env.BASE_URL as string
let baseBackendURL: Function = () => process.env.BASE_URL as string
let baseAuthURL: Function = () => process.env.BASE_URL as string
let isLocalhost: Function = () => process.env.API_IS_LOCALHOST === 'true'
let getAuthURL: Function = () => process.env.AUTH_URL as string
let getSsoURL: Function = () => process.env.SSO_URL as string
let getMxRenewURL: Function = () => process.env.MX_RENEW_URL as string
let isProduction: Function = () => process.env.API_IS_PRODUCTION === 'true'
let ldClientId: Function = () => process.env.LD_CLIENT_ID as string
let graphqlServerUrl: Function = () => process.env.GRAPHQL_SERVER_URL as string
let hasuraServerUrl: Function = () => process.env.HASURA_SERVER_URL as string
let hasuraServerWs: Function = () => process.env.HASURA_SERVER_WS as string
let imageServerUrl: Function = () => process.env.IMAGE_SERVER_URL as string
let getSolsticeDeployUrl: Function = () => process.env.SOLSTICE_DEPLOY_URL as string
let getDeployDocumentationUrl: Function = () => process.env.DEPLOY_DOCUMENTATION_URL as string

export {
  baseURL,
  baseBackendURL,
  baseAuthURL,
  configEnv,
  isLocalhost,
  getAuthURL,
  getSsoURL,
  getMxRenewURL,
  isProduction,
  packageName,
  packageVersion,
  ldClientId,
  graphqlServerUrl,
  hasuraServerUrl,
  hasuraServerWs,
  imageServerUrl,
  getSolsticeDeployUrl,
  getDeployDocumentationUrl,
}

export default config
