import React, { useState } from 'react'
import styles from './SolTemplateHeader.module.scss'
import { Form, InputOnChangeData } from 'semantic-ui-react'
import SolInput from 'SolComponents/SolInput/SolInput'
import SolConfirmationModal from 'SolComponents/SolConfirmationModal/SolConfirmationModal'

export const TemplateNameModal = ({
  isOpen,
  close,
  clearSavingError,
  handleSaveDisplayAsNewTemplate,
  isSaving,
  savingError,
}: {
  isOpen: boolean
  close: () => void
  clearSavingError: () => void
  handleSaveDisplayAsNewTemplate: (name: string) => void
  isSaving: boolean
  savingError: string | null
}) => {
  const [newTemplateName, setNewTemplateName] = useState('')

  const submitNewTemplateName = async () => {
    try {
      await handleSaveDisplayAsNewTemplate(newTemplateName)
      close()
      setNewTemplateName('')
      clearSavingError()
    } catch (e) {}
  }

  return (
    <SolConfirmationModal
      isOpen={isOpen}
      onCloseModal={() => {
        close()
        setNewTemplateName('')
        clearSavingError()
      }}
      acceptingText="Save"
      acceptingDisabled={!newTemplateName || isSaving}
      onAcceptingAction={submitNewTemplateName}
    >
      <div className={styles.templateNameModalContent}>
        <Form
          onSubmit={async e => {
            e.preventDefault()
            submitNewTemplateName()
          }}
        >
          <Form.Field
            control={SolInput}
            label="Enter template name"
            value={newTemplateName}
            error={!newTemplateName || !!savingError}
            errorText={savingError ?? ''}
            maxLength={40}
            onChange={({}, v: InputOnChangeData) => {
              setNewTemplateName(v.value)
              clearSavingError()
            }}
          />
        </Form>
      </div>
    </SolConfirmationModal>
  )
}

export const UnknownPasswordModal = ({
  isOpen,
  close,
  confirm,
}: {
  isOpen: boolean
  close: () => void
  confirm: () => void
}) => (
  <SolConfirmationModal
    isOpen={isOpen}
    modalText="Review Passwords"
    cancelText="Return"
    acceptingText="Continue"
    onCloseModal={close}
    onAcceptingAction={() => {
      close()
      confirm()
    }}
  >
    <div className={styles.unknownPasswordModalContent}>
      <p>This template has passwords set by Dashboard.</p>
      <p>Unless re-entered, any current Dashboard passwords will be cleared from this template.</p>
      <p>Would you like to return to update passwords or continue?</p>
    </div>
  </SolConfirmationModal>
)
