import React from 'react'
import styles from './VLAN.module.scss'
import { Vlan, NetworkConfig, UniqueSettingsConfiguration } from 'graphql/__generated__/types'
import EnabledOptions from '../EnabledOptions/EnabledOptions'
import { SolNumInput } from 'SolComponents'
import { defaultNetworkConfig } from '../EthernetTypes'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import UniqueSettingsInput, { getTooltipText } from '../../UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import { getVlanDnsHostname, setVlanDnsHostname } from '../../../../shared/config/ethernet'

export interface Props {
  vlan: Vlan
  setVlan: (vlan: Vlan) => void
  index: number
  getIpAddress: (uniqueSettings: UniqueSettingsConfiguration) => string
  updateUniqueSettingsConfig: (config: UniqueSettingsConfiguration, value: any) => UniqueSettingsConfiguration
  podId?: string
  isDefault: boolean
}

export default function VLAN({
  vlan,
  setVlan,
  index,
  getIpAddress,
  updateUniqueSettingsConfig,
  podId,
  isDefault,
}: Props) {
  return (
    <div className={styles.vlanWrapper}>
      <div className={styles.vlanFields}>
        <ValidationInput
          name={`vlan_label_${index}`}
          rules={{ required: true }}
          required
          label="Network Name"
          value={vlan.label ?? ''}
          maxLength={20}
          onChange={(e, value) =>
            setVlan({
              ...vlan,
              label: value.value,
            })
          }
        />
        <SolNumInput
          inputSize="large"
          min={1}
          max={4094}
          label="Tag"
          value={vlan?.tag ?? 100}
          onChange={num => {
            setVlan({
              ...vlan,
              tag: num,
            })
          }}
        />
        <div>
          <UniqueSettingsInput
            label="DNS Hostname"
            tooltipText={getTooltipText({
              setting: 'DNS Hostname',
              template: 'Ethernet',
            })}
            getValue={getVlanDnsHostname(index - 1)}
            updateConfig={setVlanDnsHostname(index - 1)}
            name={`ethernetVlan_dnsHostname_${index}`}
            podId={podId}
            inline={false}
            placeholder="Optional"
            maxLength={62}
          />
        </div>
        <EnabledOptions
          admin={vlan?.adminAccess ?? false}
          updateAdmin={admin => {
            setVlan({
              ...vlan,
              adminAccess: admin,
            })
          }}
          getIpAddress={getIpAddress}
          section={`VLAN_${index}`}
          config={vlan?.networkConfig ?? defaultNetworkConfig}
          updateConfig={(config: NetworkConfig) =>
            setVlan({
              ...vlan,
              networkConfig: config,
            })
          }
          updateUniqueSettingsConfig={updateUniqueSettingsConfig}
          podId={podId}
          isDefault={isDefault}
        />
      </div>
    </div>
  )
}
