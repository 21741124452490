import React from 'react'
import styles from './PodsTable.module.scss'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { Categorizable, Column, DataTableInstance, Direction } from 'components/DataTableSlim/DataTableSlim'
import classNames from 'classnames'
import { format, isAfter, differenceInDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { SolPodVersion, SolTooltip } from 'SolComponents'
import { Display, DisplayOrderableField } from './../../../graphql/__generated__/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Role } from 'shared/types/Role'

export const defaultSort = {
  direction: 'ascending' as Direction,
  field: 'name',
}

const isInTrial = (display: Display) => {
  const trialEndDate = new Date(display.activeLearning?.trial?.endDate ?? '')
  return !display.activeLearning?.licenseEndDate && isAfter(new Date(trialEndDate), new Date())
}

interface Props {
  totalItemsSelected: number
  totalItemsSelectable: number
  totalItems: number
  data: Display[]
  loading: boolean
  enableTrialButton: () => JSX.Element
  role?: number | null
}

const dateFormat = 'M/d/yyyy'
/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const formatTrialEndDate = (date: string, expiringSoonCutoff: number) => {
  const expires = zonedTimeToUtc( new Date(date), localTimezone )
  return (
    <div
      className={classNames(styles.trialText, {
        [styles.expiringSoon]: differenceInDays(new Date(), expires) < expiringSoonCutoff,
      })}
    >
      <p className={styles.header}>TRIAL</p>
      { format( new Date(expires), dateFormat)}
    </div>
  )
}

const formatLicenseEndDate = (date: string) => {
  if (!date) {
    return '⁠—'
  }
  const expires = zonedTimeToUtc(date, localTimezone)
  return format(new Date(expires), dateFormat)
}

const getColumns = (
  roomsEnabled: boolean,
): Column<Categorizable<Display>>[] => [
  {
    name: DisplayOrderableField.Name,
    displayName: 'Name',
    render: r => r.name,
  },
  {
    name: DisplayOrderableField.Version,
    displayName: 'Version',
    render: r => (
      <SolPodVersion
        version={r.versions.software ?? ''}
        catchingUp={r.catchingUp}
      />
    ),
  },
  {
    name: DisplayOrderableField.ActiveLearningExpires,
    displayName: 'Expires',
    render: r =>
      (isInTrial(r)
        ? formatTrialEndDate(r.activeLearning?.trial?.endDate ?? '', 10)
        : formatLicenseEndDate(r.activeLearning?.licenseEndDate ?? '')),
  },
  ...(roomsEnabled
    ? [
      {
        name: DisplayOrderableField.ActiveLearningRoomName,
        displayName: 'Rooms',
        render: (r: Display) => (
          <div
            className={classNames({
              [styles.trialText]: isInTrial(r),
            })}
          >
            {r.activeLearning?.rooms[0]?.name ?? '⁠—'}{' '}
            {r.activeLearning?.rooms && r.activeLearning.rooms.length > 1 && (
              <SolTooltip
                hoverable
                position="top right"
                trigger={<span>{`(+${r.activeLearning?.rooms.length - 1})`}</span>}
                isInline
                text={r.activeLearning?.rooms.map(room => (
                  <div key={room.id}>{room.name}</div>
                ))}
              />
            )}
          </div>
        ),
      },
    ]
    : []),
  {
    name: DisplayOrderableField.ActiveLearningStatus,
    displayName: 'Status',
    render: r => r.activeLearning?.status,
  },
]

export const PodsTable = ({
  totalItemsSelected,
  totalItemsSelectable,
  totalItems,
  data,
  loading,
  role,
  enableTrialButton,
}: Props) => {
  const { activeLearningRooms = false } = useFlags()
  return (
    <div className={styles.podsTable}>
      {ServerDataTable<Categorizable<Display>>({
        id: DataTableInstance.ActiveLearningPods,
        columns: getColumns(activeLearningRooms),
        data,
        title: 'Active Learning Pods',
        totalItems,
        totalItemsSelected,
        totalItemsSelectable,
        defaultSort,
        loading,
        addableColumns: false,
        selectable: role !== Role.Viewer && (d => !!d.activeLearning?.trial?.isEligible),
        columnManager: role  !==  Role.Viewer ? enableTrialButton : undefined,
        padded: false,
      })}
    </div>
  )
}
