import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Form, Icon, InputOnChangeData } from 'semantic-ui-react'
import { SolButton } from 'SolComponents'
import styles from './Password.module.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import CreateOrgLink from '../CreateOrgLink/CreateOrgLink'

interface Props {
  error: string
  handleOnSubmit: () => void
  loading: boolean
  email: string
  password: string
  setPassword: (password: string) => void
  goToEmail: () => void
  ssoSignIn: () => void
  ssoLoading: boolean
  ssoEnabled: boolean
}

const Password = (props: Props) => {
  const { ssoLoginFlow, createOrg } = useFlags()

  const { email, password, setPassword, goToEmail, handleOnSubmit: onSubmit } = props

  const handleOnChange = useCallback((_e, data: InputOnChangeData) => setPassword(data.value), [setPassword])

  const handleOnSubmit = useCallback(
    evt => {
      evt.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  const { error, loading } = props

  return (
    <Form onSubmit={handleOnSubmit}>
      <Form.Group className={styles.group}>
        <div className={styles.back}>
          <button type="button" className={styles.buttonLink} onClick={goToEmail}>
            <Icon name="angle left" /> Back to email
          </button>
        </div>
        <input
          autoComplete="username"
          id="username"
          name="username"
          className={styles.hidden}
          value={email}
          onChange={() => {}}
        />{/** This is a hidden field to fill the browser's save password username */}
        <Form.Input
          id="password"
          autoFocus
          name="password"
          current-password="true"
          className={styles.input}
          type="password"
          placeholder="Password"
          onChange={handleOnChange}
          label={`Enter password for ${email.length > 30 ? email.substring(0, 30) + '...' : email}`}
        >
          <input autoComplete="current-password" id="password" name="password" value={password} />
        </Form.Input>
        <div className={styles.loginButton}>
          <SolButton
            text="Sign In"
            disabled={email.length === 0 || password.length === 0}
            primary
            basic={false}
            isLink={false}
            loading={loading}
          />
        </div>
        {error ? <p className={styles.error}>{error}</p> : null}
        {ssoLoginFlow && props.ssoEnabled && (
          <div className={`${styles.loginButton} ${styles.button}`}>
            <SolButton
              text="Login with SSO"
              disabled={email.length === 0}
              basic
              color="blue"
              isLink={false}
              loading={props.ssoLoading}
              type="button"
              handleClick={props.ssoSignIn}
            />
          </div>
        )}
        <Link
          to={{
            pathname: '/reset-password',
            state: { email },
          }}
        >
          <strong>Forgot password?</strong>
        </Link>
      </Form.Group>
      {
        createOrg && <CreateOrgLink/>
      }
    </Form>
  )
}
Password.defaultProps = {
  email: '',
}

export default Password
