import { ApolloError, ServerError, ServerParseError } from '@apollo/client'

export const ClientErrorCodes = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  402: 'PAYMENT_REQUIRED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  405: 'METHOD_NOT_ALLOWED',
  406: 'NOT_ACCEPTABLE',
  407: 'PROXY_AUTHENTICATION_REQUIRED',
  408: 'REQUEST_TIMEOUT',
  409: 'CONFLICT',
  410: 'GONE',
  411: 'LENGTH_REQUIRED',
  412: 'PRECONDITION_FAILED',
  413: 'PAYLOAD_TOO_LARGE',
  414: 'URI_TOO_LONG',
  415: 'UNSUPPORTED_MEDIA_TYPE',
  416: 'REQUESTED_RANGE_NOT_SATISFIABLE',
  417: 'EXPECTATION_FAILED',
  418: 'I\'M_A_TEAPOT',
  421: 'MISDIRECTED_REQUEST',
  422: 'UNPROCESSABLE_ENTITY',
  423: 'LOCKED',
  424: 'FAILED_DEPENDENCY',
  425: 'TOO_EARLY',
  426: 'UPGRADE_REQUIRED',
  428: 'PRECONDITION_REQUIRED',
  429: 'TOO_MANY_REQUESTS',
  431: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
  451: 'UNAVAILABLE_FOR_LEGAL_REASONS',
} as const

export const ServerErrorCodes = {
  500: 'INTERNAL_SERVER_ERROR',
  501: 'NOT_IMPLEMENTED',
  502: 'BAD_GATEWAY',
  503: 'SERVICE_UNAVAILABLE',
  504: 'GATEWAY_TIMEOUT',
  505: 'HTTP_VERSION_NOT_SUPPORTED',
  506: 'VARIANT_ALSO_NEGOTIATES',
  507: 'INSUFFICIENT_STORAGE',
  508: 'LOOP_DETECTED',
  510: 'NOT_EXTENDED',
  511: 'NETWORK_AUTHENTICATION_REQUIRED',
} as const

/**
 * HTTP response error codes
 *
 * @name ErrorCodes
 * @type {Object<number, string>}
 * @constant
 * @default
 */
export const ErrorCodes = {
  ...ClientErrorCodes,
  ...ServerErrorCodes,
} as const

/**
 * Parses an error for a safe message to display to the end user.
 * It observes the status codes of network errors to determine if the error is safe to show or not
 *
 * @function
 * @name parseErrorMessage
 * @param {ApolloError} err The error which was caught
 * @param {string} [fallbackMessage='Something went wrong'] A fallback error message to use if an error is encounter which isn't safe to show to the end user
 */
export function parseErrorMessage(
  err: ApolloError,
  fallbackMessage: string = 'Something went wrong',
): string {
  let parsedMessage: string | undefined
  if ((err.networkError as ServerError | ServerParseError)?.statusCode >= 500) {
    return fallbackMessage
  }

  if (
    (err.networkError as ServerError | ServerParseError)?.statusCode >= 400
    && (err.networkError as ServerError | ServerParseError)?.statusCode < 500
  ) {
    parsedMessage = (err.networkError as ServerError)?.result?.errors?.find((e: Error) => e.message)?.message
    || (err.networkError as ServerParseError)?.bodyText
    || err.message
  }

  if (!parsedMessage) {
    parsedMessage = err.graphQLErrors?.find(e => !(
      +e.extensions?.code >= 500
      || Object.values(ServerErrorCodes).some(statusText => statusText === e.extensions?.code)
    ))?.message
  }

  return parsedMessage || err.message || fallbackMessage
}
