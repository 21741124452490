import axios, { AxiosError, AxiosResponse } from 'axios'
import { imageServerUrl } from 'shared/core/config'
import { getToken } from 'shared/services/auth/helpers'
import { LocalStorageToken } from 'shared/services/auth/types'
import { RestInterface } from '../types'

export interface AssetServerRestInterface {
  list: RestInterface['get']
  upload: RestInterface['post']
}

export interface AssetServiceInterface {
  list: (token?: LocalStorageToken) => Promise<any>
  upload: (file: File, type: 'single' | 'dual') => Promise<any>
}

const defaultRest: AssetServerRestInterface = {
  list: axios.get,
  upload: axios.post,
}

export interface Image {
  name: string // [hash].jpg,
  metadata: {
    org: string
    originalName: string
    index: string
    displayType: 'single' | 'span'
  }
}

interface ListResponse {
  defaultImages: Image[]
}

export class AssetServer implements AssetServiceInterface {
  apiUrl: string
  rest: AssetServerRestInterface

  hasMadeListRequest: boolean
  defaultImages: Image[] = []
  constructor(apiUrl: string = imageServerUrl(), rest: AssetServerRestInterface = defaultRest) {
    this.apiUrl = apiUrl
    this.rest = rest
  }

  async getDefaultImages() {
    const self = this
    return new Promise<Image[]>(resolve => {
      const interval = setInterval(function() {
        if (self.defaultImages.length) {
          resolve(self.defaultImages)
          clearInterval(interval)
        }
      }, 500)
    })
  }

  list(token?: LocalStorageToken): Promise<Image[]> {
    // Only make one http request per app load
    if (this.hasMadeListRequest) {
      return this.getDefaultImages()
    }
    this.hasMadeListRequest = true
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token || getToken()}`,
    }
    return this.rest
      .list(this.apiUrl, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .then((result: ListResponse) => {
        this.defaultImages = result.defaultImages
        return result.defaultImages
      })
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  upload(file: File): Promise<Image> {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
    }

    const data = new FormData()
    data.append('image', file)

    return axios
      .post(this.apiUrl, data, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        const status = err?.response?.status || 500
        if (status === 409) {
          const hash = err?.response?.data?.message || ''
          return Promise.resolve({ name: hash })
        }
        return Promise.reject(err)
      })
  }
}

export default new AssetServer(imageServerUrl(), defaultRest)
