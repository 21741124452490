import React from 'react'
import {
  ValidationInput,
  RegexPattern,
} from 'components/FormValidation/ValidationInput'
import {
  CalendarUniqueSettingsValidation,
  UniqueCalendarConfiguration,
  UniqueEthernetConfiguration,
  EthernetUniqueSettingsValidation,
  WelcomeScreenUniqueSettingsValidation,
  UniqueWelcomeScreenConfiguration,
  WifiUniqueSettingsValidation,
  UniqueWifiConfiguration,
} from 'graphql/__generated__/types'
import { defaultUniqueVlan } from './UniqueSettingsTypes'

export const CalendarFields = (props: {
  validation: CalendarUniqueSettingsValidation
  configuration: UniqueCalendarConfiguration
  setConfiguration: (config: Partial<UniqueCalendarConfiguration>) => void
}) => (
  <>
    {props.validation?.exchangeSettings?.impersonationMailbox?.required && (
      <ValidationInput
        label="Impersonation Mailbox"
        name="impersonation_mailbox"
        rules={{ required: true, pattern: RegexPattern.Email }}
        value={props.configuration.exchangeSettings?.impersonationMailbox}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            exchangeSettings: {
              ...props.configuration.exchangeSettings,
              impersonationMailbox: v.value,
            },
          })
        }
      />
    )}
    {props.validation?.googleSettings?.roomMailbox?.required && (
      <ValidationInput
        label="Room Email"
        name="google_mailbox"
        rules={{ required: true, pattern: RegexPattern.Email }}
        value={props.configuration.googleSettings?.roomMailbox}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            googleSettings: {
              ...props.configuration.googleSettings,
              roomMailbox: v.value,
            },
          })
        }
      />
    )}
    {props.validation?.exchangeSettings?.delegationMailbox?.required && (
      <ValidationInput
        label="Delegation Mailbox"
        name="delegation_mailbox"
        rules={{ required: true, pattern: RegexPattern.Email }}
        value={props.configuration.exchangeSettings?.delegationMailbox}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            exchangeSettings: {
              ...props.configuration.exchangeSettings,
              delegationMailbox: v.value,
            },
          })
        }
      />
    )}
    {props.validation?.exchangeSettings?.userAccount?.required && (
      <ValidationInput
        label="Username"
        name="user_name"
        rules={{ required: true }}
        value={props.configuration.exchangeSettings?.userAccount}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            exchangeSettings: {
              ...props.configuration.exchangeSettings,
              userAccount: v.value,
            },
          })
        }
      />
    )}
    {props.validation?.exchangeSettings?.userPassword?.secret?.required && (
      <ValidationInput
        variant="password"
        label="User Password"
        name="user_password"
        rules={{ required: true }}
        value={props.configuration.exchangeSettings?.userPassword?.secret}
        hash={props.configuration.exchangeSettings?.userPassword?.secretHash}
        salt={props.configuration.exchangeSettings?.userPassword?.secretSalt}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            exchangeSettings: {
              ...props.configuration.exchangeSettings,
              userPassword: {
                secret: v.value,
              },
            },
          })
        }
        onClear={() =>
          props.setConfiguration({
            ...props.configuration,
            exchangeSettings: {
              ...props.configuration.exchangeSettings,
              userPassword: {
                secret: '',
              },
            },
          })
        }
      />
    )}
    {props.validation?.office365Settings?.username?.required && (
      <ValidationInput
        label="Username"
        name="username"
        rules={{ required: true }}
        value={props.configuration.office365Settings?.username}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            office365Settings: {
              username: v.value,
            },
          })
        }
      />
    )}
  </>
)

export const EthernetFields = (props: {
  validation: EthernetUniqueSettingsValidation
  configuration: UniqueEthernetConfiguration
  setConfiguration: (config: Partial<UniqueEthernetConfiguration>) => void
}) => (
  <>
    {props.validation?.ipAddress?.required && (
      <ValidationInput
        label="IP Address"
        name="ip_address"
        value={props.configuration.ipAddress}
        rules={{
          required: true,
          pattern: RegexPattern.IpAddress,
        }}
        onChange={(_, v) =>
          props.setConfiguration({ ...props.configuration, ipAddress: v.value })
        }
      />
    )}
    {Array.from(Array(3).keys()).map(
      i =>
        props.validation?.vlans?.[i]?.ipAddress?.required && (
          <ValidationInput
            key={i}
            label={`VLAN ${i + 1} IP Address`}
            name={`vlan_${i + 1}_ip_address`}
            value={props.configuration.vlans?.[i]?.ipAddress}
            rules={{
              required: true,
              pattern: RegexPattern.IpAddress,
            }}
            onChange={(_, v) => {
              const uniqueVlan1 = (props.configuration.vlans ?? [])[0] ?? {
                ...defaultUniqueVlan,
              }
              const uniqueVlan2 = (props.configuration.vlans ?? [])[1] ?? {
                ...defaultUniqueVlan,
              }
              const uniqueVlan3 = (props.configuration.vlans ?? [])[2] ?? {
                ...defaultUniqueVlan,
              }
              const vlans = [uniqueVlan1, uniqueVlan2, uniqueVlan3]
              vlans[i] = { ...defaultUniqueVlan, ipAddress: v.value }
              props.setConfiguration({ ...props.configuration, vlans })
            }}
          />
        ),
    )}
  </>
)

export const WelcomeScreenFields = (props: {
  validation: WelcomeScreenUniqueSettingsValidation
  configuration: UniqueWelcomeScreenConfiguration
  setConfiguration: (config: Partial<UniqueWelcomeScreenConfiguration>) => void
}) => (
  <>
    {props.validation?.displayName?.required && (
      <ValidationInput
        label="Display Name"
        name="display_name"
        value={props.configuration.displayName}
        rules={{ required: true }}
        onChange={(_, v) =>
          props.setConfiguration({
            ...props.configuration,
            displayName: v.value,
          })
        }
      />
    )}
  </>
)

export const WifiFields = (props: {
  validation: WifiUniqueSettingsValidation
  configuration: UniqueWifiConfiguration
  setConfiguration: (config: Partial<UniqueWifiConfiguration>) => void
}) => (
  <>
    {props.validation?.ipAddress?.required && (
      <ValidationInput
        label="IP Address"
        name="ip_address"
        rules={{
          required: true,
          pattern: RegexPattern.IpAddress,
        }}
        value={props.configuration.ipAddress ?? ''}
        onChange={(_, v) =>
          props.setConfiguration({ ...props.configuration, ipAddress: v.value })
        }
      />
    )}
    {props.validation?.wapSsid?.required && (
      <ValidationInput
        label="WAP SSID"
        name="wap_ssid"
        rules={{ required: true }}
        value={props.configuration.wapSsid}
        onChange={(_, v) =>
          props.setConfiguration({ ...props.configuration, wapSsid: v.value })
        }
      />
    )}
  </>
)
