import React from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Template,
  PowerManagementConfiguration, PowerManagementControlTypes,
} from 'graphql/__generated__/types'
import { SolRadio, SolNotification } from 'SolComponents'
import styles from './PowerManagement.module.scss'
import { ValidationInput } from '../../../components/FormValidation/ValidationInput'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: PowerManagementConfiguration) => void
}


function SuspendOptions({
  updateConfiguration,
  template,
}: Props) {
  const configuration = template.revision.configuration as PowerManagementConfiguration
  return (
    <Grid.Row>
      <Grid.Column className={styles.suspendOptionsWrapper}>
        <div className={styles.suspendOptions}>
          <h4>Choose Suspend Method <SolNotification type="menu" notifications="Gen 2i+"/></h4>
          <div className={styles.suspendRadio}>
            <SolRadio
              label="Suspend HDMI Signal"
              checked={configuration?.control?.controlMethod === PowerManagementControlTypes.Hdmi}
              onClick={() =>
                updateConfiguration({
                  ...configuration,
                  control: {
                    ...configuration.control,
                    controlMethod: PowerManagementControlTypes.Hdmi,
                  },
                })
              }
            />
          </div>
          <div className={styles.suspendRadio}>
            <SolRadio
              label="RS-232"
              checked={configuration?.control?.controlMethod === PowerManagementControlTypes.Rs232}
              onClick={() =>
                updateConfiguration({
                  ...configuration,
                  control: {
                    ...configuration.control,
                    controlMethod: PowerManagementControlTypes.Rs232,
                  },
                })
              }
            />
          </div>
          <div className={styles.suspendInputs}>
            <ValidationInput
              name="networkName"
              rules={{
                required: configuration?.control?.controlMethod === PowerManagementControlTypes.Rs232,
              }}
              label="Display on command"
              required={configuration?.control?.controlMethod === PowerManagementControlTypes.Rs232}
              size="extraLarge"
              disabled={configuration?.control?.controlMethod !== PowerManagementControlTypes.Rs232}
              value={configuration?.control?.commandDisplayOn ?? ''}
              onChange={(e, val) => {
                updateConfiguration({
                  ...configuration,
                  control: {
                    ...configuration.control,
                    commandDisplayOn: val.value ?? '',
                  },
                })
              }}
            />
            <ValidationInput
              name="displayOffCommand"
              rules={{
                required: configuration?.control?.controlMethod === PowerManagementControlTypes.Rs232,
              }}
              label="Display off command"
              required={configuration?.control?.controlMethod === PowerManagementControlTypes.Rs232}
              size="extraLarge"
              disabled={configuration?.control?.controlMethod !== PowerManagementControlTypes.Rs232}
              value={configuration?.control?.commandDisplayOff ?? ''}
              onChange={(e, val) => {
                updateConfiguration({
                  ...configuration,
                  control: {
                    ...configuration.control,
                    commandDisplayOff: val.value ?? '',
                  },
                })
              }}
            />
          </div>
        </div>
      </Grid.Column>
    </Grid.Row>
  )
}

export default SuspendOptions
