import React, { useEffect } from 'react'
import UpdatesBanner from './UpdatesBanner'
import { useHistory } from 'react-router-dom'
import { useUpdateablePodsCountQuery } from 'graphql/__generated__/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdatesBanner } from 'shared/store/slices/core'

const UpdatesBannerController = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { updatesBanner } = useFlags()
  const showBanner = useSelector((state: any) => state.core.updatesBanner)
  const { data } = useUpdateablePodsCountQuery({
    variables: {
      options: {
        isIncludedElementPods: false,
      },
    },
  })

  useEffect(() => {
    if (data?.updateablePods?.updateablePodsCount && updatesBanner) {
      dispatch(setUpdatesBanner(true))
    }
  }, [data])

  const onClick = () => {
    history.push('/manage/updates')
  }

  return showBanner ?  (
    <UpdatesBanner
      closeBanner={() => {dispatch(setUpdatesBanner(false))}}
      onButtonClick={() => onClick()}
      updateablePods={data?.updateablePods}
    />
  ) : null
}

export default UpdatesBannerController
