import React, { ReactNode }  from 'react'
import { Menu as SemanticMenu, Accordion } from 'semantic-ui-react'
import styles from './SolMenu.module.scss'

interface Props {
  children?: ReactNode
}

export const Menu = ({ children }: Props) => (
  <Accordion
    as={SemanticMenu}
    vertical
    className={styles.menu}
    data-testid="vertical-navbar"
  >
    {children}
  </Accordion>
)
