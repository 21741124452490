interface Data {
  building: string | undefined
  city: string | undefined
  state_province: string | undefined
  country: string | undefined
  zip_code: string | undefined
}

/* global google:readonly */
export const parseGoogleData = (addressBreakDown: google.maps.places.PlaceResult['address_components']) => {
  const newData: Data = {
    building: undefined,
    city: undefined,
    state_province: undefined,
    country: undefined,
    zip_code: undefined,
  }

  if (addressBreakDown !== undefined) {
    var dict = {
      street_number: undefined,
      route: undefined,
      premise: undefined,

      administrative_area_level_1: undefined,
      country: undefined,
      postal_code: undefined,

      locality: undefined,
      administrative_area_level_3: undefined,
      neighborhood: undefined,
      administrative_area_level_2: undefined,
      sublocality: undefined,
      sublocality_level_1: undefined,
      postal_town: undefined,
    }

    for (var i = 0; i < addressBreakDown.length; i++) {
      for (var j = 0; j < addressBreakDown[i].types.length; j++) {
        if (addressBreakDown[i].types[j] === 'administrative_area_level_1') {
          dict[addressBreakDown[i].types[j]] = addressBreakDown[i].short_name
        } else if (addressBreakDown[i].types[j] !== 'political') {
          dict[addressBreakDown[i].types[j]] = addressBreakDown[i].long_name
        }
      }
    }

    let buildingName
      = dict.street_number !== undefined || dict.route !== undefined ? dict.street_number + ' ' + dict.route : undefined
    newData.building = buildingName ?? dict.premise ?? undefined

    newData.city
      = dict.locality
      ?? dict.administrative_area_level_3
      ?? dict.sublocality
      ?? dict.sublocality_level_1
      ?? dict.postal_town
      ?? dict.neighborhood
      ?? dict.administrative_area_level_2
      ?? dict.administrative_area_level_1
    newData.state_province = dict.administrative_area_level_1

    newData.country = dict.country === 'United Kingdom' ? dict.administrative_area_level_1 : dict.country
    newData.zip_code = dict.postal_code
  }

  return newData
}

export default parseGoogleData
