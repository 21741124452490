import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import {
  SolsticeRoutingSpace,
  SolsticeRoutingRoutePreset,
  SolsticeRoutingRoute,
} from 'graphql/__generated__/types'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import {
  SolButton,
  SolEllipsesDropdown,
  SolInput,
  SolTooltip,
} from 'SolComponents'
import { PodGrid } from './PodGrid/PodGrid'
import { SpaceParams } from './SpacePage'
import styles from './routepreset.tab.module.scss'

export interface Props {
    setCurrentTab: Dispatch<SetStateAction<string>>
    space: SolsticeRoutingSpace
    setSpace: Dispatch<SetStateAction<SolsticeRoutingSpace | undefined>>
    saveSpace: any
}
export const RoutePresetsTab = ({
  setCurrentTab,
  space,
  setSpace,
  saveSpace,
}: Props) => {
  const { spaceId } = useParams<SpaceParams>()
  const newRoutePreset = (): SolsticeRoutingRoutePreset => {
    return {
      name: 'Preset #' + ((space?.route_presets?.length ?? 0) + 1),
      routes: [],
    }
  }

  const [routePresetIndex, setRoutePresetIndex] = useState(0)
  const [routePreset, setRoutePreset] = useState(
    space?.route_presets && space?.route_presets?.length > 0
      ? space?.route_presets[routePresetIndex] : newRoutePreset,
  )
  
  useEffect(() => {
    setRoutePreset( space?.route_presets && space?.route_presets?.length > 0
      ? space?.route_presets[routePresetIndex]
      : newRoutePreset,
    )
  }, [spaceId, space, space?.route_presets] )

  const addRoute = (newRoute: SolsticeRoutingRoute) => {
    const updatedRoutePreset = { ...routePreset, routes: [...routePreset?.routes!, newRoute] }
    setSpace({ ...space, route_presets: space.route_presets!.map((rp, i) => {
      return i === routePresetIndex ? updatedRoutePreset : rp
    }),
    })
  }

  const removeRoute = (routeToRemove: SolsticeRoutingRoute) => {
    const updatedRoutePreset = { ...routePreset, routes: routePreset?.routes?.filter(r =>
      r?.sink_pod?.id !== routeToRemove.sink_pod?.id
      || r?.source_pod?.id !== routeToRemove?.source_pod?.id,
    ) }
    setSpace({ ...space, route_presets: space.route_presets!.map((rp, i) => {
      return i === routePresetIndex ? updatedRoutePreset : rp
    }),
    })
  }

  const deleteRoutePreset = (routePresetToDelete: SolsticeRoutingRoutePreset) => {
    setSpace({ ...space,
      route_presets: space?.route_presets?.filter(rp => rp !== routePresetToDelete) ?? [newRoutePreset()],
    })
    if (routePreset === routePresetToDelete) {
      setRoutePresetIndex( 0 )
      setRoutePreset(space.route_presets![routePresetIndex])
    } else if (routePresetIndex > space?.route_presets!.length - 2) {
      setRoutePresetIndex(Math.max(routePresetIndex - 1, 0))
      setRoutePreset(space.route_presets![routePresetIndex])
    }
  }

  const duplicateRoutePreset = (routePresetToDupe: SolsticeRoutingRoutePreset) => {
    if (space?.route_presets && space?.route_presets?.length < 10) {
      const duplicatedRoutePreset = {
        ...routePresetToDupe,
        name: routePresetToDupe?.name + ' - Copy',
        created_at: null, updated_at: null, id: null,
        routes: [...routePresetToDupe?.routes ?? []],
      }
      setSpace({ ...space, route_presets: [...space.route_presets, duplicatedRoutePreset] })
      setRoutePresetIndex(space.route_presets.length)
      setRoutePreset(space.route_presets[routePresetIndex])
    }
  }

  return (
    <React.Fragment>
      <ContentWrapper>
        <div className={styles.header}>Custom Presets
          <SolTooltip text={`Save custom audio and video routing presets to 
            the space. Default presets such as Primary to All and All to 
            Primary will also be available. Create up to ten custom presets. `}/>
        </div>
        <div className={styles.subHeader}>
          To create a preset, click and drag routing lines between pods
          or click on a routing line to remove it.
        </div>

        <div className={styles.routePresetEdit}>
          <SolInput
            size="large"
            autoFocus={false}
            className={styles.routePresetNameInput}
            name={`route_preset_name_${routePreset?.id}`}
            value={routePreset?.name}
            maxLength={60}
            placeholder="Enter Preset Name"
            onChange={(e, value) => {
              e?.stopPropagation()
              const newRP = {
                ...routePreset,
                name: value.value,
              }
              setRoutePreset(newRP)
              setSpace({ ...space, route_presets: space?.route_presets?.map((rp, i) => {
                if (i === routePresetIndex) return newRP
                return rp
              }) })
            }}
          />

          <div className={styles.buttonWrapper}>

            <Dropdown className={styles.savedPresetsDropdown}
              text="Saved Presets"
            >
              <Dropdown.Menu>
                {space?.route_presets?.map( (rp, index) =>
                  (<Dropdown.Item
                    key={'route-preset-menu-' + index}
                    value={index}
                    text={rp?.name ?? 'Route Preset #' + index + 1}
                    onClick={(() => {
                      if (space?.route_presets) setRoutePreset(space?.route_presets[index])
                      setRoutePresetIndex(index)
                    })}
                    content={
                      <span className={styles.routePresetDropdownOption}>
                        <div className={styles.routePresetDropdownOptionName}>{rp?.name}</div>
                        <SolEllipsesDropdown
                          direction="right"
                          className={styles.routePresetEllipsesDropdown}
                        >
                          <Dropdown.Menu className={styles.rpMenu}>
                            <Dropdown.Item icon="pencil alternate" text="Delete" onClick={() => {
                              deleteRoutePreset(space?.route_presets?.[index]!)
                            }} />
                            <Dropdown.Item icon="copy outline" text="Duplicate" onClick={() => {
                              duplicateRoutePreset(space?.route_presets?.[index]!)
                            }} />
                          </Dropdown.Menu>
                        </SolEllipsesDropdown>
                      </span>
                    }
                  >
                  </Dropdown.Item>),
                )}
              </Dropdown.Menu>
            </Dropdown>

            <Button
              color="blue"
              disabled={(!!space?.route_presets && space?.route_presets?.length >= 10)}
              onClick={() => {
                const newRP = newRoutePreset()
                setSpace({ ...space,
                  route_presets: [...space.route_presets ?? [], newRP],
                })
                setRoutePresetIndex(space?.route_presets?.length ?? 0)
                setRoutePreset( space?.route_presets![routePresetIndex] )
              }}
            >
              <div><Icon name="plus circle"></Icon>Create New Preset</div>
            </Button>
          </div>
        
        </div>

        <div id="routePresetsPodGridWrapper">
          <PodGrid
            pods={space?.pods}
            routes={routePreset?.routes || []}
            cols={9}
            rows={6}
            showRoutes
            allowDraggingPods={false}
            addRoute={addRoute}
            removeRoute={removeRoute}
            primaryPodId={space?.primary_pod ?? ''}
          />
        </div>

        <span className={styles.buttonHolder}>
          <SolButton
            text="Back"
            className={styles.addPodsBackButton}
            handleClick={() => { setCurrentTab('design') }}
          />
          <SolButton
            text={space?.id ? 'Save' : 'Save and Finish'}
            color="blue"
            className={styles.addPodsNextButton}
            handleClick={() => { saveSpace() }}
          />
        </span>

      </ContentWrapper>
    </React.Fragment>)
}
