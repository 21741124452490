import mxRenewService, {
  MxRenewService,
} from './mxRenewServiceApi'

import {
  MxRenewRestInterface as MxRenewRestType,
  MxRenewServiceInterface as MxRenewServiceType,
  MxRenewInterface as MxRenewType,
  CreateMxRenewLeadInterface as CreateMxRenewLeadType,
  MxRenewLeadInterface as MxRenewLeadType,
  MxRenewLeadId as MxRenewLeadIdType,
} from './types'

import mockService from './__mocks__/mxRenewService.mock'
import mockServiceDelay from './__mocks__/mxRenewService.mock.delay'

export type MxRenewRestInterface = MxRenewRestType
export type MxRenewServiceInterface = MxRenewServiceType
export type MxRenewInterface = MxRenewType
export type CreateMxRenewLeadInterface = CreateMxRenewLeadType
export type MxRenewLeadInterface = MxRenewLeadType
export type MxRenewLeadId = MxRenewLeadIdType

export {
  MxRenewService,
  mockService,
  mockServiceDelay,
}

export default mxRenewService
