import setObj from 'lodash/set'
import { OrderBy, Direction } from 'components/DataTableSlim/DataTableSlim'
import {
  ViewDisplaysBoolExp,
  ViewDisplaysSelectColumn,
  ViewDisplaysAvailabilitySelectColumn,
  OrderBy as HasuraOrderBy,
} from 'graphql/__generated__/hasura-types'
import { CategoryFilter } from 'graphql/__generated__/types'

export interface SearchIds {
  include_ids?: string[]
  exclude_ids?: string[]
}

export interface SearchOptions {
  includedIds?: string[]
  excludedIds?: string[]
}

export const getCategorySearchArg = (categories: CategoryFilter[]) => {
  const result: ViewDisplaysBoolExp = { _and: [] }

  if (categories) {
    categories.map(category => {
      const optionIds = (category.optionIds ?? []) as string[]
      const includeOptionsVars = {
        categories: {
          is_assigned: { _eq: true },
          category_id: { _eq: category.id },
          option_id: { _in: optionIds },
        },
      }
      const includeUnassignedVars = {
        categories: {
          is_assigned: { _eq: false },
          category_id: { _eq: category.id },
        },
      }

      let vars: ViewDisplaysBoolExp = includeOptionsVars
      if (category.includeUnassigned && optionIds.length > 0) {
        vars = {
          _or: [includeOptionsVars, includeUnassignedVars],
        }
      } else if (category.includeUnassigned) {
        vars = includeUnassignedVars
      }

      result._and?.push(vars)
    })
  }
  return result
}

export const getDirection = (direction: Direction) => {
  switch (direction) {
    case 'descending':
      return HasuraOrderBy.DescNullsLast
    default:
      return HasuraOrderBy.AscNullsFirst
  }
}

export const getOrderByParams = (orderBy: OrderBy | undefined) => {
  const orderByFields: { [key: string]: HasuraOrderBy } = {}
  if (orderBy) {
    const direction = getDirection(orderBy.direction)
    setObj(
      orderByFields,
      orderBy.field === 'SUBSCRIPTION' ? 'is_in_subscription' : orderBy.field,
      direction,
    )
    if (
      orderBy.field !== ViewDisplaysSelectColumn.Name
      && orderBy.field !== ViewDisplaysSelectColumn.Version
    ) {
      orderByFields.name = direction
    }
    if (
      orderBy.field
      === 'availability.' + ViewDisplaysAvailabilitySelectColumn.IsOnline
    ) {
      orderByFields.is_in_subscription = direction
    }
    if (
      orderBy.field
      === 'availability.' + ViewDisplaysAvailabilitySelectColumn.LastOnline
    ) {
      setObj(orderByFields, 'availability', {
        status: direction,
        last_online: direction,
      })
    }
  }
  return [orderByFields]
}

/**
 * Hasura util to handle wildcard * in search request params
 */
export const getSearchIds = (searchOptions: SearchOptions): SearchIds => {
  const { includedIds, excludedIds } = searchOptions
  const hasIncludedWildCard = includedIds?.includes('*')
  const hasExcludedWildCard = excludedIds?.includes('*')
  const filteredDisplayIds = includedIds?.filter(val => val !== '*')
  const filteredExcludedIds = excludedIds?.filter(val => val !== '*')
  const filteredDisplayIdsSize = filteredDisplayIds?.length || 0
  const filteredExcludedListSize = filteredExcludedIds?.length || 0

  const args: SearchIds = {}

  const shouldIncludedBeEmpty
    // case: should return an empty included_ids array when the wild card is present in both arrays with ids
    = (hasIncludedWildCard && hasExcludedWildCard)
    // case: should exclude all the ids
    || (hasExcludedWildCard
      && !filteredDisplayIdsSize
      && !filteredExcludedListSize)
    // case: should have an empty include_ids array when there is a wildcard in excludedIds
    || (hasExcludedWildCard
      && filteredExcludedListSize
      && filteredDisplayIdsSize === 0)

  if (shouldIncludedBeEmpty) {
    args.include_ids = []
    return args
  }

  // case: should return an include_ids array with IDs when excluded_ids has a wildcard and ids
  if (
    hasExcludedWildCard
    && filteredExcludedListSize
    && filteredDisplayIdsSize
    && !hasIncludedWildCard
  ) {
    args.include_ids = filteredDisplayIds
    return args
  }

  // case: should return an excluded_ids array with ids if wildcard and ids are present in included_ids array
  // case: should have a excluded_ids array when there is no wildcards present in the arrays
  if (
    (hasIncludedWildCard && filteredExcludedListSize)
    || excludedIds?.length
  ) {
    args.exclude_ids = filteredExcludedIds
    // case: should have both arrays if there are ids in included_ids and excluded_ids and no wildcard
    if (!hasIncludedWildCard && filteredDisplayIds) {
      args.include_ids = filteredDisplayIds
    }
    return args
  }

  // case: should return an empty object when there is only an included_ids array with a wildcard
  if (hasIncludedWildCard && !filteredExcludedListSize) {
    return args
  }

  // case: should have an included_ids array when there is no wildcards present in the arrays
  if (filteredDisplayIdsSize) {
    args.include_ids = filteredDisplayIds
  }

  return args
}

export const getSearchIdArgs = (searchOptions: SearchOptions) => {
  const selectedIds = getSearchIds(searchOptions)
  return {
    id: {
      _in: selectedIds.include_ids,
      _nin: selectedIds.exclude_ids,
    },
  }
}
