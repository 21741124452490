import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const highFive = require('assets/high_five.png')
const headshot = require('assets/headshot.png')

export const style = {
  wrapper: {
    paddingTop: '64px',
    width: '500px',
    margin: '0 auto',
    textAlign: 'center',
  } as React.CSSProperties,
  highFive: {
    width: '320px',
    margin: '0 auto',
  } as React.CSSProperties,
  title: {
    fontSize: '50px',
    lineHeight: '60px',
  } as React.CSSProperties,
  text: {
    fontSize: '26px',
    lineHeight: '32px',
  } as React.CSSProperties,
  contactCard: {
    padding: '25px 36px',
    display: 'flex',
    fontSize: '18px',
    lineHeight: '24px',
    height: '150px',
    width: '500px',
    border: '1px solid #D8D8D8',
    borderLeft: '5px solid #1991EB',
  } as React.CSSProperties,
  contact: {
    margin: 'auto',
    width: '250px',
  } as React.CSSProperties,
  headshot: {
    height: '100px',
    width: '100px',
  } as React.CSSProperties,
  button: {
    marginTop: '70px',
    float: 'right',
  } as React.CSSProperties,
}

export interface Props {
  finishFlow: () => void
}
export default class AllDone extends React.Component<Props> {
  handleFinish = () => {
    const { finishFlow } = this.props
    finishFlow()
  }
  render() {
    return (
      <div style={style.wrapper}>
        <img style={style.highFive} src={highFive} alt="High five" />
        <h1 style={style.title}>You're all set!</h1>
        <p style={style.text}>We’ve received your request and will provide a quote shortly.</p>
        <div style={style.contactCard}>
          <img style={style.headshot} src={headshot} alt="headshot" />
          <p style={style.contact}>
            If you have questions please contact
            <strong> renew@mersive.com</strong>
          </p>
        </div>
        <Link to="home">
          <Button primary style={style.button} onClick={this.handleFinish}>
            Back to Solstice Cloud
          </Button>
        </Link>
      </div>
    )
  }
}
