import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { Role } from 'shared/types/Role'
import {
  OrderBy,
  UsersDocument,
  useUsersQuery,
  ViewUsersBoolExp,
  ViewUsersOrderBy,
} from 'graphql/__generated__/hasura-types'
import { useSearchParams } from 'components/DataTableSlim/Hooks/useSearchDisplays'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useMutation } from '@apollo/client'
import { User } from '../types'
import {
  CreateSsoUserDocument,
  CreateSsoUserMutation,
  CreateSsoUserMutationVariables,
  DeleteUserDocument,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  InviteUserDocument,
  InviteUserInput,
  InviteUserMutation,
  InviteUserMutationVariables,
  UpdateSsoStatusDocument,
  UpdateSsoStatusMutation,
  UpdateSsoStatusMutationVariables,
  UpdateUserRoleDocument,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
} from 'graphql/__generated__/types'
import { useAlerts } from 'shared/hooks/useAlerts'

const sortMapper = (field: string, orderBy: OrderBy) => {
  const viewUsersOrderBy: ViewUsersOrderBy[] = [{ email: orderBy }]
  if (field !== 'email') {
    viewUsersOrderBy.unshift({ [field]: orderBy })
  }
  return viewUsersOrderBy
}

const searchMapper = (searchTerm: string) => ({
  email: { _ilike: `%${searchTerm}%` },
})

export const useAccountList = () => {
  const {
    searchValue,
    page,
    orderBy: orderByTable,
    setOrderBy,
  } = useDataTable<User>(DataTableInstance.Users)
  const variables = useSearchParams<ViewUsersBoolExp>({
    tableInstance: DataTableInstance.Users,
    page,
    search: searchValue,
    orderBy: orderByTable,
    setOrderBy,
    sortMapper,
    searchMapper,
  })
  const { showSuccess } = useAlerts()
  const { data, loading: loadingUsers, refetch: refetchUsers } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables,
  })
  const users = data?.users.filter((user: User) => user.email) ?? []
  const totalUsers = data?.totalUsers.aggregate?.count ?? 0

  const [inviteUserMut, { loading: inviteLoading }] = useMutation<
    InviteUserMutation,
    InviteUserMutationVariables
  >(InviteUserDocument,
    {
      onCompleted: () => {
        showSuccess('Invite Sent')
      },
    })
  const [createSsoUserMut, { loading: createSSOLoading }] = useMutation<
    CreateSsoUserMutation,
    CreateSsoUserMutationVariables
  >(CreateSsoUserDocument,
    {
      onCompleted: () => {
        showSuccess('Added SSO User')
      },
    })
  const [updateUserRoleMut, { loading: updateUserLoading }] = useMutation<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >(UpdateUserRoleDocument)
  const [updateSsoStatusMut, { loading: updateSsoLoading }] = useMutation<
    UpdateSsoStatusMutation,
    UpdateSsoStatusMutationVariables
  >(UpdateSsoStatusDocument)
  const [deleteUserMut, { loading: deleteLoading }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument)

  const options = {
    refetchQueries: [
      {
        query: UsersDocument,
        variables,
      },
    ],
    awaitRefetchQueries: true,
    update: () => refetchUsers(),
  }

  const updateUser = (user: User) =>
    updateUserRoleMut({
      variables: {
        options: {
          email: user.email ?? '',
          role: user.role ?? Role.Viewer,
        },
      },
      ...options,
    })

  const updateSsoStatus = (user: User) => {
    return (
      updateSsoStatusMut({
        variables: {
          options: {
            email: user.email ?? '',
            ssoOnly: user.sso_only || false,
          },
        },
        ...options,
      })
    )
  }

  const deleteUser = (user: User) =>
    deleteUserMut({
      variables: {
        options: {
          email: user.email ?? '',
        },
      },
      ...options,
    })

  const sendInviteForUser = (user: InviteUserInput) =>
    inviteUserMut({
      variables: {
        options: {
          email: user.email ?? '',
          role: user.role ?? Role.Viewer,
          resend: user.resend,
        },
      },
      ...options,
    })

  const addSsoUser = (user: User) =>
    createSsoUserMut({
      variables: {
        options: {
          email: user.email ?? '',
          role: user.role ?? Role.Viewer,
        },
      },
      ...options,
    })

  return {
    updateUser,
    updateSsoStatus,
    sendInviteForUser,
    deleteUser,
    refetchUsers,
    addSsoUser,
    totalUsers,
    users,
    // eslint-disable-next-line max-len
    loadingUsers:  updateSsoLoading || updateUserLoading || deleteLoading || loadingUsers || createSSOLoading || inviteLoading,
    sendingInvite: createSSOLoading || inviteLoading,
  }
}
