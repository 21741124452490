import { ValidationInput } from 'components/FormValidation/ValidationInput'
import React from 'react'
import SolDropdown from 'SolComponents/SolDropdown/SolDropdown'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import styles from './SSO.module.scss'
import VerificationCert from './VerificationCert'
import { validateURL } from 'shared/core/utils'
import { SolQuestionCircle } from 'SolComponents/Icons'
import { SsoInfoQuery } from 'graphql/__generated__/types'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'

export type Props = {
  cert: string
  certName: string
  ssoLoading: boolean
  idpDropdown: DropdownItemProps[]
  idp: IDP
  signOnURL: string
  ssoData?: SsoInfoQuery
  idpEntityId: string
  setIdpEntityId: (entityId: string) => void
  setCert: (certName: string) => void
  setCertName: (certName: string) => void
  setIdp: (idp: IDP) => void
  setSignOnURL: (url: string) => void
  invalidCert: boolean
}

export type IDP = {
  id: number
  name: string
  spURL: string
  placeholder: string
}

const SSOForm = ({
  cert,
  certName,
  idpDropdown,
  idp,
  idpEntityId,
  ssoLoading,
  setIdpEntityId,
  setIdp,
  signOnURL,
  setSignOnURL,
  setCert,
  setCertName,
  invalidCert,
}: Props) => {
  return (
    <>
      <div className={styles.input}>
        <SolDropdown
          loading={ssoLoading}
          label="Identity Provider"
          size="large"
          options={idpDropdown}
          value={idp.id}
          onChange={val => {
            const idpInfo = idpDropdown.find((el: any) => el.value === parseInt(val))
            const text = (idpInfo?.text)?.toString() ?? ''
            setIdp({ id: parseInt(val), name: text, spURL: idpInfo?.url, placeholder: idpInfo?.placeholder })
          }}
        />
      </div>
      <div className={styles.input}>
        <ValidationInput
          label="Sign On URL"
          placeholder={idp.placeholder}
          value={signOnURL}
          name="signOnURL"
          required
          size="fluid"
          icon={(
            <SolTooltip
              text={`
                Enter the sign on URL of the SSO provider where
                Solstice Cloud will redirect users to log in
                when a user selects to sign in with SSO.
              `}
              trigger={<SolQuestionCircle size="small" />}
            />
          )}
          onChange={(_, v) => {
            setSignOnURL(v.value)
          }}
          rules={{
            required: true,
            validate: validateURL,
          }}
          errorText="Invalid URL"
        />
      </div>
      <div className={styles.input}>
        <ValidationInput
          label={idp.name === 'Azure'  ? 'Azure AD Identifier' : 'Identity Provider Issuer'}
          placeholder={idp.name === 'Azure' ? 'https://sts.windows.net/{uuid}/' : 'https://www.okta.com/{oktaId}'}
          value={idpEntityId}
          name="idpEntityId"
          required
          size="fluid"
          icon={(
            <SolTooltip
              text={`
              The entity ID is the unique name for your identity provider
              account and can be found within their setup dashboard.
              `}
              trigger={<SolQuestionCircle size="small" />}
            />
          )}
          onChange={(_, v) => {
            setIdpEntityId(v.value)
          }}
          rules={{
            required: true,
          }}
        />
      </div>

      <VerificationCert
        cert={cert}
        certName={certName || ''}
        setCertName={setCertName}
        placeHolder="Paste certificate here"
        setCert={setCert}
        invalidCert={invalidCert}
      />
    </>
  )
}

export default SSOForm
