import React from 'react'
import styles from './StaticTable.module.scss'

export interface Props {
  headers: string[]
  rows: string[][]
}

const StaticTable = (props: Props) => (
  <table className={styles.podTableID}>
    <thead className={styles.podTableHead}>
      <tr className={styles.podTableRow}>
        {props.headers.map((header: string, index: React.ReactText) => (
          <td className={styles.podTableData} key={index}>
            {header}
          </td>
        ))}
      </tr>
    </thead>
    <tbody className={styles.podTableBody}>
      {props.rows.map((row: string[], rowIndex: React.ReactText) => (
        <tr className={styles.podTableRow} key={rowIndex}>
          {row.map((cell: string, cellIndex: React.ReactText) => (
            <td className={styles.podTableData} key={cellIndex}>
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default StaticTable
