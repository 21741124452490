import { OrgAddressesBoolExp, useSearchAddressesQuery } from 'graphql/__generated__/hasura-types'
import { useDebounce } from 'use-debounce'
type NickNameIsValid = {
  loading: boolean
  isValid: boolean
}

export const useNicknameIsValid = (
  nickname: string,
  excludeAddressId?: number,
  locationId?: string,
): NickNameIsValid => {
  const [searchValue] = useDebounce(nickname, 300, { maxWait: 300 })

  const where: OrgAddressesBoolExp = {
    id: excludeAddressId
      ? {
        _nin: [excludeAddressId],
      }
      : {},
    _and: [
      {
        external_id: {
          _eq: locationId,
        },
      },
      {
        nickname: {
          _ilike: searchValue,
        },
      },
    ],
  }

  const { data, loading } = useSearchAddressesQuery({
    variables: {
      where: where,
    },
    fetchPolicy: 'no-cache',
  })

  return {
    loading,
    isValid: searchValue.length === 0 || data?._addresses.length === 0,
  }
}
