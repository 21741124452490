import React, { useEffect } from 'react'
import { SolInput } from '../../SolComponents'
import { SolInputProps } from '../../SolComponents/SolInput/SolInput'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { InputOnChangeData } from 'semantic-ui-react'
import { PasswordContainer } from 'pages/Templates/Common/PasswordContainer'
import { useValidation } from './useValidation'
import classNames from 'classnames'
import solInputStyles from '../../SolComponents/SolInput/SolInput.module.scss'
import solPasswordStyles from '../../SolComponents/SolPassword/SolPassword.module.scss'

/* eslint-disable max-len */
export const RegexPattern = {
  IpAddress:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
  Url: /^(?:(?:(?:https?|ftp):)?(\/\/)?)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  Email: /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i,
  PhoneNumber:
    /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/,
  HypertextProtocol: /^https?:\/\//,
  Binary: /^[0-1]{6}/,
  PositiveIntegers: /^[1-9]\d*/,
  IntegersOnly: /^[0-9]*$/,
  IntegersOnlyWithNegatives: /^-?[0-9]*$/,
  PasswordStrength: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  IpAddressOrUrl:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
}
/* eslint-enable max-len */

/**
 * Checks if a given value is a valid URL, IP Address, or if the hostname is an IP address
 *
 * @function
 * @name validateUrlOrIpAddress
 * @param {string} val A possible URL or IP address
 * @returns {boolean} Whether or not he value is a valid IP or URL
 */
export function validateUrlOrIpAddress(val: string) {
  if (RegexPattern.IpAddressOrUrl.test(val)) {
    return true
  }

  try {
    const { protocol, hostname } = new URL(val)
    return /^https?:/i.test(protocol)
      && !/\s/.test(val)
      && RegexPattern.IpAddress.test(hostname)
  } catch (err) {
    // not a valid URL
  }
  return false
}

// To use you must wrap component in a FormValidation component
type Props = SolInputProps & {
  name: string
  rules: RegisterOptions
  onClear?: () => void | undefined
  value?: string | null
  hash?: string | null
  salt?: string | null
  exists?: boolean | null
  variant?: 'text' | 'password'
  maxLength?: number
  disabled?: boolean
  noHighlightOnEmptyText?: boolean
  errorText?: string
  onBlur?: () => void
}

function ValidationInputComponent(props: Props) {
  const { setValue, error } = useValidation(props.name, `${props.value ?? ''}`, props.rules)

  const { required } = props.rules

  useEffect(() => {
    if (props.disabled && !required && error) {
      const value: InputOnChangeData = { value: '' }
      props.onChange?.(null, value)
    }
  }, [props.disabled, required, error])
  const Input = props.variant === 'password' ? PasswordContainer : SolInput
  const inputStyles = props.variant === 'password' ? solPasswordStyles : solInputStyles

  return (
    <Input
      {...props}
      onChange={async (
        _: React.SyntheticEvent<HTMLInputElement, Event> | null,
        value: InputOnChangeData,
      ) => {
        setValue(props.name, value.value, {
          shouldValidate: true,
          shouldDirty: true,
        })
        props.onChange?.(_, value)
      }}
      onClear={() => {
        setValue(props.name, '', { shouldValidate: true, shouldDirty: true })
        props.onClear?.()
      }}
      className={classNames({
        [inputStyles.noBackgroundHighlight]: (
          !!error && props.noHighlightOnEmptyText && !props.value?.length
        ),
      })}
      value={props.value}
      hash={props.hash}
      salt={props.salt}
      size={props.size}
      error={error}
      errorText={error && props?.value?.length ? props.errorText : null}
      maxLength={props.maxLength}
      required={props.rules.required === true}
      disabled={props.disabled}
    />
  )
}

ValidationInputComponent.defaultProps = {
  noHighlightOnEmptyText: true,
}

export const ValidationInput = React.memo(ValidationInputComponent)
