import { OrderBy } from './DataTableSlim'
import { AppliedCategoryFilters, CustomFilters } from './Hooks/useAppliedFilters'

export interface ServerSelectedItems {
  includedIds?: string[]
  excludedIds?: string[]
}

// all the selected items that are in local memory
export interface SelectedItems extends ServerSelectedItems {
  items: any[]
  ids: any[]
}

export interface Page {
  size: number
  number: number
}

export interface Data<CustomFilterValueType = {}> {
  selectedItems: SelectedItems
  page: Page
  searchValue: string
  orderBy: OrderBy
  addedColumns: string[]
  categoryFilters: AppliedCategoryFilters
  customFilters: CustomFilters<CustomFilterValueType>
  categorySorts: Record<string, string>
}

export interface DataTables<CustomFilterValueType = {}> {
  [tableId: string]: Data<CustomFilterValueType>
}

export const defaultValue = {
  selectedItems: {
    ids: [],
    items: [],
    includedIds: undefined,
    excludedIds: undefined,
  },
  page: {
    size: 25,
    number: 1,
  },
  searchValue: '',
  addedColumns: [],
  categoryFilters: {},
  customFilters: {},
  categorySorts: {},
}
