import {
  HdmiOutputModeType,
  InstructionsDisplayType,
  SettingsGroupType,
  Template,
} from 'graphql/__generated__/types'
import { DropdownItemProps } from 'semantic-ui-react'

import defaultCustomInstructions from './defaultCustomInstructions.html'

const temp = {
  id: '',
  org: 'test',
  name: '',
  isCustom: false,
  isDefault: false,
}

export const welcomeScreenDefault: Template = {
  ...temp,
  settingsGroup: {
    name: 'Welcome Screen',
    type: SettingsGroupType.WelcomeScreen,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      isModern: true,
      hdmiOutput: HdmiOutputModeType.Single,
      instructions: InstructionsDisplayType.ShowDefault,
      showAirplay: true,
      showMiracast: true,
      customInstructions: defaultCustomInstructions,
      background: '',
      pbShowPresenceBar: true,
      pbShowRoomName: true,
      pbShowIPAddress: true,
      pbShowScreenKey: true,
      showClock: true,
      showRoomName: true,
      screenKey: true,
      showHostnameInsteadOfIp: false,
      showFullyQualifiedName: false,
      shouldAnimateBackground: false,
      textColor: '#FFFFFF',
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}

export const hdmiOptions: DropdownItemProps[] = [
  {
    key: 0,
    value: HdmiOutputModeType.Single,
    setting: 'hdmiOutput',
    text: 'Single ',
  },
  {
    key: 1,
    value: HdmiOutputModeType.Mirror,
    setting: 'hdmiOutput',
    text: 'Dual - Mirror',
  },
  {
    key: 2,
    value: HdmiOutputModeType.Span,
    setting: 'hdmiOutput',
    text: 'Dual - Extend',
  },
  {
    key: 3,
    value: HdmiOutputModeType.SeamlessSpan,
    setting: 'hdmiOutput',
    text: 'Dual - Seamless Extend',
  },
]

export const notAvailableForElementPods: any[] = [
  HdmiOutputModeType.Span,
  HdmiOutputModeType.SeamlessSpan,
]

export const instructionOptions: DropdownItemProps[] = [
  { key: 0, value: InstructionsDisplayType.ShowDefault, text: 'Default' },
  {
    key: 1,
    value: InstructionsDisplayType.ShowCustom,
    text: 'Show custom instructions',
  },
  {
    key: 2,
    value: InstructionsDisplayType.Hide,
    text: `Don't show instructions`,
  },
]
