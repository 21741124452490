import React, { useEffect, useState } from 'react'
import { SolButton } from 'SolComponents'
import styles from './Map.module.scss'
import { BaseAddress } from './MapsTypes'
import { BuildingsDropdown } from 'components/Location/AddressSearchSelectDropdown/BuildingsDropdown'
import { CardModal } from 'components/Location/AddressSearchSelectDropdown/CardModal/CardModal'

interface Props {
  displayName: string
  isOpen: boolean
  onSelectAddress: (val: BaseAddress) => void
}

export const SetInitialLocationModal = ({
  isOpen,
  onSelectAddress,
  displayName,
}: Props) => {
  const [selectedAddress, setSelectedAddress] = useState<BaseAddress>()

  useEffect(() => {
    setSelectedAddress(undefined)
  }, [isOpen])

  return (
    <CardModal
      className={styles.setLocationModal}
      isOpen={isOpen}
      header={`Set location for ${displayName}`}
      modalInfo={
        <BuildingsDropdown
          address={selectedAddress}
          size="static"
          variant="new-location-view"
          label="Select building from list or add new"
          onSelectAddress={setSelectedAddress}
        />
      }
      actionButtons={
        <SolButton
          className={styles.nextButton}
          text="Next"
          color="blue"
          disabled={!selectedAddress}
          handleClick={() => {
            onSelectAddress(selectedAddress!)
          }}
        />
      }
    />
  )
}
