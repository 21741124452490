import React from 'react'
import { SolConfirmationModal } from 'SolComponents'
import { useAlerts } from 'shared/hooks/useAlerts'
import { useState } from 'react'

type Props = {
  data?: { id: string; name: string }
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  deletePod: (podId: string) => void
}

const trimmedName = (podName: string) => {
  if (podName.length >= 22) {
    return podName.substring(0, 22) + '...'
  }
  return podName
}

const subHeader = (
  <span data-testid="subheader">
    All Pod information will be <strong>permanently deleted</strong> from Solstice Cloud. This cannot be undone.
  </span>
)

const DeleteModal = ({ isOpen, setIsOpen, deletePod, data }: Props) => {
  const { showError, showSuccess } = useAlerts()
  const [deleting, setDeleting] = useState(false)

  const body = (
    <span data-testid="body">
      Are you sure you want to delete <strong>{trimmedName(data?.name || '')}</strong>?
    </span>
  )

  const onAccept = async () => {
    if (data?.id) {
      setDeleting(true)
      try {
        await deletePod(data.id)
        showSuccess(`${data.name} successfully deleted.`)
      } catch (e) {
        showError(`Could not delete ${data.name}. Please try again later.`)
      }
      setDeleting(false)
    }
    setIsOpen(false)
  }

  const onClose = () => setIsOpen(false)

  return (
    <div>
      <SolConfirmationModal
        dataTestId="delete-modal"
        isOpen={isOpen}
        modalText={body}
        onAcceptingAction={onAccept}
        acceptingText="Delete"
        cancelText="Cancel"
        onCloseModal={onClose}
        subHeaderText={subHeader}
        acceptingDisabled={deleting}
        cancelDisabled={deleting}
      />
    </div>
  )
}

export default DeleteModal
