import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { Form, Icon } from 'semantic-ui-react'
import { SolButton, SolTooltip } from 'SolComponents'
const solsticeCloudLoginLogo = require('../../assets/solstice-cloud-login.png')
import styles from './CreateOrg.module.scss'
import { createOrgData } from './CreateOrgController'
import validation from './Utilities/OrgFormValidation'

export interface formValues {
  email: string
  password: string
  confirmPassword: string
  termsAgree: boolean
  error: string
}

export interface validationValues {
  email: string
  password: string
  confirmPassword: string
  termsAgree: string
  genericError: string
}

function CreateOrg(props: any) {
  const [seePassword, setSeePassword] = useState(false)
  const [seeConPassword, setSeeConPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState<formValues>({
    email: '',
    password: '',
    confirmPassword: '',
    termsAgree: false,
    error: '',
  })
  const [validate, setValidate] = useState<validationValues>({
    email: '',
    password: '',
    confirmPassword: '',
    termsAgree: '',
    genericError: '',
  })

  const checkboxText = (<label>I agree to the
    <a className={styles.link}
      href="https://www.mersive.com/terms-and-conditions/"
      target="_blank" rel="noreferrer"> Terms of Service </a>
    and have read the<a className={styles.link} href="https://www.mersive.com/privacy-policy/"
      target="_blank" rel="noreferrer"> Privacy Policy</a>
    .</label>)


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const podSerialPopup = (
    <div className={styles.popupBox}>
      <p>
        Please enter a serial number of a Gen3 Pod you have. You can find this on the bottom of the Pod.
      </p>
      <p>
        If you have a Gen2i Pod, please contact support.
      </p>
    </div>
  )
  const popupText = (<div className={styles.popupBox}>
    <span className={styles.passwordText}>Please create a password with:</span>
    <ul className={styles.list}>
      <li>A minimum of 10 characters</li>
      <li>At least one uppercase character</li>
      <li>At least one lowercase character</li>
      <li>At least one number</li>
    </ul>
  </div>)

  function onChange(event: any) {
    const { name, value, checked } = event.target
    setFormValues(oldState => ({ ...oldState, [name]: name === 'termsAgree' ? checked : value }))
    setValidate(oldState => ({ ...oldState, [name]: '' }))
  }

  const onSubmit = () => {
    setLoading(true)
    const validateResult = validation(formValues)
    setValidate(oldState => ({ ...oldState, ...validateResult }))
    if (Object.values(validateResult).every(x => x === '')) {
      const requestObject: createOrgData  = {
        email: formValues.email,
        password: formValues.password,
      }
      props.createOrgRequest(requestObject)
        .catch((err: AxiosError) => {
          if (err.response?.status === 409) {
            setValidate(oldState => ({
              ...oldState,
              'email': 'user exists',
            }))
          } else {
            setValidate(oldState => ({
              ...oldState,
              'genericError': 'Oops, something went wrong. Please try again.',
            }))
          }
          setLoading(false)
        })
    } else {setLoading(false)}
  }
  return (
    <div className={styles.createOrgWrapper}>
      <div className={styles.createOrgContainer}>
        <img draggable={false} className={styles.createOrgLogo} src={solsticeCloudLoginLogo} />
        <Form autoComplete="off" onSubmit={onSubmit}>
          <Form.Group className={styles.group}>
            <div className={styles.back}>
              <button type="button" className={styles.buttonLink} onClick={() => props.history.push('/')}>
                <Icon name="angle left" /> Back
              </button>
            </div>
            <Form.Input
              autoComplete="off"
              id="email"
              name="email"
              className={styles.input}
              type="text"
              label={<React.Fragment>
                <span className={styles.inputText}>Email</span>
                <span className={styles.asterisk}>*</span>
              </React.Fragment>}
              required
              error={!!validate.email}
              value={formValues.email}
              onChange={e => onChange(e)}
            />
            {(validate.email && validate.email !== 'user exists')
         && <p className={styles.error}>{validate.email}</p>}
            {(validate.email && validate.email === 'user exists')
         && <p className={styles.error}>
           You already have a Solstice Cloud Account.
           <a href={`/login?email=${formValues.email}`} className={styles.link}> Please sign in </a></p>}
            <Form.Input
              autoComplete="off"
              icon={<Icon
                name={seePassword ? 'eye slash' : 'eye'}
                link
                onClick={() => setSeePassword(!seePassword)}/>}
              id="password"
              name="password"
              className={styles.input}
              type={!seePassword ? 'password' : 'text'}
              required
              error={!!validate.password}
              value={formValues.password}
              onChange={e => onChange(e)}
              label={<React.Fragment>
                <span className={styles.inputText}>Create password</span>
                <span className={styles.asterisk}>*</span>
                <SolTooltip className={styles.popup} isInline text={popupText} />
              </React.Fragment>
              }
            />
            {validate.password && <p className={styles.error}>{validate.password}</p>}
            <Form.Input
              autoComplete="off"
              icon={<Icon
                name={seeConPassword ? 'eye slash' : 'eye'}
                link
                onClick={() => setSeeConPassword(!seeConPassword)}/>}
              id="confirmPassword"
              name="confirmPassword"
              className={styles.input}
              type={!seeConPassword ? 'password' : 'text'}
              label={<React.Fragment>
                <span className={styles.inputText}>Repeat password</span>
                <span className={styles.asterisk}>*</span>
              </React.Fragment>}
              required
              value={formValues.confirmPassword}
              error={!!validate.confirmPassword}
              onChange={e => onChange(e)}
            />
            {validate.confirmPassword && <p className={styles.error}>{validate.confirmPassword}</p>}
            <Form.Checkbox
              id="termsAgree"
              name="termsAgree"
              className={styles.input}
              label={checkboxText}
              checked={formValues.termsAgree}
              onChange={e => onChange(e)}
              required
            />
            {validate.termsAgree && <p className={styles.error}>{validate.termsAgree}</p>}
            <div className={styles.loginButton}>
              <SolButton
                loading={loading}
                disabled={!formValues.email
                  || !formValues.password
                  || !formValues.confirmPassword
                  || !formValues.termsAgree}
                text="Create Account and Login"
                primary
                basic={false}
                isLink={false}
              />
            </div>
            {validate.genericError && <p className={styles.error}>{validate.genericError}</p>}
          </Form.Group>
        </Form>
      </div>
      <span className={styles.copyright}>Copyright 2023 Mersive Technologies, Inc. All rights reserved.</span>
    </div>
  )
}

export default CreateOrg
