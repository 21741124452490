import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { SolTooltip } from 'SolComponents'
import SolPassword, { Props } from 'SolComponents/SolPassword/SolPassword'
import styles from './PasswordContainer.module.scss'
import { PasswordValidationModal } from './PasswordValidationModal'

export function PasswordContainer(props: Props) {
  // if the password was set by Dashboard, Solstice Cloud won't have it
  const setByDashboard = props.value === undefined && props.hash && props.salt
  const [modalOpen, setModalOpen] = useState(false)

  const value = setByDashboard ? 'set-by-dashboard' : props.value ?? ''
  const [initialValue, setInitialValue] = useState<string>(value)
  const [internalValue, setInternalValue] = useState<string>(value)
  const isDirty = value !== initialValue
  const { setError, clearErrors } = useFormContext()

  useEffect(() => {
    /*
      listen for a "save" event where the value for the password becomes
      different than what was on the template originally
    */
    if (props.value !== internalValue) {
      setInitialValue(value)
    }
  }, [props.value])

  useEffect(() => {
    if (setByDashboard) {
      setError(`unknown_password_${props.name}`, {
        type: 'unknownPassword',
      })
    }
    return () => clearErrors(`unknown_password_${props.name}`)
  }, [setByDashboard])

  return (
    <>
      <SolPassword
        {...props}
        value={value}
        isDirty={isDirty}
        onChange={(e, v) => {
          props.onChange(e, v)
          setInternalValue(v.value)
        }}
      />
      {setByDashboard && (
        <div className={styles.reenterDashboardPassword}>
          <a onClick={() => setModalOpen(true)} className={styles.link}>
            Re-enter Dashboard password
          </a>
          &nbsp;
          <SolTooltip text="Re-enter Dashboard password or enter a new password" isInline position="right center" />
          <PasswordValidationModal
            isOpen={modalOpen}
            close={() => setModalOpen(false)}
            setPassword={pwd => {
              props.onChange(null, { value: pwd })
              setInternalValue(pwd)
            }}
            hash={props.hash ?? ''}
            salt={props.salt ?? ''}
          />
        </div>
      )}
    </>
  )
}
