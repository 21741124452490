import React, { useState } from 'react'
import { SolTooltipWithLink } from 'SolComponents'
import MaintenanceModal from 'components/Maintenance/MaintenanceModal'
import styles from './index.module.scss'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

export function OutOfSubscriptionCell() {
  const [showModal, setShowModal] = useState(false)
  return (
    <React.Fragment>
      <MaintenanceModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        slideKeys={['availability', 'proVsBase']}
      />
      <SolTooltipWithLink
        trigger={<SolAlertIcon className={styles.exclamationTriangle} />}
        text={[
          'Certain features are not available for this Pod because its Solstice',
          'Subscription period has lapsed.',
        ]}
        buttonText="LEARN MORE"
        position="bottom right"
        onClick={() => {
          setShowModal(true)
        }}
      />
    </React.Fragment>
  )
}
