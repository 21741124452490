import React, { SFC } from 'react'
import { Card } from 'semantic-ui-react'
import classNames from 'classnames'
import styles from './SolCard.module.scss'

export type Props = {
  header?: React.ReactNode
  children: React.ReactNode
  shadow?: boolean
  className?: string
  modal?: JSX.Element
  fluid?: boolean
}

const SolCard: SFC<Props> = (props: Props) => {
  return (
    <Card
      fluid={props.fluid}
      className={classNames(styles.solCard, props.className, {
        [styles.shadow]: props.shadow !== false,
      })}
    >
      <Card.Content>
        {props.modal}
        <Card.Header>{props.header}</Card.Header>
        {props.children}
      </Card.Content>
    </Card>
  )
}

export default SolCard
