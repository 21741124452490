import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import styles from './Accordian.module.scss'

export interface Props {
  title: string
  edit: () => void
  children: any
}

const AccordionExampleStandard = (props: Props) => {
  const { title, edit } = props
  const [accordianOpen, setAccordianOpen] = useState(false)

  const handleClick = () => {
    setAccordianOpen(!accordianOpen)
  }

  return (
    <div>
      <Accordion>
        <Accordion.Title active={accordianOpen} index={0} onClick={handleClick}>
          <span className={styles.accordianTitle}>{title}</span>
          <Icon className={styles.accordianIcon} name="chevron down" size="large" />
          <a className={styles.accordianEdit} onClick={edit}>
            Edit
          </a>
        </Accordion.Title>
        <Accordion.Content active={accordianOpen}>{props.children}</Accordion.Content>
      </Accordion>
    </div>
  )
}

export default AccordionExampleStandard
