import React from 'react'
import AvailabilityPage from './Availability'
import {
  useAvailabilityQuery,
  useAvailabilityLazyQuery,
  PlatformConfigQuery,
  PlatformConfigDocument,
} from '../../graphql/__generated__/types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { useQuery } from '@apollo/client'

const AvailabilityController = () => {
  const {
    searchValue,
    page,
    orderBy,
    customFilters,
  } = useDataTable<any>(DataTableInstance.Availability)

  // The only custom filter on this page is pod status
  const customOptions: { status?: string } = (() => {
    const onlineVals = customFilters?.currentlyStatus?.values
    if (!onlineVals || onlineVals.Online && onlineVals.Offline) {
      return {}
    } else if (onlineVals.Online) {
      return { status: 'READY' }
    } else if (onlineVals.Offline) {
      return { status: 'POD_OFFLINE' }
    }
    return {}
  })()

  const queryOptions = {
    ...useAppliedFilterOptions(DataTableInstance.Availability),
    ...customOptions,
    nameLike: searchValue,
    orderBy: { [orderBy?.field ?? 'name']: (orderBy?.direction !== 'ascending' ? 'DESC' : 'ASC') },
  }

  const result = useAvailabilityQuery({
    pollInterval: 20000,
    variables: {
      options: {
        ...queryOptions,
        offset: (page.number - 1) * page.size,
        limit: page.size,
      },
    },
  })

  const [fetchCsvData] = useAvailabilityLazyQuery({
    variables: {
      options: {
        ...queryOptions,
        offset: 0,
        limit: 999999,
      },
    },
  })

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const availability = result.data?.availability
  const aggregate = availability?.aggregate

  return (<AvailabilityPage
    rows={availability?.pods ?? []}
    pods={aggregate?.pods ?? 0}
    subscribedPods={aggregate?.subscribedPods ?? 0}
    podsOnlineNow={aggregate?.podsOnlineNow ?? 0}
    podsOnlineSinceYesterday={aggregate?.podsOnlineSinceYesterday ?? 0}
    loading={result.loading}
    fetchCsvData={fetchCsvData}
    hasElementPods={hasElementPods}
  />)
}

export default AvailabilityController
