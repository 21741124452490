import React from 'react'
import styles from './CategoryChartLegendView.module.scss'
import SolEditIcon from 'SolComponents/Icons/SolEditIcon'
import { SolButton } from 'SolComponents'
import { CategoryPieData } from '../CategoryPieData.interface'

export interface Props {
  data: CategoryPieData
  editClicked: () => void
}

export const CategoryChartLegendView = ({ data, editClicked }: Props) => {
  return (
    <div>
      <div className={styles.controls}>
        {data.editable && (
          <SolButton basic noBoxShadow text={<SolEditIcon className={styles.editIcon} />} handleClick={editClicked} />
        )}
      </div>
      <div className={styles.legend}>
        {data.data.map((option, key) => {
          return (
            <div key={key} className={styles.item}>
              <div
                className={styles.circle}
                style={{
                  backgroundColor: option.color,
                }}
              />
              <div className={styles.text}>
                {/* name */}
                <div className={styles.name}>{option.name}</div>
                {/* flexy bit */}
                <div className={styles.flexy} />
                {/* count */}
                <div className={styles.gray}>{option.value}&nbsp;</div>
                {/* percent */}
                <div className={styles.gray}>
                  ({parseFloat(option.percentage.toString()).toFixed(1)}
                  %)
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryChartLegendView
