import jwt from 'jsonwebtoken'

export function isAuthenticated() {
  const token = getAccessToken()
  if (!token) {
    return false
  }
  const decoded = jwt.decode(token) as any

  if (!decoded) {
    return false
  }
  const timeNow = Date.now() / 1000
  return !(timeNow > (decoded.exp || 0))
}

export const getAccessToken = () => {
  return localStorage.getItem('access_token')
}
