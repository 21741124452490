import React, { Fragment, ComponentType }  from 'react'
import { Menu as SemanticMenu } from 'semantic-ui-react'
import { SolNotification } from 'SolComponents'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { scrollToTop } from 'shared/core/utils'
import styles from './SolMenu.module.scss'
import { closeSidebar } from 'shared/store/slices/core'
import { Dispatch } from 'shared/types/redux'
import { connect } from 'react-redux'

interface Props {
  path: string
  icon?: ComponentType<{ className?: string }>
  label: string
  notification?: number
  child?: true
  grandchild?: true
  hidden?: boolean
  closeSidebar: () => void
}

const MenuLink = (props: Props) => {
  const location = useLocation()

  function handleClick() {
    props.closeSidebar?.()
    scrollToTop()
  }

  return (
    props.hidden ? <Fragment /> : (
      <SemanticMenu.Item
        active={location.pathname === props.path}
        className={classnames({
          [styles.item]: !props.child && !props.grandchild,
          [styles.childItem]: props.child,
          [styles.grandchildItem]: props.grandchild,
        })}
        onClick={handleClick}
        as={Link}
        to={props.path}
      >
        {!!props.icon && <props.icon className={styles.icon} />}
        {props.label}
        {!!props.notification && (
          <SolNotification type="menu" notifications={props.notification} />
        )}
      </SemanticMenu.Item>
    )
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeSidebar: () => dispatch(closeSidebar()),
})
  
export default connect(null, mapDispatchToProps)(MenuLink)
