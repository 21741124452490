import React from 'react'
import { Icon } from 'semantic-ui-react'
import {
  SolCheckbox,
  SolTooltip,
} from 'SolComponents'

export interface Props {
  enableGatewayCheck: boolean
  mismatched: boolean
  toggleGatewayCheck: () => void
}

const questionIcon = <Icon name="question circle" />

export function GatewayCheckSettings({
  enableGatewayCheck,
  toggleGatewayCheck,
  mismatched,
}: Props) {
  return (
    <SolCheckbox
      label="Enable Gateway Check"
      checked={enableGatewayCheck}
      onClick={toggleGatewayCheck}
      bold={false}
      mismatched={mismatched}
      notification={
        <SolTooltip
          isInline
          trigger={questionIcon}
          text="Support for Gateway Check was removed in Solstice 5.3.2. This setting will be ignored on 5.3.2+ Pods."
          position="top left"
        />
      }
    />
  )
}
