import { TaskType, useDeletePodMutation, useDeletePodsMutation } from 'graphql/__generated__/types'
import React, { useState } from 'react'
import { SolConfirmationModal, SolModal, SolTooltip } from 'SolComponents'
import { useCreateScheduledTask } from 'shared/hooks/useCreateScheduledTask'
import { useAlerts } from 'shared/hooks/useAlerts'
import { useFetchSelectedDisplayIds } from './useFetchSelectedDisplayIds'
import styles from './SolPodActions.module.scss'
import { Button, Dropdown } from 'semantic-ui-react'
import { useReleases } from 'shared/hooks/versions'
import compareVersions from 'compare-versions'
import { useHistory } from 'react-router-dom'

export const getSolPodActionOptions
= ({
  taskTypes,
  podVersion,
  count,
  disabled,
}: {
  taskTypes: Array<TaskType>
  podVersion?: string
  count?: number
  disabled?: boolean
}) => {
  return getActions({ podVersion: podVersion, count, disabled })
    .filter(a => taskTypes.includes(a.taskType))
    .map(a => ({ value: a.taskType, text: a.optionText, disabled: a.disabled }))
}

interface Props {
  taskType: TaskType
  podName?: string
  podId?: string
  podVersion?: string
  serialId?: string
  podCount?: number
  onClose: () => void
  onAccept: () => void
}

export const SolPodActionConfirmationModal = (props: Props) => {
  return getActions({
    podName: props.podName,
    count: props.podCount ?? 1,
    podId: props.podId,
    podVersion: props.podVersion,
    onClose: props.onClose,
    onAccept: props.onAccept,
    serialId: props.serialId,
  }).find(a => a.taskType === props.taskType)!.getModal()
}

type SolPodAction = {
  taskType: TaskType
  optionText: string | JSX.Element
  disabled: boolean
  getModal: () => JSX.Element
}

const useCreateSolPodAction = (
  taskType: TaskType,
  onAccept: () => void,
  setLoading: React.Dispatch<boolean>,
  podId?: string,
) => {
  const { showSuccess, showError } = useAlerts()
  const fetchSelectedDisplayIds = useFetchSelectedDisplayIds()
  const createScheduledTask = useCreateScheduledTask()
  return async () => {
    try {
      setLoading(true)
      await createScheduledTask({
        type: taskType!,
        durationSecs: taskType === TaskType.Reboot ? 300 : 60,
        displayIds: podId ? [podId] : await fetchSelectedDisplayIds(),
      })
      showSuccess(`Action sent`)
    } catch (err) {
      showError(`Failed to send action to pods. Please make sure Pods are online.`)
    } finally {
      setLoading(false)
      onAccept()
    }
  }
}

const getConfirmationModal = (
  { content, header }: { content: string; header: string },
  taskType: TaskType,
  onClose: () => void,
  onAccept: () => void,
  podId?: string,
) => {
  const [loading, setLoading] = useState(false)
  const createSolPodAction = useCreateSolPodAction(taskType, onAccept, setLoading, podId)
  return (<SolConfirmationModal
    subHeaderText={content}
    modalText={header}
    onAcceptingAction={createSolPodAction}
    acceptingDisabled={loading}
    cancelDisabled={loading}
    onCloseModal={onClose}
  />)
}

const getActions = ({
  podVersion,
  podId,
  podName,
  count =  1,
  onClose,
  onAccept,
  disabled = false,
}: {
  podVersion?: string
  podId?: string
  podName?: string
  count?: number
  onClose?: () => void
  onAccept?: () => void
  disabled?: boolean
  serialId?: string
}): Array<SolPodAction> => [
  {
    taskType: TaskType.Reboot,
    optionText: `Reboot Pod${count === 1 ? ''  : 's'}`,
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Reboot ${podName}?`,
          content: `Immediately restarts ${podName} and disconnects any users.`,
        } : {
          header: `Reboot ${count} selected Pods?`,
          content: 'The Pods will immediately restart and any connected users will be disconnected.',
        },
        TaskType.Reboot,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.BootAllUsers,
    optionText: 'Disconnect all users',
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Disconnect all users from ${podName}?`,
          content: '',
        } : {
          header: `Disconnect all users from ${count} selected Pods?`,
          content: '',
        },
        TaskType.BootAllUsers,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.ClearScreen,
    optionText: 'Clear screen',
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Clear ${podName}'s screen?`,
          content: `Removes all content from ${podName}'s screen while keeping users connected.`,
        } : {
          header: `Clear the screen on ${count} selected Pods?`,
          content: 'Remove all content from the Pod screens.',
        },
        TaskType.ClearScreen,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.Wake,
    optionText: 'Power Management - Wake',
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Wake ${podName} from a sleep state?`,
          content: '',
        } : {
          header: `Wake ${count} selected Pods?`,
          content: `Wake the Pods from a power management sleep state.`,
        },
        TaskType.Wake,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.Suspend,
    optionText: 'Power Management - Suspend',
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Suspend ${podName} into a sleep state?`,
          content: 'Any users will be disconnected.',
        } : {
          header: `Suspend ${count} selected Pods?`,
          content: 'Suspend the Pods\' video signal allowing a power management sleep state.',
        },
        TaskType.Suspend,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.ForgetWifiNetworks,
    optionText: 'Forget WiFi networks',
    disabled,
    getModal: () => {
      return getConfirmationModal(
        (count === 1) ? {
          header: `Forget all saved WiFi networks for ${podName}?`,
          content: 'If currently connected to a WiFi network, users will be disconnected.',
        } : {
          header: `Forget WiFi networks on ${count} selected Pods?`,
          content: 'Force the Pods to forget all saved WiFi SSIDs and associated credentials.',
        },
        TaskType.ForgetWifiNetworks,
        onClose!,
        onAccept!,
        podId,
      )
    },
  },
  {
    taskType: TaskType.DisplayUpdate,
    optionText: 'Downgrade Pod',
    disabled: disabled || compareVersions('5.4.30427', podVersion ?? '5.4') >= 0,
    getModal: () => {
      // Gen3P2 pod hardware is indicated by its serialId starting 'MPOD8001',
      // if we don't have a serialId, we assume it's Gen3P2 just in case
      // BUT
      // serial-based hardware distinctions driving the baseline pod version are not currently used
      // because Solstice Version -based licensing restrictions supersede hardware entirely, for now
      // const isPodHardwareGen3P2 = serialId?.startsWith('MPOD8001') || !serialId

      const baselinePodVersion = '5.5.36320'

      const allReleases = useReleases()

      const releases = allReleases?.filter(
        release => {
          // compareVersion outputs -1 for <, 0 for =, 1 for >
          // a candiddate release (to DOWNgrade a pod to) is less then pod version,
          // but not less than baseline if we don't know the pod version
          return ( compareVersions(podVersion ?? baselinePodVersion, release.key) === 1 )
          // AND release is baseline or higher
          && ( compareVersions(baselinePodVersion, release.key) < 1 )
        },
      )
      const createScheduledTask = useCreateScheduledTask()
      const history = useHistory()
      const displayIds = [podId!]
      const [selectedVersion, setSelectedVersion] = useState<string>('')
      const [loading, setLoading] = useState(false)
      return (<SolModal
        isOpen
        onCloseModal={onClose!}
      >
        <div className={styles.downgradeModal}>
          <div>
            <strong className={styles.downgradeModalHeader}>Downgrade {podName}?</strong>
            <p className={styles.smallText}>
              Select a version from the list below. Your settings will not be affected.
            </p>
          </div>
          <Dropdown
            placeholder="Choose a version"
            fluid
            search
            selection
            options={releases}
            upward={false}
            value={selectedVersion}
            className={styles.releasesDropDown}
            onChange={(e, { value }) => {
              if (value) {
                setSelectedVersion(value.toString())
              }
            }}
          />
          <div className={styles.downgradeButtonDiv}>
            <Button
              basic
              className={styles.downgradeCancelButton}
              onClick={onClose!}>
              Cancel
            </Button>
            <Button
              disabled={!selectedVersion}
              color="blue"
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await createScheduledTask({
                  type: TaskType.DisplayUpdate,
                  displayIds,
                  data: {
                    versionToUpdate: selectedVersion,
                  },
                })
                history.push('/manage/updates?tab=tasks')
                onAccept!()
              }}>
              Confirm
            </Button>
          </div>
        </div>
      </SolModal>)
    },
  },
  {
    taskType: TaskType.Delete,
    optionText: (<SolTooltip
      hoverable
      disabled={count < 6}
      position="bottom center"
      trigger={<span className={styles.deletePodText}>{`Delete Pod${count === 1 ? ''  : 's'}`}</span>}
      text="You can only delete up to 5 Pods at a time."
      isInline
    />),
    disabled: count >  5,
    getModal: () => {
      const history = useHistory()
      const [handleDeletePod] = useDeletePodMutation()
      const [handleDeletePods] = useDeletePodsMutation()
      const { showSuccess, showError } = useAlerts()
      const fetchSelectedDisplayIds = useFetchSelectedDisplayIds()

      const deletePod = async () => {
        if (podId) {
          await  handleDeletePod({
            variables: {
              options: { podId },
            },
          })
        } else {
          const selectedIds = await fetchSelectedDisplayIds()
          await handleDeletePods({
            variables: {
              options: {
                podIds: selectedIds,
              },
            },
          })
        }
      }
      return (<SolConfirmationModal
        subHeaderText="All Pod data will be permanently deleted. This cannot be undone."
        modalText={`Are you sure you want to delete ${count === 1 ? podName : count + ' selected Pods'}?`}
        acceptingText="Delete"
        onAcceptingAction={async () => {
          try {
            await deletePod()
            showSuccess(`${count === 1 ? podName : count + ' selected Pods'} deleted`)
            if (history.location.pathname !== '/manage/pods') {
              history.push('/manage/pods')
            }
            onAccept!()
          } catch {
            showError('Unable to delete Pod. Try refreshing the page or try again later.')
          }
        }}
        onCloseModal={onClose!}
      />)
    },
  },
]
