import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { parseErrorMessage } from 'shared/errors'
import { Dropdown, Icon } from 'semantic-ui-react'
import { SolEllipsesDropdown, SolConfirmationModal, SolTooltip } from 'SolComponents'
import { Role, RoleDisplayText } from 'shared/types/Role'
import styles from './AccountList.module.scss'
import { User } from '../types'
import { User as SharedUser } from 'shared/types/User'
import { getEmail } from 'shared/core/authenticate'
import { InviteUserInput } from 'graphql/__generated__/types'

export interface DispatchProps {
  showError: (message: string) => void
}

export interface OwnProps {
  row: User
  updateUser: (user: User) => void
  deleteUser: (user: User) => void
  sendInviteForUser: (user: InviteUserInput) => void
  updateSsoStatus: (user: User) => void
  signedInUser: SharedUser
  showSsoItem: boolean
  userHasPassword: boolean
}

export type Props = OwnProps & DispatchProps

export interface State {
  loading: boolean
  modalOpen: boolean
  menuOpen: boolean
  roleMenuOpen: boolean
}

interface RoleMenuProps {
  userRole: Role
  signedInRole: Role
  userEmail: string
  onClick: (email: string, newRole: Role, oldRole: Role) => void
}

interface SsoItemProps {
  onClick: () => void
  ssoOnly?: boolean | null
  userHasPassword?: boolean
}

export function RoleMenu({ userRole, signedInRole, userEmail, onClick }: RoleMenuProps) {
  const { roomDiscoveryRole } = useFlags()
  const opts = roomDiscoveryRole
    ? Object.values(Role)
    : Object.values(Role).filter(v => (v.valueOf() !== 500 && v.valueOf() !== 'Room Discovery Guest'))
  const roleOptions = opts.map((roleId: Role) => (
    <React.Fragment key={roleId}>
      {signedInRole <= roleId && (
        <div className={`${styles.roleWrapper} wrapper`} onClick={() => onClick(userEmail, roleId, userRole)}>
          <div>
            {RoleDisplayText[roleId]}
            {userRole === roleId && (
              <span className={styles.roleCheckmark}>
                <Icon name="checkmark" color="green" />
              </span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  ))
  return <div className={styles.showOptions}>{roleOptions}</div>
}

function SsoItem({ onClick, ssoOnly, userHasPassword }: SsoItemProps) {
  return (
    <SolTooltip
      isInline
      text={[
        'Users added as SSO Only cannot log in with an email and password.',
        'To enable non-SSO logins, remove the user and re-invite without SSO Only checked.',
      ]}
      position="left center"
      disabled={userHasPassword}
      className={styles.ssoOption}
      trigger={
        <div
          onClick={() => (userHasPassword ? onClick() : null)}
          className={`${styles.roleOptions} ${!userHasPassword && styles.disabled}`}
        >
          <Icon
            className={`${styles.roleSpace} ${!userHasPassword && styles.disabled} `}
            name="lock"
          />
          SSO Only
          {ssoOnly
            && <Icon className={styles.dropdownCheck} name="checkmark" color="green" />
          }
        </div>
      }
    />
  )
}

export const UserManager = ({
  row,
  signedInUser,
  sendInviteForUser,
  showError,
  deleteUser,
  updateUser,
  updateSsoStatus,
  userHasPassword,
  showSsoItem,
}: Props) => {

  const [modalOpen, setmodalOpen] = useState(false)
  const [loading, setloading] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [roleMenuOpen, setRoleMenuOpen] = useState(false)
  const myEmail = getEmail()

  const email: string = row.email ?? ''
  const status: string = row.status ?? ''
  const rowRole: Role = row.role ?? Role.Viewer
  // 'effective' in the sense that the logged in user may be missing 'Admin'
  // permissions if they used the Support Login feature
  const effectiveLoggedInRole = signedInUser.permissions.includes('update:displays')
    ? signedInUser.role : Role.Viewer
  const isSelf: boolean = email.toLowerCase() === myEmail.toLowerCase()
  const truncatedEmail = email.length > 25 ? `${email.substr(0, 25)}...` : email


  const sendInvite = async () => {
    setloading(true)
    try {
      return await sendInviteForUser({ email, role: rowRole ?? Role.Viewer, resend: true })
    } catch (err) {
      showError(parseErrorMessage(err, 'Unable to invite user for that email address'))
    }
  }

  const closeModal = () => {
    return setmodalOpen(false)
  }

  const updateRole = async (rowEmail: string, newRole: Role) => {
    setMenuOpen(false)
    setRoleMenuOpen(false)
    try {
      await updateUser({ email: rowEmail, role: newRole ?? Role.Viewer })
    } catch (e) {
      showError(parseErrorMessage(e, `Error updating ${rowEmail}'s user role`))
    }
    return
  }


  const deleteTheUser = async () => {
    if (email.toLowerCase() === myEmail.toLowerCase()) {
      return
    }
    try {
      await deleteUser({ email })
    } catch (e) {
      showError(parseErrorMessage(e, `Error deleting ${email}`))
    }
    setmodalOpen(false)
  }

  const updateTheSsoStatus =  async (user: User) => {
    try {
      await updateSsoStatus({ email, sso_only: !user.sso_only })
    } catch (e) {
      showError(parseErrorMessage(e, `Error updating sso_only for ${email}`))
    }
    setmodalOpen(false)
  }

  const handleOpen = () => setmodalOpen(true)

  if (row.status === 'deleted') {
    return null
  }

  if (isSelf && !showSsoItem) {
    return null
  }

  const ssoSelfDropdown = () => {
    return (
      <SolEllipsesDropdown
        direction="left"
        onOpen={() => setMenuOpen(true)}
        onBlur={() => setMenuOpen(false)}
        open={menuOpen}
      >
        <Dropdown.Menu className="direction">
          <SsoItem
            onClick={() => updateTheSsoStatus(row)}
            ssoOnly={row.sso_only}
            userHasPassword={userHasPassword}
          />
        </Dropdown.Menu>
      </SolEllipsesDropdown>
    )
  }
  
  const renderDropdown = () => {
    if (rowRole >= effectiveLoggedInRole) {
      let label, modalText
      if (status === 'invite sent') {
        label = 'Revoke Invite'
        modalText  = 'Are you sure you want to revoke the pending invite from '
      } else {
        label = 'Delete User'
        modalText = 'Are you sure you want to delete '
      }
      return (
        <SolEllipsesDropdown
          direction="left"
          onOpen={() => setMenuOpen(true)}
          onBlur={() => setMenuOpen(false)}
          open={menuOpen}
        >
          <Dropdown.Menu>
            {effectiveLoggedInRole < Role.Viewer // Do not show role selection for this user in row since they are higher access
              && <div
                onMouseOver={() => setRoleMenuOpen(true)}
                onMouseLeave={() => setRoleMenuOpen(false)}
                onClick={e => e.stopPropagation()}
                role="option"
                className={styles.roleOptions}
              >
                <Icon className={styles.roleSpace} name="user circle" />
                Role
                <Icon className={styles.dropdownCaret} name="caret right" />
                {roleMenuOpen && (
                  <RoleMenu
                    userRole={rowRole}
                    signedInRole={effectiveLoggedInRole}
                    userEmail={email}
                    onClick={updateRole}
                  />
                )}
              </div>
            }
            {showSsoItem
                && <SsoItem
                  onClick={() => updateTheSsoStatus(row)}
                  ssoOnly={row.sso_only}
                  userHasPassword={userHasPassword}
                />
            }
            <Dropdown.Item icon="trash alternate outline" text={label} onClick={handleOpen} />
            {status === 'invite sent'
              && <Dropdown.Item
                icon="mail"
                text="Resend Invite"
                loading={loading.toString()}
                onClick={() => sendInvite()}
              />
            }
            <SolConfirmationModal
              modalText={
                <p className={styles.header}>
                  {modalText} <strong>{truncatedEmail}</strong>?
                </p>
              }
              isOpen={modalOpen}
              onCloseModal={() => closeModal()}
              onAcceptingAction={() => {
                deleteTheUser()
              }}
              acceptingText="Delete"
              cancelText="Cancel"
            />
          </Dropdown.Menu>
        </SolEllipsesDropdown>
      )
    }
    return
  }

  return isSelf ? ssoSelfDropdown() : <span>{renderDropdown()}</span>
}
