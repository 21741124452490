import React from 'react'
import InvitePanel from './InvitePanel/InvitePanel'
import AccountList from './List'
import { User } from 'shared/types/User'
import { useAccountList } from './List/useAccountList'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import styles from './UsersPage.module.scss'
import { Button, Icon, Segment } from 'semantic-ui-react'
import { SolTooltipWithLink } from 'SolComponents'
import { SolQuestionCircle } from 'SolComponents/Icons'
import { useSelector } from 'react-redux'
import { StoreState } from './types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { add, format } from 'date-fns'

type Props = {
  ssoEnabled: boolean
  updateSupportAccess: (any: any) => void
  supportAccess?: string
}

const UsersPage = ({ ssoEnabled = false, updateSupportAccess, supportAccess }: Props) => {

  const {
    updateUser,
    updateSsoStatus,
    sendInviteForUser,
    deleteUser,
    refetchUsers,
    addSsoUser,
    totalUsers,
    users,
    loadingUsers,
    sendingInvite,
  } = useAccountList()

  const { ssoAdminPage, supportTeamAccess } = useFlags()

  const me: User = useSelector((state: StoreState) => state.user)

  const updateAccess = (start?: boolean) => {
    let timeStmp = null
    if (start) {
      timeStmp = add(new Date(), {
        days: 7,
      })
    }
    updateSupportAccess(timeStmp)
  }

  return (
    <ContentWrapper>
      <div className={styles.header}>
        <span className={styles.grey}>Organization</span>
        <Icon name="angle right" color="grey" />
        <span>Users</span>
        <span>
          <SolTooltipWithLink
            className={styles.icon}
            text="Need help? Learn how to manage your users and roles."
            buttonText="View Documentation"
            isInline
            trigger={<SolQuestionCircle size="small" />}
            isExternalLink
            linkUrl="https://documentation.mersive.com/en/users-and-roles.html"
          />
        </span>
      </div>
      <InvitePanel
        sendInviteForUser={sendInviteForUser}
        me={me}
        refetchUsers={refetchUsers}
        addSsoUser={addSsoUser}
        isFetching={sendingInvite}
        ssoEnabled={ssoAdminPage && ssoEnabled}
      />
      <AccountList
        me={me}
        sendInviteForUser={sendInviteForUser}
        updateUser={updateUser}
        updateSsoStatus={updateSsoStatus}
        deleteUser={deleteUser}
        totalUsers={totalUsers}
        users={users}
        loadingUsers={loadingUsers}
        ssoEnabled={ssoAdminPage && ssoEnabled}
      />
      {
        supportTeamAccess
        && <Segment>
          {
            (Date.now() < Number(supportAccess))
              ? <span>
                <Button
                  className={styles.revokeAccess} color="blue"
                  onClick={() => updateAccess()}
                  size="small"
                >
                  Revoke Mersive Admin Access
                </Button>
                <span className={styles.revokeAccess}>
                  Mersive admin access will automatically expire on {
                    format(new Date(Number(supportAccess)), 'MMMM do, p')
                  }.
                </span>
              </span>
              : <Button color="blue" onClick={() => updateAccess(true)}>
                Grant Mersive temporary admin access for 7 days
              </Button>
          }
        </Segment>
      }
    </ContentWrapper>
  )
}

export default UsersPage
