import { Pod } from './../CategoryTypes'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { handleSelectItems } from 'components/DataTableSlim/Utils'

export const useSelectDisplays = () => {
  const {
    selectedItems: currentSelectedItems,
    setSelectedItems,
  } = useDataTable<Pod>(DataTableInstance.Categories)

  const selectDisplays = (displays: Pod[]) => {
    const newSelectedItems = handleSelectItems<Pod>(
      currentSelectedItems,
      item => item.displayId ?? '',
      displays,
    )
    setSelectedItems(newSelectedItems)
  }

  const unselectDisplays = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  return {
    selectDisplays,
    unselectDisplays,
  }
}
