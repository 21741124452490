import FlowButtons, {
  Props as FlowProps,
  style,
} from './FlowButtons'

export {
  style,
}

export type Props = FlowProps

export default FlowButtons
