import { RealtimeDisplaysQuery } from 'graphql/__generated__/types'
import React from 'react'
import SolMap from 'SolComponents/SolMap/SolMap'
import FilterByVisiblePodsCheckbox from 'pages/Location/Map/FilterByVisiblePodsCheckbox'
import PodsOnMap from './PodsOnMap'
import styles from './Map.module.scss'
import { StatusEnum } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'
import { SolRealtimeStatus, SolCard } from 'SolComponents'
import { useCustomFilters, CustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'

interface Props {
  data?: RealtimeDisplaysQuery
  boundingBoxFilter: number[][] | undefined
  setBoundingBoxFilter: (newFilter: number[][] | undefined) => void

  mapBounds: number[][] | undefined
  setMapBounds: (newFilter: number[][] | undefined) => void

  filterCheckBoxValue: boolean
  setFilterCheckBoxValue: (el: boolean) => void
}

const StatusItem = ({
  value,
  displayName,
  keyValue,
  setCustomFilter,
  customFilters,
}: {
  value: StatusEnum
  displayName: string
  keyValue: string
  setCustomFilter: Function
  customFilters: CustomFilters<StatusEnum>
}) => {
  return (
    <li
      onClick={() =>
        setCustomFilter('status', {
          values: {
            ...customFilters.status?.values,
            [keyValue]: {
              displayName,
              value,
            },
          },
        })
      }
    >
      <SolRealtimeStatus status={value} isInline />
      <label>{displayName}</label>
    </li>
  )
}

const Map = ({
  data,
  setBoundingBoxFilter,
  setMapBounds,
  boundingBoxFilter,
  filterCheckBoxValue,
  setFilterCheckBoxValue,
}: Props) => {
  const { customFilters, setCustomFilter } = useCustomFilters<StatusEnum>(DataTableInstance.Realtime)

  return (
    <div className={styles.mapWrapper}>
      <SolCard className={styles.mapCard}>
        <div className={filterCheckBoxValue ? styles.borderActive : styles.border}>
          <SolMap className={styles.map} maxZoom={20} minZoom={2}>
            <FilterByVisiblePodsCheckbox
              setBoundingBoxFilter={setBoundingBoxFilter}
              boundingBoxFilter={boundingBoxFilter}
              filterCheckBoxValue={filterCheckBoxValue}
              setFilterCheckBoxValue={setFilterCheckBoxValue}
              setMapBounds={setMapBounds}
            />
            <div className={styles.statusesList}>
              <ul>
                <StatusItem
                  displayName="Ad Hoc Meeting"
                  value={StatusEnum.AD_HOC}
                  keyValue="adHoc"
                  customFilters={customFilters}
                  setCustomFilter={setCustomFilter}
                />
                <StatusItem
                  displayName="In use"
                  value={StatusEnum.IN_USE}
                  keyValue="inUse"
                  customFilters={customFilters}
                  setCustomFilter={setCustomFilter}
                />
                <StatusItem
                  displayName="Single Occupant"
                  value={StatusEnum.SINGLE_OCCUPANT}
                  keyValue="singleOccupant"
                  customFilters={customFilters}
                  setCustomFilter={setCustomFilter}
                />
                <StatusItem
                  displayName="Booked, empty"
                  value={StatusEnum.BOOKED_EMPTY}
                  keyValue="bookedEmpty"
                  customFilters={customFilters}
                  setCustomFilter={setCustomFilter}
                />
                <StatusItem
                  displayName="Subscription expired"
                  value={StatusEnum.SUBSCRIPTION_EXPIRED}
                  keyValue="subscriptionExpired"
                  customFilters={customFilters}
                  setCustomFilter={setCustomFilter}
                />
              </ul>
            </div>
            <PodsOnMap data={data} showCluster zoomToBounds />
          </SolMap>
        </div>
      </SolCard>
    </div>
  )
}

export default Map
