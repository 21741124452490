import React from 'react'
import GoogleAnalytics from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { AnyObject } from 'shared/types/common'

import config, { isProduction } from './config'

export interface GoogleAnalyticsException {
  hitType: 'exception'
  exFatal?: boolean
  exDescription?: string
}

export interface GoogleAnalyticsTiming {
  hitType: 'timing'
  timingVar: string
  timingValue: any
  timingCategory: string
  timingLabel?: string
}

export interface GoogleAnalyticsEvent {
  hitType: 'event'
  eventCategory: string
  eventAction: string
  eventLabel?: string
  eventValue?: any
  nonInteraction?: boolean
  transport?: string
}

/**
 * Initializes GoogleAnalytics (if in production mode)
 *
 * @function
 * @category GoogleAnalytics
 * @name startGoogleAnalytics
 */
export function startGoogleAnalytics() {
  if (isProduction()) {
    // https://github.com/react-ga/react-ga/issues/122#issuecomment-299692833
    GoogleAnalytics.initialize(config().googleAnalytics)
  }
}

/**
 * Sends custom event data on-demand to Google Analytics
 *
 * @function
 * @name sendToGoogleAnalytics
 * @param {GoogleAnalyticsEvent} eventData Google analytics event data to send
 */
export function sendToGoogleAnalytics(eventData: GoogleAnalyticsEvent) {
  if (isProduction()) {
    GoogleAnalytics.ga('send', eventData)
  }
}

/**
 * A higher-order component which enhances any React component by adding Google Analytics tracking to it
 *
 * @function
 * @name withTracker
 * @param {JSX.Element} WrappedComponent The React component to be enhanced
 * @param {AnyObject} [options] Additional props to pass to Google Analytics
 * @returns {React.Component} The original React component, but with Google Analytics tracking enhancement applied
 */
export function withTracker(
  WrappedComponent: (props: RouteComponentProps) => JSX.Element | null,
  options: AnyObject = {},
) {
  const trackPage = (page: string) => {
    if (isProduction()) {
      GoogleAnalytics.set({
        page,
        ...options,
      })
      GoogleAnalytics.pageview(page)
    }
  }

  const HOC = class extends React.Component<RouteComponentProps, {}> {
    componentDidMount() {
      const page = this.props.location.pathname
      trackPage(page)
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}
