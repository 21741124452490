import React from 'react'
import StepRewind from '../StepRewind'
import { Grid, Header } from 'semantic-ui-react'

export const style = {
  page: {
    padding: '10px',
    marginBottom: '-30px',
  } as React.CSSProperties,
  subHeader: {
    color: 'black',
    fontSize: '18px',
    paddingTop: '8px',
  } as React.CSSProperties,
  subTitle: {
    color: 'black',
    fontSize: '18px',
  } as React.CSSProperties,
}

export interface Props {
  rewind: () => void
  title: string
  subHeader?: string
  subTitle?: string
  showBackButton?: boolean
}

export const StepHeader = (props: Props) => {
  const { rewind, title, subTitle, subHeader, showBackButton = true } = props
  return (
    <Grid columns={2} style={style.page}>
      <Grid.Row>
        {showBackButton && (
          <Grid.Column width={2}>
            <StepRewind rewind={rewind} />
          </Grid.Column>
        )}
        <Grid.Column width={12}>
          <Header as="h2">
            {title}
            <Header.Subheader style={style.subHeader}>
              {subHeader}
            </Header.Subheader>
          </Header>
          <p style={style.subTitle}>{subTitle}</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default StepHeader
