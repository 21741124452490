import {
  UserDevicesQuery,
  UserDevicesQueryVariables,
  UserDevicesDocument,
  ClientPlatform,
} from 'graphql/__generated__/types'
import { useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import { toPercentage } from 'shared/core/utils'
import { OptionDate } from 'shared/types/OptionDate'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'


const getTotal = (data: ClientPlatform[]): number =>
  data.reduce((acc, record) => acc + (record?.total || 0), 0)

const getTotalsByDevice = (data: any[]): Record<string, PieSector> => {
  const total = getTotal(data)
  const devices = {
    macos: { name: 'macOS', count: 0, percentage: 0 },
    android: { name: 'Android', count: 0, percentage: 0 },
    windows: { name: 'Windows', count: 0, percentage: 0 },
    ios: { name: 'iOS', count: 0, percentage: 0 },
    apple_airplay: { name: 'Clientless', count: 0, percentage: 0 },
  }

  return data.reduce((acc, record) => {
    const platform = record.name?.toLowerCase() ?? ''

    if (platform === 'apple' || platform === 'ios') {
      acc.ios.count = (record.total || 0) + acc.ios.count
      acc.ios.percentage = toPercentage(acc.ios.count, total)
    } else if (acc[platform]) {
      acc[platform].count = record.total
      acc[platform].percentage = toPercentage(acc[platform].count, total)
    }

    return acc
  }, devices)
}

export const useDevices = ({ fromDate, toDate }: OptionDate) => {
  const { data: dataDevices, loading: isDeviceLoading } = useQuery<UserDevicesQuery, UserDevicesQueryVariables>(
    UserDevicesDocument,
    {
      variables: {
        options: {
          fromDate: fromDate,
          toDate: toDate,
        },
      },
    },
  )
  const totalsByDevice = getTotalsByDevice(dataDevices?.userDevices ?? [])

  return {
    devices: sortBy(totalsByDevice, ['count']),
    isLoading: isDeviceLoading,
  }
}
