import React from 'react'
import { SolCard } from 'SolComponents'
import { UserCollaborationQuery } from 'graphql/__generated__/hasura-types'

import styles from './index.module.scss'
import { Header } from 'semantic-ui-react'
import { Pie, PieChart, Tooltip } from 'recharts'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import { Cell } from 'recharts'
import { toPercentage } from 'shared/core/utils'

type Props = {
  userCollaboration?: UserCollaborationQuery
  loading?: boolean
}

const COLORS = {
  collaborators: '#163296',
  presenters: '#1EB3C9',
}

const UserCollaboration = (props: Props) => {
  const { userCollaboration, loading } = props
  const data = userCollaboration?._user_collaboration?.map(group => ({
    name: group.group,
    value: group.total,
  }))

  const collaboratorsTotal
    = data?.find(group => group.name === 'collaborators')?.value || 0
  const presentersTotal
    = data?.find(group => group.name === 'presenters')?.value || 0

  const totalParticipants = collaboratorsTotal + presentersTotal

  const collaboratorsPercentage = toPercentage(
    collaboratorsTotal,
    totalParticipants,
  )
  const presentersPercentage = toPercentage(presentersTotal, totalParticipants)

  return (
    <SolCard
      className={styles.userCollaboration}
      header={
        <div>
          <Header
            className={styles.header}
            as="h3"
            textAlign="left"
            content="User Collaboration"
          />
        </div>
      }
    >
      <LoadingWrapper
        isEmpty={totalParticipants === 0}
        isLoading={loading || false}
      >
        <div className={styles.chart}>
          <PieChart width={120} height={120}>
            <Pie
              data={data || []}
              dataKey="value"
              cx={54}
              cy={54}
              fill="#8884d8"
              paddingAngle={0}
            >
              {data?.map((entry, i) => (
                <Cell
                  key={`${entry.name}-${i}`}
                  fill={COLORS[entry.name || '']}
                />
              ))}
            </Pie>
            <Tooltip separator=": "/>
          </PieChart>
        </div>
        <div className={styles.collaborators}>
          <div className={styles.number}>{collaboratorsPercentage}%</div>
          <div className={styles.title}>Collaborators</div>
          <div className={styles.text}>
            These users collaborated with each other over shared content
          </div>
        </div>
        <div className={styles.presenters}>
          <div className={styles.number}>{presentersPercentage}%</div>
          <div className={styles.title}>Presenters</div>
          <div className={styles.text}>
            These users were the sole presenter during meetings
          </div>
        </div>
      </LoadingWrapper>
    </SolCard>
  )
}

export default UserCollaboration
