import React, { useEffect, useState } from 'react'
import {
  useLocationsPageCardsQuery,
  ViewDisplaysBoolExp,
  useLocationsPageQuery,
  LocationsPageCardsQuery,
} from 'graphql/__generated__/hasura-types'
import omit from 'lodash/omit'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { cool } from 'assets/palettes'
import OverviewCard from './OverviewCard'
import { AppliedCategoryFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { addFilter } from 'components/DataTableSlim/Utils/filterMenu'
import { SolDonutCard, SolThreeCardCarousel } from 'SolComponents'
import styles from './Cards.module.scss'
import { toPercentage } from 'shared/core/utils'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null
  setCategoryFilters: (filters: AppliedCategoryFilters) => void
  currentFilters: AppliedCategoryFilters // Pass down setter for the setFilters
}

const LocationCards = (props: Props) => {
  const [cities, setCities] = useState<PieSector[]>([])
  const [countries, setCountries] = useState<PieSector[]>([])
  const [lastAddedFilter, setNewFilter] = useState<string[]>([])

  const { data: cityData, loading: cityLoading } = useLocationsPageCardsQuery({
    variables: {
      where: { display: props.searchParamsWhere },
      category: 'city',
    },
  })

  const { data: countryData, loading: countryLoading } = useLocationsPageCardsQuery({
    variables: {
      where: { display: props.searchParamsWhere },
      category: 'country',
    },
  })

  const { data: totalDisplaysData } = useLocationsPageQuery({
    variables: {
      where: props.searchParamsWhere,
    },
  })

  useEffect(() => {
    const totalDisplays = totalDisplaysData?.filteredDisplayCount?.aggregate?.count ?? 0

    if (cityData) {
      cityData?._categories
        .filter(ele => ele.id === 'city')
        .forEach(element => {
          setCities(buildSlices(element, totalDisplays))
        })
    }
  }, [cityData, totalDisplaysData, props.currentFilters])

  useEffect(() => {
    const totalDisplays = totalDisplaysData?.filteredDisplayCount?.aggregate?.count ?? 0

    if (countryData) {
      countryData?._categories
        .filter(ele => ele.id === 'country')
        .forEach(element => {
          setCountries(buildSlices(element, totalDisplays))
        })
    }
  }, [countryData, totalDisplaysData, props.currentFilters])

  useEffect(() => {
    if (lastAddedFilter.length) {
      const [optionId, categoryId] = lastAddedFilter
      props.setCategoryFilters(
        addFilter(
          categoryId === 'building' && optionId === 'unassigned'
            ? omit(props.currentFilters, ['city', 'country'])
            : props.currentFilters,
          categoryId,
          optionId,
        ),
      )
    }
  }, [lastAddedFilter])

  const buildSlices = (element: LocationsPageCardsQuery['_categories'][number], totalCount: number): PieSector[] => {
    const palette = cool(element.options?.length)

    return element.options?.map((item, index) => {
      const count = item.assignedDisplays_aggregate.aggregate?.count ?? 0
      return {
        name: item.display_name ?? '',
        count: count,
        percentage: toPercentage(count, totalCount, 1),
        fill: palette[index % palette.length],
        onClick: () => {
          setNewFilter([item.id ?? '', element.id ?? ''])
        },
      }
    })
  }

  return (
    <div className={styles.cards}>
      <SolThreeCardCarousel>
        <OverviewCard
          setFilterbyUnknownBuilding={() => {
            setNewFilter(['unassigned', 'building'])
          }}
          searchParamsWhere={props.searchParamsWhere}
          currentFilters={props.currentFilters}
        />
        <SolDonutCard data={cities} isLoading={cityLoading} title="Cities" testId="cities-card" paging />
        <SolDonutCard data={countries} isLoading={countryLoading} title="Countries" testId="countries-card" paging />
      </SolThreeCardCarousel>
    </div>
  )
}

export default LocationCards
