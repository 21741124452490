import React, { useState } from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { Categorizable, DataTableInstance, Column } from 'components/DataTableSlim/DataTableSlim'
import { StatusEnum } from 'SolComponents/SolRealtimeStatus/SolRealtimeStatus'
import { SolRealtimeStatus } from 'SolComponents'
import { zonedTimeToUtc } from 'date-fns-tz'
import { RealtimeDisplay } from 'graphql/__generated__/types'
import { download } from 'shared/core/csv-download'

type Props = {
  realtimeData: RealtimeDisplay[] | undefined
  loading: boolean
  totalItems: number
  fetchCsvData: any
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const footTrafficTooltip = () => {
  return (
    <div>
      <p>The percentage of time this room was in use during the past three hours.</p>
    </div>
  )
}

const columns: Column<Categorizable<RealtimeDisplay>>[] = [
  {
    name: 'realtimeStatus',
    displayName: 'Status',
    collapsing: true,
    centered: true,
    render: (r, toCsv) => {
      if (toCsv) {
        return r.realtimeStatus
      }
      let status = r?.realtimeStatus
      return <SolRealtimeStatus status={status as StatusEnum} isInline={false} />
    },
  },
  {
    name: 'building',
    displayName: 'Building',
    collapsing: true,
    render: row => row?.building,
  },
  {
    name: 'name',
    displayName: 'Room',
    collapsing: true,
    render: row => row?.name,
  },
  {
    name: 'solsticeUserCount',
    displayName: 'Solstice Users',
    collapsing: true,
    render: row => (row?.solsticeUserCount && row?.solsticeUserCount >= 0
      ? row?.solsticeUserCount : 0),
  },
  {
    name: 'isOccupied',
    displayName: 'Currently Occupied',
    collapsing: true,
    render: row => (row?.isOccupied ? 'Occupied' : '--'),
  },
  {
    name: 'footTraffic',
    displayName: 'Foot Traffic',
    collapsing: true,
    render: row => Math.round( (row?.footTraffic  || 0 ) * 100.) + '%',
    tooltipText: footTrafficTooltip( ),
  },
  {
    name: 'booked',
    displayName: 'Booked',
    collapsing: true,
    render: r => {
      const nowDate = zonedTimeToUtc( new Date(), localTimezone)
      const inUse = r?.realtimeStatus === 'in use'
      const isCalEnabled = r?.calendarConfigEnabled
      let hasEntry = false

      if (r?.currentCalendarStart && r?.currentCalendarEnd) {
        if (
          zonedTimeToUtc( r?.currentCalendarStart, localTimezone) <= nowDate
          && zonedTimeToUtc( r?.currentCalendarEnd, localTimezone) >= nowDate
        ) {
          hasEntry = true
        }
      }
      if (r?.nextCalendarStart && r?.nextCalendarEnd) {
        if (
          zonedTimeToUtc( r?.nextCalendarStart, localTimezone) <= nowDate
          && zonedTimeToUtc( r?.nextCalendarEnd, localTimezone) >= nowDate
        ) {
          hasEntry = true
        }
      }

      return inUse && isCalEnabled && hasEntry ? 'Yes' : 'No'
    },
  },
]

const RealtimeTable = ({
  realtimeData,
  loading,
  totalItems,
  fetchCsvData,
}: Props) => {
  const data = realtimeData || []

  const [loadingExport, setLoadingExport] = useState(false)
  const onExport = async () => {
    setLoadingExport(true)
    download(((await fetchCsvData()).data.realtimeDisplays ?? [])
      .map((row: any) => {
        return columns.reduce( (obj, col) =>
          Object.assign(obj, { [col?.displayName as string]: col.render?.(row, true) as string })
        , {})
      }), 'Realtime')
    setLoadingExport(false)
  }

  return (
    <div>
      {ServerDataTable<Categorizable<RealtimeDisplay>>({
        id: DataTableInstance.Realtime,
        columns: columns,
        data,
        title: 'Realtime',
        totalItems,
        loading,
        allowExportToCsv: true,
        exportProcessing: loadingExport,
        onExport: onExport,
        addableColumns: false,
        padded: false,
      })}
    </div>
  )
}

export default RealtimeTable
