import { SettingsGroupType, Template } from 'graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultTimeLocaleTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'TimeLocale',
    type: SettingsGroupType.TimeLocale,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enabled: true,
      hostname: 'pool.ntp.org',
      show24Hours: false,
      timezone: 'Europe/London',
      language: 'en_US',
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
