import React from 'react'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'
import { format } from 'date-fns'
import AlmostDone from './AlmostDone/AlmostDone'
import FlowButtons from 'components/Maintenance/Renewal/Partials/FlowButtons'
import { MxRenewLeadDisplayInterface } from 'shared/services/mxRenew/types'

export interface Props {
  cancelFlow: () => void
  closeBanner: () => void
  currentLead: MxRenewLeadInterface
  edit: () => void
  nextStep: number
  rewindFlow: () => void
  pods: { [field: string]: MxRenewLeadDisplayInterface }
  numRawPods: number
  updateLead: (
    id: MxRenewLeadId,
    update: Partial<MxRenewLeadInterface>,
    nextStep: number
  ) => void
}

class AlmostDoneStep extends React.Component<Props> {
  handleNext = () => {
    const { updateLead, nextStep, currentLead, closeBanner } = this.props
    updateLead(
      currentLead.id,
      {
        ...currentLead,
        orderStatus: 'completed',
        step: nextStep,
      },
      nextStep,
    )
    closeBanner()
  }

  handleCancel = () => {
    const { updateLead, nextStep, currentLead, cancelFlow } = this.props
    updateLead(
      currentLead.id,
      {
        ...currentLead,
        orderStatus: 'cancelled',
      },
      nextStep - 1,
    )
    cancelFlow()
  }

  render() {
    const { edit, rewindFlow, pods, numRawPods, currentLead } = this.props

    const flowButtonsProps = {
      cancel: this.handleCancel,
      nextText: 'Request a Quote',
      next: this.handleNext,
    }

    const selectedPods = Object.keys(pods).length

    const rows: string[][] = []

    const rawPods: MxRenewLeadDisplayInterface[] = Object.values(pods)
    rawPods.forEach(pod => {
      rows.push([
        pod.name,
        pod.mxExpires,
        currentLead.newPodExpiration
          ? format(new Date(currentLead.newPodExpiration), 'M/d/yyyy')
          : 'N/A',
      ])
    })

    return (
      <div>
        <AlmostDone
          rewindFlow={rewindFlow}
          selectedPods={selectedPods}
          totalPods={numRawPods}
          rows={rows}
          currentLead={currentLead}
          edit={edit}
        />
        <FlowButtons {...flowButtonsProps} />
      </div>
    )
  }
}

export default AlmostDoneStep
