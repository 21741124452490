import {
  Template,
  SettingsGroupType,
  Vlan,
  NetworkConfig,
  IpType,
  EapMethod,
  Phase2Auth,
} from 'graphql/__generated__/types'

export const defaultNetworkConfig: NetworkConfig = {
  ipType: IpType.Dhcp,
  gateway: '',
  networkPrefixLength: 24,
  dns1: '',
  dns2: '',
}

export const defaultVlan: Vlan = {
  enabled: false,
  label: '',
  tag: 100,
  adminAccess: true,
  networkConfig: defaultNetworkConfig,
}

import { DefaultTemplate } from '../TemplateTypes'

export const defaultEthernetTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Ethernet',
    type: SettingsGroupType.Ethernet,
    hidden: false,
  },
  assignedDisplays: {
    totalRecords: 0,
  },
  revision: {
    id: '',
    configuration: {
      enableEthernet: true,
      networkName: 'Internal',
      networkConfig: defaultNetworkConfig,
      adminAccess: true,
      enable8021x: true,
      eapMethod: EapMethod.None,
      phase2Auth: Phase2Auth.None,
      caCertificate: '',
      userCertificate: '',
      identity: '',
      password: '',
      vlans: [
        defaultVlan,
        {
          enabled: false,
          label: '',
          tag: 100,
          adminAccess: true,
          networkConfig: defaultNetworkConfig,
        },
        {
          enabled: false,
          label: '',
          tag: 100,
          adminAccess: true,
          networkConfig: defaultNetworkConfig,
        },
      ],
    },
  },
}
