import React from 'react'
import Metric from './Metric'

interface BusiestHourProps {
  count: number | undefined
}

type MetricType = {
  count: number | '--'
  unit: string
}

export const getMetric = (rawCount?: number): MetricType => {
  if (rawCount === undefined) {
    return {
      count: '--',
      unit: '',
    }
  }
  return {
    count: rawCount > 12 ? rawCount - 12 : rawCount,
    unit: rawCount >= 12 ? 'pm' : 'am',
  }
}

const BusiestHour = ({ count }: BusiestHourProps) => {
  const { unit, count: countFormatted } = getMetric(count)
  return <Metric live={false} count={countFormatted} unit={unit} caption="Busiest hour" dataTestId="busy-card" />
}

export default BusiestHour
