import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import styles from './SolTooltip.module.scss'
import classNames from 'classnames'

type Position =
  | 'top left'
  | 'top right'
  | 'bottom right'
  | 'bottom left'
  | 'right center'
  | 'left center'
  | 'top center'
  | 'bottom center'

export interface Props {
  text: string | JSX.Element | string[] | JSX.Element[]
  hoverable?: boolean
  position?: Position
  trigger?: Element | JSX.Element
  isInline?: boolean
  isInlineThin?: boolean
  className?: string
  disabled?: boolean
  open?: boolean
  note?: string
}

export function SolTooltip(props: Props) {
  const text = Array.isArray(props.text) && typeof props.text[0] === 'string'
    ? props.text.join(' ')
    : props.text

  const note = !!props.note && (
    <div className={styles.note}>
      {props.note}
    </div>
  )
  
  const trigger = (
    <div>
      {props.trigger ?? <Icon name="question circle" />}
    </div>
  )

  return (
    <div
      className={classNames(styles.popup, props.className, {
        [styles.isInline]: props.isInline,
        [styles.isInlineThin]: props.isInlineThin,
      })}
    >
      <Popup
        content={[text, note]}
        hoverable={props.hoverable}
        position={props.position ?? 'top left'}
        trigger={trigger}
        className={props.className}
        open={props.open}
        disabled={props.disabled}
      />
    </div>
  )
}

export default SolTooltip
