import React, { useEffect } from 'react'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  useUpsertDisplayLocationsMutation,
  FetchAffectedDisplayIdsDocument,
  FetchAffectedDisplayIdsQueryVariables,
  FetchAffectedDisplayIdsQuery,
  useLocationsPageQuery,
  LocationsPageOverviewCardCountDocument,
  LocationsPageTableDocument,
  DisplayAddressesCountDocument,
} from 'graphql/__generated__/hasura-types'
import { useApolloClient } from '@apollo/client'
import { SolConfirmationModal } from 'SolComponents'
import { LatLng } from 'leaflet'
import { updateLocationsPageCardsCountQueryOnAddressChange } from 'shared/hooks/maps/useLocation'
import { LocationPageFilters } from '../Utils/get-hasura-params'
import { getSearchIdArgs } from 'components/DataTableSlim/Utils/hasuraUtils'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'

export type AreYouSureModalContext = {
  isOpen: boolean
  addressId?: number
  addressLat?: number
  addressLong?: number
}
type Props = {
  searchParamsAll: LocationPageFilters
  setToast: (t: boolean) => void
  areYouSureModalContext: AreYouSureModalContext
  setAreYouSureModalContext: (c: AreYouSureModalContext) => void
}
export const ChangeLocationModal = (props: Props) => {
  const searchParams = props.searchParamsAll.tableFilters
  const graphSearchParams = props.searchParamsAll.selectedItemsFilters
  const areYouSureModalContext = props.areYouSureModalContext

  const { setSelectedItems, tableData } = useDataTable<any>(DataTableInstance.Locations)

  const apolloClient = useApolloClient()

  const [upsertDisplayLocation] = useUpsertDisplayLocationsMutation()

  const { data: locationPageData } = useLocationsPageQuery({
    variables: {
      where: graphSearchParams,
      whereTotal: searchParams.where,
    },
  })

  const selectedPodCount = locationPageData?.filteredDisplayCount?.aggregate?.count ?? 0

  const deselectAllPods = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  const currentSelectedItems = tableData?.selectedItems ?? { ids: [], items: [] }
  const selectedDisplayArgs = getSearchIdArgs(currentSelectedItems)
  const whereSelected = {
    _display: {
      _and: [...(searchParams.where?._and ?? []), selectedDisplayArgs],
    },
  }

  const changeLocation = async () => {
    // Fetch affected display ids.
    // need the address_id
    const { data: affectedDisplaysResult } = await apolloClient.query<
      FetchAffectedDisplayIdsQuery,
      FetchAffectedDisplayIdsQueryVariables
    >({
      query: FetchAffectedDisplayIdsDocument,
      variables: {
        where: graphSearchParams,
      },
    })

    const addressLatLng = new LatLng(areYouSureModalContext.addressLat!, areYouSureModalContext.addressLong!)
    // pass in affected displayIds
    let oldDataCountsCity: { [index: string]: number } = {}
    let oldDataCountsCountry: { [index: string]: number } = {}
    affectedDisplaysResult?._displays.forEach(item => {
      const city
        = item.location?.address?.city
        + (item.location?.address?.state_province ? ', ' + item.location?.address?.state_province : '')
      const country = item.location?.address?.country ?? ''

      oldDataCountsCity[city] = (oldDataCountsCity[city] || 0) + 1
      oldDataCountsCountry[country] = (oldDataCountsCountry[country] || 0) + 1
    })

    upsertDisplayLocation({
      variables: {
        items: (affectedDisplaysResult?._displays ?? []).map(item => {
          const distance = addressLatLng.distanceTo(
            new LatLng(item?.location?.precise_lat!, item?.location?.precise_long!),
          )!

          if (distance > 500 || item?.location?.precise_lat === null || item?.location?.precise_long === null) {
            /// for all elements taht had thier lat long updated >> update cashe of the pods on map and address querys so that the map updates
            return {
              display: item.id,
              address_id: areYouSureModalContext.addressId,
              user_lat: areYouSureModalContext.addressLat,
              user_long: areYouSureModalContext.addressLong,
              user_accuracy_h: 25.0,
              display_uuid: item.uuid,
            }
          }
          return {
            display: item.id,
            address_id: areYouSureModalContext.addressId,
            user_lat: item?.location?.precise_lat,
            user_long: item?.location?.precise_long,
            display_uuid: item.uuid,
          }

        }),
      },
      // for all elements that had thier lat long updated >> update cashe of the pods on map and address querys so that the map updates
      update: updateLocationsPageCardsCountQueryOnAddressChange(
        props.searchParamsAll.tableFilters.where ?? {},
        oldDataCountsCity,
        oldDataCountsCountry,
      ),
      refetchQueries: [
        {
          query: LocationsPageOverviewCardCountDocument,
          variables: { displaywhere: searchParams.where },
        },
        {
          query: DisplayAddressesCountDocument,
          variables: {
            where: whereSelected,
          },
        },
        {
          query: LocationsPageTableDocument,
          variables: props.searchParamsAll.tableFilters,
        },
      ],
      awaitRefetchQueries: true,
    })

    props.setAreYouSureModalContext({ isOpen: false })
    deselectAllPods()
    props.setToast(true)
  }

  // Skip confirmation if count is 1
  useEffect(() => {
    if (areYouSureModalContext?.isOpen && selectedPodCount === 1) {
      changeLocation()
    }
  }, [areYouSureModalContext?.isOpen, selectedPodCount])

  return (
    <SolConfirmationModal
      modalText={`You are changing the location of ${selectedPodCount} pods`}
      subHeaderText="Are you sure you want to bulk assign?"
      isOpen={areYouSureModalContext?.isOpen && selectedPodCount > 1}
      onCloseModal={() => props.setAreYouSureModalContext({ isOpen: false })}
      onAcceptingAction={changeLocation}
      acceptingText="Confirm"
      cancelText="Cancel"
    />
  )
}
