import {
  useDisplayLocationSubscription,
  LocationsPageMapDocument,
  LocationsPageMapQuery,
  ViewDisplaysBoolExp,
  UpsertDisplayLocationsMutation,
  ViewDisplays,
  OrgAddresses,
  ViewDisplaysLocations,
  LocationsPageCardsQuery,
  LocationsPageCardsDocument,
} from 'graphql/__generated__/hasura-types'
import { LatLng } from 'leaflet'
import { BaseAddress } from 'pages/PodConfig/SettingsCards/Map/MapsTypes'
import { DataProxy } from '@apollo/client/cache'
import { ExecutionResult } from 'graphql'
import isEqual from 'lodash/isEqual'

export const useCurrentPodLocation = (podId: string) => {
  const { data } = useDisplayLocationSubscription({
    variables: { id: podId },
  })

  let currentPodLocation: LatLng | undefined
  const location = data?._display_locations[0]
  if (location) {
    currentPodLocation = new LatLng(location.precise_lat, location.precise_long)
  }

  return {
    currentPodLocation,
    address: location?.address as BaseAddress,
  }
}

export const updateLocationsPageMapQueryOnAddressChange = (mapWhere: ViewDisplaysBoolExp) => (
  proxy: DataProxy,
  { data }: ExecutionResult<UpsertDisplayLocationsMutation>,
) => {
  const cacheData = proxy.readQuery({
    query: LocationsPageMapDocument,
    variables: {
      where: mapWhere,
    },
  }) as LocationsPageMapQuery

  const changedData = data?.insert_orgs_displays_locations?.returning ?? []
  const cacheDisplays = cacheData._displays as ViewDisplays[]
  const _displays = cacheDisplays?.map(place => {
    const newInfo = changedData.find(updatedPlace => place.id === updatedPlace.display)
    if (newInfo) {
      const updateLocation = newInfo.user_long === newInfo?.address?.long && newInfo.user_lat === newInfo?.address?.lat
      return {
        id: place?.id,
        name: place?.name,
        location: {
          address_id: newInfo.address_id,
          precise_lat: updateLocation ? newInfo.user_lat : place?.location?.precise_lat,
          precise_long: updateLocation ? newInfo.user_long : place?.location?.precise_long,
          address: {
            ...(newInfo.address as OrgAddresses),
            __typename: 'org_addresses',
          },
          __typename: 'view_displays_locations',
        } as ViewDisplaysLocations,
        __typename: 'view_displays',
      } as ViewDisplays
    }
    return place
    
  })

  proxy.writeQuery({
    query: LocationsPageMapDocument,
    variables: {
      where: mapWhere,
    },
    data: {
      _displays,
    },
  })
}

export const updateLocationsPageCardsCountQueryOnAddressChange = (
  cardWhere: ViewDisplaysBoolExp,
  subtractCity: { [index: string]: number },
  subtractCountry: { [index: string]: number },
) => (proxy: DataProxy, { data }: ExecutionResult<UpsertDisplayLocationsMutation>) => {
  const changedData = data?.insert_orgs_displays_locations?.returning ?? []

  let addCity: { [index: string]: number } = {}
  let addCountry: { [index: string]: number } = {}
  changedData?.forEach(item => {
    const city = item.address?.city + (item.address?.state_province ? ', ' + item.address?.state_province : '')
    const country = item.address?.country ?? ''

    addCity[city] = (addCity[city] || 0) + 1
    addCountry[country] = (addCountry[country] || 0) + 1
  })

  /// ///CITY
  if (addSubtractEqual(addCity, subtractCity) === false) {
    const cacheDataCity = proxy.readQuery({
      query: LocationsPageCardsDocument,
      variables: {
        where: { display: cardWhere },
        category: 'city',
      },
    }) as LocationsPageCardsQuery
    if (cacheDataCity) {
      proxy.writeQuery({
        query: LocationsPageCardsDocument,
        variables: {
          where: { display: cardWhere },
          category: 'city',
        },
        data: {
          _categories: [
            {
              display_name: 'City',
              id: 'city',
              options: buildNewOptions(cacheDataCity._categories[0]?.options ?? [], addCity, subtractCity),
            },
          ],
        },
      })
    }
  }

  /// /// COUNTRY
  if (addSubtractEqual(addCountry, subtractCountry) === false) {
    const cacheDataCountry = proxy.readQuery({
      query: LocationsPageCardsDocument,
      variables: {
        where: { display: cardWhere },
        category: 'country',
      },
    }) as LocationsPageCardsQuery

    if (cacheDataCountry) {
      proxy.writeQuery({
        query: LocationsPageCardsDocument,
        variables: {
          where: { display: cardWhere },
          category: 'country',
        },
        data: {
          _categories: [
            {
              display_name: 'Country',
              id: 'country',
              options: buildNewOptions(cacheDataCountry._categories?.[0]?.options ?? [], addCountry, subtractCountry),
            },
          ],
        },
      })
    }
  }
}

const addSubtractEqual = (add: { [index: string]: number }, subtract: { [index: string]: number }) => {
  if (
    isEqual(Object.keys(add).sort(), Object.keys(subtract).sort())
    && isEqual(Object.values(add).sort(), Object.values(subtract).sort())
  ) {
    return true
  }
  return false
}

type Options = LocationsPageCardsQuery['_categories'][number]['options']

const buildNewOptions = (options: Options, add: { [index: string]: number }, subtract: { [index: string]: number }) => {
  if (!addSubtractEqual(add, subtract) && options !== []) {
    let updates = options.map(option => {
      let updatedCount = option.assignedDisplays_aggregate.aggregate?.count ?? 0

      if (option.display_name && subtract.hasOwnProperty(option.display_name)) {
        updatedCount
          = updatedCount - subtract[option.display_name] < 0 ? 0 : updatedCount - subtract[option.display_name]
      }
      if (option.display_name && add.hasOwnProperty(option.display_name)) {
        updatedCount += add[option.display_name]
        add[option.display_name] -= add[option.display_name]
      }

      return {
        display_name: option.display_name,
        id: option.id,
        assignedDisplays_aggregate: { aggregate: { count: updatedCount } },
      }
    })

    for (const [key, value] of Object.entries(add)) {
      if (value > 0) {
        updates.push({
          display_name: key,
          id: key.toLowerCase().replace(', ', '_'),
          assignedDisplays_aggregate: { aggregate: { count: value } },
        })
      }
    }
    return updates
  } else if (options === []) {
    let updates: Options = []
    for (const [key, value] of Object.entries(add)) {
      if (value > 0) {
        updates.push({
          display_name: key,
          id: key.toLowerCase().replace(', ', '_'),
          assignedDisplays_aggregate: { aggregate: { count: value } },
        })
      }
    }
    return updates
  }
  return []
}
