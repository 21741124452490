import { createSelectorWithDependencies as createSelector, registerSelectors } from 'reselect-tools'
import { State } from '../slices/reducerUtils'

/// ///////////////////////////////////////////////////////////////////

const isMersiveFn = (state: any): boolean => {
  const email: string = state?.email || 'user@example.com'
  return email.indexOf('mersive.com') !== -1
}

export const isMersive = createSelector([isMersiveFn], (result: State) => result)

/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getPathFn = (state: any) => state?.router?.location?.pathname

export const getPath = createSelector([getPathFn], (path: State) => path)

/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

registerSelectors({
  isMersive,
  getPath,
  getPathFn,
})

/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
