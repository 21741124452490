import React, { useEffect } from 'react'
import StickyBanner from 'components/Maintenance/StickyBanner'
import { MxModalSlideKey } from 'shared/store/types'
import { useTotalDisplaysNoViewQuery } from 'graphql/__generated__/types'

export interface State {
  showBanner: boolean
}

export interface StateFromProps {
  users: any
  showBanner: boolean
  modalSlideKeys: MxModalSlideKey[]
  setBannerOpen: (open: boolean) => void
}

const MaintenanceBanner = (props: StateFromProps) => {
  const { showBanner, modalSlideKeys, setBannerOpen } = props

  const { data } = useTotalDisplaysNoViewQuery({
    variables: {
      options: {
        isInSubscription: false,
        isIncludedElementPods: false,
      },
    },
  })

  const numExpiredCount = data?.totalDisplays || 0

  useEffect(() => {
    setBannerOpen(numExpiredCount > 0)
  }, [data])

  if (showBanner) {
    return (
      <StickyBanner
        expiredCount={numExpiredCount}
        modalSlideKeys={modalSlideKeys}
        closeBanner={() => setBannerOpen(false)}
      />
    )
  }
  return null
}

export default MaintenanceBanner
