import { Store } from 'redux'
import { Dispatch } from 'react'
// TODO: make an injection point for this
import authService from 'shared/services/auth'

import {
  AuthUserInviteToken,
  AuthUserInvitePromise,
  AuthUserInviteResponseInterface,
  AuthUserInvitePasswordInterface,
} from 'shared/services/auth/types'

export type CheckInviteStatusFn = (
  data: { jwt: AuthUserInviteToken }
) => (
  dispatch: Dispatch<Store<any>>
) => AuthUserInvitePromise

export const checkInviteStatus: CheckInviteStatusFn = ({ jwt }) => (_dispatch: Dispatch<Store<any>>) => (
  authService
    .checkInviteStatus(jwt)
    .then((data: AuthUserInviteResponseInterface) => data)
    .catch(err => err)
)

type SetPasswordResponseStatus = 'changed' | 'error'

export interface SetPasswordResponse {
  error?: string
  status: SetPasswordResponseStatus
}

export type SetPasswordPromise = Promise<SetPasswordResponse>

type SetInvitedUserPasswordFn =
  (data: AuthUserInvitePasswordInterface) =>
  (dispatch: Dispatch<Store<any>>) =>
  SetPasswordPromise

export const setInvitedUserPassword: SetInvitedUserPasswordFn = ({
  jwt,
  password,
}: AuthUserInvitePasswordInterface) => (_dispatch: Dispatch<Store<any>>) => (
  authService
    .setInvitedUserPassword(jwt, password)
    .then((data: SetPasswordResponse) => data)
    .catch(err => err)
)
