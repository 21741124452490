import React from 'react'
import Filter from './Filter'
import { useCustomFilters } from './Hooks/useAppliedFilters'
import styles from './DataTableSlim.module.scss'
import { ActiveLearningStatus } from 'graphql/__generated__/types'
import { useDataTable } from './Hooks/useDataTable'
import { ellipsesStringOverflow } from 'shared/core/utils'

interface Props {
  tableId: string
  clearSelectedItemsOnFilter?: boolean
  topFilter?: boolean
  totalPods?: number
  setSearchValue?: (searchValue: string) => void
  searchValue?: string
}

export function AppliedFilters({
  tableId,
  clearSelectedItemsOnFilter,
  topFilter,
  ...props
}: Props) {
  const { setCategoryFilters, categoryFilters, ...dataTable } = useDataTable<any>(
    tableId,
    { clearSelectedItemsOnFilter },
  )
  const setSearchValue = props.setSearchValue ?? dataTable.setSearchValue
  const searchValue = props.searchValue ?? dataTable.searchValue
  const { customFilters, removeCustomFilter } = useCustomFilters<ActiveLearningStatus>(tableId)

  const handleRemoveFilter = (categoryId: string | number) => {
    const { [categoryId]: _existing, ...rest } = categoryFilters
    setCategoryFilters(rest)
  }

  return (
    <div>
      {topFilter && searchValue && searchValue.length > 0 ? (
        <Filter
          key="searchOpt"
          className={styles.pill}
          optionDisplayName={ellipsesStringOverflow(searchValue, 150)}
          onRemoveFilter={() => setSearchValue('')}
        />
      ) : (
        ''
      )}
      {Object.values(categoryFilters).map((categoryFilter, index) => {
        return (
          <Filter
            key={index}
            className={styles.pill}
            optionDisplayName={Object.values(categoryFilter.optionMatches)
              .map(option => option.displayName)
              .join(' OR ')}
            onRemoveFilter={() => handleRemoveFilter(categoryFilter.categoryInternalName)}
          />
        )
      })}
      {Object.entries(customFilters).map(([filterId, customFilter]) => (
        <Filter
          key={filterId}
          className={styles.pill}
          optionDisplayName={Object.values(customFilter.values)
            .map(v => v.displayName)
            .join(' OR ')}
          color={customFilter.color}
          onRemoveFilter={() => removeCustomFilter(filterId)}
        />
      ))}
    </div>
  )
}
