import Papa from 'papaparse'

export function download(csvReadyJSON: Record<string, string>[], fileName: string) {
  const rawCsvData = Papa.unparse(csvReadyJSON as any)

  var csvData = new Blob([rawCsvData], {
    type: 'text/csv;charset=utf-8;',
  })
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, `${fileName}.csv`)
  } else {
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(csvData)
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
