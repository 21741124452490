import React from 'react'

import styles from './Chart.module.scss'
import OptionCheckbox from './OptionCheckbox'
import { ChartOption } from '../types'

export interface Props {
  options: ChartOption[]
  handleClick: any
  maxOptions: boolean
}

export default function ChartOptions(props: Props) {
  return (
    <div className={styles.optionsParent}>
      {props.options.map((opt, i) => (
        <OptionCheckbox
          key={i}
          id={opt.name}
          label={opt.name}
          checked={opt.checked}
          color={opt.color ? opt.color : '#E9EAEA'}
          handleClick={props.handleClick}
          disabled={props.maxOptions && !opt.checked}
          tooltip="A maxium of eight category options can be selected at a time."
        />
      ))}
    </div>
  )
}
