import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import { Bar, XAxisProps } from 'recharts'
import { SolBarChart, SolCard, SolCheckbox, SolButton } from 'SolComponents'
import styles from './SoftwareUsed.module.scss'
import { ContentSharingAppsGroupsQuery, ContentSharingAppNamesQuery } from 'graphql/__generated__/hasura-types'
import { Pagination } from 'shared/hooks/usePagination'
import { MAX_PER_PAGE } from '../useCards.hook'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'

interface Props {
  records: ContentSharingAppsGroupsQuery | ContentSharingAppNamesQuery | undefined
  testId?: string
  isCategoryActive?: boolean
  category?: string | undefined
  isGrouped: boolean
  isLoading: boolean
  pagination?: Pagination
  handleAxisYClick?: (groupName: string) => void
  handleCategoryActive: (isActive?: string) => void
  handleGrouping: (isGrouped: boolean) => void
}

const xAxisProps: XAxisProps = { type: 'number' }
const yAxisProps: any = { type: 'category', dataKey: 'name', width: 100 }
const commonGraphConfig = {
  xAxis: xAxisProps,
  yAxis: yAxisProps,
  tooltip: { formatter: (value: string) => [value, `Total`] },
}

const WhatCard = ({
  records,
  testId,
  category,
  isGrouped,
  isLoading,
  pagination,
  handleAxisYClick,
  handleCategoryActive,
  handleGrouping,
}: Props) => {
  const isCategoryActive = !!category && isGrouped

  const { data, count } = records || {}

  const total = count?.aggregate?.total || 0

  const noResults = !data || data.length === 0

  const onAxisClick = ({ value }: { value: string }) => {
    if (handleAxisYClick) {
      handleAxisYClick(value)
    }
  }

  const appConfig = {
    ...commonGraphConfig,
    tick: { fill: '#333' },
  }

  const groupConfig = {
    ...commonGraphConfig,
    yAxis: {
      ...commonGraphConfig.yAxis,
      tick: { fill: '#1279C6', cursor: 'pointer' },
      onClick: onAxisClick,
    },
  }

  const config = (!isCategoryActive && !isGrouped) || isCategoryActive ? appConfig : groupConfig

  const handleTitle = () => handleCategoryActive(undefined)

  const setGrouping = (grouped: boolean) => () => handleGrouping(grouped)

  const categoryHeader = (
    <a color="blue" onClick={handleTitle}>
      <Icon name="chevron left" />
      {category}
    </a>
  )

  return (
    <div data-testid={testId} className={styles.fullHeight}>
      <SolCard className={styles.whatCard} fluid>
        <div className={styles.headerContainer}>
          {isCategoryActive ? (
            <Header as="h3" textAlign="left" content={categoryHeader} className={styles.title} />
          ) : (
            <div className={styles.paginationContainer}>
              <div>
                <Header as="h3" textAlign="left" content="Software Used in Meetings" className={styles.title} />
              </div>
              <div className={styles.groupingOptions}>
                {!noResults && (
                  <SolCheckbox
                    className={styles.groupedCheck}
                    label="Grouped"
                    checked={isGrouped}
                    onClick={setGrouping(!isGrouped)}
                  />
                )}
              </div>
            </div>
          )}
          {total !== 0 && total > MAX_PER_PAGE && (
            <div className={styles.controls}>
              <SolButton
                handleClick={pagination?.prev}
                text={<Icon className={styles.arrow} name="chevron left" />}
                primary={false}
                disabled={pagination?.currentPage === 1}
                basic
                color="black"
                isLink
              />
              {pagination?.currentPage} of {pagination?.maxPage}
              <SolButton
                handleClick={pagination?.next}
                text={<Icon className={styles.arrow} name="chevron right" />}
                primary={false}
                disabled={pagination?.currentPage === pagination?.maxPage}
                basic
                color="black"
                isLink
              />
            </div>
          )}
        </div>
        <LoadingWrapper isEmpty={noResults} isLoading={isLoading}>
          <SolBarChart
            data={data || []}
            width={600}
            responsiveHeight={260}
            layout="vertical"
            xAxis={config.xAxis}
            yAxis={config.yAxis}
            tooltip={config.tooltip}
            margin={{ right: 24 }}
          >
            <Bar dataKey="total" fill="#a73b8f" />
          </SolBarChart>
        </LoadingWrapper>
      </SolCard>
    </div>
  )
}

export default WhatCard
