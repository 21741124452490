import React, { useEffect } from 'react'
import { OptionDate } from 'shared/types/OptionDate'
import { SolDonutCard } from 'SolComponents'
import { useDevices } from './UserDevices.hook'

interface UserDevicesProps {
  dateRange: OptionDate
  doneLoading: Function
}

const UserDevices = ({ dateRange, doneLoading }: UserDevicesProps) => {
  const { devices, isLoading } = useDevices({
    fromDate: dateRange.fromDate,
    toDate: dateRange.toDate,
  })

  useEffect(() => {
    if (!isLoading) {
      doneLoading()
    }
  }, [isLoading])

  return (
    <SolDonutCard
      data={devices}
      isLoading={isLoading}
      title="User Devices"
      url="/in-room-tech"
      linkName="IN ROOM TECH"
      testId="user-devices-card"
    />
  )
}

export default UserDevices
