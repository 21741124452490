import React from 'react'
import classNames from 'classnames'
import styles from './CardModal.module.scss'
export interface Props {
  className?: string
  isOpen: boolean
  header: string
  modalInfo: JSX.Element
  error?: string
  actionButtons: JSX.Element
  close?: () => void
}

export const CardModal = (props: Props) => (
  <>
    {props.isOpen && (
      <>
        <div className={styles.modalOverlay} onClick={props.close} />
        <div className={classNames(styles.modalContent, props.className)}>
          <div>
            <h4 className={styles.header}>{props.header}</h4>
            <div className={styles.modalInfo}>{props.modalInfo}</div>
            {props.error ? (
              <div className={styles.error}>{props.error}</div>
            ) : (
              <div className={styles.actionButtons}>{props.actionButtons}</div>
            )}
          </div>
        </div>
      </>
    )}
  </>
)
