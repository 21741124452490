import { SettingsGroupType, Template } from 'graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultDiscoveryTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Discovery',
    type: SettingsGroupType.Discovery,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      broadcast: true,
      publishToSds: true,
      sdsHosts: [
        { hostname: '' },
        { hostname: '' },
        { hostname: '' },
        { hostname: '' },
        { hostname: '' },
      ],
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
