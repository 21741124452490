import React from 'react'
import { Header, Input } from 'semantic-ui-react'
import { WithHelp } from '../../WithHelp/WithHelp'
import styles from './AddCustomNameCategory.module.scss'

interface Props {
  updateCategoryName: (displayName: string) => void
  nextStage: () => void
  cancel: () => void
  categoryName: string
}

const AddCustomNameCategory = ({
  updateCategoryName,
  nextStage,
  cancel,
  categoryName,
}: Props) => {
  const handleChange = (_e: any, { value }: { value: string }) => {
    updateCategoryName(value)
  }

  const handleKeyUp = (e: any) => {
    e.stopPropagation()
    if (!e.altKey && !e.ctrlKey && !e.metaKey && e.key === 'Enter') {
      nextStage()
    } else if (e.key === 'Escape') {
      if (e.target !== null) {
        cancel()
      }
    }
  }

  const helpText = `Enter a name for your custom category. Some examples would be 'City', 'Building', or 'Floor'.`

  return (
    <WithHelp
      component={() => (
        <div className={styles.contentWrapper}>
          <Header as="h5">CATEGORY NAME</Header>
          <Input
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            placeholder="Category name"
            autoFocus
          >
            <input maxLength={25} value={categoryName} />
          </Input>
        </div>
      )}
      helpText={helpText}
    />
  )
}

export default AddCustomNameCategory
