// TODO: separate from localStorage for testing
import { decode, JwtPayload } from 'jsonwebtoken'
import { AuthenticatedUserClaims } from 'shared/types/AuthenticatedUserClaims'

export function setToken(token: string) {
  localStorage.setItem('access_token', token)
}

export function setIdToken(idToken: string) {
  localStorage.setItem('id_token', idToken)
}

export function setOrg(org: string) {
  localStorage.setItem('org', org)
}

export function setEmail(email: string) {
  localStorage.setItem('email', email)
}

export function getToken() {
  return localStorage.getItem('access_token')
}

export function setAuthType(authType: 'auth0' | 'sso') {
  localStorage.setItem('authType', authType)
}

export function getAuthType() {
  return localStorage.getItem('authType')
}

export function parseUserDetailsFromToken(token: string) {
  let claims: AuthenticatedUserClaims | undefined
  const decoded = decode(token) as JwtPayload

  if (decoded) {
    const {
      sub = '',
      'https://mersive.com/org': orgId,
      'https://mersive.com/person': userEmail,
      'https://mersive.com/status': userStatus,
      'https://mersive.com/authType': authType,
      'https://mersive.com/partition': partition,
      'https://mersive.com/authorization': authorization,
      'https://mersive.com/ssoOnly': ssoOnly,
    } = decoded
    const { roles, groups, permissions } = authorization || {}
    const [_, userId] = /\|/.test(sub) ? sub.split('|') : [undefined, sub]

    claims = {
      orgId,
      roles,
      userId,
      groups,
      authType,
      userEmail,
      userStatus,
      partition,
      permissions: permissions ?? (decoded.scope ?? '').split(/\s/),
      ssoOnly,
    }
  }

  return claims
}
