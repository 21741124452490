import React from 'react'
import SpacesList from './SpacesList'
import {
  useSolsticeRoutingSpacesQuery,
  SolsticeRoutingSpacesOrderableField,
  Direction,
  SolsticeRoutingSpace,
} from 'graphql/__generated__/types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'

const SolsticeRoutingController = () => {

  const {
    searchValue,
    page,
    orderBy,
  } = useDataTable<SolsticeRoutingSpace>
  (DataTableInstance.SolsticeRoutingSpaces)

  const {
    data: spacesData,
    loading: spacesLoading,
    refetch: refetchSpaces,
  } = useSolsticeRoutingSpacesQuery({
    variables: {
      options: {
        nameLike: searchValue,
        limit: page.size,
        offset: (page.number - 1) * page.size,
        orderBy: {
          field: orderBy?.field.toUpperCase() as SolsticeRoutingSpacesOrderableField
            ?? SolsticeRoutingSpacesOrderableField.Name,
          direction: orderBy?.direction === 'descending'
            ? Direction.Desc : Direction.Asc,
        },
      },
    }, fetchPolicy: 'network-only',
  })

  const spaces = spacesData?.solsticeRoutingSpaces?.items as SolsticeRoutingSpace[]
  const totalSpaces = spacesData?.solsticeRoutingSpaces?.totalItems

  return (
    <SpacesList
      spaces={spaces ?? []}
      totalSpaces={totalSpaces ?? 0}
      spacesLoading={spacesLoading}
      refetchSpaces={refetchSpaces}
    ></SpacesList>
  )
}

export default SolsticeRoutingController
