import React, { useState } from 'react'
import { TransitionButtons } from '../TransitionButtons'
import AddCustomNameCategory from './AddCustomNameCategory/AddCustomNameCategory'
import AddCategoryAddOptions from './AddCategoryAddOptions/AddCategoryAddOptions'
import { RecommendedOrCustom } from './RecommendedOrCustom'
import AddedCategory from './AddedCategory/AddedCategory'
import { hasAllRecommendedCategories } from './hasAllRecommendedCategories'
import styles from './AddCategoryFlow.module.scss'
import CancelModal from '../modals/CancelModal'
import { AddRecommendedPickCategory } from './AddRecommendedPickCategory'
import { Category } from '../../CategoryTypes'

export interface StepProperties {
  cancelVisible: boolean
  continueDisabled: boolean
  continueText?: string
  ctaVisible: boolean
  modalComponent?: any
  topText?: string
  onCancel: () => any
  setNextStep: () => any
  setPreviousStep?: () => any
  childComponent: JSX.Element
}

export interface Props {
  onCategoryFinished: (categoryName: string, optionNames: string[]) => void
  onCancelled: () => void
  categories: Category[]
}

export const AddCategoryFlow = ({
  onCategoryFinished,
  onCancelled,
  categories,
}: Props) => {
  enum AddCategoryFlowStep {
    RECOMMENDED_OR_CUSTOM,
    ADDING_CUSTOM_NAME,
    ADDING_CUSTOM_OPTIONS,
    ADDING_RECOMMENDED,
    CONFIRM_CATEGORY_CANCEL,
    CONFIRM_OPTION_CANCEL,
    DONE
  }

  // State
  const [step, setStep] = useState(
    hasAllRecommendedCategories(categories)
      ? AddCategoryFlowStep.ADDING_CUSTOM_NAME
      : AddCategoryFlowStep.RECOMMENDED_OR_CUSTOM,
  )
  const [newCategoryName, setNewCategoryName] = useState<string>('')
  const [newOptionNames, setNewOptionNames] = useState<string[]>(['', ''])

  // Category methods
  const addDefaultCategory = (addedCategory: Category) => {
    setNewCategoryName(addedCategory.name)
    setNewOptionNames(addedCategory.options.map(option => option.name))
    setStep(AddCategoryFlowStep.DONE)
  }
  
  // Option methods
  const addOption = () => setNewOptionNames([...newOptionNames, ''])
  const removeOption = (index: number) => setNewOptionNames(newOptionNames.filter((_, i) => i !== index))
  const updateOption = (index: number, name: string) => {
    const newNewOptionNames = [...newOptionNames]
    newNewOptionNames[index] = name
    setNewOptionNames(newNewOptionNames)
  }

  const stepDefaults = {
    cancelVisible: true,
    continueDisabled: true,
    ctaVisible: true,
    modalProps: {},
    onCancel: onCancelled,
    setNextStep: () => {},
    childComponent: (<React.Fragment />),
  }
  
  const stepProperties = ((): StepProperties => {
    switch (step) {
      case AddCategoryFlowStep.RECOMMENDED_OR_CUSTOM: {
        return {
          ...stepDefaults,
          topText: 'Select a recommended category below',
          childComponent: (<RecommendedOrCustom
            addCustom={() => setStep(AddCategoryFlowStep.ADDING_CUSTOM_NAME)}
            addRecommended={() => setStep(AddCategoryFlowStep.ADDING_RECOMMENDED)}
          />),
        }
      }
      case AddCategoryFlowStep.ADDING_RECOMMENDED: {
        return {
          ...stepDefaults,
          continueDisabled: false,
          setPreviousStep: () => setStep(AddCategoryFlowStep.RECOMMENDED_OR_CUSTOM),
          setNextStep: () => setStep(AddCategoryFlowStep.DONE),
          childComponent: (<AddRecommendedPickCategory
            onRecommendedCategoryPicked={addDefaultCategory}
            categories={categories}
          />),
        }
      }
      case AddCategoryFlowStep.ADDING_CUSTOM_NAME: {
        return {
          ...stepDefaults,
          continueDisabled: newCategoryName?.trim() === '',
          topText: 'Select a recommended category or create a custom one',
          onCancel: () => setStep(AddCategoryFlowStep.CONFIRM_CATEGORY_CANCEL),
          setNextStep: () => setStep(AddCategoryFlowStep.ADDING_CUSTOM_OPTIONS),
          childComponent: (<AddCustomNameCategory
            updateCategoryName={setNewCategoryName}
            nextStage={() => setStep(AddCategoryFlowStep.ADDING_CUSTOM_OPTIONS)}
            categoryName={newCategoryName ?? ''}
            cancel={() => setStep(AddCategoryFlowStep.CONFIRM_CATEGORY_CANCEL)}
          />),
        }
      }
      case AddCategoryFlowStep.ADDING_CUSTOM_OPTIONS: {
        const hasMinOptionCount = newOptionNames.filter(name => name.trim() !== '').length >= 2
        const everyOptionNameUnique = newOptionNames.filter(
          (name, index) => newOptionNames.indexOf(name) !== index,
        ).length === 0
        return {
          ...stepDefaults,
          continueDisabled: !(hasMinOptionCount && everyOptionNameUnique),
          continueText: 'save & apply',
          topText: `Add options to ${newCategoryName} category`,
          onCancel: () => setStep(AddCategoryFlowStep.CONFIRM_OPTION_CANCEL),
          setPreviousStep: () => setStep(AddCategoryFlowStep.ADDING_CUSTOM_NAME),
          setNextStep: () => setStep(AddCategoryFlowStep.DONE),
          childComponent: (<AddCategoryAddOptions
            cancel={() => setStep(AddCategoryFlowStep.CONFIRM_OPTION_CANCEL)}
            optionNames={newOptionNames}
            updateOptionValue={updateOption}
            addOption={addOption}
            removeOption={removeOption}
          />),
        }
      }
      case AddCategoryFlowStep.CONFIRM_CATEGORY_CANCEL: {
        return {
          ...stepDefaults,
          modalComponent: (<CancelModal
            cancelAction={onCancelled}
            modalHide={() => setStep(AddCategoryFlowStep.ADDING_CUSTOM_NAME)}
          />),
        }
      }
      case AddCategoryFlowStep.CONFIRM_OPTION_CANCEL: {
        return {
          ...stepDefaults,
          modalComponent: (<CancelModal
            cancelAction={onCancelled}
            modalHide={() => setStep(AddCategoryFlowStep.ADDING_CUSTOM_OPTIONS)}
          />),
        }
      }
      case AddCategoryFlowStep.DONE: {
        return {
          ...stepDefaults,
          cancelVisible: false,
          continueText: 'got it',
          continueDisabled: false,
          topText: `New category '${newCategoryName}' added`,
          setNextStep: () => {
            onCategoryFinished(newCategoryName, newOptionNames.filter(name => name.trim().length > 0))
          },
          childComponent: (<AddedCategory categoryName={newCategoryName} />),
        }
      }
      default: {
        return stepDefaults
      }
    }
  })()

  return (
    <TransitionButtons
      cancelDisabled={false}
      cancelText="cancel"
      cancelVisible={stepProperties.cancelVisible}
      onCancelClicked={stepProperties.onCancel}
      ctaAction={stepProperties.setNextStep}
      ctaDisabled={stepProperties.continueDisabled}
      ctaText={stepProperties.continueText}
      ctaVisible={stepProperties.ctaVisible}
      modalComponent={stepProperties.modalComponent}
      modalVisible={!!stepProperties.modalComponent}
      previousAction={stepProperties.setPreviousStep}
      previousDisabled={false}
      previousText="previous"
      previousVisible={!!stepProperties.setPreviousStep}
      topText={stepProperties.topText}
    >
      <div className={styles.wrapper}>{stepProperties.childComponent}</div>
    </TransitionButtons>
  )
}
