import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { SolButton, SolLink } from 'SolComponents'
import styles from './SolTooltipWithLink.module.scss'

type Position =
  | 'top right'
  | 'bottom right'
  | 'top left'
  | 'bottom left'
  | 'top center'
  | 'bottom center'

interface Props {
  trigger: JSX.Element | Element
  text: string | string[]
  buttonText: JSX.Element | string
  open?: boolean
  onOpen?: () => void
  onClick?: () => void
  position?: Position
  isExternalLink?: boolean
  linkUrl?: string
  isInline?: boolean
  className?: string
  offset?: [number, number?]
}

export function SolTooltipWithLink(props: Props) {
  const [showPopup, setShowPopup] = useState(false)
  return (
    <div className={classNames(props.className, {
      [styles.isInline]: props.isInline !== false,
    })}>
      <Popup
        hoverable
        open={showPopup}
        onOpen={() => {
          if (props.onOpen) {
            props.onOpen()
          } else {
            setShowPopup(true)
          }
        }}
        onClose={() => setShowPopup(false)}
        position={props.position}
        trigger={<span>{props.trigger}</span>}
        className={props.className}
        offset={props.offset}
      >
        <Popup.Content className={styles.centeredButton}>
          <p className={styles.text}>
            {Array.isArray(props.text) ? props.text.join(' ') : props.text}
          </p>
          {props.isExternalLink && props.linkUrl && typeof props.buttonText === 'string' ? (
            <SolLink
              url={props.linkUrl}
              label={props.buttonText}
            />
          ) : (
            <SolButton
              primary={false}
              basic
              color="blue"
              isLink
              handleClick={e => {
                setShowPopup(false)
                props.onClick?.()
                e.stopPropagation()
              }}
              text={props.buttonText}
            />
          )}
        </Popup.Content>
      </Popup>
    </div>
  )
}

export default SolTooltipWithLink
