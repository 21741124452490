import React from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
import { AdvancedConfiguration } from 'graphql/__generated__/types'
import styles from '../Advanced.module.scss'
import {
  SolTimePicker,
  SolCheckbox,
} from 'SolComponents'

export interface Props {
  configuration: AdvancedConfiguration
  updateConfiguration: (newSettings: AdvancedConfiguration) => void
  templateDiff?: any
}

export function DailyRebootSettings({
  configuration,
  templateDiff,
  updateConfiguration,
}: Props) {
  return (
    <>
      <SolCheckbox
        label="Daily Reboot Enabled"
        checked={configuration.enableDailyRestart ?? false}
        mismatched={templateDiff?.enableDailyRestart === 'mismatched'}
        onClick={() => {
          updateConfiguration({
            ...configuration,
            enableDailyRestart: !configuration.enableDailyRestart,
          })
        }}
        bold={false}
      />
      <div className={classNames(styles.subItem, styles.timePicker)}>
        <SolTimePicker
          // @ts-ignore
          onClick={(e: any) => {
            e.stopPropagation()
          }}
          label="Daily Reboot Time"
          note="The Pod will reboot within 10 minutes after the selected time."
          value={configuration.dailyRestartTime ?? ''}
          mismatched={templateDiff?.dailyRestartTime === 'mismatched'}
          onChange={dates => {
            const twentyFourHourTime = format(dates[0], 'HH:mm')
            updateConfiguration({
              ...configuration,
              dailyRestartTime: twentyFourHourTime,
            })
          }}
          disabled={!configuration.enableDailyRestart}
        />
      </div>
    </>
  )
}
