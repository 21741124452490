import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import { RealtimeDisplay, RealtimeDisplaysQuery } from 'graphql/__generated__/types'
import React from 'react'
import { SolBarChart, SolCard, SolTooltip } from 'SolComponents'
import styles from './index.module.scss'
import SolsticeLogo from 'assets/solstice_logomark.png'
import { pluralize, toPercentage } from 'shared/core/utils'
import { Bar, XAxisProps, YAxisProps } from 'recharts'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'

type Props = {
  occupancyData?: RealtimeDisplaysQuery
  loading: boolean
}

const xAxisProps: XAxisProps = { type: 'category', dataKey: 'occupancyNumber' }
const yAxisProps: YAxisProps = { type: 'number', tickCount: 0, width: 15 }
const chartConfig = {
  margin: { right: 0, left: -15 },
  responsiveHeight: 100,
  width: 166,
  tooltip: {
    content: ({ payload, label }: { payload: { value: number }[]; label: string }) => (
      <div className={styles.tooltip}>
        {Math.round(payload[0]?.value)}% of current meetings have {label} {pluralize(Number(label), 'attendee', false)}
      </div>
    ),
  },
}

type Values = {
  occupancySum: number
  solUsers: number
  chartData: {
    occupancyNumber0: number
    occupancyNumber1: number
    occupancyNumber23: number
    occupancyNumber45: number
    occupancyNumber68: number
    occupancyNumber9: number
  }
}

const getChartData = (values: Values, room?: RealtimeDisplay | null) => {
  const attendees = room?.occupancyCount || 0
  if (attendees === 0) {
    return {
      ...values.chartData,
      occupancyNumber0: values.chartData.occupancyNumber0 + 1,
    }
  }
  if (attendees === 1) {
    return {
      ...values.chartData,
      occupancyNumber1: values.chartData.occupancyNumber1 + 1,
    }
  }
  if (attendees <= 3) {
    return {
      ...values.chartData,
      occupancyNumber23: values.chartData.occupancyNumber23 + 1,
    }
  }
  if (attendees <= 5) {
    return {
      ...values.chartData,
      occupancyNumber45: values.chartData.occupancyNumber45 + 1,
    }
  }
  if (attendees <= 8) {
    return {
      ...values.chartData,
      occupancyNumber68: values.chartData.occupancyNumber68 + 1,
    }
  }
  return {
    ...values.chartData,
    occupancyNumber9: values.chartData.occupancyNumber9 + 1,
  }
  return values.chartData
}

const Occupancy = ({ occupancyData, loading }: Props) => {
  const occupancyValues = occupancyData?.realtimeDisplays?.reduce(
    (values, room: RealtimeDisplay): Values => {
      const roomUserCount = !room.solsticeUserCount || room.solsticeUserCount < 0 ? 0 : room.solsticeUserCount
      const occupancyCount = room.occupancyCount ?? 0
      return {
        occupancySum: values.occupancySum + occupancyCount,
        solUsers: values.solUsers + roomUserCount,
        chartData: getChartData(values, room),
      }
    },
    {
      occupancySum: 0,
      solUsers: 0,
      chartData: {
        occupancyNumber0: 0,
        occupancyNumber1: 0,
        occupancyNumber23: 0,
        occupancyNumber45: 0,
        occupancyNumber68: 0,
        occupancyNumber9: 0,
      },
    },
  )

  const currentRoomsInUse = occupancyData?.realtimeDisplays.length || 0

  const chartData = [
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber0 || 0, currentRoomsInUse),
      occupancyNumber: '0',
    },
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber1 || 0, currentRoomsInUse),
      occupancyNumber: '1',
    },
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber23 || 0, currentRoomsInUse),
      occupancyNumber: '2-3',
    },
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber45 || 0, currentRoomsInUse),
      occupancyNumber: '4-5',
    },
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber68 || 0, currentRoomsInUse),
      occupancyNumber: '6-8',
    },
    {
      occupancy: toPercentage(occupancyValues?.chartData.occupancyNumber9 || 0, currentRoomsInUse),
      occupancyNumber: '9+',
    },
  ]
  return (
    <SolCard className={styles.occupancy}>
      <LoadingWrapper isEmpty={false} isLoading={loading}>
        <div data-testid="occupancy-card" className={styles.content}>
          <div className={styles.contentLeft}>
            <div className={styles.occupancyNumberContainer}>
              <div className={styles.occupancyNumber}>{occupancyValues?.occupancySum}</div>
              <div className={styles.questionMark}>
                <SolTooltip
                  /* eslint-disable-next-line max-len */
                  text="Number of occupants is only available for rooms with a camera plugged in and occupancy data setting is enabled."
                  trigger={<SolQuestionCircle />}
                />
              </div>
            </div>
            <div className={styles.occupancyLegend}>Current occupancy</div>
            <div className={styles.solUsers}>
              <img src={SolsticeLogo} /> {occupancyValues?.solUsers} Solstice{' '}
              {pluralize(occupancyValues?.solUsers || 0, 'user', false)}
            </div>
          </div>
          <div className={styles.contentRight}>
            <SolBarChart
              data={chartData}
              width={chartConfig.width}
              responsiveHeight={chartConfig.responsiveHeight}
              xAxis={xAxisProps}
              yAxis={yAxisProps}
              margin={chartConfig.margin}
              tooltip={chartConfig.tooltip}
              grid={false}
            >
              <Bar dataKey="occupancy" fill="#21BED5" />
            </SolBarChart>
            <div className={styles.average}>
              <span className={styles.averageNumber}>
                {currentRoomsInUse !== 0 ? Math.round((occupancyValues?.occupancySum || 0) / currentRoomsInUse) : 0}
              </span>
              <span className={styles.averageLabel}>Avg room occupancy</span>
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </SolCard>
  )
}

export default Occupancy
