import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styles from './SsoError.module.scss'
const solsticeCloudLoginLogo = require('../../assets/solstice-cloud-login.png')


const getFailureMessage = (message: string | null) => {
  switch (message) {
    case 'missing AttributeStatement':
      return `email and orgId are missing from your SAML configuration and are required fields.
       Please ensure all required information is entered correctly.`
    case 'missing attribute email':
      return `email is missing from your SAML configuration and is a required field.
       Please ensure all required information is entered correctly.`
    case 'missing orgId attribute':
      return `orgId is missing from your SAML configuration and is a required field.
       Please ensure all required information is entered correctly.`
    default:
      return `There are attributes missing from your SAML configuration.
      Please ensure all required information is entered correctly.`
  }
}

function SsoError(props: RouteComponentProps) {
  const queryParams = new URLSearchParams(props.location.search)
  const message = queryParams.get('message')

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginContainer}>
        <img draggable={false} className={styles.loginLogo} src={solsticeCloudLoginLogo} />
        <div className={styles.back}>
          <Link to="/login" >
            <Icon name="angle left" /> Back to Login
          </Link>
        </div>
        <Message negative>
          <Message.Header>SSO Configuation Error</Message.Header>
          <br />
          <Message.Content>
            {getFailureMessage(message)}
          </Message.Content>
        </Message>
      </div>
    </div>
  )
}

export default SsoError

