import React from 'react'
import classNames from 'classnames'
import { RoomIntelligenceConfiguration } from 'graphql/__generated__/types'
import styles from './RoomIntelligence.module.scss'
import {
  SolCheckbox,
  SolIconNotification,
  SolNotification,
  SolNumInput,
  SolTooltip,
} from 'SolComponents'
import { ValidationNumInput } from 'components/FormValidation/ValidationNumInput'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import { SolCalendarIcon } from 'SolComponents/Icons'
import { Props as RoomIntelligenceProps } from './RoomIntelligence'

interface Props {
  configuration: RoomIntelligenceConfiguration
  updateConfiguration: RoomIntelligenceProps['updateConfiguration']
}

export function EndOfMeetingNotifications({
  configuration,
  updateConfiguration,
}: Props) {
  const firstTimeBeforeMeeting = configuration.endOfMeetingMessage?.timeBeforeMeeting ?? 5
  const secondMessageEnabled = configuration.secondaryEndOfMeetingMessage?.enabled ?? false
  return (
    <>
      <SolCheckbox
        label="Enable upcoming meeting reminders"
        checked={configuration.endOfMeetingMessage?.enabled ?? false}
        notification={[
          <SolTooltip
            key={0}
            trigger={<SolQuestionCircle size="small" />}
            text="Upcoming meeting reminders notify users of the next meeting's start time"
            isInline
            className={styles.questionTooltip}
          />,
          <SolNotification
            key={1}
            type="menu"
            notifications="v5.4+"
            className={styles.pill}
          />,
          <SolIconNotification
            key={2}
            icon={SolCalendarIcon}
            text="This feature requires Calendar to be enabled."
            className={classNames(styles.pill, styles.calendar)}
          />,
        ]}
        onClick={val => updateConfiguration({
          ...configuration,
          endOfMeetingMessage: {
            ...configuration.endOfMeetingMessage,
            enabled: val,
          },
          secondaryEndOfMeetingMessage: {
            ...configuration.secondaryEndOfMeetingMessage,
            enabled: false,
          },
        })}
      />
      <div className={styles.children}>
        <SolNumInput
          label="Time before next meeting"
          inputSize="large"
          min={1}
          max={15}
          format={num => `${num} mins`}
          value={firstTimeBeforeMeeting}
          onChange={val => updateConfiguration({
            ...configuration,
            endOfMeetingMessage: {
              ...configuration.endOfMeetingMessage,
              timeBeforeMeeting: val,
            },
          })}
          containerClassName={styles.timeBeforeMeeting}
          disabled={!configuration.endOfMeetingMessage?.enabled}
        />
        <SolNumInput
          label="Duration of message"
          inputSize="large"
          min={1}
          max={30}
          format={num => `${num} secs`}
          value={configuration.endOfMeetingMessage?.messageDuration ?? 10}
          onChange={val => updateConfiguration({
            ...configuration,
            endOfMeetingMessage: {
              ...configuration.endOfMeetingMessage,
              messageDuration: val,
            },
          })}
          disabled={!configuration.endOfMeetingMessage?.enabled}
        />
        <SolCheckbox
          label="Display follow-up reminder"
          checked={secondMessageEnabled}
          notification={
            <SolTooltip
              isInline
              trigger={<SolQuestionCircle size="small" />}
              text={[
                'Displays a second reminder closer to the next meeting\'s start time.',
                '"Time before next meeting" must be less than that of the first reminder',
              ]}
              className={styles.questionTooltip}
            />
          }
          onClick={val => updateConfiguration({
            ...configuration,
            secondaryEndOfMeetingMessage: {
              ...configuration.secondaryEndOfMeetingMessage,
              enabled: val,
            },
          })}
          disabled={!configuration.endOfMeetingMessage?.enabled}
          className={styles.secondaryMessage}
        />
        <ValidationNumInput
          label="Time before next meeting"
          inputSize="large"
          min={1}
          max={10}
          format={num => `${num} mins`}
          value={configuration.secondaryEndOfMeetingMessage?.timeBeforeMeeting ?? 10}
          onChange={val => updateConfiguration({
            ...configuration,
            secondaryEndOfMeetingMessage: {
              ...configuration.secondaryEndOfMeetingMessage,
              timeBeforeMeeting: val,
            },
          })}
          name="second_time_before_meeting"
          rules={
            secondMessageEnabled
              ? { max: firstTimeBeforeMeeting - 1 }
              : {}
          }
          containerClassName={styles.timeBeforeMeeting}
          disabled={!configuration.endOfMeetingMessage?.enabled}
        />
        <SolNumInput
          label="Duration of message"
          inputSize="large"
          min={1}
          max={30}
          format={num => `${num} secs`}
          value={configuration.secondaryEndOfMeetingMessage?.messageDuration ?? 10}
          onChange={val => updateConfiguration({
            ...configuration,
            secondaryEndOfMeetingMessage: {
              ...configuration.secondaryEndOfMeetingMessage,
              messageDuration: val,
            },
          })}
          disabled={!configuration.endOfMeetingMessage?.enabled}
        />
      </div>
    </>
  )
}
