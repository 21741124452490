import React from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  DataTableInstance,
  Column,
  Categorizable,
  OrderBy,
} from 'components/DataTableSlim/DataTableSlim'
import styles from './index.module.scss'
import { ExpiredDisplaysQuery } from 'graphql/__generated__/hasura-types'
import { format } from 'date-fns'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

type Data = ExpiredDisplaysQuery['_displays'][0]

type Props = {
  totalItems: number
  data: Data[]
  loading: boolean
  onSort: (orderBy: OrderBy) => void
  totalItemsSelectable?: number
  totalItemsSelected?: number
}

const getSubscriptionRow = (row: Categorizable<Data>) => {
  const subscription = row.subscription
  const flexeraEnd = subscription?.flexera_maintenance_end
  const maintenanceEnd = subscription?.maintenance_end
  const dateFormat = 'yyyy/MM/dd'

  if (flexeraEnd) {
    return format(new Date(flexeraEnd), dateFormat)
  }

  if (maintenanceEnd) {
    return format(new Date(maintenanceEnd), dateFormat)
  }

  return 'N/A'
}

const columns: Column<Data>[] = [
  {
    name: 'is_in_subscription',
    displayName: 'Status',
    centered: true,
    addable: false,
    render: r => {
      if (!r?.is_in_subscription) {
        return <SolAlertIcon className={styles.exclamationTriangle} />
      }
      return ''
    },
  },
  {
    name: 'name',
    addable: false,
    render: row => row.name,
  },
  {
    name: 'maintenance',
    displayName: 'Expiration Date',
    addable: false,
    render: row => getSubscriptionRow(row),
  },
]

const ExpiredTable = ({
  totalItems,
  data,
  loading,
  onSort,
  totalItemsSelected,
  totalItemsSelectable,
}: Props) => {
  return (
    <div data-testid="renew-step1-table">
      {ServerDataTable<Categorizable<Data>>({
        id: DataTableInstance.RenewStep1,
        columns,
        data,
        title: 'Lapsed Pods',
        totalItems,
        onSort,
        loading,
        addCategoryCols: false,
        selectable: true,
        defaultSelectAll: true,
        totalItemsSelected,
        totalItemsSelectable,
        filterDrawer: false,
        padded: false,
      })}
    </div>
  )
}

export default ExpiredTable
