export type CategoryData = {
  data: CategoryOptionData[]
  displayName?: string
  internalName: string
  title?: string
}

export type CategoryOptionData = {
  name?: string
  internalName: string
  value: number
  color?: string
}

export enum FilterType {
  DisplayUnreachable = 'display-unreachable',
  DisplayRebooted = 'display-rebooted',
  DisplayOutOfTemplate = 'display-out-of-template',
  UsbDevice = 'usb-device'
}

export type CombinedFilter = {
  categoryInternalName: string
  optionInternalNames: string[]
}

export type Filter = {
  id: string
  optionInternalName?: string | null
  categoryInternalName?: string | null
}

export type DisplayUnreachableDelay = 5 | 15 | 30

export type UsbPlugEventType = 'added' | 'removed' | 'added-or-removed'
