import React from 'react'
import { AllDisplaysQuery, AllDisplaysQueryVariables, AllDisplaysDocument } from 'graphql/__generated__/types'
import { Icon } from 'semantic-ui-react'
import styles from './PodConfig.module.scss'
import { SolButton } from 'SolComponents'
import { useQuery } from '@apollo/client'
import { useDisplaySearchOptions } from 'pages/AllPods/useDisplaySearchOptions'

const usePreviousNextPods = (podId: string) => {
  const options = useDisplaySearchOptions()
  const { data } = useQuery<AllDisplaysQuery, AllDisplaysQueryVariables>(AllDisplaysDocument, {
    variables: {
      options,
    },
    fetchPolicy: 'cache-first',
  })
  const currentPods = data?.displays?.items ?? []
  const index = currentPods.findIndex(d => d?.id === podId)
  return {
    previousPod: index === -1 ? undefined : currentPods?.[index - 1],
    nextPod: index === -1 ? undefined : currentPods?.[index + 1],
  }
}

export const PreviousNextPodButtons = (props: { podId: string }) => {
  const { previousPod, nextPod } = usePreviousNextPods(props.podId)
  if (!previousPod && !nextPod) {
    return null
  }
  return (
    <div className={styles.prevNext}>
      {!!previousPod && (
        <SolButton
          text={
            <>
              <Icon name="chevron left" size="large" />
              <strong>Previous Pod</strong>
            </>
          }
          basic
          isLink
          internalLink={`/manage/pod/${previousPod?.id}`}
          containerClassName={styles.prev}
          dataTestId="previous-pod-button"
        />
      )}
      {!!nextPod && (
        <SolButton
          text={
            <>
              <strong>Next Pod</strong>
              <Icon name="chevron right" size="large" />
            </>
          }
          basic
          isLink
          internalLink={`/manage/pod/${nextPod?.id}`}
          containerClassName={styles.next}
          dataTestId="next-pod-button"
        />
      )}
    </div>
  )
}
