import React from 'react'
import { GoogleSettings, Template } from 'graphql/__generated__/types'
import { SolTooltipWithLink, SolFileUpload } from 'SolComponents'
import { getGoogleRoomMailbox, setGoogleRoomMailbox } from 'shared/config/calendar'
import { useAlerts } from 'shared/hooks/useAlerts'
import UniqueSettingsInput from '../UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'
import styles from './Calendar.module.scss'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import { RegexPattern } from 'components/FormValidation/ValidationInput'

export interface Props {
  podId?: string
  setGoogleSettings: (newSettings: Partial<GoogleSettings>) => void
  template: Template
  googleSettings: GoogleSettings
}

function GoogleSettingsLayout({ podId, setGoogleSettings, googleSettings }: Props) {
  const { showError } = useAlerts()

  return (
    <React.Fragment>
      <div>
        <div className={styles.label}>
          Login to your{' '}
          <a href="https://console.developers.google.com/iam-admin/serviceaccounts" target="_blank" rel="noreferrer">
            service accounts page
          </a>
          &nbsp;to generate a service accounts credentials file.
          <SolTooltipWithLink
            trigger={<SolQuestionCircle className={styles.questionIcon} />}
            text="Need help? Click the link below for more instructions."
            buttonText="VIEW DOCUMENTATION"
            position="top left"
            isExternalLink
            linkUrl="https://documentation.mersive.com/en/google-workspace-calendar.html"
          />
        </div>
        <SolFileUpload
          file={{
            name: googleSettings?.credentials?.name ?? '',
            contents: googleSettings?.credentials?.content?.secret ?? '',
          }}
          label="service account credentials"
          onUpload={({ fileName, fileContents }) => {
            setGoogleSettings({
              ...googleSettings,
              credentials: {
                name: fileName,
                content: {
                  secret: fileContents,
                },
              },
            })
          }}
          onFailure={showError}
          fileTypes=".json"
        />
        <div className={styles.file}>
          <UniqueSettingsInput
            label="Room Email"
            name="room_mailbox"
            rules={{ required: true, pattern: RegexPattern.Email }}
            getValue={getGoogleRoomMailbox}
            updateConfig={setGoogleRoomMailbox}
            podId={podId}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default GoogleSettingsLayout
