import { UniqueSettingsConfiguration } from './../../graphql/__generated__/types'

export const getIpAddress = (uniqueSettings: UniqueSettingsConfiguration) =>
  uniqueSettings?.WIFI?.ipAddress ?? ''

export const setIpAddress = (
  uniqueSettings: UniqueSettingsConfiguration,
  ipAddress: string,
) => ({
  WIFI: {
    ...uniqueSettings.WIFI,
    ipAddress,
  },
})

export const getWapDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.WIFI?.wapDnsHostname

export const setWapDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
  wapDnsHostname: string,
) => ({
  WIFI: {
    ...uniqueSettings.WIFI,
    wapDnsHostname,
  },
})

export const getExistingNetworkDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.WIFI?.existingNetworkHostname

export const setExistingNetworkDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
  existingNetworkHostname: string,
) => ({
  WIFI: {
    ...uniqueSettings.WIFI,
    existingNetworkHostname,
  },
})

export const getWapSsid = (uniqueSettings: UniqueSettingsConfiguration) =>
  uniqueSettings?.WIFI?.wapSsid

export const setWapSsid = (
  uniqueSettings: UniqueSettingsConfiguration,
  wapSsid: string,
) => ({
  WIFI: {
    ...uniqueSettings.WIFI,
    wapSsid,
  },
})
