import React, { useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import styles from './SolAlertMessage.module.scss'

interface Props {
  message: string
  show: boolean
  onDismiss: () => void
  type: 'success' | 'error'
}

function SolAlertMessage({ message, show, onDismiss, type }: Props) {
  if (!show) {
    return null
  }

  useEffect(() => {
    const timer = window.setTimeout(() => {
      onDismiss()
    }, 5000)
    return () => {
      window.clearTimeout(timer)
    }
  }, [show])

  return (
    <div>
      {type === 'error' && (
        <div className={styles.wrapper}>
          <div className={styles.error}>
            <p>{message} <Icon name="x"/></p>
          </div>
        </div>
      )}

      {type === 'success' && (
        <div className={styles.wrapper}>
          <div className={styles.succeeded}>
            <p>{message} <Icon name="checkmark"/></p>
          </div>
        </div>
      )}
    </div>
  )
}

export default SolAlertMessage
