import React, { useState, useEffect } from 'react'
import { LatLng, divIcon } from 'leaflet'
import styles from './Map.module.scss'
import 'leaflet/dist/leaflet.css'
import {
  useLocationsPageMapQuery,
  ViewDisplaysBoolExp,
} from 'graphql/__generated__/hasura-types'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import { renderToString } from 'react-dom/server'
import MarkerCluster from 'components/Location/Clustering/MarkerCluster'
import { BuildingClusterGroup } from 'components/Location/Clustering/ClusterTypes'
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
  showCluster: boolean
  zoom: number
  zoomToNewFilter: boolean
}

export function BuildingClusters(props: Props) {
  const { data } = useLocationsPageMapQuery({
    variables: {
      where: props.searchParamsWhere,
    },
  })

  useEffect(() => {
    if (data) {
      const locationData = data?._displays
      let uniqueAddress: number[] = []
      let bClusters: MarkerObject[] = []
      locationData.forEach(display => {
        const addressID = display.location?.address?.id ?? null
        const building = display.location?.address?.building ?? null
        const nickname
          = display.location?.address?.nickname
          ?? display.location?.address?.building
          ?? null
        const addressLat = display.location?.address?.lat ?? null
        const addressLong = display.location?.address?.long ?? null
        const numOfPodsAtAddress
          = display.location?.address?.assignedDisplays_aggregate?.aggregate
            ?.count ?? null

        if (addressID && !uniqueAddress.includes(addressID)) {
          uniqueAddress.push(addressID)

          bClusters.push({
            id: addressID.toString(),
            name: nickname ?? building,
            latlng: new LatLng(addressLat ?? 0, addressLong ?? 0),
            building: building,
            numOfPodsAtAddress: numOfPodsAtAddress,
          } as MarkerObject)
        }
      })
      setBuildingClusters(bClusters)
    }
  }, [data])

  const [buildingClusters, setBuildingClusters] = useState<MarkerObject[]>([])

  return (
    <div>
      {buildingClusters && buildingClusters.length > 0 && (
        <MarkerCluster
          mcg={BuildingClusterGroup}
          markersList={buildingClusters}
          buttonPopUp={false}
          icon={divIcon({
            html: renderToString(<OfficeBuildingIcon color="#88d7cd" />),
            className: `${styles.podDot}`,
          })}
          fitBounds={props.zoomToNewFilter && buildingClusters.length > 1}
          showCluster={props.zoom < 18 && props.showCluster}
          type="Building"
        />
      )}
    </div>
  )
}
export default BuildingClusters
