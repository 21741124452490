import React from 'react'
import Task from '../Task'
import styles from './HistoryTab.module.scss'
import EmptyTab from '../EmptyTab'
import { TabLoader } from '../TabLoader'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'
import { ScheduledTask } from 'graphql/__generated__/types'

export interface Props {
  versionUtils: VersionUtils
  loading: boolean
  tasks: ScheduledTask[]
}

export function HistoryTab({ versionUtils, loading, tasks }: Props) {

  if (loading) {
    return <TabLoader />
  } else if (tasks.length === 0) {
    return <EmptyTab text="A list of previous tasks will appear here." />
  }
  const taskCards = tasks.map((task, i) => {
    return <Task key={i} task={task} versionUtils={versionUtils} />
  })
  return <div className={styles.wrapper}>{taskCards}</div>
}
