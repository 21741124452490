import React from 'react'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useAppliedFilterOptions, useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import UsbPage from './UsbPage'
import { UsbConnection } from './Table/types'
import { SemanticCOLORS } from 'semantic-ui-react'
import { UsbDevice, UsbDeviceHistory, UsbProduct, useUsbDeviceConnectionsQuery } from 'graphql/__generated__/types'

const UsbController = () => {
  const {
    searchValue,
    page,
    orderBy: orderByTable,
  } = useDataTable<UsbConnection>(DataTableInstance.Usb)

  // Get the Custom filters, broken down into three types, and the generic setter to specialize later
  const {
    customFilters: usbCustomFilters,
    setCustomFilter: setUsbCustomFilters,
  } = useCustomFilters<any>(DataTableInstance.Usb)
  const { product, avDevices, availability } = usbCustomFilters

  // Get the specific methods to set the filter pills along in the header filter
  const addProductFilter = (id: number, name: string) => {
    setUsbCustomFilters('product', { values: {
      ...product?.values,
      [id]: { displayName: name, value: id },
    } })
  }
  const addAvailabilityFilter = (
    availabilityValue: boolean,
    displayName: string,
    color: SemanticCOLORS,
  ) => {
    setUsbCustomFilters('availability', {
      values: { available: { displayName, value: availabilityValue } },
      color,
    })
  }
  const addAvDeviceFilter = (value: boolean, displayName: string) => {
    setUsbCustomFilters('avDevices', { values: { av: { displayName, value } },
    })
  }

  const { data: usbDevicesData, loading: usbDevicesDataLoading } = useUsbDeviceConnectionsQuery({
    variables: {
      options: {
        orderBy: { [orderByTable?.field ?? 'name']: (orderByTable?.direction !== 'ascending' ? 'DESC' : 'ASC') },
        limit: page.size,
        offset: (page.number - 1) * page.size,
        nameLike: searchValue,
        // Applied Filters are either Categories or Locations
        ...useAppliedFilterOptions(DataTableInstance.Usb),
        // Custom Filters are unique to each page, USBPage has three kinds:
        ...{
          productFilter: Object.keys(product?.values ?? {}),
          avDevicesFilter: avDevices?.values?.av?.value?.toString(),
          availabilityFilter: availability?.values?.available?.value?.toString(),
        },
      },
    },
  })
  const usbDeviceData = usbDevicesData?.usbDevices?.usbDevices as [UsbDevice]
  const usbDeviceHistory = usbDevicesData?.usbDevices
    ?.history as [UsbDeviceHistory]
  const products = usbDevicesData?.usbDevices?.products as UsbProduct[]

  const usbDeviceCount = usbDevicesData?.usbDevices?.usbDeviceCount ?? 0
  const usbAvDeviceCount = usbDevicesData?.usbDevices?.usbAvDeviceCount ?? 0
  const usbAvOthersDeviceCount = usbDevicesData?.usbDevices
    ?.usbAvOthersDeviceCount ?? 0
  const usbOnlineDeviceCount = usbDevicesData?.usbDevices
    ?.usbOnlineDeviceCount ?? 0
  const usbOfflineDeviceCount = usbDevicesData?.usbDevices
    ?.usbOfflineDeviceCount ?? 0
  const podsOnlineCount = usbDevicesData?.usbDevices?.podsOnlineCount ?? 0
  const podsWithDevicesOnlineCount = usbDevicesData?.usbDevices
    ?.podsWithDevicesOnlineCount ?? 0
  const plugCountSum = usbDevicesData?.usbDevices?.plugCountSum ?? 0
  const unplugCountSum = usbDevicesData?.usbDevices?.unplugCountSum ?? 0
  const productConnectionsCount = usbDevicesData?.usbDevices
    ?.productConnectionsCount ?? 0

  return (<UsbPage
    addProductFilter={addProductFilter}
    addAvailabilityFilter={addAvailabilityFilter}
    addAvDeviceFilter={addAvDeviceFilter}
    tableData={usbDeviceData}

    podsWithDevicesCount={podsWithDevicesOnlineCount}

    totalDevicesCount={usbDeviceCount}
    totalAvDevicesCount={usbAvDeviceCount}
    totalOtherDevicesCount={usbAvOthersDeviceCount}
    usbOnlineDeviceCount={usbOnlineDeviceCount}
    usbDevicesDataLoading={usbDevicesDataLoading}
    usbOfflineDeviceCount={usbOfflineDeviceCount}
    totalPodsCount={podsOnlineCount}

    usbDeviceHistory={usbDeviceHistory}
    plugCountSum={plugCountSum}
    unplugCountSum={unplugCountSum}

    productData={products}
    productConnectionsCount={productConnectionsCount}
  ></UsbPage>)
}

export default UsbController
