import React from 'react'
import { WapSecurityProtocol, WapSettings } from 'graphql/__generated__/types'

import { SolButtonGroupProps, SolButtonGroup, SolDropdown } from 'SolComponents'
import { ValidationInput } from '../../../../components/FormValidation/ValidationInput'
import { DropdownItemProps } from 'semantic-ui-react'

export interface Props {
  config: WapSettings
  updateConfiguration: (newSettings: WapSettings) => void
}

type Frequency = '2.4' | '5'

function AdvancedWap({ updateConfiguration, config }: Props) {
  const frequency: Frequency = (config?.channel ?? 1) < 12 ? '2.4' : '5'
  const channelOptions5: DropdownItemProps[] = [
    { value: 36, text: '36' },
    { value: 40, text: '40' },
    { value: 44, text: '44' },
    { value: 48, text: '48' },
    { value: 52, text: '52' },
    { value: 56, text: '56' },
    { value: 60, text: '60' },
    { value: 64, text: '64' },
    { value: 100, text: '100' },
    { value: 104, text: '104' },
    { value: 108, text: '108' },
    { value: 112, text: '112' },
    { value: 116, text: '116' },
    { value: 120, text: '120' },
    { value: 124, text: '124' },
    { value: 128, text: '128' },
    { value: 132, text: '132' },
    { value: 136, text: '136' },
    { value: 140, text: '140' },
    { value: 144, text: '144' },
    { value: 149, text: '149' },
    { value: 153, text: '153' },
    { value: 157, text: '157' },
    { value: 161, text: '161' },
    { value: 165, text: '165' },
  ]
  const channelOptions2: DropdownItemProps[] = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
  ]

  const SecurityButtons: SolButtonGroupProps = [
    {
      primary: config?.securityProtocol === WapSecurityProtocol.Open,
      value: WapSecurityProtocol.Open,
      content: 'Open',
      onClick: (value: any) => {
        updateConfiguration({
          ...config,
          securityProtocol: value,
        })
      },
    },
    {
      primary: config?.securityProtocol === WapSecurityProtocol.Wpa,
      value: WapSecurityProtocol.Wpa,
      content: 'WPA2',
      onClick: (value: any) => {
        updateConfiguration({
          ...config,
          securityProtocol: value,
        })
      },
    },
  ]
  const frequencyButtons: SolButtonGroupProps = [
    {
      primary: frequency === '2.4',
      value: '2.4',
      content: '2.4 GHz',
      onClick: () => {
        updateConfiguration({
          ...config,
          channel: 6,
        })
      },
    },
    {
      primary: frequency === '5',
      value: '5',
      content: '5 GHz',
      onClick: () => {
        updateConfiguration({
          ...config,
          channel: 36,
        })
      },
    },
  ]
  return (
    <div>
      <SolButtonGroup label="Security" buttons={SecurityButtons} />
      {config.securityProtocol === WapSecurityProtocol.Wpa && (
        <div>
          <ValidationInput
            name="wpa2_pw"
            rules={{ required: true, minLength: 8 }}
            variant="password"
            label="WPA2 Password"
            value={config?.wpa2password?.secret}
            onChange={(e, value) =>
              updateConfiguration({
                ...config,
                wpa2password: {
                  secret: value.value,
                },
              })
            }
            onClear={() => {
              updateConfiguration({
                ...config,
                wpa2password: {
                  secret: '',
                },
              })
            }}
            errorText="Password must be at least 8 characters."
          />
        </div>
      )}
      <div>
        <SolButtonGroup label="Frequency" buttons={frequencyButtons} />
        <div>
          <SolDropdown
            type="caret"
            direction="left"
            options={frequency === '2.4' ? channelOptions2 : channelOptions5}
            label="Channel"
            value={config.channel ?? 11}
            onChange={val =>
              updateConfiguration({
                ...config,
                channel: Number(val),
              })
            }
            size="tiny"
          />
        </div>
      </div>
    </div>
  )
}

export default AdvancedWap
