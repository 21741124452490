import React, { useEffect, SyntheticEvent } from 'react'
import { Dropdown, Label } from 'semantic-ui-react'

import { PrimaryAxis, SecondaryAxis, Option, AxisOptions } from './types'

import styles from './AxisPicker.module.scss'
import { primaryOptions, secondaryOptions } from './chartUtils'
import SolElementInfoMessage from '../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'

interface Props {
  setAxis: (axis: AxisOptions[]) => void
  initPrimaryAxis: PrimaryAxis
  initSecondaryAxis: SecondaryAxis
  hasElementPods?: Boolean | null
}

export enum DropdownOptions {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type Dropdowns = DropdownOptions.PRIMARY | DropdownOptions.SECONDARY

type OptionsSelection = {
  primaryAxis: PrimaryAxis
  secondaryAxis: SecondaryAxis
}

const filterMetrics = (
  axisList: PrimaryAxis | SecondaryAxis,
) => (acc: Option[], opt: Option) => {
  if (opt.value === axisList) {
    return acc
  }
  return [...acc, opt]

}

const AxisPicker = ({ setAxis, initPrimaryAxis, initSecondaryAxis, hasElementPods }: Props) => {
  const [axisData, setOption] = React.useState({ primaryAxis: initPrimaryAxis, secondaryAxis: initSecondaryAxis })

  const selectOption = (value: Partial<OptionsSelection>) => setOption({ ...axisData, ...value })

  const handleDropdownChange = (
    _: SyntheticEvent,
    { value, name }: { value: PrimaryAxis | SecondaryAxis; name: Dropdowns },
  ) => {
    if (DropdownOptions.PRIMARY === name) {
      selectOption({ primaryAxis: value as PrimaryAxis })
    } else if (DropdownOptions.SECONDARY === name) {
      selectOption({ secondaryAxis: value })
    }
  }

  const availablePrimaryOptions: Option[] = primaryOptions.reduce(
    filterMetrics(axisData.secondaryAxis),
    [],
  )
  const availableSecondaryOptions: Option[] = secondaryOptions.reduce(
    filterMetrics(axisData.primaryAxis),
    [],
  )

  useEffect(() => {
    const axisSelected: AxisOptions[] = Object.keys(axisData)
      .map(key => axisData[key])
      .filter(metric => !!metric)
    setAxis(axisSelected)
  }, [axisData])

  return (
    <div className={styles.parent}>
      <Label basic color="blue">
        <Dropdown
          options={availablePrimaryOptions}
          onChange={handleDropdownChange}
          value={axisData.primaryAxis}
          selectOnBlur={false}
          name={DropdownOptions.PRIMARY}
          id="primary-metric"
          className={styles.dropdown}
        />
      </Label>
      <div className={styles.secondary}>
        <Label basic color="teal">
          <Dropdown
            placeholder="+ Add second axis"
            options={availableSecondaryOptions}
            onChange={handleDropdownChange}
            value={axisData.secondaryAxis}
            selectOnBlur={false}
            name={DropdownOptions.SECONDARY}
            id="secondary-metric"
            className={styles.dropdown}
          />
        </Label>
      </div>
      {hasElementPods
        && (
          <div className={styles.elementMessageContainer}>
            <SolElementInfoMessage
              message="Solstice Element pods are not included"
            />
          </div>
        )
      }
    </div>
  )
}

export default AxisPicker
