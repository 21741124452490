import React, { ReactNode, CSSProperties } from 'react'

const styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: '0 1 auto',
  overflowY: 'auto',
  width: '100%',
} as CSSProperties

function Content({ children }: { children?: ReactNode }) {
  return <div style={styles}>{children}</div>
}

export default Content
