import React, { useState } from 'react'
import { WithHelp } from '../WithHelp/WithHelp'
import { Menu, Dropdown } from 'semantic-ui-react'
import { Category, Option } from '../../CategoryTypes'
import { InlineEditTextbox } from '../shared/InlineEditTexbox/InlineEditTextbox'
import { TransitionButtons } from '../TransitionButtons'
import { CategoryChart } from '../AddCategoryFlow/CategoryChart'
import { CategoryChartLegendEdit } from '../CategoryChartLegendEdit/CategoryChartLegendEdit'
import DeleteCategoryConfirm from '../modals/DeleteCategoryConfirm'
import DeleteOptionConfirm from '../modals/DeleteOptionConfirm'
import styles from './EditCategory.module.scss'
import LoadingBar from 'components/Loaders/LoadingBar'
import { CategoryPieData } from '../CategoryPieData.interface'
import { CategoryMutationResponse, CategoryOptionMutationResponse } from 'graphql/__generated__/types'

interface Props {
  category: Category
  pieData: CategoryPieData
  loading: boolean
  onCancelEdit: () => void
  createCategoryOption: (
    categoryId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  updateCategoryOption: (
    categoryId: string,
    optionId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  deleteCategoryOption: (
    categoryId: string,
    optionId: string,
  ) => Promise<number>
  updateCategory: (
    categoryId: string,
    categoryName: string,
  ) => Promise<CategoryMutationResponse>
  deleteCategory: (
    categoryId: string,
  ) => Promise<number>
}

export const EditCategory = ({
  category,
  pieData,
  loading,
  onCancelEdit,
  createCategoryOption,
  updateCategoryOption,
  deleteCategoryOption,
  updateCategory,
  deleteCategory,
}: Props) => {
  const categoryId = category.id ?? ''
  const [renamingCategory, setRenamingCategory] = useState(false)
  const [deletingOption, setDeletingOption] = useState<{ id: string; name: string} | undefined>(undefined)
  const [deletingCategory, setDeletingCategory] = useState(false)

  const updateCategoryName = async (newCategoryName: string) => {
    setRenamingCategory(false)
    await updateCategory(category.id, newCategoryName)
  }

  const renderDeleteCategoryModal = () => (<DeleteCategoryConfirm
    deleteCategory={() => deleteCategory(category.id)}
    displayName={category.name}
    internalName={categoryId}
    modalHide={() => setDeletingCategory(false)}
    podCount={pieData.data.filter(x => x.id !== 'unassigned').reduce((prev, curr) => prev + curr.value, 0)}
    alertFilterCount={0}
  />)

  const renderDeleteOptionModal = () => (<DeleteOptionConfirm
    categoryInternalName={categoryId}
    deleteOption={() => deleteCategoryOption(categoryId, deletingOption!.id)}
    optionDisplayName={deletingOption?.name ?? ''}
    optionInternalName={deletingOption?.id ?? ''}
    modalHide={() => setDeletingOption(undefined)}
    podCount={(pieData.data.find(x => x.name === deletingOption!.name) || { value: 0 }).value}
    alertFilterCount={0}
  />)

  const confirmDeleteOption = (optionId: string) => {
    const option: Option | undefined = Object.values(category.options).find(c => c.id === optionId)
    if (option) {
      setDeletingOption(option)
    } else {
      /* eslint-disable-next-line no-console */
      console.warn(
        `Cannot delete selected option ${optionId} because it
          does not seem to exist on the current category ${category.name}`,
      )
    }
  }

  const helpText = `From here, you can edit this category and each of its options,
    add new options or delete the whole thing. Press 'DONE' when finished.`

  return (
    <TransitionButtons
      cancelVisible={false}
      ctaAction={onCancelEdit}
      ctaDisabled={false}
      ctaText="done"
      ctaVisible
      modalComponent={
        (deletingCategory && renderDeleteCategoryModal()) || (deletingOption && renderDeleteOptionModal())
      }
      modalVisible={deletingCategory || !!deletingOption}
      previousDisabled
      previousVisible={false}
      topText="Edit category name & options below"
    >
      <LoadingBar visible={loading} header />
      <WithHelp
        component={() => (
          <div className={styles.parent}>
            <div className={styles.content}>
              <div className={styles.graph}>
                {renamingCategory ? (
                  <div className={styles.renameTextbox}>
                    <InlineEditTextbox
                      displayName={category.name ?? ''}
                      value={category.name ?? ''}
                      disabled={(value: string) => value.trim() === ''}
                      onCancelEdit={() => {
                        setRenamingCategory(false)
                      }}
                      onCommitValue={updateCategoryName}
                      autoFocus
                    />
                  </div>
                ) : (
                  <Menu vertical className={styles.menu}>
                    <Dropdown item text={category.name ?? ''} className={styles.dropdown}>
                      <Dropdown.Menu className={styles.submenu}>
                        <Dropdown.Item onClick={() => setRenamingCategory(true)}>Rename Category</Dropdown.Item>
                        <Dropdown.Item onClick={() => setDeletingCategory(true)}>Delete Category</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                )}
                <CategoryChart data={pieData.data} />
              </div>
              <CategoryChartLegendEdit
                data={pieData}
                category={category}
                updateOption={(optionId: string, optionName: string) => {
                  updateCategoryOption(categoryId, optionId, optionName)
                }}
                deleteOption={confirmDeleteOption}
                createOption={(optionName: string) => { createCategoryOption(categoryId, optionName) }}
              />
            </div>
          </div>
        )}
        helpText={helpText}
      />
    </TransitionButtons>
  )
}
