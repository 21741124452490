import React from 'react'
import { SolButtonGroup, SolButtonGroupProps } from 'SolComponents'

import styles from './AlertHistory.module.scss'

interface ToggleProps {
  onlyShowEmailedAlerts: boolean
  setOnlyShowEmailedAlerts: (value: boolean) => void
}

const Toggle = (props: ToggleProps) => {
  const buttons: SolButtonGroupProps = [
    {
      primary: props.onlyShowEmailedAlerts,
      value: 'emailed',
      content: 'Only emailed alerts',
      onClick: () => props.setOnlyShowEmailedAlerts(true),
    },
    {
      primary: !props.onlyShowEmailedAlerts,
      value: 'all',
      content: 'All alerts',
      onClick: () => props.setOnlyShowEmailedAlerts(false),
    },
  ]
  return (
    <div className={styles.toggle}>
      <SolButtonGroup className={styles.buttons} buttons={buttons} />
    </div>
  )
}

export default Toggle
