import { useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { Segment } from 'semantic-ui-react'
import { LatLng } from 'leaflet'
import styles from './Map.module.scss'
import { UsageGeoDataDocument, UsageGeoDataQueryVariables, UsageGeoDataQuery, MapData }
  from 'graphql/__generated__/types'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import { offsetLatLng } from 'components/Location/Clustering/helpers'
import SolMap from 'SolComponents/SolMap/SolMap'
import { SolCheckbox } from 'SolComponents'
import { DateRange } from '../TopRow'
import { HomeMapClusters } from './HomeMapClusters'

type Props = {
  doneLoading: (ready: boolean) => void
  dateRange: DateRange
}

export function HomeMap(props: Props) {
  const [orgPods, setOrgPods] = useState<MarkerObject[]>([])

  const { data: alertsUsageData } = useQuery<UsageGeoDataQuery, UsageGeoDataQueryVariables>(
    UsageGeoDataDocument,
    {
      variables: {
        options: {
          fromDate: props.dateRange.fromDate,
          toDate: props.dateRange.toDate,
        },
      },
      fetchPolicy: 'no-cache',
    })

  const [alertMarkers, setAlertMarkers] = useState<MarkerObject[]>([])

  const [showUsage, setUsage] = useState(false)
  const [showAlerts, setAlerts] = useState(false)

  const [usageToggled, setUsageToggled] = useState(false)

  useEffect(() => {
    if (alertsUsageData) {
      const total = alertsUsageData?.usageGeoData?.mapData
        .filter(d => (d && d?.usageHours >= 0))
        .reduce((sum, d) => (d ? d?.usageHours + sum : sum), 0) || 1
      const locations: MarkerObject[] = alertsUsageData?.usageGeoData?.mapData.map((d: MapData) => ({
        id: d.id ?? '',
        name: d.name ?? '',
        latlng: offsetLatLng(new LatLng(d.preciseLat, d.preciseLong), d.id, 0.0005),
        usage: d.usageHours / total,
        numberOfAlerts: d.totalAlerts,
      })) || []

      const alerts: MarkerObject[] = alertsUsageData?.usageGeoData?.mapData
        .filter((d: MapData) => d.totalAlerts > 0)
        .map((d: MapData) => {
          const coords = offsetLatLng(new LatLng(d.preciseLat, d.preciseLong), d.id, 0.0005)

          return {
            id: d.id ?? '',
            name: d.name ?? '',
            latlng: coords, // new LatLng(lat, lng),
            usage: d.usageHours / total,
            numberOfAlerts: d.totalAlerts,
            alertsOutOfTemplateCount: d.outOfTemplateCount,
            alertsUnreachableCount: d.unreachableCount,
            alertsRestartCount: d.restartCount,
            alertsUsbRemovedCount: d.usbRemovedEventCount,
            alertsUsbAddedCount: d.usbAddedEventCount,
          }
        }) || []
      setAlertMarkers(alerts)
      setTimeout(() => { setOrgPods(locations) }, 1)
    }
  }, [alertsUsageData])

  return (
    <Segment
      className={styles.segment}
      data-testid="map-portlet"
    >
      <div className={styles.newHeader}>
        <div className={styles.roomLocs}>Room Locations</div>
        <div className={styles.checkboxes}>
          <SolCheckbox
            className={styles.box}
            label="Usage"
            checked={showUsage}
            onClick={() => {
              setUsage(!showUsage)
              setUsageToggled(!showUsage)
            }}
          />
          <SolCheckbox
            className={styles.box + ' checkbox-background-yellow'}
            label="Alerts"
            checked={showAlerts}
            onClick={() => setAlerts(!showAlerts)}
          />
        </div>
      </div>
      <div className={showAlerts ? styles.alerts : styles.none}>
        <SolMap
          className={styles.map}
          maxZoom={20}
          minZoom={2}
          beforeZoomToYourLocation={() => {
            if (showUsage) {
              setUsage(false)
            }
          }}
          afterZoomToYourLocation={() => {
            if (usageToggled) {
              setUsage(true)
            }
          }}
        >
          <HomeMapClusters
            orgPods={orgPods}
            alertMarkers={alertMarkers}
            showUsage={showUsage}
            showAlerts={showAlerts}
          />
        </SolMap>
      </div>
    </Segment>
  )
}
