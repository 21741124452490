import React, { useState } from 'react'
import { SolCard, SolCardHeader, SolPassword, SolAlertMessage } from 'SolComponents'
import styles from './ChangePassword.module.scss'
import LoadingBar from 'components/Loaders/LoadingBar'
import authService from 'shared/services/auth'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

const ChangePassword = () => {

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const [curentPasswordError, setCurrentError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')

  const [loading, setLoading] = useState(false)
  const [showUpdateNotification, setShowUpdateNotification] = useState(false)

  const checkPasswords = async () => {
    setCurrentError(false)
    let failed = false
    if (newPassword.length < 10) {
      setNewPasswordError('Password must be at least 10 characters long')
      failed = true
    } else if (!(/[A-Z]/g.test(newPassword))) {
      setNewPasswordError('Password must contain at least one uppercase character')
      failed = true
    } else if (!(/[a-z]/g.test(newPassword))) {
      setNewPasswordError('Password must contain at least one lowercase character')
      failed = true
    } else if (!/\d/.test(newPassword)) {
      setNewPasswordError('Password must contain at least one number')
      failed = true
    } else {
      setNewPasswordError('')
    }

    if (newPassword !== repeatPassword) {
      setRepeatPasswordError('Passwords do no match')
      failed = true
    } else {
      setRepeatPasswordError('')
    }

    if (!failed) {
      setLoading(true)
      try {
        const email = localStorage.getItem('email') || ''
        await authService.changePassword({ email, currentPassword, newPassword })
        setShowUpdateNotification(true)
        resetForm()
      } catch (err) {
        if (err.response?.status === 403 || err.response?.status === 500) {
          setCurrentError(true)
        }
      }
      setLoading(false)
    }
  }

  const resetForm = () => {
    setCurrentPassword('')
    setNewPassword('')
    setRepeatPassword('')
    setCurrentError(false)
    setNewPasswordError('')
    setRepeatPasswordError('')
    setLoading(false)
  }

  return (
    <>
      <LoadingBar visible={loading}/>
      <SolAlertMessage
        message="Password changed"
        show={showUpdateNotification}
        type="success"
        onDismiss={() => setShowUpdateNotification(false)}
      />
      <ContentWrapper>
        <SolCard className={styles.changePasswordCard}>
          <SolCardHeader
            name="Change Password"
            submit={() => {
              checkPasswords()
            }}
            cancel={resetForm}
            cancelButtonDisabled={!newPassword && !currentPassword && !repeatPassword}
            saveButtonDisabled={!newPassword || !repeatPassword || !currentPassword}
          />
          <div className={styles.cardContent}>
            <SolPassword
              className={styles.passwordInput}
              label="Current Password"
              onClear={() => {}}
              onChange={(e, v) => setCurrentPassword(v.value)}
              value={currentPassword}
              error={curentPasswordError}
              errorText={curentPasswordError ? 'Current password is incorrect' : ''}
              size="large"
            />
            <div>
              <span>Please create a password with:</span>
              <ul>
                <li>At least 10 characters</li>
                <li>At least one uppercase character</li>
                <li>At least one lowercase character</li>
                <li>At least one number</li>
              </ul>
            </div>
            <SolPassword
              className={styles.passwordInput}
              label="New Password"
              onClear={() => {}}
              onChange={(e, v) => setNewPassword(v.value)}
              value={newPassword}
              error={!!newPasswordError}
              errorText={newPasswordError}
              size="large"
            />
            <SolPassword
              className={styles.passwordInput}
              label="Repeat Password"
              onClear={() => {}}
              onChange={(e, v) => setRepeatPassword(v.value)}
              value={repeatPassword}
              error={!!repeatPasswordError}
              errorText={repeatPasswordError}
              size="large"
            />
          </div>
        </SolCard>
      </ContentWrapper>
    </>
  )
}

export default ChangePassword
