import { useEffect, useState } from 'react'
import { Styles } from 'react-joyride'
import {
  useJoyridesQuery,
  useResetJoyrideMutation,
  useFinishJoyrideMutation,
  JoyrideOption,
} from 'graphql/__generated__/types'

export enum Ride {
  SolsticeUpdates = 'SolsticeUpdates',
  ComparePage = 'ComparePage',
  SolsticeCloud = 'SolsticeCloud',
  ActiveLearning = 'ActiveLearning',
  PodActions = 'PodActions',
  SolsticeDeployDownload = 'SolsticeDeployDownload',
}

interface JoyRideHook {
  show: boolean
  finishRide: () => void
  resetRide: () => void
  startRide: () => void
  ready: boolean
}

const ActiveRides = {
  [Ride.SolsticeUpdates]: {
    active: true,
  },
  [Ride.ComparePage]: {
    active: true,
  },
  [Ride.ActiveLearning]: {
    active: true,
  },
  [Ride.PodActions]: {
    active: true,
  },
  [Ride.SolsticeDeployDownload]: {
    active: true,
  },
}

export function useJoyride(rideKey: Ride, delayStartMs?: number): JoyRideHook {
  const { data, refetch } = useJoyridesQuery({
    fetchPolicy: 'cache-first',
  })
  const [finishJoyride] = useFinishJoyrideMutation({
    variables: { options: JoyrideOption[rideKey] },
  })
  const [resetJoyride] = useResetJoyrideMutation({
    variables: { options: JoyrideOption[rideKey] },
  })

  const [rideShow, setRideShow] = useState(false)
  const [ready, setReady] = useState(false)
  const hasSeen = data?.joyrides.some(({ joyride }) => rideKey.includes( joyride ) )
  const finish = async () => {
    setRideShow(false)
    await finishJoyride()
    refetch()
  }
  const reset = () => resetJoyride()

  useEffect(() => {
    if (hasSeen === false) {
      setTimeout(() => {
        setRideShow(!hasSeen && ActiveRides[rideKey].active)
        setReady(true)
      }, delayStartMs ?? 0)
    }
  }, [hasSeen])

  return {
    show: rideShow,
    finishRide: finish,
    startRide: () => {
      setRideShow(true)
    },
    resetRide: reset,
    ready,
  }
}

export const styles: Styles = {
  tooltipTitle: {
    textAlign: 'left',
    paddingLeft: '10px',
  },
  tooltipContent: {
    textAlign: 'left',
  },
  options: {
    zIndex: 1000,
  },
  buttonNext: {
    border: 'none',
    textTransform: 'uppercase',
    backgroundColor: 'white',
    color: '#2185d0',
    fontSize: 14,
    fontWeight: 500,
  },
  buttonClose: {
    display: 'none',
  },
  buttonBack: {
    border: 'none',
    textTransform: 'uppercase',
    backgroundColor: 'white',
    color: '#1279C6',
    fontSize: 14,
    fontWeight: 500,
  },
  overlay: {
    cursor: 'default',
  },
  buttonSkip: {
    textTransform: 'uppercase',
    marginRight: 0,
    marginLeft: 'auto',
    display: 'flex',
    color: '#000',
    fontFamily: 'Lato',
  },
}
