import React from 'react'
import { AdvancedLldpSettings } from 'graphql/__generated__/types'
import styles from '../Advanced.module.scss'
import {
  SolCheckbox,
  SolNotification,
  SolTooltip,
} from 'SolComponents'
import { Icon } from 'semantic-ui-react'

interface Props {
  lldp?: AdvancedLldpSettings
  updateLLDP: (lldp: AdvancedLldpSettings) => void
  templateDiff?: any
}

const questionIcon = <Icon name="question circle" />

export function LLDPSettings({
  lldp,
  updateLLDP,
  templateDiff,
}: Props) {
  return (
    <>
      <SolCheckbox
        label="Enable LLDP"
        checked={lldp?.enabled ?? false}
        mismatched={templateDiff?.enabled === 'mismatched'}
        onClick={() => (
          updateLLDP({
            enabled: !lldp?.enabled,
            poeEnabled: false,
          })
        )}
        notification={[
          <SolTooltip
            key={0}
            isInline
            trigger={questionIcon}
            text="Enable reception and transmission of LLDP frames on all networks"
            className={styles.questionIcon}
          />,
          <SolNotification key={1} type="menu" notifications="v5.4+" />,
          <SolNotification key={2} type="menu" notifications="Gen 3+" />,
        ]}
      />
      <div className={styles.subItem}>
        <SolCheckbox
          label="Use LLDP for PoE"
          checked={lldp?.poeEnabled ?? false}
          mismatched={templateDiff?.poeEnabled === 'mismatched'}
          onClick={() => (
            updateLLDP({
              enabled: true,
              poeEnabled: !lldp?.poeEnabled,
            })
          )}
          disabled={!lldp?.enabled}
          notification={
            <SolTooltip
              isInline
              trigger={questionIcon}
              text={[
                'Use LLDP for PoE power negotiation, including displaying a warning to the user',
                'if the amount of power available is insufficient. This should only be enabled ',
                'for Pods that use Power over Ethernet as their main power source.',
              ]}
            />
          }
        />
      </div>
    </>
  )
}
