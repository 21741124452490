import React, { useState } from 'react'
import { SolButton } from 'SolComponents'
import { MetricCards } from './MetricCards/MetricCards'
import { TrialDrawer } from './TrialDrawer/TrialDrawer'
import { PodsTable, defaultSort } from './PodsTable/PodsTable'
import styles from './ActiveLearning.module.scss'
import { useJoyride, Ride } from 'shared/hooks/useJoyride'
import {
  TotalDisplaysDocument,
  TotalDisplaysQueryVariables,
  TotalDisplaysQuery,
  Display,
  Hardware,
  ActiveLearningDisplaysQueryVariables,
  ActiveLearningDisplaysQuery,
  ActiveLearningDisplaysDocument,
  ActiveLearningStatus,
  DisplaySearchOptions,
} from 'graphql/__generated__/types'
import { useQuery } from '@apollo/client'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useAppliedFilterOptions, useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { orderByTransform } from 'shared/domain/pods'
import { utm } from './constants'
import { ScreenKeyVerification } from './ScreenKeyVerification'
const logo = require('../../assets/active_learning/logo_full.svg')
const screenshot = require('../../assets/active_learning/screenshot.png')
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useSelector } from 'react-redux'
import { StoreState, User } from 'pages/Users/types'

const LandingPage = (props: { getStarted: () => void }) => (
  <div className={styles.landingPage}>
    <img src={logo} className={styles.logo} />
    <p className={styles.description}>
      Active Learning for Solstice allows you to dynamically route content between multiple displays using a simple,
      intuitive interface.
    </p>
    <SolButton
      text="LEARN MORE"
      color="blue"
      basic
      isLink
      externalLink={`https://www.mersive.com/active-learning/?${utm}`}
    />
    <img src={screenshot} />
    <SolButton
      text="Get Started"
      color="blue"
      basic={false}
      handleClick={props.getStarted}
      className={styles.getStarted}
    />
  </div>
)

export const PodsPage = () => {
  const [trialDrawerOpen, setTrialDrawerOpen] = useState(false)

  const user: User = useSelector((state: StoreState) => state.user)

  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.ActiveLearningPods)

  const { orderBy, page, searchValue, selectedItems, setSelectedItems } = useDataTable<Display>(
    DataTableInstance.ActiveLearningPods,
  )

  const { customFilters } = useCustomFilters<ActiveLearningStatus>(DataTableInstance.ActiveLearningPods)
  const unSelectRows = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  const displaySearchOptions: DisplaySearchOptions = {
    hardware: [Hardware.Gen3],
    searchTerm: searchValue,
    categories: categoryFilters,
    locationFilters: locationFilters,
    ...(customFilters.activeLearningStatus && {
      activeLearning: {
        statuses: Object.values(customFilters.activeLearningStatus.values).map(v => v.value),
      },
    }),
  }

  const {
    data,
    loading,
    refetch: fetchActiveLearningDisplays,
  } = useQuery<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>(ActiveLearningDisplaysDocument, {
    variables: {
      options: {
        ...displaySearchOptions,
        page: page.number,
        records: page.size,
        orderBy: orderByTransform(orderBy || defaultSort),
        isIncludedElementPods: false,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: totalItemsSelectableResponse } = useQuery<TotalDisplaysQuery, TotalDisplaysQueryVariables>(
    TotalDisplaysDocument,
    {
      variables: {
        options: {
          ...displaySearchOptions,
          activeLearning: {
            statuses: [ActiveLearningStatus.ReadyToEnable],
          },
          isIncludedElementPods: false,
        },
      },
    },
  )

  const fetchTotalSelectedItemsOptions = {
    ...displaySearchOptions,
    excludeDisplayIds: selectedItems.excludedIds,
    displayIds: selectedItems.includedIds,
    activeLearning: {
      statuses: [ActiveLearningStatus.ReadyToEnable],
    },
  }

  const { data: selectedItemsResult } = useQuery<TotalDisplaysQuery, TotalDisplaysQueryVariables>(
    TotalDisplaysDocument,
    {
      variables: { options: fetchTotalSelectedItemsOptions },
    },
  )

  const totalItemsSelected = !selectedItems.includedIds?.length ? 0 : selectedItemsResult?.displays?.totalRecords ?? 0

  const enableTrialButton = () => (
    <SolButton
      text="Enable Active Learning Trial"
      color="blue"
      handleClick={() => setTrialDrawerOpen(true)}
      disabled={totalItemsSelected === 0}
      className={styles.enableTrial}
    />
  )

  return (
    <div>
      <SolFilterHeaderCard
        header="Active Learning"
        filteredItems={totalItemsSelected}
        getTotalPods={() => data?.displays?.totalRecords ?? 0}
        tableId={DataTableInstance.ActiveLearningPods}
      />
      <ContentWrapper>
        <MetricCards displaySearchOptions={displaySearchOptions} />
        <div className={styles.aboveTable}>
          <div className={styles.aboveTableItem}>
            <div>
              <ScreenKeyVerification />
            </div>
          </div>
        </div>
        <PodsTable
          role={user.role}
          data={(data?.displays?.items ?? []) as Display[]}
          totalItemsSelectable={totalItemsSelectableResponse?.displays?.totalRecords ?? 0}
          loading={loading}
          totalItems={data?.displays?.totalRecords ?? 0}
          totalItemsSelected={totalItemsSelected}
          enableTrialButton={enableTrialButton}
        />
        <TrialDrawer
          isOpen={trialDrawerOpen}
          pods={totalItemsSelected}
          displaySearchOptions={{
            ...displaySearchOptions,
            displayIds: selectedItems.includedIds,
            excludeDisplayIds: selectedItems.excludedIds,
          }}
          onCloseDrawer={() => setTrialDrawerOpen(false)}
          onSubmitForm={() => {
            fetchActiveLearningDisplays()
            unSelectRows()
          }}
        />
      </ContentWrapper>
    </div>
  )
}

export default () => {
  const { show: showLandingPage, finishRide } = useJoyride(Ride.ActiveLearning)
  if (showLandingPage) {
    return <LandingPage getStarted={finishRide} />
  }
  return <PodsPage />
}
