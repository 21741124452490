import React, { Fragment, ComponentType, ReactNode }  from 'react'
import { Menu as SemanticMenu, Accordion } from 'semantic-ui-react'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import { ActiveIndex, childIndexes } from './SolMenu'
import classnames from 'classnames'
import styles from './SolMenu.module.scss'
import { State } from './SolMenuWrapper'

export interface Props {
  activeIndex: ActiveIndex
  active: boolean
  icon?: ComponentType<{ className?: string }>
  label: string
  children: ReactNode
  child?: true
  hidden?: boolean
  state: State
  setState: (state: State) => void
}

export const MenuDropdown = (props: Props) => {
  const { activeIndex, isExpanded } = props.state

  function handleClick(
    {}: {},
    { index }: { index: ActiveIndex },
  ) {
    const parentKey = Object.keys(childIndexes).find(key => childIndexes[key].includes(index))
    const parentIndex = parentKey === undefined ? undefined : parseInt(parentKey) as ActiveIndex
    
    if (activeIndex === index) {
      props.setState({
        activeIndex: parentIndex,
        isExpanded: !!parentIndex || !isExpanded,
      })
    } else {
      props.setState({
        activeIndex: index,
        isExpanded: true,
      })
    }
  }

  const children = childIndexes[props.activeIndex ?? '']
  const isActive
      = activeIndex === props.activeIndex
      || !!activeIndex && children?.includes(activeIndex)

  return props.hidden ? <Fragment /> : (
    <SemanticMenu.Item
      className={classnames({
        [styles.item]: !props.child,
        [styles.childItem]: props.child,
        [styles.expandedAccordion]: isActive && isExpanded,
      })}
      active={isActive}
    >
      <Accordion.Title
        className={classnames(styles.accordionTitle, {
          [styles.nested]: props.child,
        })}
        active={props.active}
        index={props.activeIndex}
        onClick={handleClick}
        content={
          <>
            {!!props.icon && <props.icon className={styles.icon} />}
            {props.label}
            <ChevronRightIcon className={classnames(styles.menuArrow, {
              [styles.child]: props.child,
            })} />
          </>
        }
      />
      <Accordion.Content
        active={isActive}
        className={classnames({
          [styles.childContent]: props.child,
        })}
      >
        {props.children}
      </Accordion.Content>
    </SemanticMenu.Item>
  )
}
