import {
  Display,
  DisplaySearchOptions,
  DisplayOrderableField,
  ManagementStatus,
} from 'graphql/__generated__/types'
import {
  DataTableInstance,
  Direction,
} from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import {
  useCustomFilters,
  useAppliedFilterOptions,
} from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { orderByTransform } from 'shared/domain/pods'

export const defaultSort = {
  direction: 'ascending' as Direction,
  field: 'name',
}

export const useDisplaySearchOptions = (): DisplaySearchOptions => {
  const { orderBy, page, searchValue: searchTerm } = useDataTable<Display>(
    DataTableInstance.AllPods,
  )
  const { categoryFilters, locationFilters } = useAppliedFilterOptions(DataTableInstance.AllPods)
  const { customFilters } = useCustomFilters<ManagementStatus>(
    DataTableInstance.AllPods,
  )

  return {
    searchTerm,
    orderBy: orderByTransform(
      orderBy ?? defaultSort,
      DisplayOrderableField.IsManageable,
    ),
    categories: categoryFilters,
    locationFilters: locationFilters,
    page: page.number,
    records: page.size,
    ...(customFilters.managementStatus && {
      managementStatus: {
        statuses: Object.values(customFilters.managementStatus.values).map(
          v => v.value,
        ),
      },
    }),
  }
}
