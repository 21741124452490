import { getToken } from 'shared/services/auth/helpers'
import { imageServerUrl } from './config'
import { Release } from '../hooks/versions/useVersionUtils'

export const scrollToTop = () => window.scroll({ top: 0, left: 0, behavior: 'smooth' })

export function hasHashCode(value: any): boolean {
  return value != null && typeof value.hashCode === 'function'
}

export const customMemoizeResolver = (...args: any[]): string => {
  return args.reduce((acc: string, cur: any) => (
    `${acc}${hasHashCode(cur) ? cur.hashCode() : JSON.stringify(cur)}`
  ), '')
}

export const unique = (val: any, i: number, self: any[]) => self.indexOf(val) === i

// logic taken from
// https://github.com/rotaready/moment-range/blob/f2a21a4e8d24fcbd40a0f1850c17224c392139ca/lib/moment-range.js#L154
// A start, A end, B start, B end, must all be the same magnitude, ideally milliseconds
// if two time ranges are adjacent, i.e. aE == bS, will return false
export const overlaps = (aS: number, aE: number, bS: number, bE: number): boolean => {
  const isZeroLength = aS === aE
  const isOtherZeroLength = bS === bE

  // Zero-length ranges
  if (isZeroLength || isOtherZeroLength) {
    return false
  }

  // Non zero-length ranges
  if (
    (aS <= bS && bS < aE && aE < bE)
    || (bS < aS && aS < bE && bE <= aE)
    || (bS < aS && aS <= aE && aE < bE)
    || (aS <= bS && bS <= bE && bE <= aE)
  ) {
    return true
  }

  return false
}

export const getJSBundleName = (): string => {
  const scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script')
  for (let i = 0; i < scripts.length; i++) {
    const fullURL: string = scripts[i].src || ''
    if (fullURL.indexOf('main') === -1) {
      continue
    } else {
      return fullURL.split('/').reverse()[0] || ''
    }
  }
  return ''
}

// bundleName will be main.version.js, where version is a hash generated by webpack
// by default returns the current version from the dom, or the version from the arg passed
export const versionFromBundleName = (bundleName: string = ''): string => bundleName.split('.')[1] || ''

// returns whether a particular bit in a bytearray is set
export const getBitValue = (bitset: Uint8Array, index: number): boolean => {
  return (bitset[(index / 8) | 0] & (1 << index % 8)) !== 0
}

export const getSelectedBitCount = (bitset: Uint8Array): number => {
  return bitset
    .toString() // '15,9'
    .split(',') // ['15', '9']
    .map(v => (+v).toString(2)) // '1111, 1001'
    .join('') // '11111001'
    .split('0') // ["11111", "", "1"]
    .join('').length // '111111' // 6
}

export const setBit = (bitset: Uint8Array, index: number): Uint8Array => {
  bitset[(index / 8) | 0] |= 1 << (index % 8 | 0)
  return bitset
}

export const unsetBit = (bitset: Uint8Array, index: number): Uint8Array => {
  bitset[(index / 8) | 0] &= ~(1 << (index % 8 | 0))
  return bitset
}

export function applyImageServer(hash: string) {
  return `${imageServerUrl()}/${hash}?auth=${getToken()}`
}

export function urlParams(url: string): Map<string, string> {
  const [, searchParams = ''] = url.split('?')
  return searchParams
    .split('&')
    .map(v => v.split('='))
    .reduce((map, [key = '', value]) => (key.length ? map.set(key, decodeURIComponent(value)) : map), new Map())
}

type ParamValue = string | number | boolean

export type Params = { [index: string]: ParamValue | ParamValue[] }

const encodeParam = (key: string, value: ParamValue) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`

export const queryString = (params: Params) =>
  Object.entries(params)
    .map(([key, val]) =>
      (Array.isArray(val) ? val.map(subVal => encodeParam(key, subVal)).join('&') : encodeParam(key, val)),
    )
    .join('&')

export const roundNumber = (value = 0, decimals = 2): number => +value.toFixed(decimals)

export const pluralize = (count: number, noun: string, showCount = true) =>
  `${showCount ? `${count} ` : ''}${noun}${count === 1 ? '' : 's'}`

export const toPercentage = (numerator: number, denominator: number, decimals = 0) => {
  const fraction = numerator / denominator
  return isFinite(fraction) ? roundNumber(fraction * 100, decimals) : 0
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, txt => `${
    txt.charAt(0).toUpperCase()
  }${
    txt.substr(1).toLowerCase()
  }`)
}

export const ellipsesStringOverflow = (val: string, len: number) => {
  return val.length > len ? val.substring(0, len) + '...' : val
}

export const validateURL = (string: string ) => {
  // eslint-disable-next-line
  var res = string.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return (res !== null)
}

/* eslint-disable-next-line new-cap */
export const abbreviateNumber = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  // @ts-ignore
  notation: 'compact',
  compactDisplay: 'short',
}).format

export class DateRange {
  start: Date
  end: Date
}

export const getVersionLabel = (releases: Release[], version: string | null | undefined) => {
  return releases.find(release => release.key === version)?.text
}
