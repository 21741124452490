import React from 'react'
import { Segment } from 'semantic-ui-react'
import { CategoryChart } from '../CategoryChart'
import styles from './AddedCategory.module.scss'

interface Props {
  categoryName: string
}

export const AddedCategory = ({ categoryName }: Props) => {
  const style = {
    segment: {
      width: '300px',
    },
    segmentCol: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    } as React.CSSProperties,
    chart: {
      width: '250px',
    },
  }

  const data = [
    {
      name: categoryName ?? '',
      value: 1,
      color: '#e5e5e5',
    },
  ]

  return (
    <div className={styles.parent}>
      <div className={styles.addedCategoryChartWrapper}>
        <div className={styles.addedCategoryTitle}>
          {categoryName}
        </div>
        <CategoryChart data={data} />
      </div>
      <div style={style.segmentCol}>
        <Segment color="blue" style={style.segment} >
          Use the table below to assign <span className={styles.truncateCategory}>{categoryName}</span>
          options to each Solstice display.
          This category will now appear throughout Solstice Cloud as a way to filter, sort and compare data.
        </Segment>
      </div>
    </div>
  )
}

export default AddedCategory
