import React, { useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import styles from './SolSearchDropdown.module.scss'
import { SolInput, SolTooltip } from 'SolComponents'
import classNames from 'classnames'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon'
import { BulkProps } from 'SolComponents/SolDropdown/SolDropdown'
import SolEditIcon from 'SolComponents/Icons/SolEditIcon'
import SolDeleteIconAdvanced from '../Icons/SolDeleteIcon/SolDeleteIconAdvanced'

export interface DropDownOption {
  key: string | number
  value: string
  text: string
  description: string
  editable: boolean
}

export interface Props {
  options: DropDownOption[]
  value?: string
  pinnedButtonText: string
  label: string
  questionIcon?: boolean
  placeholder?: string
  isOpen: boolean
  size?: 'static' | 'fluid' | 'fixed'
  onOpen?: () => void
  onClose?: () => void
  onClick?: () => void
  onBlur?: () => void
  onSearchChange: (val?: string) => void // function to handle changing input text inside dropdown
  editPencilClick?: (val?: any) => void
  deleteAddressClick?: (val?: any) => void
  onSelect: (v: DropDownOption) => void
  pinnedButtonClick: () => void
  addIcon?: JSX.Element | null
  variant: 'table-view' | 'single-view' | 'new-location-view'
  hasError?: boolean
  bulk?: BulkProps
  tooltipText?: string
}

const IconTooltip = (props: { text: string }) => {
  const trigger = <SolQuestionCircle size="small" className={styles.questionIcon} />
  return (
    <SolTooltip
      hoverable
      position="top center"
      trigger={trigger}
      isInline
      text={props.text}
    />
  )
}

export const SolSearchDropdown = (props: Props) => {
  const [hoverIndex, setHoverIndex] = useState<string|number|null>(null)
  props.options.sort((x: DropDownOption, y: DropDownOption) => {
    return x.editable === y.editable ? 0 : x.editable ? -1 : 1
  })

  const addIcon
    = props.addIcon === undefined ? (
      <span className={styles.addIcon}>
        <MapMarkerPlusIcon color="#2987cd" />
      </span>
    ) : null

  const pinnedButton = () => {
    if (props.size === 'static') {
      return styles.staticPinnedButton
    }
    if (props.size === 'fixed') {
      return styles.fixed
    }
    if (props.size === 'fluid') {
      return styles.pinnedButton
    }
    return
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.solSearchTable]: props.variant === 'table-view',
        [styles.solSearch]: props.variant !== 'table-view',
      })}
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
          props.onBlur?.()
        }
      }}
    >
      <SolInput
        onClick={() => {
          props?.onClick?.()
        }}
        className={classNames(styles.searchInput, {
          [styles.static]: props.size === 'static',
          [styles.fixed]: props.size === 'fixed',
          [styles.tableViewUnopened]: props.variant === 'table-view' && !props.isOpen,
          [styles.tableViewUnopenedError]: props.hasError && props.variant === 'table-view' && !props.isOpen,
        })}
        label={props.label}
        icon={props.questionIcon && props.tooltipText ? <IconTooltip text={props.tooltipText} /> : undefined}
        size="fluid"
        placeholder={props.placeholder ?? 'Search...'}
        value={props.value}
        onChange={(e, v) => {
          props.onSearchChange(v.value)
        }}
      />

      <Dropdown
        upward={false}
        fluid
        className={classNames({
          [styles.tableIcon]: props.variant === 'table-view',
          [styles.searchDropdownIcon]: props.variant !== 'table-view',
          [styles.bulkDropDown]: props.bulk && props.bulk.itemsSelected > 1,
        })}
        open={props.isOpen}
        onOpen={props.onOpen}
        onClose={props.onClose}
      >
        <Dropdown.Menu
          className={classNames({
            [styles.firstMenuTable]: props.variant === 'table-view',
            [styles.firstMenu]: props.variant === 'single-view',
            [styles.firstMenuNew]: props.variant === 'new-location-view',
          })}
          direction="left"
        >
          <Dropdown.Menu scrolling className={styles.nestedMenu}>
            {props.bulk && props.bulk.itemsSelected > 1 && (
              <Dropdown.Header>
                <Dropdown.Item disabled>
                  <div className={styles.podsSelected}>
                    {props.bulk.itemsSelected} {props.bulk.itemsLabel ?? 'items'} selected
                  </div>
                  <Icon className="times circle" onClick={props.bulk.onCancel} />
                </Dropdown.Item>
              </Dropdown.Header>
            )}
            {props.options.map(header => (
              <Dropdown.Header
                onMouseDown={() => {
                  if (!header.editable) {
                    props.onSelect(header)
                  }
                }}
                disabled={header.editable}
                key={header.key}
                className={classNames(
                  styles.dropdownMenu,
                  header.editable ? styles.disabledElement : styles.dropdownMenu,
                )}
                onMouseEnter={() => setHoverIndex(header.key)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                {header.text}
                {props.variant !== 'new-location-view' && header.editable ? (
                  <span
                    className={styles.pencil}
                  >
                    <SolEditIcon className={styles.editIcon} onClick={props.editPencilClick}/>
                    <SolDeleteIconAdvanced
                      onClick={() => props.deleteAddressClick?.(header)}
                      className={styles.editIcon}
                    />
                  </span>
                ) : (
                  <span className={styles.delete}>
                    {hoverIndex === header.key && (
                      <SolDeleteIconAdvanced onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation()
                        props.deleteAddressClick?.(header)
                      }} className={styles.editIcon} />
                    )}
                  </span>
                )}
                <Dropdown.Item className={styles.dropdownDescription}>
                  <div
                    className={classNames(props.size === 'static' ? styles.static : undefined, 'truncated-text')}
                  >
                    {header.description}
                  </div>
                </Dropdown.Item>
              </Dropdown.Header>
            ))}
          </Dropdown.Menu>

          <Dropdown.Item
            className={pinnedButton()}
            onMouseDown={() => {
              props.pinnedButtonClick()
            }}
          >
            {props.pinnedButtonText}
            {addIcon}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default SolSearchDropdown
