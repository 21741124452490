import { Location } from 'history'
import React, { useState } from 'react'
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom'
import SolConfirmationModal, { ConfirmationModalProps } from '../SolConfirmationModal/SolConfirmationModal'

export interface Props {
  when: boolean
  modalProps: Omit<ConfirmationModalProps, 'isOpen' | 'onAcceptingAction' | 'onCloseModal'>
}

export const SolRouteGuard = (props: Props & RouteComponentProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [lastLocation, setLastLocation] = useState('')

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (nextLocation.pathname === props.location.pathname) {
      return false
    }
    if (modalOpen) {
      return true
    }
    setLastLocation(nextLocation.pathname)
    setModalOpen(true)
    return false
  }

  const handleConfirmNavigationClick = () => {
    props.history.push(lastLocation)
    setModalOpen(false)
  }

  return (
    <div>
      <Prompt when={props.when} message={handleBlockedNavigation} />
      <SolConfirmationModal
        isOpen={modalOpen}
        onAcceptingAction={handleConfirmNavigationClick}
        onCloseModal={handleCloseModal}
        acceptingText="Leave"
        modalText="Are you sure you want to leave?"
        subHeaderText="All progress will be lost"
        {...props.modalProps}
      />
    </div>
  )
}

export default withRouter(SolRouteGuard)
