import React from 'react'
import classNames from 'classnames'
import { Tab } from 'semantic-ui-react'

import styles from './SolTabs.module.scss'

interface MenuItem {
  name: string
  displayName: string | React.ReactNode
}

export interface Props {
  menuItems: MenuItem[]
  alignment: 'right' | 'left' | 'center'
  onTabChange: (tabName: string) => void
  currentTab: string
}

export function SolTabs({
  alignment,
  menuItems = [],
  onTabChange,
  currentTab,
}: Props) {
  let tabAlignment = 'tabs-center'
  if (alignment) {
    tabAlignment = `tabs-${alignment}`
  }
  return (
    <React.Fragment>
      <Tab
        className={classNames(styles.solTabs, styles[tabAlignment])}
        menu={{ secondary: true, pointing: true }}
        panes={menuItems.map(item => ({
          menuItem: item.displayName,
          key: item.name,
        }))}
        activeIndex={menuItems.findIndex(m => m.name === currentTab)}
        onTabChange={(_, data) => {
          onTabChange(menuItems[data.activeIndex as string].name)
        }}
      />
    </React.Fragment>
  )
}

export default SolTabs
