import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Template, ProxyConfiguration } from 'graphql/__generated__/types'
import { SolCheckbox } from 'SolComponents'
import ProxyOptions from './ProxyOptions/ProxyOptions'
import styles from './Proxy.module.scss'
import classNames from 'classnames'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: ProxyConfiguration) => void
}

function Proxy({ updateConfiguration, template }: Props) {
  const configuration = template.revision.configuration as ProxyConfiguration
  const httpEnabledClass = classNames(
    { [styles.httpEnabled]: configuration.http.enabled },
    { [styles.httpDisabled]: !configuration.http.enabled },
  )
  return (
    <div>
      <div>
        <Grid stackable divided="vertically">
          <Grid.Row>
            <Grid.Column largeScreen={7} mobile={16} className={httpEnabledClass}>
              <SolCheckbox
                label="Enable web proxy for HTTP traffic"
                checked={configuration?.http.enabled ?? false}
                onClick={value => {
                  const enabledHttps = value ? value : configuration.https.enabled
                  updateConfiguration({
                    ...configuration,
                    http: {
                      ...configuration.http,
                      enabled: value,
                    },
                    https: {
                      ...configuration.https,
                      enabled: enabledHttps,
                    },
                  })
                }}
                bold
              />
              {configuration.http.enabled && (
                <div className={styles.proxyColumn}>
                  <ProxyOptions
                    settings={configuration.http}
                    variant="http"
                    updateConfig={proxy => {
                      updateConfiguration({
                        ...configuration,
                        http: proxy,
                      })
                    }}
                  />
                </div>
              )}
            </Grid.Column>
            <Grid.Column largeScreen={7} computer={16} tablet={16} mobile={16} className={styles.httpsColumn}>
              <SolCheckbox
                label="Enable web proxy for HTTPS traffic"
                checked={configuration.https.enabled ?? false}
                onClick={value => {
                  updateConfiguration({
                    ...configuration,
                    https: {
                      ...configuration.https,
                      enabled: value,
                    },
                  })
                }}
                bold
              />
              {configuration.https.enabled && (
                <div className={styles.proxyColumn}>
                  <ProxyOptions
                    settings={configuration.https}
                    variant="https"
                    updateConfig={proxy => {
                      updateConfiguration({
                        ...configuration,
                        https: proxy,
                      })
                    }}
                  />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default Proxy
