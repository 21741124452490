import React from 'react'
import {
  Categorizable,
  Column,
  DataTableInstance,
  DataTableSlimColumn,
} from 'components/DataTableSlim/DataTableSlim'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { Event } from '../types'

interface Props {
  totalItems: number
  data: Event[]
  loading: boolean
  onExport: (
    columnList: DataTableSlimColumn<Categorizable<Event>>[],
    addedColumns: string[],
  ) => Promise<void>
}

const columns: Column<Categorizable<Event>>[] = [
  {
    name: 'status',
    centered: true,
    collapsing: true,
    render: () => 'Status',
  },
  {
    name: 'date',
    render: () => '123',
  },
  {
    name: 'user',
    render: () => 'mtolliver@mersive.com',
  },
  {
    name: 'element',
    render: () => 'Pod',
  },
  {
    name: 'event',
    render: () => 'Updated',
  },
  {
    name: 'change_detail',
    render: () => (
      <>
        <div>Change Detail</div>
        <div>5.0</div>
      </>
    ),
  },
]

const HistoryTable = ({
  totalItems,
  data,
  loading,
  onExport,
}: Props) => {
  return (
    <ServerDataTable<Categorizable<Event>>
      id={DataTableInstance.History}
      columns={columns}
      data={data}
      title="Management History"
      totalItems={totalItems}
      loading={loading}
      allowExportToCsv
      addCategoryCols={false}
      onExport={onExport}
    />
  )
}

export default HistoryTable
