import React from 'react'
import { SecurityConfiguration } from 'graphql/__generated__/types'
import styles from './Security.module.scss'

import { SolCheckbox, SolFileUpload, SolButton } from 'SolComponents'
import { useAlerts } from 'shared/hooks/useAlerts'
import classNames from 'classnames'
import { defaultSecurityTemplate } from './SecurityTypes'
import { PasswordContainer } from '../Common/PasswordContainer'

export function isSecured(fileName?: string | null): boolean {
  return (fileName?.includes('.p12') || fileName?.includes('.pfx')) ?? false
}

export interface Props {
  config: SecurityConfiguration
  updateConfiguration: (newSettings: SecurityConfiguration) => void
}

function SecurityCerts({ updateConfiguration, config }: Props) {
  const { showError } = useAlerts()
  const isSecuredFile = isSecured(config?.displayCertificate?.cert?.name)
  const hasUploadedCertFile = !!config?.displayCertificate?.cert?.name
  return (
    <div>
      <SolCheckbox
        label="Enable encryption for Solstice traffic"
        checked={config?.enableEncryption ?? true}
        onClick={val => {
          updateConfiguration({
            ...config,
            enableEncryption: val,
          })
        }}
      />
      {config.enableEncryption && (
        <div className={styles.encryptionWrapper}>
          <SolFileUpload
            label="Certificate"
            fileTypes=".cer, .der, .crt, .pem, .pfx, .p12"
            file={{
              name: config?.displayCertificate?.cert?.name ?? '',
              contents: config?.displayCertificate?.cert?.content?.secret ?? '',
            }}
            variant="security"
            onUpload={({ fileName, fileContents }) => {
              updateConfiguration({
                ...config,
                displayCertificate: {
                  ...defaultSecurityTemplate.revision.configuration.displayCertificate,
                  cert: {
                    name: fileName,
                    content: { secret: fileContents },
                  },
                },
              })
            }}
            onFailure={showError}
          />
          {hasUploadedCertFile && !isSecuredFile && (
            <div className={styles.fileUpload}>
              <SolFileUpload
                label="new private key"
                fileTypes=".cer, .der, .crt, .pem, .pfx, .p12"
                file={{
                  name: config?.displayCertificate?.key?.name ?? '',
                  contents: config?.displayCertificate?.key?.content?.secret ?? '',
                }}
                variant="security"
                onUpload={({ fileName, fileContents }) => {
                  updateConfiguration({
                    ...config,
                    displayCertificate: {
                      ...config.displayCertificate,
                      key: {
                        name: fileName,
                        content: {
                          secret: fileContents,
                        },
                      },
                    },
                  })
                }}
                onFailure={showError}
              />
            </div>
          )}
          {hasUploadedCertFile && isSecuredFile && (
            <div className={styles.fileUpload}>
              <PasswordContainer
                label="Password"
                onChange={(_, v) =>
                  updateConfiguration({
                    ...config,
                    displayCertificate: {
                      ...config.displayCertificate,
                      password: {
                        secret: v.value,
                      },
                    },
                  })
                }
                onClear={() =>
                  updateConfiguration({
                    ...config,
                    displayCertificate: {
                      ...config.displayCertificate,
                      password: {
                        secret: '',
                      },
                    },
                  })
                }
                name="display_cert_pw"
                value={config?.displayCertificate?.password?.secret}
                hash={config?.displayCertificate?.password?.secretHash}
                salt={config?.displayCertificate?.password?.secretSalt}
              />
            </div>
          )}
          <div
            className={classNames({
              [styles.encryption]: !hasUploadedCertFile || !isSecuredFile,
            })}
          >
            <SolButton
              text="Reset to default"
              primary
              disabled={!hasUploadedCertFile}
              basic={false}
              handleClick={() => {
                updateConfiguration({
                  ...config,
                  displayCertificate: defaultSecurityTemplate.revision.configuration.displayCertificate,
                })
              }}
              isLink={false}
            />
          </div>
        </div>
      )}

      <SolCheckbox
        label="Use custom CA cert bundle for https"
        checked={config?.useCaCertificate ?? true}
        onClick={val => {
          updateConfiguration({
            ...config,
            useCaCertificate: val,
          })
        }}
      />
      {config.useCaCertificate && (
        <div className={styles.caCert}>
          <SolFileUpload
            label="custom CA cert bundle"
            fileTypes=".cer, .der, .crt, .pem, .pfx, .p12"
            variant="security"
            file={{
              name: config?.caCertificate?.name ?? '',
              contents: config?.caCertificate?.content ?? '',
            }}
            onUpload={({ fileName, fileContents }) => {
              updateConfiguration({
                ...config,
                caCertificate: {
                  name: fileName,
                  content: fileContents,
                },
              })
            }}
            onFailure={showError}
          />
        </div>
      )}
    </div>
  )
}

export default SecurityCerts
