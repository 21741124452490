import React, { useEffect, useState } from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import { SolButton, SolColumn } from 'SolComponents'
import styles from './DataTableSlim.module.scss'

export const itemsPerPageOptions = [25, 50, 100] as const
export type PageSize = typeof itemsPerPageOptions[number]
export const itemsPerPageDefault: PageSize = 25

export interface PaginationProps {
  currentPage: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: PageSize) => void
  itemsPerPage: number
  totalItems: number
  totalItemsSelected: number
  totalItemsSelectable: number
  colSpan?: number
  loading?: boolean
  allowExportToCsv?: boolean
  exportProcessing?: boolean
  onExportToCsv?: () => void
  hasItems: boolean
}

function Pagination({
  currentPage,
  itemsPerPage,
  onPageChange,
  onPageSizeChange,
  allowExportToCsv,
  exportProcessing,
  onExportToCsv,
  hasItems,
  loading,
  totalItems,
}: PaginationProps) {
  const [totalPages, setTotalPages] = useState(1)
  useEffect(() => {
    // We don't want totalPages to change during page changes
    if (!loading) {
      const newTotalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1
      setTotalPages(newTotalPages)
      if (currentPage > newTotalPages) {
        onPageChange(newTotalPages)
      }
    }
  }, [totalItems, itemsPerPage, loading])


  //             {/* <Icon name="download" /> */}
  return (
    <SolColumn md={12}>
      <Menu floated="right" pagination data-testid="page-navigation">
        <Menu.Item as="a" icon disabled={currentPage === 1} name="first" onClick={() => onPageChange(1)}>
          <Icon name="angle double left" />
        </Menu.Item>
        <Menu.Item
          as="a"
          icon
          disabled={currentPage === 1}
          name="previous"
          onClick={() => onPageChange(currentPage - 1)}
        >
          <Icon name="angle left" />
        </Menu.Item>
        <Menu.Item active>
          <div>
            Page {currentPage} of {totalPages > 0 ? totalPages : 1}
          </div>
        </Menu.Item>
        <Menu.Item
          as="a"
          icon
          disabled={currentPage === totalPages}
          name="next"
          onClick={() => onPageChange(currentPage + 1)}
        >
          <Icon name="angle right" />
        </Menu.Item>
        <Menu.Item
          as="a"
          icon
          disabled={currentPage === totalPages}
          name="last"
          onClick={() => onPageChange(totalPages)}
        >
          <Icon name="angle double right" />
        </Menu.Item>
      </Menu>
      <Menu floated="right" data-testid="items-per-page">
        <Menu.Item active>Items per page</Menu.Item>
        {itemsPerPageOptions.map((pageSize: PageSize) => (
          <Menu.Item
            key={pageSize}
            onClick={() => onPageSizeChange(pageSize)}
            header={pageSize === itemsPerPage}
            color={itemsPerPage === pageSize ? 'black' : undefined}
            name={pageSize.toString()}
          />
        ))}
      </Menu>
      {allowExportToCsv && (
        <div style={{ textAlign: 'left', paddingTop: '12px' }}>
          <SolButton
            className={styles.filterButton}
            disabled={!hasItems}
            handleClick={onExportToCsv}
            loading={exportProcessing}
            text={<>
              <Icon name="download" />
              <span> EXPORT TO CSV </span>
            </>}
          />
        </div>
      )}
    </SolColumn>
  )
}

export default Pagination
