import { defaultUniqueVlan } from 'pages/Templates/UniqueSettings/UniqueSettingsTypes'
import { UniqueSettingsConfiguration } from 'graphql/__generated__/types'

export const getVlanIpAddress = (i: number) => (
  uniqueSettings: UniqueSettingsConfiguration,
) => {
  const uniqueVlan = (uniqueSettings?.ETHERNET?.vlans ?? [])[i] ?? defaultUniqueVlan
  return uniqueVlan.ipAddress ?? ''
}

export const setVlanIpAddress = (i: number) => (
  uniqueSettings: UniqueSettingsConfiguration,
  ipAddress: string,
) => {
  const uniqueVlan1
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[0] ?? { ...defaultUniqueVlan }
  const uniqueVlan2
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[1] ?? { ...defaultUniqueVlan }
  const uniqueVlan3
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[2] ?? { ...defaultUniqueVlan }
  const vlans = [uniqueVlan1, uniqueVlan2, uniqueVlan3]
  vlans[i] = { ...vlans[i], ipAddress }
  return {
    ETHERNET: {
      ...uniqueSettings?.ETHERNET,
      vlans,
    },
  }
}

export const getIpAddress = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.ETHERNET?.ipAddress ?? ''

export const setIpAddress = (
  uniqueSettings: UniqueSettingsConfiguration,
  ipAddress: string,
) => ({
  ETHERNET: {
    ...uniqueSettings.ETHERNET,
    ipAddress,
  },
})

export const getDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
) => uniqueSettings?.ETHERNET?.hostname ?? ''

export const setDnsHostname = (
  uniqueSettings: UniqueSettingsConfiguration,
  hostname: string,
) => ({
  ETHERNET: {
    ...uniqueSettings.ETHERNET,
    hostname,
  },
}
)

export const getVlanDnsHostname = (i: number) => (
  uniqueSettings: UniqueSettingsConfiguration,
) => {
  const uniqueVlan = (uniqueSettings?.ETHERNET?.vlans ?? [])[i] ?? defaultUniqueVlan
  return uniqueVlan.hostname ?? ''
}

export const setVlanDnsHostname = (i: number) => (
  uniqueSettings: UniqueSettingsConfiguration,
  hostname: string,
) => {
  const uniqueVlan1
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[0] ?? { ...defaultUniqueVlan }
  const uniqueVlan2
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[1] ?? { ...defaultUniqueVlan }
  const uniqueVlan3
    = (uniqueSettings?.ETHERNET?.vlans ?? [])[2] ?? { ...defaultUniqueVlan }
  const vlans = [uniqueVlan1, uniqueVlan2, uniqueVlan3]
  vlans[i] = { ...vlans[i], hostname }
  return {
    ETHERNET: {
      ...uniqueSettings?.ETHERNET,
      vlans,
    },
  }
}
