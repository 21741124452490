import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Template, FeaturesConfiguration } from 'graphql/__generated__/types'
import styles from '../Features.module.scss'
import {
  SolCheckbox,
  SolTooltip,
  SolNotification,
  SolIconNotification,
  SolConfirmationModal,
} from 'SolComponents'
import { SolWifiIcon } from 'SolComponents/Icons'
import { NullaryFn } from 'shared/types/common'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: FeaturesConfiguration) => void
}

const WiFiNotification = () => (
  <SolIconNotification
    icon={SolWifiIcon}
    text="Miracast requires WiFi to be enabled."
    className={styles.secondNotification}
  />
)


const ConfirmationModal = (props: {
  confirm: NullaryFn
  close: NullaryFn
}) => (
  <SolConfirmationModal
    isOpen
    modalText="Make sure WiFi is enabled"
    subHeaderText={[
      'Miracast requires WiFi to be enabled. Enable wireless settings',
      'in the WiFi template for your Pod(s) before enabling Miracast.',
    ]}
    acceptingText="OK"
    onCloseModal={props.close}
    onAcceptingAction={() => {
      props.confirm()
      props.close()
    }}
    hideCancel
  />
)


function BasicOptions({ updateConfiguration, template }: Props) {
  const { settings54 = false } = useFlags()
  const configuration = template.revision.configuration as FeaturesConfiguration
  const [confirm, setConfirm] = useState<() => NullaryFn>()

  return (
    <div>
      {!!confirm && (
        <ConfirmationModal
          confirm={confirm}
          close={() => setConfirm(undefined)}
        />
      )}
      <h4 className={styles.header}>Solstice Feature Options</h4>
      <div className={styles.options}>
        <SolCheckbox
          label="Enable screen key"
          checked={!!configuration.enableScreenKey}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              enableScreenKey: !configuration.enableScreenKey,
            })
          }}
          bold={false}
        />
        <SolCheckbox
          label="Speak screen key"
          checked={!!configuration.speakScreenKey}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              speakScreenKey: !configuration.speakScreenKey,
            })
          }}
          bold={false}
        />
        <SolTooltip
          text="If checked, users will not be able to enable moderator mode within the Solstice app."
          hoverable
          position="top left"
          trigger={
            <SolCheckbox
              label="Disallow Moderator Mode"
              checked={configuration.disallowModeratorMode === true}
              onClick={val => {
                updateConfiguration({
                  ...configuration,
                  disallowModeratorMode: val,
                })
              }}
              bold={false}
            />
          }
        />
        <SolCheckbox
          label="Enable Miracast WiFi Direct"
          checked={!!configuration.enableMiracastWifiDirect}
          notification={[
            <SolNotification key={0} type="menu" notifications="Gen 2i+" />,
            <WiFiNotification key={1} />,
          ]}
          onClick={() => {
            const update = () => updateConfiguration({
              ...configuration,
              enableMiracastWifiDirect: !configuration.enableMiracastWifiDirect,
            })
            if (configuration.enableMiracastWifiDirect) {
              update()
            } else {
              setConfirm(() => update)
            }
          }}
          bold={false}
        />
        <SolCheckbox
          label="Enable Miracast Infrastructure"
          checked={!!configuration.enableMiracastInfrastructure}
          notification={[
            <SolNotification key={0} type="menu" notifications="Gen 2i+" />,
            <WiFiNotification key={1} />,
          ]}
          onClick={() => {
            const update = () => {
              updateConfiguration({
                ...configuration,
                enableMiracastInfrastructure: !configuration.enableMiracastInfrastructure,
              })
            }
            if (configuration.enableMiracastInfrastructure) {
              update()
            } else {
              setConfirm(() => update)
            }
          }}
          bold={false}
        />
        <SolCheckbox
          label="Enable Android mirroring"
          checked={!!configuration.enableAndroidMirroring}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              enableAndroidMirroring: !configuration.enableAndroidMirroring,
            })
          }}
          bold={false}
        />
        <SolCheckbox
          label="Enable iOS mirroring (AirPlay)"
          checked={!!configuration.enableAirplay}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              enableAirplay: !configuration.enableAirplay,
              enableAirplayProxy: !configuration.enableAirplay && configuration.enableAirplayProxy,
              enableAirplayBluetooth: !configuration.enableAirplay && configuration.enableAirplayBluetooth,
            })
          }}
          bold={false}
        />
        <div className={styles.airplayProxy}>
          <SolCheckbox
            label="Enable AirPlay discovery proxy"
            checked={!!configuration.enableAirplayProxy}
            onClick={() => {
              updateConfiguration({
                ...configuration,
                enableAirplayProxy: !configuration.enableAirplayProxy,
              })
            }}
            disabled={!configuration.enableAirplay}
            bold={false}
          />
          <SolCheckbox
            label="Enable Bluetooth discovery for AirPlay"
            checked={!!configuration.enableAirplayBluetooth}
            notification={[
              <SolNotification key={0} type="menu" notifications="v4.6+" />,
              <SolNotification key={1} className={styles.secondNotification} type="menu" notifications="Gen 3+" />,
            ]}
            onClick={() => {
              updateConfiguration({
                ...configuration,
                enableAirplayBluetooth: !configuration.enableAirplayBluetooth,
              })
            }}
            disabled={!configuration.enableAirplay}
            bold={false}
          />
        </div>
        <div className={styles.browserless}>
          <SolCheckbox
            label="Enable Web Sharing"
            checked={!!configuration.enableWebRTC}
            notification={<SolNotification type="menu" notifications="Gen 2i+" />}
            onClick={() => {
              updateConfiguration({
                ...configuration,
                enableWebRTC: !configuration.enableWebRTC,
              })
            }}
            bold={false}
          />
        </div>
        {settings54 && (
          <SolCheckbox
            label="Route audio to HDMI Out"
            checked={!!configuration.usbAudioToHdmiEnabled}
            notification={[
              <SolNotification key={0} type="menu" notifications="v5.4+" />,
              <SolNotification key={1} className={styles.secondNotification} type="menu" notifications="Gen 3+" />,
            ]}
            onClick={() => {
              updateConfiguration({
                ...configuration,
                usbAudioToHdmiEnabled: !configuration.usbAudioToHdmiEnabled,
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default BasicOptions
