import { PowerManagementControlTypes, SettingsGroupType, Template } from '../../../graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultPowerManagementTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'PowerManagement',
    type: SettingsGroupType.PowerManagement,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enableOffHours: false,
      occupancyEnabled: false,
      occupancyDelayMinutes: 5,
      control: {
        controlMethod: PowerManagementControlTypes.Hdmi,
        commandDisplayOn: '',
        commandDisplayOff: '',
      },
      weekday: {
        suspendAfter: 30,
        allDay: false,
        startTime: '19:00',
        endTime: '06:00',
      },
      weekend: {
        suspendAfter: 30,
        allDay: true,
        startTime: '19:00',
        endTime: '06:00',
      },
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}

export const suspendOptions = [
  {
    key: 0,
    text: 'Immediate',
    value: 0,
  },
  {
    key: 1,
    text: '1 min',
    value: 1,
  },
  {
    key: 2,
    text: '5 min',
    value: 5,
  },
  {
    key: 3,
    text: '10 min',
    value: 10,
  },
  {
    key: 4,
    text: '15 min',
    value: 15,
  },
  {
    key: 5,
    text: '30 min',
    value: 30,
  },
  {
    key: 6,
    text: '1 hr',
    value: 60,
  },
  {
    key: 7,
    text: '2 hr',
    value: 120,
  },
]

export const delayOptions = [
  {
    key: 0,
    text: 'Immediate',
    value: 0,
  },
  {
    key: 1,
    text: '5 min',
    value: 5,
  },
  {
    key: 3,
    text: '10 min',
    value: 10,
  },
  {
    key: 4,
    text: '15 min',
    value: 15,
  },
]
