import React, { useState, useEffect, ReactNode } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import classNames from 'classnames'

import styles from './SolExpandableCard.module.scss'
import { SolNotification } from 'SolComponents'

export interface Props {
  children?: ReactNode
  title?: string
  content?: JSX.Element | string
  rightActionItems?: JSX.Element | string
  icon?: 'info' | 'menu' | 'warning'
  locked?: boolean
  static?: boolean
  disabled?: boolean
  expanded?: boolean
  checkExpanded?: (open: boolean) => void
  dropdownItems?: JSX.Element | boolean
  // if Passed in as JSX element, must be enclosed in spans to ensure they are on same line as the title
}

export const SolExpandableCard = (props: Props) => {
  const [cardExpanded, setCardExpanded] = useState(props.expanded || false)

  useEffect(() => {
    if (props.checkExpanded) {
      props.checkExpanded(cardExpanded)
    }
  })

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!props.locked && !props.static && !props.disabled) {
      setCardExpanded(!cardExpanded)
      e.stopPropagation()
    }
  }
  return (
    <Accordion
      className={classNames(styles.content, {
        [styles.disabled]: props.disabled,
      })}
      onClick={() => {
        if (props.locked && !props.disabled) {
          setCardExpanded(!cardExpanded)
        }
      }}
    >
      <Accordion.Title
        active={cardExpanded}
        onClick={handleClick}
        className={classNames(styles.titleBar, {
          [styles.static]: props.static,
        })}
      >
        <span className={styles.title}>
          {props.icon && <SolNotification type={props.icon} />}
          {props.title} {props.content}
        </span>
        <div className={styles.iconWrapper}>
          {props.dropdownItems && <span>{props.dropdownItems}</span>}
          {!props.static && !props.disabled && (
            <Icon name="chevron down" size="large" className={styles.accordionIcon} onClick={handleClick} />
          )}
        </div>
        <span className={styles.actionItems}>{props.rightActionItems}</span>
      </Accordion.Title>
      <Accordion.Content active={cardExpanded} className={styles.accordionContent}>
        {cardExpanded && props.children}
      </Accordion.Content>
    </Accordion>
  )
}

export default SolExpandableCard
