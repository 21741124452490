import React, { useState } from 'react'
import { Table, Radio, Icon } from 'semantic-ui-react'
import find from 'lodash/find'
import { AlertFilterSelect } from './AlertFilterSelect'
import { AlertFilterPills } from './AlertFilterPills'
import { Filter, FilterType } from './types'
import { Category } from 'shared/types/categories'
import styles from './Alerts.module.scss'
import { useInsertAlertFilter } from './hooks/useInsertAlertFilters'
import { useDeleteAlertFilters } from './hooks/useDeleteAlertFilters'

interface Props {
  enabled: boolean
  filteredDisplaysCount: number
  displayCount: number
  categories: Category[]
  columns: (string | JSX.Element)[]
  filters: Filter[]
  filterType: FilterType
  setEnabled: (enabled: boolean) => void
}

export const AlertRow = (props: Props) => {
  const insertAlertFilter = useInsertAlertFilter()
  const deleteAlertFilters = useDeleteAlertFilters()

  const [filterVisible, setFilterVisible] = useState(false)

  const handleCheckbox = ({}, data: { checked: boolean }) => {
    props.setEnabled(data.checked)
  }

  const handleFilterSelectToggle = () => {
    setFilterVisible(!filterVisible)
  }

  const handleFilterToggle = (filter: Partial<Filter>, forceRemove: boolean = false) => {
    const filterExists: boolean = !!find(props.filters, filter)

    if (filterExists || forceRemove) {
      deleteAlertFilters(props.filterType, filter)
    } else {
      insertAlertFilter(props.filterType, filter)
    }
  }

  const handleRemoveAllFilters = () => {
    deleteAlertFilters(props.filterType, {})
  }

  return (
    <Table.Row>
      <Table.Cell className={styles.toggleCell}>
        <Radio toggle value={props.filterType} checked={props.enabled} onChange={handleCheckbox} />
      </Table.Cell>

      <Table.Cell>
        <div className={styles.flexRow}>
          {props.columns.map((column, i) => (
            <div key={i} className={styles.flexColumn}>
              {column}
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          <div className={styles.flexColumn}>
            <span className={props.enabled ? styles.enabledForActive : styles.enabledForInactive}>
              {`Enabled for ${props.filteredDisplaysCount}/${props.displayCount} pods`}
            </span>
          </div>
        </div>
        <div className={filterVisible ? styles.accordionActive : styles.accordionInactive}>
          <AlertFilterSelect
            categories={props.categories as Category[]}
            filters={props.filters}
            onSelect={filter => handleFilterToggle(filter)}
          />
        </div>
        {props.filters.length > 0 && (
          <AlertFilterPills
            categories={props.categories as Category[]}
            filters={props.filters}
            removeFilter={filter => handleFilterToggle(filter, true)}
            removeAll={handleRemoveAllFilters}
            editing={filterVisible}
            active={props.enabled}
          />
        )}
      </Table.Cell>

      <Table.Cell className={styles.toggleCell}>
        <a className={props.enabled ? styles.anchorActive : styles.anchorInactive} onClick={handleFilterSelectToggle}>
          filter&nbsp;
          <Icon name={filterVisible ? `close` : `filter`} className="small" />
        </a>
      </Table.Cell>
    </Table.Row>
  )
}
