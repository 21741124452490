import React from 'react'
import { Form } from 'semantic-ui-react'
import { isEmail } from 'validator'

import { FlowButtons } from 'components/Maintenance/Renewal/Partials'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'
import { MxRenewLeadDisplayInterface } from 'shared/services/mxRenew/types'

export const style = {
  page: {
    padding: '10px',
    marginTop: '-15px',
  } as React.CSSProperties,
  star: {
    color: 'red',
  } as React.CSSProperties,
}

export interface Props {
  email: string
  updateLead: (
    id: MxRenewLeadId,
    update: Partial<MxRenewLeadInterface>,
    nextStep: number
  ) => void
  nextStep: number
  cancelFlow: () => void
  currentLead: MxRenewLeadInterface
  selectedPods: { [key: string]: MxRenewLeadDisplayInterface }
}

export interface State {
  firstName: string
  lastName: string
  primaryEmail: string
  secondaryEmail: string
  phoneNumber: string
  orgName: string
  isValidPrimaryEmail: boolean
  isValidSecondaryEmail?: boolean
  formIsValid: boolean
}

// allows (), +, spaces, ., and x, ext, extension for extension.
// does not allow non phone numbers, should for domestic and international numbers

/* eslint-disable-next-line max-len */
const phoneValidation = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/

export default class InformationForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.phoneError = this.phoneError.bind(this)
    this.isValid = this.isValid.bind(this)
    const {
      userInformation: {
        firstName = null,
        lastName = null,
        primaryEmail = this.props.email,
        secondaryEmail = null,
        phoneNumber = null,
        orgName = null,
      } = {},
    } = this.props.currentLead

    const state = {
      firstName,
      lastName,
      primaryEmail,
      secondaryEmail,
      phoneNumber,
      orgName,
      isValidPrimaryEmail: true,
      isValidSecondaryEmail: true,
      formIsValid: false,
    }

    state.formIsValid = this.isValid(state)
    this.state = state
  }

  isValid({
    firstName,
    lastName,
    phoneNumber,
    orgName,
    isValidPrimaryEmail,
  }: State) {
    if (
      firstName
      && firstName.length > 0
      && lastName
      && lastName.length > 0
      && phoneNumber
      && phoneNumber.length > 0
      && phoneNumber.match(phoneValidation)
      && orgName
      && orgName.length > 0
      && isValidPrimaryEmail
    ) {
      return true
    }
    return false
  }

  phoneError() {
    if (
      this.state.phoneNumber.length > 0
      && !this.state.phoneNumber.match(phoneValidation)
    ) {
      return <span style={style.star}> Error</span>
    }
    return
  }

  handleSubmit() {
    const {
      firstName,
      lastName,
      primaryEmail,
      secondaryEmail,
      phoneNumber,
      orgName,
    } = this.state

    const { nextStep, updateLead, currentLead, selectedPods } = this.props

    const info: object = {
      firstName,
      lastName,
      primaryEmail,
      secondaryEmail,
      phoneNumber,
      orgName,
    }

    updateLead(
      currentLead.id,
      {
        ...currentLead,
        step: nextStep,
        userInformation: info,
        selectedExpiredPods: Object.keys(
          currentLead.selectedExpiredPods || {},
        ).reduce((acc, key) => {
          acc[key] = selectedPods[key]
          return acc
        }, {}),
        selectedOtherPods: Object.keys(
          currentLead.selectedOtherPods || {},
        ).reduce((acc, key) => {
          acc[key] = selectedPods[key]
          return acc
        }, {}),
        quotedPrice: `$${Object.values(selectedPods)
          .reduce((acc, current) => {
            return acc + current.price
          }, 0)
          .toFixed(2)}`,
      },
      nextStep,
    )
  }

  handleOnChange(event: any, { field, value }: any) {
    this.setState(
      (prevState: State) => ({
        ...prevState,
        [field]: value,
        isValidPrimaryEmail:
          field === 'primaryEmail'
            ? isEmail(value)
            : prevState.isValidPrimaryEmail,
        isValidSecondaryEmail:
          field === 'secondaryEmail'
            ? isEmail(value)
            : prevState.isValidSecondaryEmail,
      }),
      () => {
        this.setState({ formIsValid: this.isValid(this.state) })
      },
    )
  }

  handleCancel() {
    const {
      firstName,
      lastName,
      primaryEmail,
      secondaryEmail,
      phoneNumber,
      orgName,
    } = this.state

    const { nextStep, updateLead, currentLead, cancelFlow } = this.props

    const info: object = {
      firstName,
      lastName,
      primaryEmail,
      secondaryEmail,
      phoneNumber,
      orgName,
    }

    updateLead(
      currentLead.id,
      {
        ...currentLead,
        userInformation: info,
        orderStatus: 'cancelled',
      },
      nextStep - 1,
    )

    cancelFlow()
  }

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      primaryEmail,
      secondaryEmail,
      orgName,
      isValidSecondaryEmail,
    } = this.state

    return (
      <React.Fragment>
        <Form style={style.page} onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Input
              label="First Name *"
              placeholder="First Name"
              width={10}
              field="firstName"
              value={firstName || ''}
              error={firstName !== null && firstName.length === 0}
              onChange={this.handleOnChange}
              maxLength={40}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Last Name *"
              placeholder="Last Name"
              width={10}
              field="lastName"
              value={lastName || ''}
              error={lastName !== null && lastName.length === 0}
              onChange={this.handleOnChange}
              maxLength={40}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Email *"
              type="email"
              placeholder={this.props.email}
              width={10}
              field="primaryEmail"
              value={primaryEmail || ''}
              onChange={this.handleOnChange}
              error={!this.state.isValidPrimaryEmail}
              maxLength={40}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Email #2 (optional)"
              placeholder="Email #2"
              width={10}
              field="secondaryEmail"
              value={secondaryEmail || ''}
              error={!isValidSecondaryEmail}
              onChange={this.handleOnChange}
              maxLength={40}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              type="tel"
              label="Phone Number *"
              pattern="^[0-9-+s()]*$"
              placeholder="Phone Number"
              width={10}
              field="phoneNumber"
              style={style.star}
              value={this.state.phoneNumber || ''}
              error={
                (phoneNumber !== null && phoneNumber.length === 0)
                || (phoneNumber !== null && !phoneNumber.match(phoneValidation))
              }
              onChange={this.handleOnChange}
              maxLength={40}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Organization Name *"
              placeholder="Organization Name"
              width={10}
              field="orgName"
              value={orgName || ''}
              error={orgName !== null && orgName.length === 0}
              onChange={this.handleOnChange}
              maxLength={40}
            />
          </Form.Group>
        </Form>
        <FlowButtons
          next={this.handleSubmit}
          disabled={!this.state.formIsValid}
          nextText="Next"
          cancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}
