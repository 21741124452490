export const externalLinks = {
  terms: 'https://www.mersive.com/kepler-terms-use/',
  privacy: 'https://www.mersive.com/privacy/',
  roomServices:
    'https://documentation.mersive.com/en/deploy-conferencing.html',
  locationServices:
    'https://documentation.mersive.com/content/topics/info-location-services.htm',
  occupancyData:
    'https://documentation.mersive.com/en/occupancy-sensing.html',
  discovery:
    'https://documentation.mersive.com/content/topics/info-solstice-discover.htm',
}

export const mxRenewalConstants = {
  numDaysToDismissBanner: 7,
}
