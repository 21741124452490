import React from 'react'
import classNames from 'classnames'
import DeleteIcon from 'mdi-react/TrashCanOutlineIcon'
import styles from './SolDeleteIcon.module.scss'

const SolDeleteIconAdvanced = (props: {
  isLink?: boolean
  className?: string
  dataTestId?: string
  onClick?: (event?: any) => void
}) => (
  <DeleteIcon
    data-testid={props.dataTestId}
    className={classNames(
      styles.icon,
      props.className,
      {
        [styles.isLink]: props.isLink !== false,
      },
    )}
    onMouseDown={props.onClick}
  />
)

export default SolDeleteIconAdvanced
