import React, { memo } from 'react'
import classNames from 'classnames'
import {
  SolDropdown,
  SolTooltip,
} from 'SolComponents'
import styles from './AllPodsTable.module.scss'
import { Icon } from 'semantic-ui-react'
import { SettingsGroupType } from 'graphql/__generated__/types'
const pendingClock = require('assets/pendingClock.png')

const TemplateDropDown = memo((
  {
    options,
    hasPendingChanges,
    syncState,
    currentTemplate,
    onChange,
    settingsGroupType,
    loading,
    row,
    getTemplateCompatibility,
  }:
  {
    options: any[]
    hasPendingChanges?: boolean
    syncState?: string
    currentTemplate: any
    onChange: (args: any) => void
    settingsGroupType: SettingsGroupType
    loading: boolean
    row: any
    getTemplateCompatibility: (id: string) => void
  },
) => {
  return (
    <div>
      {hasPendingChanges && (
        <div className={styles.pendingClock}>
          <SolTooltip
            isInline
            text="This template has pending changes that are being applied."
            position="top left"
            trigger={<img src={pendingClock} />}
          />
        </div>
      )}
      {(syncState === 'mismatched' || syncState === 'rejected') && (
        <div className={styles.templateError}>
          <SolTooltip
            text="Template could not be applied to this Pod. Please review Pod's template settings and try again."
            position="top left"
            trigger={<Icon name="exclamation circle" color="red" />}
          />
        </div>
      )}
      <SolDropdown
        loading={loading}
        itemsLabel="Pods"
        type="bulk"
        light
        direction="left"
        options={options}
        text={currentTemplate?.name ?? 'Unassigned'}
        value={currentTemplate?.id ?? null}
        className={classNames(styles.podTemplate, {
          [styles.unassigned]: !currentTemplate?.name,
        })}
        containerClassName={classNames({
          [styles.pendingChange]: hasPendingChanges || (syncState === 'mismatched' || syncState === 'rejected'),
        })}
        size="fluid"
        menuSize="medium"
        onChange={value => {
          const name = options?.find((o: any) => o.value === value)?.text ?? ''
          onChange({
            row,
            settingsGroupType: settingsGroupType,
            value,
            name,
          })
        }}
        onFocus={() => {
          if (settingsGroupType === SettingsGroupType.WelcomeScreen) {
            getTemplateCompatibility(row.id)
          }
        }}
      />
    </div>
  )
})

export default TemplateDropDown
