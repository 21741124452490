import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarOpen: false,
  updatesBanner: false,
  endOfLifeBanner: false,
  showUnussigTemplateCheckbox: localStorage.getItem('showUnussigTemplateCheckbox') ?? true,
}
export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    toggleSidebar: state => {
      state.sidebarOpen = !state.sidebarOpen
    },
    closeSidebar: state => {
      state.sidebarOpen = false
    },
    setUpdatesBanner: (state, action) => {
      state.updatesBanner = action.payload
    },
    setEndOfLifeBanner: (state, action) => {
      state.endOfLifeBanner = action.payload
    },
    setShowUnussigTemplateCheckbox: (state, action) => {
      state.showUnussigTemplateCheckbox = !action.payload
      localStorage.setItem('showUnussigTemplateCheckbox', action.payload ? 'false' : 'true')
    },
  },
})

export const {
  toggleSidebar,
  closeSidebar,
  setUpdatesBanner,
  setEndOfLifeBanner,
  setShowUnussigTemplateCheckbox,
} = coreSlice.actions


export default coreSlice.reducer
