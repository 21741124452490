import React from 'react'
import classNames from 'classnames'

import styles from './Chart.module.scss'
import { Popup } from 'semantic-ui-react'

export interface Props {
  id: string
  label: string
  checked: boolean
  color: string
  handleClick: (value: any) => void
  disabled: boolean
  tooltip?: string
}

export interface State {
  checked: boolean
}

export default function OptionCheckbox(props: Props) {
  const classes = classNames(styles.checkbox, {
    [styles.checked]: props.checked || props.disabled,
    [styles.unchecked]: !props.checked && !props.disabled,
  })
  const checkbox = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      transform="scale(0.875)"
    >
      <rect
        className={styles.square}
        x="2"
        y="2"
        width="20"
        height="20"
        rx="2"
        ry="2"
        style={{
          fill: props.checked ? props.color : '#E9EAEA',
          stroke: props.checked ? props.color : '#E9EAEA',
          strokeWidth: `2px`,
        }}
      />
      <path
        className={styles.tick}
        d="M6,6 v8 h16"
        stroke="#EFEFEF"
        strokeWidth="4"
        fill="none"
        transform="rotate(-45, 12, 12)"
      />
    </svg>
  )

  return (
    <div role="checkbox" className={classes} aria-checked="false" onClick={() => props.handleClick(props.id)}>
      <input id={props.id} type="checkbox" style={{ display: 'none' }} />
      {props.disabled && <Popup content={props.tooltip} trigger={checkbox} />}
      {!props.disabled && checkbox}
      <label className={styles.pointer} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
}
