import React from 'react'
import { Button } from 'semantic-ui-react'
import classNames from 'classnames'

import styles from './SolButtonGroup.module.scss'

export type SolButtonGroupProps = {
  primary: boolean
  disabled?: boolean
  value: string
  content: React.ReactNode
  onClick: (value: any) => void
}[]

export interface Props {
  buttons: SolButtonGroupProps
  label?: string
  className?: string
}

export const SolButtonGroup = (props: Props) => {
  return (
    <div className={classNames(styles.buttonGroup, props.className)}>
      {!!props.label && <label>{props.label}</label>}
      <Button.Group>
        {props.buttons.map(button => (
          <Button
            disabled={button.disabled}
            key={button.value}
            content={button.content}
            primary={button.primary}
            onClick={e => {
              e.preventDefault()
              button.onClick(button.value)
            }}
          />
        ))}
      </Button.Group>
    </div>
  )
}

export default SolButtonGroup
