import React from 'react'
import Dialog from 'react-modal'
import classNames from 'classnames'
import styles from './SolModal.module.scss'
import SolCloseIcon from 'SolComponents/Icons/SolCloseIcon/SolCloseIcon'

export interface Props {
  isOpen: boolean
  children?: any
  onCloseModal: () => void
  showCloseIcon?: boolean
  className?: string
}

export function SolModal({ isOpen, onCloseModal, showCloseIcon = true, className, children }: Props) {
  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={classNames(styles.modalContent, className)}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal}>
        {showCloseIcon && (
          <div style={{ textAlign: 'right' }}>
            <SolCloseIcon className="closeIcon" onClick={onCloseModal} />
          </div>
        )}
        {children}
      </div>
    </Dialog>
  )
}

Dialog.setAppElement('body')

export default SolModal
