import React from 'react'
import { SolButton, SolTooltipWithLink } from 'SolComponents'
import {
  useActiveLearningFlagsSubscription,
  useUpsertActiveLearningFlagsMutation,
} from 'graphql/__generated__/hasura-types'
import styles from './ActiveLearning.module.scss'

export function ScreenKeyVerification() {
  const { data } = useActiveLearningFlagsSubscription()
  const [updateActiveLearningFlag] = useUpsertActiveLearningFlagsMutation()
  const screenKeyEnabled = data?.active_learning_flags?.[0]?.screen_key_enabled ?? false
  return (
    <div className={styles.screenKey}>
      Screen Key Verification:&nbsp;&nbsp;
      <SolTooltipWithLink
        trigger={<a href="#">{screenKeyEnabled ? 'ON' : 'OFF'}</a>}
        text={`Require Active Learning users to enter the screen key of any of the
          displays in an Active Learning room prior to routing content`}
        buttonText={
          <SolButton
            text={`TURN ${screenKeyEnabled ? 'OFF' : 'ON'}`}
            color="blue"
            basic
            isLink
            handleClick={() => {
              updateActiveLearningFlag({
                variables: { screen_key_enabled: !screenKeyEnabled },
              })
            }}
          />
        }
        position="bottom center"
      />
    </div>
  )
}
