import {
  InstructionOption,
  ScreenOption,
  SettingsGroupType,
  Template,
} from 'graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultDigitalSignageTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'DigitalSignage',
    type: SettingsGroupType.DigitalSignage,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enabled: false,
      url: 'https://digitalsignage.mersive.com',
      startAfter: 30,
      screenOption: ScreenOption.FooterOnly,
      instructionOption: InstructionOption.NetworkOnly,
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
