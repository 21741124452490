import React from 'react'
import FilterIcon from 'mdi-react/FilterIcon'
import SolButton from 'SolComponents/SolButton/SolButton'
import styles from './FilterButton.module.scss'

type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const FilterButton = ({ onClick, disabled }: Props) => (
  <SolButton
    className={styles.filterButton}
    handleClick={onClick}
    text={
      <>
        <FilterIcon color="#1279C6" size={17} />
        <span> FILTER</span>
      </>
    }
    disabled={disabled}
  />
)

export default FilterButton
