import { MutableRefObject, useEffect } from 'react'

const useClickOutside = (ref: MutableRefObject<any>, handler: () => void, exceptions: Array<String> = []) => {
  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = event => {
      if (
        ref.current
        && !ref.current.contains(event.target)
        && !exceptions.some(exception => event?.target instanceof Element && exception === event.target?.className)
      ) {
        handler()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handler, ref])
}

export default useClickOutside
