import React from 'react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { ViewActivityUserMobilityResult } from 'graphql/__generated__/hasura-types'

import styles from './index.module.scss'
import { TestingProps } from 'shared/types/TestingProps'
import { SolCard } from 'SolComponents'
import SolChartLegend from 'SolComponents/SolChartLegend/SolChartLegend'
import { toPercentage } from 'shared/core/utils'

interface ActivityUserMobilityChartProps extends TestingProps {
  data: ViewActivityUserMobilityResult[]
  isLoading?: boolean
  dataTestId: string
}

const teal = '#3BB6C5'
const sessionColor = '#F4B656'
const labelsColors = [
  {
    id: 'USERCOUNT',
    text: 'User Count',
    color: teal,
  },
  {
    id: 'SESSIONS',
    text: 'Sessions',
    color: sessionColor,
  },
]

const ActivityUserMobilityChart = ({ data, dataTestId }: ActivityUserMobilityChartProps) => {
  const singleGuest = data.find(group => {
    return group.group === '1 - Guest'
  })

  const totalCount = data.reduce((prev, cur) => {
    return prev + cur.count
  }, 0)

  const totalSessionCount = data.reduce((prev, cur) => {
    return prev + cur.sessions
  }, 0)

  const userMobilityFormatted = data
    .filter(userMobItem => userMobItem.group !== '1 - Guest')
    .map(userMobilityItem => {
      if (userMobilityItem.group === '1 - Single Room') {
        const singleGuestCount = singleGuest?.count || 0
        const singleGuestSessions = singleGuest?.sessions || 0
        return {
          group: userMobilityItem.group,
          roomCount: userMobilityItem.count + singleGuestCount,
          sessionCount: userMobilityItem.sessions + singleGuestSessions,
        }
      }
      return {
        group: userMobilityItem.group,
        roomCount: userMobilityItem.count,
        sessionCount: userMobilityItem.sessions,
      }
    })

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active: boolean
    payload: {
      payload: {
        roomCount: number
        sessionCount: number
      }
    }[]
    label: string
  }) => {
    if (active && payload && payload.length > 0) {
      const group = label
      const value = payload[0].payload.roomCount
      const percentage = toPercentage(value, totalCount, 1)
      let tooltipLbl = ''
      if (label === '1 - Single Room') {
        tooltipLbl = `only visited a single room.`
      } else {
        tooltipLbl = `visited ${group} separate rooms during the time period.`
      }
      const sessionValue = payload[0].payload.sessionCount
      const percentageSession = toPercentage(sessionValue, totalSessionCount, 1)
      return (
        <div className={styles.customTooltip}>
          <p>
            <strong>{`${percentage}% of users `}</strong>
            {tooltipLbl}
          </p>
          <p>
            Those users accounted for <strong>{`${percentageSession}%`}</strong> overall meeting engagement.
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <div className={styles.userMobilityChart} data-testid={dataTestId}>
      <SolCard className={styles.card} header={<div className={styles.header}> Activity by User Mobility </div>}>
        <div className={styles.subTitle}> How does user mobility correlate with user engagement? </div>
        <div className={styles.cardContent}>
          <div className={styles.chartContainer}>
            <div className={styles.chart}>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={userMobilityFormatted} data-testid="activity-mobility-bar-chart">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="group" />
                  <YAxis />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey="roomCount" stackId="a" fill={teal} />
                  <Bar dataKey="sessionCount" stackId="b" fill={sessionColor} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className={styles.vendorsLegend}>
          <SolChartLegend labels={labelsColors} />
        </div>
      </SolCard>
    </div>
  )
}

export default ActivityUserMobilityChart
