import React, { useState, useEffect, useCallback } from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { isEmail } from 'validator'
const solsticeCloudLoginLogo = require('../../assets/solstice-cloud-login.png')
import styles from './Login.module.scss'
import { useAlerts } from '../../shared/hooks/useAlerts'
import LoginForm from './components/LoginForm'
import ResetPassword from './components/ResetPassword'
import { ConnectedSolAlertMessage } from 'SolComponents'
import { AxiosError } from 'axios'
import Password from './components/Password'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useRecordLastLoginMutation } from 'graphql/__generated__/types'

export const style = {
  heading: {
    fontWeight: 400,
    fontSize: '3rem',
    marginTop: '-10%',
  },
}

export interface Props {
  me: {
    token: string
  }
  authenticate: (data: { email: string; password: string }) => any
  authenticateWithSSO: (data: { email: string }) => any
  resetPassword: (email: string) => Promise<any>
  completeSSO: (token: string) => Promise<void>
  getSsoStatus: (email: string) => Promise<any>
  history: any
  location: any
}

function Login(props: Props & RouteComponentProps) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [ssoLoading, setSsoLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [step, setStep] = useState(1)
  const [orgSsoEnabled, setSsoEnabled] = useState(false)

  // ldClient allows up to update launch darkly with an updated org so that flags can be targeted to specific orgs
  // this normaly happens later but we want targetting for sso 
  const client = useLDClient()
  const { checkSsoStatus } = useFlags()

  const queryParams = new URLSearchParams(props.location.search)
  const token = queryParams.get('token')
  const queryEmail = queryParams.get('email')

  const [recordLastLoginMutation] = useRecordLastLoginMutation()

  useEffect(() => {
    if (props.me.token) {
      props.history.push('/')
    }
  })

  useEffect(() => {
    if (token) {
      // pass token back to sso-service to complete sso sign in
      props.completeSSO(token)
        .then(() => {
          recordLastLoginMutation({ variables: {
            options: {
              email: email,
            },
          } })
        })
    }
  }, [token])

  useEffect(() => {
    if (queryEmail) {
      setEmail(queryEmail)
      props.history.replace('/login') // this path must match exactly to maintain the email state
    }
  }, [queryEmail])

  const updateEmail = useCallback(
    updatedEmail => {
      setError('')
      setEmail(updatedEmail)
    },
    [setError, setEmail],
  )

  const checkSsoOnly = async () => {
    if (!isEmail(email)) {
      setError('Invalid email')
      setLoading(false)
      return
    }
    if (checkSsoStatus) {
      setLoading(true)

      const orgData = await props.getSsoStatus(email).catch(() => {
        setSsoEnabled(false)
        setLoading(false)
        setStep(2)
        return
      })

      client?.identify({
        key: orgData?.org || 'unauthorized',
        name: orgData?.org || 'unauthorized',
      })

      if (orgData?.ssoOnly && orgData?.ssoEnabled) {
        ssoSignIn()
      } else if (orgData?.ssoOnly) {
        setError('Invalid SSO login')
        setLoading(false)
      } else {
        setSsoEnabled(orgData?.ssoEnabled || false)
        setLoading(false)
        setStep(2)
      }
    } else {
      setStep(2)
    }
  }

  const goToEmail = useCallback(() => setStep(1), [])

  const handleOnSubmit = () => {
    setError('')
    setLoading(true)

    props
      .authenticate({ email, password })
      .then(() => {
        recordLastLoginMutation({ variables: {
          options: {
            email: email,
          },
        } })
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 403 || err.response?.status === 500) {
          setError('Invalid email and/or password')
        }
        if (err.response?.status === 429) {
          setError('Too many tries with an invalid email and/or password')
        }
        setLoading(false)
      })
  }

  const { showSuccess, showError } = useAlerts()

  const handlePasswordReset = useCallback(
    (updatedEmail: string) => {
      setError('')
      setLoading(true)

      if (!isEmail(updatedEmail)) {
        setError('Invalid email')
        setLoading(false)
      } else {
        props
          .resetPassword(updatedEmail)
          .then(() => {
            props.history.push({
              pathname: '/login',
              state: { updatedEmail },
            })
            setError('')
            setLoading(false)
            return showSuccess('Reset password email sent ')
          })
          .catch(() => {
            setError('Unable to reset password')
            setLoading(false)
            showError('Unable to reset passowrd')
          })
      }
    },
    [setError, setLoading, props],
  )

  const ssoSignIn = () => {
    setSsoLoading(true)
    props
      .authenticateWithSSO({ email })
      .catch(() => {
        setError('Invalid email and/or password')
        setSsoLoading(false)
      })
  }

  return (
    <div className={styles.loginWrapper}>
      <ConnectedSolAlertMessage />
      {!token
        && <div className={styles.loginContainer}>
          <img draggable={false} className={styles.loginLogo} src={solsticeCloudLoginLogo} />
          <Switch>
            <Route exact path="/login">
              {step === 1 ? (
                <LoginForm
                  email={email}
                  setEmail={updateEmail}
                  error={error}
                  handleOnSubmit={checkSsoOnly}
                  passwordLoading={loading}
                />
              ) : (
                <Password
                  goToEmail={goToEmail}
                  password={password}
                  setPassword={setPassword}
                  email={email}
                  error={error}
                  handleOnSubmit={handleOnSubmit}
                  loading={loading}
                  ssoSignIn={ssoSignIn}
                  ssoLoading={ssoLoading}
                  ssoEnabled={orgSsoEnabled}
                />
              )}
            </Route>
            <Route exact path="/reset-password">
              <React.Fragment>
                <ResetPassword
                  error={error}
                  handleOnSubmit={handlePasswordReset}
                  loading={loading}
                  setError={setError}
                />
              </React.Fragment>
            </Route>
          </Switch>
        </div>
      }
    </div>
  )
}

export default Login
