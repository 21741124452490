import React from 'react'
import { Button } from 'semantic-ui-react'
import { SolModal } from 'SolComponents'
import styles from './InfoBanner.module.scss'

export interface Props {
  isOpen: boolean
  onClose: () => void
}

function InfoModal({ isOpen, onClose }: Props) {
  return (
    <SolModal isOpen={isOpen} onCloseModal={onClose} showCloseIcon={false}>
      <h3 className={styles.header}>
        We have become aware of certain issues impacting your experience and are working to resolve them:
      </h3>

      <ol className={styles.list}>
        <li>Pods losing template assignments</li>
        <li>Pods not accepting software updates</li>
        <li>Inaccurate reporting of online/offline status</li>
      </ol>
      
      <div className={styles.buttonContatiner}>
        <Button basic color="blue" size="large" onClick={onClose}>Close</Button>
      </div>
    </SolModal>
  )
}
  
export default InfoModal
