import { isEmail } from 'validator'
import { formValues, validationValues } from '../CreateOrg'

function validation(formState: formValues) {
  const passwordVal = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{10,})/
  const validationStatement: validationValues = {
    email: '',
    password: '',
    confirmPassword: '',
    termsAgree: '',
    genericError: '',
  }
  if (!formState.email.trim()) {
    validationStatement.email = 'Please enter your email address.'
  } else if (!isEmail(formState.email)) {
    validationStatement.email  = 'Please enter a valid email address.'
  }
  if (!formState.password.trim()) {
    validationStatement.password = 'Please enter a password.'
  } else if (!formState.password.match(passwordVal)) {
    // eslint-disable-next-line max-len
    validationStatement.password = 'Password must be at least 10 characters long, and have at least one uppercase character, one lowercase character, and one number.'
  }
  if (!formState.confirmPassword.trim()) {
    validationStatement.confirmPassword = 'Please confirm password.'
  } else if (formState.password !== formState.confirmPassword) {
    validationStatement.confirmPassword = 'Passwords do not match.'
  }
  if (!formState.termsAgree) {
    validationStatement.termsAgree = 'Please agree to the Terms and Service and Privacy Policy.'
  }
  return validationStatement
}

export default validation
