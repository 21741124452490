import React, { useState } from 'react'
import styles from './SolTemplateHeader.module.scss'
import { TemplateHeaderProps, ActionButtons } from './ActionButtons'
import { useHistory } from 'react-router'
import { useAssignTemplates, useUnassignTemplates } from 'shared/hooks/management/useTemplates'
import SolInput from 'SolComponents/SolInput/SolInput'
import SolStatus from 'SolComponents/SolStatus/SolStatus'
import SolDropdown from 'SolComponents/SolDropdown/SolDropdown'
import { SettingsGroupType, AssignTemplateMutation, ManagementStatus } from 'graphql/__generated__/types'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'
import SolButton from 'SolComponents/SolButton/SolButton'
import classNames from 'classnames'
import { FormValidation } from 'shared/context/FormValidation'
import UniqueSettings, { Props as UniqueSettingsProps } from 'pages/Templates/UniqueSettings/UniqueSettings'
import { SolConfirmationModal } from 'SolComponents'
import { useAlerts } from 'shared/hooks/useAlerts'
import { ExecutionResult } from 'graphql'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'
import { Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowUnussigTemplateCheckbox } from '../../../shared/store/slices/core'

type Confirmation = {
  modalOpen: boolean
  action?: 'assign' | 'unassign' | 'cancel'
  meta?: {
    displayId: string
    settingsGroupType: SettingsGroupType
    template: {
      id: string
      name: string
    }
    showClassicWarning: boolean
  }
}

type UniqueSettingsWorkflow = {
  drawerIsOpen: boolean
} & Omit<UniqueSettingsProps, 'onClose' | 'assignTemplates'>

export default function SolTemplateHeader(props: TemplateHeaderProps) {
  const {
    displayName,
    podId,
    handleCancelPending,
    isPending,
    isOnline,
    open,
    template,
    updateTemplate,
    view,
    isInModern,
    totalAssignedDisplays,
    locked,
    refetchDisplay,
    syncState,
  } = props
  const history = useHistory()
  const { showSuccess } = useAlerts()
  const updateDisplay = ({}, { data }: ExecutionResult<AssignTemplateMutation>) => {
    const validation = data?.assignTemplate?.validation
    if ((validation?.incompatibleDisplays?.length ?? 0) === 0) {
      refetchDisplay?.()
    }
  }
  const { assignTemplates } = useAssignTemplates({}, updateDisplay)
  const { unassignTemplates } = useUnassignTemplates()
  const [confirmation, setConfirmation] = useState<Confirmation>({
    modalOpen: false,
  })
  const state = confirmation.meta
  const [uniqueSettingsWorkflow, setUniqueSettingsWorkflow] = useState<UniqueSettingsWorkflow>({
    drawerIsOpen: true,
  })
  const dispatch = useDispatch()
  const handleChangeTemplate = async () => {
    const selectedItems = { includedIds: [podId ?? ''] }
    if (confirmation.action === 'unassign') {
      await unassignTemplates(selectedItems, template.settingsGroup.type)
      showSuccess('Template was unassigned')
      refetchDisplay?.()
    } else {
      const response = await assignTemplates(selectedItems, state?.template.id ?? '')
      if (response.data?.assignTemplate.validation.incompatibleDisplays?.length === 0) {
        showSuccess('Template was applied')
      } else {
        setUniqueSettingsWorkflow({
          drawerIsOpen: true,
          ...response.data?.assignTemplate,
        } as any)
      }
    }
  }

  const templateNameText = template.isCustom ? (
    <span className={styles.customTemplateName}>Unassigned</span>
  ) : (
    template.name
  )

  const templateNameInput = (
    <form
      onSubmit={e => {
        e.preventDefault()
        props.handleSaveTemplate(e)
      }}
    >
      {template.isDefault ? (
        <label className={styles.uniqueDefault}>{template.name}</label>
      ) : (
        <SolInput
          size="large"
          autoFocus={template.name.length === 0}
          className={styles.titleInput}
          name={`${template.settingsGroup.type}_template`}
          value={template.name}
          maxLength={40}
          placeholder="Enter Template Name"
          onChange={(e, value) => {
            e?.stopPropagation()
            updateTemplate({
              ...template,
              name: value.value,
            })
          }}
        />
      )}
    </form>
  )
  const templateOptions = [
    {
      text: 'Unassign',
      value: 'unassign',
      disabled: template.isCustom,
    },
    ...(props.templateOptions?.map(t => {
      const { isCompatible, incompatibleReason } = props.templateCompatibility?.find(
        tc => tc?.templateId === t?.id,
      ) ?? {
        isCompatible: true,
      }
      return {
        value: t.id,
        text: t.name,
        disabled: t.id === template.id || !isCompatible,
        description: !isCompatible ? incompatibleReason : undefined,
      }
    }) ?? []),
  ]

  const classicText = (
    <span>
      <strong>This Pod will be converted to Modern Welcome Screen.</strong> Modern Welcome Screen is the only Welcome
      Screen in Solstice Cloud Management. You need to use the Dashboard in order to undo this.
    </span>
  )

  const modernScreenText = `You're changing a template on 1 Pod.`

  const modalTextWarningClassic = state?.showClassicWarning === true
    ? classicText
    : modernScreenText


  const getPodIdentifier = (): string => {
    const truncatedName = displayName && displayName.length > 46
      ? `${displayName.slice(0, 46)}...`
      : displayName as string

    return displayName ? truncatedName : 'Pod'
  }

  const subHeaderText = {
    assign: modalTextWarningClassic,
    unassign: 'Unassigning a template will not change any existing Pod settings.',
    cancel: `${getPodIdentifier()} will revert back to previous settings once it's back online.`,
  }[confirmation.action ?? 'assign']

  const modalText = {
    assign: `${getPodIdentifier()} will now be on the ${state?.template.name} template.`,
    unassign: `${getPodIdentifier()} will be unassigned from a template.`,
    cancel: `Cancel pending changes to ${getPodIdentifier()}?`,
  }[confirmation.action ?? 'assign']

  const showUnassignTemplateCheckboxState
    = useSelector((coreState: any) => coreState?.core?.showUnussigTemplateCheckbox)


  const templateNameDropdown = (
    <div onClick={e => e.stopPropagation()}>
      <SolDropdown
        direction="right"
        text={template.isCustom ? 'Unassigned' : template.name}
        defaultValue={template.id}
        options={templateOptions}
        className={classNames(styles.templateNameDropdown, {
          [styles.unassigned]: template.isCustom,
        })}
        onChange={value => {
          const newTemplate = {
            id: value,
            name: templateOptions?.find(o => o.value === value)?.text ?? '',
          }
          const showClassicWarning
            = template.settingsGroup.type === SettingsGroupType.WelcomeScreen
            && !template.revision.configuration?.isModern
            && value !== 'unassign'

          if (value === 'unassign' && localStorage.getItem('showUnussigTemplateCheckbox') === 'false') {
            const selectedItems = { includedIds: [podId ?? ''] }
            unassignTemplates(selectedItems, template.settingsGroup.type).then(() => {
              showSuccess('Template was unassigned')
              refetchDisplay?.()
            })
            return
          }
          setConfirmation({
            modalOpen: true,
            action: value === 'unassign' ? 'unassign' : 'assign',
            meta: {
              displayId: podId ?? '',
              settingsGroupType: template.settingsGroup.type,
              template: newTemplate,
              showClassicWarning,
            },
          })
        }}
        disabled={props.loadingTemplateOptions}
      />
      <SolConfirmationModal
        modalText={<p>{modalText}</p>}
        subHeaderText={subHeaderText}
        isOpen={confirmation.modalOpen}
        onCloseModal={() => setConfirmation({ modalOpen: false })}
        dontShowAgainCheckbox={confirmation.action === 'unassign' ? showUnassignTemplateCheckboxState : false}
        onDontShowCheckboxChange={value => {
          dispatch(setShowUnussigTemplateCheckbox(value))
        }}
        onAcceptingAction={e => {
          if (confirmation.action === 'cancel') {
            handleCancelPending(e)
          } else {
            handleChangeTemplate()
          }
          setConfirmation({ modalOpen: false })
        }}
        acceptingText="Confirm"
      />
      <FormValidation>
        <UniqueSettings
          onClose={() => setUniqueSettingsWorkflow({ drawerIsOpen: false })}
          assignTemplates={assignTemplates}
          {...uniqueSettingsWorkflow}
        />
      </FormValidation>
    </div>
  )
  const leftContent
    // Pod Details page view
    = view === 'Pod' ? (
      <React.Fragment>
        {isPending && (
          <SolStatus status={isOnline ? 'OnlinePending' : ManagementStatus.OfflinePending} iconSize="small" />
        )}
        {(syncState === 'mismatched' || syncState === 'rejected') && (
          <SolTooltip
            text="Template could not be applied to this Pod. Please review template settings and try again."
            trigger={
              <Icon name="exclamation circle" color="red" />
            }
          />
        )}
        {open && !locked ? templateNameText : templateNameDropdown}
        {template.isCustom && !isInModern && template.settingsGroup.type === SettingsGroupType.WelcomeScreen && (
          <SolTooltip
            text={
              'Modern Welcome Screen is the only supported Welcome Screen in Solstice Cloud Management.'
              + ' You need to use the Dashboard in order to undo this'
            }
            position="top left"
            trigger={<SolAlertIcon className={styles.exclamationTriangle} />}
            isInline
          />
        )}
      </React.Fragment>
    ) : (
      // Template header view
      <React.Fragment>
        {open && templateNameInput}
        {!open && template.id !== '' && (
          <span className={styles.header}>
            <span
              className={classNames(styles.templateName, {
                [styles.uniqueDefault]: template.isDefault,
              })}
            >
              {template.name}
            </span>
            |<span className={styles.assignedPods}>{`${totalAssignedDisplays} Pods assigned`}</span>
          </span>
        )}
        {!open && totalAssignedDisplays === 0 && (
          <SolButton
            containerClassName={styles.assignPods}
            className={styles.button}
            text="Assign Pods"
            primary
            disabled={false}
            color="blue"
            basic={false}
            handleClick={e => {
              e.stopPropagation()
              history.push('/manage/pods')
            }}
            isLink={false}
          />
        )}
      </React.Fragment>
    )

  const centerContent
    = view === 'Pod' && isPending ? (
      <SolTooltip
        position="top left"
        hoverable
        trigger={
          <SolButton
            containerClassName={styles.cancelPending}
            text="Cancel Pending Changes"
            primary={false}
            disabled={false}
            basic
            isLink={false}
            color="blue"
            handleClick={e => {
              e.stopPropagation()
              if (isPending && !isOnline) {
                setConfirmation({
                  modalOpen: true,
                  action: 'cancel',
                })
              } else {
                handleCancelPending(e)
              }
            }}
          />
        }
        text={
          `Changes made to ${displayName} ${template.settingsGroup.name} template are pending until`
          + ` ${displayName} is back online. Expand the card to view/edit/cancel.`
        }
      />
    ) : null

  return (
    <div className={styles.wrapper} data-testid="template-header">
      <div className={styles.leftContent} data-testid="left-content">
        {leftContent}
      </div>
      <div className={styles.centerContent}>{centerContent}</div>
      <div className={styles.rightContent}>
        <ActionButtons {...props} />
      </div>
    </div>
  )
}
