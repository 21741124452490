import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The Config broken up into Settings Groups as shown in DisplayConfiguration */
  Config: any;
  JSONObject: any;
  /** The raw JSON config that solstice expects */
  RawConfig: any;
};

export type ActiveLearning = {
  __typename?: 'ActiveLearning';
  licenseEndDate?: Maybe<Scalars['String']>;
  rooms: Array<ActiveLearningRoom>;
  status: Scalars['String'];
  trial?: Maybe<ActiveLearningTrial>;
};

export type ActiveLearningRoom = {
  __typename?: 'ActiveLearningRoom';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum ActiveLearningStatus {
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  ExpiringSoon = 'EXPIRING_SOON',
  ReadyToEnable = 'READY_TO_ENABLE',
  TrialActive = 'TRIAL_ACTIVE',
  TrialExpired = 'TRIAL_EXPIRED',
  UnsupportedFirmware = 'UNSUPPORTED_FIRMWARE',
  UnsupportedSolstice = 'UNSUPPORTED_SOLSTICE'
}

export type ActiveLearningTrial = {
  __typename?: 'ActiveLearningTrial';
  endDate?: Maybe<Scalars['String']>;
  isEligible: Scalars['Boolean'];
};

export type ActiveMeetings = {
  __typename?: 'ActiveMeetings';
  count: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  address_full?: Maybe<Scalars['String']>;
  building: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  num_displays: Scalars['Int'];
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type AddressIdOnly = {
  __typename?: 'AddressIdOnly';
  id: Scalars['Int'];
};

export type AddressOptions = {
  hasPods?: Maybe<Scalars['Boolean']>;
};

export type AdvancedConfiguration = {
  __typename?: 'AdvancedConfiguration';
  dailyRestartTime?: Maybe<Scalars['String']>;
  enableDailyRestart?: Maybe<Scalars['Boolean']>;
  enableGatewayCheck?: Maybe<Scalars['Boolean']>;
  enableHDCP?: Maybe<Scalars['Boolean']>;
  enableQos?: Maybe<Scalars['Boolean']>;
  host_name?: Maybe<Scalars['String']>;
  licenseAgree?: Maybe<Scalars['Boolean']>;
  lldp?: Maybe<AdvancedLldpSettings>;
  preferredHDMIInput?: Maybe<PreferredHdmiInput>;
  qosAudioDscp?: Maybe<Scalars['String']>;
  qosVideoDscp?: Maybe<Scalars['String']>;
};

export type AdvancedLldpSettings = {
  __typename?: 'AdvancedLldpSettings';
  enabled?: Maybe<Scalars['Boolean']>;
  poeEnabled?: Maybe<Scalars['Boolean']>;
};

export type Alert = {
  __typename?: 'Alert';
  alerts: Array<Maybe<RecentAlert>>;
  totalAlerts: Scalars['Int'];
  totalDisplays: Scalars['Int'];
};

export enum Alignment {
  DetermineAtRuntime = 'determine_at_runtime',
  Freeform = 'freeform',
  Grid = 'grid'
}

export type AnalyticCounts = {
  __typename?: 'AnalyticCounts';
  busiestHour: Scalars['Int'];
  medianMeeting: Scalars['Int'];
  uniqueUsers: Scalars['Int'];
};

export type AnalyticCountsOptions = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type AppliedCategory = {
  __typename?: 'AppliedCategory';
  categoryId: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  option: CategoryOption;
};

export type AssignTemplateOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  /** The new templateId */
  templateId: Scalars['String'];
};

export type AssignTemplateResult = {
  __typename?: 'AssignTemplateResult';
  template?: Maybe<Template>;
  validation: UniqueSettingsCompatibility;
};

export type AssignedDisplays = {
  __typename?: 'AssignedDisplays';
  compatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type AssignedTemplate = {
  __typename?: 'AssignedTemplate';
  hasPendingChanges?: Maybe<Scalars['Boolean']>;
  settingsGroup?: Maybe<SettingsGroup>;
  syncState?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
};

export enum AuthenticationType {
  Basic = 'basic',
  Ntlm = 'ntlm',
  Oauth = 'oauth'
}

export type Availability = {
  __typename?: 'Availability';
  aggregate: AvailabilityAggregate;
  pods: Array<AvailabilityByDisplay>;
};

export type AvailabilityAggregate = {
  __typename?: 'AvailabilityAggregate';
  pods: Scalars['Int'];
  podsOnlineNow: Scalars['Int'];
  podsOnlineSinceYesterday: Scalars['Int'];
  subscribedPods: Scalars['Int'];
};

export type AvailabilityByDisplay = {
  __typename?: 'AvailabilityByDisplay';
  catchingUp: Scalars['Boolean'];
  displayId: Scalars['String'];
  lastOnline?: Maybe<Scalars['String']>;
  licenseShortName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  org: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  version?: Maybe<Array<Scalars['Int']>>;
};

export type AvailabilityOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByAvailability>;
  status?: Maybe<Scalars['String']>;
};

export enum AvailabilityStatus {
  Expired = 'EXPIRED',
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export enum BrowserLookIn {
  DetermineAtRuntime = 'determine_at_runtime',
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type CalendarConfiguration = {
  __typename?: 'CalendarConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  exchangeSettings?: Maybe<ExchangeSettings>;
  googleSettings?: Maybe<GoogleSettings>;
  office365Settings?: Maybe<Office365Settings>;
  showOrganizers?: Maybe<Scalars['Boolean']>;
  showOverlay?: Maybe<Scalars['Boolean']>;
  showTitles?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CalendarType>;
  updateInterval?: Maybe<Scalars['Int']>;
};

export enum CalendarType {
  Custom = 'custom',
  Exchange = 'exchange',
  Google = 'google',
  Office365 = 'office365',
  Office365Oauth = 'office365_oauth'
}

export type CalendarUniqueSettingsValidation = {
  __typename?: 'CalendarUniqueSettingsValidation';
  exchangeSettings?: Maybe<UniqueExchangeSettingsValidation>;
  googleSettings?: Maybe<UniqueGoogleSettingsValidation>;
  office365Settings?: Maybe<UniqueOffice365SettingsValidation>;
};

export type CancelPendingChangesInput = {
  displayId: Scalars['String'];
  settingsGroupType: SettingsGroupType;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<CategoryOption>;
};

export type CategoryApplication = {
  categoryId: Scalars['String'];
  displayIds: Array<Scalars['String']>;
  optionId?: Maybe<Scalars['String']>;
};

export type CategoryFilter = {
  id: Scalars['String'];
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CategoryManagerPod = {
  __typename?: 'CategoryManagerPod';
  categories: Array<AppliedCategory>;
  displayId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
};

export type CategoryManagerPodOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  idIn?: Maybe<Array<Scalars['String']>>;
  idNotIn?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByCategoryManagerPod>;
};

export type CategoryMetricSearchOptions = {
  categories?: Maybe<Array<CategoryFilter>>;
  fromDate?: Maybe<Scalars['String']>;
  locations?: Maybe<LocationFilters>;
  metricType: MetricType;
  primaryCategoryId: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
  timeInterval: TimeInterval;
  toDate?: Maybe<Scalars['String']>;
};

export type CategoryMetricsByDate = {
  __typename?: 'CategoryMetricsByDate';
  date: Scalars['String'];
  options?: Maybe<Array<Maybe<TotalByOption>>>;
};

export type CategoryMutationResponse = {
  __typename?: 'CategoryMutationResponse';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
};

export type CategoryOption = {
  __typename?: 'CategoryOption';
  appliedDisplayCount?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CategoryOptionMutationResponse = {
  __typename?: 'CategoryOptionMutationResponse';
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
  optionName: Scalars['String'];
};

export type Certificate = {
  __typename?: 'Certificate';
  cert?: Maybe<SecretFile>;
  key?: Maybe<SecretFile>;
  password?: Maybe<Secret>;
};

export type ClientPlatform = {
  __typename?: 'ClientPlatform';
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type Compatibility = {
  __typename?: 'Compatibility';
  hardRequirement: Scalars['Boolean'];
  maxHardware?: Maybe<Scalars['String']>;
  maxLms?: Maybe<Scalars['String']>;
  maxSoftware?: Maybe<Scalars['String']>;
  minHardware: Scalars['String'];
  minLms: Scalars['String'];
  minSoftware: Scalars['String'];
  softRequirement: Scalars['Boolean'];
  subscriptionRequired: Scalars['Boolean'];
};


export type ConfigDiffResult = {
  __typename?: 'ConfigDiffResult';
  diffObject?: Maybe<Scalars['Config']>;
};

export type ConfigurationSetting = {
  __typename?: 'ConfigurationSetting';
  compatibility?: Maybe<Compatibility>;
  key: Scalars['String'];
  settingsGroupType?: Maybe<SettingsGroupType>;
  value?: Maybe<Scalars['String']>;
};

export type ConfirmedDisplays = {
  __typename?: 'ConfirmedDisplays';
  compatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  totalRecords: Scalars['Int'];
};

export enum CountType {
  ActiveMeetings = 'ACTIVE_MEETINGS',
  BusiestHour = 'BUSIEST_HOUR',
  MedianMeeting = 'MEDIAN_MEETING',
  UniqueUsers = 'UNIQUE_USERS'
}

export type CreateActiveLearningTrialLicenseInput = {
  displaySearchOptions: DisplaySearchOptions;
  email: Scalars['String'];
  emailTwo?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateCategoryInput = {
  categoryName: Scalars['String'];
};

export type CreateCategoryOptionInput = {
  categoryId: Scalars['String'];
  optionName: Scalars['String'];
};

export type CreateSsoOrgInput = {
  idp_binding?: Maybe<Scalars['String']>;
  idp_entity_id: Scalars['String'];
  idp_id: Scalars['Int'];
  idp_url: Scalars['String'];
  idp_x509_cert?: Maybe<Scalars['String']>;
  sp_binding?: Maybe<Scalars['String']>;
  sp_entity_id: Scalars['String'];
  sp_url: Scalars['String'];
};

export type CreateSsoOrgResult = {
  __typename?: 'CreateSSOOrgResult';
  created: Scalars['Boolean'];
  idp_binding?: Maybe<Scalars['String']>;
  idp_cert_timestamp?: Maybe<Scalars['String']>;
  idp_entity_id?: Maybe<Scalars['String']>;
  idp_id?: Maybe<Scalars['Int']>;
  idp_url?: Maybe<Scalars['String']>;
  sp_binding?: Maybe<Scalars['String']>;
  sp_entity_id?: Maybe<Scalars['String']>;
  sp_url?: Maybe<Scalars['String']>;
  updated: Scalars['Boolean'];
};

export type CreateScheduledTaskInput = {
  data: CreateTaskOptions;
  displayIds: Array<Scalars['String']>;
  durationSecs: Scalars['Int'];
  id: Scalars['ID'];
  startDate: Scalars['String'];
  type: TaskType;
  updateType: ScheduledTaskUpdateType;
};

export type CreateSolsticeRoutingSpaceInput = {
  building_name?: Maybe<Scalars['String']>;
  moderator_sharing_mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['String']>;
  pods?: Maybe<Array<Maybe<SolsticeRoutingPodInput>>>;
  primary_pod?: Maybe<Scalars['String']>;
  require_password_on_exit?: Maybe<Scalars['Boolean']>;
  require_screen_key_verification?: Maybe<Scalars['Boolean']>;
  room_number?: Maybe<Scalars['String']>;
  route_presets?: Maybe<Array<Maybe<SolsticeRoutingRoutePresetInput>>>;
  show_routing_banner?: Maybe<Scalars['Boolean']>;
  space_type?: Maybe<Scalars['String']>;
};

export type CreateTaskOptions = {
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
  versionToUpdate?: Maybe<Scalars['String']>;
};

export type CreateTemplateInput = {
  configuration: Scalars['Config'];
  name: Scalars['String'];
  settingsGroupType: SettingsGroupType;
};

export type DeleteAddressInput = {
  addressId: Scalars['Int'];
};

export type DeleteAddressResult = {
  __typename?: 'DeleteAddressResult';
  address?: Maybe<AddressIdOnly>;
};

export type DeleteCategoryInput = {
  categoryId: Scalars['String'];
};

export type DeleteCategoryOptionInput = {
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
};

export type DeletePodInput = {
  podId: Scalars['String'];
};

export type DeletePodResult = {
  __typename?: 'DeletePodResult';
  podId: Scalars['String'];
};

export type DeletePodsInput = {
  podIds?: Maybe<Array<Scalars['String']>>;
};

export type DeletePodsResult = {
  __typename?: 'DeletePodsResult';
  podIds?: Maybe<Array<Scalars['String']>>;
};

export type DeleteTemplateInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  email: Scalars['String'];
};

export type Deployment = {
  __typename?: 'Deployment';
  deploy_date?: Maybe<Scalars['String']>;
  display_id: Scalars['String'];
  hardware?: Maybe<Scalars['String']>;
  ip_primary?: Maybe<Scalars['String']>;
  ip_wifi?: Maybe<Scalars['String']>;
  is_online?: Maybe<Scalars['Boolean']>;
  license?: Maybe<Scalars['String']>;
  mac_primary?: Maybe<Scalars['String']>;
  mac_wifi?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  onboard_date?: Maybe<Scalars['String']>;
  org_id: Scalars['String'];
  sds_enabled?: Maybe<Scalars['Boolean']>;
  sds_host_1?: Maybe<Scalars['String']>;
  sds_host_2?: Maybe<Scalars['String']>;
  subscription_date?: Maybe<Scalars['String']>;
  version?: Maybe<Array<Scalars['Int']>>;
};

export type DeploymentsByDisplay = {
  __typename?: 'DeploymentsByDisplay';
  catchingUp?: Maybe<Scalars['Boolean']>;
  deployDate?: Maybe<Scalars['String']>;
  displayId: Scalars['String'];
  flexeraMaintenanceEnd?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  hasUnconfirmedTemplates?: Maybe<Scalars['Boolean']>;
  ipPrimary?: Maybe<Scalars['String']>;
  ipWifi?: Maybe<Scalars['String']>;
  isInSubscription?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  license?: Maybe<Scalars['String']>;
  licenseShortName?: Maybe<Scalars['String']>;
  macPrimary?: Maybe<Scalars['String']>;
  macWifi?: Maybe<Scalars['String']>;
  maintenanceEnd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  onboardDate?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  sdsEnabled?: Maybe<Scalars['Boolean']>;
  sdsHost1?: Maybe<Scalars['String']>;
  sdsHost2?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  version?: Maybe<Array<Scalars['Int']>>;
};

export type DeploymentsPage = {
  __typename?: 'DeploymentsPage';
  aggregate?: Maybe<DeploymentsPageAggregate>;
  pods: Array<DeploymentsByDisplay>;
};

export type DeploymentsPageAggregate = {
  __typename?: 'DeploymentsPageAggregate';
  totalCurrentLmsVersionPods?: Maybe<Scalars['Int']>;
  totalExpiredPods?: Maybe<Scalars['Int']>;
  totalExpiringPods?: Maybe<Scalars['Int']>;
  totalPods?: Maybe<Scalars['Int']>;
};

export type DeploymentsPageOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByDeployments>;
  solsticeSubscriptionFilters?: Maybe<Array<Scalars['String']>>;
  solsticeVersionFilters?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

export type DigitalSignageConfiguration = {
  __typename?: 'DigitalSignageConfiguration';
  enabled: Scalars['Boolean'];
  instructionOption: InstructionOption;
  screenOption: ScreenOption;
  startAfter: Scalars['Int'];
  url: Scalars['String'];
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DiscoveryConfiguration = {
  __typename?: 'DiscoveryConfiguration';
  broadcast: Scalars['Boolean'];
  publishToSds: Scalars['Boolean'];
  sdsHosts: Array<Maybe<SdsHost>>;
};

export type Display = {
  __typename?: 'Display';
  activeLearning?: Maybe<ActiveLearning>;
  assignedTemplate?: Maybe<AssignedTemplate>;
  assignedTemplates: Array<Maybe<AssignedTemplate>>;
  catchingUp?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<AppliedCategory>>>;
  deployment?: Maybe<Deployment>;
  displayCam?: Maybe<DisplayCam>;
  ethernetConfigStaticIp?: Maybe<Scalars['String']>;
  hasPendingChanges: Scalars['Boolean'];
  id: Scalars['ID'];
  ipAddressWifi?: Maybe<Scalars['String']>;
  ipAddressesPrimary?: Maybe<Scalars['String']>;
  /** Is Eligible for Kepler Management */
  isManageable: Scalars['Boolean'];
  isOnline: Scalars['Boolean'];
  license?: Maybe<License>;
  location?: Maybe<Location>;
  macAddressesPrimary?: Maybe<Scalars['String']>;
  macAddressesWifi?: Maybe<Scalars['String']>;
  managementStatus: ManagementStatus;
  metrics?: Maybe<DisplayMetrics>;
  name: Scalars['String'];
  org: Scalars['String'];
  serialId?: Maybe<Scalars['String']>;
  subscription: Subscription;
  subscriptions?: Maybe<Subscriptions>;
  syncState?: Maybe<Scalars['String']>;
  useConfigSvc?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  versions: DisplayVersions;
  vlan1StaticIp?: Maybe<Scalars['String']>;
  vlan2StaticIp?: Maybe<Scalars['String']>;
  vlan3StaticIp?: Maybe<Scalars['String']>;
  wifiClientConfigStaticIp?: Maybe<Scalars['String']>;
};


export type DisplayAssignedTemplateArgs = {
  settingsGroupType: SettingsGroupType;
};

export type DisplayActiveLearningSearchOptions = {
  statuses?: Maybe<Array<Maybe<ActiveLearningStatus>>>;
};

export type DisplayCam = {
  __typename?: 'DisplayCam';
  cam1?: Maybe<Scalars['String']>;
  cam2?: Maybe<Scalars['String']>;
};

export type DisplayCompatibilityAggregate = {
  __typename?: 'DisplayCompatibilityAggregate';
  minHardware: Scalars['String'];
  minLms: Scalars['String'];
  minSoftware: Scalars['String'];
};

/** A representation of the Solstice Config broken up by SettingsGroupType. Each sub object is meant to be used in place of the Config scalar */
export type DisplayConfiguration = {
  __typename?: 'DisplayConfiguration';
  ADVANCED?: Maybe<AdvancedConfiguration>;
  CALENDAR?: Maybe<CalendarConfiguration>;
  DIGITAL_SIGNAGE?: Maybe<DigitalSignageConfiguration>;
  DISCOVERY?: Maybe<DiscoveryConfiguration>;
  ETHERNET?: Maybe<EthernetConfiguration>;
  FEATURES?: Maybe<FeaturesConfiguration>;
  MESSAGE_CENTER?: Maybe<MessageCenterConfiguration>;
  POWER_MANAGEMENT?: Maybe<PowerManagementConfiguration>;
  PROXY?: Maybe<ProxyConfiguration>;
  ROOM_INTELLIGENCE?: Maybe<RoomIntelligenceConfiguration>;
  SECURITY?: Maybe<SecurityConfiguration>;
  TIME_LOCALE?: Maybe<TimeLocaleConfiguration>;
  UNIQUE_SETTINGS?: Maybe<UniqueSettingsConfiguration>;
  WELCOME_SCREEN?: Maybe<WelcomeScreenConfiguration>;
  WIFI?: Maybe<WifiConfiguration>;
};

export type DisplayManagementStatusSearchOptions = {
  statuses?: Maybe<Array<Maybe<ManagementStatus>>>;
};

export type DisplayMetricSearchOptions = {
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};

export type DisplayMetrics = {
  __typename?: 'DisplayMetrics';
  totalAverageHourlyOccupancy: Scalars['Float'];
  totalAverageMeetingDuration: Scalars['Float'];
  totalAverageMeetingRating: Scalars['Float'];
  totalConfSessions: Scalars['Int'];
  totalContentPerMeeting: Scalars['Float'];
  totalMaxOccupancy?: Maybe<Scalars['Int']>;
  totalMeetings: Scalars['Int'];
  totalMinutesInUse: Scalars['Float'];
  totalPosts: Scalars['Int'];
  totalUsers: Scalars['Int'];
};

export enum DisplayOrderableField {
  ActiveLearningExpires = 'ACTIVE_LEARNING_EXPIRES',
  ActiveLearningRoomName = 'ACTIVE_LEARNING_ROOM_NAME',
  ActiveLearningStatus = 'ACTIVE_LEARNING_STATUS',
  AverageHourlyOccupancy = 'AVERAGE_HOURLY_OCCUPANCY',
  AverageMeetingDuration = 'AVERAGE_MEETING_DURATION',
  AverageMeetingRating = 'AVERAGE_MEETING_RATING',
  Cam1 = 'CAM1',
  Cam2 = 'CAM2',
  ConfSessions = 'CONF_SESSIONS',
  ContentItemsPerMeeting = 'CONTENT_ITEMS_PER_MEETING',
  Id = 'ID',
  IsManageable = 'IS_MANAGEABLE',
  IsOnline = 'IS_ONLINE',
  MaxOccupancy = 'MAX_OCCUPANCY',
  Meetings = 'MEETINGS',
  MinutesInUse = 'MINUTES_IN_USE',
  MultiRoomSessions = 'MULTI_ROOM_SESSIONS',
  Name = 'NAME',
  Posts = 'POSTS',
  Subscription = 'SUBSCRIPTION',
  Users = 'USERS',
  Version = 'VERSION'
}

export type DisplaySearchOptions = {
  activeLearning?: Maybe<DisplayActiveLearningSearchOptions>;
  assignedTemplateId?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<CategoryFilter>>;
  confirmedTemplateId?: Maybe<Scalars['String']>;
  displayIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  excludeDisplayIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  hardware?: Maybe<Array<Maybe<Hardware>>>;
  inClassicSplash?: Maybe<Scalars['Boolean']>;
  isIncludedElementPods?: Maybe<Scalars['Boolean']>;
  /** Returns displays that are eligible for Kepler Management */
  isManageable?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  location?: Maybe<RadiusLocation>;
  locationFilters?: Maybe<LocationFilters>;
  managementStatus?: Maybe<DisplayManagementStatusSearchOptions>;
  metrics?: Maybe<DisplayMetricSearchOptions>;
  orderBy?: Maybe<OrderByDisplay>;
  page?: Maybe<Scalars['Int']>;
  records?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type DisplayUpdateTask = {
  __typename?: 'DisplayUpdateTask';
  displayId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  downloadPercentage?: Maybe<Scalars['Float']>;
  errorMessage?: Maybe<Scalars['String']>;
  status: TaskStatus;
  timezone?: Maybe<Scalars['String']>;
};

export type DisplayVersions = {
  __typename?: 'DisplayVersions';
  hardware?: Maybe<Scalars['String']>;
  lms?: Maybe<Scalars['String']>;
  software?: Maybe<Scalars['String']>;
};

export type Displays = {
  __typename?: 'Displays';
  items?: Maybe<Array<Maybe<Display>>>;
  page?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export enum EapMethod {
  None = 'none',
  Peap = 'peap',
  Pwd = 'pwd',
  Tls = 'tls',
  Ttls = 'ttls'
}

export type ElementPod = {
  __typename?: 'ElementPod';
  id?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
};

export type ElementPods = {
  __typename?: 'ElementPods';
  items?: Maybe<Array<Maybe<ElementPod>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  success: Scalars['Boolean'];
};

export type EndOfMeetingMessage = {
  __typename?: 'EndOfMeetingMessage';
  enabled?: Maybe<Scalars['Boolean']>;
  messageDuration?: Maybe<Scalars['Int']>;
  timeBeforeMeeting?: Maybe<Scalars['Int']>;
};

export type EnterpriseSettings = {
  __typename?: 'EnterpriseSettings';
  caCertificate?: Maybe<File>;
  eapMethod?: Maybe<EapMethod>;
  identity?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
  phase2Auth?: Maybe<Phase2Auth>;
  userCertificate?: Maybe<SecretFile>;
};

export type EthernetConfiguration = {
  __typename?: 'EthernetConfiguration';
  adminAccess?: Maybe<Scalars['Boolean']>;
  caCertificate?: Maybe<File>;
  eapMethod?: Maybe<EapMethod>;
  enableEthernet?: Maybe<Scalars['Boolean']>;
  identity?: Maybe<Scalars['String']>;
  networkConfig: NetworkConfig;
  networkName?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
  phase2Auth?: Maybe<Phase2Auth>;
  userCertificate?: Maybe<SecretFile>;
  vlans?: Maybe<Array<Maybe<Vlan>>>;
};

export type EthernetUniqueSettingsValidation = {
  __typename?: 'EthernetUniqueSettingsValidation';
  hostname?: Maybe<ValidationOptions>;
  ipAddress?: Maybe<ValidationOptions>;
  vlans?: Maybe<Array<Maybe<VlanValidationOptions>>>;
};

export type ExchangeSettings = {
  __typename?: 'ExchangeSettings';
  authenticationType?: Maybe<AuthenticationType>;
  mailboxType?: Maybe<MailboxType>;
  ntlmDomain?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userAccount?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Secret>;
};

export type FeaturesConfiguration = {
  __typename?: 'FeaturesConfiguration';
  alignment?: Maybe<Alignment>;
  browserLookIn?: Maybe<BrowserLookIn>;
  disallowModeratorMode?: Maybe<Scalars['Boolean']>;
  enableAirplay?: Maybe<Scalars['Boolean']>;
  enableAirplayBluetooth?: Maybe<Scalars['Boolean']>;
  enableAirplayProxy?: Maybe<Scalars['Boolean']>;
  enableAndroidMirroring?: Maybe<Scalars['Boolean']>;
  enableMiracastInfrastructure?: Maybe<Scalars['Boolean']>;
  enableMiracastWifiDirect?: Maybe<Scalars['Boolean']>;
  enableScreenKey?: Maybe<Scalars['Boolean']>;
  enableWebRTC?: Maybe<Scalars['Boolean']>;
  hdmiInputMode?: Maybe<InputMode>;
  quickConnectAction?: Maybe<QuickConnectAction>;
  speakScreenKey?: Maybe<Scalars['Boolean']>;
  usbAudioToHdmiEnabled?: Maybe<Scalars['Boolean']>;
};

export type File = {
  __typename?: 'File';
  content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetAllRoutePresetsResponse = {
  __typename?: 'GetAllRoutePresetsResponse';
  items?: Maybe<Array<Maybe<SolsticeRoutingRoutePreset>>>;
  totalItems?: Maybe<Scalars['Int']>;
};

export type GetAllSpacesResponse = {
  __typename?: 'GetAllSpacesResponse';
  items?: Maybe<Array<Maybe<SolsticeRoutingSpace>>>;
  totalItems?: Maybe<Scalars['Int']>;
};

export type GoogleSettings = {
  __typename?: 'GoogleSettings';
  credentials?: Maybe<SecretFile>;
};

export enum Hardware {
  Gen2 = 'GEN2',
  Gen2I = 'GEN2I',
  Gen3 = 'GEN3'
}

export enum HdmiOutputModeType {
  Mirror = 'mirror',
  SeamlessSpan = 'seamless_span',
  Single = 'single',
  Span = 'span'
}

export type Idp = {
  __typename?: 'Idp';
  example_idp_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  sso_url_regex?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  hash: Scalars['String'];
};

export enum InputMode {
  Persistent = 'persistent',
  Primary = 'primary',
  Standard = 'standard'
}

export enum InstructionOption {
  CalendarOnly = 'calendar_only',
  NetworkAndCalendar = 'network_and_calendar',
  NetworkOnly = 'network_only'
}

export enum InstructionsDisplayType {
  Hide = 'hide',
  ShowCustom = 'show_custom',
  ShowDefault = 'show_default'
}

export type InviteUserInput = {
  email: Scalars['String'];
  resend?: Maybe<Scalars['Boolean']>;
  role: Scalars['Int'];
};

export enum IpType {
  Dhcp = 'dhcp',
  Static = 'static'
}


export type Joyride = {
  __typename?: 'Joyride';
  finishedOn?: Maybe<Scalars['String']>;
  joyride: JoyrideOption;
};

export enum JoyrideOption {
  ActiveLearning = 'ActiveLearning',
  ComparePage = 'ComparePage',
  PodActions = 'PodActions',
  SolsticeDeployDownload = 'SolsticeDeployDownload',
  SolsticeUpdates = 'SolsticeUpdates'
}

export type Language = {
  __typename?: 'Language';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type License = {
  __typename?: 'License';
  shortName?: Maybe<Scalars['String']>;
};

export type LiveAnalyticCounts = {
  __typename?: 'LiveAnalyticCounts';
  activeMeetings: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  address_id?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationFilter = {
  idIn?: Maybe<Array<Scalars['Int']>>;
  includeNull?: Maybe<Scalars['Boolean']>;
};

export type LocationFilters = {
  building?: Maybe<LocationFilter>;
  city?: Maybe<LocationFilter>;
  country?: Maybe<LocationFilter>;
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum MailboxType {
  Default = 'default',
  Delegation = 'delegation',
  Impersonation = 'impersonation'
}

export enum ManagementStatus {
  NotYetDeployed = 'NotYetDeployed',
  Offline = 'Offline',
  OfflinePending = 'OfflinePending',
  Online = 'Online',
  Unsupported = 'Unsupported'
}

export type MapData = {
  __typename?: 'MapData';
  id: Scalars['String'];
  name: Scalars['String'];
  outOfTemplateCount: Scalars['Int'];
  preciseLat: Scalars['Float'];
  preciseLong: Scalars['Float'];
  restartCount: Scalars['Int'];
  totalAlerts: Scalars['Int'];
  unreachableCount: Scalars['Int'];
  usageHours: Scalars['Float'];
  usbAddedEventCount: Scalars['Int'];
  usbRemovedEventCount: Scalars['Int'];
};

export type MessageCenterConfiguration = {
  __typename?: 'MessageCenterConfiguration';
  customMessage?: Maybe<MessageCenterMessage>;
  emergencyMessage?: Maybe<MessageCenterMessage>;
  rssFeedCount?: Maybe<Scalars['Int']>;
  rssFeeds?: Maybe<Array<Maybe<MessageCenterRssFeed>>>;
};

export type MessageCenterMessage = {
  __typename?: 'MessageCenterMessage';
  enabled?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

export type MessageCenterRssFeed = {
  __typename?: 'MessageCenterRSSFeed';
  duration?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MetricSearchOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  fromDate?: Maybe<Scalars['String']>;
  metricTypes: Array<MetricType>;
  timeInterval: TimeInterval;
  toDate?: Maybe<Scalars['String']>;
};

export enum MetricType {
  AverageHourlyOccupancy = 'AVERAGE_HOURLY_OCCUPANCY',
  AverageMeetingDuration = 'AVERAGE_MEETING_DURATION',
  AverageMeetingRating = 'AVERAGE_MEETING_RATING',
  ContentItemsPerMeeting = 'CONTENT_ITEMS_PER_MEETING',
  MaxOccupancy = 'MAX_OCCUPANCY',
  Meetings = 'MEETINGS',
  MinutesInUse = 'MINUTES_IN_USE',
  Posts = 'POSTS',
  Users = 'USERS'
}

export type MetricsByDate = {
  __typename?: 'MetricsByDate';
  date: Scalars['String'];
  metrics: Array<Maybe<TotalByMetric>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Apply category to a display */
  applyCategory: SimpleUpdateResult;
  /** Assign one or many displays to a new template */
  assignTemplate: AssignTemplateResult;
  /** Revert the current assigned template to be the current confirmed template */
  cancelPendingChanges: Display;
  /** Create an Active Learning trial license */
  createActiveLearningTrialLicense: Array<Display>;
  /** Category verbs */
  createCategory: CategoryMutationResponse;
  /** Category option verbs */
  createCategoryOption: CategoryOptionMutationResponse;
  createSSOOrg: CreateSsoOrgResult;
  /** Create a scheduled task */
  createScheduledTask?: Maybe<ScheduledTask>;
  createSolsticeRoutingSpace: SolsticeRoutingSpace;
  createSsoUser: User;
  /** Save a new template */
  createTemplate?: Maybe<Template>;
  /** Delete addresses */
  deleteAddress: DeleteAddressResult;
  deleteCategory: SimpleUpdateResult;
  deleteCategoryOption: SimpleUpdateResult;
  /** Delete a pod */
  deletePod: DeletePodResult;
  deletePods: DeletePodsResult;
  deleteSolsticeRoutingSpace: EmptyResponse;
  /** Delete an existing template */
  deleteTemplate: EmptyResponse;
  deleteUser: User;
  finishJoyride?: Maybe<Joyride>;
  inviteUser: User;
  recordLastLogin: EmptyResponse;
  resetJoyride?: Maybe<Joyride>;
  /** Unassign one or many displays from their existing settingsType template */
  unassignTemplate: UnassignTemplateResult;
  updateCategory: CategoryMutationResponse;
  updateCategoryOption: CategoryOptionMutationResponse;
  updateDisplay: UpdateDisplayResult;
  updateScheduledTask?: Maybe<ScheduledTask>;
  updateSolsticeRoutingOrg?: Maybe<SolsticeRoutingOrg>;
  updateSolsticeRoutingSpace: SolsticeRoutingSpace;
  updateSsoStatus: User;
  updateSupportAccess?: Maybe<Org>;
  /** Update an existing template */
  updateTemplate: UpdateTemplateResult;
  updateUserRole: User;
};


export type MutationApplyCategoryArgs = {
  options: CategoryApplication;
};


export type MutationAssignTemplateArgs = {
  options: AssignTemplateOptions;
};


export type MutationCancelPendingChangesArgs = {
  options: CancelPendingChangesInput;
};


export type MutationCreateActiveLearningTrialLicenseArgs = {
  options: CreateActiveLearningTrialLicenseInput;
};


export type MutationCreateCategoryArgs = {
  options: CreateCategoryInput;
};


export type MutationCreateCategoryOptionArgs = {
  options: CreateCategoryOptionInput;
};


export type MutationCreateSsoOrgArgs = {
  options: CreateSsoOrgInput;
};


export type MutationCreateScheduledTaskArgs = {
  options: CreateScheduledTaskInput;
};


export type MutationCreateSolsticeRoutingSpaceArgs = {
  options: CreateSolsticeRoutingSpaceInput;
};


export type MutationCreateSsoUserArgs = {
  options: InviteUserInput;
};


export type MutationCreateTemplateArgs = {
  template: CreateTemplateInput;
};


export type MutationDeleteAddressArgs = {
  options: DeleteAddressInput;
};


export type MutationDeleteCategoryArgs = {
  options: DeleteCategoryInput;
};


export type MutationDeleteCategoryOptionArgs = {
  options: DeleteCategoryOptionInput;
};


export type MutationDeletePodArgs = {
  options: DeletePodInput;
};


export type MutationDeletePodsArgs = {
  options: DeletePodsInput;
};


export type MutationDeleteSolsticeRoutingSpaceArgs = {
  spaceId: Scalars['String'];
};


export type MutationDeleteTemplateArgs = {
  template: DeleteTemplateInput;
};


export type MutationDeleteUserArgs = {
  options: DeleteUserInput;
};


export type MutationFinishJoyrideArgs = {
  options?: Maybe<JoyrideOption>;
};


export type MutationInviteUserArgs = {
  options: InviteUserInput;
};


export type MutationRecordLastLoginArgs = {
  options: RecordLastLoginUserInput;
};


export type MutationResetJoyrideArgs = {
  options?: Maybe<JoyrideOption>;
};


export type MutationUnassignTemplateArgs = {
  options: UnassignTemplateOptions;
};


export type MutationUpdateCategoryArgs = {
  options: UpdateCategoryInput;
};


export type MutationUpdateCategoryOptionArgs = {
  options: UpdateCategoryOptionInput;
};


export type MutationUpdateDisplayArgs = {
  options: UpdateDisplayInput;
};


export type MutationUpdateScheduledTaskArgs = {
  options: UpdateScheduledTaskInput;
};


export type MutationUpdateSolsticeRoutingOrgArgs = {
  options: UpdateSolsticeRoutingOrgInput;
};


export type MutationUpdateSolsticeRoutingSpaceArgs = {
  options: UpdateSolsticeRoutingSpaceInput;
};


export type MutationUpdateSsoStatusArgs = {
  options: UpdateSsoStatusInput;
};


export type MutationUpdateSupportAccessArgs = {
  options?: Maybe<UpdateSupportInput>;
};


export type MutationUpdateTemplateArgs = {
  template: UpdateTemplateInput;
};


export type MutationUpdateUserRoleArgs = {
  options: UpdateUserRoleInput;
};

export type NetworkConfig = {
  __typename?: 'NetworkConfig';
  dns1?: Maybe<Scalars['String']>;
  dns2?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  ipType?: Maybe<IpType>;
  networkPrefixLength?: Maybe<Scalars['Int']>;
};

export type OccupancySettings = {
  __typename?: 'OccupancySettings';
  enableAutoReserve?: Maybe<Scalars['Boolean']>;
  enableOccupancyData?: Maybe<Scalars['Boolean']>;
};

export type Office365Settings = {
  __typename?: 'Office365Settings';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Secret>;
  tenantId?: Maybe<Scalars['String']>;
};

export type OrderByAvailability = {
  lastOnline?: Maybe<Direction>;
  name?: Maybe<Direction>;
  status?: Maybe<Direction>;
  version?: Maybe<Direction>;
};

export type OrderByCategoryManagerPod = {
  name?: Maybe<Direction>;
};

export type OrderByDeployments = {
  catchingUp?: Maybe<Direction>;
  deployDate?: Maybe<Direction>;
  displayId?: Maybe<Direction>;
  flexeraMaintenanceEnd?: Maybe<Direction>;
  hardware?: Maybe<Direction>;
  hasUnconfirmedTemplates?: Maybe<Direction>;
  ipPrimary?: Maybe<Direction>;
  ipWifi?: Maybe<Direction>;
  isInSubscription?: Maybe<Direction>;
  isManageable?: Maybe<Direction>;
  isOnline?: Maybe<Direction>;
  lastOnlin?: Maybe<Direction>;
  license?: Maybe<Direction>;
  lmsVersion?: Maybe<Direction>;
  location?: Maybe<Direction>;
  macPrimary?: Maybe<Direction>;
  macWifi?: Maybe<Direction>;
  maintenanceEnd?: Maybe<Direction>;
  name?: Maybe<Direction>;
  onboardDate?: Maybe<Direction>;
  orgId?: Maybe<Direction>;
  sdsEnabled?: Maybe<Direction>;
  sdsHost1?: Maybe<Direction>;
  sdsHost2?: Maybe<Direction>;
  serialId?: Maybe<Direction>;
  status?: Maybe<Direction>;
  tag1?: Maybe<Direction>;
  tag2?: Maybe<Direction>;
  tag3?: Maybe<Direction>;
  tag4?: Maybe<Direction>;
  version?: Maybe<Direction>;
};

export type OrderByDisplay = {
  category?: Maybe<Scalars['String']>;
  direction: Direction;
  field?: Maybe<DisplayOrderableField>;
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type OrderByRealtime = {
  booked?: Maybe<Direction>;
  building?: Maybe<Direction>;
  footTraffic?: Maybe<Direction>;
  isOccupied?: Maybe<Direction>;
  name?: Maybe<Direction>;
  realtimeStatus?: Maybe<Direction>;
  solsticeUserCount?: Maybe<Direction>;
};

export type OrderByRoomDiscoveryRooms = {
  ipAddress?: Maybe<Direction>;
  name?: Maybe<Direction>;
};

export type OrderBySolsticeRoutingSpaces = {
  direction: Direction;
  field?: Maybe<SolsticeRoutingSpacesOrderableField>;
};

export type OrderByUpdateablePods = {
  name?: Maybe<Direction>;
  status?: Maybe<Direction>;
  subscriptionEndDate?: Maybe<Direction>;
  version?: Maybe<Direction>;
};

export type OrderByUsbDevices = {
  fullProductName?: Maybe<Direction>;
  isConferenceReady?: Maybe<Direction>;
  isOccupancyReady?: Maybe<Direction>;
  name?: Maybe<Direction>;
  usbPlugTimestamp?: Maybe<Direction>;
  usbStatus?: Maybe<Direction>;
};

export type Org = {
  __typename?: 'Org';
  grant_support_access_until?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PartialDisplay = {
  __typename?: 'PartialDisplay';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum Phase2Auth {
  Gtc = 'gtc',
  Mschap = 'mschap',
  Mschapv2 = 'mschapv2',
  None = 'none',
  Pap = 'pap'
}

export type PlatformConfig = {
  __typename?: 'PlatformConfig';
  hasElementPods?: Maybe<Scalars['Boolean']>;
  hasGen2Pods?: Maybe<Scalars['Boolean']>;
};

export type PodsPerSdsHost = {
  __typename?: 'PodsPerSdsHost';
  sdsHosts: Array<SdsHostPodCount>;
};

export type PodsPerSdsHostOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
};

export type PowerManagementConfiguration = {
  __typename?: 'PowerManagementConfiguration';
  control?: Maybe<PowerManagementControlSettings>;
  enableOffHours?: Maybe<Scalars['Boolean']>;
  occupancyDelayMinutes?: Maybe<Scalars['Int']>;
  occupancyEnabled?: Maybe<Scalars['Boolean']>;
  weekday?: Maybe<PowerManagementSettings>;
  weekend?: Maybe<PowerManagementSettings>;
};

export type PowerManagementControlSettings = {
  __typename?: 'PowerManagementControlSettings';
  commandDisplayOff?: Maybe<Scalars['String']>;
  commandDisplayOn?: Maybe<Scalars['String']>;
  controlMethod?: Maybe<PowerManagementControlTypes>;
};

export enum PowerManagementControlTypes {
  Hdmi = 'hdmi',
  Rs232 = 'rs232'
}

export type PowerManagementSettings = {
  __typename?: 'PowerManagementSettings';
  allDay?: Maybe<Scalars['Boolean']>;
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  suspendAfter?: Maybe<Scalars['Int']>;
};

export type PreferredHdmiInput = {
  __typename?: 'PreferredHDMIInput';
  enabled?: Maybe<Scalars['Boolean']>;
  resolution?: Maybe<PreferredHdmInputResolution>;
};

export enum PreferredHdmInputResolution {
  Vga = 'VGA',
  _720p = '_720p',
  _1080p = '_1080p'
}

export type Product = {
  __typename?: 'Product';
  edition?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  releases?: Maybe<Array<Maybe<Release>>>;
  type?: Maybe<Scalars['String']>;
};

export enum ProductEdition {
  Base = 'BASE',
  Sge = 'SGE',
  Unlimited = 'UNLIMITED'
}

export enum ProductPlatform {
  Android = 'ANDROID',
  Pod_3 = 'POD_3',
  PodGen2I = 'POD_GEN2I',
  PodGen3 = 'POD_GEN3',
  Windows = 'WINDOWS'
}

export enum ProductType {
  SolsticeAndroidImage = 'SOLSTICE_ANDROID_IMAGE',
  SolsticeDashboard = 'SOLSTICE_DASHBOARD',
  SolsticeServer = 'SOLSTICE_SERVER'
}

export type Proxy = {
  __typename?: 'Proxy';
  bypassOnSameSubnet?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  exclusionList?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
  port?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type ProxyConfiguration = {
  __typename?: 'ProxyConfiguration';
  http: Proxy;
  https: Proxy;
};

export type Query = {
  __typename?: 'Query';
  activeLearningRooms?: Maybe<Array<Maybe<ActiveLearningRoom>>>;
  activeMeetings?: Maybe<ActiveMeetings>;
  addresses: Array<Address>;
  analyticCounts?: Maybe<AnalyticCounts>;
  availability?: Maybe<Availability>;
  categories: Array<Category>;
  categoryManagerPods: Array<CategoryManagerPod>;
  categoryMetricsByDate?: Maybe<Array<Maybe<CategoryMetricsByDate>>>;
  configDiff?: Maybe<ConfigDiffResult>;
  currentScheduledTasks: Array<Maybe<ScheduledTask>>;
  deployment?: Maybe<Deployment>;
  deployments?: Maybe<DeploymentsPage>;
  display?: Maybe<Display>;
  displayCompatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  displays?: Maybe<Displays>;
  elementPods?: Maybe<ElementPods>;
  getUserAuthorizationClaims?: Maybe<UserAuthorizationClaims>;
  historicalScheduledTasks: Array<Maybe<ScheduledTask>>;
  idps?: Maybe<Array<Maybe<Idp>>>;
  joyrides: Array<Joyride>;
  languages?: Maybe<Array<Language>>;
  liveAnalyticCounts?: Maybe<LiveAnalyticCounts>;
  metricsByDate?: Maybe<Array<Maybe<MetricsByDate>>>;
  platformConfig?: Maybe<PlatformConfig>;
  podsPerSdsHost?: Maybe<PodsPerSdsHost>;
  realtimeDisplays: Array<RealtimeDisplay>;
  recentAlerts?: Maybe<Alert>;
  roomDiscoveryRooms: Array<RoomDiscoveryRoom>;
  secretVerification: SecretVerificationResult;
  settingsGroups?: Maybe<Array<Maybe<SettingsGroup>>>;
  solsticeRoutingOrg: SolsticeRoutingOrg;
  solsticeRoutingRoutePresets: GetAllRoutePresetsResponse;
  solsticeRoutingSpace: SolsticeRoutingSpace;
  solsticeRoutingSpaces: GetAllSpacesResponse;
  ssoOrg?: Maybe<SsoOrg>;
  subscriptions?: Maybe<Subscriptions>;
  supportAccess?: Maybe<Org>;
  templateCompatibility?: Maybe<Array<Maybe<TemplateCompatibility>>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  timeZones?: Maybe<Array<TimeZone>>;
  totalDisplays: Scalars['Int'];
  uniqueSettingsCompatibility?: Maybe<UniqueSettingsCompatibility>;
  updateability?: Maybe<Updateability>;
  updateablePods: UpdateablePods;
  updateablePodsByVersion: Array<UpdateablePodsByVersion>;
  usageByRoomType?: Maybe<UsageByRoomType>;
  usageGeoData?: Maybe<UsageGeoData>;
  usbDevices?: Maybe<UsbPage>;
  userDevices?: Maybe<Array<Maybe<ClientPlatform>>>;
  versions?: Maybe<Array<Maybe<Product>>>;
};


export type QueryAddressesArgs = {
  options?: Maybe<AddressOptions>;
};


export type QueryAnalyticCountsArgs = {
  options: AnalyticCountsOptions;
};


export type QueryAvailabilityArgs = {
  options?: Maybe<AvailabilityOptions>;
};


export type QueryCategoryManagerPodsArgs = {
  options?: Maybe<CategoryManagerPodOptions>;
};


export type QueryCategoryMetricsByDateArgs = {
  options: CategoryMetricSearchOptions;
};


export type QueryConfigDiffArgs = {
  id: Scalars['String'];
};


export type QueryCurrentScheduledTasksArgs = {
  options: ScheduledTaskSearchOptions;
};


export type QueryDeploymentsArgs = {
  options?: Maybe<DeploymentsPageOptions>;
};


export type QueryDisplayArgs = {
  id: Scalars['String'];
};


export type QueryDisplayCompatibilityAggregateArgs = {
  options?: Maybe<DisplaySearchOptions>;
};


export type QueryDisplaysArgs = {
  options?: Maybe<DisplaySearchOptions>;
};


export type QueryHistoricalScheduledTasksArgs = {
  options: ScheduledTaskSearchOptions;
};


export type QueryMetricsByDateArgs = {
  options: MetricSearchOptions;
};


export type QueryPodsPerSdsHostArgs = {
  options?: Maybe<PodsPerSdsHostOptions>;
};


export type QueryRealtimeDisplaysArgs = {
  options?: Maybe<RealtimeDisplaysOptions>;
};


export type QueryRecentAlertsArgs = {
  options: RecentAlertsOptions;
};


export type QueryRoomDiscoveryRoomsArgs = {
  options?: Maybe<RoomDiscoveryRoomOptions>;
};


export type QuerySecretVerificationArgs = {
  options: SecretVerificationOptions;
};


export type QuerySettingsGroupsArgs = {
  options?: Maybe<SettingsGroupSearchOptions>;
};


export type QuerySolsticeRoutingOrgArgs = {
  orgId?: Maybe<Scalars['String']>;
};


export type QuerySolsticeRoutingRoutePresetsArgs = {
  options?: Maybe<SolsticeRoutingRoutePresetsOptions>;
};


export type QuerySolsticeRoutingSpaceArgs = {
  spaceId?: Maybe<Scalars['String']>;
};


export type QuerySolsticeRoutingSpacesArgs = {
  options?: Maybe<SolsticeRoutingSpacesOptions>;
};


export type QueryTemplateCompatibilityArgs = {
  options?: Maybe<TemplateCompatibilityOptions>;
};


export type QueryTemplatesArgs = {
  options?: Maybe<TemplateSearchOptions>;
};


export type QueryTotalDisplaysArgs = {
  options?: Maybe<TotalDisplaysNoViewOptions>;
};


export type QueryUniqueSettingsCompatibilityArgs = {
  options?: Maybe<UniqueSettingsCompatibilityOptions>;
};


export type QueryUpdateablePodsArgs = {
  options?: Maybe<UpdateablePodsOptions>;
};


export type QueryUsageByRoomTypeArgs = {
  options: UsageByRoomTypeOptions;
};


export type QueryUsageGeoDataArgs = {
  options: UsageGeoDataOptions;
};


export type QueryUsbDevicesArgs = {
  options?: Maybe<UsbPageOptions>;
};


export type QueryUserDevicesArgs = {
  options: AnalyticCountsOptions;
};


export type QueryVersionsArgs = {
  options?: Maybe<VersionsOptions>;
};

export enum QuickConnectAction {
  LaunchAndConnect = 'launch_and_connect',
  LaunchAndSetSds = 'launch_and_set_sds',
  LaunchOnly = 'launch_only'
}

export type RadiusLocation = {
  center?: Maybe<LocationInput>;
  radiusKm?: Maybe<Scalars['Int']>;
};


export type RealtimeDisplay = {
  __typename?: 'RealtimeDisplay';
  building?: Maybe<Scalars['String']>;
  calendarConfigEnabled?: Maybe<Scalars['Boolean']>;
  currentCalendarAttendees?: Maybe<Scalars['Int']>;
  currentCalendarEnd?: Maybe<Scalars['String']>;
  currentCalendarStart?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  footTraffic?: Maybe<Scalars['Float']>;
  isOccupied?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nextCalendarAttendees?: Maybe<Scalars['Int']>;
  nextCalendarEnd?: Maybe<Scalars['String']>;
  nextCalendarStart?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  occupancyCount?: Maybe<Scalars['Int']>;
  org: Scalars['String'];
  preciseLat: Scalars['Float'];
  preciseLong: Scalars['Float'];
  realtimeStatus: Scalars['String'];
  solsticeUserCount?: Maybe<Scalars['Int']>;
};

export type RealtimeDisplaysOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByRealtime>;
  realtimeStatusFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecentAlert = {
  __typename?: 'RecentAlert';
  date: Scalars['String'];
  totalAlerts: Scalars['Int'];
};

export type RecentAlertsOptions = {
  fromDate: Scalars['String'];
  timeInterval: TimeInterval;
  toDate: Scalars['String'];
};

export type RecordLastLoginUserInput = {
  email: Scalars['String'];
};

export type Release = {
  __typename?: 'Release';
  androidVersionCode?: Maybe<Scalars['String']>;
  build?: Maybe<Scalars['String']>;
  customerFacingVersion?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  download?: Maybe<Scalars['String']>;
  minimumAndroidVersionCode?: Maybe<Scalars['String']>;
  minimumDashboardVersion?: Maybe<Scalars['String']>;
  minimumServerVersion?: Maybe<Scalars['String']>;
  relNotes?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Revision = {
  __typename?: 'Revision';
  /** Our representation of the Solstice Configuration broken up into chunks by Settings Group */
  configuration?: Maybe<Scalars['Config']>;
  id: Scalars['ID'];
  /** The raw config object that the Solstice API expects */
  rawConfiguration?: Maybe<Scalars['RawConfig']>;
};

export type RoleDetail = {
  __typename?: 'RoleDetail';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type RoomDiscoveryRoom = {
  __typename?: 'RoomDiscoveryRoom';
  compatible: Scalars['Boolean'];
  ipAddress: Scalars['String'];
  name: Scalars['String'];
};

export type RoomDiscoveryRoomOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByRoomDiscoveryRooms>;
};

export type RoomIntelligenceConfiguration = {
  __typename?: 'RoomIntelligenceConfiguration';
  enableLocationServices?: Maybe<Scalars['Boolean']>;
  enableSolsticeConferencing?: Maybe<Scalars['Boolean']>;
  endOfMeetingMessage?: Maybe<EndOfMeetingMessage>;
  occupancySettings?: Maybe<OccupancySettings>;
  secondaryEndOfMeetingMessage?: Maybe<EndOfMeetingMessage>;
};

export type RoomTypeUsage = {
  __typename?: 'RoomTypeUsage';
  id: Scalars['String'];
  name: Scalars['String'];
  totalMeetingLength: Scalars['Float'];
};

export type SdsHost = {
  __typename?: 'SDSHost';
  hostname: Scalars['String'];
};

export type SsoOrg = {
  __typename?: 'SSOOrg';
  idp_cert_timestamp: Scalars['String'];
  idp_entity_id: Scalars['String'];
  idp_id: Scalars['Int'];
  idp_url: Scalars['String'];
};

export type ScheduledTask = {
  __typename?: 'ScheduledTask';
  createdBy?: Maybe<Scalars['String']>;
  data: TaskOptions;
  displays?: Maybe<Array<Maybe<DisplayUpdateTask>>>;
  finishedOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startDate: Scalars['String'];
  type: TaskType;
  updateType: ScheduledTaskUpdateType;
};

export type ScheduledTaskSearchOptions = {
  taskType: TaskType;
};

export enum ScheduledTaskUpdateType {
  Now = 'now',
  Scheduled = 'scheduled'
}

export enum ScreenOption {
  FooterAndOverlay = 'footer_and_overlay',
  FooterOnly = 'footer_only',
  Fullscreen = 'fullscreen'
}

export type SdsHostPodCount = {
  __typename?: 'SdsHostPodCount';
  host: Scalars['String'];
  podCount: Scalars['Int'];
};

export type Secret = {
  __typename?: 'Secret';
  secret?: Maybe<Scalars['String']>;
  secretHash?: Maybe<Scalars['String']>;
  secretSalt?: Maybe<Scalars['String']>;
};

export type SecretFile = {
  __typename?: 'SecretFile';
  content?: Maybe<Secret>;
  name?: Maybe<Scalars['String']>;
};

export type SecretValidation = {
  __typename?: 'SecretValidation';
  secret?: Maybe<ValidationOptions>;
};

export type SecretVerificationOptions = {
  secret: Scalars['String'];
  secretHash: Scalars['String'];
  secretSalt: Scalars['String'];
};

export type SecretVerificationResult = {
  __typename?: 'SecretVerificationResult';
  verified: Scalars['Boolean'];
};

export type SecurityConfiguration = {
  __typename?: 'SecurityConfiguration';
  adminPassword?: Maybe<Secret>;
  allowLocalConfiguration?: Maybe<Scalars['Boolean']>;
  allowWebBrowserConfiguration?: Maybe<Scalars['Boolean']>;
  caCertificate?: Maybe<File>;
  deliverAppOn443?: Maybe<Scalars['Boolean']>;
  disableCaptivePortal?: Maybe<Scalars['Boolean']>;
  disableIcmpPingReplies?: Maybe<Scalars['Boolean']>;
  displayCertificate?: Maybe<Certificate>;
  enableEncryption?: Maybe<Scalars['Boolean']>;
  enforcePasswordValidation?: Maybe<Scalars['Boolean']>;
  redirectHTTPS?: Maybe<Scalars['Boolean']>;
  useCaCertificate?: Maybe<Scalars['Boolean']>;
};

export type SettingsGroup = {
  __typename?: 'SettingsGroup';
  hidden: Scalars['Boolean'];
  name: Scalars['String'];
  settings?: Maybe<Array<Maybe<ConfigurationSetting>>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  type: SettingsGroupType;
};

export type SettingsGroupSearchOptions = {
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export enum SettingsGroupType {
  Advanced = 'ADVANCED',
  Calendar = 'CALENDAR',
  DigitalSignage = 'DIGITAL_SIGNAGE',
  Discovery = 'DISCOVERY',
  Ethernet = 'ETHERNET',
  Features = 'FEATURES',
  JunkDrawer = 'JUNK_DRAWER',
  MessageCenter = 'MESSAGE_CENTER',
  PowerManagement = 'POWER_MANAGEMENT',
  Proxy = 'PROXY',
  RoomIntelligence = 'ROOM_INTELLIGENCE',
  Security = 'SECURITY',
  TimeLocale = 'TIME_LOCALE',
  UniqueSettings = 'UNIQUE_SETTINGS',
  WelcomeScreen = 'WELCOME_SCREEN',
  Wifi = 'WIFI'
}

export type SimpleUpdateResult = {
  __typename?: 'SimpleUpdateResult';
  recordsUpdated: Scalars['Int'];
};

export type SolsticeRoutingOrg = {
  __typename?: 'SolsticeRoutingOrg';
  created_at?: Maybe<Scalars['String']>;
  ex_id?: Maybe<Scalars['String']>;
  ex_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  require_password_on_exit?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SolsticeRoutingPod = {
  __typename?: 'SolsticeRoutingPod';
  audio_routing_enabled?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ipAddressPrimary?: Maybe<Scalars['String']>;
  ipAddressWifi?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SolsticeRoutingPodInput = {
  audio_routing_enabled?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
};

export type SolsticeRoutingRoute = {
  __typename?: 'SolsticeRoutingRoute';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org?: Maybe<Scalars['String']>;
  sink_pod?: Maybe<SolsticeRoutingPod>;
  source_pod?: Maybe<SolsticeRoutingPod>;
};

export type SolsticeRoutingRouteInput = {
  id?: Maybe<Scalars['Int']>;
  sink_pod?: Maybe<SolsticeRoutingPodInput>;
  source_pod?: Maybe<SolsticeRoutingPodInput>;
};

export type SolsticeRoutingRoutePreset = {
  __typename?: 'SolsticeRoutingRoutePreset';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<Maybe<SolsticeRoutingRoute>>>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SolsticeRoutingRoutePresetInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<Maybe<SolsticeRoutingRouteInput>>>;
};

export type SolsticeRoutingRoutePresetsOptions = {
  limit?: Maybe<Scalars['Int']>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBySolsticeRoutingSpaces>;
  spaceId?: Maybe<Scalars['Int']>;
};

export type SolsticeRoutingSpace = {
  __typename?: 'SolsticeRoutingSpace';
  building_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_used?: Maybe<Scalars['String']>;
  moderator_sharing_mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['String']>;
  pods?: Maybe<Array<Maybe<SolsticeRoutingPod>>>;
  primary_pod?: Maybe<Scalars['String']>;
  require_password_on_exit?: Maybe<Scalars['Boolean']>;
  require_screen_key_verification?: Maybe<Scalars['Boolean']>;
  room_number?: Maybe<Scalars['String']>;
  route_presets?: Maybe<Array<Maybe<SolsticeRoutingRoutePreset>>>;
  show_routing_banner?: Maybe<Scalars['Boolean']>;
  space_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SolsticeRoutingSpacesOptions = {
  limit?: Maybe<Scalars['Int']>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBySolsticeRoutingSpaces>;
};

export enum SolsticeRoutingSpacesOrderableField {
  BuildingName = 'BUILDING_NAME',
  Name = 'NAME',
  RoomNumber = 'ROOM_NUMBER',
  SpaceType = 'SPACE_TYPE'
}

export type Subscription = {
  __typename?: 'Subscription';
  expirationDate?: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export type Subscriptions = {
  __typename?: 'Subscriptions';
  display_id: Scalars['String'];
  is_expiring_soon: Scalars['Boolean'];
  is_in_subscription: Scalars['Boolean'];
  org: Scalars['String'];
  subscription_date?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type TaskOptions = {
  __typename?: 'TaskOptions';
  versionToUpdate?: Maybe<Scalars['String']>;
};

export enum TaskStatus {
  Completed = 'completed',
  Downloading = 'downloading',
  Failed = 'failed',
  Installing = 'installing',
  Installpending = 'installpending',
  Pending = 'pending',
  Unknown = 'unknown'
}

export enum TaskType {
  BootAllUsers = 'bootAllUsers',
  ClearScreen = 'clearScreen',
  Delete = 'delete',
  DisplayUpdate = 'displayUpdate',
  ForgetWifiNetworks = 'forgetWifiNetworks',
  Reboot = 'reboot',
  Suspend = 'suspend',
  UsbFirmwareUpdate = 'usbFirmwareUpdate',
  Wake = 'wake'
}

export type Template = {
  __typename?: 'Template';
  assignedDisplays: AssignedDisplays;
  count?: Maybe<Scalars['Int']>;
  displayIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  isCustom: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  org: Scalars['String'];
  revision: Revision;
  settingsGroup: SettingsGroup;
};

export type TemplateCompatibility = {
  __typename?: 'TemplateCompatibility';
  incompatibleReason?: Maybe<Scalars['String']>;
  isCompatible: Scalars['Boolean'];
  isHardRequirement?: Maybe<Scalars['Boolean']>;
  template: Template;
  templateId: Scalars['String'];
};

export type TemplateCompatibilityOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type TemplateSearchOptions = {
  isCustom?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  settingsGroupType?: Maybe<SettingsGroupType>;
  templateIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TimeInterval {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type TimeLocaleConfiguration = {
  __typename?: 'TimeLocaleConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  hostname?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  show24Hours?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type TotalByMetric = {
  __typename?: 'TotalByMetric';
  name: MetricType;
  total: Scalars['Float'];
};

export type TotalByOption = {
  __typename?: 'TotalByOption';
  option?: Maybe<CategoryOption>;
  optionId: Scalars['String'];
  total: Scalars['Float'];
};

export type TotalDisplaysNoViewOptions = {
  isInSubscription?: Maybe<Scalars['Boolean']>;
  isIncludedElementPods?: Maybe<Scalars['Boolean']>;
};

export type UnassignTemplateOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  /** The settings group to remove remove from 1 or more displays */
  settingsGroupType: SettingsGroupType;
};

export type UnassignTemplateResult = {
  __typename?: 'UnassignTemplateResult';
  affectedIds: Array<Maybe<Scalars['String']>>;
};

export type UniqueAdvancedConfiguration = {
  __typename?: 'UniqueAdvancedConfiguration';
  host_name?: Maybe<Scalars['String']>;
};

export type UniqueCalendarConfiguration = {
  __typename?: 'UniqueCalendarConfiguration';
  exchangeSettings?: Maybe<UniqueExchangeSettings>;
  googleSettings?: Maybe<UniqueGoogleSettings>;
  office365Settings?: Maybe<UniqueOffice365Settings>;
};

export type UniqueEthernetConfiguration = {
  __typename?: 'UniqueEthernetConfiguration';
  hostname?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  vlans?: Maybe<Array<Maybe<UniqueEthernetVlan>>>;
};

export type UniqueEthernetVlan = {
  __typename?: 'UniqueEthernetVLAN';
  hostname?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type UniqueExchangeSettings = {
  __typename?: 'UniqueExchangeSettings';
  delegationMailbox?: Maybe<Scalars['String']>;
  impersonationMailbox?: Maybe<Scalars['String']>;
  userAccount?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Secret>;
};

export type UniqueExchangeSettingsValidation = {
  __typename?: 'UniqueExchangeSettingsValidation';
  delegationMailbox?: Maybe<ValidationOptions>;
  impersonationMailbox?: Maybe<ValidationOptions>;
  userAccount?: Maybe<ValidationOptions>;
  userPassword?: Maybe<SecretValidation>;
};

export type UniqueGoogleSettings = {
  __typename?: 'UniqueGoogleSettings';
  roomMailbox?: Maybe<Scalars['String']>;
};

export type UniqueGoogleSettingsValidation = {
  __typename?: 'UniqueGoogleSettingsValidation';
  roomMailbox?: Maybe<ValidationOptions>;
};

export type UniqueOffice365Settings = {
  __typename?: 'UniqueOffice365Settings';
  username?: Maybe<Scalars['String']>;
};

export type UniqueOffice365SettingsValidation = {
  __typename?: 'UniqueOffice365SettingsValidation';
  username?: Maybe<ValidationOptions>;
};

export type UniqueSettingsCompatibility = {
  __typename?: 'UniqueSettingsCompatibility';
  fieldValidation?: Maybe<UniqueSettingsValidation>;
  incompatibleDisplays?: Maybe<Array<Maybe<Display>>>;
};

export type UniqueSettingsCompatibilityOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  templateId: Scalars['ID'];
};

export type UniqueSettingsConfiguration = {
  __typename?: 'UniqueSettingsConfiguration';
  ADVANCED?: Maybe<UniqueAdvancedConfiguration>;
  CALENDAR?: Maybe<UniqueCalendarConfiguration>;
  ETHERNET?: Maybe<UniqueEthernetConfiguration>;
  WELCOME_SCREEN?: Maybe<UniqueWelcomeScreenConfiguration>;
  WIFI?: Maybe<UniqueWifiConfiguration>;
};

export type UniqueSettingsValidation = {
  __typename?: 'UniqueSettingsValidation';
  CALENDAR?: Maybe<CalendarUniqueSettingsValidation>;
  ETHERNET?: Maybe<EthernetUniqueSettingsValidation>;
  WELCOME_SCREEN?: Maybe<WelcomeScreenUniqueSettingsValidation>;
  WIFI?: Maybe<WifiUniqueSettingsValidation>;
};

export type UniqueWelcomeScreenConfiguration = {
  __typename?: 'UniqueWelcomeScreenConfiguration';
  displayName: Scalars['String'];
};

export type UniqueWifiConfiguration = {
  __typename?: 'UniqueWifiConfiguration';
  existingNetworkHostname?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  wapDnsHostname?: Maybe<Scalars['String']>;
  wapSsid?: Maybe<Scalars['String']>;
};

export type UpdateCategoryInput = {
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
};

export type UpdateCategoryOptionInput = {
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
  optionName: Scalars['String'];
};

export type UpdateDisplayInput = {
  configuration: Scalars['Config'];
  displayId: Scalars['String'];
  newTemplateId?: Maybe<Scalars['String']>;
  newTemplateName?: Maybe<Scalars['String']>;
  saveAsNewTemplate?: Maybe<Scalars['Boolean']>;
  settingsGroupType: SettingsGroupType;
};

export type UpdateDisplayResult = {
  __typename?: 'UpdateDisplayResult';
  display?: Maybe<Display>;
  validation?: Maybe<UniqueSettingsCompatibility>;
};

export type UpdateScheduledTaskInput = {
  cancelledOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
};

export type UpdateSolsticeRoutingOrgInput = {
  orgId?: Maybe<Scalars['String']>;
  require_password_on_exit?: Maybe<Scalars['Boolean']>;
};

export type UpdateSolsticeRoutingSpaceInput = {
  building_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  moderator_sharing_mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['String']>;
  pods?: Maybe<Array<Maybe<SolsticeRoutingPodInput>>>;
  primary_pod?: Maybe<Scalars['String']>;
  require_password_on_exit?: Maybe<Scalars['Boolean']>;
  require_screen_key_verification?: Maybe<Scalars['Boolean']>;
  room_number?: Maybe<Scalars['String']>;
  route_presets?: Maybe<Array<Maybe<SolsticeRoutingRoutePresetInput>>>;
  show_routing_banner?: Maybe<Scalars['Boolean']>;
  space_type?: Maybe<Scalars['String']>;
};

export type UpdateSsoStatusInput = {
  email: Scalars['String'];
  ssoOnly: Scalars['Boolean'];
};

export type UpdateSupportInput = {
  grantAccessUntil?: Maybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  admin?: Maybe<Scalars['Boolean']>;
  baseRevision?: Maybe<Scalars['String']>;
  configuration: Scalars['Config'];
  id: Scalars['ID'];
  isCustom: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  previousRevisionId?: Maybe<Scalars['String']>;
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type UpdateTemplateResult = {
  __typename?: 'UpdateTemplateResult';
  template: Template;
  validation: UniqueSettingsCompatibility;
};

export type UpdateUserRoleInput = {
  email: Scalars['String'];
  role: Scalars['Int'];
};

export type Updateability = {
  __typename?: 'Updateability';
  current_datetime?: Maybe<Scalars['String']>;
  display_id: Scalars['String'];
  org_id: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updateable: Scalars['Boolean'];
  updating: Scalars['Boolean'];
  version?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateableDisplaysByVersion = {
  __typename?: 'UpdateableDisplaysByVersion';
  count: Scalars['Int'];
  version: Scalars['String'];
};

export type UpdateablePod = {
  __typename?: 'UpdateablePod';
  catchingUp?: Maybe<Scalars['Boolean']>;
  displayId: Scalars['String'];
  hardware?: Maybe<Scalars['String']>;
  licenseShortName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<UpdateablePodsStatus>;
  subscriptionEndDate?: Maybe<Scalars['String']>;
  updateable?: Maybe<Scalars['Boolean']>;
  updating?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateablePods = {
  __typename?: 'UpdateablePods';
  pods: Array<UpdateablePod>;
  totalPodsCount: Scalars['Int'];
  updateablePodsCount: Scalars['Int'];
};

export type UpdateablePodsByVersion = {
  __typename?: 'UpdateablePodsByVersion';
  count: Scalars['Int'];
  version?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateablePodsOptions = {
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  idIn?: Maybe<Array<Scalars['String']>>;
  idNotIn?: Maybe<Array<Scalars['String']>>;
  isIncludedElementPods?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByUpdateablePods>;
  updateable?: Maybe<Scalars['Boolean']>;
};

export enum UpdateablePodsStatus {
  PodOffline = 'POD_OFFLINE',
  Ready = 'READY',
  SolsticeSubscriptionLapsed = 'SOLSTICE_SUBSCRIPTION_LAPSED',
  UnsupportedFirmware = 'UNSUPPORTED_FIRMWARE'
}

export type UsageByRoomType = {
  __typename?: 'UsageByRoomType';
  roomTypes: Array<Maybe<RoomTypeUsage>>;
};

export type UsageByRoomTypeOptions = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type UsageGeoData = {
  __typename?: 'UsageGeoData';
  mapData: Array<Maybe<MapData>>;
};

export type UsageGeoDataOptions = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type UsbDevice = {
  __typename?: 'UsbDevice';
  avDevice?: Maybe<Scalars['Boolean']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceProtocol?: Maybe<Scalars['String']>;
  deviceSubclass?: Maybe<Scalars['String']>;
  display?: Maybe<UsbDisplay>;
  displayId?: Maybe<Scalars['String']>;
  fullProductName?: Maybe<Scalars['String']>;
  isConferenceReady?: Maybe<Scalars['Boolean']>;
  isOccupancyReady?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  occupancyCountingEnabled?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['Int']>;
  plugOrInplug?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  relayEnabled?: Maybe<Scalars['Boolean']>;
  usbPlugTimestamp?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UsbDeviceHistory = {
  __typename?: 'UsbDeviceHistory';
  date: Scalars['String'];
  plugCount?: Maybe<Scalars['Int']>;
  unplugCount?: Maybe<Scalars['Int']>;
};

export type UsbDisplay = {
  __typename?: 'UsbDisplay';
  hardware?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isInSubscription?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UsbPage = {
  __typename?: 'UsbPage';
  history?: Maybe<Array<Maybe<UsbDeviceHistory>>>;
  plugCountSum?: Maybe<Scalars['Int']>;
  podsOnlineCount?: Maybe<Scalars['Int']>;
  podsWithDevicesOnlineCount?: Maybe<Scalars['Int']>;
  productConnectionsCount?: Maybe<Scalars['Int']>;
  products: Array<UsbProduct>;
  unplugCountSum?: Maybe<Scalars['Int']>;
  usbAvDeviceCount?: Maybe<Scalars['Int']>;
  usbAvOthersDeviceCount?: Maybe<Scalars['Int']>;
  usbDeviceCount?: Maybe<Scalars['Int']>;
  usbDevices: Array<UsbDevice>;
  usbOfflineDeviceCount?: Maybe<Scalars['Int']>;
  usbOnlineDeviceCount?: Maybe<Scalars['Int']>;
};

export type UsbPageOptions = {
  avDevicesFilter?: Maybe<Array<Scalars['String']>>;
  availabilityFilter?: Maybe<Array<Scalars['String']>>;
  categoryFilters?: Maybe<Array<CategoryFilter>>;
  limit?: Maybe<Scalars['Int']>;
  locationFilters?: Maybe<LocationFilters>;
  nameLike?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByUsbDevices>;
  productFilter?: Maybe<Array<Scalars['String']>>;
};

export type UsbProduct = {
  __typename?: 'UsbProduct';
  displayCount?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type UserAuthorizationClaims = {
  __typename?: 'UserAuthorizationClaims';
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<Array<Maybe<RoleDetail>>>;
};

export type Vlan = {
  __typename?: 'VLAN';
  adminAccess?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  networkConfig?: Maybe<NetworkConfig>;
  tag?: Maybe<Scalars['Int']>;
};

export type ValidationOptions = {
  __typename?: 'ValidationOptions';
  required: Scalars['Boolean'];
};

export type VersionAggregate = {
  __typename?: 'VersionAggregate';
  updateableDisplays?: Maybe<Array<UpdateableDisplaysByVersion>>;
};

export type VersionsOptions = {
  edition?: Maybe<ProductEdition>;
  platform?: Maybe<ProductPlatform>;
  type?: Maybe<ProductType>;
};

export type VlanValidationOptions = {
  __typename?: 'VlanValidationOptions';
  hostname?: Maybe<ValidationOptions>;
  ipAddress?: Maybe<ValidationOptions>;
};

export enum WapSecurityProtocol {
  Open = 'open',
  Wpa = 'wpa'
}

export type WapSettings = {
  __typename?: 'WapSettings';
  channel?: Maybe<Scalars['Int']>;
  hideSsid?: Maybe<Scalars['Boolean']>;
  securityProtocol?: Maybe<WapSecurityProtocol>;
  wpa2password?: Maybe<Secret>;
};

export type WelcomeScreenConfiguration = {
  __typename?: 'WelcomeScreenConfiguration';
  customInstructions?: Maybe<Scalars['String']>;
  hdmiOutput?: Maybe<HdmiOutputModeType>;
  images?: Maybe<Array<Maybe<WelcomeScreenImage>>>;
  instructions?: Maybe<InstructionsDisplayType>;
  isModern?: Maybe<Scalars['Boolean']>;
  pbPersist?: Maybe<Scalars['Boolean']>;
  pbShowIPAddress?: Maybe<Scalars['Boolean']>;
  pbShowPresenceBar?: Maybe<Scalars['Boolean']>;
  pbShowRoomName?: Maybe<Scalars['Boolean']>;
  pbShowScreenKey?: Maybe<Scalars['Boolean']>;
  screenKey?: Maybe<Scalars['Boolean']>;
  shouldAnimateBackground?: Maybe<Scalars['Boolean']>;
  showAirplay?: Maybe<Scalars['Boolean']>;
  showClock?: Maybe<Scalars['Boolean']>;
  showFullyQualifiedName?: Maybe<Scalars['Boolean']>;
  showHostnameInsteadOfIp?: Maybe<Scalars['Boolean']>;
  showMiracast?: Maybe<Scalars['Boolean']>;
  showRoomName?: Maybe<Scalars['Boolean']>;
  showWifi?: Maybe<Scalars['Boolean']>;
  textColor?: Maybe<Scalars['String']>;
};

export type WelcomeScreenImage = {
  __typename?: 'WelcomeScreenImage';
  _16x9: Image;
  _32x9: Image;
  enabled: Scalars['Boolean'];
  position: Scalars['Int'];
};

export type WelcomeScreenUniqueSettingsValidation = {
  __typename?: 'WelcomeScreenUniqueSettingsValidation';
  displayName?: Maybe<ValidationOptions>;
};

export type WifiClientSettings = {
  __typename?: 'WifiClientSettings';
  enterpriseSettings?: Maybe<EnterpriseSettings>;
  ipConfiguration?: Maybe<NetworkConfig>;
  /** This should be either open, wep, wpa, 802.1x */
  securityProtocol?: Maybe<Scalars['String']>;
  ssid?: Maybe<Scalars['String']>;
};

export type WifiConfiguration = {
  __typename?: 'WifiConfiguration';
  allowAdmin?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<WifiMode>;
  wapSettings?: Maybe<WapSettings>;
  wifiClientSettings?: Maybe<WifiClientSettings>;
};

export enum WifiMode {
  Wap = 'wap',
  WifiClient = 'wifi_client'
}

export type WifiUniqueSettingsValidation = {
  __typename?: 'WifiUniqueSettingsValidation';
  ipAddress?: Maybe<ValidationOptions>;
  wapSsid?: Maybe<ValidationOptions>;
};

export type ScheduledTaskFragment = (
  { __typename?: 'ScheduledTask' }
  & Pick<ScheduledTask, 'id' | 'type' | 'updateType' | 'startDate' | 'finishedOn'>
  & { data: (
    { __typename?: 'TaskOptions' }
    & Pick<TaskOptions, 'versionToUpdate'>
  ), displays?: Maybe<Array<Maybe<(
    { __typename?: 'DisplayUpdateTask' }
    & Pick<DisplayUpdateTask, 'status' | 'errorMessage' | 'displayId' | 'downloadPercentage' | 'timezone' | 'displayName'>
  )>>> }
);

export type TemplateFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'name' | 'org' | 'isCustom' | 'isDefault'>
  & { assignedDisplays: (
    { __typename?: 'AssignedDisplays' }
    & Pick<AssignedDisplays, 'totalRecords'>
    & { compatibilityAggregate?: Maybe<(
      { __typename?: 'DisplayCompatibilityAggregate' }
      & Pick<DisplayCompatibilityAggregate, 'minSoftware' | 'minHardware' | 'minLms'>
    )> }
  ), settingsGroup: (
    { __typename?: 'SettingsGroup' }
    & Pick<SettingsGroup, 'type' | 'hidden' | 'name'>
    & { settings?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurationSetting' }
      & Pick<ConfigurationSetting, 'key' | 'value' | 'settingsGroupType'>
      & { compatibility?: Maybe<(
        { __typename?: 'Compatibility' }
        & Pick<Compatibility, 'minSoftware' | 'minHardware' | 'minLms' | 'hardRequirement'>
      )> }
    )>>> }
  ), revision: (
    { __typename?: 'Revision' }
    & Pick<Revision, 'id' | 'configuration'>
  ) }
);

export type UniqueSettingsValidationFragment = (
  { __typename?: 'UniqueSettingsCompatibility' }
  & { incompatibleDisplays?: Maybe<Array<Maybe<(
    { __typename?: 'Display' }
    & Pick<Display, 'id' | 'name'>
    & { versions: (
      { __typename?: 'DisplayVersions' }
      & Pick<DisplayVersions, 'hardware' | 'software' | 'lms'>
    ), assignedTemplate?: Maybe<(
      { __typename?: 'AssignedTemplate' }
      & { template?: Maybe<(
        { __typename?: 'Template' }
        & { revision: (
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'configuration'>
        ) }
      )> }
    )> }
  )>>>, fieldValidation?: Maybe<(
    { __typename?: 'UniqueSettingsValidation' }
    & { CALENDAR?: Maybe<(
      { __typename?: 'CalendarUniqueSettingsValidation' }
      & { exchangeSettings?: Maybe<(
        { __typename?: 'UniqueExchangeSettingsValidation' }
        & { impersonationMailbox?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )>, delegationMailbox?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )>, userAccount?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )>, userPassword?: Maybe<(
          { __typename?: 'SecretValidation' }
          & { secret?: Maybe<(
            { __typename?: 'ValidationOptions' }
            & Pick<ValidationOptions, 'required'>
          )> }
        )> }
      )>, office365Settings?: Maybe<(
        { __typename?: 'UniqueOffice365SettingsValidation' }
        & { username?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )> }
      )>, googleSettings?: Maybe<(
        { __typename?: 'UniqueGoogleSettingsValidation' }
        & { roomMailbox?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )> }
      )> }
    )>, ETHERNET?: Maybe<(
      { __typename?: 'EthernetUniqueSettingsValidation' }
      & { ipAddress?: Maybe<(
        { __typename?: 'ValidationOptions' }
        & Pick<ValidationOptions, 'required'>
      )>, vlans?: Maybe<Array<Maybe<(
        { __typename?: 'VlanValidationOptions' }
        & { ipAddress?: Maybe<(
          { __typename?: 'ValidationOptions' }
          & Pick<ValidationOptions, 'required'>
        )> }
      )>>> }
    )>, WELCOME_SCREEN?: Maybe<(
      { __typename?: 'WelcomeScreenUniqueSettingsValidation' }
      & { displayName?: Maybe<(
        { __typename?: 'ValidationOptions' }
        & Pick<ValidationOptions, 'required'>
      )> }
    )>, WIFI?: Maybe<(
      { __typename?: 'WifiUniqueSettingsValidation' }
      & { ipAddress?: Maybe<(
        { __typename?: 'ValidationOptions' }
        & Pick<ValidationOptions, 'required'>
      )>, wapSsid?: Maybe<(
        { __typename?: 'ValidationOptions' }
        & Pick<ValidationOptions, 'required'>
      )> }
    )> }
  )> }
);

export type ApplyCategoryMutationVariables = Exact<{
  options: CategoryApplication;
}>;


export type ApplyCategoryMutation = (
  { __typename?: 'Mutation' }
  & { applyCategory: (
    { __typename?: 'SimpleUpdateResult' }
    & Pick<SimpleUpdateResult, 'recordsUpdated'>
  ) }
);

export type AssignTemplateMutationVariables = Exact<{
  options: AssignTemplateOptions;
}>;


export type AssignTemplateMutation = (
  { __typename?: 'Mutation' }
  & { assignTemplate: (
    { __typename?: 'AssignTemplateResult' }
    & { template?: Maybe<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name' | 'isCustom'>
      & { settingsGroup: (
        { __typename?: 'SettingsGroup' }
        & Pick<SettingsGroup, 'type' | 'name'>
      ) }
    )>, validation: (
      { __typename?: 'UniqueSettingsCompatibility' }
      & UniqueSettingsValidationFragment
    ) }
  ) }
);

export type CancelPendingMutationVariables = Exact<{
  options: CancelPendingChangesInput;
}>;


export type CancelPendingMutation = (
  { __typename?: 'Mutation' }
  & { cancelPendingChanges: (
    { __typename?: 'Display' }
    & Pick<Display, 'id' | 'name' | 'isOnline' | 'hasPendingChanges'>
    & { assignedTemplates: Array<Maybe<(
      { __typename?: 'AssignedTemplate' }
      & Pick<AssignedTemplate, 'hasPendingChanges'>
      & { template?: Maybe<(
        { __typename?: 'Template' }
        & Pick<Template, 'id' | 'name' | 'isCustom'>
        & { assignedDisplays: (
          { __typename?: 'AssignedDisplays' }
          & { compatibilityAggregate?: Maybe<(
            { __typename?: 'DisplayCompatibilityAggregate' }
            & Pick<DisplayCompatibilityAggregate, 'minSoftware' | 'minHardware' | 'minLms'>
          )> }
        ), settingsGroup: (
          { __typename?: 'SettingsGroup' }
          & Pick<SettingsGroup, 'type' | 'hidden' | 'name'>
          & { settings?: Maybe<Array<Maybe<(
            { __typename?: 'ConfigurationSetting' }
            & Pick<ConfigurationSetting, 'key' | 'value'>
            & { compatibility?: Maybe<(
              { __typename?: 'Compatibility' }
              & Pick<Compatibility, 'minSoftware' | 'minHardware' | 'hardRequirement'>
            )> }
          )>>> }
        ), revision: (
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'configuration'>
        ) }
      )>, settingsGroup?: Maybe<(
        { __typename?: 'SettingsGroup' }
        & Pick<SettingsGroup, 'type'>
      )> }
    )>> }
  ) }
);

export type CreateActiveLearningTrialLicenseMutationVariables = Exact<{
  options: CreateActiveLearningTrialLicenseInput;
}>;


export type CreateActiveLearningTrialLicenseMutation = (
  { __typename?: 'Mutation' }
  & { createActiveLearningTrialLicense: Array<(
    { __typename?: 'Display' }
    & Pick<Display, 'id'>
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  options: CreateCategoryInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'CategoryMutationResponse' }
    & Pick<CategoryMutationResponse, 'categoryId' | 'categoryName'>
  ) }
);

export type CreateCategoryOptionMutationVariables = Exact<{
  options: CreateCategoryOptionInput;
}>;


export type CreateCategoryOptionMutation = (
  { __typename?: 'Mutation' }
  & { createCategoryOption: (
    { __typename?: 'CategoryOptionMutationResponse' }
    & Pick<CategoryOptionMutationResponse, 'optionId' | 'optionName' | 'categoryId'>
  ) }
);

export type CreateScheduledTaskMutationVariables = Exact<{
  options: CreateScheduledTaskInput;
}>;


export type CreateScheduledTaskMutation = (
  { __typename?: 'Mutation' }
  & { createScheduledTask?: Maybe<(
    { __typename?: 'ScheduledTask' }
    & Pick<ScheduledTask, 'id'>
  )> }
);

export type CreateSolsticeRoutingSpaceMutationVariables = Exact<{
  options: CreateSolsticeRoutingSpaceInput;
}>;


export type CreateSolsticeRoutingSpaceMutation = (
  { __typename?: 'Mutation' }
  & { createSolsticeRoutingSpace: (
    { __typename?: 'SolsticeRoutingSpace' }
    & Pick<SolsticeRoutingSpace, 'id' | 'name' | 'org' | 'partition' | 'building_name' | 'space_type' | 'show_routing_banner' | 'require_screen_key_verification' | 'require_password_on_exit' | 'moderator_sharing_mode' | 'room_number' | 'primary_pod'>
    & { pods?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingPod' }
      & Pick<SolsticeRoutingPod, 'id' | 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'name'>
    )>>>, route_presets?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingRoutePreset' }
      & Pick<SolsticeRoutingRoutePreset, 'name'>
      & { routes?: Maybe<Array<Maybe<(
        { __typename?: 'SolsticeRoutingRoute' }
        & { sink_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'id'>
        )>, source_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'id'>
        )> }
      )>>> }
    )>>> }
  ) }
);

export type CreateSsoUserMutationVariables = Exact<{
  options: InviteUserInput;
}>;


export type CreateSsoUserMutation = (
  { __typename?: 'Mutation' }
  & { createSsoUser: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type CreateTemplateMutationVariables = Exact<{
  template: CreateTemplateInput;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'isCustom'>
    & { assignedDisplays: (
      { __typename?: 'AssignedDisplays' }
      & Pick<AssignedDisplays, 'totalRecords'>
    ), revision: (
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'configuration'>
    ) }
  )> }
);

export type DeleteAddressMutationVariables = Exact<{
  options: DeleteAddressInput;
}>;


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & { deleteAddress: (
    { __typename?: 'DeleteAddressResult' }
    & { address?: Maybe<(
      { __typename: 'AddressIdOnly' }
      & Pick<AddressIdOnly, 'id'>
    )> }
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  options: DeleteCategoryInput;
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory: (
    { __typename?: 'SimpleUpdateResult' }
    & Pick<SimpleUpdateResult, 'recordsUpdated'>
  ) }
);

export type DeleteCategoryOptionMutationVariables = Exact<{
  options: DeleteCategoryOptionInput;
}>;


export type DeleteCategoryOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategoryOption: (
    { __typename?: 'SimpleUpdateResult' }
    & Pick<SimpleUpdateResult, 'recordsUpdated'>
  ) }
);

export type DeletePodMutationVariables = Exact<{
  options: DeletePodInput;
}>;


export type DeletePodMutation = (
  { __typename?: 'Mutation' }
  & { deletePod: (
    { __typename?: 'DeletePodResult' }
    & Pick<DeletePodResult, 'podId'>
  ) }
);

export type DeletePodsMutationVariables = Exact<{
  options: DeletePodsInput;
}>;


export type DeletePodsMutation = (
  { __typename?: 'Mutation' }
  & { deletePods: (
    { __typename?: 'DeletePodsResult' }
    & Pick<DeletePodsResult, 'podIds'>
  ) }
);

export type DeleteSolsticeRoutingSpaceMutationVariables = Exact<{
  spaceId: Scalars['String'];
}>;


export type DeleteSolsticeRoutingSpaceMutation = (
  { __typename?: 'Mutation' }
  & { deleteSolsticeRoutingSpace: (
    { __typename?: 'EmptyResponse' }
    & Pick<EmptyResponse, 'success'>
  ) }
);

export type DeleteTemplateMutationVariables = Exact<{
  template: DeleteTemplateInput;
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplate: (
    { __typename?: 'EmptyResponse' }
    & Pick<EmptyResponse, 'success'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  options: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type FinishJoyrideMutationVariables = Exact<{
  options: JoyrideOption;
}>;


export type FinishJoyrideMutation = (
  { __typename?: 'Mutation' }
  & { finishJoyride?: Maybe<(
    { __typename?: 'Joyride' }
    & Pick<Joyride, 'joyride' | 'finishedOn'>
  )> }
);

export type InviteUserMutationVariables = Exact<{
  options: InviteUserInput;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteUser: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type RecordLastLoginMutationVariables = Exact<{
  options: RecordLastLoginUserInput;
}>;


export type RecordLastLoginMutation = (
  { __typename?: 'Mutation' }
  & { recordLastLogin: (
    { __typename?: 'EmptyResponse' }
    & Pick<EmptyResponse, 'success'>
  ) }
);

export type ResetJoyrideMutationVariables = Exact<{
  options: JoyrideOption;
}>;


export type ResetJoyrideMutation = (
  { __typename?: 'Mutation' }
  & { resetJoyride?: Maybe<(
    { __typename?: 'Joyride' }
    & Pick<Joyride, 'joyride' | 'finishedOn'>
  )> }
);

export type UnassignTemplateMutationVariables = Exact<{
  options: UnassignTemplateOptions;
}>;


export type UnassignTemplateMutation = (
  { __typename?: 'Mutation' }
  & { unassignTemplate: (
    { __typename?: 'UnassignTemplateResult' }
    & Pick<UnassignTemplateResult, 'affectedIds'>
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  options: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'CategoryMutationResponse' }
    & Pick<CategoryMutationResponse, 'categoryId' | 'categoryName'>
  ) }
);

export type UpdateCategoryOptionMutationVariables = Exact<{
  options: UpdateCategoryOptionInput;
}>;


export type UpdateCategoryOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateCategoryOption: (
    { __typename?: 'CategoryOptionMutationResponse' }
    & Pick<CategoryOptionMutationResponse, 'optionId' | 'optionName' | 'categoryId'>
  ) }
);

export type UpdateDisplayMutationVariables = Exact<{
  options: UpdateDisplayInput;
}>;


export type UpdateDisplayMutation = (
  { __typename?: 'Mutation' }
  & { updateDisplay: (
    { __typename?: 'UpdateDisplayResult' }
    & { display?: Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name' | 'isOnline' | 'hasPendingChanges'>
      & { assignedTemplates: Array<Maybe<(
        { __typename?: 'AssignedTemplate' }
        & Pick<AssignedTemplate, 'hasPendingChanges'>
        & { template?: Maybe<(
          { __typename?: 'Template' }
          & TemplateFragment
        )>, settingsGroup?: Maybe<(
          { __typename?: 'SettingsGroup' }
          & Pick<SettingsGroup, 'type'>
        )> }
      )>> }
    )>, validation?: Maybe<(
      { __typename?: 'UniqueSettingsCompatibility' }
      & UniqueSettingsValidationFragment
    )> }
  ) }
);

export type UpdateScheduledTaskMutationVariables = Exact<{
  options: UpdateScheduledTaskInput;
}>;


export type UpdateScheduledTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateScheduledTask?: Maybe<(
    { __typename?: 'ScheduledTask' }
    & Pick<ScheduledTask, 'id'>
  )> }
);

export type UpdateSolsticeRoutingOrgMutationVariables = Exact<{
  options: UpdateSolsticeRoutingOrgInput;
}>;


export type UpdateSolsticeRoutingOrgMutation = (
  { __typename?: 'Mutation' }
  & { updateSolsticeRoutingOrg?: Maybe<(
    { __typename?: 'SolsticeRoutingOrg' }
    & Pick<SolsticeRoutingOrg, 'id' | 'ex_id' | 'require_password_on_exit' | 'ex_name' | 'updated_at' | 'created_at'>
  )> }
);

export type UpdateSolsticeRoutingSpaceMutationVariables = Exact<{
  options: UpdateSolsticeRoutingSpaceInput;
}>;


export type UpdateSolsticeRoutingSpaceMutation = (
  { __typename?: 'Mutation' }
  & { updateSolsticeRoutingSpace: (
    { __typename?: 'SolsticeRoutingSpace' }
    & Pick<SolsticeRoutingSpace, 'id' | 'name' | 'org' | 'partition' | 'building_name' | 'space_type' | 'show_routing_banner' | 'require_screen_key_verification' | 'require_password_on_exit' | 'room_number' | 'moderator_sharing_mode' | 'primary_pod'>
    & { pods?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingPod' }
      & Pick<SolsticeRoutingPod, 'id' | 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'name'>
    )>>>, route_presets?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingRoutePreset' }
      & Pick<SolsticeRoutingRoutePreset, 'name'>
      & { routes?: Maybe<Array<Maybe<(
        { __typename?: 'SolsticeRoutingRoute' }
        & { sink_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'id'>
        )>, source_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'color' | 'audio_routing_enabled' | 'row' | 'column' | 'id'>
        )> }
      )>>> }
    )>>> }
  ) }
);

export type UpdateSsoStatusMutationVariables = Exact<{
  options: UpdateSsoStatusInput;
}>;


export type UpdateSsoStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateSsoStatus: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type UpdateSupportAccessMutationVariables = Exact<{
  options?: Maybe<UpdateSupportInput>;
}>;


export type UpdateSupportAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateSupportAccess?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'grant_support_access_until'>
  )> }
);

export type UpdateTemplateMutationVariables = Exact<{
  template: UpdateTemplateInput;
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate: (
    { __typename?: 'UpdateTemplateResult' }
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name' | 'isCustom' | 'isDefault'>
      & { settingsGroup: (
        { __typename?: 'SettingsGroup' }
        & Pick<SettingsGroup, 'name'>
      ), assignedDisplays: (
        { __typename?: 'AssignedDisplays' }
        & Pick<AssignedDisplays, 'totalRecords'>
      ), revision: (
        { __typename?: 'Revision' }
        & Pick<Revision, 'id' | 'configuration'>
      ) }
    ), validation: (
      { __typename?: 'UniqueSettingsCompatibility' }
      & UniqueSettingsValidationFragment
    ) }
  ) }
);

export type UpdateUserRoleMutationVariables = Exact<{
  options: UpdateUserRoleInput;
}>;


export type UpdateUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateUserRole: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type CreateUpdateSsoMutationVariables = Exact<{
  options: CreateSsoOrgInput;
}>;


export type CreateUpdateSsoMutation = (
  { __typename?: 'Mutation' }
  & { createSSOOrg: (
    { __typename?: 'CreateSSOOrgResult' }
    & Pick<CreateSsoOrgResult, 'created' | 'updated' | 'idp_id' | 'idp_url' | 'idp_cert_timestamp'>
  ) }
);

export type ActiveLearningDisplayMetricsQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<CategoryFilter> | CategoryFilter>;
  locationFilters?: Maybe<LocationFilters>;
}>;


export type ActiveLearningDisplayMetricsQuery = (
  { __typename?: 'Query' }
  & { currentSubscriptionDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, expiringSoonDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, trialDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, readyToEnableDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )> }
);

export type ActiveLearningDisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type ActiveLearningDisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name' | 'catchingUp'>
      & { versions: (
        { __typename?: 'DisplayVersions' }
        & Pick<DisplayVersions, 'software'>
      ), activeLearning?: Maybe<(
        { __typename?: 'ActiveLearning' }
        & Pick<ActiveLearning, 'status' | 'licenseEndDate'>
        & { rooms: Array<(
          { __typename?: 'ActiveLearningRoom' }
          & Pick<ActiveLearningRoom, 'id' | 'name'>
        )>, trial?: Maybe<(
          { __typename?: 'ActiveLearningTrial' }
          & Pick<ActiveLearningTrial, 'endDate' | 'isEligible'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ActiveLearningRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveLearningRoomsQuery = (
  { __typename?: 'Query' }
  & { activeLearningRooms?: Maybe<Array<Maybe<(
    { __typename?: 'ActiveLearningRoom' }
    & Pick<ActiveLearningRoom, 'id' | 'name'>
  )>>> }
);

export type ActiveMeetingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveMeetingsQuery = (
  { __typename?: 'Query' }
  & { activeMeetings?: Maybe<(
    { __typename?: 'ActiveMeetings' }
    & Pick<ActiveMeetings, 'count'>
  )> }
);

export type AllDisplayIdsQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type AllDisplayIdsQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id'>
    )>>> }
  )> }
);

export type AllDisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type AllDisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name' | 'syncState' | 'isOnline' | 'isManageable' | 'managementStatus' | 'hasPendingChanges' | 'ipAddressesPrimary'>
      & { versions: (
        { __typename?: 'DisplayVersions' }
        & Pick<DisplayVersions, 'hardware' | 'software' | 'lms'>
      ), assignedTemplates: Array<Maybe<(
        { __typename?: 'AssignedTemplate' }
        & Pick<AssignedTemplate, 'syncState' | 'hasPendingChanges'>
        & { template?: Maybe<(
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name' | 'isCustom'>
          & { settingsGroup: (
            { __typename?: 'SettingsGroup' }
            & Pick<SettingsGroup, 'type'>
          ) }
        )>, settingsGroup?: Maybe<(
          { __typename?: 'SettingsGroup' }
          & Pick<SettingsGroup, 'type'>
        )> }
      )>> }
    )>>> }
  )> }
);

export type AnalyticCountsQueryVariables = Exact<{
  options: AnalyticCountsOptions;
}>;


export type AnalyticCountsQuery = (
  { __typename?: 'Query' }
  & { analyticCounts?: Maybe<(
    { __typename?: 'AnalyticCounts' }
    & Pick<AnalyticCounts, 'medianMeeting' | 'busiestHour' | 'uniqueUsers'>
  )> }
);

export type AvailabilityQueryVariables = Exact<{
  options?: Maybe<AvailabilityOptions>;
}>;


export type AvailabilityQuery = (
  { __typename?: 'Query' }
  & { availability?: Maybe<(
    { __typename?: 'Availability' }
    & { pods: Array<(
      { __typename?: 'AvailabilityByDisplay' }
      & Pick<AvailabilityByDisplay, 'org' | 'displayId' | 'name' | 'version' | 'lastOnline' | 'catchingUp' | 'status' | 'licenseShortName'>
    )>, aggregate: (
      { __typename?: 'AvailabilityAggregate' }
      & Pick<AvailabilityAggregate, 'pods' | 'subscribedPods' | 'podsOnlineNow' | 'podsOnlineSinceYesterday'>
    ) }
  )> }
);

export type CategoryManagerPodsQueryVariables = Exact<{
  options?: Maybe<CategoryManagerPodOptions>;
}>;


export type CategoryManagerPodsQuery = (
  { __typename?: 'Query' }
  & { categoryManagerPods: Array<(
    { __typename?: 'CategoryManagerPod' }
    & Pick<CategoryManagerPod, 'displayId' | 'displayName'>
    & { categories: Array<(
      { __typename?: 'AppliedCategory' }
      & Pick<AppliedCategory, 'categoryId' | 'name'>
      & { option: (
        { __typename?: 'CategoryOption' }
        & Pick<CategoryOption, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CategoryMetricsByDateQueryVariables = Exact<{
  options: CategoryMetricSearchOptions;
}>;


export type CategoryMetricsByDateQuery = (
  { __typename?: 'Query' }
  & { categoryMetricsByDate?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryMetricsByDate' }
    & Pick<CategoryMetricsByDate, 'date'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'TotalByOption' }
      & Pick<TotalByOption, 'total'>
      & { option?: Maybe<(
        { __typename?: 'CategoryOption' }
        & Pick<CategoryOption, 'id' | 'name'>
      )> }
    )>>> }
  )>>> }
);

export type GetConfigDiffQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetConfigDiffQuery = (
  { __typename?: 'Query' }
  & { configDiff?: Maybe<(
    { __typename?: 'ConfigDiffResult' }
    & Pick<ConfigDiffResult, 'diffObject'>
  )> }
);

export type CurrentScheduledTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentScheduledTasksQuery = (
  { __typename?: 'Query' }
  & { scheduledTasks: Array<Maybe<(
    { __typename?: 'ScheduledTask' }
    & ScheduledTaskFragment
  )>> }
);

export type CustomCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { options: Array<(
      { __typename?: 'CategoryOption' }
      & Pick<CategoryOption, 'id' | 'name' | 'appliedDisplayCount'>
    )> }
  )> }
);

export type DeploymentsQueryVariables = Exact<{
  options?: Maybe<DeploymentsPageOptions>;
}>;


export type DeploymentsQuery = (
  { __typename?: 'Query' }
  & { deployments?: Maybe<(
    { __typename?: 'DeploymentsPage' }
    & { pods: Array<(
      { __typename?: 'DeploymentsByDisplay' }
      & Pick<DeploymentsByDisplay, 'name' | 'orgId' | 'version' | 'hardware' | 'displayId' | 'serialId' | 'isOnline' | 'hasUnconfirmedTemplates' | 'isInSubscription' | 'onboardDate' | 'deployDate' | 'catchingUp' | 'flexeraMaintenanceEnd' | 'maintenanceEnd' | 'ipPrimary' | 'ipWifi' | 'license' | 'licenseShortName' | 'macPrimary' | 'macWifi' | 'sdsEnabled' | 'sdsHost1' | 'sdsHost2' | 'status'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'tag' | 'color'>
      )>>> }
    )>, aggregate?: Maybe<(
      { __typename?: 'DeploymentsPageAggregate' }
      & Pick<DeploymentsPageAggregate, 'totalPods' | 'totalCurrentLmsVersionPods' | 'totalExpiringPods' | 'totalExpiredPods'>
    )> }
  )> }
);

export type DisplayQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DisplayQuery = (
  { __typename?: 'Query' }
  & { display?: Maybe<(
    { __typename?: 'Display' }
    & Pick<Display, 'id' | 'uuid' | 'serialId' | 'name' | 'isOnline' | 'hasPendingChanges' | 'syncState' | 'managementStatus' | 'macAddressesPrimary' | 'macAddressesWifi' | 'catchingUp'>
    & { versions: (
      { __typename?: 'DisplayVersions' }
      & Pick<DisplayVersions, 'hardware' | 'software' | 'lms'>
    ), categories?: Maybe<Array<Maybe<(
      { __typename?: 'AppliedCategory' }
      & Pick<AppliedCategory, 'categoryId' | 'id'>
    )>>>, assignedTemplates: Array<Maybe<(
      { __typename?: 'AssignedTemplate' }
      & Pick<AssignedTemplate, 'hasPendingChanges' | 'syncState'>
      & { template?: Maybe<(
        { __typename?: 'Template' }
        & Pick<Template, 'id' | 'name' | 'isCustom'>
        & { assignedDisplays: (
          { __typename?: 'AssignedDisplays' }
          & { compatibilityAggregate?: Maybe<(
            { __typename?: 'DisplayCompatibilityAggregate' }
            & Pick<DisplayCompatibilityAggregate, 'minSoftware' | 'minHardware' | 'minLms'>
          )> }
        ), settingsGroup: (
          { __typename?: 'SettingsGroup' }
          & Pick<SettingsGroup, 'type' | 'hidden' | 'name'>
          & { settings?: Maybe<Array<Maybe<(
            { __typename?: 'ConfigurationSetting' }
            & Pick<ConfigurationSetting, 'key' | 'value'>
            & { compatibility?: Maybe<(
              { __typename?: 'Compatibility' }
              & Pick<Compatibility, 'minSoftware' | 'minHardware' | 'hardRequirement'>
            )> }
          )>>> }
        ), revision: (
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'configuration'>
        ) }
      )>, settingsGroup?: Maybe<(
        { __typename?: 'SettingsGroup' }
        & Pick<SettingsGroup, 'type'>
      )> }
    )>> }
  )>, templateCompatibility?: Maybe<Array<Maybe<(
    { __typename?: 'TemplateCompatibility' }
    & Pick<TemplateCompatibility, 'templateId' | 'isCompatible' | 'incompatibleReason'>
  )>>>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { options: Array<(
      { __typename?: 'CategoryOption' }
      & Pick<CategoryOption, 'id' | 'name'>
    )> }
  )> }
);

export type ElementPodsQueryVariables = Exact<{ [key: string]: never; }>;


export type ElementPodsQuery = (
  { __typename?: 'Query' }
  & { elementPods?: Maybe<(
    { __typename?: 'ElementPods' }
    & Pick<ElementPods, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ElementPod' }
      & Pick<ElementPod, 'id' | 'org'>
    )>>> }
  )> }
);

export type HistoricalScheduledTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type HistoricalScheduledTasksQuery = (
  { __typename?: 'Query' }
  & { scheduledTasks: Array<Maybe<(
    { __typename?: 'ScheduledTask' }
    & ScheduledTaskFragment
  )>> }
);

export type JoyridesQueryVariables = Exact<{ [key: string]: never; }>;


export type JoyridesQuery = (
  { __typename?: 'Query' }
  & { joyrides: Array<(
    { __typename?: 'Joyride' }
    & Pick<Joyride, 'joyride' | 'finishedOn'>
  )> }
);

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = (
  { __typename?: 'Query' }
  & { languages?: Maybe<Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'text' | 'value'>
  )>> }
);

export type LocationCategoriesQueryVariables = Exact<{
  options?: Maybe<AddressOptions>;
}>;


export type LocationCategoriesQuery = (
  { __typename?: 'Query' }
  & { addresses: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'nickname' | 'building' | 'city' | 'state_province' | 'country' | 'num_displays'>
  )> }
);

export type ManagementStatusDisplayMetricsQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<CategoryFilter> | CategoryFilter>;
  locationFilters?: Maybe<LocationFilters>;
}>;


export type ManagementStatusDisplayMetricsQuery = (
  { __typename?: 'Query' }
  & { OfflinePending?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, Offline?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, Online?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, Unsupported?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, NotYetDeployed?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )> }
);

export type MetricsByDateQueryVariables = Exact<{
  options: MetricSearchOptions;
}>;


export type MetricsByDateQuery = (
  { __typename?: 'Query' }
  & { metricsByDate?: Maybe<Array<Maybe<(
    { __typename?: 'MetricsByDate' }
    & Pick<MetricsByDate, 'date'>
    & { metrics: Array<Maybe<(
      { __typename?: 'TotalByMetric' }
      & Pick<TotalByMetric, 'total' | 'name'>
    )>> }
  )>>> }
);

export type DisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type DisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name'>
      & { metrics?: Maybe<(
        { __typename?: 'DisplayMetrics' }
        & Pick<DisplayMetrics, 'totalMinutesInUse' | 'totalMeetings' | 'totalPosts' | 'totalUsers' | 'totalMaxOccupancy' | 'totalAverageHourlyOccupancy' | 'totalContentPerMeeting' | 'totalAverageMeetingDuration' | 'totalAverageMeetingRating'>
      )>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'AppliedCategory' }
        & Pick<AppliedCategory, 'categoryId'>
        & { option: (
          { __typename?: 'CategoryOption' }
          & Pick<CategoryOption, 'id' | 'name'>
        ) }
      )>>>, license?: Maybe<(
        { __typename?: 'License' }
        & Pick<License, 'shortName'>
      )>, subscription: (
        { __typename?: 'Subscription' }
        & Pick<Subscription, 'status' | 'expirationDate'>
      ), displayCam?: Maybe<(
        { __typename?: 'DisplayCam' }
        & Pick<DisplayCam, 'cam1' | 'cam2'>
      )> }
    )>>> }
  )> }
);

export type PlatformConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type PlatformConfigQuery = (
  { __typename?: 'Query' }
  & { platformConfig?: Maybe<(
    { __typename?: 'PlatformConfig' }
    & Pick<PlatformConfig, 'hasGen2Pods' | 'hasElementPods'>
  )> }
);

export type RealtimeDisplaysQueryVariables = Exact<{
  options?: Maybe<RealtimeDisplaysOptions>;
}>;


export type RealtimeDisplaysQuery = (
  { __typename?: 'Query' }
  & { realtimeDisplays: Array<(
    { __typename?: 'RealtimeDisplay' }
    & Pick<RealtimeDisplay, 'realtimeStatus' | 'org' | 'display' | 'name' | 'solsticeUserCount' | 'calendarConfigEnabled' | 'isOccupied' | 'footTraffic' | 'currentCalendarStart' | 'currentCalendarEnd' | 'currentCalendarAttendees' | 'nextCalendarStart' | 'nextCalendarEnd' | 'nextCalendarAttendees' | 'building' | 'nickname' | 'preciseLat' | 'preciseLong'>
  )> }
);

export type RecentAlertsQueryVariables = Exact<{
  options: RecentAlertsOptions;
}>;


export type RecentAlertsQuery = (
  { __typename?: 'Query' }
  & { recentAlerts?: Maybe<(
    { __typename?: 'Alert' }
    & Pick<Alert, 'totalDisplays' | 'totalAlerts'>
    & { alerts: Array<Maybe<(
      { __typename?: 'RecentAlert' }
      & Pick<RecentAlert, 'date' | 'totalAlerts'>
    )>> }
  )> }
);

export type SdsPodsPerHostQueryVariables = Exact<{
  options?: Maybe<PodsPerSdsHostOptions>;
}>;


export type SdsPodsPerHostQuery = (
  { __typename?: 'Query' }
  & { podsPerSdsHost?: Maybe<(
    { __typename?: 'PodsPerSdsHost' }
    & { sdsHosts: Array<(
      { __typename?: 'SdsHostPodCount' }
      & Pick<SdsHostPodCount, 'host' | 'podCount'>
    )> }
  )> }
);

export type SecretValidationQueryVariables = Exact<{
  options: SecretVerificationOptions;
}>;


export type SecretValidationQuery = (
  { __typename?: 'Query' }
  & { secretVerification: (
    { __typename?: 'SecretVerificationResult' }
    & Pick<SecretVerificationResult, 'verified'>
  ) }
);

export type SettingsGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsGroupsQuery = (
  { __typename?: 'Query' }
  & { settingsGroups?: Maybe<Array<Maybe<(
    { __typename?: 'SettingsGroup' }
    & Pick<SettingsGroup, 'name' | 'type'>
    & { templates?: Maybe<Array<Maybe<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name'>
    )>>>, settings?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurationSetting' }
      & Pick<ConfigurationSetting, 'key' | 'value'>
      & { compatibility?: Maybe<(
        { __typename?: 'Compatibility' }
        & Pick<Compatibility, 'minSoftware' | 'minHardware' | 'hardRequirement'>
      )> }
    )>>> }
  )>>> }
);

export type SharingDisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type SharingDisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name'>
      & { metrics?: Maybe<(
        { __typename?: 'DisplayMetrics' }
        & Pick<DisplayMetrics, 'totalMinutesInUse' | 'totalConfSessions' | 'totalMeetings'>
      )>, displayCam?: Maybe<(
        { __typename?: 'DisplayCam' }
        & Pick<DisplayCam, 'cam1'>
      )>, license?: Maybe<(
        { __typename?: 'License' }
        & Pick<License, 'shortName'>
      )>, subscription: (
        { __typename?: 'Subscription' }
        & Pick<Subscription, 'status' | 'expirationDate'>
      ) }
    )>>> }
  )> }
);

export type SolsticeRoutingDisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type SolsticeRoutingDisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Display' }
      & Pick<Display, 'id' | 'name' | 'ipAddressesPrimary'>
      & { versions: (
        { __typename?: 'DisplayVersions' }
        & Pick<DisplayVersions, 'hardware' | 'software' | 'lms'>
      ), subscription: (
        { __typename?: 'Subscription' }
        & Pick<Subscription, 'status' | 'expirationDate'>
      ) }
    )>>> }
  )> }
);

export type SolsticeRoutingOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type SolsticeRoutingOrgQuery = (
  { __typename?: 'Query' }
  & { solsticeRoutingOrg: (
    { __typename?: 'SolsticeRoutingOrg' }
    & Pick<SolsticeRoutingOrg, 'id' | 'ex_id' | 'require_password_on_exit' | 'ex_name' | 'updated_at' | 'created_at'>
  ) }
);

export type SolsticeRoutingRoutePresetsQueryVariables = Exact<{
  options?: Maybe<SolsticeRoutingRoutePresetsOptions>;
}>;


export type SolsticeRoutingRoutePresetsQuery = (
  { __typename?: 'Query' }
  & { solsticeRoutingRoutePresets: (
    { __typename?: 'GetAllRoutePresetsResponse' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingRoutePreset' }
      & Pick<SolsticeRoutingRoutePreset, 'id' | 'name' | 'org' | 'created_at' | 'updated_at'>
      & { routes?: Maybe<Array<Maybe<(
        { __typename?: 'SolsticeRoutingRoute' }
        & Pick<SolsticeRoutingRoute, 'id' | 'created_at'>
        & { source_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'id'>
        )>, sink_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'id'>
        )> }
      )>>> }
    )>>> }
  ) }
);

export type SolsticeRoutingSpaceQueryVariables = Exact<{
  spaceId: Scalars['String'];
}>;


export type SolsticeRoutingSpaceQuery = (
  { __typename?: 'Query' }
  & { solsticeRoutingSpace: (
    { __typename?: 'SolsticeRoutingSpace' }
    & Pick<SolsticeRoutingSpace, 'building_name' | 'created_at' | 'id' | 'last_used' | 'moderator_sharing_mode' | 'name' | 'org' | 'partition' | 'primary_pod' | 'require_password_on_exit' | 'require_screen_key_verification' | 'room_number' | 'show_routing_banner' | 'space_type' | 'updated_at'>
    & { pods?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingPod' }
      & Pick<SolsticeRoutingPod, 'id' | 'name' | 'audio_routing_enabled' | 'color' | 'row' | 'column' | 'created_at' | 'updated_at' | 'ipAddressPrimary' | 'ipAddressWifi'>
    )>>>, route_presets?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingRoutePreset' }
      & Pick<SolsticeRoutingRoutePreset, 'id' | 'name'>
      & { routes?: Maybe<Array<Maybe<(
        { __typename?: 'SolsticeRoutingRoute' }
        & { sink_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'id' | 'name'>
        )>, source_pod?: Maybe<(
          { __typename?: 'SolsticeRoutingPod' }
          & Pick<SolsticeRoutingPod, 'id' | 'name'>
        )> }
      )>>> }
    )>>> }
  ) }
);

export type SolsticeRoutingSpacesQueryVariables = Exact<{
  options: SolsticeRoutingSpacesOptions;
}>;


export type SolsticeRoutingSpacesQuery = (
  { __typename?: 'Query' }
  & { solsticeRoutingSpaces: (
    { __typename?: 'GetAllSpacesResponse' }
    & Pick<GetAllSpacesResponse, 'totalItems'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SolsticeRoutingSpace' }
      & Pick<SolsticeRoutingSpace, 'id' | 'name' | 'org' | 'created_at' | 'last_used' | 'building_name' | 'room_number' | 'space_type'>
    )>>> }
  ) }
);

export type SsoInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type SsoInfoQuery = (
  { __typename?: 'Query' }
  & { ssoOrg?: Maybe<(
    { __typename?: 'SSOOrg' }
    & Pick<SsoOrg, 'idp_id' | 'idp_url' | 'idp_entity_id' | 'idp_cert_timestamp'>
  )>, idps?: Maybe<Array<Maybe<(
    { __typename?: 'Idp' }
    & Pick<Idp, 'id' | 'sso_url_regex' | 'name' | 'route' | 'example_idp_url'>
  )>>> }
);

export type GetSupportAccessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupportAccessQuery = (
  { __typename?: 'Query' }
  & { supportAccess?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'grant_support_access_until'>
  )> }
);

export type TemplateCompatibilityQueryVariables = Exact<{
  options?: Maybe<TemplateCompatibilityOptions>;
}>;


export type TemplateCompatibilityQuery = (
  { __typename?: 'Query' }
  & { templateCompatibility?: Maybe<Array<Maybe<(
    { __typename?: 'TemplateCompatibility' }
    & Pick<TemplateCompatibility, 'templateId' | 'isCompatible' | 'isHardRequirement' | 'incompatibleReason'>
  )>>> }
);

export type TemplateListQueryVariables = Exact<{
  options: TemplateSearchOptions;
}>;


export type TemplateListQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'Template' }
    & TemplateFragment
  )>>> }
);

export type TimeZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeZonesQuery = (
  { __typename?: 'Query' }
  & { timeZones?: Maybe<Array<(
    { __typename?: 'TimeZone' }
    & Pick<TimeZone, 'text' | 'value'>
  )>> }
);

export type TotalDisplaysQueryVariables = Exact<{
  options: DisplaySearchOptions;
}>;


export type TotalDisplaysQuery = (
  { __typename?: 'Query' }
  & { displays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )> }
);

export type TotalDisplaysNoViewQueryVariables = Exact<{
  options?: Maybe<TotalDisplaysNoViewOptions>;
}>;


export type TotalDisplaysNoViewQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalDisplays'>
);

export type TotalSelectableDisplaysQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<CategoryFilter> | CategoryFilter>;
  locationFilters?: Maybe<LocationFilters>;
  displayIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  excludeDisplayIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  isIncludedElementPods?: Maybe<Scalars['Boolean']>;
}>;


export type TotalSelectableDisplaysQuery = (
  { __typename?: 'Query' }
  & { totalSelectableDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )>, totalSelectedDisplays?: Maybe<(
    { __typename?: 'Displays' }
    & Pick<Displays, 'totalRecords'>
  )> }
);

export type UpdateablePodsQueryVariables = Exact<{
  options?: Maybe<UpdateablePodsOptions>;
}>;


export type UpdateablePodsQuery = (
  { __typename?: 'Query' }
  & { updateablePods: (
    { __typename?: 'UpdateablePods' }
    & Pick<UpdateablePods, 'totalPodsCount' | 'updateablePodsCount'>
    & { pods: Array<(
      { __typename?: 'UpdateablePod' }
      & Pick<UpdateablePod, 'displayId' | 'name' | 'version' | 'updateable' | 'updating' | 'status' | 'catchingUp' | 'subscriptionEndDate' | 'licenseShortName' | 'hardware'>
    )> }
  ) }
);

export type UpdateablePodsByVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateablePodsByVersionQuery = (
  { __typename?: 'Query' }
  & { updateablePodsByVersion: Array<(
    { __typename?: 'UpdateablePodsByVersion' }
    & Pick<UpdateablePodsByVersion, 'version' | 'count'>
  )> }
);

export type UpdateablePodsCountQueryVariables = Exact<{
  options?: Maybe<UpdateablePodsOptions>;
}>;


export type UpdateablePodsCountQuery = (
  { __typename?: 'Query' }
  & { updateablePods: (
    { __typename?: 'UpdateablePods' }
    & Pick<UpdateablePods, 'totalPodsCount' | 'updateablePodsCount'>
  ) }
);

export type UsageByRoomTypeQueryVariables = Exact<{
  options: UsageByRoomTypeOptions;
}>;


export type UsageByRoomTypeQuery = (
  { __typename?: 'Query' }
  & { usageByRoomType?: Maybe<(
    { __typename?: 'UsageByRoomType' }
    & { roomTypes: Array<Maybe<(
      { __typename?: 'RoomTypeUsage' }
      & Pick<RoomTypeUsage, 'id' | 'name' | 'totalMeetingLength'>
    )>> }
  )> }
);

export type UsageGeoDataQueryVariables = Exact<{
  options: UsageGeoDataOptions;
}>;


export type UsageGeoDataQuery = (
  { __typename?: 'Query' }
  & { usageGeoData?: Maybe<(
    { __typename?: 'UsageGeoData' }
    & { mapData: Array<Maybe<(
      { __typename?: 'MapData' }
      & Pick<MapData, 'outOfTemplateCount' | 'id' | 'name' | 'preciseLat' | 'preciseLong' | 'restartCount' | 'totalAlerts' | 'unreachableCount' | 'usageHours' | 'usbAddedEventCount' | 'usbRemovedEventCount'>
    )>> }
  )> }
);

export type UsbDeviceConnectionsQueryVariables = Exact<{
  options?: Maybe<UsbPageOptions>;
}>;


export type UsbDeviceConnectionsQuery = (
  { __typename?: 'Query' }
  & { usbDevices?: Maybe<(
    { __typename?: 'UsbPage' }
    & Pick<UsbPage, 'usbDeviceCount' | 'usbOnlineDeviceCount' | 'usbOfflineDeviceCount' | 'usbAvDeviceCount' | 'usbAvOthersDeviceCount' | 'podsOnlineCount' | 'podsWithDevicesOnlineCount' | 'plugCountSum' | 'unplugCountSum' | 'productConnectionsCount'>
    & { usbDevices: Array<(
      { __typename?: 'UsbDevice' }
      & Pick<UsbDevice, 'avDevice' | 'deviceId' | 'deviceName' | 'deviceProtocol' | 'deviceSubclass' | 'displayId' | 'fullProductName' | 'isConferenceReady' | 'isOccupancyReady' | 'isOnline' | 'isUpdatable' | 'occupancyCountingEnabled' | 'orgId' | 'partition' | 'plugOrInplug' | 'productId' | 'productName' | 'relayEnabled' | 'usbPlugTimestamp' | 'vendorId' | 'vendorName' | 'version'>
      & { display?: Maybe<(
        { __typename?: 'UsbDisplay' }
        & Pick<UsbDisplay, 'org' | 'id' | 'hardware' | 'version' | 'isInSubscription' | 'name'>
      )> }
    )>, history?: Maybe<Array<Maybe<(
      { __typename?: 'UsbDeviceHistory' }
      & Pick<UsbDeviceHistory, 'date' | 'plugCount' | 'unplugCount'>
    )>>>, products: Array<(
      { __typename?: 'UsbProduct' }
      & Pick<UsbProduct, 'productId' | 'productName' | 'displayCount'>
    )> }
  )> }
);

export type UserDevicesQueryVariables = Exact<{
  options: AnalyticCountsOptions;
}>;


export type UserDevicesQuery = (
  { __typename?: 'Query' }
  & { userDevices?: Maybe<Array<Maybe<(
    { __typename?: 'ClientPlatform' }
    & Pick<ClientPlatform, 'name' | 'total'>
  )>>> }
);

export type VersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionsQuery = (
  { __typename?: 'Query' }
  & { versions?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & { releases?: Maybe<Array<Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'version' | 'customerFacingVersion' | 'date'>
    )>>> }
  )>>> }
);

export const ScheduledTaskFragmentDoc = gql`
    fragment ScheduledTask on ScheduledTask {
  id
  type
  updateType
  startDate
  finishedOn
  data {
    versionToUpdate
  }
  displays {
    status
    errorMessage
    displayId
    downloadPercentage
    timezone
    displayName
  }
}
    `;
export const TemplateFragmentDoc = gql`
    fragment Template on Template {
  id
  name
  org
  assignedDisplays {
    compatibilityAggregate {
      minSoftware
      minHardware
      minLms
    }
    totalRecords
  }
  settingsGroup {
    type
    hidden
    name
    settings {
      key
      value
      compatibility {
        minSoftware
        minHardware
        minLms
        hardRequirement
      }
      settingsGroupType
    }
  }
  isCustom
  isDefault
  revision {
    id
    configuration
  }
}
    `;
export const UniqueSettingsValidationFragmentDoc = gql`
    fragment UniqueSettingsValidation on UniqueSettingsCompatibility {
  incompatibleDisplays {
    id
    name
    versions {
      hardware
      software
      lms
    }
    assignedTemplate(settingsGroupType: UNIQUE_SETTINGS) {
      template {
        revision {
          id
          configuration
        }
      }
    }
  }
  fieldValidation {
    CALENDAR {
      exchangeSettings {
        impersonationMailbox {
          required
        }
        delegationMailbox {
          required
        }
        userAccount {
          required
        }
        userPassword {
          secret {
            required
          }
        }
      }
      office365Settings {
        username {
          required
        }
      }
      googleSettings {
        roomMailbox {
          required
        }
      }
    }
    ETHERNET {
      ipAddress {
        required
      }
      vlans {
        ipAddress {
          required
        }
      }
    }
    WELCOME_SCREEN {
      displayName {
        required
      }
    }
    WIFI {
      ipAddress {
        required
      }
      wapSsid {
        required
      }
    }
  }
}
    `;
export const ApplyCategoryDocument = gql`
    mutation ApplyCategory($options: CategoryApplication!) {
  applyCategory(options: $options) {
    recordsUpdated
  }
}
    `;
export type ApplyCategoryMutationFn = Apollo.MutationFunction<ApplyCategoryMutation, ApplyCategoryMutationVariables>;

/**
 * __useApplyCategoryMutation__
 *
 * To run a mutation, you first call `useApplyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCategoryMutation, { data, loading, error }] = useApplyCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useApplyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCategoryMutation, ApplyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCategoryMutation, ApplyCategoryMutationVariables>(ApplyCategoryDocument, options);
      }
export type ApplyCategoryMutationHookResult = ReturnType<typeof useApplyCategoryMutation>;
export type ApplyCategoryMutationResult = Apollo.MutationResult<ApplyCategoryMutation>;
export type ApplyCategoryMutationOptions = Apollo.BaseMutationOptions<ApplyCategoryMutation, ApplyCategoryMutationVariables>;
export const AssignTemplateDocument = gql`
    mutation AssignTemplate($options: AssignTemplateOptions!) {
  assignTemplate(options: $options) {
    template {
      id
      name
      isCustom
      settingsGroup {
        type
        name
      }
    }
    validation {
      ...UniqueSettingsValidation
    }
  }
}
    ${UniqueSettingsValidationFragmentDoc}`;
export type AssignTemplateMutationFn = Apollo.MutationFunction<AssignTemplateMutation, AssignTemplateMutationVariables>;

/**
 * __useAssignTemplateMutation__
 *
 * To run a mutation, you first call `useAssignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTemplateMutation, { data, loading, error }] = useAssignTemplateMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAssignTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AssignTemplateMutation, AssignTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTemplateMutation, AssignTemplateMutationVariables>(AssignTemplateDocument, options);
      }
export type AssignTemplateMutationHookResult = ReturnType<typeof useAssignTemplateMutation>;
export type AssignTemplateMutationResult = Apollo.MutationResult<AssignTemplateMutation>;
export type AssignTemplateMutationOptions = Apollo.BaseMutationOptions<AssignTemplateMutation, AssignTemplateMutationVariables>;
export const CancelPendingDocument = gql`
    mutation cancelPending($options: CancelPendingChangesInput!) {
  cancelPendingChanges(options: $options) {
    id
    name
    isOnline
    hasPendingChanges
    assignedTemplates {
      template {
        id
        name
        isCustom
        assignedDisplays {
          compatibilityAggregate {
            minSoftware
            minHardware
            minLms
          }
        }
        settingsGroup {
          type
          hidden
          name
          settings {
            key
            value
            compatibility {
              minSoftware
              minHardware
              hardRequirement
            }
          }
        }
        revision {
          id
          configuration
        }
      }
      hasPendingChanges
      settingsGroup {
        type
      }
    }
  }
}
    `;
export type CancelPendingMutationFn = Apollo.MutationFunction<CancelPendingMutation, CancelPendingMutationVariables>;

/**
 * __useCancelPendingMutation__
 *
 * To run a mutation, you first call `useCancelPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPendingMutation, { data, loading, error }] = useCancelPendingMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCancelPendingMutation(baseOptions?: Apollo.MutationHookOptions<CancelPendingMutation, CancelPendingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPendingMutation, CancelPendingMutationVariables>(CancelPendingDocument, options);
      }
export type CancelPendingMutationHookResult = ReturnType<typeof useCancelPendingMutation>;
export type CancelPendingMutationResult = Apollo.MutationResult<CancelPendingMutation>;
export type CancelPendingMutationOptions = Apollo.BaseMutationOptions<CancelPendingMutation, CancelPendingMutationVariables>;
export const CreateActiveLearningTrialLicenseDocument = gql`
    mutation createActiveLearningTrialLicense($options: CreateActiveLearningTrialLicenseInput!) {
  createActiveLearningTrialLicense(options: $options) {
    id
  }
}
    `;
export type CreateActiveLearningTrialLicenseMutationFn = Apollo.MutationFunction<CreateActiveLearningTrialLicenseMutation, CreateActiveLearningTrialLicenseMutationVariables>;

/**
 * __useCreateActiveLearningTrialLicenseMutation__
 *
 * To run a mutation, you first call `useCreateActiveLearningTrialLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActiveLearningTrialLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActiveLearningTrialLicenseMutation, { data, loading, error }] = useCreateActiveLearningTrialLicenseMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateActiveLearningTrialLicenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateActiveLearningTrialLicenseMutation, CreateActiveLearningTrialLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActiveLearningTrialLicenseMutation, CreateActiveLearningTrialLicenseMutationVariables>(CreateActiveLearningTrialLicenseDocument, options);
      }
export type CreateActiveLearningTrialLicenseMutationHookResult = ReturnType<typeof useCreateActiveLearningTrialLicenseMutation>;
export type CreateActiveLearningTrialLicenseMutationResult = Apollo.MutationResult<CreateActiveLearningTrialLicenseMutation>;
export type CreateActiveLearningTrialLicenseMutationOptions = Apollo.BaseMutationOptions<CreateActiveLearningTrialLicenseMutation, CreateActiveLearningTrialLicenseMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($options: CreateCategoryInput!) {
  createCategory(options: $options) {
    categoryId
    categoryName
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateCategoryOptionDocument = gql`
    mutation CreateCategoryOption($options: CreateCategoryOptionInput!) {
  createCategoryOption(options: $options) {
    optionId
    optionName
    categoryId
  }
}
    `;
export type CreateCategoryOptionMutationFn = Apollo.MutationFunction<CreateCategoryOptionMutation, CreateCategoryOptionMutationVariables>;

/**
 * __useCreateCategoryOptionMutation__
 *
 * To run a mutation, you first call `useCreateCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryOptionMutation, { data, loading, error }] = useCreateCategoryOptionMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryOptionMutation, CreateCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryOptionMutation, CreateCategoryOptionMutationVariables>(CreateCategoryOptionDocument, options);
      }
export type CreateCategoryOptionMutationHookResult = ReturnType<typeof useCreateCategoryOptionMutation>;
export type CreateCategoryOptionMutationResult = Apollo.MutationResult<CreateCategoryOptionMutation>;
export type CreateCategoryOptionMutationOptions = Apollo.BaseMutationOptions<CreateCategoryOptionMutation, CreateCategoryOptionMutationVariables>;
export const CreateScheduledTaskDocument = gql`
    mutation createScheduledTask($options: CreateScheduledTaskInput!) {
  createScheduledTask(options: $options) {
    id
  }
}
    `;
export type CreateScheduledTaskMutationFn = Apollo.MutationFunction<CreateScheduledTaskMutation, CreateScheduledTaskMutationVariables>;

/**
 * __useCreateScheduledTaskMutation__
 *
 * To run a mutation, you first call `useCreateScheduledTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledTaskMutation, { data, loading, error }] = useCreateScheduledTaskMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateScheduledTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduledTaskMutation, CreateScheduledTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduledTaskMutation, CreateScheduledTaskMutationVariables>(CreateScheduledTaskDocument, options);
      }
export type CreateScheduledTaskMutationHookResult = ReturnType<typeof useCreateScheduledTaskMutation>;
export type CreateScheduledTaskMutationResult = Apollo.MutationResult<CreateScheduledTaskMutation>;
export type CreateScheduledTaskMutationOptions = Apollo.BaseMutationOptions<CreateScheduledTaskMutation, CreateScheduledTaskMutationVariables>;
export const CreateSolsticeRoutingSpaceDocument = gql`
    mutation CreateSolsticeRoutingSpace($options: CreateSolsticeRoutingSpaceInput!) {
  createSolsticeRoutingSpace(options: $options) {
    id
    name
    org
    partition
    building_name
    space_type
    show_routing_banner
    require_screen_key_verification
    require_password_on_exit
    moderator_sharing_mode
    room_number
    primary_pod
    pods {
      id
      color
      audio_routing_enabled
      row
      column
      name
    }
    route_presets {
      name
      routes {
        sink_pod {
          color
          audio_routing_enabled
          row
          column
          id
        }
        source_pod {
          color
          audio_routing_enabled
          row
          column
          id
        }
      }
    }
  }
}
    `;
export type CreateSolsticeRoutingSpaceMutationFn = Apollo.MutationFunction<CreateSolsticeRoutingSpaceMutation, CreateSolsticeRoutingSpaceMutationVariables>;

/**
 * __useCreateSolsticeRoutingSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSolsticeRoutingSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSolsticeRoutingSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSolsticeRoutingSpaceMutation, { data, loading, error }] = useCreateSolsticeRoutingSpaceMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateSolsticeRoutingSpaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSolsticeRoutingSpaceMutation, CreateSolsticeRoutingSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSolsticeRoutingSpaceMutation, CreateSolsticeRoutingSpaceMutationVariables>(CreateSolsticeRoutingSpaceDocument, options);
      }
export type CreateSolsticeRoutingSpaceMutationHookResult = ReturnType<typeof useCreateSolsticeRoutingSpaceMutation>;
export type CreateSolsticeRoutingSpaceMutationResult = Apollo.MutationResult<CreateSolsticeRoutingSpaceMutation>;
export type CreateSolsticeRoutingSpaceMutationOptions = Apollo.BaseMutationOptions<CreateSolsticeRoutingSpaceMutation, CreateSolsticeRoutingSpaceMutationVariables>;
export const CreateSsoUserDocument = gql`
    mutation CreateSsoUser($options: InviteUserInput!) {
  createSsoUser(options: $options) {
    email
  }
}
    `;
export type CreateSsoUserMutationFn = Apollo.MutationFunction<CreateSsoUserMutation, CreateSsoUserMutationVariables>;

/**
 * __useCreateSsoUserMutation__
 *
 * To run a mutation, you first call `useCreateSsoUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSsoUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSsoUserMutation, { data, loading, error }] = useCreateSsoUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateSsoUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSsoUserMutation, CreateSsoUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSsoUserMutation, CreateSsoUserMutationVariables>(CreateSsoUserDocument, options);
      }
export type CreateSsoUserMutationHookResult = ReturnType<typeof useCreateSsoUserMutation>;
export type CreateSsoUserMutationResult = Apollo.MutationResult<CreateSsoUserMutation>;
export type CreateSsoUserMutationOptions = Apollo.BaseMutationOptions<CreateSsoUserMutation, CreateSsoUserMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation createTemplate($template: CreateTemplateInput!) {
  createTemplate(template: $template) {
    id
    name
    isCustom
    assignedDisplays {
      totalRecords
    }
    revision {
      id
      configuration
    }
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($options: DeleteAddressInput!) {
  deleteAddress(options: $options) {
    address {
      id
      __typename
    }
  }
}
    `;
export type DeleteAddressMutationFn = Apollo.MutationFunction<DeleteAddressMutation, DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, options);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($options: DeleteCategoryInput!) {
  deleteCategory(options: $options) {
    recordsUpdated
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteCategoryOptionDocument = gql`
    mutation DeleteCategoryOption($options: DeleteCategoryOptionInput!) {
  deleteCategoryOption(options: $options) {
    recordsUpdated
  }
}
    `;
export type DeleteCategoryOptionMutationFn = Apollo.MutationFunction<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>;

/**
 * __useDeleteCategoryOptionMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryOptionMutation, { data, loading, error }] = useDeleteCategoryOptionMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>(DeleteCategoryOptionDocument, options);
      }
export type DeleteCategoryOptionMutationHookResult = ReturnType<typeof useDeleteCategoryOptionMutation>;
export type DeleteCategoryOptionMutationResult = Apollo.MutationResult<DeleteCategoryOptionMutation>;
export type DeleteCategoryOptionMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>;
export const DeletePodDocument = gql`
    mutation DeletePod($options: DeletePodInput!) {
  deletePod(options: $options) {
    podId
  }
}
    `;
export type DeletePodMutationFn = Apollo.MutationFunction<DeletePodMutation, DeletePodMutationVariables>;

/**
 * __useDeletePodMutation__
 *
 * To run a mutation, you first call `useDeletePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePodMutation, { data, loading, error }] = useDeletePodMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeletePodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePodMutation, DeletePodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePodMutation, DeletePodMutationVariables>(DeletePodDocument, options);
      }
export type DeletePodMutationHookResult = ReturnType<typeof useDeletePodMutation>;
export type DeletePodMutationResult = Apollo.MutationResult<DeletePodMutation>;
export type DeletePodMutationOptions = Apollo.BaseMutationOptions<DeletePodMutation, DeletePodMutationVariables>;
export const DeletePodsDocument = gql`
    mutation DeletePods($options: DeletePodsInput!) {
  deletePods(options: $options) {
    podIds
  }
}
    `;
export type DeletePodsMutationFn = Apollo.MutationFunction<DeletePodsMutation, DeletePodsMutationVariables>;

/**
 * __useDeletePodsMutation__
 *
 * To run a mutation, you first call `useDeletePodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePodsMutation, { data, loading, error }] = useDeletePodsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeletePodsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePodsMutation, DeletePodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePodsMutation, DeletePodsMutationVariables>(DeletePodsDocument, options);
      }
export type DeletePodsMutationHookResult = ReturnType<typeof useDeletePodsMutation>;
export type DeletePodsMutationResult = Apollo.MutationResult<DeletePodsMutation>;
export type DeletePodsMutationOptions = Apollo.BaseMutationOptions<DeletePodsMutation, DeletePodsMutationVariables>;
export const DeleteSolsticeRoutingSpaceDocument = gql`
    mutation DeleteSolsticeRoutingSpace($spaceId: String!) {
  deleteSolsticeRoutingSpace(spaceId: $spaceId) {
    success
  }
}
    `;
export type DeleteSolsticeRoutingSpaceMutationFn = Apollo.MutationFunction<DeleteSolsticeRoutingSpaceMutation, DeleteSolsticeRoutingSpaceMutationVariables>;

/**
 * __useDeleteSolsticeRoutingSpaceMutation__
 *
 * To run a mutation, you first call `useDeleteSolsticeRoutingSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSolsticeRoutingSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSolsticeRoutingSpaceMutation, { data, loading, error }] = useDeleteSolsticeRoutingSpaceMutation({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useDeleteSolsticeRoutingSpaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSolsticeRoutingSpaceMutation, DeleteSolsticeRoutingSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSolsticeRoutingSpaceMutation, DeleteSolsticeRoutingSpaceMutationVariables>(DeleteSolsticeRoutingSpaceDocument, options);
      }
export type DeleteSolsticeRoutingSpaceMutationHookResult = ReturnType<typeof useDeleteSolsticeRoutingSpaceMutation>;
export type DeleteSolsticeRoutingSpaceMutationResult = Apollo.MutationResult<DeleteSolsticeRoutingSpaceMutation>;
export type DeleteSolsticeRoutingSpaceMutationOptions = Apollo.BaseMutationOptions<DeleteSolsticeRoutingSpaceMutation, DeleteSolsticeRoutingSpaceMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($template: DeleteTemplateInput!) {
  deleteTemplate(template: $template) {
    success
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($options: DeleteUserInput!) {
  deleteUser(options: $options) {
    email
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const FinishJoyrideDocument = gql`
    mutation FinishJoyride($options: JoyrideOption!) {
  finishJoyride(options: $options) {
    joyride
    finishedOn
  }
}
    `;
export type FinishJoyrideMutationFn = Apollo.MutationFunction<FinishJoyrideMutation, FinishJoyrideMutationVariables>;

/**
 * __useFinishJoyrideMutation__
 *
 * To run a mutation, you first call `useFinishJoyrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishJoyrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishJoyrideMutation, { data, loading, error }] = useFinishJoyrideMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useFinishJoyrideMutation(baseOptions?: Apollo.MutationHookOptions<FinishJoyrideMutation, FinishJoyrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishJoyrideMutation, FinishJoyrideMutationVariables>(FinishJoyrideDocument, options);
      }
export type FinishJoyrideMutationHookResult = ReturnType<typeof useFinishJoyrideMutation>;
export type FinishJoyrideMutationResult = Apollo.MutationResult<FinishJoyrideMutation>;
export type FinishJoyrideMutationOptions = Apollo.BaseMutationOptions<FinishJoyrideMutation, FinishJoyrideMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($options: InviteUserInput!) {
  inviteUser(options: $options) {
    email
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const RecordLastLoginDocument = gql`
    mutation RecordLastLogin($options: RecordLastLoginUserInput!) {
  recordLastLogin(options: $options) {
    success
  }
}
    `;
export type RecordLastLoginMutationFn = Apollo.MutationFunction<RecordLastLoginMutation, RecordLastLoginMutationVariables>;

/**
 * __useRecordLastLoginMutation__
 *
 * To run a mutation, you first call `useRecordLastLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordLastLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordLastLoginMutation, { data, loading, error }] = useRecordLastLoginMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRecordLastLoginMutation(baseOptions?: Apollo.MutationHookOptions<RecordLastLoginMutation, RecordLastLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordLastLoginMutation, RecordLastLoginMutationVariables>(RecordLastLoginDocument, options);
      }
export type RecordLastLoginMutationHookResult = ReturnType<typeof useRecordLastLoginMutation>;
export type RecordLastLoginMutationResult = Apollo.MutationResult<RecordLastLoginMutation>;
export type RecordLastLoginMutationOptions = Apollo.BaseMutationOptions<RecordLastLoginMutation, RecordLastLoginMutationVariables>;
export const ResetJoyrideDocument = gql`
    mutation ResetJoyride($options: JoyrideOption!) {
  resetJoyride(options: $options) {
    joyride
    finishedOn
  }
}
    `;
export type ResetJoyrideMutationFn = Apollo.MutationFunction<ResetJoyrideMutation, ResetJoyrideMutationVariables>;

/**
 * __useResetJoyrideMutation__
 *
 * To run a mutation, you first call `useResetJoyrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetJoyrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetJoyrideMutation, { data, loading, error }] = useResetJoyrideMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useResetJoyrideMutation(baseOptions?: Apollo.MutationHookOptions<ResetJoyrideMutation, ResetJoyrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetJoyrideMutation, ResetJoyrideMutationVariables>(ResetJoyrideDocument, options);
      }
export type ResetJoyrideMutationHookResult = ReturnType<typeof useResetJoyrideMutation>;
export type ResetJoyrideMutationResult = Apollo.MutationResult<ResetJoyrideMutation>;
export type ResetJoyrideMutationOptions = Apollo.BaseMutationOptions<ResetJoyrideMutation, ResetJoyrideMutationVariables>;
export const UnassignTemplateDocument = gql`
    mutation UnassignTemplate($options: UnassignTemplateOptions!) {
  unassignTemplate(options: $options) {
    affectedIds
  }
}
    `;
export type UnassignTemplateMutationFn = Apollo.MutationFunction<UnassignTemplateMutation, UnassignTemplateMutationVariables>;

/**
 * __useUnassignTemplateMutation__
 *
 * To run a mutation, you first call `useUnassignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTemplateMutation, { data, loading, error }] = useUnassignTemplateMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUnassignTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnassignTemplateMutation, UnassignTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignTemplateMutation, UnassignTemplateMutationVariables>(UnassignTemplateDocument, options);
      }
export type UnassignTemplateMutationHookResult = ReturnType<typeof useUnassignTemplateMutation>;
export type UnassignTemplateMutationResult = Apollo.MutationResult<UnassignTemplateMutation>;
export type UnassignTemplateMutationOptions = Apollo.BaseMutationOptions<UnassignTemplateMutation, UnassignTemplateMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($options: UpdateCategoryInput!) {
  updateCategory(options: $options) {
    categoryId
    categoryName
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateCategoryOptionDocument = gql`
    mutation UpdateCategoryOption($options: UpdateCategoryOptionInput!) {
  updateCategoryOption(options: $options) {
    optionId
    optionName
    categoryId
  }
}
    `;
export type UpdateCategoryOptionMutationFn = Apollo.MutationFunction<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>;

/**
 * __useUpdateCategoryOptionMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryOptionMutation, { data, loading, error }] = useUpdateCategoryOptionMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>(UpdateCategoryOptionDocument, options);
      }
export type UpdateCategoryOptionMutationHookResult = ReturnType<typeof useUpdateCategoryOptionMutation>;
export type UpdateCategoryOptionMutationResult = Apollo.MutationResult<UpdateCategoryOptionMutation>;
export type UpdateCategoryOptionMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>;
export const UpdateDisplayDocument = gql`
    mutation updateDisplay($options: UpdateDisplayInput!) {
  updateDisplay(options: $options) {
    display {
      id
      name
      isOnline
      hasPendingChanges
      assignedTemplates {
        template {
          ...Template
        }
        hasPendingChanges
        settingsGroup {
          type
        }
      }
    }
    validation {
      ...UniqueSettingsValidation
    }
  }
}
    ${TemplateFragmentDoc}
${UniqueSettingsValidationFragmentDoc}`;
export type UpdateDisplayMutationFn = Apollo.MutationFunction<UpdateDisplayMutation, UpdateDisplayMutationVariables>;

/**
 * __useUpdateDisplayMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayMutation, { data, loading, error }] = useUpdateDisplayMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateDisplayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisplayMutation, UpdateDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisplayMutation, UpdateDisplayMutationVariables>(UpdateDisplayDocument, options);
      }
export type UpdateDisplayMutationHookResult = ReturnType<typeof useUpdateDisplayMutation>;
export type UpdateDisplayMutationResult = Apollo.MutationResult<UpdateDisplayMutation>;
export type UpdateDisplayMutationOptions = Apollo.BaseMutationOptions<UpdateDisplayMutation, UpdateDisplayMutationVariables>;
export const UpdateScheduledTaskDocument = gql`
    mutation UpdateScheduledTask($options: UpdateScheduledTaskInput!) {
  updateScheduledTask(options: $options) {
    id
  }
}
    `;
export type UpdateScheduledTaskMutationFn = Apollo.MutationFunction<UpdateScheduledTaskMutation, UpdateScheduledTaskMutationVariables>;

/**
 * __useUpdateScheduledTaskMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledTaskMutation, { data, loading, error }] = useUpdateScheduledTaskMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateScheduledTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduledTaskMutation, UpdateScheduledTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduledTaskMutation, UpdateScheduledTaskMutationVariables>(UpdateScheduledTaskDocument, options);
      }
export type UpdateScheduledTaskMutationHookResult = ReturnType<typeof useUpdateScheduledTaskMutation>;
export type UpdateScheduledTaskMutationResult = Apollo.MutationResult<UpdateScheduledTaskMutation>;
export type UpdateScheduledTaskMutationOptions = Apollo.BaseMutationOptions<UpdateScheduledTaskMutation, UpdateScheduledTaskMutationVariables>;
export const UpdateSolsticeRoutingOrgDocument = gql`
    mutation UpdateSolsticeRoutingOrg($options: UpdateSolsticeRoutingOrgInput!) {
  updateSolsticeRoutingOrg(options: $options) {
    id
    ex_id
    require_password_on_exit
    ex_name
    updated_at
    created_at
  }
}
    `;
export type UpdateSolsticeRoutingOrgMutationFn = Apollo.MutationFunction<UpdateSolsticeRoutingOrgMutation, UpdateSolsticeRoutingOrgMutationVariables>;

/**
 * __useUpdateSolsticeRoutingOrgMutation__
 *
 * To run a mutation, you first call `useUpdateSolsticeRoutingOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSolsticeRoutingOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSolsticeRoutingOrgMutation, { data, loading, error }] = useUpdateSolsticeRoutingOrgMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateSolsticeRoutingOrgMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSolsticeRoutingOrgMutation, UpdateSolsticeRoutingOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSolsticeRoutingOrgMutation, UpdateSolsticeRoutingOrgMutationVariables>(UpdateSolsticeRoutingOrgDocument, options);
      }
export type UpdateSolsticeRoutingOrgMutationHookResult = ReturnType<typeof useUpdateSolsticeRoutingOrgMutation>;
export type UpdateSolsticeRoutingOrgMutationResult = Apollo.MutationResult<UpdateSolsticeRoutingOrgMutation>;
export type UpdateSolsticeRoutingOrgMutationOptions = Apollo.BaseMutationOptions<UpdateSolsticeRoutingOrgMutation, UpdateSolsticeRoutingOrgMutationVariables>;
export const UpdateSolsticeRoutingSpaceDocument = gql`
    mutation UpdateSolsticeRoutingSpace($options: UpdateSolsticeRoutingSpaceInput!) {
  updateSolsticeRoutingSpace(options: $options) {
    id
    name
    org
    partition
    building_name
    space_type
    show_routing_banner
    require_screen_key_verification
    require_password_on_exit
    room_number
    moderator_sharing_mode
    primary_pod
    pods {
      id
      color
      audio_routing_enabled
      row
      column
      name
    }
    route_presets {
      name
      routes {
        sink_pod {
          color
          audio_routing_enabled
          row
          column
          id
        }
        source_pod {
          color
          audio_routing_enabled
          row
          column
          id
        }
      }
    }
  }
}
    `;
export type UpdateSolsticeRoutingSpaceMutationFn = Apollo.MutationFunction<UpdateSolsticeRoutingSpaceMutation, UpdateSolsticeRoutingSpaceMutationVariables>;

/**
 * __useUpdateSolsticeRoutingSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateSolsticeRoutingSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSolsticeRoutingSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSolsticeRoutingSpaceMutation, { data, loading, error }] = useUpdateSolsticeRoutingSpaceMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateSolsticeRoutingSpaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSolsticeRoutingSpaceMutation, UpdateSolsticeRoutingSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSolsticeRoutingSpaceMutation, UpdateSolsticeRoutingSpaceMutationVariables>(UpdateSolsticeRoutingSpaceDocument, options);
      }
export type UpdateSolsticeRoutingSpaceMutationHookResult = ReturnType<typeof useUpdateSolsticeRoutingSpaceMutation>;
export type UpdateSolsticeRoutingSpaceMutationResult = Apollo.MutationResult<UpdateSolsticeRoutingSpaceMutation>;
export type UpdateSolsticeRoutingSpaceMutationOptions = Apollo.BaseMutationOptions<UpdateSolsticeRoutingSpaceMutation, UpdateSolsticeRoutingSpaceMutationVariables>;
export const UpdateSsoStatusDocument = gql`
    mutation updateSsoStatus($options: UpdateSsoStatusInput!) {
  updateSsoStatus(options: $options) {
    email
  }
}
    `;
export type UpdateSsoStatusMutationFn = Apollo.MutationFunction<UpdateSsoStatusMutation, UpdateSsoStatusMutationVariables>;

/**
 * __useUpdateSsoStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSsoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSsoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSsoStatusMutation, { data, loading, error }] = useUpdateSsoStatusMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateSsoStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSsoStatusMutation, UpdateSsoStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSsoStatusMutation, UpdateSsoStatusMutationVariables>(UpdateSsoStatusDocument, options);
      }
export type UpdateSsoStatusMutationHookResult = ReturnType<typeof useUpdateSsoStatusMutation>;
export type UpdateSsoStatusMutationResult = Apollo.MutationResult<UpdateSsoStatusMutation>;
export type UpdateSsoStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSsoStatusMutation, UpdateSsoStatusMutationVariables>;
export const UpdateSupportAccessDocument = gql`
    mutation updateSupportAccess($options: UpdateSupportInput) {
  updateSupportAccess(options: $options) {
    grant_support_access_until
  }
}
    `;
export type UpdateSupportAccessMutationFn = Apollo.MutationFunction<UpdateSupportAccessMutation, UpdateSupportAccessMutationVariables>;

/**
 * __useUpdateSupportAccessMutation__
 *
 * To run a mutation, you first call `useUpdateSupportAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportAccessMutation, { data, loading, error }] = useUpdateSupportAccessMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateSupportAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupportAccessMutation, UpdateSupportAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupportAccessMutation, UpdateSupportAccessMutationVariables>(UpdateSupportAccessDocument, options);
      }
export type UpdateSupportAccessMutationHookResult = ReturnType<typeof useUpdateSupportAccessMutation>;
export type UpdateSupportAccessMutationResult = Apollo.MutationResult<UpdateSupportAccessMutation>;
export type UpdateSupportAccessMutationOptions = Apollo.BaseMutationOptions<UpdateSupportAccessMutation, UpdateSupportAccessMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($template: UpdateTemplateInput!) {
  updateTemplate(template: $template) {
    template {
      id
      name
      isCustom
      isDefault
      settingsGroup {
        name
      }
      assignedDisplays {
        totalRecords
      }
      revision {
        id
        configuration
      }
    }
    validation {
      ...UniqueSettingsValidation
    }
  }
}
    ${UniqueSettingsValidationFragmentDoc}`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($options: UpdateUserRoleInput!) {
  updateUserRole(options: $options) {
    email
  }
}
    `;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const CreateUpdateSsoDocument = gql`
    mutation createUpdateSSO($options: CreateSSOOrgInput!) {
  createSSOOrg(options: $options) {
    created
    updated
    idp_id
    idp_url
    idp_cert_timestamp
  }
}
    `;
export type CreateUpdateSsoMutationFn = Apollo.MutationFunction<CreateUpdateSsoMutation, CreateUpdateSsoMutationVariables>;

/**
 * __useCreateUpdateSsoMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSsoMutation, { data, loading, error }] = useCreateUpdateSsoMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateUpdateSsoMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateSsoMutation, CreateUpdateSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateSsoMutation, CreateUpdateSsoMutationVariables>(CreateUpdateSsoDocument, options);
      }
export type CreateUpdateSsoMutationHookResult = ReturnType<typeof useCreateUpdateSsoMutation>;
export type CreateUpdateSsoMutationResult = Apollo.MutationResult<CreateUpdateSsoMutation>;
export type CreateUpdateSsoMutationOptions = Apollo.BaseMutationOptions<CreateUpdateSsoMutation, CreateUpdateSsoMutationVariables>;
export const ActiveLearningDisplayMetricsDocument = gql`
    query ActiveLearningDisplayMetrics($searchTerm: String, $categories: [CategoryFilter!], $locationFilters: LocationFilters) {
  currentSubscriptionDisplays: displays(
    options: {activeLearning: {statuses: [CURRENT]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, hardware: [GEN3]}
  ) {
    totalRecords
  }
  expiringSoonDisplays: displays(
    options: {activeLearning: {statuses: [EXPIRING_SOON]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, hardware: [GEN3]}
  ) {
    totalRecords
  }
  trialDisplays: displays(
    options: {activeLearning: {statuses: [TRIAL_ACTIVE]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, hardware: [GEN3]}
  ) {
    totalRecords
  }
  readyToEnableDisplays: displays(
    options: {activeLearning: {statuses: [READY_TO_ENABLE]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, hardware: [GEN3], isIncludedElementPods: false}
  ) {
    totalRecords
  }
}
    `;

/**
 * __useActiveLearningDisplayMetricsQuery__
 *
 * To run a query within a React component, call `useActiveLearningDisplayMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearningDisplayMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearningDisplayMetricsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      categories: // value for 'categories'
 *      locationFilters: // value for 'locationFilters'
 *   },
 * });
 */
export function useActiveLearningDisplayMetricsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>(ActiveLearningDisplayMetricsDocument, options);
      }
export function useActiveLearningDisplayMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>(ActiveLearningDisplayMetricsDocument, options);
        }
export type ActiveLearningDisplayMetricsQueryHookResult = ReturnType<typeof useActiveLearningDisplayMetricsQuery>;
export type ActiveLearningDisplayMetricsLazyQueryHookResult = ReturnType<typeof useActiveLearningDisplayMetricsLazyQuery>;
export type ActiveLearningDisplayMetricsQueryResult = Apollo.QueryResult<ActiveLearningDisplayMetricsQuery, ActiveLearningDisplayMetricsQueryVariables>;
export const ActiveLearningDisplaysDocument = gql`
    query ActiveLearningDisplays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
    items {
      id
      name
      versions {
        software
      }
      catchingUp
      activeLearning {
        rooms {
          id
          name
        }
        trial {
          endDate
          isEligible
        }
        status
        licenseEndDate
      }
    }
  }
}
    `;

/**
 * __useActiveLearningDisplaysQuery__
 *
 * To run a query within a React component, call `useActiveLearningDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearningDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearningDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useActiveLearningDisplaysQuery(baseOptions: Apollo.QueryHookOptions<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>(ActiveLearningDisplaysDocument, options);
      }
export function useActiveLearningDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>(ActiveLearningDisplaysDocument, options);
        }
export type ActiveLearningDisplaysQueryHookResult = ReturnType<typeof useActiveLearningDisplaysQuery>;
export type ActiveLearningDisplaysLazyQueryHookResult = ReturnType<typeof useActiveLearningDisplaysLazyQuery>;
export type ActiveLearningDisplaysQueryResult = Apollo.QueryResult<ActiveLearningDisplaysQuery, ActiveLearningDisplaysQueryVariables>;
export const ActiveLearningRoomsDocument = gql`
    query ActiveLearningRooms {
  activeLearningRooms {
    id
    name
  }
}
    `;

/**
 * __useActiveLearningRoomsQuery__
 *
 * To run a query within a React component, call `useActiveLearningRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearningRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearningRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLearningRoomsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveLearningRoomsQuery, ActiveLearningRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLearningRoomsQuery, ActiveLearningRoomsQueryVariables>(ActiveLearningRoomsDocument, options);
      }
export function useActiveLearningRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLearningRoomsQuery, ActiveLearningRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLearningRoomsQuery, ActiveLearningRoomsQueryVariables>(ActiveLearningRoomsDocument, options);
        }
export type ActiveLearningRoomsQueryHookResult = ReturnType<typeof useActiveLearningRoomsQuery>;
export type ActiveLearningRoomsLazyQueryHookResult = ReturnType<typeof useActiveLearningRoomsLazyQuery>;
export type ActiveLearningRoomsQueryResult = Apollo.QueryResult<ActiveLearningRoomsQuery, ActiveLearningRoomsQueryVariables>;
export const ActiveMeetingsDocument = gql`
    query ActiveMeetings {
  activeMeetings {
    count
  }
}
    `;

/**
 * __useActiveMeetingsQuery__
 *
 * To run a query within a React component, call `useActiveMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMeetingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveMeetingsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveMeetingsQuery, ActiveMeetingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveMeetingsQuery, ActiveMeetingsQueryVariables>(ActiveMeetingsDocument, options);
      }
export function useActiveMeetingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveMeetingsQuery, ActiveMeetingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveMeetingsQuery, ActiveMeetingsQueryVariables>(ActiveMeetingsDocument, options);
        }
export type ActiveMeetingsQueryHookResult = ReturnType<typeof useActiveMeetingsQuery>;
export type ActiveMeetingsLazyQueryHookResult = ReturnType<typeof useActiveMeetingsLazyQuery>;
export type ActiveMeetingsQueryResult = Apollo.QueryResult<ActiveMeetingsQuery, ActiveMeetingsQueryVariables>;
export const AllDisplayIdsDocument = gql`
    query AllDisplayIds($options: DisplaySearchOptions!) {
  displays(options: $options) {
    items {
      id
    }
  }
}
    `;

/**
 * __useAllDisplayIdsQuery__
 *
 * To run a query within a React component, call `useAllDisplayIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisplayIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisplayIdsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAllDisplayIdsQuery(baseOptions: Apollo.QueryHookOptions<AllDisplayIdsQuery, AllDisplayIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDisplayIdsQuery, AllDisplayIdsQueryVariables>(AllDisplayIdsDocument, options);
      }
export function useAllDisplayIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDisplayIdsQuery, AllDisplayIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDisplayIdsQuery, AllDisplayIdsQueryVariables>(AllDisplayIdsDocument, options);
        }
export type AllDisplayIdsQueryHookResult = ReturnType<typeof useAllDisplayIdsQuery>;
export type AllDisplayIdsLazyQueryHookResult = ReturnType<typeof useAllDisplayIdsLazyQuery>;
export type AllDisplayIdsQueryResult = Apollo.QueryResult<AllDisplayIdsQuery, AllDisplayIdsQueryVariables>;
export const AllDisplaysDocument = gql`
    query AllDisplays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
    items {
      id
      name
      syncState
      isOnline
      isManageable
      managementStatus
      hasPendingChanges
      ipAddressesPrimary
      versions {
        hardware
        software
        lms
      }
      assignedTemplates {
        template {
          id
          name
          settingsGroup {
            type
          }
          isCustom
        }
        syncState
        hasPendingChanges
        settingsGroup {
          type
        }
      }
    }
  }
}
    `;

/**
 * __useAllDisplaysQuery__
 *
 * To run a query within a React component, call `useAllDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAllDisplaysQuery(baseOptions: Apollo.QueryHookOptions<AllDisplaysQuery, AllDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDisplaysQuery, AllDisplaysQueryVariables>(AllDisplaysDocument, options);
      }
export function useAllDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDisplaysQuery, AllDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDisplaysQuery, AllDisplaysQueryVariables>(AllDisplaysDocument, options);
        }
export type AllDisplaysQueryHookResult = ReturnType<typeof useAllDisplaysQuery>;
export type AllDisplaysLazyQueryHookResult = ReturnType<typeof useAllDisplaysLazyQuery>;
export type AllDisplaysQueryResult = Apollo.QueryResult<AllDisplaysQuery, AllDisplaysQueryVariables>;
export const AnalyticCountsDocument = gql`
    query AnalyticCounts($options: AnalyticCountsOptions!) {
  analyticCounts(options: $options) {
    medianMeeting
    busiestHour
    uniqueUsers
  }
}
    `;

/**
 * __useAnalyticCountsQuery__
 *
 * To run a query within a React component, call `useAnalyticCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticCountsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAnalyticCountsQuery(baseOptions: Apollo.QueryHookOptions<AnalyticCountsQuery, AnalyticCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticCountsQuery, AnalyticCountsQueryVariables>(AnalyticCountsDocument, options);
      }
export function useAnalyticCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticCountsQuery, AnalyticCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticCountsQuery, AnalyticCountsQueryVariables>(AnalyticCountsDocument, options);
        }
export type AnalyticCountsQueryHookResult = ReturnType<typeof useAnalyticCountsQuery>;
export type AnalyticCountsLazyQueryHookResult = ReturnType<typeof useAnalyticCountsLazyQuery>;
export type AnalyticCountsQueryResult = Apollo.QueryResult<AnalyticCountsQuery, AnalyticCountsQueryVariables>;
export const AvailabilityDocument = gql`
    query Availability($options: AvailabilityOptions) {
  availability(options: $options) {
    pods {
      org
      displayId
      name
      version
      lastOnline
      catchingUp
      status
      licenseShortName
    }
    aggregate {
      pods
      subscribedPods
      podsOnlineNow
      podsOnlineSinceYesterday
    }
  }
}
    `;

/**
 * __useAvailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilityQuery, AvailabilityQueryVariables>(AvailabilityDocument, options);
      }
export function useAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilityQuery, AvailabilityQueryVariables>(AvailabilityDocument, options);
        }
export type AvailabilityQueryHookResult = ReturnType<typeof useAvailabilityQuery>;
export type AvailabilityLazyQueryHookResult = ReturnType<typeof useAvailabilityLazyQuery>;
export type AvailabilityQueryResult = Apollo.QueryResult<AvailabilityQuery, AvailabilityQueryVariables>;
export const CategoryManagerPodsDocument = gql`
    query CategoryManagerPods($options: CategoryManagerPodOptions) {
  categoryManagerPods(options: $options) {
    displayId
    displayName
    categories {
      categoryId
      name
      option {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCategoryManagerPodsQuery__
 *
 * To run a query within a React component, call `useCategoryManagerPodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryManagerPodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryManagerPodsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCategoryManagerPodsQuery(baseOptions?: Apollo.QueryHookOptions<CategoryManagerPodsQuery, CategoryManagerPodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryManagerPodsQuery, CategoryManagerPodsQueryVariables>(CategoryManagerPodsDocument, options);
      }
export function useCategoryManagerPodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryManagerPodsQuery, CategoryManagerPodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryManagerPodsQuery, CategoryManagerPodsQueryVariables>(CategoryManagerPodsDocument, options);
        }
export type CategoryManagerPodsQueryHookResult = ReturnType<typeof useCategoryManagerPodsQuery>;
export type CategoryManagerPodsLazyQueryHookResult = ReturnType<typeof useCategoryManagerPodsLazyQuery>;
export type CategoryManagerPodsQueryResult = Apollo.QueryResult<CategoryManagerPodsQuery, CategoryManagerPodsQueryVariables>;
export const CategoryMetricsByDateDocument = gql`
    query CategoryMetricsByDate($options: CategoryMetricSearchOptions!) {
  categoryMetricsByDate(options: $options) {
    date
    options {
      total
      option {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCategoryMetricsByDateQuery__
 *
 * To run a query within a React component, call `useCategoryMetricsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryMetricsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryMetricsByDateQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCategoryMetricsByDateQuery(baseOptions: Apollo.QueryHookOptions<CategoryMetricsByDateQuery, CategoryMetricsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryMetricsByDateQuery, CategoryMetricsByDateQueryVariables>(CategoryMetricsByDateDocument, options);
      }
export function useCategoryMetricsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryMetricsByDateQuery, CategoryMetricsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryMetricsByDateQuery, CategoryMetricsByDateQueryVariables>(CategoryMetricsByDateDocument, options);
        }
export type CategoryMetricsByDateQueryHookResult = ReturnType<typeof useCategoryMetricsByDateQuery>;
export type CategoryMetricsByDateLazyQueryHookResult = ReturnType<typeof useCategoryMetricsByDateLazyQuery>;
export type CategoryMetricsByDateQueryResult = Apollo.QueryResult<CategoryMetricsByDateQuery, CategoryMetricsByDateQueryVariables>;
export const GetConfigDiffDocument = gql`
    query GetConfigDiff($id: String!) {
  configDiff(id: $id) {
    diffObject
  }
}
    `;

/**
 * __useGetConfigDiffQuery__
 *
 * To run a query within a React component, call `useGetConfigDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigDiffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConfigDiffQuery(baseOptions: Apollo.QueryHookOptions<GetConfigDiffQuery, GetConfigDiffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigDiffQuery, GetConfigDiffQueryVariables>(GetConfigDiffDocument, options);
      }
export function useGetConfigDiffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigDiffQuery, GetConfigDiffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigDiffQuery, GetConfigDiffQueryVariables>(GetConfigDiffDocument, options);
        }
export type GetConfigDiffQueryHookResult = ReturnType<typeof useGetConfigDiffQuery>;
export type GetConfigDiffLazyQueryHookResult = ReturnType<typeof useGetConfigDiffLazyQuery>;
export type GetConfigDiffQueryResult = Apollo.QueryResult<GetConfigDiffQuery, GetConfigDiffQueryVariables>;
export const CurrentScheduledTasksDocument = gql`
    query CurrentScheduledTasks {
  scheduledTasks: currentScheduledTasks(options: {taskType: displayUpdate}) {
    ...ScheduledTask
  }
}
    ${ScheduledTaskFragmentDoc}`;

/**
 * __useCurrentScheduledTasksQuery__
 *
 * To run a query within a React component, call `useCurrentScheduledTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentScheduledTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentScheduledTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentScheduledTasksQuery(baseOptions?: Apollo.QueryHookOptions<CurrentScheduledTasksQuery, CurrentScheduledTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentScheduledTasksQuery, CurrentScheduledTasksQueryVariables>(CurrentScheduledTasksDocument, options);
      }
export function useCurrentScheduledTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentScheduledTasksQuery, CurrentScheduledTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentScheduledTasksQuery, CurrentScheduledTasksQueryVariables>(CurrentScheduledTasksDocument, options);
        }
export type CurrentScheduledTasksQueryHookResult = ReturnType<typeof useCurrentScheduledTasksQuery>;
export type CurrentScheduledTasksLazyQueryHookResult = ReturnType<typeof useCurrentScheduledTasksLazyQuery>;
export type CurrentScheduledTasksQueryResult = Apollo.QueryResult<CurrentScheduledTasksQuery, CurrentScheduledTasksQueryVariables>;
export const CustomCategoriesDocument = gql`
    query CustomCategories {
  categories {
    id
    name
    options {
      id
      name
      appliedDisplayCount
    }
  }
}
    `;

/**
 * __useCustomCategoriesQuery__
 *
 * To run a query within a React component, call `useCustomCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CustomCategoriesQuery, CustomCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomCategoriesQuery, CustomCategoriesQueryVariables>(CustomCategoriesDocument, options);
      }
export function useCustomCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomCategoriesQuery, CustomCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomCategoriesQuery, CustomCategoriesQueryVariables>(CustomCategoriesDocument, options);
        }
export type CustomCategoriesQueryHookResult = ReturnType<typeof useCustomCategoriesQuery>;
export type CustomCategoriesLazyQueryHookResult = ReturnType<typeof useCustomCategoriesLazyQuery>;
export type CustomCategoriesQueryResult = Apollo.QueryResult<CustomCategoriesQuery, CustomCategoriesQueryVariables>;
export const DeploymentsDocument = gql`
    query Deployments($options: DeploymentsPageOptions) {
  deployments(options: $options) {
    pods {
      name
      orgId
      version
      hardware
      displayId
      serialId
      isOnline
      hasUnconfirmedTemplates
      isInSubscription
      onboardDate
      deployDate
      catchingUp
      flexeraMaintenanceEnd
      maintenanceEnd
      ipPrimary
      ipWifi
      license
      licenseShortName
      macPrimary
      macWifi
      sdsEnabled
      sdsHost1
      sdsHost2
      status
      tags {
        tag
        color
      }
    }
    aggregate {
      totalPods
      totalCurrentLmsVersionPods
      totalExpiringPods
      totalExpiredPods
    }
  }
}
    `;

/**
 * __useDeploymentsQuery__
 *
 * To run a query within a React component, call `useDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, options);
      }
export function useDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, options);
        }
export type DeploymentsQueryHookResult = ReturnType<typeof useDeploymentsQuery>;
export type DeploymentsLazyQueryHookResult = ReturnType<typeof useDeploymentsLazyQuery>;
export type DeploymentsQueryResult = Apollo.QueryResult<DeploymentsQuery, DeploymentsQueryVariables>;
export const DisplayDocument = gql`
    query Display($id: String!) {
  display(id: $id) {
    id
    uuid
    serialId
    name
    isOnline
    hasPendingChanges
    syncState
    versions {
      hardware
      software
      lms
    }
    categories {
      categoryId
      id
    }
    managementStatus
    assignedTemplates {
      template {
        id
        name
        isCustom
        assignedDisplays {
          compatibilityAggregate {
            minSoftware
            minHardware
            minLms
          }
        }
        settingsGroup {
          type
          hidden
          name
          settings {
            key
            value
            compatibility {
              minSoftware
              minHardware
              hardRequirement
            }
          }
        }
        revision {
          id
          configuration
        }
      }
      hasPendingChanges
      syncState
      settingsGroup {
        type
      }
    }
    macAddressesPrimary
    macAddressesWifi
    catchingUp
  }
  templateCompatibility(options: {displaySearchOptions: {displayIds: [$id]}}) {
    templateId
    isCompatible
    incompatibleReason
  }
  categories {
    id
    name
    options {
      id
      name
    }
  }
}
    `;

/**
 * __useDisplayQuery__
 *
 * To run a query within a React component, call `useDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisplayQuery(baseOptions: Apollo.QueryHookOptions<DisplayQuery, DisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayQuery, DisplayQueryVariables>(DisplayDocument, options);
      }
export function useDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayQuery, DisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayQuery, DisplayQueryVariables>(DisplayDocument, options);
        }
export type DisplayQueryHookResult = ReturnType<typeof useDisplayQuery>;
export type DisplayLazyQueryHookResult = ReturnType<typeof useDisplayLazyQuery>;
export type DisplayQueryResult = Apollo.QueryResult<DisplayQuery, DisplayQueryVariables>;
export const ElementPodsDocument = gql`
    query ElementPods {
  elementPods {
    totalRecords
    items {
      id
      org
    }
  }
}
    `;

/**
 * __useElementPodsQuery__
 *
 * To run a query within a React component, call `useElementPodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useElementPodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElementPodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useElementPodsQuery(baseOptions?: Apollo.QueryHookOptions<ElementPodsQuery, ElementPodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ElementPodsQuery, ElementPodsQueryVariables>(ElementPodsDocument, options);
      }
export function useElementPodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ElementPodsQuery, ElementPodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ElementPodsQuery, ElementPodsQueryVariables>(ElementPodsDocument, options);
        }
export type ElementPodsQueryHookResult = ReturnType<typeof useElementPodsQuery>;
export type ElementPodsLazyQueryHookResult = ReturnType<typeof useElementPodsLazyQuery>;
export type ElementPodsQueryResult = Apollo.QueryResult<ElementPodsQuery, ElementPodsQueryVariables>;
export const HistoricalScheduledTasksDocument = gql`
    query HistoricalScheduledTasks {
  scheduledTasks: historicalScheduledTasks(options: {taskType: displayUpdate}) {
    ...ScheduledTask
  }
}
    ${ScheduledTaskFragmentDoc}`;

/**
 * __useHistoricalScheduledTasksQuery__
 *
 * To run a query within a React component, call `useHistoricalScheduledTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalScheduledTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalScheduledTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useHistoricalScheduledTasksQuery(baseOptions?: Apollo.QueryHookOptions<HistoricalScheduledTasksQuery, HistoricalScheduledTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoricalScheduledTasksQuery, HistoricalScheduledTasksQueryVariables>(HistoricalScheduledTasksDocument, options);
      }
export function useHistoricalScheduledTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoricalScheduledTasksQuery, HistoricalScheduledTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoricalScheduledTasksQuery, HistoricalScheduledTasksQueryVariables>(HistoricalScheduledTasksDocument, options);
        }
export type HistoricalScheduledTasksQueryHookResult = ReturnType<typeof useHistoricalScheduledTasksQuery>;
export type HistoricalScheduledTasksLazyQueryHookResult = ReturnType<typeof useHistoricalScheduledTasksLazyQuery>;
export type HistoricalScheduledTasksQueryResult = Apollo.QueryResult<HistoricalScheduledTasksQuery, HistoricalScheduledTasksQueryVariables>;
export const JoyridesDocument = gql`
    query Joyrides {
  joyrides {
    joyride
    finishedOn
  }
}
    `;

/**
 * __useJoyridesQuery__
 *
 * To run a query within a React component, call `useJoyridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoyridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoyridesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoyridesQuery(baseOptions?: Apollo.QueryHookOptions<JoyridesQuery, JoyridesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoyridesQuery, JoyridesQueryVariables>(JoyridesDocument, options);
      }
export function useJoyridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoyridesQuery, JoyridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoyridesQuery, JoyridesQueryVariables>(JoyridesDocument, options);
        }
export type JoyridesQueryHookResult = ReturnType<typeof useJoyridesQuery>;
export type JoyridesLazyQueryHookResult = ReturnType<typeof useJoyridesLazyQuery>;
export type JoyridesQueryResult = Apollo.QueryResult<JoyridesQuery, JoyridesQueryVariables>;
export const LanguagesDocument = gql`
    query Languages {
  languages {
    text
    value
  }
}
    `;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const LocationCategoriesDocument = gql`
    query LocationCategories($options: AddressOptions) {
  addresses(options: $options) {
    id
    nickname
    building
    city
    state_province
    country
    num_displays
  }
}
    `;

/**
 * __useLocationCategoriesQuery__
 *
 * To run a query within a React component, call `useLocationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationCategoriesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useLocationCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<LocationCategoriesQuery, LocationCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationCategoriesQuery, LocationCategoriesQueryVariables>(LocationCategoriesDocument, options);
      }
export function useLocationCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationCategoriesQuery, LocationCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationCategoriesQuery, LocationCategoriesQueryVariables>(LocationCategoriesDocument, options);
        }
export type LocationCategoriesQueryHookResult = ReturnType<typeof useLocationCategoriesQuery>;
export type LocationCategoriesLazyQueryHookResult = ReturnType<typeof useLocationCategoriesLazyQuery>;
export type LocationCategoriesQueryResult = Apollo.QueryResult<LocationCategoriesQuery, LocationCategoriesQueryVariables>;
export const ManagementStatusDisplayMetricsDocument = gql`
    query ManagementStatusDisplayMetrics($searchTerm: String, $categories: [CategoryFilter!], $locationFilters: LocationFilters) {
  OfflinePending: displays(
    options: {managementStatus: {statuses: [OfflinePending]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm}
  ) {
    totalRecords
  }
  Offline: displays(
    options: {managementStatus: {statuses: [Offline]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm}
  ) {
    totalRecords
  }
  Online: displays(
    options: {managementStatus: {statuses: [Online]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm}
  ) {
    totalRecords
  }
  Unsupported: displays(
    options: {managementStatus: {statuses: [Unsupported]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm}
  ) {
    totalRecords
  }
  NotYetDeployed: displays(
    options: {managementStatus: {statuses: [NotYetDeployed]}, categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm}
  ) {
    totalRecords
  }
}
    `;

/**
 * __useManagementStatusDisplayMetricsQuery__
 *
 * To run a query within a React component, call `useManagementStatusDisplayMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagementStatusDisplayMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagementStatusDisplayMetricsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      categories: // value for 'categories'
 *      locationFilters: // value for 'locationFilters'
 *   },
 * });
 */
export function useManagementStatusDisplayMetricsQuery(baseOptions?: Apollo.QueryHookOptions<ManagementStatusDisplayMetricsQuery, ManagementStatusDisplayMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagementStatusDisplayMetricsQuery, ManagementStatusDisplayMetricsQueryVariables>(ManagementStatusDisplayMetricsDocument, options);
      }
export function useManagementStatusDisplayMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagementStatusDisplayMetricsQuery, ManagementStatusDisplayMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagementStatusDisplayMetricsQuery, ManagementStatusDisplayMetricsQueryVariables>(ManagementStatusDisplayMetricsDocument, options);
        }
export type ManagementStatusDisplayMetricsQueryHookResult = ReturnType<typeof useManagementStatusDisplayMetricsQuery>;
export type ManagementStatusDisplayMetricsLazyQueryHookResult = ReturnType<typeof useManagementStatusDisplayMetricsLazyQuery>;
export type ManagementStatusDisplayMetricsQueryResult = Apollo.QueryResult<ManagementStatusDisplayMetricsQuery, ManagementStatusDisplayMetricsQueryVariables>;
export const MetricsByDateDocument = gql`
    query MetricsByDate($options: MetricSearchOptions!) {
  metricsByDate(options: $options) {
    date
    metrics {
      total
      name
    }
  }
}
    `;

/**
 * __useMetricsByDateQuery__
 *
 * To run a query within a React component, call `useMetricsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsByDateQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMetricsByDateQuery(baseOptions: Apollo.QueryHookOptions<MetricsByDateQuery, MetricsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsByDateQuery, MetricsByDateQueryVariables>(MetricsByDateDocument, options);
      }
export function useMetricsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsByDateQuery, MetricsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsByDateQuery, MetricsByDateQueryVariables>(MetricsByDateDocument, options);
        }
export type MetricsByDateQueryHookResult = ReturnType<typeof useMetricsByDateQuery>;
export type MetricsByDateLazyQueryHookResult = ReturnType<typeof useMetricsByDateLazyQuery>;
export type MetricsByDateQueryResult = Apollo.QueryResult<MetricsByDateQuery, MetricsByDateQueryVariables>;
export const DisplaysDocument = gql`
    query Displays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
    items {
      id
      name
      metrics {
        totalMinutesInUse
        totalMeetings
        totalPosts
        totalUsers
        totalMaxOccupancy
        totalAverageHourlyOccupancy
        totalContentPerMeeting
        totalAverageMeetingDuration
        totalAverageMeetingRating
      }
      categories {
        categoryId
        option {
          id
          name
        }
      }
      license {
        shortName
      }
      subscription {
        status
        expirationDate
      }
      displayCam {
        cam1
        cam2
      }
    }
  }
}
    `;

/**
 * __useDisplaysQuery__
 *
 * To run a query within a React component, call `useDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDisplaysQuery(baseOptions: Apollo.QueryHookOptions<DisplaysQuery, DisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysQuery, DisplaysQueryVariables>(DisplaysDocument, options);
      }
export function useDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysQuery, DisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysQuery, DisplaysQueryVariables>(DisplaysDocument, options);
        }
export type DisplaysQueryHookResult = ReturnType<typeof useDisplaysQuery>;
export type DisplaysLazyQueryHookResult = ReturnType<typeof useDisplaysLazyQuery>;
export type DisplaysQueryResult = Apollo.QueryResult<DisplaysQuery, DisplaysQueryVariables>;
export const PlatformConfigDocument = gql`
    query PlatformConfig {
  platformConfig {
    hasGen2Pods
    hasElementPods
  }
}
    `;

/**
 * __usePlatformConfigQuery__
 *
 * To run a query within a React component, call `usePlatformConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlatformConfigQuery(baseOptions?: Apollo.QueryHookOptions<PlatformConfigQuery, PlatformConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformConfigQuery, PlatformConfigQueryVariables>(PlatformConfigDocument, options);
      }
export function usePlatformConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformConfigQuery, PlatformConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformConfigQuery, PlatformConfigQueryVariables>(PlatformConfigDocument, options);
        }
export type PlatformConfigQueryHookResult = ReturnType<typeof usePlatformConfigQuery>;
export type PlatformConfigLazyQueryHookResult = ReturnType<typeof usePlatformConfigLazyQuery>;
export type PlatformConfigQueryResult = Apollo.QueryResult<PlatformConfigQuery, PlatformConfigQueryVariables>;
export const RealtimeDisplaysDocument = gql`
    query RealtimeDisplays($options: RealtimeDisplaysOptions) {
  realtimeDisplays(options: $options) {
    realtimeStatus
    org
    display
    name
    solsticeUserCount
    calendarConfigEnabled
    isOccupied
    footTraffic
    currentCalendarStart
    currentCalendarEnd
    currentCalendarAttendees
    nextCalendarStart
    nextCalendarEnd
    nextCalendarAttendees
    building
    nickname
    preciseLat
    preciseLong
  }
}
    `;

/**
 * __useRealtimeDisplaysQuery__
 *
 * To run a query within a React component, call `useRealtimeDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealtimeDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealtimeDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRealtimeDisplaysQuery(baseOptions?: Apollo.QueryHookOptions<RealtimeDisplaysQuery, RealtimeDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealtimeDisplaysQuery, RealtimeDisplaysQueryVariables>(RealtimeDisplaysDocument, options);
      }
export function useRealtimeDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealtimeDisplaysQuery, RealtimeDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealtimeDisplaysQuery, RealtimeDisplaysQueryVariables>(RealtimeDisplaysDocument, options);
        }
export type RealtimeDisplaysQueryHookResult = ReturnType<typeof useRealtimeDisplaysQuery>;
export type RealtimeDisplaysLazyQueryHookResult = ReturnType<typeof useRealtimeDisplaysLazyQuery>;
export type RealtimeDisplaysQueryResult = Apollo.QueryResult<RealtimeDisplaysQuery, RealtimeDisplaysQueryVariables>;
export const RecentAlertsDocument = gql`
    query RecentAlerts($options: RecentAlertsOptions!) {
  recentAlerts(options: $options) {
    totalDisplays
    totalAlerts
    alerts {
      date
      totalAlerts
    }
  }
}
    `;

/**
 * __useRecentAlertsQuery__
 *
 * To run a query within a React component, call `useRecentAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentAlertsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRecentAlertsQuery(baseOptions: Apollo.QueryHookOptions<RecentAlertsQuery, RecentAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentAlertsQuery, RecentAlertsQueryVariables>(RecentAlertsDocument, options);
      }
export function useRecentAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentAlertsQuery, RecentAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentAlertsQuery, RecentAlertsQueryVariables>(RecentAlertsDocument, options);
        }
export type RecentAlertsQueryHookResult = ReturnType<typeof useRecentAlertsQuery>;
export type RecentAlertsLazyQueryHookResult = ReturnType<typeof useRecentAlertsLazyQuery>;
export type RecentAlertsQueryResult = Apollo.QueryResult<RecentAlertsQuery, RecentAlertsQueryVariables>;
export const SdsPodsPerHostDocument = gql`
    query SdsPodsPerHost($options: PodsPerSdsHostOptions) {
  podsPerSdsHost(options: $options) {
    sdsHosts {
      host
      podCount
    }
  }
}
    `;

/**
 * __useSdsPodsPerHostQuery__
 *
 * To run a query within a React component, call `useSdsPodsPerHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsPodsPerHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsPodsPerHostQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSdsPodsPerHostQuery(baseOptions?: Apollo.QueryHookOptions<SdsPodsPerHostQuery, SdsPodsPerHostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsPodsPerHostQuery, SdsPodsPerHostQueryVariables>(SdsPodsPerHostDocument, options);
      }
export function useSdsPodsPerHostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsPodsPerHostQuery, SdsPodsPerHostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsPodsPerHostQuery, SdsPodsPerHostQueryVariables>(SdsPodsPerHostDocument, options);
        }
export type SdsPodsPerHostQueryHookResult = ReturnType<typeof useSdsPodsPerHostQuery>;
export type SdsPodsPerHostLazyQueryHookResult = ReturnType<typeof useSdsPodsPerHostLazyQuery>;
export type SdsPodsPerHostQueryResult = Apollo.QueryResult<SdsPodsPerHostQuery, SdsPodsPerHostQueryVariables>;
export const SecretValidationDocument = gql`
    query SecretValidation($options: SecretVerificationOptions!) {
  secretVerification(options: $options) {
    verified
  }
}
    `;

/**
 * __useSecretValidationQuery__
 *
 * To run a query within a React component, call `useSecretValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretValidationQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSecretValidationQuery(baseOptions: Apollo.QueryHookOptions<SecretValidationQuery, SecretValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecretValidationQuery, SecretValidationQueryVariables>(SecretValidationDocument, options);
      }
export function useSecretValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecretValidationQuery, SecretValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecretValidationQuery, SecretValidationQueryVariables>(SecretValidationDocument, options);
        }
export type SecretValidationQueryHookResult = ReturnType<typeof useSecretValidationQuery>;
export type SecretValidationLazyQueryHookResult = ReturnType<typeof useSecretValidationLazyQuery>;
export type SecretValidationQueryResult = Apollo.QueryResult<SecretValidationQuery, SecretValidationQueryVariables>;
export const SettingsGroupsDocument = gql`
    query SettingsGroups {
  settingsGroups {
    name
    type
    templates {
      id
      name
    }
    settings {
      key
      value
      compatibility {
        minSoftware
        minHardware
        hardRequirement
      }
    }
  }
}
    `;

/**
 * __useSettingsGroupsQuery__
 *
 * To run a query within a React component, call `useSettingsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsGroupsQuery, SettingsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsGroupsQuery, SettingsGroupsQueryVariables>(SettingsGroupsDocument, options);
      }
export function useSettingsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsGroupsQuery, SettingsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsGroupsQuery, SettingsGroupsQueryVariables>(SettingsGroupsDocument, options);
        }
export type SettingsGroupsQueryHookResult = ReturnType<typeof useSettingsGroupsQuery>;
export type SettingsGroupsLazyQueryHookResult = ReturnType<typeof useSettingsGroupsLazyQuery>;
export type SettingsGroupsQueryResult = Apollo.QueryResult<SettingsGroupsQuery, SettingsGroupsQueryVariables>;
export const SharingDisplaysDocument = gql`
    query SharingDisplays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
    items {
      id
      name
      metrics {
        totalMinutesInUse
        totalConfSessions
        totalMeetings
      }
      displayCam {
        cam1
      }
      license {
        shortName
      }
      subscription {
        status
        expirationDate
      }
    }
  }
}
    `;

/**
 * __useSharingDisplaysQuery__
 *
 * To run a query within a React component, call `useSharingDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSharingDisplaysQuery(baseOptions: Apollo.QueryHookOptions<SharingDisplaysQuery, SharingDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharingDisplaysQuery, SharingDisplaysQueryVariables>(SharingDisplaysDocument, options);
      }
export function useSharingDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharingDisplaysQuery, SharingDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharingDisplaysQuery, SharingDisplaysQueryVariables>(SharingDisplaysDocument, options);
        }
export type SharingDisplaysQueryHookResult = ReturnType<typeof useSharingDisplaysQuery>;
export type SharingDisplaysLazyQueryHookResult = ReturnType<typeof useSharingDisplaysLazyQuery>;
export type SharingDisplaysQueryResult = Apollo.QueryResult<SharingDisplaysQuery, SharingDisplaysQueryVariables>;
export const SolsticeRoutingDisplaysDocument = gql`
    query SolsticeRoutingDisplays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
    items {
      id
      name
      versions {
        hardware
        software
        lms
      }
      ipAddressesPrimary
      subscription {
        status
        expirationDate
      }
    }
  }
}
    `;

/**
 * __useSolsticeRoutingDisplaysQuery__
 *
 * To run a query within a React component, call `useSolsticeRoutingDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeRoutingDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeRoutingDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSolsticeRoutingDisplaysQuery(baseOptions: Apollo.QueryHookOptions<SolsticeRoutingDisplaysQuery, SolsticeRoutingDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeRoutingDisplaysQuery, SolsticeRoutingDisplaysQueryVariables>(SolsticeRoutingDisplaysDocument, options);
      }
export function useSolsticeRoutingDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeRoutingDisplaysQuery, SolsticeRoutingDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeRoutingDisplaysQuery, SolsticeRoutingDisplaysQueryVariables>(SolsticeRoutingDisplaysDocument, options);
        }
export type SolsticeRoutingDisplaysQueryHookResult = ReturnType<typeof useSolsticeRoutingDisplaysQuery>;
export type SolsticeRoutingDisplaysLazyQueryHookResult = ReturnType<typeof useSolsticeRoutingDisplaysLazyQuery>;
export type SolsticeRoutingDisplaysQueryResult = Apollo.QueryResult<SolsticeRoutingDisplaysQuery, SolsticeRoutingDisplaysQueryVariables>;
export const SolsticeRoutingOrgDocument = gql`
    query SolsticeRoutingOrg($orgId: String!) {
  solsticeRoutingOrg(orgId: $orgId) {
    id
    ex_id
    require_password_on_exit
    ex_name
    updated_at
    created_at
  }
}
    `;

/**
 * __useSolsticeRoutingOrgQuery__
 *
 * To run a query within a React component, call `useSolsticeRoutingOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeRoutingOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeRoutingOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSolsticeRoutingOrgQuery(baseOptions: Apollo.QueryHookOptions<SolsticeRoutingOrgQuery, SolsticeRoutingOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeRoutingOrgQuery, SolsticeRoutingOrgQueryVariables>(SolsticeRoutingOrgDocument, options);
      }
export function useSolsticeRoutingOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeRoutingOrgQuery, SolsticeRoutingOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeRoutingOrgQuery, SolsticeRoutingOrgQueryVariables>(SolsticeRoutingOrgDocument, options);
        }
export type SolsticeRoutingOrgQueryHookResult = ReturnType<typeof useSolsticeRoutingOrgQuery>;
export type SolsticeRoutingOrgLazyQueryHookResult = ReturnType<typeof useSolsticeRoutingOrgLazyQuery>;
export type SolsticeRoutingOrgQueryResult = Apollo.QueryResult<SolsticeRoutingOrgQuery, SolsticeRoutingOrgQueryVariables>;
export const SolsticeRoutingRoutePresetsDocument = gql`
    query SolsticeRoutingRoutePresets($options: SolsticeRoutingRoutePresetsOptions) {
  solsticeRoutingRoutePresets(options: $options) {
    items {
      id
      name
      org
      created_at
      updated_at
      routes {
        id
        source_pod {
          id
        }
        sink_pod {
          id
        }
        created_at
      }
    }
  }
}
    `;

/**
 * __useSolsticeRoutingRoutePresetsQuery__
 *
 * To run a query within a React component, call `useSolsticeRoutingRoutePresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeRoutingRoutePresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeRoutingRoutePresetsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSolsticeRoutingRoutePresetsQuery(baseOptions?: Apollo.QueryHookOptions<SolsticeRoutingRoutePresetsQuery, SolsticeRoutingRoutePresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeRoutingRoutePresetsQuery, SolsticeRoutingRoutePresetsQueryVariables>(SolsticeRoutingRoutePresetsDocument, options);
      }
export function useSolsticeRoutingRoutePresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeRoutingRoutePresetsQuery, SolsticeRoutingRoutePresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeRoutingRoutePresetsQuery, SolsticeRoutingRoutePresetsQueryVariables>(SolsticeRoutingRoutePresetsDocument, options);
        }
export type SolsticeRoutingRoutePresetsQueryHookResult = ReturnType<typeof useSolsticeRoutingRoutePresetsQuery>;
export type SolsticeRoutingRoutePresetsLazyQueryHookResult = ReturnType<typeof useSolsticeRoutingRoutePresetsLazyQuery>;
export type SolsticeRoutingRoutePresetsQueryResult = Apollo.QueryResult<SolsticeRoutingRoutePresetsQuery, SolsticeRoutingRoutePresetsQueryVariables>;
export const SolsticeRoutingSpaceDocument = gql`
    query SolsticeRoutingSpace($spaceId: String!) {
  solsticeRoutingSpace(spaceId: $spaceId) {
    building_name
    created_at
    id
    last_used
    moderator_sharing_mode
    name
    org
    partition
    primary_pod
    require_password_on_exit
    require_screen_key_verification
    room_number
    show_routing_banner
    space_type
    updated_at
    pods {
      id
      name
      audio_routing_enabled
      color
      row
      column
      created_at
      updated_at
      ipAddressPrimary
      ipAddressWifi
    }
    route_presets {
      id
      name
      routes {
        sink_pod {
          id
          name
        }
        source_pod {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSolsticeRoutingSpaceQuery__
 *
 * To run a query within a React component, call `useSolsticeRoutingSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeRoutingSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeRoutingSpaceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSolsticeRoutingSpaceQuery(baseOptions: Apollo.QueryHookOptions<SolsticeRoutingSpaceQuery, SolsticeRoutingSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeRoutingSpaceQuery, SolsticeRoutingSpaceQueryVariables>(SolsticeRoutingSpaceDocument, options);
      }
export function useSolsticeRoutingSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeRoutingSpaceQuery, SolsticeRoutingSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeRoutingSpaceQuery, SolsticeRoutingSpaceQueryVariables>(SolsticeRoutingSpaceDocument, options);
        }
export type SolsticeRoutingSpaceQueryHookResult = ReturnType<typeof useSolsticeRoutingSpaceQuery>;
export type SolsticeRoutingSpaceLazyQueryHookResult = ReturnType<typeof useSolsticeRoutingSpaceLazyQuery>;
export type SolsticeRoutingSpaceQueryResult = Apollo.QueryResult<SolsticeRoutingSpaceQuery, SolsticeRoutingSpaceQueryVariables>;
export const SolsticeRoutingSpacesDocument = gql`
    query SolsticeRoutingSpaces($options: SolsticeRoutingSpacesOptions!) {
  solsticeRoutingSpaces(options: $options) {
    items {
      id
      name
      org
      created_at
      last_used
      building_name
      room_number
      space_type
    }
    totalItems
  }
}
    `;

/**
 * __useSolsticeRoutingSpacesQuery__
 *
 * To run a query within a React component, call `useSolsticeRoutingSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeRoutingSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeRoutingSpacesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSolsticeRoutingSpacesQuery(baseOptions: Apollo.QueryHookOptions<SolsticeRoutingSpacesQuery, SolsticeRoutingSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeRoutingSpacesQuery, SolsticeRoutingSpacesQueryVariables>(SolsticeRoutingSpacesDocument, options);
      }
export function useSolsticeRoutingSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeRoutingSpacesQuery, SolsticeRoutingSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeRoutingSpacesQuery, SolsticeRoutingSpacesQueryVariables>(SolsticeRoutingSpacesDocument, options);
        }
export type SolsticeRoutingSpacesQueryHookResult = ReturnType<typeof useSolsticeRoutingSpacesQuery>;
export type SolsticeRoutingSpacesLazyQueryHookResult = ReturnType<typeof useSolsticeRoutingSpacesLazyQuery>;
export type SolsticeRoutingSpacesQueryResult = Apollo.QueryResult<SolsticeRoutingSpacesQuery, SolsticeRoutingSpacesQueryVariables>;
export const SsoInfoDocument = gql`
    query SsoInfo {
  ssoOrg {
    idp_id
    idp_url
    idp_entity_id
    idp_cert_timestamp
  }
  idps {
    id
    sso_url_regex
    name
    route
    example_idp_url
  }
}
    `;

/**
 * __useSsoInfoQuery__
 *
 * To run a query within a React component, call `useSsoInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSsoInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSsoInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSsoInfoQuery(baseOptions?: Apollo.QueryHookOptions<SsoInfoQuery, SsoInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SsoInfoQuery, SsoInfoQueryVariables>(SsoInfoDocument, options);
      }
export function useSsoInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SsoInfoQuery, SsoInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SsoInfoQuery, SsoInfoQueryVariables>(SsoInfoDocument, options);
        }
export type SsoInfoQueryHookResult = ReturnType<typeof useSsoInfoQuery>;
export type SsoInfoLazyQueryHookResult = ReturnType<typeof useSsoInfoLazyQuery>;
export type SsoInfoQueryResult = Apollo.QueryResult<SsoInfoQuery, SsoInfoQueryVariables>;
export const GetSupportAccessDocument = gql`
    query GetSupportAccess {
  supportAccess {
    id
    grant_support_access_until
  }
}
    `;

/**
 * __useGetSupportAccessQuery__
 *
 * To run a query within a React component, call `useGetSupportAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportAccessQuery(baseOptions?: Apollo.QueryHookOptions<GetSupportAccessQuery, GetSupportAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportAccessQuery, GetSupportAccessQueryVariables>(GetSupportAccessDocument, options);
      }
export function useGetSupportAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportAccessQuery, GetSupportAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportAccessQuery, GetSupportAccessQueryVariables>(GetSupportAccessDocument, options);
        }
export type GetSupportAccessQueryHookResult = ReturnType<typeof useGetSupportAccessQuery>;
export type GetSupportAccessLazyQueryHookResult = ReturnType<typeof useGetSupportAccessLazyQuery>;
export type GetSupportAccessQueryResult = Apollo.QueryResult<GetSupportAccessQuery, GetSupportAccessQueryVariables>;
export const TemplateCompatibilityDocument = gql`
    query TemplateCompatibility($options: TemplateCompatibilityOptions) {
  templateCompatibility(options: $options) {
    templateId
    isCompatible
    isHardRequirement
    incompatibleReason
  }
}
    `;

/**
 * __useTemplateCompatibilityQuery__
 *
 * To run a query within a React component, call `useTemplateCompatibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateCompatibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateCompatibilityQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTemplateCompatibilityQuery(baseOptions?: Apollo.QueryHookOptions<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>(TemplateCompatibilityDocument, options);
      }
export function useTemplateCompatibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>(TemplateCompatibilityDocument, options);
        }
export type TemplateCompatibilityQueryHookResult = ReturnType<typeof useTemplateCompatibilityQuery>;
export type TemplateCompatibilityLazyQueryHookResult = ReturnType<typeof useTemplateCompatibilityLazyQuery>;
export type TemplateCompatibilityQueryResult = Apollo.QueryResult<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>;
export const TemplateListDocument = gql`
    query TemplateList($options: TemplateSearchOptions!) {
  templates(options: $options) {
    ...Template
  }
}
    ${TemplateFragmentDoc}`;

/**
 * __useTemplateListQuery__
 *
 * To run a query within a React component, call `useTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTemplateListQuery(baseOptions: Apollo.QueryHookOptions<TemplateListQuery, TemplateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateListQuery, TemplateListQueryVariables>(TemplateListDocument, options);
      }
export function useTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateListQuery, TemplateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateListQuery, TemplateListQueryVariables>(TemplateListDocument, options);
        }
export type TemplateListQueryHookResult = ReturnType<typeof useTemplateListQuery>;
export type TemplateListLazyQueryHookResult = ReturnType<typeof useTemplateListLazyQuery>;
export type TemplateListQueryResult = Apollo.QueryResult<TemplateListQuery, TemplateListQueryVariables>;
export const TimeZonesDocument = gql`
    query TimeZones {
  timeZones {
    text
    value
  }
}
    `;

/**
 * __useTimeZonesQuery__
 *
 * To run a query within a React component, call `useTimeZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeZonesQuery(baseOptions?: Apollo.QueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, options);
      }
export function useTimeZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, options);
        }
export type TimeZonesQueryHookResult = ReturnType<typeof useTimeZonesQuery>;
export type TimeZonesLazyQueryHookResult = ReturnType<typeof useTimeZonesLazyQuery>;
export type TimeZonesQueryResult = Apollo.QueryResult<TimeZonesQuery, TimeZonesQueryVariables>;
export const TotalDisplaysDocument = gql`
    query TotalDisplays($options: DisplaySearchOptions!) {
  displays(options: $options) {
    totalRecords
  }
}
    `;

/**
 * __useTotalDisplaysQuery__
 *
 * To run a query within a React component, call `useTotalDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalDisplaysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTotalDisplaysQuery(baseOptions: Apollo.QueryHookOptions<TotalDisplaysQuery, TotalDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalDisplaysQuery, TotalDisplaysQueryVariables>(TotalDisplaysDocument, options);
      }
export function useTotalDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalDisplaysQuery, TotalDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalDisplaysQuery, TotalDisplaysQueryVariables>(TotalDisplaysDocument, options);
        }
export type TotalDisplaysQueryHookResult = ReturnType<typeof useTotalDisplaysQuery>;
export type TotalDisplaysLazyQueryHookResult = ReturnType<typeof useTotalDisplaysLazyQuery>;
export type TotalDisplaysQueryResult = Apollo.QueryResult<TotalDisplaysQuery, TotalDisplaysQueryVariables>;
export const TotalDisplaysNoViewDocument = gql`
    query TotalDisplaysNoView($options: TotalDisplaysNoViewOptions) {
  totalDisplays(options: $options)
}
    `;

/**
 * __useTotalDisplaysNoViewQuery__
 *
 * To run a query within a React component, call `useTotalDisplaysNoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalDisplaysNoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalDisplaysNoViewQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTotalDisplaysNoViewQuery(baseOptions?: Apollo.QueryHookOptions<TotalDisplaysNoViewQuery, TotalDisplaysNoViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalDisplaysNoViewQuery, TotalDisplaysNoViewQueryVariables>(TotalDisplaysNoViewDocument, options);
      }
export function useTotalDisplaysNoViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalDisplaysNoViewQuery, TotalDisplaysNoViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalDisplaysNoViewQuery, TotalDisplaysNoViewQueryVariables>(TotalDisplaysNoViewDocument, options);
        }
export type TotalDisplaysNoViewQueryHookResult = ReturnType<typeof useTotalDisplaysNoViewQuery>;
export type TotalDisplaysNoViewLazyQueryHookResult = ReturnType<typeof useTotalDisplaysNoViewLazyQuery>;
export type TotalDisplaysNoViewQueryResult = Apollo.QueryResult<TotalDisplaysNoViewQuery, TotalDisplaysNoViewQueryVariables>;
export const TotalSelectableDisplaysDocument = gql`
    query TotalSelectableDisplays($searchTerm: String, $categories: [CategoryFilter!], $locationFilters: LocationFilters, $displayIds: [String], $excludeDisplayIds: [String], $isIncludedElementPods: Boolean) {
  totalSelectableDisplays: displays(
    options: {categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, isIncludedElementPods: $isIncludedElementPods}
  ) {
    totalRecords
  }
  totalSelectedDisplays: displays(
    options: {categories: $categories, locationFilters: $locationFilters, searchTerm: $searchTerm, displayIds: $displayIds, excludeDisplayIds: $excludeDisplayIds, isIncludedElementPods: $isIncludedElementPods}
  ) {
    totalRecords
  }
}
    `;

/**
 * __useTotalSelectableDisplaysQuery__
 *
 * To run a query within a React component, call `useTotalSelectableDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalSelectableDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalSelectableDisplaysQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      categories: // value for 'categories'
 *      locationFilters: // value for 'locationFilters'
 *      displayIds: // value for 'displayIds'
 *      excludeDisplayIds: // value for 'excludeDisplayIds'
 *      isIncludedElementPods: // value for 'isIncludedElementPods'
 *   },
 * });
 */
export function useTotalSelectableDisplaysQuery(baseOptions?: Apollo.QueryHookOptions<TotalSelectableDisplaysQuery, TotalSelectableDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalSelectableDisplaysQuery, TotalSelectableDisplaysQueryVariables>(TotalSelectableDisplaysDocument, options);
      }
export function useTotalSelectableDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalSelectableDisplaysQuery, TotalSelectableDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalSelectableDisplaysQuery, TotalSelectableDisplaysQueryVariables>(TotalSelectableDisplaysDocument, options);
        }
export type TotalSelectableDisplaysQueryHookResult = ReturnType<typeof useTotalSelectableDisplaysQuery>;
export type TotalSelectableDisplaysLazyQueryHookResult = ReturnType<typeof useTotalSelectableDisplaysLazyQuery>;
export type TotalSelectableDisplaysQueryResult = Apollo.QueryResult<TotalSelectableDisplaysQuery, TotalSelectableDisplaysQueryVariables>;
export const UpdateablePodsDocument = gql`
    query updateablePods($options: UpdateablePodsOptions) {
  updateablePods(options: $options) {
    totalPodsCount
    updateablePodsCount
    pods {
      displayId
      name
      version
      updateable
      updating
      status
      catchingUp
      subscriptionEndDate
      licenseShortName
      hardware
    }
  }
}
    `;

/**
 * __useUpdateablePodsQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateablePodsQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsQuery, UpdateablePodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsQuery, UpdateablePodsQueryVariables>(UpdateablePodsDocument, options);
      }
export function useUpdateablePodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsQuery, UpdateablePodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsQuery, UpdateablePodsQueryVariables>(UpdateablePodsDocument, options);
        }
export type UpdateablePodsQueryHookResult = ReturnType<typeof useUpdateablePodsQuery>;
export type UpdateablePodsLazyQueryHookResult = ReturnType<typeof useUpdateablePodsLazyQuery>;
export type UpdateablePodsQueryResult = Apollo.QueryResult<UpdateablePodsQuery, UpdateablePodsQueryVariables>;
export const UpdateablePodsByVersionDocument = gql`
    query UpdateablePodsByVersion {
  updateablePodsByVersion {
    version
    count
  }
}
    `;

/**
 * __useUpdateablePodsByVersionQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsByVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsByVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsByVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateablePodsByVersionQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsByVersionQuery, UpdateablePodsByVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsByVersionQuery, UpdateablePodsByVersionQueryVariables>(UpdateablePodsByVersionDocument, options);
      }
export function useUpdateablePodsByVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsByVersionQuery, UpdateablePodsByVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsByVersionQuery, UpdateablePodsByVersionQueryVariables>(UpdateablePodsByVersionDocument, options);
        }
export type UpdateablePodsByVersionQueryHookResult = ReturnType<typeof useUpdateablePodsByVersionQuery>;
export type UpdateablePodsByVersionLazyQueryHookResult = ReturnType<typeof useUpdateablePodsByVersionLazyQuery>;
export type UpdateablePodsByVersionQueryResult = Apollo.QueryResult<UpdateablePodsByVersionQuery, UpdateablePodsByVersionQueryVariables>;
export const UpdateablePodsCountDocument = gql`
    query updateablePodsCount($options: UpdateablePodsOptions) {
  updateablePods(options: $options) {
    totalPodsCount
    updateablePodsCount
  }
}
    `;

/**
 * __useUpdateablePodsCountQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsCountQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateablePodsCountQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>(UpdateablePodsCountDocument, options);
      }
export function useUpdateablePodsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>(UpdateablePodsCountDocument, options);
        }
export type UpdateablePodsCountQueryHookResult = ReturnType<typeof useUpdateablePodsCountQuery>;
export type UpdateablePodsCountLazyQueryHookResult = ReturnType<typeof useUpdateablePodsCountLazyQuery>;
export type UpdateablePodsCountQueryResult = Apollo.QueryResult<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>;
export const UsageByRoomTypeDocument = gql`
    query UsageByRoomType($options: UsageByRoomTypeOptions!) {
  usageByRoomType(options: $options) {
    roomTypes {
      id
      name
      totalMeetingLength
    }
  }
}
    `;

/**
 * __useUsageByRoomTypeQuery__
 *
 * To run a query within a React component, call `useUsageByRoomTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageByRoomTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageByRoomTypeQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsageByRoomTypeQuery(baseOptions: Apollo.QueryHookOptions<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>(UsageByRoomTypeDocument, options);
      }
export function useUsageByRoomTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>(UsageByRoomTypeDocument, options);
        }
export type UsageByRoomTypeQueryHookResult = ReturnType<typeof useUsageByRoomTypeQuery>;
export type UsageByRoomTypeLazyQueryHookResult = ReturnType<typeof useUsageByRoomTypeLazyQuery>;
export type UsageByRoomTypeQueryResult = Apollo.QueryResult<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>;
export const UsageGeoDataDocument = gql`
    query UsageGeoData($options: UsageGeoDataOptions!) {
  usageGeoData(options: $options) {
    mapData {
      outOfTemplateCount
      id
      name
      preciseLat
      preciseLong
      restartCount
      totalAlerts
      unreachableCount
      usageHours
      usbAddedEventCount
      usbRemovedEventCount
    }
  }
}
    `;

/**
 * __useUsageGeoDataQuery__
 *
 * To run a query within a React component, call `useUsageGeoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageGeoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageGeoDataQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsageGeoDataQuery(baseOptions: Apollo.QueryHookOptions<UsageGeoDataQuery, UsageGeoDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsageGeoDataQuery, UsageGeoDataQueryVariables>(UsageGeoDataDocument, options);
      }
export function useUsageGeoDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsageGeoDataQuery, UsageGeoDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsageGeoDataQuery, UsageGeoDataQueryVariables>(UsageGeoDataDocument, options);
        }
export type UsageGeoDataQueryHookResult = ReturnType<typeof useUsageGeoDataQuery>;
export type UsageGeoDataLazyQueryHookResult = ReturnType<typeof useUsageGeoDataLazyQuery>;
export type UsageGeoDataQueryResult = Apollo.QueryResult<UsageGeoDataQuery, UsageGeoDataQueryVariables>;
export const UsbDeviceConnectionsDocument = gql`
    query UsbDeviceConnections($options: UsbPageOptions) {
  usbDevices(options: $options) {
    usbDevices {
      avDevice
      deviceId
      deviceName
      deviceProtocol
      deviceSubclass
      display {
        org
        id
        hardware
        version
        isInSubscription
        name
      }
      displayId
      fullProductName
      isConferenceReady
      isOccupancyReady
      isOnline
      isUpdatable
      occupancyCountingEnabled
      orgId
      partition
      plugOrInplug
      productId
      productName
      relayEnabled
      usbPlugTimestamp
      vendorId
      vendorName
      version
    }
    history {
      date
      plugCount
      unplugCount
    }
    products {
      productId
      productName
      displayCount
    }
    usbDeviceCount
    usbOnlineDeviceCount
    usbOfflineDeviceCount
    usbAvDeviceCount
    usbAvOthersDeviceCount
    podsOnlineCount
    podsWithDevicesOnlineCount
    plugCountSum
    unplugCountSum
    productConnectionsCount
  }
}
    `;

/**
 * __useUsbDeviceConnectionsQuery__
 *
 * To run a query within a React component, call `useUsbDeviceConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDeviceConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDeviceConnectionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsbDeviceConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<UsbDeviceConnectionsQuery, UsbDeviceConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDeviceConnectionsQuery, UsbDeviceConnectionsQueryVariables>(UsbDeviceConnectionsDocument, options);
      }
export function useUsbDeviceConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDeviceConnectionsQuery, UsbDeviceConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDeviceConnectionsQuery, UsbDeviceConnectionsQueryVariables>(UsbDeviceConnectionsDocument, options);
        }
export type UsbDeviceConnectionsQueryHookResult = ReturnType<typeof useUsbDeviceConnectionsQuery>;
export type UsbDeviceConnectionsLazyQueryHookResult = ReturnType<typeof useUsbDeviceConnectionsLazyQuery>;
export type UsbDeviceConnectionsQueryResult = Apollo.QueryResult<UsbDeviceConnectionsQuery, UsbDeviceConnectionsQueryVariables>;
export const UserDevicesDocument = gql`
    query UserDevices($options: AnalyticCountsOptions!) {
  userDevices(options: $options) {
    name
    total
  }
}
    `;

/**
 * __useUserDevicesQuery__
 *
 * To run a query within a React component, call `useUserDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDevicesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserDevicesQuery(baseOptions: Apollo.QueryHookOptions<UserDevicesQuery, UserDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDevicesQuery, UserDevicesQueryVariables>(UserDevicesDocument, options);
      }
export function useUserDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDevicesQuery, UserDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDevicesQuery, UserDevicesQueryVariables>(UserDevicesDocument, options);
        }
export type UserDevicesQueryHookResult = ReturnType<typeof useUserDevicesQuery>;
export type UserDevicesLazyQueryHookResult = ReturnType<typeof useUserDevicesLazyQuery>;
export type UserDevicesQueryResult = Apollo.QueryResult<UserDevicesQuery, UserDevicesQueryVariables>;
export const VersionsDocument = gql`
    query Versions {
  versions {
    releases {
      version
      customerFacingVersion
      date
    }
  }
}
    `;

/**
 * __useVersionsQuery__
 *
 * To run a query within a React component, call `useVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionsQuery(baseOptions?: Apollo.QueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
      }
export function useVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
        }
export type VersionsQueryHookResult = ReturnType<typeof useVersionsQuery>;
export type VersionsLazyQueryHookResult = ReturnType<typeof useVersionsLazyQuery>;
export type VersionsQueryResult = Apollo.QueryResult<VersionsQuery, VersionsQueryVariables>;