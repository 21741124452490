import React from 'react'
import { pluralize } from 'shared/core/utils'
import Metric from './Metric'

interface UniqueUsersProps {
  count: number
}

const UniqueUsers = ({ count }: UniqueUsersProps) => {
  return (
    <Metric
      live={false}
      count={count}
      caption={pluralize(count, 'Unique user', false)}
      dataTestId="user-card"
      url="/user-trends"
      linkName="USER TRENDS"
    />
  )
}

export default UniqueUsers
