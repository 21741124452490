import React from 'react'
import { RouteProps } from 'react-router'
// page components
import PodConfig from 'pages/PodConfig/PodConfig'
import TemplatePage from 'pages/Templates/TemplatePage'
import { SettingsGroupType } from 'graphql/__generated__/types'
import { welcomeScreenDefault } from 'pages/Templates/WelcomeScreen/WelcomeScreen.types'
import { defaultEthernetTemplate } from 'pages/Templates/Ethernet/EthernetTypes'
import { defaultProxyTemplate } from 'pages/Templates/Proxy/ProxyTypes'
import { defaultDiscoveryTemplate } from 'pages/Templates/Discovery/DiscoveryTypes'
import { defaultTimeLocaleTemplate } from 'pages/Templates/TimeLocale/TimeLocaleTypes'
import { defaultAdvancedTemplate } from 'pages/Templates/Advanced/AdvancedTypes'
import { defaultFeaturesTemplate } from 'pages/Templates/Features/FeaturesTypes'
import { defaultPowerManagementTemplate } from '../pages/Templates/PowerMgmt/PowerManagementTypes'
import { defaultDigitalSignageTemplate } from 'pages/Templates/DigitalSignage/DigitalSignageTypes'
import { defaultSecurityTemplate } from 'pages/Templates/Security/SecurityTypes'
import { defaultMessageCenterTemplate } from 'pages/Templates/MessageCenter/MessageCenterTypes'
import { defaultCalendarTemplate } from 'pages/Templates/Calendar/CalendarTypes'
import { defaultWifiTemplate } from 'pages/Templates/WiFi/WifiTypes'
import { defaultRoomIntelligenceTemplate } from '../pages/Templates/RoomIntelligence/RoomIntelligenceTypes'
import Users from 'pages/Users'
import UpdatesPage from 'pages/Updates/UpdatesController'
import ComparePage from 'pages/Compare/ComparePage'
import AllPods from 'pages/AllPods/AllPodsPage'
import Location from 'pages/Location/Location'
import Usb from 'pages/USB'
import UsageHistoryPage from 'pages/UsageHistory/UsageHistory'
import AlertsPage from 'pages/Alerts/Alerts'
import HistoryPage from 'pages/History/History'
import Availability from 'pages/Availability'
import DeploymentPage from 'pages/Deployment'
import CategoryPage from 'pages/Category/CategoryController'
import HomePage from 'pages/Home/Home'
import ActiveLearningPage from 'pages/ActiveLearning/ActiveLearning'
import SolsticeRouting from 'pages/SolsticeRouting'
import SharingPage from 'pages/Sharing/SharingPage'
import UserTrendsPage from 'pages/UsersTrends/UserTrendsPage'
import RealtimePage from 'pages/Realtime'
import Login from 'pages/Login'
import SelfAuthPage from 'pages/SelfAuth/SelfAuthPage'
import Invite from 'pages/Invite/Invite'
import ActiveLearningApp from 'pages/ActiveLearningApp/ActiveLearningApp'
import RenewFlow from 'pages/Maintenance/RenewFlow'
import SSO from 'pages/SSO'
import ChangePassword from 'pages/ChangePassword'
import SsoError from 'pages/SsoError/SsoError'
import CreateOrg from 'pages/CreateOrg'
import SupportLogin from 'pages/SupportLogin'
import SpacePage from 'pages/SolsticeRouting/SpacePageTabs/SpacePage'
import SolsticeRoutingSettings from 'pages/SolsticeRouting/Settings'

export type RouteObj = {
  key?: string
  path: string
  exact?: boolean
} & Pick<RouteProps, 'component' | 'render'>

export const routesWithMenuAuth = ({
  locationsPage = false,
  userAnalyticsPage = false,
  realtimePage = false,
  MANAGE_ENABLED = false,
  ssoAdminPage = false,
}) => {
  return [
    {
      path: '/home',
      exact: true,
      component: HomePage,
    },
    {
      path: '/availability',
      component: Availability,
    },
    {
      path: '/deployment',
      component: DeploymentPage,
    },
    {
      path: '/alerts',
      component: AlertsPage,
    },
    {
      path: '/history',
      component: HistoryPage,
    },
    {
      path: '/categories',
      component: CategoryPage,
    },
    {
      path: '/solstice-routing',
      component: SolsticeRouting,
    },
    {
      path: '/solstice-routing/settings',
      component: SolsticeRoutingSettings,
    },
    {
      path: '/solstice-routing/space/:spaceId?',
      component: SpacePage,
    },
    {
      path: '/active-learning',
      component: ActiveLearningPage,
    },
    {
      path: '/user-trends',
      component: UserTrendsPage,
      enabled: userAnalyticsPage,
    },
    {
      path: '/usage-history',
      component: UsageHistoryPage,
    },
    {
      path: '/in-room-tech',
      component: SharingPage,
    },
    {
      path: '/real-time',
      enabled: realtimePage,
      component: RealtimePage,
    },
    {
      exact: true,
      path: '/manage/organization/users',
      enabled: MANAGE_ENABLED,
      component: Users,
    },
    {
      exact: true,
      path: '/manage/updates',
      component: UpdatesPage,
      enabled: MANAGE_ENABLED,
    },

    {
      exact: true,
      path: '/manage/location',
      enabled: locationsPage && MANAGE_ENABLED,
      component: Location,
    },
    {
      exact: true,
      path: '/manage/usb',
      component: Usb,
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/pod/:podId',
      component: PodConfig,
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/welcome-screen',
      render: () => (
        <TemplatePage defaultTemplate={welcomeScreenDefault} settingsGroup={SettingsGroupType.WelcomeScreen} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/ethernet',
      render: () => (
        <TemplatePage defaultTemplate={defaultEthernetTemplate} settingsGroup={SettingsGroupType.Ethernet} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/proxy',
      render: () => <TemplatePage defaultTemplate={defaultProxyTemplate} settingsGroup={SettingsGroupType.Proxy} />,
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/wifi',
      render: () => <TemplatePage defaultTemplate={defaultWifiTemplate} settingsGroup={SettingsGroupType.Wifi} />,
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/calendar',
      render: () => (
        <TemplatePage defaultTemplate={defaultCalendarTemplate} settingsGroup={SettingsGroupType.Calendar} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/discovery',
      render: () => (
        <TemplatePage defaultTemplate={defaultDiscoveryTemplate} settingsGroup={SettingsGroupType.Discovery} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/digital-signage',
      render: () => (
        <TemplatePage
          defaultTemplate={defaultDigitalSignageTemplate}
          settingsGroup={SettingsGroupType.DigitalSignage}
        />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/advanced',
      render: () => (
        <TemplatePage defaultTemplate={defaultAdvancedTemplate} settingsGroup={SettingsGroupType.Advanced} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/features',
      render: () => (
        <TemplatePage defaultTemplate={defaultFeaturesTemplate} settingsGroup={SettingsGroupType.Features} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/power',
      render: () => (
        <TemplatePage
          defaultTemplate={defaultPowerManagementTemplate}
          settingsGroup={SettingsGroupType.PowerManagement}
        />
      ),
      enabled: MANAGE_ENABLED,
    },

    {
      exact: true,
      path: '/manage/templates/room-intelligence',
      render: () => (
        <TemplatePage
          defaultTemplate={defaultRoomIntelligenceTemplate}
          settingsGroup={SettingsGroupType.RoomIntelligence}
        />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/message',
      render: () => (
        <TemplatePage defaultTemplate={defaultMessageCenterTemplate} settingsGroup={SettingsGroupType.MessageCenter} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/security',
      render: () => (
        <TemplatePage defaultTemplate={defaultSecurityTemplate} settingsGroup={SettingsGroupType.Security} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/templates/locale',
      render: () => (
        <TemplatePage defaultTemplate={defaultTimeLocaleTemplate} settingsGroup={SettingsGroupType.TimeLocale} />
      ),
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/manage/organization/sso',
      enabled: ssoAdminPage && MANAGE_ENABLED,
      component: SSO,
    },
    {
      exact: true,
      path: '/manage/accounts/change-password',
      component: ChangePassword,
    },
    {
      exact: true,
      path: '/compare',
      component: ComparePage,
    },
    {
      exact: true,
      path: '/manage/pods',
      component: AllPods,
      enabled: MANAGE_ENABLED,
    },
    {
      exact: true,
      path: '/_error',
      render: () => {
        throw new Error('Error Route')
      },
    },
  ]
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const publicRoutes = ({ createOrg =  false }) => [
  {
    exact: true,
    path: '/login',
    component: Login,
  },
  {
    exact: true,
    path: '/support',
    component: SupportLogin,
  },
  {
    exact: true,
    path: '/reset-password',
    component: Login,
  },
  {
    exact: true,
    path: '/self_auth',
    component: SelfAuthPage,
  },
  {
    exact: true,
    path: '/invitation',
    component: Invite,
  },
  {
    exact: true,
    path: '/active-learning-app',
    component: ActiveLearningApp,
  },
  {
    exact: false,
    path: '/sso-error',
    component: SsoError,
  },
  {
    exact: true,
    path: '/create-org',
    component: CreateOrg,
    // disabled temporarily, so that the feature is present for our support,
    // but not the link to confuse customers
    enabled: true,
  },
]

export const noMenuRoutes = () => [
  {
    exact: true,
    path: '/renew',
    component: RenewFlow,
  },
]
