import React from 'react'
import styles from './SolInput.module.scss'
import { Icon, Input, InputOnChangeData } from 'semantic-ui-react'
import classNames from 'classnames'
import SolButton from '../SolButton/SolButton'
import { SolTooltip } from 'SolComponents'

export interface SolInputProps {
  containerClassName?: string
  className?: string
  placeholder?: string
  autoFocus?: boolean
  error?: boolean
  errorText?: string | null
  label?: string | JSX.Element
  required?: boolean
  disabled?: boolean
  icon?: JSX.Element
  value?: string | null | undefined | number
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge' | 'fluid'
  onChange?: (e: React.SyntheticEvent<HTMLInputElement, Event> | null, value: InputOnChangeData) => void
  onFocus?: () => void
  onClick?: () => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement> | null) => void
  name?: string
  maxLength?: number
  minLength?: number
  id?: string
  clickable?: boolean
  onBlur?: () => void
  optionalInput?: boolean
  onInlineSave?: () => void
  mismatched?: boolean
}

export default function SolInput({
  containerClassName,
  className,
  placeholder,
  autoFocus,
  error,
  errorText,
  label,
  icon,
  required,
  disabled,
  value,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onKeyUp,
  size = 'medium',
  name,
  maxLength,
  id,
  clickable = true,
  optionalInput,
  onInlineSave,
  mismatched,
}: SolInputProps) {
  const labelComponent = (
    <label>
      {label}
      {required && <span className={styles.required}>*</span>}
      {optionalInput && <span className={styles.optionalInput}>&nbsp;(Optional)</span>}
      {icon && <span className={styles.icon}>{icon}</span>}
      { mismatched
        && <span className={styles.syncStateIcon}>
          <SolTooltip
            isInline
            trigger={<Icon  name="exclamation circle" color="red" />}
            text={`This setting does not match between Solstice Cloud and the 
          Pod. Please review this setting and try again.`}
            position="top left"
          />
        </span>
      }
    </label>
  )

  return (
    <div
      className={classNames(styles.inputGroup, containerClassName, className, {
        [styles.disabled]: disabled,
        [styles.fluid]: size === 'fluid',
        [styles.unclickable]: !clickable && disabled,
      })}
      onClick={e => e.stopPropagation()}
    >
      {label && labelComponent}
      <Input
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={name}
        className={classNames(
          className,
          { [styles.tiny]: size === 'tiny' },
          { [styles.small]: size === 'small' },
          { [styles.defaultMedium]: size === 'medium' },
          { [styles.large]: size === 'large' },
          { [styles.extraLarge]: size === 'extraLarge' },
        )}
        fluid={size === 'fluid'}
        type="text"
        value={value ?? ''}
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        error={error}
        autoComplete="off"
        maxLength={maxLength}
        id={id}
        onKeyUp={onKeyUp}
      >
        <input
          data-lpignore="true"
          className={classNames({
            [styles.inlineSave]: !!onInlineSave,
          })}
        />
        {!!onInlineSave && (
          <SolButton
            isLink
            onMouseDown={() => onInlineSave()}
            disabled={!value || error}
            basic
            text="SAVE"
            color="blue"
            className={styles.saveButton}
          />
        )}
      </Input>
      {typeof errorText === 'string' && <div className={styles.errorText}>{errorText}</div>}
    </div>
  )
}
