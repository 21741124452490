import React, { useEffect, useState } from 'react'
import styles from './EditAddMode.module.scss'
import { SolCancelSave, SolInput, SolTooltipWithLink } from 'SolComponents'
import { Input, Icon } from 'semantic-ui-react'
import parseGoogleData from './ParseGoogleData'
import { CardModal } from '../CardModal/CardModal'
import classNames from 'classnames'
import { CreateBaseAddress } from 'pages/PodConfig/SettingsCards/Map/MapsTypes'
import { googlePlacesApi } from 'shared/config/maps'
import { useNicknameIsValid } from './useNicknameIsValid'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'

export interface Props {
  onClose: () => void
  isOpen: boolean
  onAccept: (v: CreateBaseAddress) => void
  dropdownClick: () => void | undefined
}

enum ErrorMessage {
  None = '',
  AlreadyExists = 'Address already exists. Please select new address.',
  ImpreciseAddress = 'Please select a specific address.',
  NotFound = 'Location not found. Please reselect location.',
}

function initInput(): HTMLInputElement | null {
  const inputs = document.getElementsByTagName('input')
  var myElement: HTMLInputElement | null = null
  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i].id === 'autocompleteInput') {
      myElement = inputs[i]
    }
  }
  return myElement
}

export const AddGoogleAddress = ({ isOpen, onClose, onAccept }: Props) => {
  /* global google:readonly */
  let autocomplete: google.maps.places.Autocomplete
  const [googleMapsObject, setGoogleMapsObject] = useState<Partial<CreateBaseAddress>>()
  const [googleError, setGoogleError] = useState<ErrorMessage>()
  const { isValid: nicknameIsValid, loading: nickNameValidationLoading } = useNicknameIsValid(
    googleMapsObject?.nickname ?? '',
    undefined,
    googleMapsObject?.external_id ?? '',
  )

  useEffect(() => {
    const script = document.createElement('script')
    script.src = googlePlacesApi
    script.onload = () => {
      const myElement = initInput()
      if (myElement !== null) {
        autocomplete = new window.google.maps.places.Autocomplete(myElement, {
          types: ['geocode'],
        })

        // Avoid paying for data that you don't need by restricting the set of place fields that are returned to just the address components and formatted address.
        autocomplete.setFields(['address_components', 'geometry', 'place_id', 'formatted_address'])
        autocomplete.addListener('place_changed', handlePlaceChanged)
      }
    }

    document.body.appendChild(script)
  }, [])

  const handlePlaceChanged = async () => {
    const place = autocomplete.getPlace()
    if (!place) {
      return setGoogleError(ErrorMessage.NotFound)
    }

    const breakDown = parseGoogleData(place.address_components)
    setGoogleMapsObject({
      ...googleMapsObject,
      building: breakDown.building,
      nickname: breakDown.building,
      city: breakDown.city,
      state_province: breakDown.state_province,
      country: breakDown.country,
      zip_code: breakDown.zip_code,
      lat: place.geometry?.location.lat(),
      long: place.geometry?.location.lng(),
      external_id: place.place_id,
      address_full: place.formatted_address ?? '',
      provider: 'Google',
    })

    if (breakDown.building === undefined || breakDown.city === undefined || breakDown.country === undefined) {
      return setGoogleError(ErrorMessage.ImpreciseAddress)
    }
  }

  const clearAddress = () => {
    const myElement = initInput()
    if (myElement) {
      const setNativeValue = (element: any, value: string) => {
        let lastValue = element.value
        element.value = value
        let event = new Event('input', element)
        let tracker = element._valueTracker
        if (tracker) {
          tracker.setValue(lastValue)
        }
        element.dispatchEvent(event)
      }
      setNativeValue(myElement, '')
      setGoogleError(ErrorMessage.None)
      setGoogleMapsObject(undefined)
    }
  }

  return (
    <>
      <CardModal
        className={styles.modalContent}
        isOpen={isOpen}
        header="Add new building"
        modalInfo={
          <>
            <label
              className={classNames(styles.googlePlaceLabel, {
                [styles.errorLabel]: !!googleError,
              })}
            >
              Building Address
            </label>
            <div className={styles.firstInput}>
              <Input
                id="autocompleteInput"
                className={styles.googlePlaceInput}
                placeholder="Building Address"
                error={!!googleError}
                autoFocus
                onChange={() => {
                  if (googleError !== ErrorMessage.None) {
                    setGoogleError(ErrorMessage.None)
                  }
                  if (googleMapsObject) {
                    setGoogleMapsObject(undefined)
                  }
                }}
                icon={
                  googleMapsObject && (
                    <Icon
                      name="x"
                      className={styles.ClearIcon}
                      link
                      onClick={() => {
                        clearAddress()
                      }}
                    />
                  )
                }
              />
              {googleError && <div className={styles.errorText}>{googleError}</div>}
            </div>
            <SolInput
              size="fluid"
              className={classNames({
                [styles.errorLabel]: false,
              })}
              onChange={(_, v) => {
                setGoogleMapsObject({
                  ...googleMapsObject,
                  nickname: v.value,
                })
              }}
              maxLength={40}
              disabled={!googleMapsObject}
              placeholder="Building Name"
              label="Building Name"
              icon={
                <SolTooltipWithLink
                  trigger={
                    <SolQuestionCircle className={styles.questionIcon} />
                  }
                  text={
                    [
                      'Creating a Building Name will provide a user friendly label to this location.',
                      'The Building Name will also be visible to Solstice app users when they discover Pods nearby. \n',
                    ].join(' ')
                  }
                  buttonText="LEARN MORE"
                  position="top left"
                  isExternalLink
                  linkUrl="https://documentation.mersive.com/en/pod-locations.html"
                />
              }
              value={googleMapsObject?.nickname}
              error={(!googleMapsObject?.nickname || !nicknameIsValid) && !nickNameValidationLoading}
              errorText={!nicknameIsValid ? 'Building Name already exists. Please enter a new name.' : null}
            />
          </>
        }
        actionButtons={
          <SolCancelSave
            cancelClick={() => {
              // reset the form... ?
              onClose()
            }}
            acceptingClick={() => {
              onAccept(googleMapsObject as CreateBaseAddress)
            }}
            acceptingDisabled={
              !!googleError
              || !googleMapsObject?.building
              || !googleMapsObject?.nickname
              || nickNameValidationLoading
              || !nicknameIsValid
            }
          />
        }
        close={onClose}
      />
    </>
  )
}
