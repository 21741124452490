import React, { useState } from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  Categorizable,
  Column,
  DataTableInstance,
} from 'components/DataTableSlim/DataTableSlim'
import { User } from 'shared/types/User'
import { AppliedCategory, CategoryManagerPodsQueryResult } from 'graphql/__generated__/types'
import { CategoryDropdown } from './CategoryDropdown'
import { Category, Pod } from './../CategoryTypes'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { getTotalItemsSelected } from 'components/DataTableSlim/Utils'
import { download } from 'shared/core/csv-download'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'

const getColumns = (
  categories: Category[],
  totalSelectedItems: number,
  applyCategory: (categoryId: string, optionId?: string) => Promise<number>,
  currentUser: User,
) => {
  const columns: Column<Categorizable<Pod>>[] = [
    {
      name: 'name',
      displayName: 'Name',
      render: row => row.displayName,
      addable: false,
    },
    ...categories.map(category => ({
      name: `category_${category.id}`,
      displayName: category.name ?? '',
      sortable: false,
      render: (row: Pod, toCsv: boolean) => {
        const displayCategory: AppliedCategory | undefined
          = row.categories.find(c => c.categoryId === category.id)
        if (toCsv) {
          return displayCategory?.option.name ?? 'Not set'
        }
        return (
          <CategoryDropdown
            disabled={!currentUser?.permissions?.includes('update:categories')}
            key={category.id ?? ''}
            pod={row}
            category={category}
            options={category.options}
            value={displayCategory?.option ?? undefined}
            totalSelectedItems={totalSelectedItems}
            applyCategory={applyCategory}
          />
        )
      },
    })),
  ]
  return columns
}

interface Props {
  pods: Pod[]
  categories: Category[]
  totalPods: number
  loading: boolean
  fetchCsvData: () => Promise<CategoryManagerPodsQueryResult>
  applyCategory: (categoryId: string, optionId?: string) => Promise<number>
}

export const CategoriesTable = ({
  pods = [],
  categories = [],
  totalPods,
  loading,
  fetchCsvData,
  applyCategory,
}: Props) => {
  const { selectedItems, addedColumns } = useDataTable<Pod>(DataTableInstance.Categories)
  const totalItemsSelected = getTotalItemsSelected(selectedItems, totalPods)
  const columns = getColumns(categories, totalItemsSelected, applyCategory, useCurrentUser())
  const [loadingExport, setLoadingExport] = useState(false)
  const onExport = async () => {
    setLoadingExport(true)
    download(((await fetchCsvData()).data?.categoryManagerPods ?? []).map(row => {
      return {
        ...columns.filter(col => addedColumns.includes(col.name)).reduce((prev, curr) => {
          prev[curr.displayName as string] = curr.render?.(row, true)
          return prev
        }, {}),
      }
    }), 'Category Manager')
    setLoadingExport(false)
  }
  return (
    <>
      {ServerDataTable<Categorizable<Pod>>({
        id: DataTableInstance.Categories,
        idSelector: (row: Pod) => row.displayId,
        columns,
        data: pods,
        exportProcessing: loadingExport,
        loading,
        addableColumns: true,
        title: 'Category Manager',
        selectable: true,
        allowExportToCsv: true,
        scrollable: false,
        onExport,
        totalItems: totalPods,
        totalItemsSelectable: totalPods,
        totalItemsSelected,
        padded: false,
      })}
    </>
  )
}
