import React from 'react'
import styles from './SolStickyBanner.module.scss'
import classNames from 'classnames'

import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

export type ColorOptions = 'warning' | 'error' | 'grey' | 'green'

export interface Props {
  color: ColorOptions
  text?: string | JSX.Element
  children?: any
  pure?: boolean
}

export const SolStickyBanner = (props: Props) => {
  let color
  if (props.color === 'warning') {
    color = styles.warning
  }
  if (props.color === 'error') {
    color = styles.error
  }
  if (props.color === 'grey') {
    color = styles.grey
  }
  if (props.color === 'green') {
    color = styles.green
  }
  return (
    <div
      data-testid={`banner-${props.color}`}
      className={classNames(styles.stickyBanner, color)}
    >
      {
        !props.pure && <SolAlertIcon className={styles.exclamationTriangle} />
      }
      {props.text} {props.children}
    </div>
  )
}

export default SolStickyBanner
