import React from 'react'
import { SolButton, SolInput } from 'SolComponents'
import {
  RegexPattern,
  ValidationInput,
} from 'components/FormValidation/ValidationInput'
import styles from './TrialDrawer.module.scss'
import { useFormContext } from 'react-hook-form'
import { ContactInfo } from './TrialDrawer'

export const ContactForm = ({
  goNext,
  contactInfo,
  setContactInfo,
}: {
  goNext: () => void
  contactInfo: ContactInfo
  setContactInfo: (input: ContactInfo) => void
}) => {
  const { errors } = useFormContext()
  return (
    <div className={styles.formWrapper}>
      <ValidationInput
        name="first_name"
        rules={{ required: true }}
        label="First Name"
        value={contactInfo.firstName}
        onChange={(_, v) =>
          setContactInfo({ ...contactInfo, firstName: v.value })
        }
      />
      <ValidationInput
        name="last_name"
        rules={{ required: true }}
        label="Last Name"
        value={contactInfo.lastName}
        onChange={(_, v) =>
          setContactInfo({ ...contactInfo, lastName: v.value })
        }
      />
      <ValidationInput
        name="phone_number"
        rules={{ required: true, pattern: RegexPattern.PhoneNumber }}
        label="Phone Number"
        value={contactInfo.phoneNumber}
        onChange={(_, v) =>
          setContactInfo({ ...contactInfo, phoneNumber: v.value })
        }
      />
      <SolInput
        label="Job Title"
        placeholder="(optional)"
        value={contactInfo.jobTitle}
        onChange={(_, v) =>
          setContactInfo({ ...contactInfo, jobTitle: v.value })
        }
      />
      <ValidationInput
        name="email"
        rules={{
          required: true,
          pattern: RegexPattern.Email,
        }}
        label="Email"
        value={contactInfo.email}
        size="extraLarge"
        onChange={(_, v) => setContactInfo({ ...contactInfo, email: v.value })}
      />
      <ValidationInput
        name="email_2"
        placeholder="(optional)"
        rules={{
          pattern: RegexPattern.Email,
        }}
        label="Email #2"
        value={contactInfo.emailTwo}
        size="extraLarge"
        onChange={(_, v) =>
          setContactInfo({ ...contactInfo, emailTwo: v.value })
        }
      />
      <div className={styles.sendQuote}>
        <SolButton
          text="Next"
          color="blue"
          handleClick={goNext}
          disabled={Object.keys(errors).length > 0}
        />
      </div>
    </div>
  )
}
