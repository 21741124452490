import { NetworkStatus } from '@apollo/client'
import React from 'react'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'
import { usePollHistoricalTasks } from './../../hooks/useScheduledTasks'
import { HistoryTab } from './HistoryTab'

export interface Props {
    versionUtils: VersionUtils
  }

export const HistoryTabController = ({ versionUtils }: Props) => {
  const {
    data,
    networkStatus,
  } = usePollHistoricalTasks()

  const historicalTasks = (data === undefined) ? [] : data

  return (<HistoryTab
    tasks={historicalTasks}
    loading={networkStatus !== NetworkStatus.ready}
    versionUtils={versionUtils}
  />)
}

export default HistoryTabController
