import React from 'react'
import { Button } from 'semantic-ui-react'
import { SolModal } from 'SolComponents'
import styles from './InfoStyle.module.scss'

export interface Props {
  isOpen: boolean
  onClose: () => void
}

function InfoModal({ isOpen, onClose }: Props) {
  return (
    <SolModal isOpen={isOpen} onCloseModal={onClose} showCloseIcon={false}>
      <h3 className={styles.header}>
        If this status persists more than a few minutes,
        make sure the Pod has access to the Internet.
      </h3>
      <h3 className={styles.header}>
        If the Pod still appears offline, try the following:
      </h3>

      <ol className={styles.list}>
        <li>Delete the Pod from Solstice Cloud</li>
        <li><a href="https://mersive.my.site.com/support/s/article/Pod-Factory-Reset"
          target="_blank" rel="noreferrer">Factory reset</a> the Pod</li>
        <li>Onboard the Pod again to Solstice Cloud</li>
      </ol>
      
      <div className={styles.buttonContatiner}>
        <Button basic color="blue" size="large" onClick={onClose}>Close</Button>
      </div>
    </SolModal>
  )
}
  
export default InfoModal
