import React from 'react'
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon'

type Props = {
  className?: string
}

const SolCalendarIcon = ({ className }: Props) => (
  <CalendarBlankIcon className={className} />
)

export default SolCalendarIcon
