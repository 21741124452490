import { LocalStorageToken } from '../../auth/types'

import {
  CreateMxRenewLeadInterface,
  MxRenewServiceInterface,
  MxRenewLeadInterface,
  MxRenewLeadId,
} from '../types'

export class MxRenewServiceMock implements MxRenewServiceInterface {
  apiUrl: string
  rest: any
  mockLeads: MxRenewLeadInterface[]
  mockNextLeadId: number // MxRenewLeadId
  constructor(apiUrl: string = '', rest: any = null) {
    this.apiUrl = apiUrl
    this.rest = rest
    this.mockLeads = [
      {
        id: '100',
        email: 'foo@bar.com',
        step: 0,
        maxStep: 0,
      },
    ]
    this.mockNextLeadId = 101
  }

  createLead(lead: CreateMxRenewLeadInterface, _token: LocalStorageToken = '') {
    const id: MxRenewLeadId = '101'
    return Promise.resolve({ ...lead, id })
  }

  updateLead(
    id: MxRenewLeadId,
    update: Partial<MxRenewLeadInterface>,
    _token: LocalStorageToken = '',
  ) {
    const leadIndex = this.mockLeads.findIndex(mockLead => mockLead.id === id)
    if (leadIndex > -1) {
      this.mockLeads[leadIndex] = { ...this.mockLeads[leadIndex], ...update }
    }
    return Promise.resolve(this.mockLeads[leadIndex])
  }
}

export default new MxRenewServiceMock()
