import React, { useState } from 'react'
import {
  Categorizable,
  Column,
  DataTableInstance,
} from 'components/DataTableSlim/DataTableSlim'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import Label from './Label'
import DeleteModal from './DeleteModal'
import { format, parseISO } from 'date-fns'
import styles from './index.module.scss'
import { SolPodVersion, SolStatus } from 'SolComponents'
import { OutOfSubscriptionCell } from 'components/DataTableSlim/Custom/OutOfSubscriptionCell'
import SolDeleteIcon from 'SolComponents/Icons/SolDeleteIcon/SolDeleteIcon'
import {
  DeploymentsByDisplay,
  Maybe,
  Tag,
  DeploymentsQueryResult,
} from 'graphql/__generated__/types'
import { useGetSoftwareVersion } from 'shared/hooks/versions'
import { download } from 'shared/core/csv-download'
import { SolsticeElement } from '../../../SolComponents/SolStatus/SolStatus'
import { SolsticeElementCell } from '../../../components/DataTableSlim/Custom/SolsticeElementCell'
import { SolsticeElementEnum } from '../../../shared/enums'

/**
 * We want to enforce the required displayName because here it drives CSV headers
 * but in other datatables there is no CSV export, so displayName isn't required
 * for every instance of Column.
 */
interface ColumnWithDisplayName extends
  Column<Categorizable<DeploymentsByDisplay>> {
    displayName: string
  }

type DeploymentTableProps = {
  totalItems: number
  tableData: DeploymentsByDisplay[]
  loading: boolean
  userRole?: number
  onDeletePod: (podId: string) => void
  fetchCsvData: (  ) => Promise<DeploymentsQueryResult>
}

type Modal = {
  data?: { id: string; name: string }
  isOpen: boolean
}

/**
 * Each pod may have had any combination of up to four tags set
 * , each with a custom name and one color chosen out of four possible.
 * The user may choose the same color four times for a single pod.
 * So we sort by tag name to get a consistent display ordering.
 * @param index 1-4, the Tag column we're thinking about
 * @param tagList
 * @param toCsv
 * @returns
 */
const findTag = (
  index: number,
  tagList?: Maybe<Maybe<Tag>[]>,
  toCsv?: boolean,
) => {
  const sortedTags = tagList ? tagList.slice()?.sort( (a, b) => {
    return (a?.tag && b?.tag && a.tag > b.tag ) ? 1 : -1
  } ) : []
  const tag = sortedTags?.length > index ? sortedTags[index] : null
  const bgColor = (tag && tag.color) ? `#${tag?.color.replace('0x', '')}` : '0xff00ff'
  const label = toCsv ? tag?.tag : <Label bgColor={bgColor}>{tag?.tag}</Label>
  return tag ? label : '--'
}

const getSubscriptionRow = (row: Categorizable<DeploymentsByDisplay>) => {
  if (row.licenseShortName === SolsticeElementEnum.licenseShortName) {
    return 'N/A'
  }

  const dateFormat = 'yyyy/MM/dd'
  const flexeraEnd = row.flexeraMaintenanceEnd ? format( parseISO(row.flexeraMaintenanceEnd), dateFormat) : null
  const maintenanceEnd = row.maintenanceEnd ? format( new Date(parseInt(row.maintenanceEnd)), dateFormat) : null
  if (flexeraEnd) {
    return flexeraEnd
  }
  if (maintenanceEnd) {
    return maintenanceEnd
  }
  return '--'
}

const getStatus = ({
  isOnline: online,
  hasUnconfirmedTemplates: pending,
  isInSubscription: inMx,
  flexeraMaintenanceEnd: flexEnd,
  maintenanceEnd,
  status,
}: DeploymentsByDisplay) => {
  if (!flexEnd && !maintenanceEnd) {
    return 'Unknown'
  }
  if (!inMx) {
    return 'OutOfMx'
  }
  if (online) {
    return pending ? 'OnlinePending' : 'Online'
  }
  if (status === 'UNSUPPORTED_FIRMWARE') {
    return 'Unsupported'
  }
  return 'Offline'
}

const getStatusText = (status: ReturnType<typeof getStatus>) =>
  ({
    OnlinePending: 'Online',
    Online: 'Online',
    Offline: 'Offline',
    OutOfMx: 'EXPIRED',
    Unknown: 'Status Unknown',
    Unsupported: 'Unsupported Location Management Services',
  }[status])

export const getStatusDescriptions = (pod: DeploymentsByDisplay) => ({
  OnlinePending: `${
    pod.name
  } is online, and currently sending data to Solstice Cloud. Until finished, some data may be out of date.`,
  Online: `Pod is currently online. Pod settings are up to date.`,
  Offline: `Pod is currently offline. Last known settings shown. Changes may be pending.`,
  Unsupported: `Pod has an old version of Location Monitoring Services.`,
})

const DeploymentTable = ({
  totalItems,
  tableData: data,
  loading,
  userRole,
  fetchCsvData,
  onDeletePod }: DeploymentTableProps) => {
  const [modalState, setModal] = useState<Modal>({ isOpen: false })

  const handleIsOpen = (isOpen: boolean) => setModal(state => ({ ...state, isOpen }))
  const getSoftwareVersion = useGetSoftwareVersion()

  const columns: ColumnWithDisplayName[] = [
    {
      name: 'status',
      displayName: 'Status',
      centered: true,
      collapsing: true,
      addable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (row.licenseShortName === SolsticeElementEnum.licenseShortName) {
          return toCsv ? SolsticeElement : <SolsticeElementCell />
        }

        const icon
          = status === 'OutOfMx' ? (
            <OutOfSubscriptionCell />
          ) : (
            <SolStatus status={status} statusDescriptions={getStatusDescriptions(row)} />
          )
        return toCsv ? getStatusText(status) : icon
      },
    },
    {
      name: 'displayId',
      displayName: 'ID',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {return row.displayId}
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>{row.displayId}</span>
          : row.displayId)
      },
    },
    {
      name: 'name',
      displayName: 'Name',
      addable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {return row.name}
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>{row.name}</span>
          : row.name)
      },
    },
    {
      name: 'serialId',
      displayName: 'Serial number',
      addable: true,
      render: (row, toCsv) => {
        if (toCsv) {return row.serialId || 'Undefined'}

        return row.serialId || '--'
      },
    },
    {
      name: 'macPrimary',
      displayName: 'MAC (Ethernet)',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.macPrimary
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.macPrimary || '--')
      },
    },
    {
      name: 'macWifi',
      displayName: 'MAC (Wi-Fi)',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.macWifi
        }
        return (getStatus(row) === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.macWifi || '--')
      },
    },
    {
      name: 'ipPrimary',
      displayName: 'IP (Ethernet)',
      addable: true,
      render: (row, toCsv) => {
        // this logic is here because there isn't a row called ethernet in the database
        // the ethernet ip will always be 'primary' so if the wifi matches primary, we can assume that ethernet does not exist
        const ethernetIP = row.ipPrimary === row.ipWifi ? '--' : row.ipPrimary

        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : ethernetIP
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : ethernetIP || '--')
      },
    },
    {
      name: 'ipWifi',
      displayName: 'IP (Wi-Fi)',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.ipWifi
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.ipWifi || '--')
      },
    },
    {
      name: 'sdsEnabled',
      displayName: 'SDS',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.sdsEnabled
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : (row?.sdsEnabled ? 'Enabled' : 'Disabled'))
      },
    },
    {
      name: 'sdsHost1',
      displayName: 'SDS Host 1',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.sdsHost1
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.sdsHost1 || '--')
      },
    },
    {
      name: 'sdsHost2',
      displayName: 'SDS Host 2',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.sdsHost2
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.sdsHost2 || '--')
      },
    },
    {
      name: 'version',
      addable: true,
      displayName: 'Version',
      render: (row, toCsv) => {
        let v = row.version?.join('.')
        const status = getStatus(row)

        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : getSoftwareVersion(v ?? '1.0.0')
        }
        if (status === 'Unknown') {
          return <span style={{ color: 'grey' }}>--</span>
        }
        return (<SolPodVersion
          version={row.version?.join('.')}
          catchingUp={row.catchingUp}
        />)
      },
    },
    {
      name: 'hardware',
      displayName: 'Hardware',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.hardware
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row.hardware || '--')
      },
    },
    {
      name: 'license',
      displayName: 'License',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)

        const license = row?.licenseShortName && row?.licenseShortName === SolsticeElementEnum.licenseShortName
          ? 'Element'
          : row?.license

        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : license
        }

        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : license)
      },
    },
    {
      name: 'maintenanceEnd',
      displayName: 'Subscription',
      addable: true,
      defaultAdded: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : getSubscriptionRow(row)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : getSubscriptionRow(row))
      },
    },
    {
      name: 'tag1',
      displayName: 'Tag 1',
      addable: true,
      sortable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : findTag(0, row?.tags, toCsv)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : findTag(0, row?.tags, toCsv))
      },
    },
    {
      name: 'tag2',
      displayName: 'Tag 2',
      addable: true,
      sortable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : findTag(1, row?.tags, toCsv)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : findTag(1,  row.tags ?? [], toCsv))
      },
    },
    {
      name: 'tag3',
      displayName: 'Tag 3',
      addable: true,
      sortable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : findTag(2, row?.tags, toCsv)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : findTag(2, row?.tags, toCsv))
      },
    },
    {
      name: 'tag4',
      displayName: 'Tag 4',
      addable: true,
      sortable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : findTag(3, row?.tags, toCsv)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : findTag(3, row?.tags, toCsv))
      },
    },
    {
      name: 'tagCount',
      displayName: 'Tag Count',
      addable: true,
      sortable: false,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.tags?.length ?? '--'
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row?.tags?.length ?? '--')
      },
    },
    {
      name: 'onboardDate',
      displayName: 'Import Date',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx' || !row?.onboardDate
            ? 'RENEW' : format( new Date(parseInt(row?.onboardDate || '') ?? 0), 'yyyy/MM/dd')
        }
        return row?.onboardDate
          ? format( new Date(parseInt(row?.onboardDate ?? '') ?? 0), 'yyyy/MM/dd')
          : '--'
      },
    },
    {
      name: 'deployDate',
      displayName: 'Deploy Date',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx' || !row?.deployDate
            ? 'RENEW' : format( new Date( parseInt(row.deployDate ?? '') ?? 0), 'yyyy/MM/dd')
        }
        return row?.deployDate ? format( new Date( parseInt(row.deployDate ?? '') ?? 0), 'yyyy/MM/dd') : '--'
      },
    },
  ]

  if (userRole === 0) {
    columns.push(
      {
        name: 'delete',
        displayName: 'Delete',
        addable: false,
        sortable: false,
        render: row => {
          return (
            <SolDeleteIcon
              dataTestId="delete-icon"
              onClick={() => {
                setModal({
                  isOpen: true,
                  data: {
                    id: row.displayId ?? '',
                    name: row.name ?? '',
                  },
                })
              }}
            />
          )
        },
      },
    )
  }

  const [loadingExport, setLoadingExport] = useState(false)
  const onExport = async (combinedColumns: any, addedColumns: any) => {
    setLoadingExport(true)
    download(((await fetchCsvData()).data?.deployments?.pods ?? []).map((row: any) => {
      return columns.filter(c => {
        return ((c?.name as string) !== 'delete' && (
          addedColumns.indexOf(c?.name) > 0 || !c.addable
        ) )
      }).reduce( (obj, col) =>
        Object.assign(obj, { [col?.displayName as string]: col.render?.(row, true) as string })
      , {})
    }), 'Deployments')
    setLoadingExport(false)
  }

  return (
    <div data-testid="deployment-table" className={styles.table}>
      <DeleteModal data={modalState.data} isOpen={modalState.isOpen} setIsOpen={handleIsOpen} deletePod={onDeletePod} />
      {ServerDataTable<Categorizable<DeploymentsByDisplay>>({
        id: DataTableInstance.Deployment,
        columns: columns,
        data,
        title: 'Deployment',
        totalItems,
        exportProcessing: loadingExport,
        loading,
        addableColumns: true,
        allowExportToCsv: true,
        addCategoryCols: false,
        onExport: onExport,
        padded: false,
      })}
    </div>
  )
}

export default DeploymentTable
