import { differenceInCalendarMonths } from 'date-fns'
import { Dispatch } from 'react'
import getHistory from 'shared/core/history'
import { initMxRenew } from 'shared/store/slices/maintenance'

/*
 * INIT_MX_RENEW: wireup middleware, so we don't code it in 3 places.
 * 1. intercepts action dispatch when feature flag is disabled.
 * 2. when enabled, soft redirect to renew flow route after dispatching init action.
 */
export const dispatchInitMxRenew = (dispatch: Dispatch<any>) => () => {
  // get router history
  const history = getHistory()
  // get current route
  const fromRoute = history.location.pathname
  // dispatch the init action.
  dispatch(initMxRenew({ fromRoute }))
  // router part of redux store is effectively decoupled,
  // and thus out of scope of action reducers.
  // so we must do a soft redirect separately.
  history.push('/renew')
}

type Hardware = 'Pod Gen2i' | 'Pod Gen2' | 'Pod Gen3' | 'Pod Gen1' | '(unknown)'
type Edition = 'Enterprise' | 'Standard'
type License = 'Unlimited' | 'SGE'
type ProductName =
  | 'Gen3 UE'
  | 'Gen3 Standard Enterprise'
  | 'Gen2i UE'
  | 'Gen2i Standard Enterprise'
  | 'Gen2i Standard Unlimited'
  | 'Gen2i Standard'

/* eslint-disable max-len */
const prices = {
  'Gen3 UE': [23.30, 46.60, 69.90, 93.20, 116.50, 139.80, 163.10, 186.40, 209.70, 233.00, 256.30, 280.00, 298.70, 317.40, 336.10, 354.80, 373.50, 392.20, 410.90, 429.60, 448.30, 467.00, 485.70, 504.00, 521.50, 539.00, 556.50, 574.00, 591.50, 609.00, 626.50, 644.00, 661.50, 679.00, 696.50, 714.00, 729.20, 744.40, 759.60, 774.80, 790.00, 805.20, 820.40, 835.60, 850.80, 866.00, 881.20, 896.00, 910.80, 925.60, 940.40, 955.20, 970.00, 984.80, 999.60, 1014.40, 1029.20, 1044.00, 1058.80, 1073.60],
  'Gen3 Standard Enterprise': [20.00, 40.00, 60.00, 80.00, 100.00, 120.00, 140.00, 160.00, 180.00, 200.00, 220.00, 240.00, 256.00, 272.00, 288.00, 304.00, 320.00, 336.00, 352.00, 368.00, 384.00, 400.00, 416.00, 432.00, 447.00, 462.00, 477.00, 492.00, 507.00, 522.00, 537.00, 552.00, 567.00, 582.00, 597.00, 612.00, 625.00, 638.00, 651.00, 664.00, 677.00, 690.00, 703.00, 716.00, 729.00, 742.00, 755.00, 768.00, 781.00, 794.00, 807.00, 820.00, 833.00, 846.00, 859.00, 872.00, 885.00, 898.00, 911.00, 924.00],
  'Gen2i UE': [21.70, 43.40, 65.10, 86.80, 108.50, 130.20, 151.90, 173.60, 195.30, 217.00, 238.70, 260.00, 277.30, 294.60, 311.90, 329.20, 346.50, 363.80, 381.10, 398.40, 415.70, 433.00, 450.30, 468.00, 484.30, 500.60, 516.90, 533.20, 549.50, 565.80, 582.10, 598.40, 614.70, 631.00, 647.30, 663.00, 677.10, 691.20, 705.30, 719.40, 733.50, 747.60, 761.70, 775.80, 789.90, 804.00, 818.10, 832.00, 845.90, 859.80, 873.70, 887.60, 901.50, 915.40, 929.30, 943.20, 957.10, 971.00, 984.90, 998.80],
  'Gen2i Standard Enterprise': [18.30, 36.60, 54.90, 73.20, 91.50, 109.80, 128.10, 146.40, 164.70, 183.00, 201.30, 220.00, 234.70, 249.40, 264.10, 278.80, 293.50, 308.20, 322.90, 337.60, 352.30, 367.00, 381.70, 396.00, 409.80, 423.60, 437.40, 451.20, 465.00, 478.80, 492.60, 506.40, 520.20, 534.00, 547.80, 561.00, 572.90, 584.80, 596.70, 608.60, 620.50, 632.40, 644.30, 656.20, 668.10, 680.00, 691.90, 704.00, 716.10, 728.20, 740.30, 752.40, 764.50, 776.60, 788.70, 800.80, 812.90, 825.00, 837.10, 849.20],
  'Gen2i Standard Unlimited': [16.70, 33.40, 50.10, 66.80, 83.50, 100.20, 116.90, 133.60, 150.30, 167.00, 183.70, 200.00, 213.30, 226.60, 239.90, 253.20, 266.50, 279.80, 293.10, 306.40, 319.70, 333.00, 346.30, 360.00, 372.50, 385.00, 397.50, 410.00, 422.50, 435.00, 447.50, 460.00, 472.50, 485.00, 497.50, 510.00, 520.80, 531.60, 542.40, 553.20, 564.00, 574.80, 585.60, 596.40, 607.20, 618.00, 628.80, 640.00, 651.20, 662.40, 673.60, 684.80, 696.00, 707.20, 718.40, 729.60, 740.80, 752.00, 763.20, 774.40],
  'Gen2i Standard': [13.30, 26.60, 39.90, 53.20, 66.50, 79.80, 93.10, 106.40, 119.70, 133.00, 146.30, 160.00, 170.70, 181.40, 192.10, 202.80, 213.50, 224.20, 234.90, 245.60, 256.30, 267.00, 277.70, 288.00, 298.00, 308.00, 318.00, 328.00, 338.00, 348.00, 358.00, 368.00, 378.00, 388.00, 398.00, 408.00, 416.70, 425.40, 434.10, 442.80, 451.50, 460.20, 468.90, 477.60, 486.30, 495.00, 503.70, 512.00, 520.30, 528.60, 536.90, 545.20, 553.50, 561.80, 570.10, 578.40, 586.70, 595.00, 603.30, 611.60],
}
/* eslint-enable max-len */

export const getProductName = (
  hardware: Hardware,
  edition: Edition,
  license: License,
): ProductName => {
  if (hardware === 'Pod Gen3') {
    return license === 'Unlimited' ? 'Gen3 UE' : 'Gen3 Standard Enterprise'
  } else if (edition === 'Standard') {
    return license === 'Unlimited'
      ? 'Gen2i Standard Unlimited'
      : 'Gen2i Standard'
  }
  return license === 'Unlimited' ? 'Gen2i UE' : 'Gen2i Standard Enterprise'
  
}

export const generatePricePerPod = (
  oldExpirationDate: Date,
  newExpirationDate: Date,
  hardWare: Hardware,
  edition: Edition,
  _license: License, // currently using default 'Unlimited' for all
) => {
  const oldDate = new Date(oldExpirationDate)
  const newDate = new Date(newExpirationDate)
  const diffInMonths = differenceInCalendarMonths(newDate, oldDate)
  const productName = getProductName(hardWare, edition, 'Unlimited')

  const [maxPrice] = prices[productName].slice(-1)
  return prices[productName][diffInMonths - 1] ?? maxPrice
}
