import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import DropZone from 'react-dropzone'
import styles from './SolFileUpload.module.scss'

interface Props {
  file: File
  variant?: string
  label: string
  onUpload: ({ fileName, fileContents }: { fileName: string; fileContents: string }) => void
  onFailure: (reason: string) => void
  fileTypes: string
}

interface File {
  name: string
  contents: string
}

function SolFileUpload({ file: currentFile, label, fileTypes, onUpload, variant, onFailure }: Props) {
  return (
    <DropZone
      onDrop={async files => {
        const file = files[0]
        if (!fileTypes.toLowerCase().includes(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase())) {
          return onFailure('Invalid file type')
        }

        const fileReader = new FileReader()
        fileReader.onload = function(readFile: any) {
          const f = {
            name: file.name,
            contents: btoa(readFile.currentTarget.result),
          }
          onUpload({
            fileName: f.name,
            fileContents: f.contents,
          })
        }
        fileReader.readAsBinaryString(file)
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <React.Fragment>
          <Button {...getRootProps()} basic color="blue" className={styles.upload}>
            <input {...getInputProps()} accept={fileTypes} multiple={false} />
            <Icon name="cloud upload" color="blue" />
            {variant === 'security' && 'Upload '}
            {variant !== 'security' && (currentFile.name.length > 0 || currentFile.contents.length > 0) && 'Replace '}
            {variant !== 'security'
              && (!currentFile || currentFile.name.length === 0 || currentFile.contents.length === 0)
              && 'Upload '}
            {label}
          </Button>
          {currentFile && currentFile.name}
        </React.Fragment>
      )}
    </DropZone>
  )
}

export default SolFileUpload
