import React from 'react'
import Dialog from 'react-modal'
import styles from './SolDrawer.module.scss'
import classNames from 'classnames'
import SolCloseIcon from 'SolComponents/Icons/SolCloseIcon/SolCloseIcon'

export interface Props {
  isOpen: boolean
  children: React.ReactNode
  onCloseDrawer: () => void
  className?: string
}

export function SolDrawer({
  isOpen,
  onCloseDrawer,
  children,
  className,
}: Props) {
  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onCloseDrawer}
      className="drawer"
      overlayClassName={styles.drawerOverlay}
    >
      <div
        className={classNames(styles.drawer, className)}
        data-testid="sol-drawer"
      >
        <SolCloseIcon className={styles.closeIcon} onClick={onCloseDrawer} />
        {children}
      </div>
    </Dialog>
  )
}

Dialog.setAppElement('body')

export default SolDrawer
