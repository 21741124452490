import React from 'react'
import { useQuery } from '@apollo/client'
import { Template, TimeLocaleConfiguration } from 'graphql/__generated__/types'
import { SolCheckbox, SolDropdown, SolNotification, SolTooltip } from 'SolComponents'
import { ValidationInput, RegexPattern } from 'components/FormValidation/ValidationInput'
import {
  LanguagesDocument,
  LanguagesQuery,
  TimeZonesDocument,
  TimeZonesQuery,
} from '../../../graphql/__generated__/types'
import styles from './TimeLocale.module.scss'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: TimeLocaleConfiguration) => void
}

function TimeLocale({ template, updateConfiguration }: Props) {
  const configuration = template.revision.configuration as TimeLocaleConfiguration

  const { data: timeZonesData, loading: loadingTimeZones } = useQuery<TimeZonesQuery>(TimeZonesDocument)
  const timeZones = timeZonesData?.timeZones ?? []
  const { data: languagesData, loading: loadingLanguages } = useQuery<LanguagesQuery>(LanguagesDocument)
  const languages = languagesData?.languages ?? []

  return (
    <div className={styles.templateWrapper}>
      <SolCheckbox
        label="Set date/time automatically"
        checked={!!configuration.enabled}
        onClick={enabled => updateConfiguration({ ...configuration, enabled })}
      />
      <ValidationInput
        name="ntp_server"
        label="NTP Server"
        rules={{
          required: !!configuration.enabled,
          pattern: RegexPattern.IpAddressOrUrl,
        }}
        value={configuration.hostname ?? ''}
        disabled={!configuration.enabled}
        onChange={(_, v) =>
          updateConfiguration({
            ...configuration,
            hostname: v.value,
          })
        }
      />
      <SolCheckbox
        label="24 hour format"
        checked={!!configuration.show24Hours}
        onClick={show24Hours => updateConfiguration({ ...configuration, show24Hours })}
      />
      <SolDropdown
        type="caret"
        direction="left"
        options={timeZones}
        label="Timezone"
        value={configuration.timezone}
        onChange={timezone => updateConfiguration({ ...configuration, timezone })}
        size="medium"
        loading={loadingTimeZones}
        sorted
      />
      <SolDropdown
        type="caret"
        direction="left"
        options={languages}
        label="Language"
        value={configuration.language}
        onChange={language => updateConfiguration({ ...configuration, language })}
        size="medium"
        loading={loadingLanguages}
        sorted
        // If Hungarian show v6+ or if Welsh show v5+, 
        // for any other language, don't show the blue pill      
        notification={(configuration.language === 'hu_HU'
          ? [<SolTooltip
            text="Solstice 6 or later is required to use the selected language"
            hoverable
            position="top left"
            isInline
            key={0}
            trigger={<SolNotification key={0} type="menu" notifications="v6.0+" />}
          />]
          : (configuration.language === 'cy_GB'
            ? [<SolTooltip
              text="Solstice 5 or later is required to use the selected language"
              hoverable
              position="top left"
              isInline
              key={1}
              trigger={<SolNotification key={1} type="menu" notifications="v5.0+" />}
            />] : []))}
      />
    </div>
  )
}

export default TimeLocale
