import {
  DisplayConfiguration,
  SettingsGroupType,
  Template,
} from 'graphql/__generated__/types'

const configuration: DisplayConfiguration['MESSAGE_CENTER'] = {
  rssFeeds: [
    {
      title: 'Solstice Wireless Display',
      url: 'https://mersive.com/go.xml',
      duration: 3, // 3 min
      enabled: false,
    },
  ],
  customMessage: {
    text: '',
    enabled: false,
  },
  emergencyMessage: {
    text: '',
    enabled: false,
  },
}

import { DefaultTemplate } from '../TemplateTypes'

export const defaultMessageCenterTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'MessageCenter',
    type: SettingsGroupType.MessageCenter,
    hidden: false,
  },
  revision: {
    id: '',
    configuration,
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
