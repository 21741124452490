import L, { MarkerCluster, MarkerClusterGroup } from 'leaflet'
import { abbreviateNumber } from 'shared/core/utils'

export const getMarkerSize = (childCount: number) => {
  var c = 'kepler-marker-cluster-'
  if (childCount < 10) {
    c += 'small'
  } else if (childCount < 50) {
    c += 'medium'
  } else if (childCount < 99) {
    c += 'large'
  } else {
    c += 'x-large'
  }
  return c
}

export const PodsClusterGroup: MarkerClusterGroup = L.markerClusterGroup({
  chunkedLoading: true,
  removeOutsideVisibleBounds: true,
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: false,
  disableClusteringAtZoom: 18,
  iconCreateFunction: function(cluster: MarkerCluster) {
    return new L.DivIcon({
      html: '<div>' + cluster.getChildCount().toString() + '</div>',
      className: 'marker-cluster-pods  ' + getMarkerSize(cluster.getChildCount()),
    })
  },
})

export const MostCollaborativePodsClusterGroup: MarkerClusterGroup = L.markerClusterGroup({
  chunkedLoading: true,
  removeOutsideVisibleBounds: true,
  spiderfyOnMaxZoom: true,
  showCoverageOnHover: false,
  disableClusteringAtZoom: 18,
  iconCreateFunction: function(cluster: MarkerCluster) {
    return new L.DivIcon({
      html: '<div> <span></span></div>',
      className: 'marker-purple-cluster-pods marker-cluster ' + getMarkerSize(cluster.getChildCount()),
    })
  },
})

export const BuildingClusterGroup: MarkerClusterGroup = L.markerClusterGroup({
  chunkedLoading: true,
  removeOutsideVisibleBounds: true,
  spiderfyOnMaxZoom: true,
  showCoverageOnHover: false,
  disableClusteringAtZoom: 11,
  iconCreateFunction: function(cluster: MarkerCluster) {
    return new L.DivIcon({
      html: '<div class=building> <span>' + cluster.getChildCount().toString() + ' </span></div>',
      className: ' marker-cluster-buildings ' + getMarkerSize(cluster.getChildCount()),
    })
  },
})

export const AlertClusterGroup: MarkerClusterGroup = L.markerClusterGroup({
  chunkedLoading: true,
  removeOutsideVisibleBounds: true,
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: false,
  disableClusteringAtZoom: 18,
  iconCreateFunction: function(cluster: MarkerCluster) {
    return new L.DivIcon({
      html:
        '<div class=\'alerts-'
        + getMarkerSize(cluster.getChildCount())
        + '\'> <span>'
        + abbreviateNumber(cluster.getChildCount())
        + ' </span></div>',
      className: 'marker-cluster-alerts ',
    })
  },
})

export const HeatCluster = L.heatLayer([], {
  radius: 15,
  maxZoom: 3,
  minOpacity: 0.2,
  blur: 5,
  gradient: {
    0.0: '#006C98',
    0.1: '#006C98',
    0.2: '#0285BA',
    0.3: '#4D9B32',
    0.4: '#66B84A',
    0.5: '#FFE973',
    0.6: '#FBC02D',
    0.7: '#F8991F',
    0.9: '#F8601F',
    0.8: '#DC1A00',
    1.0: '#B00020',
  },
})
