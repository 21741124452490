import { Icon, Accordion } from 'semantic-ui-react'
import styles from './SideFilterMenu.module.scss'
import { FilterCategory } from './SideFilterMenu'
import { SideFilterMenuDropdownItem } from './SideFilterMenuDropdownItem'
import SolLinkToggle, {
  SolLinkToggleOptions,
} from 'SolComponents/SolLinkToggle/SolLinkToggle'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import React, { useEffect, useRef, useState } from 'react'
import SolTooltip from 'SolComponents/SolTooltip/SolTooltip'

export interface Props {
  key: number | string
  category: FilterCategory
  handleMenuClick: () => void
  totalDisplayCount: number
  active: boolean
  tableId: string
}

export type CategorySort = {
  [categoryId: string]: 'ABC' | 'count'
}

export const SideFilterDropdown = ({
  category,
  handleMenuClick,
  totalDisplayCount,
  active,
  tableId,
}: Props) => {
  const categoryId = category.categoryInternalName ?? ''

  // There are a lot of fancy react hooks below.
  // This is because refs do not cause component refreshes.
  // We need to load the component, see if the name is overflowing via ref
  // if it is overflowing set the state so that the component will refresh
  // Personally, it feels complicated but it is the best solution I have found
  
  const nameRef = useRef<null | HTMLDivElement>(null)

  const [showTooltip, setShowTooltip] = useState(
    (nameRef?.current?.scrollWidth || 0) <= (nameRef?.current?.clientWidth || 0),
  )

  useEffect(() => {
    setShowTooltip((nameRef?.current?.scrollWidth || 0) <= (nameRef?.current?.clientWidth || 0))
  }, [nameRef?.current?.scrollWidth])

  const options: [SolLinkToggleOptions, SolLinkToggleOptions] = [
    { value: 'ABC', text: 'Alphabetical' },
    { value: 'count', text: 'Pod count' },
  ]

  const { categorySorts = {}, setCategorySorts } = useDataTable<CategorySort>(tableId)
  const sortBy = categorySorts[categoryId] ?? 'count'

  return (
    <>
      <Accordion.Title
        key={categoryId}
        onClick={handleMenuClick}
        content={
          <div className={styles.title}>
            <SolTooltip
              text={category.categoryDisplayName}
              disabled={showTooltip}
              trigger={
                <span ref={nameRef} className={styles.titleText}>{category.categoryDisplayName}</span>
              }
            />
            <span className={styles.icon}>
              {active ? (
                <Icon name="chevron up" className={styles.filterMenuIcon} />
              ) : (
                <Icon name="chevron down" className={styles.filterMenuIcon} />
              )}
            </span>
          </div>
        }
      />
      <Accordion.Content
        active={active}
        className={styles.content}
      >
        {active && (
          <div>
            <div className={styles.linkToggle}>
              <SolLinkToggle
                options={options}
                label="Sort by:"
                defaultValue={sortBy ?? 'count'}
                onChange={val => {
                  setCategorySorts({ ...categorySorts, [categoryId]: val })
                }}
              />
            </div>
            <div className={styles.optionGrid}>
              {category.options.sort((a, b): number => {
                const fieldA = sortBy === 'count' ? a.displayCount : a.optionDisplayName
                const fieldB = sortBy === 'count' ? b.displayCount : b.optionDisplayName
                if (fieldA > fieldB) {
                  return 1
                }
                return -1
              }).map((option, index) => (<SideFilterMenuDropdownItem
                key={index}
                option={option}
                tableId={tableId}
              />))}
              <SideFilterMenuDropdownItem
                key={category.options.length}
                option={{
                  optionDisplayName: 'Unassigned',
                  displayCount: totalDisplayCount - category.options.reduce((a, c) => a + c.displayCount, 0),
                  categoryDisplayName: category.categoryDisplayName,
                  categoryInternalName: category.categoryInternalName,
                  optionInternalName: 'unassigned',
                }}
                tableId={tableId}
                unassigned
              />
            </div>
          </div>
        )}
      </Accordion.Content>
      <hr className={styles.line} />
    </>
  )
}

export default SideFilterDropdown
