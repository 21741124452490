import {
  useAnalyticCountsQuery,
  useActiveMeetingsQuery,
  PlatformConfigQuery,
  PlatformConfigDocument,
} from 'graphql/__generated__/types'
import { DateRange } from '.'
import { useQuery } from '@apollo/client'

export const pollInterval = 10000

interface UseTopRowProps {
  dateRange: DateRange
}

export const useTopRow = ({ dateRange }: UseTopRowProps) => {
  const { fromDate, toDate } = dateRange
  const { data: activeMeetingsData } = useActiveMeetingsQuery({ pollInterval: 20000 })
  const { data } = useAnalyticCountsQuery({ variables: { options: { fromDate, toDate } } })

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument)
    .data
    ?.platformConfig
    ?.hasElementPods

  const offsetInHours = new Date().getTimezoneOffset() / 60
  const uniqueUsers = data?.analyticCounts?.uniqueUsers ?? 0
  const busiestHour = data?.analyticCounts?.busiestHour
    ? new Date(
      new Date().setHours( data?.analyticCounts?.busiestHour - offsetInHours ),
    ).getHours()
    : undefined
  const medianMeeting = data?.analyticCounts?.medianMeeting ?? 0
  const activeMeetings = activeMeetingsData?.activeMeetings?.count ?? 0

  return {
    uniqueUsers,
    busiestHour,
    medianMeeting,
    activeMeetings,
    hasElementPods,
  }
}

export default useTopRow
