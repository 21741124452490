import { FilterType, Filter } from '../types'
import {
  useInsertRebootedAlertFilterMutation,
  useInsertUnreachableAlertFilterMutation,
  useInsertTemplateAlertFilterMutation,
  useInsertUsbPlugAlertFilterMutation,
  AlertConfigDocument,
  AlertConfigQuery,
} from 'graphql/__generated__/hasura-types'
import { DataProxy } from '@apollo/client'

export const useInsertAlertFilter = () => {
  const [insertRebootedAlertFilter] = useInsertRebootedAlertFilterMutation()
  const [insertUnreachableAlertFilter] = useInsertUnreachableAlertFilterMutation()
  const [insertTemplateAlertFilter] = useInsertTemplateAlertFilterMutation()
  const [insertUsbPlugAlertFilter] = useInsertUsbPlugAlertFilterMutation()

  return (filterType: FilterType, filter: Partial<Filter>) => {
    const mutation = {
      [FilterType.DisplayUnreachable]: insertUnreachableAlertFilter,
      [FilterType.DisplayRebooted]: insertRebootedAlertFilter,
      [FilterType.DisplayOutOfTemplate]: insertTemplateAlertFilter,
      [FilterType.UsbDevice]: insertUsbPlugAlertFilter,
    }[filterType]

    const mutFilter = {
      id: `${filter.categoryInternalName}-${filter.optionInternalName}`,
      categoryInternalName: filter.categoryInternalName ?? '',
      optionInternalName: filter.optionInternalName ?? '',
    }

    mutation({
      variables: mutFilter,
      update: (proxy: DataProxy) => {
        const cacheProp = {
          [FilterType.DisplayUnreachable]: 'unreachableFilters',
          [FilterType.DisplayRebooted]: 'rebootedFilters',
          [FilterType.DisplayOutOfTemplate]: 'templateFilters',
          [FilterType.UsbDevice]: 'usbFilters',
        }[filterType]

        const cacheData = proxy.readQuery({
          query: AlertConfigDocument,
        }) as AlertConfigQuery
        const newVal = [...cacheData[cacheProp], mutFilter]
        proxy.writeQuery({
          query: AlertConfigDocument,
          data: {
            ...cacheData,
            [cacheProp]: newVal,
          },
        })
      },
    })
  }
}
