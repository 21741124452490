import React from 'react'
import { NetworkStatus, useMutation } from '@apollo/client'
import UpdatesPage from './UpdatesPage'
import { usePodsPendingTask } from './hooks/usePodsPendingTask'
import { usePollCurrentTasks } from './hooks/useScheduledTasks'
import { useVersionUtils } from 'shared/hooks/versions'
import {
  useUpdateablePodsQuery,
  useUpdateablePodsLazyQuery,
  UpdateScheduledTaskMutation,
  UpdateScheduledTaskMutationVariables,
  UpdateScheduledTaskDocument,
  UpdateScheduledTaskInput,
  CurrentScheduledTasksDocument,
} from 'graphql/__generated__/types'
import { useUpdateablePodsOptions } from './hooks/useUpdatablePodsOptions'

const UpdatesController = () => {
  const queryOptions = useUpdateablePodsOptions()

  const { data, loading, refetch: refetchPods } = useUpdateablePodsQuery({
    variables: {
      options: {
        ...queryOptions,
        isIncludedElementPods: true,
      },
    },
  })

  const [fetchCsvData] = useUpdateablePodsLazyQuery({
    variables: {
      options: {
        ...queryOptions,
        offset: 0,
        limit: null,
        isIncludedElementPods: true,
      },
    },
  })

  const {
    data: taskData,
    networkStatus: tasksNetworkStatus,
    refetch: refetchCurrentTasks,
  } = usePollCurrentTasks(refetchPods) // This callback uses an onCompleted function in an Apollo useQuery hook, and is only executed if the query result changes

  const versionUtils = useVersionUtils()

  const currentTasks = (taskData === undefined) ? [] : taskData

  const refetchPodsAndTasks = () => {
    refetchCurrentTasks()
    refetchPods()
  }

  const updateScheduledTask = (() => {
    const [update] = useMutation<UpdateScheduledTaskMutation, UpdateScheduledTaskMutationVariables>(
      UpdateScheduledTaskDocument,
    )
    return (options: UpdateScheduledTaskInput) =>
      update({
        variables: { options },
        refetchQueries: [
          { query: CurrentScheduledTasksDocument },
        ],
      })
  })()

  const { addPodsPendingTask } = usePodsPendingTask()

  return (<UpdatesPage
    onTaskCreated={(displayIds: string[]) => {
      addPodsPendingTask(displayIds)
      refetchPodsAndTasks()
    }}
    onTaskDeleted={refetchPodsAndTasks}
    loading={loading || tasksNetworkStatus !== NetworkStatus.ready}
    currentTasks={currentTasks}
    updateablePods={data}
    versionUtils={versionUtils}
    fetchCsvData={fetchCsvData}
    updateScheduledTask={updateScheduledTask}
  />)
}

export default UpdatesController
