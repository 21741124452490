import React from 'react'
import Joyride from 'react-joyride'
import { useJoyride, styles, Ride } from 'shared/hooks/useJoyride'

export const DownloadButtonRide = () => {
  const { show, finishRide } = useJoyride(Ride.SolsticeDeployDownload)
  return (
    <Joyride
      styles={styles}
      callback={event => {
        if (event.action === 'close' || event.type === 'tour:end') {
          finishRide()
        }
      }}
      run={show}
      continuous
      locale={{
        last: 'Got it',
      }}
      steps={[
        {
          disableBeacon: true,
          target: '#solstice-deploy-download',
          content:
            'Download Solstice Deploy to enroll Pods into Solstice Cloud from Windows or macOS.',
        },
      ]}
      disableScrollParentFix
      disableOverlayClose
    />
  )
}
