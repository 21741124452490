import React from 'react'
import classNames from 'classnames'
import styles from './SolGrid.module.scss'

export type columnNum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export interface Props {
  sm?: columnNum
  md?: columnNum
  lg?: columnNum
  xl?: columnNum
  offset?: columnNum
  className?: string
  children?: any
}

export default function SolColumn({
  sm,
  md,
  lg,
  xl,
  // offset,
  children,
  className,
}: Props) {
  let smClass = '',
    mdClass = '',
    lgClass = '',
    xlClass = ''

  if (sm) {
    smClass = `col-sm-${sm}`
  }
  if (md) {
    mdClass = `col-md-${md}`
  }
  if (lg) {
    lgClass = `col-lg-${lg}`
  }
  if (xl) {
    xlClass = `col-xl-${xl}`
  }

  const classes = classNames(
    className,
    { [styles[smClass]]: sm },
    { [styles[mdClass]]: md },
    { [styles[lgClass]]: lg },
    { [styles[xlClass]]: xl },
    { [styles['col-sm']]: !xl && !lg && !md && !sm },
  )
  return <div className={classes}>{children}</div>
}
