import React from 'react'
import { Grid } from 'semantic-ui-react'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'

import { StepHeader } from 'components/Maintenance/Renewal/Partials'
import InformationForm from 'components/Maintenance/Renewal/Steps/InformationStep/InformationForm/InformationForm'
import { MxRenewLeadDisplayInterface } from 'shared/services/mxRenew/types'

export interface Props {
  cancelFlow: () => void
  rewindFlow: () => void
  updateLead: (id: MxRenewLeadId, update: Partial<MxRenewLeadInterface>, nextStep: number) => void
  nextStep: number
  selectedPods: { [field: string]: MxRenewLeadDisplayInterface }
  currentLead: MxRenewLeadInterface
  userEmail: string
}

export const style = {
  wrapper: {
    // width: '100%',
  } as React.CSSProperties,
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
class InformationStep extends React.Component<Props> {
  render() {
    const { rewindFlow, updateLead, nextStep, cancelFlow, currentLead, selectedPods, userEmail } = this.props

    const formatDate = format(
      zonedTimeToUtc(new Date(currentLead.newPodExpiration || 0), localTimezone),
      'MMMM do, yyyy',
    )
    return (
      <div style={style.wrapper}>
        <StepHeader
          rewind={rewindFlow}
          title={`You've selected ${Object.keys(selectedPods).length}
          pods for renewal on ${formatDate}`}
          subHeader={`
            Please fill out the form below so we may contact you with your quote
          `}
        />
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={2}>
              <div>&nbsp;</div>
            </Grid.Column>
            <Grid.Column width={14}>
              <InformationForm
                selectedPods={selectedPods}
                currentLead={currentLead}
                email={userEmail}
                nextStep={nextStep}
                updateLead={updateLead}
                cancelFlow={cancelFlow}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default InformationStep
