import React from 'react'
import { Button } from 'semantic-ui-react'
import { WithHelp } from '../WithHelp/WithHelp'

export interface Props {
  addCustom: () => void
  addRecommended: () => void
}

export const RecommendedOrCustom = ({ addCustom, addRecommended }: Props) => {
  const style = {
    parent: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
    } as React.CSSProperties,
  }

  const helpText = `Categories provide the ability to aggregate Solstice instances
    in ways that are meaningful to you. Once created, they can be used to filter, sort,
    and compare usage data. Choose from a list of recommended categories or create your own from scratch.`

  return (
    <WithHelp
      helpText={helpText}
      component={() => (
        <div style={style.parent}>
          <div>
            <Button basic color="blue" content="Choose from recommended" onClick={addRecommended} />
            <Button basic color="blue" content="Create custom" onClick={addCustom} />
          </div>
        </div>
      )}
    />
  )
}
