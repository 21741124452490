import React from 'react'
import classNames from 'classnames'
import CloseIcon from 'mdi-react/CloseIcon'
import styles from './SolCloseIcon.module.scss'

const SolCloseIcon = (props: {
  isLink?: boolean
  className?: string
  onClick: () => void
}) => (
  <CloseIcon
    className={classNames(styles.closeIcon, props.className, {
      [styles.isLink]: props.isLink !== false,
    })}
    onClick={props.onClick}
  />
)

export default SolCloseIcon
