import React, { useState } from 'react'
import { Label, Menu, Icon } from 'semantic-ui-react'
import { Category } from '../../CategoryTypes'
import { InlineEditTextbox } from '../shared/InlineEditTexbox/InlineEditTextbox'
import { CategoryPieData } from '../CategoryPieData.interface'
import styles from './CategoryChartLegendEdit.module.scss'

export interface Props {
  category: Category
  data: CategoryPieData
  updateOption: (optionId: string, optionName: string) => void
  deleteOption: (optionId: string) => void
  createOption: (optionName: string) => void
}
export const CategoryChartLegendEdit = ({
  category,
  data,
  updateOption,
  deleteOption,
  createOption,
}: Props) => {
  const [addingOption, setAddingOption] = useState<boolean>(false)
  const [renamedOptionId, setRenamedOptionId] = useState<string | null>(null)

  const INPUT_MAX_LENGTH = 25

  const checkInvalidOptionName = (existingDisplayName: string, newDisplayName: string) => {
    const isDuplicate = category.options.map(option => option.name).includes(newDisplayName)
    return (
      newDisplayName.trim() === ''
      || (existingDisplayName !== newDisplayName && isDuplicate)
    )
  }

  const canDeleteOptions = category.options.length > 2

  return (
    <div className={styles.legend}>
      <Menu size="tiny" className={styles.menu} vertical>
        {addingOption ? (
          <Menu.Item className={styles.item}>
            <InlineEditTextbox
              displayName={category.name ?? ''}
              value=""
              autoFocus
              maxLength={INPUT_MAX_LENGTH}
              disabled={(value: string) => checkInvalidOptionName('', value)}
              onCancelEdit={() => setAddingOption(false)}
              onCommitValue={createOption}
            />
          </Menu.Item>
        ) : (
          <Menu.Item
            content={<span className={styles.addNew}>Add new {category.name?.toLowerCase() ?? ''}</span>}
            icon="add"
            onClick={() => setAddingOption(true)}
          />
        )}
        {data.data.map(option =>
          (renamedOptionId === option.id ? (
            <Menu.Item key={option.id} className={styles.item}>
              <InlineEditTextbox
                value={option.name}
                displayName={category.name ?? ''}
                maxLength={INPUT_MAX_LENGTH}
                disabled={(value: string) => checkInvalidOptionName(option.name, value)}
                autoFocus
                onCancelEdit={() => setRenamedOptionId(null)}
                onCommitValue={(value: string) => {
                  setRenamedOptionId(null)
                  updateOption(option.id, value)
                }}
              />
            </Menu.Item>
          ) : (
            <Menu.Item
              key={option.id}
              content={
                <React.Fragment>
                  <div className={styles.itemMenuContent}>
                    <span className={styles.optionName}>{option.name}</span>
                    {canDeleteOptions && option.id !== 'unassigned' && (
                      <Icon
                        className={styles.trashCan}
                        name="trash alternate"
                        onClick={() => deleteOption(option.id ?? '')}
                      />
                    )}
                    <div className={styles.itemMenuLabel}>
                      <Label color={option.value === 0 ? 'grey' : 'blue'}>{option.value}</Label>
                    </div>
                  </div>
                </React.Fragment>
              }
              onClick={() => {
                if (option.id !== 'unassigned') {
                  setRenamedOptionId(option.id)
                }
              }}
            />
          )),
        )}
      </Menu>
    </div>
  )

}
