import root from 'window-or-global'

import axios, { AxiosResponse } from 'axios'

import { getMxRenewURL } from 'shared/core/config'

import { LocalStorageToken } from 'shared/services/auth/types'

import {
  CreateMxRenewLeadInterface,
  MxRenewServiceInterface,
  MxRenewRestInterface,
  MxRenewLeadInterface,
  MxRenewLeadId,
} from 'shared/services/mxRenew/types'

import { getToken } from 'shared/services/auth/helpers'

const MX_RENEW_URL = getMxRenewURL(root.location.hostname, root.location.port)

const defaultRest: MxRenewRestInterface = {
  post: axios.post,
  patch: axios.patch,
}

export class MxRenewService implements MxRenewServiceInterface {
  apiUrl: string
  rest: MxRenewRestInterface
  constructor(apiUrl: string = MX_RENEW_URL, rest: MxRenewRestInterface = defaultRest) {
    this.apiUrl = apiUrl
    this.rest = rest
  }

  createLead(lead: CreateMxRenewLeadInterface, token: LocalStorageToken = getToken()) {
    const endpoint = `/leads`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = lead
    return this.rest
      .post(
        url,
        {
          orderStatus: 'incomplete',
          ...body,
        },
        {
          headers,
        },
      )
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  updateLead(leadId: MxRenewLeadId, update: Partial<MxRenewLeadInterface>, token: LocalStorageToken = getToken()) {
    const endpoint = `/leads/${leadId}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = update
    return this.rest
      .patch(
        url,
        {
          orderStatus: 'incomplete',
          ...body,
        },
        {
          headers,
        },
      )
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }
}

export default new MxRenewService(MX_RENEW_URL, defaultRest)
