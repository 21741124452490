export const cool = (elements: number) => {
  const colors = [
    ['#21bed5'],
    ['#6acdd0', '#0085bb'],
    ['#6acdd0', '#0085bb', '#1b2b86'],
    ['#88d7cd', '#00a9cf', '#1362a6', '#192279'],
    ['#abe1ca', '#13bcd4', '#0085bb', '#1a4897', '#161c67'],
    ['#b4e2cb', '#49c5d2', '#009fc9', '#0f6bac', '#1d398e', '#151b62'],
    ['#bde5c9', '#67ccd1', '#00b0d4', '#0085bb', '#1759a1', '#1b2d87', '#14195d'],
    ['#c7e7c7', '#7ad2cf', '#00bbd5', '#0098c6', '#0a71af', '#1a4a98', '#1b2381', '#141759'],
    ['#cfeac7', '#8bd7cd', '#3cc2d4', '#00a9cf', '#0085bb', '#1362a6', '#1b3d91', '#192178', '#131653'],
    ['#d7edc5', '#9bdccb', '#56c8d2', '#00b5d7', '#0095c4', '#0074b1', '#18549e', '#13cc8b', '#181e6f', '#11154e'],
  ]

  // check if array exists for this many elements
  const palette: string[] = colors[elements - 1]
    // return array of colors if it exists
    ?  colors[elements - 1]
    // if array doesn't exist concat the largest array to itself and slice down to size
    : [].concat.apply(colors[9], colors[9]).slice(0, elements)

  return palette
}

export const warm = (elements: number) => {
  const colors = [
    ['#e9668c'],
    ['#ee8695', '#a73b8f'],
    ['#ee8695', '#a73b8f', '#501b73'],
    ['#f19a9b', '#d54d88', '#7b2a95', '#461765'],
    ['#f4aea3', '#e8638b', '#a73b8f', '#61208d', '#3c1357'],
    ['#f6b5a4', '#eb7590', '#c8488a', '#872e93', '#581d7f', '#3a1353'],
    ['#f7bba6', '#ed8495', '#e05286', '#a73b8f', '#6f2597', '#511b75', '#37114e'],
    ['#f8c1a8', '#ef9198', '#e8608a', '#c0458a', '#8f3192', '#63218f', '#4b186c', '#33104a'],
    ['#f8c7aa', '#f19b9c', '#ea708e', '#d54d88', '#a73b8f', '#7a2995', '#5b1f84', '#451764', '#300f45'],
    ['#f9cdac', '#f2a49f', '#ec7c92', '#e65586', '#bc438b', '#933291', '#692398', '#551c7b', '#41155e', '#2d0f41'],
  ]

  // check if array exists for this many elements
  const palette = colors[elements - 1]
    // return array of colors if it exists
    ? colors[elements - 1]
    // if array doesn't exist concat the largest array to itself and slice down to size
    : [].concat.apply(colors[9], colors[9]).slice(0, elements)

  return palette
}

export const grays = [
  '#eaeaea',
  '#d4d4d4',
  '#c7c7c7',
  '#acacac',
  '#909090',
  '#767676',
]

export const menu = {
  black: `#000`,
  blueBlack: `#2b2e3d`,
  lightBlue: `#17c8f4`,
  white: `#fff`,
}

export const dataTable = {
  blue: `#2185d0`,
  teal: '#66b4c2',
  white: `#fff`,
  offWhite: `#f9fafb`,
  lightGray: `#f0f0f0`,
  mediumGray: `#e0e1e2`,
  darkGray: `#cacbcd`,
  black: `#000`,
}

// 10 colors for max of 10 category options
export const optionColors = {
  blue: dataTable.blue,
  violet: `#6442C3`,
  olive: `#B5C840`,
  teal: `#2AB5AB`,
  green: `#36B651`,
  yellow: `#F8BB40`,
  orange: `#EE7137`,
  red: `#D72E35`,
  pink: `#DE3E96`,
  purple: `#A23BC5`,
}

export const renewFlow = {
  blue: dataTable.blue,
  black: menu.black,
}
