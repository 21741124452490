import React from 'react'
import { Progress } from 'semantic-ui-react'
import classnames from 'classnames'

import styles from './SolInlineProgressBar.module.scss'
export interface Props {
  percent: number
  maxWidthPercentage?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 // default width is 99%
}

export const SolInlineProgressBar = (props: Props) => {
  let progressWidth = 'defaultWidth'
  if (props.maxWidthPercentage) {
    progressWidth = `customWidth${props.maxWidthPercentage}`
  }
  return (
    <Progress
      className={classnames(styles.progressBar, styles[progressWidth], {
        [styles.zeroPercent]: props.percent === 0,
      })}
      size="tiny"
      percent={props.percent}
      color="green"
      active
    />
  )
}

export default SolInlineProgressBar
