import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useTableExport } from 'components/DataTableSlim/Hooks/useTableExport'
import { Event, EventsQuery } from './types'

export const useHistoryTable = () => {
  useDataTable<Event>(DataTableInstance.History)

  const totalItems = 0

  const { onExport } = useTableExport<EventsQuery, Event>({
    title: 'Management History',
    query: null,
    searchOptions: {},
    totalItems,
    resultMapper: () => [],
    addableColumns: false,
  })

  return {
    data: [],
    isLoading: false,
    totalItems,
    onExport,
  }
}
