import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import styles from './SolColorPicker.module.scss'

export interface Props {
  color: string
  setter: (color: string) => void
}

export default function SolColorPicker(props: Props) {
  const [showPicker, setShowPicker] = useState(false)

  let colorStyle = {
    backgroundColor: props.color,
  }
  return (
    <React.Fragment>
      <div
        className={styles.colorBox}
        style={colorStyle}
        onClick={() => {
          setShowPicker(!showPicker)
        }}
      />
      {showPicker ? (
        <div
          className={styles.picker}
          onClick={() => {
            setShowPicker(!showPicker)
          }}
        >
          <div onClick={e => e.stopPropagation()}>
            <ChromePicker
              color={props.color}
              disableAlpha
              onChange={color => {
                props.setter(color.hex)
              }}
            />
          </div>
          <div
            className={styles.clickOut}
            onClick={() => {
              setShowPicker(!showPicker)
            }}
          />
        </div>
      ) : null}
    </React.Fragment>
  )
}
