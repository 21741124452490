import React from 'react'
import styles from './SolGrid.module.scss'
import classNames from 'classnames'

export interface Props {
    children?: any
    className?: string
}

export default function SolRow({
  className,
  children,
}: Props) {
  return (
    <div className={classNames(className, styles.row)}>
      {children}
    </div>
  )
}
