import React from 'react'
import styles from './SolMetricCard.module.scss'
import classNames from 'classnames'
import SolCard from 'SolComponents/SolCard/SolCard'
import SolDonutChart, { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'

export interface Props {
  count: number
  label: string
  unit: string
  note: string | JSX.Element
  button?: JSX.Element
  pieData?: PieSector[]
  hideTooltip?: boolean
  legendVerticalAlign?: 'top' | 'bottom'
  externalLegend?: boolean
}

const tooltipFormatter = (value: string, name: string) => {
  const nameWithNoNumber = name?.toString().replace(value, '').trim() ?? ''
  const labelName = `${nameWithNoNumber[0]?.toUpperCase() ?? ''}${nameWithNoNumber.substr(1)}`

  return [value, labelName]
}

const tooltip = { formatter: tooltipFormatter }

const SolMetricCard = ({
  count,
  label,
  unit,
  note,
  button,
  pieData,
  hideTooltip,
  legendVerticalAlign,
  externalLegend,
}: Props) => {
  const metric = (
    <div className={classNames(styles.metric, { [styles.zero]: count === 0 })}>
      <p className={styles.count}>{count}</p>
      <div className={styles.label}>
        {label}
        <p className={styles.unit}>{unit}</p>
      </div>
      {count === 0 && (
        <p data-testid="note" className={styles.note}>
          {note}
        </p>
      )}
      {button}
    </div>
  )
  if (pieData) {
    return (
      <SolCard className={classNames(styles.metricCard, styles.chart)}>
        <div className={styles.chartContainer}>
          <SolDonutChart
            pieData={pieData}
            verticalAlign={legendVerticalAlign ?? 'top'}
            chartHeight={270}
            aspectRatio={1.3}
            xPosition={84}
            yPosition={120}
            legendWidth={180}
            legendAlignment="left"
            legendTitle={metric}
            showCount={false}
            responsiveWidth="100%"
            tooltip={tooltip}
            hideTooltip={hideTooltip}
            externalLegend={externalLegend}
          />
        </div>
      </SolCard>
    )
  }
  return <SolCard className={classNames(styles.metricCard)}>{metric}</SolCard>
}

export default SolMetricCard
