import { PrimaryAxis, Option, SecondaryAxis } from './types'
import { MetricType } from 'graphql/__generated__/types'
import { ComputeMetrics, metricLabels } from 'shared/domain/metric'

export const primaryOptions: Option<PrimaryAxis>[] = [
  {
    key: `po_1`,
    text: metricLabels.USERS,
    value: MetricType.Users,
  },
  {
    key: `po_2`,
    text: metricLabels.MEETINGS,
    value: MetricType.Meetings,
  },
  {
    key: `po_3`,
    text: metricLabels.POSTS,
    value: MetricType.Posts,
  },
  {
    key: `po_5`,
    text: metricLabels.HOURS_IN_USE,
    value: ComputeMetrics.Hour,
  },
  {
    key: `po_6`,
    text: metricLabels.AVERAGE_MEETING_DURATION,
    value: MetricType.AverageMeetingDuration,
  },
  {
    key: `po_7`,
    text: metricLabels.CONTENT_ITEMS_PER_MEETING,
    value: MetricType.ContentItemsPerMeeting,
  },
]

export const augmentedOptions: Option[] = [
  {
    key: `po_0`,
    text: 'None',
    value: 'none',
  },
]

export const secondaryOptions: Option<SecondaryAxis>[] = [...augmentedOptions, ...primaryOptions]
