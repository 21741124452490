import React, { useState } from 'react'
import { Header, Accordion } from 'semantic-ui-react'
import { useCustomCategoriesQuery, useLocationCategoriesQuery, Address, Category } from 'graphql/__generated__/types'
import styles from './SideFilterMenu.module.scss'
import { SolButton } from 'SolComponents'
import SideFilterDropdown from './SideFilterDropdown'
import SolDrawer from 'SolComponents/SolDrawer/SolDrawer'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { Link } from 'react-router-dom'

export interface Props {
  tableId: string
  filterDrawerIsOpen: boolean
  totalDisplays: number
  changeFilterDrawer: () => void
}

export type FilterCategory = {
  categoryDisplayName: string
  categoryInternalName: string
  options: FilterOption[]
}
export type FilterOption = {
  addressIds?: number[]
  displayCount: number
  optionDisplayName: string
  categoryDisplayName: string
  categoryInternalName: string
  optionInternalName: string
}

export default function SideFilterMenu({
  tableId,
  filterDrawerIsOpen,
  totalDisplays,
  changeFilterDrawer,
}: Props) {
  const [activeIndex, setActiveIndex] = useState(-1)

  // Location filters will just send an array of ids to Apollo (or use the weird Hasura view key)
  const locationFilters = (useLocationCategoriesQuery({
    variables: {
      options: {
        hasPods: true,
      },
    },
  }).data?.addresses as Address[])?.reduce((accumulator, current) => {
    // The Apollo query returns a list of buildings/addresses. This collapses that list into hashmaps of countries, cities, and buildings.
    if (current.country) {
      const country = accumulator[0].options[current.country] ?? { addressIds: [], displayCount: 0 }
      accumulator[0].options[current.country] = {
        addressIds: [...country.addressIds!, current.id],
        displayCount: country.displayCount + current.num_displays,
        optionDisplayName: current.country,
        optionInternalName: current.country.toLowerCase().replace(/\s/g, '_'),
        categoryDisplayName: 'Country',
        categoryInternalName: 'country',
      }
    }
    if (current.city) {
      const city = accumulator[1].options[current.city] ?? { addressIds: [], displayCount: 0 }
      accumulator[1].options[current.city] = {
        addressIds: [...city.addressIds!, current.id],
        displayCount: city.displayCount + current.num_displays,
        optionDisplayName: current.state_province ? `${current.city}, ${current.state_province}` : current.city,
        optionInternalName: (current.state_province
          ? `${current.city}_${current.state_province}`
          : current.city
        ).toLowerCase().replace(/\s/g, '_'),
        categoryDisplayName: 'City',
        categoryInternalName: 'city',
      }
    }
    accumulator[2].options[current.id] = {
      addressIds: [current.id],
      displayCount: current.num_displays,
      optionDisplayName: current.nickname ?? current.building,
      optionInternalName: `address_id_${current.id}`,
      categoryDisplayName: 'Building',
      categoryInternalName: 'building',
    }
    return accumulator
  }, [
    { category: 'Country', options: {} },
    { category: 'City', options: {} },
    { category: 'Building', options: {} },
  ] as Array<{ category: string; options: Record<string, FilterOption> }>).map(category => ({
    // This maps the hashmap of countries, cities, and buildings into FilterCategory objects the menu can understand
    categoryDisplayName: category.category,
    categoryInternalName: category.category.toLowerCase(),
    options: Object.values(category.options),
  } as FilterCategory)) ?? []

  const customFilters = (useCustomCategoriesQuery().data?.categories as Category[])?.map(category => ({
    categoryDisplayName: category.name,
    categoryInternalName: category.id,
    options: category.options.map(option => ({
      categoryDisplayName: category.name,
      categoryInternalName: category.id,
      optionDisplayName: option.name,
      optionInternalName: option.id,
      displayCount: option.appliedDisplayCount,
    } as FilterOption)),
  } as FilterCategory)) ?? []

  const filters = [...customFilters, ...locationFilters]

  const { setCategoryFilters, tableData } = useDataTable<any>(tableId)

  const { categoryFilters } = tableData || { categoryFilters: {} }

  return (
    <SolDrawer
      isOpen={filterDrawerIsOpen}
      onCloseDrawer={() => changeFilterDrawer()}
    >
      <div className={styles.sideFilterMenu}>
        <Header
          className={styles.header}
          content="Filter by Category"
          size="huge"
        />
        <Accordion>
          {filters.map((filter, index) => (
            <div key={filter.categoryInternalName ?? index}>
              <SideFilterDropdown
                key={filter.categoryInternalName ?? index}
                category={filter}
                handleMenuClick={() => setActiveIndex(index === activeIndex ? -1 : index)}
                totalDisplayCount={totalDisplays}
                active={index === activeIndex}
                tableId={tableId}
              />
            </div>
          ))}
        </Accordion>
        {customFilters.length === 0 && (
          <p className={styles.center}>
            Add some <Link to="/categories">categories</Link> for more powerful
            filtering of your data!
          </p>
        )}
        {Object.keys(categoryFilters).length !== 0 && (
          <SolButton
            handleClick={() => setCategoryFilters({})}
            text="Clear all"
            className={styles.clearButton}
          />
        )}
      </div>
    </SolDrawer>
  )
}
