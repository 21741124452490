import React from 'react'
import { useGetSoftwareVersion } from 'shared/hooks/versions'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SolLoaderIcon } from 'SolComponents/Icons'
import styles from './SolPodVersion.module.scss'
import { SolTooltipWithLink } from '../index'
import SolInfoCircle from '../Icons/SolInfoCircle'

interface Props {
  version?: string | null
  catchingUp?: boolean | null
  isElementPod?: boolean
}


function SolPodVersion({
  version,
  catchingUp,
  isElementPod = false,
}: Props) {
  const getSoftwareVersion = useGetSoftwareVersion()

  const {
    stupidLoadingIcon = false,
  } = useFlags()

  return (
    <>
      {isElementPod ? (
        <div className={styles.elementVersionContainer}>
          {getSoftwareVersion(version ?? '')}
          <SolTooltipWithLink
            trigger={<SolInfoCircle size="small"/>}
            text={[
              'Solstice Element upgrade options are limited.',
            ]}
            buttonText="LEARN MORE"
            position="bottom right"
            onClick={() => {
              window.open(
                'https://cdn.brandfolder.io/YLVMRULZ/at/64t624g728rb7t2h3xkvnb5w/Solstice_Element_Admin_Guide.pdf',
                '_blank',
              )
            }}
          />
        </div>
      ) : (
        getSoftwareVersion(version ?? '')
      )}

      {catchingUp && stupidLoadingIcon && (
        <SolLoaderIcon
          text="Pod version is currently syncing."
          size="mini"
          className={styles.loader}
        />
      )}
    </>
  )
}

export default SolPodVersion
