import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { OrderBy } from 'graphql/__generated__/hasura-types'
import { UpdateablePodsOptions, UpdateablePod, Direction, useUpdateablePodsQuery } from 'graphql/__generated__/types'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useSearchParams } from 'components/DataTableSlim/Hooks/useSearchDisplays'

const sortMapper = (field: string, orderBy: OrderBy) => {
  const output = {
    [field]: [
      OrderBy.Asc,
      OrderBy.AscNullsFirst,
      OrderBy.AscNullsLast,
    ].includes(orderBy) ? Direction.Asc : Direction.Desc,
  }
  return output
}
const searchMapper = (searchTerm: string): UpdateablePodsOptions => ({ nameLike: searchTerm })

export const useUpdateablePodsQueryVars = (): UpdateablePodsOptions => {
  const {
    searchValue,
    page,
    orderBy: orderByTable,
    setOrderBy,
  } = useDataTable<UpdateablePod>(DataTableInstance.Updates)
  const { limit, offset, orderBy, where } = useSearchParams<UpdateablePodsOptions>({
    tableInstance: DataTableInstance.Updates,
    page,
    search: searchValue,
    orderBy: orderByTable,
    setOrderBy,
    sortMapper,
    searchMapper,
  })

  return {
    nameLike: where.nameLike,
    limit,
    offset,
    orderBy,
  }
}

export const useUpdateablePodsCount = () => {
  const { data } = useUpdateablePodsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      options: {
        limit: 1,
      },
    },
  })
  return data?.updateablePods?.updateablePodsCount ?? 0
}
