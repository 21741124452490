import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { State } from 'shared/store/slices/reducerUtils'

import {
  showMxRenewalBanner,
  getRenewModalSlideKeys,
} from 'shared/store/selectors/maintenance'

import { setMxBanner } from 'shared/store/slices/maintenance'

import MaintenanceBanner, { StateFromProps } from './MaintenanceBanner'

const mapStateToProps = (state: State): any => ({
  showBanner: showMxRenewalBanner(state),
  modalSlideKeys: getRenewModalSlideKeys(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setBannerOpen: (open: boolean) => dispatch(setMxBanner(open)),
})

export default connect<StateFromProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceBanner)
