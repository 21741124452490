import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Header, Button } from 'semantic-ui-react'
import FilterMenuItem from './FilterMenuItem'
import { filterExists, addFilter, removeFilter } from '../Utils/filterMenu'
import { useDataTable } from '../Hooks/useDataTable'
import { useTableFilterCategoriesQuery } from 'graphql/__generated__/hasura-types'
import styles from './FilterMenu.module.scss'
import { TableFilterCategory } from '../../../shared/types/categories'

export interface Props {
  tableId: string
  clearSelectedItemsOnFilter?: boolean
}

export interface State {
  open: object
}

export const style = {
  parent: {
    marginRight: '15px',
    flex: '1',
    paddingTop: '2px',
  },
  header: {
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#f9fafb',
    textAlign: 'center',
  },
  h3: {
    display: 'inline-block',
    marginTop: '0',
  },
  icon: {
    float: 'left',
    display: 'inline-block',
    cursor: 'pointer',
    marginBottom: '-30px',
    marginTop: '4px',
    marginLeft: '-3px',
  },
  placeholder: {
    width: '58px',
    height: '292px',
  },
  label: {
    marginRight: 0,
    marginLeft: '0.5rem',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  center: {
    textAlign: 'center',
  } as React.CSSProperties,
}

export default function FilterMenu({ tableId, clearSelectedItemsOnFilter }: Props) {
  const [open, setOpen] = useState({})
  const { data: categoriesData } = useTableFilterCategoriesQuery({
    fetchPolicy: 'cache-and-network',
  })
  const totalDisplayCount = categoriesData?._displaysAggregate.aggregate?.count ?? 0
  const categories = categoriesData?._categories ?? []
  const { setCategoryFilters, tableData } = useDataTable<any>(tableId, { clearSelectedItemsOnFilter })
  const { categoryFilters } = tableData || { categoryFilters: {} }
  const onRemoveAllFilters = () => {
    setCategoryFilters({})
  }

  const onOptionClick = (data: { value: string; displayName: string }) => {
    let [category, option] = data.value.split(' ')
    const { displayName } = data

    if (!option) {
      option = 'unassigned'
    }

    if (!filterExists(categoryFilters, category, option)) {
      setCategoryFilters(addFilter(categoryFilters, category, option, displayName))
    } else {
      setCategoryFilters(removeFilter(categoryFilters, category, option))
    }
  }

  const onMenuClick = (data: { name: string }) => {
    const category = data.name.toString()
    setOpen({ [category]: !open[category] })
  }

  const customCategories = categories.filter(c => c.category_type === 'CUSTOM')

  const locationCategories = categories.filter(c => c.category_type === 'LOCATION')

  const CategoryFilterMenuItem = (category: TableFilterCategory) => (
    <FilterMenuItem
      key={category.id ?? ''}
      category={category}
      appliedFilters={categoryFilters}
      handleMenuClick={onMenuClick}
      handleOptionClick={onOptionClick}
      open={open}
      totalDisplayCount={totalDisplayCount}
    />
  )

  return (
    <div className={styles.filterMenu} style={style.parent}>
      <Header attached="top" style={style.header} data-testid="filter-table-header">
        <h3 style={style.h3}>Filter by Category</h3>
      </Header>
      <Menu pointing vertical attached="bottom" className={styles.body} data-testid="filter-table-menu">
        {customCategories.length === 0 ? (
          <Menu.Item>
            <p style={style.center}>
              Add some <Link to="/categories">categories</Link> for more powerful filtering of your data!
            </p>
          </Menu.Item>
        ) : (
          <>
            <Menu.Header className={styles.categoryType}>Custom</Menu.Header>
            <Menu.Menu className={styles.categories}>
              {Object.values(customCategories).map(category => (
                <CategoryFilterMenuItem key={category.id ?? ''} {...category} />
              ))}
            </Menu.Menu>
          </>
        )}
        {locationCategories.length > 0 && (
          <>
            <Menu.Header className={styles.categoryType}>Location</Menu.Header>
            <Menu.Menu className={styles.categories}>
              {Object.values(locationCategories)
                .reverse()
                .map(category => (
                  <CategoryFilterMenuItem key={category.id ?? ''} {...category} />
                ))}
            </Menu.Menu>
          </>
        )}
        {Object.keys(categoryFilters || {}).length > 0 && (
          <Menu.Item>
            <Button fluid onClick={onRemoveAllFilters}>
              Clear all filters
            </Button>
          </Menu.Item>
        )}
      </Menu>
    </div>
  )
}
