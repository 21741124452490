import React, { useState, useEffect } from 'react'
import { startOfDay, endOfDay, format } from 'date-fns'
import Flatpickr from 'react-flatpickr'
import SolModal from 'SolComponents/SolModal/SolModal'
import { Button, Checkbox, Grid, Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { SolConfirmationModal } from 'SolComponents'
import styles from './UpdatesPage.module.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import 'flatpickr/dist/themes/material_green.css'
import { pluralize } from 'shared/core/utils'
import { useGetSoftwareVersion } from 'shared/hooks/versions'
import { getDefaultStartDate } from './Tabs/scheduledTask'
import { useGetAllSelectedPods, useNumSelectedPods } from './hooks/useSelectedPods'
import {
  CurrentScheduledTasksDocument,
  ScheduledTaskUpdateType,
  TaskType,
  UpdateablePod,
} from 'graphql/__generated__/types'
import { useCreateScheduledTask } from 'shared/hooks/useCreateScheduledTask'
import { useUpdateEligibility } from './hooks/useUpdateEligibility'
import { Release } from '../../shared/hooks/versions/useVersionUtils'

type VersionReleases = DropdownItemProps & { date: string }

interface Props {
  latestVersion: string
  totalUpdateablePods: number
  releases: VersionReleases[]
  onCloseModal: () => void
  onTaskCreated: (displayIds?: string[]) => void
}

type SelectedPodVersion = {
  version: string
  subscriptionEndDate: Date
  hardware: string
}

// The query hooks in this modal MUST be in this modal or they will requery every time someone selects or unselects a pod
const UpdatePodsModal = React.memo(function({
  latestVersion,
  totalUpdateablePods,
  releases,
  onCloseModal,
  onTaskCreated,
}: Props) {
  const { upgradeToSpecificVersion = true } = useFlags()

  const earliestStartDate = new Date()
  const { data: selectedPodsData, loading: selectedPodsLoading } = useGetAllSelectedPods()
  const selectedPods = (selectedPodsLoading ? [] : selectedPodsData?.updateablePods.pods) as Array<UpdateablePod>
  const numSelectedPods = useNumSelectedPods(totalUpdateablePods)
  const displayIds = selectedPods.map(p => p.displayId ?? '')

  const selectedPodVersions = selectedPods.map((p: UpdateablePod) => {
    return {
      version: p?.version?.join('.') || '',
      subscriptionEndDate: p?.subscriptionEndDate
        ? new Date(p.subscriptionEndDate)
        : new Date(),
      hardware: p?.hardware ?? '',
    }
  }) as SelectedPodVersion[]

  const updateEligibility = useUpdateEligibility(releases as Release[], selectedPodVersions)

  const minVersion = selectedPodVersions.pop()?.version

  let minReleases = [] as VersionReleases[]
  if (minVersion && minVersion.length > 0) {
    minReleases = updateEligibility
  }

  const canUpdateMultiple = !(minReleases.length <= 0 && releases.length > 0)
  const [showUpdateNowModal, setShowUpdateNowModal] = useState(false)
  const [showUpdateLaterModal, setShowUpdateLaterModal] = useState(false)
  const [sendEmailOnCompletion, setSendEmailOnCompletion] = useState(true)
  const versionSelected = minReleases[minReleases.length - 1]?.key.toString()
  const [selectedVersion, setSelectedVersion] = useState(versionSelected)
  useEffect(() => setSelectedVersion(versionSelected), [versionSelected])
  const [scheduledDate, setScheduledDate] = useState(getDefaultStartDate(earliestStartDate))
  const getSoftwareVersion = useGetSoftwareVersion()

  const createScheduledTask = useCreateScheduledTask([{ query: CurrentScheduledTasksDocument }])
  const [loading, setLoading] = useState(false)
  return (
    <React.Fragment>
      <SolConfirmationModal
        isOpen={showUpdateNowModal}
        modalText={<span>Once the update begins, it can't be cancelled.</span>}
        onAcceptingAction={async () => {
          setLoading(true)
          await createScheduledTask({
            type: TaskType.DisplayUpdate,
            displayIds,
            data: {
              versionToUpdate: selectedVersion.length > 0 ? selectedVersion : latestVersion,
            },
          })
          setShowUpdateNowModal(false)
          onTaskCreated(displayIds)
          setLoading(false)
          onCloseModal()
        }}
        acceptingText="Update Now"
        acceptingDisabled={loading}
        onCloseModal={() => setShowUpdateNowModal(false)}
        cancelText="Cancel"
        subHeaderText={`${pluralize(numSelectedPods, 'Pod')} will begin updating immediately.`}
      />
      <SolModal
        isOpen={showUpdateLaterModal}
        onCloseModal={() => {
          setShowUpdateLaterModal(false)
        }}
      >
        <strong>
          {pluralize(numSelectedPods, 'pod')} selected to update to {getSoftwareVersion(selectedVersion)}
        </strong>

        <Grid columns={3} className={styles.datePicker}>
          <Grid.Row centered>
            <Grid.Column width={7}>
              <Flatpickr
                options={{
                  dateFormat: 'm/d/Y',
                  disable: [
                    function(date: Date) {
                      return date < startOfDay(new Date(earliestStartDate))
                    },
                  ],
                }}
                className={styles.date}
                value={scheduledDate}
                onChange={val => {
                  if ( format(startOfDay(new Date(earliestStartDate)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
                  === format(startOfDay( new Date(val[0])), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')) {
                    setScheduledDate( endOfDay(new Date(earliestStartDate)))
                  } else {
                    setScheduledDate(new Date(val[0]))
                  }
                }}
              />{' '}
            </Grid.Column>
            <Grid.Column className={styles.atText} width={2}>
              at
            </Grid.Column>
            <Grid.Column width={7}>
              <Flatpickr
                value={scheduledDate}
                onChange={val => {
                  if (!val.length) {
                    return
                  }
                  const date = new Date(val[0]) > earliestStartDate ? new Date(val[0]) : earliestStartDate
                  setScheduledDate(date)
                }}
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'h:i K',
                  minDate: earliestStartDate,
                }}
                className={styles.time}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <p className={styles.disclaimer}>* Updates will occur on Pod's local time.</p>
        <Checkbox
          checked={sendEmailOnCompletion}
          onChange={(_, val) => setSendEmailOnCompletion(val.checked === true)}
          label="Email me after my update is complete"
        />
        <div>
          <Button
            className={styles.schedule}
            primary
            onClick={async () => {
              setLoading(true)
              await createScheduledTask({
                type: TaskType.DisplayUpdate,
                displayIds,
                updateType: ScheduledTaskUpdateType.Scheduled,
                startDate: format(scheduledDate, 'yyyy-MM-dd\'T\'HH:mm:ss'),
                data: {
                  versionToUpdate: selectedVersion.length > 0 ? selectedVersion : latestVersion,
                  sendEmailOnCompletion,
                },
              })
              setShowUpdateLaterModal(false)
              onTaskCreated(displayIds)
              setLoading(false)
              onCloseModal()
            }}
            disabled={loading}
          >
            Schedule
          </Button>
        </div>
      </SolModal>

      <SolModal
        isOpen={!selectedPodsLoading && !showUpdateNowModal && !showUpdateLaterModal}
        onCloseModal={onCloseModal}
      >
        <div className={styles.updateContent}>
          {upgradeToSpecificVersion && !canUpdateMultiple ? (
            <div>
              <strong>
                No versions exist that are compatible with all Pods selected. Try removing any Pods that have lapsed
                Solstice Subscriptions and update those independently
              </strong>
            </div>
          ) : (
            <div>
              <strong>
                {pluralize(numSelectedPods, 'pod')} selected to update to{' '}
                {upgradeToSpecificVersion && minReleases.length > 0 ? '' : latestVersion}
              </strong>
              {upgradeToSpecificVersion && minReleases.length > 0 ? (
                <div className={styles.selectedPodslbl}>
                  {' '}
                  <Dropdown
                    placeholder="Select Version"
                    fluid
                    search
                    selection
                    upward={false}
                    className={styles.releasesDropDown}
                    value={selectedVersion || versionSelected}
                    options={minReleases}
                    onChange={(e, { value }) => {
                      if (value) {
                        setSelectedVersion(value.toString())
                      }
                    }}
                  />{' '}
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {upgradeToSpecificVersion && !canUpdateMultiple ? (
            <div className={styles.updateButtons}>
              <div>
                <Button
                  primary
                  onClick={() => {
                    onCloseModal()
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.updateButtons}>
              <div>
                <Button
                  primary
                  color="blue"
                  disabled={upgradeToSpecificVersion && !canUpdateMultiple}
                  onClick={() => {
                    setShowUpdateNowModal(true)
                  }}
                >
                  Update Now
                </Button>
              </div>
              <div className={styles.orText}>or</div>
              <div>
                <Button
                  primary
                  disabled={upgradeToSpecificVersion && !canUpdateMultiple}
                  onClick={() => {
                    setShowUpdateLaterModal(true)
                  }}
                >
                  Schedule for Later
                </Button>
              </div>
            </div>
          )}
        </div>
      </SolModal>
    </React.Fragment>
  )
})

export { UpdatePodsModal }
