import { useMutation, useQuery } from '@apollo/client'
import { ServerSelectedItems } from 'components/DataTableSlim/types'
import { idHasBeenSelected } from 'components/DataTableSlim/Utils'
import { useEffect, useState } from 'react'
import assetServer from 'shared/services/asset/image.service'
import {
  AllDisplaysDocument,
  AllDisplaysQuery,
  AssignTemplateDocument,
  AssignTemplateMutation,
  CreateTemplateDocument,
  CreateTemplateInput,
  CreateTemplateMutation,
  DeleteTemplateDocument,
  Display,
  DisplaySearchOptions,
  MutationAssignTemplateArgs,
  MutationCreateTemplateArgs,
  MutationDeleteTemplateArgs,
  MutationUnassignTemplateArgs,
  SettingsGroupType,
  Template,
  TemplateListDocument,
  TemplateListQuery,
  TemplateListQueryVariables,
  UnassignTemplateDocument,
  UnassignTemplateMutation,
  WelcomeScreenImage,
} from '../../../graphql/__generated__/types'
import { useAlerts } from '../useAlerts'
import {
  MutationUpdateTemplateArgs,
  UpdateTemplateDocument,
  UpdateTemplateInput,
  UpdateTemplateMutation,
} from './../../../graphql/__generated__/types'
import { MutationUpdaterFn } from '@apollo/client'
import { DataProxy } from '@apollo/client/cache'

type GroupedTemplates = {
  [key in SettingsGroupType]: Template[]
}

/**
 * Converts a list of templates into an object whose keys represent each type of template and whose values are all of the templates matching that type
 *
 * @function
 * @name groupTemplatesByType
 * @param {Array<Template>} templates The list of templates to summarize
 * @returns {GroupedTemplates} An object whose keys are the template type and whose values are lists of templates matching that type
 */
function groupTemplatesByType(templates: Template[]): GroupedTemplates {
  return (templates ?? [])
    .filter(Boolean)
    .reduce((summary, template) => ({
      ...summary,
      [template.settingsGroup.type]: [
        ...(summary[template.settingsGroup.type] ?? []),
        template,
      ].filter(Boolean),
    }), {}) as GroupedTemplates
}

export function useGetTemplates(settingsGroupType: SettingsGroupType) {
  
  const { data: templateData, loading, refetch, networkStatus } = useQuery<
    TemplateListQuery,
    TemplateListQueryVariables
  >(TemplateListDocument, {
    variables: {
      options: {
        settingsGroupType,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
  })

  const orgTemplates = templateData?.templates as Template[]
  return {
    data: orgTemplates,
    loading,
    refetch,
    networkStatus,
  }
}

export const useGetAllTemplates = () => {
  const { data, loading } = useQuery<
    TemplateListQuery,
    TemplateListQueryVariables
  >(TemplateListDocument, {
    variables: {
      options: {},
    },
    fetchPolicy: 'cache-and-network',
  })

  return { loading, data: groupTemplatesByType(data?.templates as Template[]) }
}

export function useCreateTemplate() {
  const { showSuccess, showError } = useAlerts()
  const [createTemplateMut, { loading: creatingTemplate }] = useMutation<
    CreateTemplateMutation,
    MutationCreateTemplateArgs
  >(CreateTemplateDocument, {
    onError: error => {
      if (error.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
        showError(error.graphQLErrors[0].message)
      } else {
        showError('Something went wrong creating a template')
      }
      throw error
    },
  })
  return {
    createTemplate: async (template: CreateTemplateInput) =>
      createTemplateMut({
        variables: {
          template,
        },
        update: () => showSuccess('Template was created'),
      }),
    creatingTemplate,
  }
}

export function useUpdateTemplate() {
  const { showSuccess, showError } = useAlerts()
  const [updateTemplateMut, { loading: updatingTemplate }] = useMutation<
    UpdateTemplateMutation,
    MutationUpdateTemplateArgs
  >(UpdateTemplateDocument, {
    onError: error => {
      if (error.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
        showError(error.graphQLErrors[0].message)
      } else {
        showError('Something went wrong updating a template')
      }
      throw error
    },
  })
  return {
    updateTemplate: async (template: UpdateTemplateInput) =>
      updateTemplateMut({
        variables: {
          template,
        },
        update: () => showSuccess('Template was updated'),
      }),
    updatingTemplate,
  }
}

export function useDeleteTemplate() {
  const { showSuccess, showError } = useAlerts()
  const [deleteTemplateMut, { loading: deletingTemplate }] = useMutation<
    MutationDeleteTemplateArgs
  >(DeleteTemplateDocument, {
    onCompleted: () => showSuccess('Template was deleted'),
    onError: error => {
      showError('There was a problem deleting the template. Try again later.')
      throw error
    },
  })
  return {
    deleteTemplate: async (id: string) =>
      deleteTemplateMut({
        variables: {
          template: { id },
        },
      }),
    deletingTemplate,
  }
}

export function useAssignTemplates(
  displaySearchOptions?: DisplaySearchOptions,
  update?: MutationUpdaterFn<AssignTemplateMutation>,
) {
  const [
    assignTemplateMut,
    { loading: assigning },
  ] = useMutation<
    AssignTemplateMutation,
    MutationAssignTemplateArgs
  >(AssignTemplateDocument)

  return {
    assignTemplates: (selectedItems: ServerSelectedItems, templateId: string) =>
      assignTemplateMut({
        variables: {
          options: {
            templateId,
            displaySearchOptions: {
              ...displaySearchOptions,
              displayIds: selectedItems.includedIds,
              excludeDisplayIds: selectedItems.excludedIds,
              isManageable: true,
            },
          },
        },
        update,
      }),
    assigning,
  }
}

export function useUnassignTemplates(
  displaySearchOptions?: DisplaySearchOptions,
  update?: MutationUpdaterFn<UnassignTemplateMutation>,
) {
  const [
    unassignTemplateMut,
    { loading: unassigning },
  ] = useMutation<
    UnassignTemplateMutation,
    MutationUnassignTemplateArgs
  >(UnassignTemplateDocument)

  return {
    unassignTemplates: (
      selectedItems: ServerSelectedItems,
      settingsGroupType: SettingsGroupType,
    ) =>
      unassignTemplateMut({
        variables: {
          options: {
            settingsGroupType,
            displaySearchOptions: {
              ...displaySearchOptions,
              displayIds: selectedItems.includedIds,
              excludeDisplayIds: selectedItems.excludedIds,
              isManageable: true,
            },
          },
        },
        update,
      }),
    unassigning,
  }
}

export const updateAllDisplaysQueryOnUnassign = (
  displaySearchOptions: DisplaySearchOptions,
  selectedItems: ServerSelectedItems,
  settingsGroupType?: SettingsGroupType,
) => (proxy: DataProxy) => {
  const cacheData = proxy.readQuery({
    query: AllDisplaysDocument,
    variables: {
      options: displaySearchOptions,
    },
  }) as AllDisplaysQuery

  const updatedItems = (cacheData?.displays?.items ?? []) as Display[]

  proxy.writeQuery({
    query: AllDisplaysDocument,
    variables: {
      options: displaySearchOptions,
    },
    data: {
      ...cacheData,
      displays: {
        ...cacheData.displays,
        items: updatedItems.map(item => {
          return {
            ...item,
            assignedTemplates: idHasBeenSelected(item.id, selectedItems)
              ? (item.assignedTemplates || []).filter(
                t => t !== null && t.settingsGroup?.type !== settingsGroupType,
              )
              : item.assignedTemplates,
          }
        }),
      },
    },
  })
}

type ImagePartial = Pick<WelcomeScreenImage, '_32x9' | '_16x9'>

interface ImagesByPosition {
  [position: number]: ImagePartial
}

export function useDefaultImages(): ImagesByPosition | undefined {
  const [images, setImages] = useState<ImagesByPosition>()
  useEffect(() => {
    const fetchImages = async () => {
      const result = await assetServer.list()
      setImages(
        result.reduce((acc: ImagesByPosition, current) => {
          const imageIndex = current.metadata.index
          const displayType = current.metadata.displayType
          acc[imageIndex] = {
            _16x9: {
              hash:
                displayType === 'single'
                  ? current.name
                  : acc?.[imageIndex]?._16x9?.hash || '',
            },
            _32x9: {
              hash:
                displayType === 'span'
                  ? current.name
                  : acc?.[imageIndex]?._32x9?.hash || '',
            },
          }
          return acc
        }, {}),
      )
    }
    fetchImages()
  }, [])
  return images
}
