import React, { useState, createRef, useEffect } from 'react'
import { Map, TileLayer, ZoomControl, Marker } from 'react-leaflet'
import { LatLng, divIcon, LatLngBounds } from 'leaflet'
import { TargetMarker } from './MapMarkers'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import styles from './Map.module.scss'
import { Button } from 'semantic-ui-react'
import SolGpsTargetIcon from 'SolComponents/Icons/SolGpsIcon/SolGpsTargetIcon'
import { useDisplaysByGeoQuery, DisplaysByGeoArgs } from 'graphql/__generated__/hasura-types'
import 'leaflet/dist/leaflet.css'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import MarkerCluster from 'components/Location/Clustering/MarkerCluster'
import { renderToString } from 'react-dom/server'
import MapDot from 'SolComponents/Icons/SolGpsIcon/MapDot'
import { PodsClusterGroup } from 'components/Location/Clustering/ClusterTypes'

type Props = {
  podId: string
  podName: string
  save: boolean
  setSearch: (currentPod: LatLng) => void
  search?: LatLng
  toast: boolean
  currentPodLocation?: LatLng
  setMapBounds: (newFilter: number[][] | undefined) => void
  setAccuracyH: (n: number) => void
}
export function InteractiveMap(props: Props) {
  const refMap = createRef<Map>()
  const [zoom, setZoom] = useState(5)
  const [center, setCenter] = useState(new LatLng(0, 0))
  const [orgPods, setOrgPods] = useState<(MarkerObject & { id?: string | null })[]>([])
  const [displaysByGeoArgs, setDisplaysByGeoArgs] = useState<DisplaysByGeoArgs>({
    center_latitude: -1,
    center_longitude: -1,
    radius_km: -1,
  })

  const { data } = useDisplaysByGeoQuery({
    variables: {
      args: displaysByGeoArgs,
    },
  })

  useEffect(() => {
    updateDisplaysByGeoArgs(center)
    updateCenterForMap()
  }, [props.podId])

  useEffect(() => {
    if (
      props.search === props.currentPodLocation
      && props.search
      && props.currentPodLocation
      && props.search.lat !== 0
      && props.search.lng !== 0
    ) {
      reCenterMapOnPoint()
    }
  }, [props.currentPodLocation, props.search])

  useEffect(() => {
    if (data?._displays_by_geo.length !== 0) {
      setOrgPods(
        data?._displays_by_geo.map(
          display =>
            ({
              id: display.id,
              latlng: new LatLng(display.location?.precise_lat, display.location?.precise_long),
              name: display?.name ?? '',
            } as MarkerObject),
        ) ?? orgPods,
      )
    }
  }, [displaysByGeoArgs])

  useEffect(() => {
    // instead of onViewPointChanged
    if (zoom > 5) {
      props.setMapBounds(getBoundingBox())
    } else {
      props.setMapBounds(undefined)
    }

    if (refMap?.current?.leafletElement) {
      const map = refMap?.current?.leafletElement
      const distance_m = map.getBounds().getNorthEast().distanceTo(map.getBounds().getSouthWest())
      props.setAccuracyH(distance_m / 20.0)
    }
  }, [zoom])

  const updateDisplaysByGeoArgs = (location: LatLng) => {
    const corner = refMap?.current?.leafletElement.getBounds().getNorthEast() ?? location

    setDisplaysByGeoArgs({
      center_latitude: location.lat,
      center_longitude: location.lng,
      radius_km: location.distanceTo(corner) !== 0 ? location.distanceTo(corner) / 1000 : 10,
    })
  }

  const updateSearch = () => {
    const map = refMap.current
    const currentZoom = map?.leafletElement.getZoom()

    if (zoom !== currentZoom) {
      setZoom(currentZoom !== undefined ? currentZoom : zoom)
    }

    if (props.save && zoom === currentZoom) {
      props.setSearch(center)
    }
    updateDisplaysByGeoArgs(center)
  }

  const getBoundingBox = () => {
    const map = refMap.current
    let bounds: LatLngBounds | undefined = undefined
    if (map) {
      bounds = map?.leafletElement.getBounds()
    }

    if (bounds) {
      return [
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      ]
    }
    return [[]]
  }

  const updateCenterForMap = () => {
    const map = refMap.current
    if (map !== null) {
      const mapCenter = map?.leafletElement.getCenter()
      setCenter(mapCenter)
    }
  }

  const reCenterMapOnPoint = () => {
    const map = refMap.current
    if (map && props.search) {
      map?.leafletElement.flyTo([props.search.lat, props.search.lng], 19)

      setTimeout(() => {
        updateDisplaysByGeoArgs(props.search ?? center)
      }, 3000)
    }
  }

  const dot = divIcon({
    html: renderToString(<MapDot color="#3388ff" />),
    className: `${styles.podDot}`,
  })

  // didnt update to solMap because of onViewportChanged={updateSearch} && onViewportChange={updateCenterForMap} couldnt recreate
  return (
    <div className={props.save ? styles.borderActive : styles.border}>
      <Map
        className={styles.map}
        center={new LatLng(39.5, -98.35)}
        zoom={zoom}
        maxZoom={23}
        minZoom={2}
        ref={refMap}
        onViewportChanged={updateSearch}
        onViewportChange={updateCenterForMap}
        zoomControl={false}
      >
        <div className={props.save ? styles.infoActive : styles.displayNone}>
          Drag the map to recenter the orange dot, and fine-tune {props.podName}
          's location.
        </div>
        <div className={props.toast && !props.save ? styles.savedBuildingActive : styles.displayNone}>
          New building saved. Click "Fine-tune Location" button to edit precise location of the Pod within the building.
        </div>

        <Button className={styles.controlButtonsOut}>
          <SolGpsTargetIcon onClick={reCenterMapOnPoint} className={styles.targetIcon} />
        </Button>
        <ZoomControl position="topright" />
        <TileLayer
          url="//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          bounds={new LatLngBounds(new LatLng(-90, -180), new LatLng(90, 180))}
          /* eslint-disable-next-line max-len */
          attribution={`&copy; <a href=https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`}
          noWrap
        />
        {orgPods && orgPods.length > 0 ? (
          <MarkerCluster
            mcg={PodsClusterGroup}
            markersList={orgPods.filter(d => d.id !== props.podId)}
            icon={dot}
            buttonPopUp
            fitBounds={false}
            showCluster
            type="Pod"
          />
        ) : (
          <MarkerClusterGroup>
            <Marker icon={dot} position={new LatLng(0, 0)} className={styles.displayNone} />
          </MarkerClusterGroup>
        )}
        <TargetMarker
          position={props.save ? center : props.search ?? center}
          name={props.podName}
          editing={props.save}
        />
      </Map>
    </div>
  )
}
export default InteractiveMap
