import React from 'react'
import { Label, Icon, SemanticCOLORS } from 'semantic-ui-react'

interface Props {
  optionDisplayName: string | number
  customFilterColor?: string
  onRemoveFilter: (categoryId: string | number) => void
  className?: string
  color?: SemanticCOLORS
}

function Filter(props: Props) {
  const { color, optionDisplayName, onRemoveFilter, className } = props
  return (
    <Label color={color ?? 'blue'} circular className={className}>
      {optionDisplayName}
      <Icon name="delete" onClick={onRemoveFilter} data-testid="delete" />
    </Label>
  )
}

export default Filter
