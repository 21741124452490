import { createSlice } from '@reduxjs/toolkit'
import { OrderBy } from 'components/DataTableSlim/DataTableSlim'
import { AppliedCategoryFilters, CustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { Page, SelectedItems } from 'components/DataTableSlim/types'

export interface TableData<CustomFilterValueType = {}> {
  selectedItems: SelectedItems
  page: Page
  searchValue: string
  orderBy: OrderBy
  addedColumns: string[]
  categoryFilters: AppliedCategoryFilters
  customFilters: CustomFilters<CustomFilterValueType>
  categorySorts: Record<string, string>
}

export type TableState = {
  [id: string]: TableData
}

export const defaultValue = {
  selectedItems: {
    ids: [],
    items: [],
    includedIds: undefined,
    excludedIds: undefined,
  },
  page: {
    size: 25,
    number: 1,
  },
  searchValue: '',
  addedColumns: [],
  categoryFilters: {},
  customFilters: {},
  categorySorts: {},
}

const lsTables = localStorage.getItem('dataTables')

const tableIds = [
  'active-learning-pods',
  'alert-history',
  'all-pods',
  'categories',
  'compare',
  'updates',
  'usage-history',
  'availability',
  'sharing',
  'deployment',
  'locations',
  'buildings',
  'renew-step-1',
  'renew-step-2',
  'usb-peripherals',
  'users',
  'users-sharing',
  'realtime',
  'history',
  'solstice-routing-spaces',
  'sr-add-pods',
]


const initialUserState = tableIds.reduce((state, tableId) => {
  state[tableId] = { ...defaultValue }
  return state
}, {})

export const tableSlice = createSlice({
  name: 'table',
  initialState: lsTables ? JSON.parse(lsTables) : {},
  reducers: {
    setOrderBy: (state: TableState, action) => {
      const { user, tableId, columnKey, direction } = action.payload
      state[user][tableId].orderBy = {
        field: columnKey,
        direction,
      }
    },
    setSelectedItems: (state: TableState, action) => {
      const { user, tableId, selectedItems } = action.payload
      state[user][tableId].selectedItems = selectedItems
    },
    setPage: (state: TableState, action) => {
      const { user, tableId, page } = action.payload
      state[user][tableId].page = page
    },
    setSearchValue: (state: TableState, action) => {
      const { user, tableId, searchValue, clearSelectedItemsOnFilter } = action.payload
      state[user][tableId].searchValue = searchValue
      state[user][tableId].page.number = 1
      if (clearSelectedItemsOnFilter) {
        state[user][tableId].selectedItems = defaultValue.selectedItems
      }
    },
    setAddedColumns: (state: TableState, action) => {
      const { user, tableId, columns } = action.payload
      state[user][tableId].addedColumns = columns
    },
    setCustomFilters: (state: TableState, action) => {
      const { user, tableId, filters, clearSelectedItemsOnFilter } = action.payload
      state[user][tableId].page.number = 1
      state[user][tableId].customFilters = filters
      if (clearSelectedItemsOnFilter) {
        state[user][tableId].selectedItems = defaultValue.selectedItems
      }
    },
    setCategoryFilters: (state: TableState, action) => {
      const { user, tableId, filters, clearSelectedItemsOnFilter } = action.payload
      state[user][tableId].page.number = 1
      state[user][tableId].categoryFilters = filters
      if (clearSelectedItemsOnFilter) {
        state[user][tableId].selectedItems = defaultValue.selectedItems
      }
    },
    setCategorySorts: (state: TableState, action) => {
      const { user, tableId, categorySorts } = action.payload
      state[user][tableId].categorySorts = categorySorts
    },
  },
  extraReducers: {
    'user/setUser': (state, action) => {
      const { name: user } = action.payload
      state[user] = state[user] ?? initialUserState
      if (user) {
        tableIds.forEach(tableId => {
          state[user][tableId] = state[user][tableId]
            ? state[user][tableId] : { ...defaultValue }
        })
      }
    },
  },
})

export const {
  setOrderBy,
  setSelectedItems,
  setPage,
  setSearchValue,
  setAddedColumns,
  setCustomFilters,
  setCategoryFilters,
  setCategorySorts,
} = tableSlice.actions

export default tableSlice.reducer
