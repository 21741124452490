import React from 'react'
import { Button, Segment } from 'semantic-ui-react'

interface Props {
  deleteCategory: () => void
  internalName: string
  displayName: string
  modalHide: (cancel: boolean) => void
  podCount: number
  alertFilterCount: number
}

export const DeleteCategoryConfirm = ({
  deleteCategory,
  displayName,
  modalHide,
  podCount,
  alertFilterCount,
}: Props) => {
  const style = {
    segment: {
      height: '300px',
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
    } as React.CSSProperties,
    h3: {
      textAlign: 'center',
    } as React.CSSProperties,
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    } as React.CSSProperties,
    flexy: {
      flex: '1',
    },
    truncateHeader: {
      display: 'inline-block',
      lineHeight: '1em',
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    } as React.CSSProperties,
    truncate: {
      display: 'inline-block',
      paddingTop: '2px',
      lineHeight: '1rem',
      maxWidth: '40%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }

  return (
    <Segment color="blue" style={style.segment}>
      <h3 style={style.h3}>Are you sure you want to delete the
        <p style={style.truncateHeader}>{displayName}</p> category?</h3>
      <p>
        {podCount} display{podCount > 1 && `s`} belong{podCount === 1 && `s`} to the
        <span style={style.truncate}>{displayName}</span> category.
        <br />
        {alertFilterCount || 0} alert filter{alertFilterCount > 1 && `s`} belong
        {alertFilterCount === 1 && `s`} to the <span style={style.truncate}>{displayName}</span> category.
      </p>
      <p>Deleting the category will unapply the category from these pods and remove any associated filters.</p>
      <p>This action can't be undone.</p>
      <p>Display configurations will not be changed.</p>
      <div style={style.flexy} />
      <div style={style.buttons}>
        <Button basic color="grey" content="YES - DELETE" onClick={deleteCategory} style={style.flexy} />
        <Button color="blue" content="NO - CANCEL" onClick={() => modalHide(true)} style={style.flexy} />
      </div>
    </Segment>
  )
}

export default DeleteCategoryConfirm
