import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import styles from './Alerts.module.scss'
import classNames from 'classnames'
import { Filter } from './types'
import { Category } from 'shared/types/categories'

export interface Props {
  categories: Category[]
  filters: Filter[]
  onSelect: (option: any) => any | void
}

export function AlertFilterSelect(props: Props) {
  const handleSelect = (selected: any) => {
    props.onSelect({
      categoryInternalName: selected.category_id,
      optionInternalName: selected.id,
    })
  }

  const categoryTypeOptions = Array.from(
    new Set(props.categories.map(o => o.category_type)),
  )

  return (
    <div>
      {categoryTypeOptions.map(categoryType => {
        const filteredCategories = props.categories.filter(o =>
          o.category_type === categoryType && o.options.length > 0)

        if (filteredCategories.length === 0) {
          return null
        }
        return (
          <div className={styles.categoryRow} key={categoryType}>
            <div className={styles.categoryName}>{categoryType}</div>
            {props.categories
              .filter(o => o.category_type === categoryType)
              ?.map(
                element =>
                  element.options.length > 0 && (
                    <div
                      className={styles.categoryCol}
                      data-testid="dropdown-alert-categories"
                      key={element.id}
                    >
                      <Dropdown
                        name={element.displayName}
                        className={styles.alertFilterDropdown}
                        text={element.displayName ?? undefined}
                        key={element.id ?? undefined}
                        upward={false}
                      >
                        <Dropdown.Menu>
                          {element.options.filter(o => !o.hidden).map(option => (
                            <Dropdown.Item
                              key={option.id ?? undefined}
                              value={option.displayName ?? undefined}
                              className={styles.dropdownItem}
                              onClick={event => {
                                event.stopPropagation() // pop the bubble
                                handleSelect(option)
                              }}
                            >
                              <div
                                data-testid={
                                  props.filters.find(
                                    opt =>
                                      opt.categoryInternalName
                                      === option.category_id
                                      && opt.optionInternalName === option.id,
                                  ) !== undefined
                                    ? 'icon-visible'
                                    : 'icon-NOT-visible'
                                }
                              >
                                {option.displayName}
                                <Icon
                                  name="check"
                                  color="green"
                                  className={classNames(
                                    styles.checkMark,
                                    props.filters.find(
                                      opt =>
                                        opt.categoryInternalName
                                        === option.category_id
                                        && opt.optionInternalName === option.id,
                                    ) !== undefined
                                      ? styles.visible
                                      : styles.invisible,
                                  )}
                                />
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ),
              )}
          </div>
        )
      })}
    </div>
  )
}

export default AlertFilterSelect
