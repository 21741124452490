import { Loader } from 'semantic-ui-react'
import React from 'react'
import styles from './TabLoader.module.scss'

export const TabLoader = () => (
  <div className={styles.spinner}>
    <Loader inline size="huge" active inverted={false}>
      Loading...
    </Loader>
  </div>
)
