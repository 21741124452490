import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'cross-fetch/polyfill'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import {
  ApolloClient,
  ApolloLink,
  split,
  ServerError,
  HttpLink,
  ApolloProvider as ApolloHooksProvider,
} from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import root from 'window-or-global'

import 'semantic-ui-css/semantic.min.css'

import { makeStore } from 'shared/store/store'
import getHistory, { createHistory } from 'shared/core/history'
import {
  configEnv,
  packageName,
  packageVersion,
  graphqlServerUrl,
  hasuraServerUrl,
  hasuraServerWs,
  ldClientId,
} from 'shared/core/config'

import { startGoogleAnalytics, withTracker } from 'shared/core/GoogleAnalytics'
import 'shared/core/hotjar'
import 'shared/core/updater'

import App from './App'
import './index.scss'
import './assets/font.css'
import 'flatpickr/dist/themes/material_blue.css'

import { getToken } from 'shared/services/auth/helpers'
import { logout } from 'shared/core/authenticate'
import { OperationDefinitionNode } from 'graphql'
import uuid from 'shared/core/uuid'

(async () => {
  root.console.log(`${packageName} version ${packageVersion} (${configEnv()})`)

  startGoogleAnalytics()
  // create browser routing history
  createHistory()

  // then make the store
  const store = makeStore()

  store.subscribe(() => {
    // I don't love this becasue it is updating local storage any time any action is dispatched
    // Ideally this would only happen when the table has changed
    localStorage.setItem('dataTables', JSON.stringify(store.getState()?.table))
  })

  const rootElement = document.getElementById('root') as HTMLElement

  const getHeaders = () => {
    const traceId = `kepler-client-${uuid()}`
    return {
      'trace-id': traceId,
      Authorization: `Bearer ${getToken()}`,
    }
  }

  const serverMiddleware = new ApolloLink((operation, forward: any) => {
    operation.setContext({ headers: getHeaders() })

    return forward(operation)
  })

  const controller = new AbortController()
  const { signal } = controller
  const serverLink = new HttpLink({
    uri: graphqlServerUrl(),
    fetchOptions: { signal },
  })

  const hasuraHttp = new HttpLink({
    uri: hasuraServerUrl(),
    fetchOptions: { signal },
  })

  const socket = new SubscriptionClient(hasuraServerWs(), {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      headers: getHeaders(),
    }),
    connectionCallback: err => {
      if (err) {
        /* eslint-disable-next-line no-console */
        console.error('Error Connecting to Subscriptions Server', err)
      }
    },
  })

  const hasuraWs = new WebSocketLink(socket)
  const hasuraLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    serverMiddleware.concat(hasuraWs),
    serverMiddleware.concat(hasuraHttp),
  )

  const requestLink = split(
    operation => operation.operationName.startsWith('h') || operation.operationName.startsWith('_'),
    serverMiddleware.concat(hasuraLink),
    serverMiddleware.concat(serverLink),
  )

  const errorLink = onError(args => {
    const { graphQLErrors, networkError } = args
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        const errorCode = extensions?.code
        if (errorCode === 'invalid-jwt') {
          logout()
        }
        /* eslint-disable-next-line no-console */
        console.log(`[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`)
      })
    }
    if (networkError) {
      if ((networkError as ServerError)?.response?.status === 401) {
        logout()
      }

      if ((networkError as any)?.extensions?.code === 'start-failed' && getToken() !== undefined) {
        // @ts-ignore
        hasuraWs.subscriptionClient.close(false, false)
      }
    }
  })

  const cache = new InMemoryCache({})
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink as any, requestLink]),
    cache,
    connectToDevTools: !!process.env.APOLLO_DEV_TOOLS_ENABLED,
  })

  const LDProvider = await asyncWithLDProvider({
    clientSideID: await ldClientId(),
    user: {
      'key': 'unauthorized',
      'name': 'unauthorized',
    },
  })

  render(
    <LDProvider>
      <ApolloHooksProvider client={client}>
        <Provider store={store}>
          <Router history={getHistory()}>
            <Route component={withTracker(App)} />
          </Router>
        </Provider>
      </ApolloHooksProvider>
    </LDProvider>, rootElement)
})()


