import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import qs from 'qs'

import AlertSettings from './Settings/AlertSettings'
import AlertHistory from './History/AlertHistory'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import styles from './index.module.scss'
import { SolTabs } from 'SolComponents'
import { OptionDate } from 'shared/types/OptionDate'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useDisplayCount } from './Settings/useFiltersDisplayCount'

const tabs = [
  {
    displayName: 'Alerts Settings',
    name: 'settings',
  },
  {
    displayName: 'Alert History',
    name: 'history',
  },
]

const Alert = ({ history }: RouteComponentProps) => {
  const displayCount = useDisplayCount()

  const [currentTab, setCurrentTab] = useState('settings')
  const [optionDate, setOptionDate] = useState<OptionDate>()
  useEffect(() => {
    if (location.search) {
      const tab = qs.parse(location.search.replace('?', '')).tab as string
      if (['settings', 'history'].includes(tab)) {
        setCurrentTab(tab)
      }
    }
  }, [location.search])

  return (
    <div className={styles.wrapper}>
      <SolFilterHeaderCard
        header="Alert Configuration"
        tableId={DataTableInstance.AlertHistory}
        dateRange={{
          include: currentTab === 'history',
          options: {
            fourtyEightHours: false,
            sevenDays: false,
          },
        }}
        subHeaderDisabled
        handleIntervalChange={(period, optDate) => setOptionDate(optDate)}
      />
      <ContentWrapper>
        <SolTabs
          alignment="center"
          menuItems={tabs}
          currentTab={currentTab}
          onTabChange={tab => {
            setCurrentTab(tab)
            history.replace(`?tab=${tab}`)
          }}
        />
        <div className={styles.tab}>
          {currentTab === 'history' && optionDate
            ? <AlertHistory optionDate={optionDate} />
            : <AlertSettings displayCount={displayCount ?? 0}/>
          }

        </div>
      </ContentWrapper>
    </div>
  )
}

export default Alert
