import React, { useState, useEffect } from 'react'
import copyToClipboard from 'copy-to-clipboard'
import CopyIcon from 'mdi-react/ContentCopyIcon'
import { DisplayVersions, UniqueSettingsConfiguration } from 'graphql/__generated__/types'
import styles from './PodNameCard.module.scss'
import { SolStatus, SolInput, SolCard, SolCancelSave, SolPodVersion } from 'SolComponents'
import { useAlerts } from 'shared/hooks/useAlerts'
import { Props } from '../SettingsCards'

export function PodVersionsText({
  versions,
  catchingUp,
}: {
  versions: DisplayVersions
  catchingUp: boolean
}) {
  const { software, hardware } = versions

  const podVersion = (
    <>
      {'Version '}
      <SolPodVersion
        version={software}
        catchingUp={catchingUp}
      />
    </>
  )

  return (
    <>
      {!!hardware && hardware.replace('Pod ', '')}
      {!!hardware && !!software && ' | '}
      {!!software && podVersion}
    </>
  )
}

export const PodNameCard = ({
  status,
  podName,
  isManageable,
  versions,
  catchingUp,
  uniqueSettingsTemplate,
  macAddresses,
  serialId,
  ...props
}: Omit<Props, 'categories'>) => {
  const { showSuccess, showError } = useAlerts()
  const [isSaving, setIsSaving] = useState(false)
  const [latestPodName, setLatestPodName] = useState(podName)
  const podNameDirty = podName !== latestPodName
  useEffect(() => {
    if (podName) {
      setLatestPodName(podName)
    }
  }, [podName])

  const handleSave = async () => {
    setIsSaving(true)
    if (!uniqueSettingsTemplate) {
      showError(`Couldn't update Pod name`)
      setIsSaving(false)
      return
    }
    const uniqueSettingsConfig: UniqueSettingsConfiguration = {
      WELCOME_SCREEN: {
        displayName: latestPodName,
      },
    }
    await props.handleSave({
      ...uniqueSettingsTemplate,
      revision: {
        id: '',
        configuration: {
          ...uniqueSettingsTemplate?.revision?.configuration,
          ...uniqueSettingsConfig,
        },
      },
    })
    setIsSaving(false)
  }

  const copy = (text: string = '') => {
    copyToClipboard(text)
    showSuccess('Copied')
  }

  const cancelDisabled = !podNameDirty || isSaving
  const saveDisabled = !latestPodName || cancelDisabled

  const truncatedName = podName && podName.length > 14
    ? `${podName.slice(0, 14)}...`
    : podName

  return (
    <SolCard className={styles.podNameCard}>
      <div className={styles.nameVersions}>
        <div className={styles.podName}>
          <SolStatus status={status} iconSize="small" containerClassName={styles.statusIcon} />
          <h1>{truncatedName}</h1>
        </div>
        <p className={styles.versions}>
          <PodVersionsText
            versions={versions}
            catchingUp={catchingUp}
          />
        </p>
        <div className={styles.macAddresses}>
          {!!macAddresses.primary && (
            <div>
              <span className={styles.label}>MAC (eth): </span>
              {macAddresses.primary}
              <CopyIcon className={styles.copyIcon} onClick={() => copy(macAddresses.primary)} />
            </div>
          )}
          {!!macAddresses.wifi && (
            <div>
              <span className={styles.label}>MAC (WiFi): </span>
              {macAddresses.wifi}
              <CopyIcon className={styles.copyIcon} onClick={() => copy(macAddresses.wifi)} />
            </div>
          )}
          {!!serialId && (
            <div>
              <span className={styles.label}>Serial: </span>
              {serialId}
              <CopyIcon className={styles.copyIcon} onClick={() => copy(serialId)} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.form}>
        <SolInput
          label="Pod Name"
          value={latestPodName}
          maxLength={32}
          disabled={!isManageable}
          size="large"
          onChange={(e, v) => {
            e?.stopPropagation()
            setLatestPodName(v.value)
          }}
          containerClassName={styles.textInput}
        />
        <SolCancelSave
          cancelDisabled={cancelDisabled}
          acceptingDisabled={saveDisabled}
          cancelClick={() => setLatestPodName(podName)}
          acceptingClick={handleSave}
          className={styles.cancelSave}
        />
      </div>
    </SolCard>
  )
}
