import React, { useState } from 'react'
import { SolCheckbox, SolConfirmationModal, SolInput } from 'SolComponents'
import {
  Template,
  DiscoveryConfiguration,
  PlatformConfigQuery,
  PlatformConfigDocument,
} from 'graphql/__generated__/types'
import styles from './discovery.module.scss'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: DiscoveryConfiguration) => void
}

const numSdsHosts = 2

function Discovery({ updateConfiguration, template }: Props) {
  const configuration = template.revision
    .configuration as DiscoveryConfiguration
  const [
    pendingConfiguration,
    setPendingConfiguration,
  ] = useState<DiscoveryConfiguration | null>(null)

  const tryUpdateConfiguration = (newConfiguration: DiscoveryConfiguration) => {
    if (!newConfiguration.broadcast && !newConfiguration.publishToSds) {
      setPendingConfiguration(newConfiguration)
    } else {
      updateConfiguration(newConfiguration)
    }
  }

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  const setSdsHostname = (index: number, value: string) =>
    updateConfiguration({
      ...configuration,
      sdsHosts: configuration.sdsHosts.map((currValue, currIndex) =>
        (currIndex === index ? { hostname: value } : currValue),
      ),
    })

  return (
    <div className={styles.templateWrapper}>
      <SolConfirmationModal
        isOpen={!!pendingConfiguration}
        modalText="You are disabling all forms of discovery."
        subHeaderText="This will make it more difficult for users to connect to Solstice."
        onAcceptingAction={() => {
          updateConfiguration(pendingConfiguration!)
          setPendingConfiguration(null)
        }}
        onCloseModal={() => setPendingConfiguration(null)}
        acceptingText="Confirm"
        cancelText="Cancel"
      />
      <SolCheckbox
        label="Broadcast display name on network"
        checked={configuration.broadcast}
        onClick={broadcast =>
          tryUpdateConfiguration({ ...configuration, broadcast })
        }
      />
      <SolCheckbox
        label="List display to SDS"
        checked={configuration.publishToSds}
        onClick={publishToSds =>
          tryUpdateConfiguration({ ...configuration, publishToSds })
        }
        note="Default lookup at solsticediscoveryservice.solstice_customer_internal"
      />
      <div className={styles.sdsHosts}>
        {Array.from(Array(numSdsHosts).keys()).map(i => (
          <SolInput
            name={`SDS_Host${i + 1}`}
            key={i}
            label={i === numSdsHosts - 1 && hasElementPods ? `SDS Host ${i + 1}*` : `SDS Host ${i + 1}`}
            value={configuration.sdsHosts?.[i]?.hostname ?? ''}
            disabled={!configuration.publishToSds}
            onChange={(_e, v) => setSdsHostname(i, v.value)}
          />
        ))}
      </div>
      {hasElementPods
        && (
          <div className={styles.spanMargin}>
            <span>* Certain features are not available for Solstice Element.</span>
          </div>
        )
      }
    </div>
  )
}

export default Discovery
