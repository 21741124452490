import React, { useState } from 'react'
import FilterHeader from './FilterHeader'
import { SolAlertMessage, SolButtonGroup } from 'SolComponents'
import LocationCards from './Cards/Cards'
import OverViewMap from './Map/OverViewMap'
import { LocationsTable as PodLocationsTable } from './Table/LocationsTable'
import styles from './Location.module.scss'
import { LatLng } from 'leaflet'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useHasuraFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { buildHasuraSearchFilters } from './Utils/get-hasura-params'
import { BuildingLocationsTable } from './Table/BuildingLocationsTable'
import { useBuildingLocationsVariables } from './Table/useBuildingLocations'
import { ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'
import { Nullable } from 'shared/types/common'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

export interface DisplayLocation {
  id: string | null | undefined
  name: string
  latlng: LatLng
  addressID: number | null | undefined
  city: string
  country: string
  building: string
  nickname: string
  numOfPodsAtAddress: number
  addressLat?: number
  addressLong?: number
  floor?: number
  location?: {
    address: number | null
    floor_count: number | null
  }
}

const Location = React.memo(() => {
  const [tab, setTab] = useState<'pods' | 'buildings'>('pods')
  // Grab filters from table, other sources
  const { selectedItems, page, orderBy, categoryFilters, setCategoryFilters } = useDataTable<DisplayLocation>(
    DataTableInstance.Locations,
  )
  const categories = useHasuraFilters(DataTableInstance.Locations)

  const { searchValue, setSearchValue } = useDataTable<DisplayLocation>(
    tab === 'pods' ? DataTableInstance.Locations : DataTableInstance.Buildings,
  )

  const [visiblePodsBox, setVisiblePodsBox] = useState<number[][] | undefined>(undefined)
  const [toast, setToast] = useState(false)
  const [filterCheckBoxValue, setFilterCheckBoxValue] = useState(false)
  const [mapBounds, setMapBounds] = useState<number[][] | undefined>(undefined)
  const [buildingFloorMap, setBuildingFloorMap] = useState<{
    [key: number]: string
  }>({})
  const [reload, setReload] = useState(false)

  const searchFilters = buildHasuraSearchFilters({
    searchValue,
    orderBy,
    categories,
    selectedItems,
    page,
    visiblePodsBox,
    buildingFloorMap,
    reload,
  })

  const buildingLocationsVariables = useBuildingLocationsVariables({
    visiblePodsBox,
  })

  const buildingLocationsWhereDisplays: ViewDisplaysBoolExp = {
    ...buildingLocationsVariables.whereDisplay,
    location: {
      address: buildingLocationsVariables.where,
    },
  }

  const searchParamsWhere: Nullable<ViewDisplaysBoolExp>
    = tab === 'pods' ? searchFilters.tableFilters.where : buildingLocationsWhereDisplays

  const buttons = [
    {
      primary: tab === 'pods',
      value: 'pods',
      content: 'Pods',
      onClick: () => setTab('pods'),
    },
    {
      primary: tab === 'buildings',
      value: 'buildings',
      content: 'Buildings',
      onClick: () => setTab('buildings'),
    },
  ]
  const tabs = () => (
    <div className={styles.tabs}>
      <SolButtonGroup buttons={buttons} />
    </div>
  )

  return (
    <div className={styles.LocationPage}>
      <FilterHeader
        selectedItems={selectedItems}
        searchParamsWhere={searchParamsWhere}
        currentFilters={categoryFilters}
        filterbyVisiblePods={filterCheckBoxValue}
        clearVisiblePods={() => {
          setVisiblePodsBox(undefined)
          setFilterCheckBoxValue(false)
        }}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        buildingFloorMap={buildingFloorMap}
        setBuildingFloorMap={e => {
          setBuildingFloorMap(e)
          setReload(!reload)
        }}
      />
      <SolAlertMessage message="Successful Change" show={toast} type="success" onDismiss={() => setToast(false)} />
      <ContentWrapper>
        <LocationCards
          searchParamsWhere={searchParamsWhere}
          setCategoryFilters={setCategoryFilters}
          currentFilters={categoryFilters}
        />
        <OverViewMap
          searchParamsWhere={searchFilters.mapFilters}
          categoryFilters={categoryFilters}
          setBoundingBoxFilter={setVisiblePodsBox}
          boundingBoxFilter={visiblePodsBox}
          setMapBounds={setMapBounds}
          mapBounds={mapBounds}
          filterCheckBoxValue={filterCheckBoxValue}
          setFilterCheckBoxValue={setFilterCheckBoxValue}
          buildingFloorMap={buildingFloorMap}
          setBuildingFloorMap={e => {
            setBuildingFloorMap(e)
            setReload(!reload)
          }}
        />
        <div>
          {tab === 'pods' ? (
            <PodLocationsTable searchParamsAll={searchFilters} setToast={setToast} mapBounds={mapBounds} tabs={tabs} />
          ) : (
            <BuildingLocationsTable tabs={tabs} variables={buildingLocationsVariables} />
          )}
        </div>
      </ContentWrapper>
    </div>
  )
})

export default Location
