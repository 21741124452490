import React, { useEffect, useState } from 'react'
import styles from './EditAddMode.module.scss'
import { SolCancelSave, SolInput } from 'SolComponents'
import { CardModal } from '../CardModal/CardModal'
import { BaseAddress, IdNickname } from 'pages/PodConfig/SettingsCards/Map/MapsTypes'
import { useNicknameIsValid } from './useNicknameIsValid'

export interface Props {
  onClose: () => void
  isOpen: boolean
  onAccept: (v: IdNickname) => void
  currentAddress: BaseAddress
}

export const EditAddressModal = ({ currentAddress, isOpen, onAccept, onClose }: Props) => {
  const [nickName, setNickName] = useState<string | undefined | null>(currentAddress?.nickname)
  const { loading, isValid } = useNicknameIsValid(
    nickName ?? '',
    currentAddress?.id,
    currentAddress.external_id ?? '',
  )
  const nickNameIsDirty = nickName !== currentAddress?.nickname

  useEffect(() => {
    setNickName(currentAddress?.nickname)
  }, [isOpen])

  return (
    <>
      <CardModal
        className={styles.modalContent}
        isOpen={isOpen}
        header="Edit building info"
        modalInfo={
          <>
            <h3 className={styles.subHeader}>Building Address</h3>
            <h2 className={styles.subHeaderContent}>{currentAddress?.address_full}</h2>
            <SolInput
              size="large"
              onChange={(_, v) => {
                setNickName(v.value)
              }}
              maxLength={40}
              autoFocus
              label="Building Name"
              value={nickName}
              error={!loading && (!isValid || !nickName)}
              errorText={!loading && !isValid ? 'Building Name already exists. Please enter a new name.' : null}
            />
          </>
        }
        actionButtons={
          <SolCancelSave
            cancelClick={onClose}
            acceptingClick={() => {
              const newIdNickname = {
                id: currentAddress?.id ?? 0,
                nickname: nickName ?? '',
              }
              onAccept(newIdNickname)
            }}
            acceptingDisabled={!isValid || !nickNameIsDirty || !nickName || loading}
          />
        }
        close={onClose}
      />
    </>
  )
}
