import React from 'react'
import { useQuery } from '@apollo/client'
import SolDonutCard from 'SolComponents/SolDonutCard/SolDonutCard'
import { DateRange } from '../TopRow'
import {
  UsageByRoomTypeDocument,
  UsageByRoomTypeQuery,
  UsageByRoomTypeQueryVariables,
} from 'graphql/__generated__/types'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import { roundNumber, toPercentage } from 'shared/core/utils'

type Props = {
  doneLoading: (ready: boolean) => void
  dateRange: DateRange
}

const RoomTypeCard = (props: Props) => {
  const { data, loading } = useQuery<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>(
    UsageByRoomTypeDocument,
    {
      variables: {
        options: {
          fromDate: props.dateRange.fromDate,
          toDate: props.dateRange.toDate,
        },
      },
    },
  )
  const roomTypes = data?.usageByRoomType?.roomTypes ?? []
  const totalMeetingLength: number = roomTypes.reduce((sum, node) => sum + (node?.totalMeetingLength || 0 ), 0)

  const slices: PieSector[] = roomTypes.map(roomType => ({
    name: roomType?.name ?? 'Unassigned',
    count: roundNumber(roomType?.totalMeetingLength),
    percentage: toPercentage(roomType?.totalMeetingLength || 0, totalMeetingLength),
  }))

  return (
    <SolDonutCard
      data={slices}
      isLoading={loading}
      title="Usage by Room Type"
      paging
      url="/compare"
      linkName="CAT COMPARE"
      testId="room-type-card"
    />
  )
}

export default RoomTypeCard
