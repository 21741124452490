import React, { useState } from 'react'
import { SolButton } from 'SolComponents'
import styles from './SolLinkToggle.module.scss'

export type SolLinkToggleOptions = {
  value: string
  text: string
}

export interface Props {
  onChange?: (val: string) => void
  onClick?: () => void
  label?: string
  defaultValue?: string
  options: [SolLinkToggleOptions, SolLinkToggleOptions] // does anyone know how to do tuples in typecript?
}

export const SolLinkToggle = (props: Props) => {
  const [showOptions, setShowOptions] = useState(false)

  const [selected, setSelected] = useState(props.options.find(el => el.value === props.defaultValue))
  const [changeOption, setChangeOption] = useState(props.options.find(el => el.value !== props.defaultValue))

  return (
    <div className={styles.SolLinkToggle}>
      <div className={styles.label}>{props.label} </div>
      <div className={styles.selected} onClick={() => setShowOptions(true)}>
        {selected && selected.text}
      </div>
      <div>
        {showOptions && (
          <SolButton
            basic
            className={styles.button}
            text={changeOption?.text}
            handleClick={() => {
              setSelected(changeOption)
              setChangeOption(props.options.find(el => el.value !== changeOption?.value))
              if (props.onChange) {
                props.onChange(changeOption?.value ?? '')
              }
              setShowOptions(false)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default SolLinkToggle
