import React, { ComponentType } from 'react'
import { Template, CancelPendingMutation, TemplateCompatibility, SettingsGroupType } from 'graphql/__generated__/types'
import styles from './TemplateCards.module.scss'
import { SolExpandableCard, SolTemplateCard } from 'SolComponents'
import classNames from 'classnames'
import { ExecutionResult } from 'graphql'
import { PodSettingsGroup, PodTemplates } from 'pages/Templates/TemplateTypes'
import { MdiReactIconProps } from 'mdi-react'

import ImageIcon from 'mdi-react/ImageIcon'
import EthernetIcon from 'mdi-react/EthernetIcon'
import ServerIcon from 'mdi-react/ServerIcon'
import CalendarTodayIcon from 'mdi-react/CalendarTodayIcon'
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon'
import CityIcon from 'mdi-react/CityIcon'
import SettingsBoxIcon from 'mdi-react/SettingsBoxIcon'
import SecurityIcon from 'mdi-react/SecurityIcon'
import SatelliteIcon from 'mdi-react/SatelliteIcon'
import PowerIcon from 'mdi-react/PowerIcon'
import MessageTextIcon from 'mdi-react/MessageTextIcon'
import StarCircleIcon from 'mdi-react/StarCircleIcon'
import LightbulbIcon from 'mdi-react/LightbulbIcon'
import omit from 'lodash/omit'
import SolWifiIcon from 'SolComponents/Icons/SolWifiIcon'

export type CommonProps = {
  displayName: string
  view: 'Pod' | 'Templates'
  onDuplicateTemplate: () => void
  onSave: (template: Template, saveAsNewTemplate: boolean) => Promise<Template | null>
  podId: string
  isOnline: boolean
  cancelPending: (options: any) => Promise<ExecutionResult<CancelPendingMutation>>
  refetchDisplay: () => void
  notifyUpdate: () => void
  templateCompatibility?: (Partial<TemplateCompatibility> | null)[]
  isManageable?: boolean
  uniqueSettingsTemplate?: Template
  loadingTemplateOptions?: boolean
}

const TemplateCard = (props: {
  className: string
  label: string
  icon: ComponentType<MdiReactIconProps | JSX.Element>
  templateOptions?: Template[]
  templateDiff?: any
  podSettingsGroup?: PodSettingsGroup
  commonProps: CommonProps
}) => {
  return (
    <div className={classNames(styles.templateCard, props.className)}>
      <div
        className={classNames(styles.groupHeader, {
          [styles.unmanageable]: !props.commonProps.isManageable,
        })}
      >
        <props.icon className={styles.templateIcon} />
        <h3>{props.label}</h3>
      </div>
      {props.podSettingsGroup?.template && props.commonProps.isManageable ? (
        <SolTemplateCard
          syncState={props.podSettingsGroup?.syncState}
          isPending={props.podSettingsGroup?.pending}
          template={props.podSettingsGroup?.template}
          templateOptions={props.templateOptions}
          templateDiff={props.templateDiff}
          {...omit(props.commonProps, 'isManageable')}
        />
      ) : (
        <SolExpandableCard disabled />
      )}
    </div>
  )
}

export const TemplateCards = ({
  templates,
  templateOptions,
  commonProps,
  mismatches = [],
}: {
  templates: PodTemplates
  templateOptions: { [key in SettingsGroupType]: Template[] }
  commonProps: CommonProps
  mismatches?: any
}) => {
  return (
    <>
      <TemplateCard
        className="welcomeScreen"
        label="Welcome Screen"
        icon={ImageIcon}
        podSettingsGroup={templates?.welcomeScreen}
        templateOptions={templateOptions[SettingsGroupType.WelcomeScreen]}
        templateDiff={mismatches[SettingsGroupType.WelcomeScreen]}
        commonProps={commonProps}
      />

      <TemplateCard
        className="calendar"
        label="Calendar"
        icon={CalendarTodayIcon}
        podSettingsGroup={templates?.calendar}
        templateOptions={templateOptions[SettingsGroupType.Calendar]}
        templateDiff={mismatches[SettingsGroupType.Calendar]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="features"
        label="Features"
        icon={StarCircleIcon}
        podSettingsGroup={templates?.features}
        templateOptions={templateOptions[SettingsGroupType.Features]}
        templateDiff={mismatches[SettingsGroupType.Features]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="digitalSignage"
        label="Digital Signage"
        icon={SatelliteIcon}
        podSettingsGroup={templates?.digitalSignage}
        templateOptions={templateOptions[SettingsGroupType.DigitalSignage]}
        templateDiff={mismatches[SettingsGroupType.DigitalSignage]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="roomIntelligence"
        label="Room Intelligence"
        icon={LightbulbIcon}
        podSettingsGroup={templates?.roomIntelligence}
        templateOptions={templateOptions[SettingsGroupType.RoomIntelligence]}
        templateDiff={mismatches[SettingsGroupType.RoomIntelligence]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="ethernet"
        label="Ethernet"
        icon={EthernetIcon}
        podSettingsGroup={templates?.ethernet}
        templateOptions={templateOptions[SettingsGroupType.Ethernet]}
        templateDiff={mismatches[SettingsGroupType.Ethernet]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="proxy"
        label="Proxy"
        icon={ServerIcon}
        podSettingsGroup={templates.proxy}
        templateOptions={templateOptions[SettingsGroupType.Proxy]}
        templateDiff={mismatches[SettingsGroupType.Proxy]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="wifi"
        label="Wifi"
        icon={SolWifiIcon}
        podSettingsGroup={templates.wifi}
        templateOptions={templateOptions[SettingsGroupType.Wifi]}
        templateDiff={mismatches[SettingsGroupType.Wifi]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="discovery"
        label="Discovery"
        icon={CrosshairsGpsIcon}
        podSettingsGroup={templates.discovery}
        templateOptions={templateOptions[SettingsGroupType.Discovery]}
        templateDiff={mismatches[SettingsGroupType.Discovery]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="security"
        label="Security"
        icon={SecurityIcon}
        podSettingsGroup={templates.security}
        templateOptions={templateOptions[SettingsGroupType.Security]}
        templateDiff={mismatches[SettingsGroupType.Security]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="timeLocale"
        label="Time/Locale"
        icon={CityIcon}
        podSettingsGroup={templates.timeLocale}
        templateOptions={templateOptions[SettingsGroupType.TimeLocale]}
        templateDiff={mismatches[SettingsGroupType.TimeLocale]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="advanced"
        label="Advanced"
        icon={SettingsBoxIcon}
        podSettingsGroup={templates.advanced}
        templateOptions={templateOptions[SettingsGroupType.Advanced]}
        templateDiff={mismatches[SettingsGroupType.Advanced]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="powerManagement"
        label="Power Management"
        icon={PowerIcon}
        podSettingsGroup={templates.powerManagement}
        templateOptions={templateOptions[SettingsGroupType.PowerManagement]}
        templateDiff={mismatches[SettingsGroupType.PowerManagement]}
        commonProps={commonProps}
      />
      <TemplateCard
        className="messageCenter"
        label="Message Center"
        icon={MessageTextIcon}
        podSettingsGroup={templates?.messageCenter}
        templateOptions={templateOptions[SettingsGroupType.MessageCenter]}
        templateDiff={mismatches[SettingsGroupType.MessageCenter]}
        commonProps={commonProps}
      />
    </>
  )
}
