import { useApolloClient } from '@apollo/client'
import { QueryDisplaysArgs } from 'graphql/__generated__/types'
import { GQLDoc } from 'shared/types/GQLDoc'

const defaultConfig = {
  numRecords: 500,
}

export function useGetAllItemsProper<Result, Item>() {
  const { query: apolloQuery } = useApolloClient()

  return async (
    query: GQLDoc,
    searchOptions: QueryDisplaysArgs,
    totalItems: number,
    resultMapper: (data: Result | undefined) => Item[],
  ) => {
    const { numRecords } = defaultConfig
    const queryOptions = { ...searchOptions, limit: numRecords }
    const numPages = Math.ceil(totalItems / numRecords)

    const queries = Array.from(Array(numPages).keys()).map(page => {
      return apolloQuery<Result, QueryDisplaysArgs>({
        query,
        variables: {
          ...queryOptions,
          options: {
            ...queryOptions?.options,
            page: page + 1,
          },
        },
      })
    })
    const results = await Promise.all(queries)
    return results.map(result => resultMapper(result?.data)).flat()
  }
}
