import React from 'react'
import Layout from 'layout/'
import Redirecter from 'components/Redirecter'
import IEWarning from 'components/IEWarning'
import { useFlags } from 'launchdarkly-react-client-sdk'
import SolStickyBanner from './SolComponents/SolStickyBanner/SolStickyBanner'

function CloudComponents() {
  const {
    outageWarning,
    outageText,
  } = useFlags()

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {outageWarning && (
        <SolStickyBanner
          color="grey"
          text={outageText}
        />
      )}
      <Redirecter/>
      <IEWarning/>
      <Layout/>
    </div>
  )
}
export default CloudComponents
