import React, { ReactElement } from 'react'
import { SolTooltip } from 'SolComponents'
import styles from './index.module.scss'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

type TooltipPosition =
  | 'top left'
  | 'top right'
  | 'bottom right'
  | 'bottom left'
  | 'right center'
  | 'left center'
  | 'top center'
  | 'bottom center'

type Props = {
  message?: string
  triggerElement?: ReactElement
  position?: TooltipPosition
}

export function SolsticeElementCell(props: Props) {
  const {
    message = 'Unavailable for Solstice Element',
    triggerElement = (<SolAlertIcon className={styles.exclamationTriangleElement} />),
    position = 'bottom right',
  } = props
  return (
    <React.Fragment>
      <SolTooltip
        trigger={triggerElement}
        text={[
          message,
        ]}
        position={position}
      />
    </React.Fragment>
  )
}
