import React from 'react'
import styles from './ConfirmationCard.module.scss'

export interface Props {
  selectedPods: number
  totalPods: number
  newExpirationDate: string | undefined
}

const ConfirmationCard = (props: Props) => {
  return (
    <div className={styles.confirmationCard}>
      <div className={styles.confirmationLabel}>
        You have selected {props.selectedPods} of {props.totalPods} for renewal.
      </div>
      <p className={styles.confirmationDetails}>
        New expiration date:
        <span className={styles.confirmationHighlighted}> {props.newExpirationDate}</span>
      </p>
      <p>
        A member of our renewal team will be contacting you shortly.
      </p>
    </div>
  )
}

export default ConfirmationCard
