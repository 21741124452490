import React, { useState, ReactNode, useEffect } from 'react'
// @ts-ignore
import { useDispatch } from 'react-redux'
import Flatpickr from 'react-flatpickr'
import { SolExpandableCard, SolButton, SolInlineProgressBar, SolConfirmationModal } from 'SolComponents'
import { Icon } from 'semantic-ui-react'
import styles from '../Tabs/Task.module.scss'
import { format, formatInTimeZone } from 'date-fns-tz'
import { startOfDay, endOfDay, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { showSuccess } from 'shared/store/slices/alert'
import {
  getNumberOfCompletedPods,
  getNumberOfPodFailures,
  getPodStatus,
  getUpdatePercent,
  getTaskStatus,
  getActivityMessage,
  getTaskCompletionPercentage,
  getPodsGroupedByOffset,
  Pod,
} from './scheduledTask'
import { pluralize } from 'shared/core/utils'
import {
  UpdateScheduledTaskInput,
  ScheduledTask,
  DisplayUpdateTask,
} from 'graphql/__generated__/types'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'
import { UpdateScheduledTaskResult } from '../types'

interface Props {
  onDelete?: () => void
  task: ScheduledTask
  versionUtils: VersionUtils
  updateScheduledTask?: (options: UpdateScheduledTaskInput) => Promise<UpdateScheduledTaskResult>
}

export default function Task({ onDelete = () => {}, task, versionUtils, updateScheduledTask }: Props) {
  const taskStatus = getTaskStatus(task)
  const dispatch = useDispatch()
  const [editOpen, setEditOpen] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [rescheduledDate, setRescheduledDate] = useState(task.startDate)
  useEffect(() => setRescheduledDate(task.startDate), [task.startDate])

  const pods = getPodsGroupedByOffset((task?.displays ?? []) as DisplayUpdateTask[])
  const getSoftwareVersion = versionUtils.getSoftwareVersion
  const earliestStartDate = new Date()

  let overallActivity = 'scheduled to update'
  if (taskStatus === 'completed') {
    overallActivity = 'updated'
  }
  if (taskStatus === 'running') {
    overallActivity = 'updating'
  }
  let numberOfPods: number = taskStatus === 'completed' ? getNumberOfCompletedPods(task) : task.displays?.length ?? 0
  let numberOfErrors: number = getNumberOfPodFailures(task)
  /* eslint-disable-next-line new-cap */
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const startDate
    = task.updateType === 'scheduled'
      ? format(new Date(task.startDate), 'MM/dd/yy')
      : format(utcToZonedTime(new Date(task.startDate), localTimezone), 'MM/dd/yy')
  const startTime
    = task.updateType === 'scheduled'
      ? format(new Date(task.startDate), 'h:mma')
      : format(utcToZonedTime( new Date(task.startDate), localTimezone), 'h:mma')
  let title: string
    = numberOfPods < 1
      ? 'All Pods failed to update on '
      : `
    ${pluralize(numberOfPods, 'Pod')}
    ${overallActivity}
    ${task.data.versionToUpdate ? `to v${getSoftwareVersion(task.data.versionToUpdate)}` : ''} on `
  if (!editOpen) {
    title = title + `${startDate} @ ${startTime}.`
  }
  title = `${title} (${
    task.updateType === 'now'
      ? `${formatInTimeZone( new Date(), localTimezone || 'utc', 'xxxxx')}`
      : 'Pod\'s local time'
  })`

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setEditOpen(!editOpen)
  }

  const rescheduledDateObj = new Date(rescheduledDate)

  const editForm = (
    <div className={styles.editForm}>
      {/*
  // @ts-ignore */}
      <Flatpickr
        options={{
          dateFormat: 'm/d/Y',
          disable: [
            function(date: Date) {
              return date < startOfDay(earliestStartDate)
            },
          ],
        }}
        // @ts-ignore
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        onChange={val => {
          if ( format(startOfDay(earliestStartDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
          === format(startOfDay(val[0]), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')) {
            setRescheduledDate( endOfDay(earliestStartDate) as any)
          } else {
            setRescheduledDate(val[0].toISOString())
          }
        }}
        value={rescheduledDateObj}
      />
      <span>@</span>
      {/*
  // @ts-ignore */}
      <Flatpickr
        // @ts-ignore
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        value={rescheduledDateObj}
        onChange={val => {
          if (!val.length) {
            return
          }
          const date = val[0] > earliestStartDate ? val[0] : earliestStartDate
          setRescheduledDate(date.toISOString())
        }}
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: 'h:i K',
          minDate: earliestStartDate > rescheduledDateObj ? rescheduledDateObj : earliestStartDate,
        }}
      />
    </div>
  )

  const editButton = editOpen ? (
    <div className={styles.buttons}>
      <SolButton text="Back" primary={false} disabled={false} basic color="black" isLink handleClick={handleEdit} />
      <SolButton
        text="Save"
        disabled={false}
        primary
        basic={false}
        isLink={false}
        handleClick={async e => {
          e.stopPropagation()
          if (rescheduledDate !== task.startDate) {
            setEditOpen(false)
            await updateScheduledTask!({
              id: task.id,
              startDate: format(parseISO(rescheduledDate), 'yyyy-MM-dd\'T\'HH:mm:ss'),
            })
            dispatch(showSuccess('Task Updated'))
          }
        }}
      />
      <Icon
        name="trash alternate outline"
        size="large"
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation()
          setDeleteModalIsOpen(true)
        }}
      />
    </div>
  ) : (
    <SolButton text="Edit" primary={false} disabled={false} basic color="blue" isLink handleClick={handleEdit} />
  )

  const renderPodData = (podsGroupedByOffset: { [key: string]: Pod[] }): ReactNode => {
    return Object.keys(podsGroupedByOffset).map((key, i) => (
      <div key={i} className={styles.utcGroup}>
        <span className={styles.utcLabel}>UTC({key})</span>
        {podsGroupedByOffset[key].map((pod, j) => {
          const status = getPodStatus(pod ?? {})
          return (
            <div key={j} className={styles.podData}>
              <span className={styles.podName}>{pod.displayName}</span>
              {status === 'failed' && (
                <div className={styles.progressSection}>
                  <span className={styles.failMessage}>{getActivityMessage(pod)}</span>
                  <Icon color="red" name="exclamation circle" />
                </div>
              )}
              {(status === 'downloading'
                || status === 'installing'
                || status === 'installpending'
                || status === 'pending') && (
                <div className={styles.progressSection}>
                  <SolInlineProgressBar percent={getUpdatePercent(pod)} />
                  <span className={styles.message}>{getActivityMessage(pod)}</span>
                </div>
              )}
              {(status === 'downloading' || status === 'installing' || status === 'installpending') && (
                <span className={styles.percentage}>{getUpdatePercent(pod) + '%'}</span>
              )}
              {status === 'completed' && <Icon color="green" name="check" />}
            </div>
          )
        })}
      </div>
    ))
  }

  return (
    <React.Fragment>
      <SolConfirmationModal
        acceptingText="Delete"
        modalText={<p className={styles.confirmationHeader}>Are you sure you want to delete this task?</p>}
        isOpen={deleteModalIsOpen}
        onCloseModal={() => {
          setDeleteModalIsOpen(false)
        }}
        onAcceptingAction={async () => {
          setDeleteModalIsOpen(false)
          setEditOpen(false)
          await updateScheduledTask!({
            id: task.id,
            cancelledOn: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          })
          dispatch(showSuccess('Task Deleted'))
          onDelete()
        }}
      />
      <SolExpandableCard
        icon={numberOfErrors > 0 ? 'warning' : undefined}
        title={title}
        content={
          <React.Fragment>
            {taskStatus === 'running' && (
              <span className={styles.taskProgress}>
                <SolInlineProgressBar percent={getTaskCompletionPercentage(task)} />
                <span className={styles.taskPercent}>{getTaskCompletionPercentage(task)}%</span>
              </span>
            )}
            {editOpen && editForm}
          </React.Fragment>
        }
        rightActionItems={
          <React.Fragment>
            {numberOfPods >= 1 && taskStatus === 'pending' && task.updateType === 'scheduled' && editButton}
          </React.Fragment>
        }
      >
        <div className={styles.contentWrapper}>{renderPodData(pods)}</div>
      </SolExpandableCard>
    </React.Fragment>
  )
}
