import React, { useEffect } from 'react'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  useLocationsPageQuery,
  useUpdateFloorMutation,
  LocationsPageTableDocument,
} from 'graphql/__generated__/hasura-types'
import { SolConfirmationModal } from 'SolComponents'
import { LocationPageFilters } from '../Utils/get-hasura-params'
import { getSearchIdArgs } from '../../../components/DataTableSlim/Utils/hasuraUtils'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'

export type AreYouSureFloorModalContext = {
  isFloorOpen: boolean
  displayId?: string
  newFloor?: number
  addressId?: number
}

type Props = {
  searchParamsAll: LocationPageFilters
  setToast: (t: boolean) => void
  areYouSureFloorModalContext: AreYouSureFloorModalContext
  setAreYouSureFloorModalContext: (c: AreYouSureFloorModalContext) => void
}

export const ChangeFloorModal = (props: Props) => {
  const searchParams = props.searchParamsAll.tableFilters
  const graphSearchParams = props.searchParamsAll.selectedItemsFilters

  const { setSelectedItems, tableData } = useDataTable<any>(DataTableInstance.Locations)
  const currentSelectedItems = tableData?.selectedItems ?? { ids: [], items: [] }

  const [updateFloor, { loading: updateFloorLoading }] = useUpdateFloorMutation()

  const updateFloorList = (newFloor?: number, addressId?: number) => {
    if (!updateFloorLoading) {
      updateFloor({
        variables: {
          where: whereSelected,
          newFloor,
        },
        refetchQueries: [
          {
            query: LocationsPageTableDocument,
            variables: {
              where: { location: { address_id: { _eq: addressId } } },
            },
          },
        ],
        awaitRefetchQueries: true,
      })
    }
  }

  const { data: locationPageData } = useLocationsPageQuery({
    variables: {
      where: graphSearchParams,
      whereTotal: searchParams.where,
    },
  })

  const selectedDisplayArgs = getSearchIdArgs(currentSelectedItems)
  const whereSelected = {
    _display: {
      _and: [...(searchParams.where?._and ?? []), selectedDisplayArgs],
    },
  }
  const selectedPodCount = locationPageData?.filteredDisplayCount?.aggregate?.count ?? 0

  const deselectAllPods = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  const changeFloor = () => {
    deselectAllPods()
    props.setToast(true)
    updateFloorList(props.areYouSureFloorModalContext.newFloor, props.areYouSureFloorModalContext.addressId)
    props.setAreYouSureFloorModalContext({ isFloorOpen: false })
  }

  // Skip confirmation if count is 1
  useEffect(() => {
    if (props.areYouSureFloorModalContext?.isFloorOpen && selectedPodCount === 1) {
      changeFloor()
    }
  }, [props.areYouSureFloorModalContext?.isFloorOpen, selectedPodCount])

  return (
    <SolConfirmationModal
      modalText={`You are changing the floor of ${selectedPodCount} pods`}
      subHeaderText="Are you sure you want to bulk assign?"
      isOpen={props.areYouSureFloorModalContext.isFloorOpen && selectedPodCount > 1}
      onCloseModal={() => props.setAreYouSureFloorModalContext({ isFloorOpen: false })}
      onAcceptingAction={changeFloor}
      acceptingText="Confirm"
      cancelText="Cancel"
    />
  )
}
