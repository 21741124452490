import React from 'react'
import styles from './EthernetSettings.module.scss'
import { EthernetConfiguration, IpType } from 'graphql/__generated__/types'
import EnabledOptions from '../EnabledOptions/EnabledOptions'
import { SolCheckbox } from 'SolComponents'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import { getDnsHostname, getIpAddress, setDnsHostname, setIpAddress } from 'shared/config/ethernet'
import UniqueSettingsInput, { getTooltipText } from '../../UniqueSettings/UniqueSettingsInput/UniqueSettingsInput'

export interface Props {
  config: EthernetConfiguration
  updateConfig: (config: EthernetConfiguration) => void
  podId?: string
  isDefault: boolean
}

function EthernetSettings({ config, updateConfig, podId, isDefault }: Props) {
  return (
    <div className={styles.ethernetEnabledWrapper}>
      <SolCheckbox
        label="Enable Ethernet"
        bold
        onClick={val => {
          const getVlan = config?.vlans?.map(vlanProp => {
            return {
              ...vlanProp,
              enabled: !val ? val : vlanProp?.enabled,
            }
          })
          updateConfig({
            ...config,
            enableEthernet: val,
            vlans: getVlan,
            networkConfig: {
              ...config.networkConfig,
              ipType: IpType.Dhcp,
            },
          })
        }}
        checked={config?.enableEthernet ?? false}
      />
      {config?.enableEthernet && (
        <div className={styles.networkName}>
          <div className={styles.networkInformation}>
            <ValidationInput
              name="networkName"
              rules={{
                required: config?.enableEthernet === true,
              }}
              label="Network Name"
              required
              maxLength={32}
              value={config.networkName ?? ''}
              onChange={(e, value) =>
                updateConfig({
                  ...config,
                  networkName: value.value,
                })
              }
            />
            <UniqueSettingsInput
              label="DNS Hostname"
              tooltipText={getTooltipText({
                setting: 'DNS Hostname',
                template: 'Ethernet',
              })}
              getValue={getDnsHostname}
              updateConfig={setDnsHostname}
              name="ethernet_dnsHostname"
              podId={podId}
              inline
              placeholder="Optional"
              maxLength={62}
            />
          </div>
          <EnabledOptions
            admin={config?.adminAccess ?? false}
            updateAdmin={admin =>
              updateConfig({
                ...config,
                adminAccess: admin,
              })
            }
            getIpAddress={getIpAddress}
            updateUniqueSettingsConfig={setIpAddress}
            podId={podId}
            isDefault={isDefault}
            section="Ethernet"
            config={config.networkConfig}
            updateConfig={networkConfig =>
              updateConfig({
                ...config,
                networkConfig: networkConfig,
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export default EthernetSettings
