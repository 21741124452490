import React, { useState } from 'react'
import { useUpdateAddressNicknameByIdMutation } from 'graphql/__generated__/hasura-types'
import PencilIcon from 'mdi-react/PencilIcon'
import styles from './LocationsTable.module.scss'
import { SolInput, SolTooltip } from 'SolComponents'
import { SolCloseIcon } from 'SolComponents/Icons'

export const BuildingName = ({ id, name }: { id: number; name: string }) => {
  const [currentValue, setCurrentValue] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [updateBuildingName, { loading: saving }] = useUpdateAddressNicknameByIdMutation({
    variables: {
      id,
      nickname: currentValue,
    },
    onError: err => {
      if (err.graphQLErrors.some(e => e.extensions?.code === 'constraint-violation')) {
        setError(`${currentValue} already exists. Please enter a new name.`)
      }
    },
    update: () => setCurrentValue(null),
  })
  const input = (
    <SolInput
      containerClassName={styles.input}
      value={currentValue}
      size="fluid"
      error={!!error || !currentValue}
      onChange={(_, data) => {
        setCurrentValue(data.value)
        setError(null)
      }}
      onBlur={() => {
        if (!currentValue) {
          setError('Please enter a building name.')
        } else {
          setCurrentValue(null)
          setError(null)
        }
      }}
      disabled={saving}
      onInlineSave={updateBuildingName}
      maxLength={40}
      autoFocus
    />
  )
  const tooltipText = (
    <div className={styles.content}>
      {error}
      <SolCloseIcon className={styles.icon} onClick={() => setError(null)} />
    </div>
  )
  return (
    <div className={styles.name}>
      {currentValue !== null ? (
        <SolTooltip text={tooltipText} trigger={input} disabled={!error} className={styles.tooltip} open />
      ) : (
        <div className={styles.text}>{name}</div>
      )}
      <div className={styles.edit}>
        <PencilIcon
          className={styles.icon}
          onMouseDown={e => {
            setCurrentValue(currentValue ? null : name)
            setError(null)
            e.preventDefault()
          }}
        />
      </div>
    </div>
  )
}
