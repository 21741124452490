import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import qs from 'qs'
import { decode } from 'jsonwebtoken'

import {
  checkInviteStatus,
  setInvitedUserPassword,
  SetPasswordPromise,
  SetPasswordResponse,
} from 'shared/store/actions/invite'

import {
  AuthUserInviteStatus,
  AuthUserInvitePromise,
  AuthUserInviteToken,
  AuthUserInviteStatusInterface,
  AuthUserInvitePasswordInterface,
} from 'shared/services/auth/types'

import { authenticateAuth0 } from 'shared/store/actions/authenticate'
import { externalLinks } from 'shared/core/constants'
const solsticeCloudLoginLogo = require('../../assets/solstice-cloud-login.png')
import styles from './Invite.module.scss'
import { Dispatch } from 'shared/types/redux'

interface InviteProps extends RouteComponentProps {
  checkInviteStatus: (data: AuthUserInviteStatusInterface) => AuthUserInvitePromise
  setInvitedUserPassword: (data: AuthUserInvitePasswordInterface) => SetPasswordPromise
  authenticate: (data: { email: string; password: string }) => Promise<void>
}
interface DispatchFromProps {
  checkInviteStatus: (data: AuthUserInviteStatusInterface) => AuthUserInvitePromise
  setInvitedUserPassword: (data: AuthUserInvitePasswordInterface) => SetPasswordPromise
  authenticate: (data: { email: string; password: string }) => Promise<void>
}

interface InviteState {
  invitee: string
  inviter: string
  jwt: AuthUserInviteToken
  loading: boolean
  newPassword: string
  previouslyAcceptedInvite: boolean
  repeatPassword: string
  settingPassword: boolean
  tokenError: boolean
  settingPasswordError: boolean
  agreeChecked: boolean
  hasUppercase: boolean | null
  hasNumber: boolean | null
  hasLowercase: boolean | null
}

interface QueryParams {
  token: string
}

interface InviteToken {
  invitee: string
  inviter: string
}

const BadInviteLink = () => (
  <Message error>
    <Message.Header style={{ textAlign: 'center' }}>Bad invite link</Message.Header>
    <p>
      Uh oh - something is wrong with the invite link you have followed.
      <br />
      <br />
      If you copy and pasted the link into your browser from an email, please check you included the full URL.
      <br />
      <br />
      If you are still having problems, please contact&nbsp;
      <a href="mailto:cloud@mersive.com">cloud@mersive.com</a> for assistance.
    </p>
  </Message>
)

const PreviouslyAcceptedInvite = () => (
  <p style={{ textAlign: 'center' }}>
    You've already accepted this invite to Solstice Cloud.
    <br />
    <br />
    You can either
    <br />
    <br />
    <Button as={Link} content="Log in" fluid to="/login" />
    <br />
    or
    <br />
    <br />
    <Button as={Link} content="Reset password" fluid to="/reset-password" />
  </p>
)

const SettingPasswordError = () => (
  <Message error>
    <Message.Header style={{ textAlign: 'center' }}>Error setting password</Message.Header>
    <p>
      Uh oh - something went wrong while setting your password.
      <br />
      <br />
      Try refreshing this page and setting your password again.
      <br />
      <br />
      If you are still having problems, please contact&nbsp;
      <a href="mailto:cloud@mersive.com">cloud@mersive.com</a> for assistance.
    </p>
  </Message>
)

class Invite extends React.Component<InviteProps, InviteState> {
  constructor(props: InviteProps) {
    super(props)
    this.state = {
      inviter: '',
      invitee: '',
      jwt: '',
      loading: true,
      newPassword: '',
      previouslyAcceptedInvite: false,
      repeatPassword: '',
      settingPassword: false,
      settingPasswordError: false,
      tokenError: false,
      agreeChecked: false,
      hasUppercase: null,
      hasNumber: null,
      hasLowercase: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAgreeCheckboxToggle = this.handleAgreeCheckboxToggle.bind(this)
    this.setPassword = this.setPassword.bind(this)
    this.shouldDisableSubmit = this.shouldDisableSubmit.bind(this)
  }

  componentDidMount() {
    const showTokenError = () =>
      this.setState((prevState: InviteState) => ({
        ...prevState,
        loading: false,
        tokenError: true,
      }))

    // search string returns form '?key=value, need to trim the ?
    const queryString: string = this.props.location.search.substring(1)

    if (queryString === '') {
      /* eslint-disable-next-line no-console */
      console.log('no query string')
      return showTokenError()
    }

    const queryParams: QueryParams = qs.parse(queryString) as any
    const token: string = queryParams.token

    if (!token) {
      /* eslint-disable-next-line no-console */
      console.log('no token')
      return showTokenError()
    }

    const decodedToken: InviteToken | null = decode(token) as InviteToken

    if (!decodedToken) {
      /* eslint-disable-next-line no-console */
      console.log('invalid token')
      return showTokenError()
    }

    this.setState((prevState: InviteState) => ({
      ...prevState,
      inviter: decodedToken['https://mersive.com/inviter'],
      invitee: decodedToken['https://mersive.com/invitee'],
      jwt: token,
    }))

    this.props
      .checkInviteStatus({ jwt: queryParams.token as string })
      .then(response => {
        const { data } = response
        if (data.status === ('invite sent' as AuthUserInviteStatus)) {
          this.setState((prevState: InviteState) => ({
            ...prevState,
            loading: false,
          }))
        }

        if (data.status === ('current' as AuthUserInviteStatus)) {
          return this.setState((prevState: InviteState) => ({
            ...prevState,
            previouslyAcceptedInvite: true,
            loading: false,
          }))
        }

        if (data.status === ('invalid' as AuthUserInviteStatus)) {
          showTokenError()
        }
      })
      .catch(() => {
        showTokenError()
      })
  }

  handleInputChange(_e: any, data: { value: string }, isNewPassword: boolean) {
    this.setState((prevState: InviteState) => {
      const newPassword = isNewPassword ? data.value : prevState.newPassword
      const repeatPassword = isNewPassword ? prevState.repeatPassword : data.value
      const hasUppercase = /(?=.*[A-Z]).*$/.test(newPassword) || /(?=.*[A-Z]).*$/.test(repeatPassword)
      const hasNumber = /(?=.*[0-9]).*$/.test(newPassword) || /(?=.*[0-9]).*$/.test(repeatPassword)
      const hasLowercase = /(?=.*[a-z]).*$/.test(newPassword) || /(?=.*[a-z]).*$/.test(repeatPassword)
      return {
        ...prevState,
        newPassword,
        repeatPassword,
        hasUppercase,
        hasNumber,
        hasLowercase,
      }
    })
  }

  handleAgreeCheckboxToggle(_e: any, checked: boolean) {
    this.setState({
      agreeChecked: checked,
    })
  }

  setPassword() {
    const { jwt, newPassword: password, invitee: email } = this.state

    const { authenticate: authenticateAction } = this.props

    this.setState(
      (prevState: InviteState) => ({
        ...prevState,
        settingPassword: true,
      }),
      () => {
        this.props
          .setInvitedUserPassword({ jwt, password })
          .then((data: SetPasswordResponse) => {
            if (data.status === 'changed') {
              // success! we've set the password, log them in and redirect them
              authenticateAction({ email, password }).then(() => {
                window.location.href = '/'
              })
            } else {
              this.setState((prevState: InviteState) => ({
                ...prevState,
                settingPassword: false,
                settingPasswordError: true,
              }))
            }
          })
          .catch((_err: any) => {
            this.setState((prevState: InviteState) => ({
              ...prevState,
              settingPassword: false,
              settingPasswordError: true,
            }))
          })
      },
    )
  }

  shouldDisableSubmit() {
    const { agreeChecked, newPassword, repeatPassword, hasUppercase, hasNumber, hasLowercase } = this.state

    const disabled
      = !agreeChecked
      || newPassword.length < 10
      || repeatPassword.length < 10
      || !hasUppercase
      || !hasNumber
      || !hasLowercase
      || newPassword !== repeatPassword

    return disabled
  }

  errorMessage() {
    const { newPassword, repeatPassword, hasUppercase, hasNumber, hasLowercase } = this.state

    if (
      (newPassword.length > 0 && newPassword.length < 10)
      || (repeatPassword.length > 0 && repeatPassword.length < 10)
    ) {
      return 'Password must be at least 10 characters long'
    }
    if (!hasUppercase && hasUppercase !== null) {
      return 'Password does not have an uppercase letter'
    }
    if (!hasNumber && hasNumber !== null) {
      return 'Password does not have a number'
    }
    if (!hasLowercase && hasLowercase !== null) {
      return 'Password does not have a lowercase letter'
    }
    if (newPassword !== repeatPassword) {
      return 'Passwords do not match'
    }

    return ''
  }

  render() {
    const style = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#f3f3f3',
      } as React.CSSProperties,
      input: {
        margin: '0 0 1.5rem',
        minWidth: '24rem',
        width: '30%',
      } as React.CSSProperties,
      group: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1.25rem',
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
      } as React.CSSProperties,
      button: {
        width: '100%',
        marginBottom: '18px',
      } as React.CSSProperties,
      error: {
        color: '#D0021B',
        marginBottom: '18px',
      } as React.CSSProperties,
      p: {
        maxWidth: '350px',
        textAlign: 'center',
      } as React.CSSProperties,
      message: {
        maxWidth: '350px',
      } as React.CSSProperties,
      segment: {
        padding: '25px 60px 10px 60px',
        minHeight: '376px',
        width: '500px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        /* eslint-disable-next-line max-len */
        boxShadow: `${'0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)'}`,
      } as React.CSSProperties,
      checkbox: {
        width: '100%',
        marginBottom: '18px',
        marginLeft: '-13px',
      } as React.CSSProperties,
      feedback: {
        maxWidth: '350px',
        textAlign: 'center',
        color: '#A1403E',
        font: 'Lato Bold',
        size: '14px',
      } as React.CSSProperties,
    }

    const {
      invitee,
      inviter,
      loading,
      previouslyAcceptedInvite,
      settingPassword,
      settingPasswordError,
      tokenError,
      agreeChecked,
    } = this.state

    const disabled = this.shouldDisableSubmit()

    return (
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <Segment style={style.segment} loading={loading}>
            <img draggable={false} className={styles.loginLogo} src={solsticeCloudLoginLogo} />
            {!previouslyAcceptedInvite && !tokenError && !loading && !settingPasswordError && (
              <Form loading={loading}>
                <Form.Group style={style.group}>
                  {!loading && (
                    <React.Fragment>
                      <p style={style.p}>
                        You've been invited to Solstice Cloud by <b>{inviter}</b>
                        <br />
                      </p>
                    </React.Fragment>
                  )}
                  <Form.Input label="Email" name="email" style={style.input} type="email" value={invitee}>
                    <input disabled />
                  </Form.Input>
                  <div className={styles.passwordRequirements}>
                    <span>Please create a password with</span>
                    <ul className={styles.list}>
                      <li>A minimum of 10 characters</li>
                      <li>At least one uppercase character</li>
                      <li>At least one lowercase character</li>
                      <li>At least one number</li>
                    </ul>
                  </div>

                  <Form.Input
                    label="New password"
                    name="password"
                    onChange={(e, data) => this.handleInputChange(e, data, true)}
                    required
                    style={style.input}
                    type="password"
                  >
                    <input autoComplete="new-password" id="password" name="new-password" />
                  </Form.Input>
                  <Form.Input
                    label="Repeat password"
                    name="repeat-password"
                    onChange={(e, data) => this.handleInputChange(e, data, false)}
                    required
                    style={style.input}
                    type="password"
                  >
                    <input autoComplete="new-password" id="repeat-password" name="repeat-password" />
                  </Form.Input>
                  <Form.Checkbox
                    label={
                      <label>
                        <span className={styles.star}>
                          I agree to the&nbsp;
                          <a href={externalLinks.terms} target="_blank" rel="noreferrer">
                            Terms of Service
                          </a>{' '}
                          and have read the&nbsp;
                          <a href={externalLinks.privacy} target="_blank" rel="noreferrer">
                            Privacy Policy
                          </a>
                          .
                        </span>
                      </label>
                    }
                    required
                    style={style.checkbox}
                    checked={agreeChecked}
                    onChange={e => this.handleAgreeCheckboxToggle(e, !agreeChecked)}
                  />
                  <Button
                    content="Create password"
                    disabled={disabled}
                    loading={settingPassword}
                    onClick={this.setPassword}
                    style={style.button}
                    type="submit"
                    primary
                  />
                  {!loading && disabled && <p style={style.feedback}>{this.errorMessage()}</p>}
                </Form.Group>
              </Form>
            )}
            {!loading && tokenError && <BadInviteLink />}
            {!loading && !tokenError && previouslyAcceptedInvite && <PreviouslyAcceptedInvite />}
            {!loading && settingPasswordError && <SettingPasswordError />}
          </Segment>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authenticate: (data: { email: string; password: string }) => dispatch(authenticateAuth0(data)),
  checkInviteStatus: (data: { jwt: string }) => dispatch<any>(checkInviteStatus(data)),
  setInvitedUserPassword: (data: { jwt: string; password: string }) => dispatch<any>(setInvitedUserPassword(data)),
})

export default withRouter(connect<InviteState, DispatchFromProps, any>(null, mapDispatchToProps)(Invite))
