import React from 'react'
import {
  Icon,
  Grid,
} from 'semantic-ui-react'

export const style = {
  pointer: {
    cursor: 'pointer',
  } as React.CSSProperties,
}

export interface Props {
  rewind: () => void
}

export const StepRewind = (
  props: Props,
) => {
  return (
    <Grid
      onClick={props.rewind}
      style={style.pointer}
    >
      <Grid.Column width={1}>
        <Icon
          name="angle left"
          size="large"
        />
      </Grid.Column>
      <Grid.Column width={1}>
        Back
      </Grid.Column>
    </Grid>
  )
}

export default StepRewind
