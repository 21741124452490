import React from 'react'
import HelpCircleIcon from 'mdi-react/HelpCircleIcon'
type Props = {
  className?: string
  size?: 'default' | 'small' | 'large'
  onClick?: () => void
}

const sizeMapper = (size: string) => {
  if (size === 'small') {
    return 20
  }
  if (size === 'large') {
    return 35
  }
  return 25
}

const SolQuestionCircle = (props: Props) => (
  <HelpCircleIcon
    className={props.className}
    size={sizeMapper(props.size ?? 'default')}
    onClick={props.onClick}
  />
)

export default SolQuestionCircle
