import React, { useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { WithHelp } from '../WithHelp/WithHelp'
import { recommendedCategories } from './recommendedCategories'
import { Category } from '../../CategoryTypes'

export interface Props {
  onRecommendedCategoryPicked: (category: Category) => void
  categories: Category[]
}
export const AddRecommendedPickCategory = ({
  onRecommendedCategoryPicked,
  categories,
}: Props) => {
  const [chosenCategory, setChosenCategory] = useState('')

  const handleItemClick = ({}, { name }: { name: string }) => {
    const category: Category = recommendedCategories.find(cat => cat.name === name) as Category
    setChosenCategory(category.id)
    onRecommendedCategoryPicked(category)
  }

  const existingCategoryDisplayNames = categories.map(c => c.name)

  const style = {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
    } as React.CSSProperties,
    h4: {
      marginBottom: '0px',
    } as React.CSSProperties,
    menu: {
      width: '250px',
    },
  }

  const helpText = `Here are a few recommended categories. You can rename them or modify the options
    after creating the category.`

  return (
    <WithHelp
      component={() => (
        <div style={style.wrapper}>
          <Menu style={style.menu} vertical>
            {recommendedCategories.map((category, index) => (
              <Menu.Item
                active={chosenCategory === category.id}
                disabled={existingCategoryDisplayNames.indexOf(category.name ?? '') !== -1}
                key={index}
                name={category.name ?? ''}
                value={category.id}
                onClick={handleItemClick}
              >
                <h4 style={style.h4}>{category.name}</h4>
                <p>{category.caption}</p>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      )}
      helpText={helpText}
    />
  )
}
