import { Input, Button } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'

export interface Props {
  value: string
  maxLength?: number
  autoFocus?: boolean
  renderControls?: boolean
  disabled?: (value: string) => boolean | boolean
  onCancelEdit?: () => void
  onCommitValue?: (value: string) => void
  displayName: string
}


export function InlineEditTextbox(props: Props) {
  const defaultMaxLength = 25

  const [disabled, setDisabled] = useState(false)

  const [maxLength, setMaxLength] = useState(defaultMaxLength)

  const [value, setValue] = useState('')

  const [renderControls, setRenderControls] = useState(true)

  const handleKeypress = (e: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    if (e.key === 'Enter') {
      if (value && props && props.onCommitValue) {
        onValueCommited()
      }
    } else if (e.key === 'Escape') {
      if (props && props.onCancelEdit) {
        props.onCancelEdit()
      }
    }
  }

  const handleValueChange = (e: any) => {
    if (e && e.target && e.target.value !== undefined) {
      setValue(e.target.value)
    }
  }

  const onValueCommited = () => {
    if (props !== undefined && !!props.onCommitValue && !disabled) {
      props.onCommitValue(value)
      setValue('')
    }
  }

  if (props.disabled == null) {
    setDisabled(false)
  }

  useEffect(() => {
    setValue(props.value)

    if (props.maxLength != null) {
      setMaxLength(props.maxLength)
    }

    if (props && props.renderControls === false) {
      setRenderControls(false)
    }
  }, [])

  useEffect(() => {
    if (props && props.value !== undefined && props.disabled !== undefined) {
      if (typeof props.disabled === 'boolean') {
        setDisabled(props.disabled)
      } else {
        setDisabled(props.disabled(value))
      }
    }
  }, [value])

  const setFocus = (ref: Input) => {
    if (ref) {
      if (props.autoFocus) {
        ref.focus()
      }
    }
  }

  const style = {
    renameInput: {
      alignSelf: 'flex-start',
      overflowY: 'visible',
      textAlign: 'center',
      width: '100%',
    },
  }

  return (
    <Input
      fluid
      action
      onKeyUp={handleKeypress}
      onChange={handleValueChange}
      placeholder={props.displayName}
      style={style.renameInput}
      value={value}
      ref={setFocus}
    >
      <input maxLength={maxLength} />
      {renderControls && (
        <React.Fragment>
          <Button icon="cancel" onClick={props.onCancelEdit} tabIndex={-1} />
          <Button color="blue" icon="checkmark" onClick={onValueCommited} tabIndex={-1} disabled={disabled} />
        </React.Fragment>
      )}
    </Input>
  )
}
