import React from 'react'

interface Props {
  url: string
  label: string
}

export default function SolLink(props: Props) {
  return (
    <a href={props.url} target="_blank" rel="noreferrer">
      {props.label}
    </a>
  )
}
