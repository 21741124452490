import React from 'react'
import { IpType, NetworkConfig, UniqueSettingsConfiguration } from 'graphql/__generated__/types'
import { SolButtonGroupProps, SolCheckbox, SolButtonGroup, SolNotification, SolTooltip } from 'SolComponents'
import StaticOptions from '../../Common/StaticOptions'
import styles from '../ethernet.module.scss'

export interface Props {
  admin: boolean
  config: NetworkConfig
  updateConfig: (config: NetworkConfig) => void
  updateAdmin: (admin: boolean) => void
  section: 'Ethernet' | string
  getIpAddress: (uniqueSettings: UniqueSettingsConfiguration) => string
  updateUniqueSettingsConfig: (config: UniqueSettingsConfiguration, value: any) => UniqueSettingsConfiguration
  podId?: string
  isDefault?: boolean
}

export default function EnabledOptions({
  admin,
  config,
  updateConfig,
  updateAdmin,
  section,
  getIpAddress,
  updateUniqueSettingsConfig,
  podId,
  isDefault,
}: Props) {
  const IpTypeButtons: SolButtonGroupProps = [
    {
      primary: config?.ipType === IpType.Dhcp,
      value: IpType.Dhcp,
      content: 'DHCP',
      onClick: (value: any) => {
        updateConfig({
          ...config,
          ipType: value,
        })
      },
    },
    {
      disabled: isDefault,
      primary: config?.ipType === IpType.Static,
      value: IpType.Static,
      content: 'Static IP',
      onClick: (value: any) => {
        updateConfig({
          ...config,
          ipType: value,
        })
      },
    },
  ]

  return (
    <div>
      <SolCheckbox
        label="Allow admin configuration access"
        notification={<SolNotification type="menu" notifications="Gen 2i+" />}
        checked={admin}
        onClick={updateAdmin}
      />
      <div>
        {isDefault ? (
          <SolTooltip
            hoverable
            position="top left"
            trigger={<SolButtonGroup buttons={IpTypeButtons} />}
            /* eslint-disable-next-line max-len */
            text="Static IP is not available for default templates. Create a custom template if you need to use static IPs."
            isInline
            className={styles.staticIpTooltip}
          />
        ) : (
          <SolButtonGroup buttons={IpTypeButtons} />
        )}
      </div>
      {config?.ipType === IpType.Static && (
        <StaticOptions
          section={section}
          networkConfig={config}
          updateNetworkConfig={(c: NetworkConfig) => {
            updateConfig(c)
          }}
          getIpAddress={getIpAddress}
          updateUniqueSettingsConfig={updateUniqueSettingsConfig}
          podId={podId}
        />
      )}
    </div>
  )
}
