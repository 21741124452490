import { useEffect } from 'react'
import { HeatLayer } from 'leaflet'
import 'leaflet.heat'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import './styles/MarkerCluster.css'
import { useLeaflet } from 'react-leaflet'
import { MarkerObject } from './MarkerObjectTypes'
import { toLatLngAltitude } from './helpers'

type Props = {
  markersList: MarkerObject[]
  showCluster: boolean
  heatMapCluster: HeatLayer
}

function HeatMapCluster(props: Props) {
  const { map } = useLeaflet()

  useEffect(() => {
    if (map) {
      if (!map.hasLayer(props.heatMapCluster)) {
        map.addLayer(props.heatMapCluster)
      }
      if (typeof props.heatMapCluster?.setLatLngs === 'function') {
        props.heatMapCluster.setLatLngs(
          props.showCluster ? toLatLngAltitude(props.markersList) : [],
        )
      }
    }
  }, [props.markersList, props.showCluster])

  return null
}

export default HeatMapCluster
