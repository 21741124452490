import React, { useEffect, useState } from 'react'
import styles from './WelcomeScreen.module.scss'
import WelcomeScreenOptions from './WelcomeScreenOptions'
import WelcomeScreenPreview from './Preview/WelcomeScreenPreview'

import {
  Template,
  WelcomeScreenConfiguration,
  WelcomeScreenImage,
  HdmiOutputModeType, PlatformConfigQuery, PlatformConfigDocument,
} from 'graphql/__generated__/types'
import { useDefaultImages } from 'shared/hooks/management/useTemplates'
import { SolGrid, SolColumn, SolRow, SolButton, SolConfirmationModal } from 'SolComponents'
import { Header } from 'semantic-ui-react'
import { useQuery } from '@apollo/client'

export interface Props {
  template: Template
  view: 'Templates' | 'Pod'
  updateConfiguration: (newSettings: WelcomeScreenConfiguration) => void
  saveTemplate: (e: React.MouseEvent<HTMLButtonElement>, configuration: any) => void
}

const modernWarning = (
  <React.Fragment>
    <p>To switch back to the Classic Welcome Screen, you will need to do the following: </p>
    <ol className={styles.modernList}>
      <li>Delete the Pod from Solstice Cloud</li>
      <li>Using Solstice Dashboard, change the Welcome Screen back to Classic</li>
      <li>Onboard the Pod again to Solstice Cloud</li>
    </ol>
  </React.Fragment>
)

function WelcomeScreen({ template, updateConfiguration, saveTemplate, view }: Props) {
  const configuration = template.revision.configuration as WelcomeScreenConfiguration
  const defaultImages = useDefaultImages()
  const [classicModalIsOpen, setClassicModalIsOpen] = useState(false)
  const images = configuration?.images ?? []
  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).data?.platformConfig?.hasElementPods

  /*
      We need to make sure if the config doesn't contain default images (or is a new template)
      that we apply default images to the template. The template should always contain 6 image slots,
      with two images per slot
    */
  useEffect(() => {
    if (defaultImages && (!configuration.images || configuration.images.length < 6)) {
      const updatedImages = Object.keys(defaultImages).reduce((acc: WelcomeScreenImage[], currentIndex: string) => {
        if (!acc[currentIndex]) {
          const position = parseInt(currentIndex)
          const image: WelcomeScreenImage = {
            enabled: false,
            position: position,
            _16x9: {
              hash: defaultImages[position]._16x9.hash,
            },
            _32x9: {
              hash: defaultImages[position]._32x9.hash,
            },
          }
          acc[currentIndex] = image
        }
        return acc
      }, configuration.images || []) as WelcomeScreenImage[]

      if (!updatedImages.some(i => i.enabled)) {
        updatedImages[0].enabled = true
      }

      updateConfiguration({
        ...configuration,
        images: updatedImages,
      })
    }
  }, [defaultImages])

  function checkImage(): (position: number, checked: boolean) => void {
    return (position, checked) => {
      if (!checked && images.filter(i => i?.position !== position).every(i => !i?.enabled)) {
        return
      }
      const updatedImage = {
        ...images[position],
        enabled: checked,
      }
      updateConfiguration({
        ...configuration,
        images: Object.assign([...images], {
          [position]: updatedImage,
        }),
      })
    }
  }

  function imageUpload(): (hash: string, position: number, hdmiOutput: HdmiOutputModeType) => void {
    return (hash, position, hdmiOutputMode) => {
      let updatedImages = [...images] as WelcomeScreenImage[]
      updatedImages[position] = {
        ...updatedImages[position],
        position,
        enabled: updatedImages?.[position]?.enabled || false,
        _16x9:
          hdmiOutputMode === HdmiOutputModeType.Single || hdmiOutputMode === HdmiOutputModeType.Mirror
            ? {
              hash,
            }
            : updatedImages[position]._16x9,
        _32x9:
          hdmiOutputMode !== HdmiOutputModeType.Single && hdmiOutputMode !== HdmiOutputModeType.Mirror
            ? {
              hash,
            }
            : updatedImages[position]._32x9,
      }
      updateConfiguration({
        ...configuration,
        images: updatedImages,
      })
    }
  }

  const classicWarning = (
    <div className={styles.classicWarning}>
      <div className={styles.warningText}>Classic Welcome Screen is not supported in Solstice Cloud.</div>
      <SolButton
        text="SWITCH TO MODERN WELCOME SCREEN"
        primary
        handleClick={() => {
          setClassicModalIsOpen(true)
        }}
      />
      <SolConfirmationModal
        isOpen={classicModalIsOpen}
        modalText="This action will require Dashboard to revert."
        subHeaderText={modernWarning}
        onAcceptingAction={e => {
          setClassicModalIsOpen(false)
          saveTemplate(e, {
            ...configuration,
            isModern: true,
          })
        }}
        onCloseModal={() => setClassicModalIsOpen(false)}
        acceptingText="Ok, got it"
        cancelText="Cancel"
      />
    </div>
  )

  const welcomeScreenTemplate = (
    <div className={styles.templateWrapper}>
      <SolGrid>
        <SolRow>
          <SolColumn>
            <div className={styles.thumbnail}>
              <Header size="tiny" color="grey">
                [<i>Thumbnail Preview</i>]
              </Header>
            </div>
          </SolColumn>
        </SolRow>
        <SolRow>
          <SolColumn className={styles.previewWrapper} xl={6} lg={7} sm={12}>
            {configuration && configuration.images && configuration?.images?.length && (
              <WelcomeScreenPreview
                settings={configuration}
                onImageUpload={imageUpload()}
                onCheckImage={checkImage()}
              />
            )}
          </SolColumn>
          <WelcomeScreenOptions template={template} setter={updateConfiguration} hasElementPods={hasElementPods} />
        </SolRow>
        {hasElementPods
          && (
            <SolRow className={styles.spanMargin}>
              <span>* Certain features are not available for Solstice Element.</span>
            </SolRow>
          )
        }
      </SolGrid>
    </div>
  )

  return view === 'Pod' && !configuration.isModern ? classicWarning : welcomeScreenTemplate
}

export default WelcomeScreen
