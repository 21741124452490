import React from 'react'
import Flatpickr from 'react-flatpickr'
import FpType from 'flatpickr'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import { format, startOfDay, getTime } from 'date-fns'
import classNames from 'classnames'
import styles from './TimeInterval.module.scss'
import { ChartTimePeriod } from '../types'
import { TimeInterval } from 'graphql/__generated__/types'
import { useTimeInterval } from './TimeInterval.hook'
import { SolButtonGroupProps } from 'SolComponents/SolButtonGroup/SolButtonGroup'
import { SolButtonGroup } from 'SolComponents'
import { OptionDate } from 'shared/types/OptionDate'

export interface DateRangeOptions {
  fourtyEightHours?: boolean
  sevenDays?: boolean
  thirtyDays?: boolean
  ninetyDays?: boolean
  customStart?: boolean
  custom?: boolean
}

export interface Props {
  timePeriod: ChartTimePeriod
  interval: TimeInterval
  setTimePeriod: (interval: ChartTimePeriod, range?: OptionDate) => void
  setInterval: (interval: TimeInterval) => void
  testId?: string
  options?: DateRangeOptions
}

export const rangeDateFormat = 'MMM dd yyyy'

const TimeIntervalMenu = (props: Props) => {
  const {
    timePeriod,
    testId,
    options: {
      fourtyEightHours = true,
      sevenDays = true,
      thirtyDays = true,
      ninetyDays = true,
      customStart = true,
      custom = true,
    } = {},
  } = props

  const {
    range,
    calendarRef,
    onCalendarChange,
    onCalendarClose,
    onHours,
    onWeek,
    onMonth,
    onQuarter,
    onCustom,
    setPreviousDate,
    nextDate,
    isCustomIntervalActive,
    openOnCalLabel,
  } = useTimeInterval(props)

  const nextCSS = () =>
    classNames(styles.icon, {
      [styles.disabled]: getTime(startOfDay(new Date(range.toDate))) === getTime(startOfDay(new Date())),
    })

  const prevCSS = () =>
    classNames(styles.icon, {
      [styles.disabled]:
      getTime(startOfDay(new Date(range.fromDate))) <= getTime(startOfDay(new Date(options.minDate))),
    })

  const options = {
    maxDate: new Date(),
    mode: 'range' as any,
    altInputClass: 'hide',
    dateFormat: 'M d Y',
    minDate: new Date('2018-01-01 00:00:01'),
    onReady: function(_: any, _1: any, el: FpType.Instance) {
      el.calendarContainer.classList.add('calendarChartHeader')
    },
  }

  const timeButtons: SolButtonGroupProps = []
  if (fourtyEightHours) {
    timeButtons.push({
      primary: timePeriod === ChartTimePeriod.HOURS,
      value: '48hrs',
      content: '48 hrs',
      onClick: onHours,
    })
  }
  if (sevenDays) {
    timeButtons.push({
      primary: timePeriod === ChartTimePeriod.WEEK,
      value: '7days',
      content: '7 days',
      onClick: onWeek,
    })
  }
  if (thirtyDays) {
    timeButtons.push({
      primary: timePeriod === ChartTimePeriod.MONTH,
      value: '30days',
      content: '30 days',
      onClick: onMonth,
    })
  }
  if (ninetyDays) {
    timeButtons.push({
      primary: timePeriod === ChartTimePeriod.QUARTER,
      value: '90days',
      content: '90 days',
      onClick: onQuarter,
    })
  }
  if (custom) {
    timeButtons.push({
      primary: timePeriod === ChartTimePeriod.CUSTOM,
      value: 'custom',
      content: 'Custom',
      onClick: onCustom,
    })
  }

  const fromDate = format(new Date(range.fromDate), rangeDateFormat)
  const toDate = format(new Date(range.toDate), rangeDateFormat)
  return (
    <div className={styles.container} data-testid={testId}>
      <SolButtonGroup buttons={timeButtons} className={styles.buttonGroup} />
      <div
        className={classNames(styles.indicators, { [styles.calendarActive]: isCustomIntervalActive })}
        data-testid="date-range-picker"
        onClick={openOnCalLabel}
      >
        {!isCustomIntervalActive && customStart && (
          <ChevronLeftIcon
            className={prevCSS()}
            onClick={setPreviousDate}
            data-testid="left-arrow"
          />
        )}
        {(customStart || isCustomIntervalActive) && `${fromDate} to ${toDate}`}
        {!isCustomIntervalActive && customStart && (
          <ChevronRightIcon
            className={nextCSS()}
            onClick={nextDate}
            data-testid="right-arrow"
          />
        )}
      </div>

      <div className={classNames(styles.calendarContainer, { [styles.calendarHide]: !isCustomIntervalActive })}>
        <Flatpickr
          data-testid="calendar"
          value={[new Date(range.fromDate), new Date(range.toDate)]}
          options={options as any}
          ref={calendarRef}
          onChange={onCalendarChange}
          onClose={onCalendarClose}
        />
      </div>
    </div>
  )
}

export default TimeIntervalMenu
