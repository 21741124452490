import React, { useEffect, useState } from 'react'
import { useCards } from './useCards.hook'
import { useMobilityCards } from './useMobilityCards.hook'
import { ChartTimePeriod } from 'SolComponents/SolChart/types'
import { getPreviousDate } from 'SolComponents/SolChart/chartUtils'
import { format, endOfDay, differenceInDays, parseISO } from 'date-fns'
import { PlatformConfigDocument, PlatformConfigQuery, TimeInterval } from 'graphql/__generated__/types'
import { useHasuraFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import DailyActiveChart from './DailyActiveChart'
import UserMobilityChart from './UserMobilityChart'
import ActivityUserMobilityChart from './ActivityUserMobilityChart'
import styles from './index.module.scss'

import DailyActiveUsers from './DailyActiveUsers'
import LoadingBar from 'components/Loaders/LoadingBar'
import MostCollaborativeSpaces from './MostCollaborativeSpaces'
import TopTenCollaborativeRooms from './TopTenCollaborativeRooms'
import { MostCollaborativeSpacesQuery, ViewMostCollaborativeSpacesResult } from 'graphql/__generated__/hasura-types'
import UserCollaboration from './UserCollaboration'
import { OptionDate } from 'shared/types/OptionDate'
import { SolFilterHeaderCard } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useQuery } from '@apollo/client'
import SolElementInfoMessage from '../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'

const TABLET_BREAKPOINT = 992

const getInterval = (intervalArg: string) => {
  let timeInterval: TimeInterval = TimeInterval.Day
  if (intervalArg === 'week') {
    timeInterval = TimeInterval.Week
  }
  if (intervalArg === 'month') {
    timeInterval = TimeInterval.Month
  }
  if (intervalArg === 'day') {
    timeInterval = TimeInterval.Day
  }
  return timeInterval
}

const UserTrendsPage = () => {
  const [selectedLocation, setSelectedLocation] = useState<ViewMostCollaborativeSpacesResult | null>(null)
  const [screenWidth, setScreenWidth] = useState<Number>(window.innerWidth)
  const [timeInterval, setTimeInterval] = React.useState<TimeInterval>(TimeInterval.Day)
  const [timePeriod, setTimePeriod] = React.useState<ChartTimePeriod>(ChartTimePeriod.MONTH)
  const [optionDate, setDate] = React.useState({
    fromDate: format( getPreviousDate(timePeriod, new Date()), 'yyyy-MM-dd HH:mm:ssXXX'),
    toDate: format( endOfDay( new Date() ), 'yyyy-MM-dd HH:mm:ssXXX'),
    interval: timeInterval?.toLowerCase(),
  })

  const onResize = () => setScreenWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const handleChangeTimePeriod = (period: ChartTimePeriod, range: OptionDate) => {
    const from = new Date(range?.fromDate || 0)
    const to = new Date(range?.toDate || 0)
    const difference = differenceInDays(to, from)
    let timeIntervalArg = timeInterval.toLowerCase()
    if (period === ChartTimePeriod.QUARTER) {
      timeIntervalArg = 'week'
    }
    if (period === ChartTimePeriod.MONTH) {
      timeIntervalArg = 'day'
    }
    if (period === ChartTimePeriod.CUSTOM) {
      if (difference > 90) {
        timeIntervalArg = 'month'
      } else {
        if (difference > 30) {
          timeIntervalArg = 'week'
        } else {
          timeIntervalArg = 'day'
        }
      }
    }
    if (range) {
      setDate({
        fromDate: range.fromDate,
        toDate: range.toDate,
        interval: timeIntervalArg,
      })
    }
    setTimeInterval(getInterval(timeIntervalArg))
    setTimePeriod(period)
  }

  const appliedCategories = useHasuraFilters(DataTableInstance.UsersSharing)
  const args = {
    from_date: optionDate.fromDate,
    to_date: optionDate.toDate,
    di: 'day',
    categories: appliedCategories,
  }
  const {
    dailyActiveUsers,
    isDailyActiveUsersLoading,
    sessionsPerUser,
    loadingSessionsPerUser,
    hoursPerUser,
    loadingHoursPerUser,
    roomsVisited,
    loadingRoomsVisited,
    usersCount,
    loadingUsersCount,
    usersCollaboration,
    loadingUsersCollaboration,
    mostCollaborativeSpaces: collaborativeSpaces,
    loadingMostCollaborativeSpaces,
    loadingPods,
    totalDisplaysResult,
  } = useCards(args)

  const argsToDate = parseISO(args?.to_date)
  const mobilityArgs = {
    from_date: args.from_date,
    to_date: argsToDate,
    categories: args.categories,
  }
  const { activeUserMobility, isActiveUserMobilityLoading } = useMobilityCards(mobilityArgs)
  const { toDate } = optionDate
  const isLoading
    = isDailyActiveUsersLoading
    || loadingSessionsPerUser
    || loadingHoursPerUser
    || loadingRoomsVisited
    || loadingUsersCount
    || isActiveUserMobilityLoading
    || loadingUsersCollaboration
    || loadingMostCollaborativeSpaces
    || loadingPods

  const isMobile = screenWidth <= TABLET_BREAKPOINT

  const mostCollaborativeSpaces: MostCollaborativeSpacesQuery | undefined = {
    ...collaborativeSpaces,
    _search_most_collaborative_spaces:
      collaborativeSpaces?._search_most_collaborative_spaces?.reduce((currentList, pod) => {
        if (currentList.some(currentPod => currentPod.display_id === pod.display_id)) {
          return currentList
        }
        return [...currentList, pod]
      }, [] as ViewMostCollaborativeSpacesResult[]) || [],
  }

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument).
    data?.
    platformConfig?.
    hasElementPods

  return (
    <div className="user-sharing">
      <LoadingBar visible={isLoading} header />
      <SolFilterHeaderCard
        header="User Trends"
        filteredItems={totalDisplaysResult}
        tableId={DataTableInstance.UsersSharing}
        dateRange={{
          include: true,
          options: {
            fourtyEightHours: false,
            sevenDays: false,
          },
        }}
        setTimeInterval={setTimeInterval}
        timeInterval={timeInterval}
        handleIntervalChange={handleChangeTimePeriod}
      />
      <ContentWrapper>
        <DailyActiveChart
          dataTestId="daily-active-chart"
          data={dailyActiveUsers || []}
          interval={getInterval('day')}
          toDate={new Date(toDate)}
          isLoading={isDailyActiveUsersLoading}
          showSingleResult={!isMobile}
          params={args}
          sessionsPerUser={sessionsPerUser}
          loadingSessionsPerUser={loadingSessionsPerUser}
          hoursPerUser={hoursPerUser}
          loadingHoursPerUser={loadingHoursPerUser}
          roomsVisited={roomsVisited}
          loadingRoomsVisited={loadingRoomsVisited}
          usersCount={usersCount}
          loadingUsersCount={loadingUsersCount}
        />
        {isMobile && (
          <div className={styles.row}>
            <DailyActiveUsers
              sessionsPerUser={sessionsPerUser}
              loadingSessionsPerUser={loadingSessionsPerUser}
              hoursPerUser={hoursPerUser}
              loadingHoursPerUser={loadingHoursPerUser}
              roomsVisited={roomsVisited}
              loadingRoomsVisited={loadingRoomsVisited}
              usersCount={usersCount}
              loadingUsersCount={loadingUsersCount}
            />
            <UserCollaboration userCollaboration={usersCollaboration} loading={loadingUsersCollaboration} />
            <TopTenCollaborativeRooms
              mostCollaborativeSpaces={mostCollaborativeSpaces}
              loadingMostCollaborativeSpaces={loadingMostCollaborativeSpaces}
              selectedLocation={selectedLocation}
              setSelectedLocation={(location: ViewMostCollaborativeSpacesResult) => setSelectedLocation(location)}
            />
          </div>
        )}
        <div className={styles.row}>
          {!isMobile ? (
            <UserCollaboration userCollaboration={usersCollaboration} loading={loadingUsersCollaboration} />
          ) : null}
          <div className={styles.mostCollaborativeSpaces}>
            <MostCollaborativeSpaces
              mostCollaborativeSpaces={mostCollaborativeSpaces}
              loadingMostCollaborativeSpaces={loadingMostCollaborativeSpaces}
              selectedLocation={selectedLocation}
              setSelectedLocation={(location: ViewMostCollaborativeSpacesResult) => setSelectedLocation(location)}
              showTopTen={!isMobile}
            />
          </div>
        </div>
        <div className={`${styles.row}, ${styles.mobility}`}>
          <div className={styles.column}>
            <UserMobilityChart
              dataTestId="user-mobility-chart"
              data={activeUserMobility || []}
              isLoading={isActiveUserMobilityLoading}
            />
          </div>
          <div className={styles.column}>
            <ActivityUserMobilityChart
              dataTestId="activity-user-mobility-chart"
              data={activeUserMobility || []}
              isLoading={isActiveUserMobilityLoading}
            />
          </div>
        </div>
      </ContentWrapper>
      {hasElementPods
        && (
          <div className={styles.elementMessageContainer}>
            <SolElementInfoMessage
              message="Solstice Element pods are not included"
            />
          </div>
        )
      }
    </div>
  )
}

export default UserTrendsPage
