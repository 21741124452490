import React from 'react'
import Header from './Header'
import WithMenuAuthenticated from './WithMenuAuthenticated'
import { publicRoutes, noMenuRoutes } from '../routes/routes'
import { Route } from 'react-router-dom'
import styles from './Layout.module.scss'
import { RouteComponentProps } from 'react-router'
import { DispatchProp } from 'react-redux'
import { AuthWrapper } from 'components/RequireAuth/AuthWrapper'
import ConnectedMaintenanceBanner from 'pages/Maintenance'
import UpdatesBanner from 'components/UpdatesBanner'
import { useFlags } from 'launchdarkly-react-client-sdk'
import EndOfLife from '../components/EndOfLife'
export interface Props extends DispatchProp<any>, RouteComponentProps<any> {
  activeItem: string
  path: string
  isMersive: boolean
}

const Layout = (props: Props) => {
  const { createOrg, eolBanner } = useFlags()
  const publicRoute = publicRoutes({ createOrg })
    .filter(({ enabled = true }) => enabled)
    .find(route => route.path === props.location.pathname)
  const noMenuRoute = noMenuRoutes().find(route => route.path === props.location.pathname)

  return (
    <>
      {publicRoute ? (
        <>
          {props.location.pathname !== '/active-learning-app' && <Header />}
          <div className={styles.parent}>
            <Route path={publicRoute.path} component={publicRoute.component} />
          </div>
        </>
      ) : (
        <AuthWrapper {...props}>
          <UpdatesBanner />
          <ConnectedMaintenanceBanner />
          {/* Use the LaunchDarkly flag eol-banner */}
          {eolBanner ? <EndOfLife/> : ''}
          <Header />
          <div className={styles.parent}>
            {noMenuRoute ? (
              <div className={styles.fullScreen}>
                <Route exact={noMenuRoute.exact} path={noMenuRoute.path} component={noMenuRoute.component} />
              </div>
            ) : (
              <WithMenuAuthenticated {...props} />
            )}
          </div>
        </AuthWrapper>
      )}
    </>
  )
}

export default Layout
