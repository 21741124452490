import authService, {
  AuthServiceApi,
} from './authServiceApi'

import {
  AuthRestInterface as AuthRestType,
  AuthServiceApiInterface as AuthServiceType,
  AuthCredentialInterface as AuthCredentialType,
} from './types'

import mockApiService from './__mocks__/authService.mock'

export type AuthRestInterface = AuthRestType
export type AuthServiceApiInterface = AuthServiceType
export type AuthCredentialInterface = AuthCredentialType

export {
  AuthServiceApi,
  mockApiService,
}

export default authService
