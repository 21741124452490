import React, { useEffect, useRef, useState } from 'react'
import { Progress } from 'semantic-ui-react'

import styles from './SolProgressBar.module.scss'

export interface Props {
  percent: number
  label?: string
}

const getTextAlign = (lessThanZero: boolean, overflow: boolean) => {
  if (lessThanZero) {
    return 'left'
  }
  if (overflow) {
    return 'right'
  }
  return 'center'
}

const ARROW_WIDTH = 10

const getMarginLeft = (
  lessThanZero: boolean,
  overflow: boolean,
  labelHalfWidth: number,
  spaceLeft: number,
  spaceRight: number,
) => {
  if (lessThanZero) {
    return `${-(labelHalfWidth + spaceLeft + ARROW_WIDTH / 2)}px`
  }
  if (overflow) {
    return `-${labelHalfWidth + spaceRight - ARROW_WIDTH / 2}px`
  }
  return `-${labelHalfWidth}px`
}

export const SolProgressBar = (props: Props) => {
  const { percent } = props
  const labelRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const labelHalfWidth = labelWidth / 2
  const leftPixels = (percent * containerWidth) / 100
  const spaceLeft = leftPixels - labelHalfWidth
  const lessThanZero = spaceLeft < 0
  const spaceRight = leftPixels + labelHalfWidth
  const overflow = spaceRight > containerWidth

  useEffect(() => {
    if (labelRef.current) {
      setLabelWidth(labelRef.current.clientWidth)
    }
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth)
    }
  }, [])
  return (
    <div ref={containerRef} className={styles.progressBar}>
      <span
        ref={labelRef}
        className={styles.label}
        style={{
          left: `${percent}%`,
          marginLeft: getMarginLeft(lessThanZero, overflow, labelHalfWidth, spaceLeft, spaceRight - containerWidth),
          textAlign: getTextAlign(lessThanZero, overflow),
        }}
      >
        <div>
          {percent}%&nbsp;{props.label || ''}
        </div>
      </span>
      <div
        className={styles.arrow}
        style={{
          left: `${percent}%`,
          marginLeft: '-5px',
        }}
      />

      <Progress className={styles.progressBarContainer} size="tiny" percent={percent} active />
    </div>
  )
}

export default SolProgressBar
