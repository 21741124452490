import React from 'react'
import Accordion from './Accordian/Accordian'
import { StepHeader } from 'components/Maintenance/Renewal/Partials'
import ConfirmationCard from './ConfirmationCard/ConfirmationCard'
import StaticTable from './StaticTable/StaticTable'
import styles from './AlmostDone.module.scss'
import { MxRenewLeadInterface } from 'shared/services/mxRenew'

export interface Props {
  rewindFlow: () => void
  selectedPods: number
  totalPods: number
  rows: string[][]
  edit: () => void
  currentLead: MxRenewLeadInterface
}

const AlmostDone = (props: Props) => {
  const { rewindFlow, selectedPods, totalPods, currentLead, rows, edit } = props

  const confirmationCardProps = {
    selectedPods,
    totalPods,
    newExpirationDate: currentLead.newPodExpiration,
  }

  const accordianProps = {
    title: 'Details',
  }

  const staticTableProps = {
    headers: ['Pod', 'Old Date', 'New Date'],
    rows,
  }
  const stepHeaderProps = {
    rewind: rewindFlow,
    title: `You're almost done!`,
    subTitle: '',
  }

  return (
    <div>
      <StepHeader {...stepHeaderProps} />
      <div className={styles.almostPage}>
        <ConfirmationCard {...confirmationCardProps} />
        <Accordion {...accordianProps} edit={edit}>
          <div className={styles.wrapper}>
            <StaticTable {...staticTableProps} />
          </div>
        </Accordion>
      </div>
    </div>
  )
}


export default AlmostDone
