import React, { useState } from 'react'
import {
  StepHeader,
  FlowButtons,
  StepSpinner,
} from 'components/Maintenance/Renewal/Partials'
import Table from './Table/index'
import { useTable } from './useTable'
import { getRenewFlowSelectedPods } from '../getSelectedPods'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'
import { useApolloClient } from '@apollo/client'
import {
  ExpiredDisplaysDocument,
  ExpiredDisplaysQuery,
  ExpiredDisplaysQueryVariables,
} from 'graphql/__generated__/hasura-types'
import styles from './index.module.scss'

type Props = {
  hasOtherPods: boolean
  cancelFlow: () => void
  rewindFlow: () => void
  updateLead: (
    id: MxRenewLeadId,
    update: MxRenewLeadInterface,
    nextStep: number
  ) => void
  nextStep: number
  currentLead: MxRenewLeadInterface
}

const SelectExpiredDisplay = ({
  rewindFlow,
  hasOtherPods,
  cancelFlow,
  updateLead,
  currentLead,
  nextStep,
}: Props) => {
  const { query } = useApolloClient()

  const [isLoadingPods, setIsLoadingPods] = useState(false)

  const {
    tableData,
    isLoading,
    totalItems,
    totalItemsSelected,
    handleOrderBy,
    selectedItemsWhere,
  } = useTable()

  const getSelectedExpiredPods = async () => {
    const { data: expiredSelectedData } = await query<
      ExpiredDisplaysQuery,
      ExpiredDisplaysQueryVariables
    >({
      query: ExpiredDisplaysDocument,
      variables: {
        where: selectedItemsWhere,
      },
    })
    const selectedExpiredPods = getRenewFlowSelectedPods(
      expiredSelectedData?._displays,
    )
    return selectedExpiredPods
  }

  const handleNext = async () => {
    setIsLoadingPods(true)
    const selectedExpiredPods = await getSelectedExpiredPods()
    await updateLead(
      currentLead.id,
      {
        ...currentLead,
        selectedExpiredPods: selectedExpiredPods,
        step: nextStep,
      },
      nextStep,
    )
  }

  const handleCancel = async () => {
    const selectedExpiredPods = await getSelectedExpiredPods()
    await updateLead(
      currentLead.id,
      {
        ...currentLead,
        orderStatus: 'cancelled',
        selectedExpiredPods: selectedExpiredPods,
      },
      nextStep - 1,
    )
    cancelFlow()
  }

  if (isLoadingPods) {
    return <StepSpinner />
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.leftCol}>
          <StepHeader
            showBackButton={false}
            rewind={rewindFlow}
            title="Select Pods to Renew"
            subTitle={`
          The Solstice Subscription has lapsed for the following Pods.
          Please select the ones you'd like to renew.
          (You'll be able to co-term with additional Pods on the next step.)
        `}
          />
        </div>
        <div className={styles.actions}>
          <FlowButtons
            disabled={totalItemsSelected === 0 && !hasOtherPods}
            next={handleNext}
            cancel={handleCancel}
          />
        </div>
      </div>
      <Table
        data={tableData || []}
        loading={isLoading || isLoadingPods}
        totalItems={totalItems}
        totalItemsSelectable={totalItems}
        totalItemsSelected={totalItemsSelected}
        onSort={handleOrderBy}
      />
    </div>
  )
}

export default SelectExpiredDisplay
