import root from 'window-or-global'

import axios, { AxiosError, AxiosResponse } from 'axios'

import { getAuthURL, getSsoURL, graphqlServerUrl } from 'shared/core/config'

import {
  AuthServiceApiInterface,
  AuthRestInterface,
  AuthCredentialInterface,
  AuthUserResetPasswordInterface,
  AuthUserInvitePasswordInterface,
  AuthUserInviteStatusInterface,
  AuthUserInviteInterface,
  AuthUserUpdateInterface,
  AuthUserDeleteInterface,
  AuthDeletePodInterface,
  LocalStorageToken,
  SupportCredentials,
  UpdateRoleApiRequest,
  ChangePasswordApiRequest,
  CreateOrgInterface,
} from 'shared/services/auth/types'

import { getToken } from 'shared/services/auth/helpers'

const AUTH_URL = getAuthURL(root.location.hostname, root.location.port, root.location.port)
const GRAPHQL_URL = graphqlServerUrl(root.location.hostname, root.location.port, root.location.port)
const SSO_URL = getSsoURL(root.location.hostname, root.location.port, root.location.port)

const defaultRest: AuthRestInterface = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
}

export class AuthServiceApi implements AuthServiceApiInterface {
  apiUrl: string
  ssoUrl: string
  rest: AuthRestInterface

  constructor(apiUrl: string = AUTH_URL, ssoUrl: string = SSO_URL, rest: AuthRestInterface = defaultRest) {
    this.apiUrl = apiUrl
    this.ssoUrl = ssoUrl
    this.rest = rest
  }

  ssoSignIn(credentials: AuthCredentialInterface) {
    const endpoint = `/auth/token`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.ssoUrl}${endpoint}`
    const body = { ...credentials, password: null }
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => {
        window.location.href = response.data.redirectURL
        return response.data.redirectURL
      })
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.error(`trouble posting to ${this.ssoUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  ssoFinish(token: string) {
    const endpoint = `/auth/token`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.ssoUrl}${endpoint}`
    const body = { token }
    return this.rest
      .put(url, body, { headers })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.error(`trouble posting to ${this.ssoUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  login(credentials: AuthCredentialInterface) {
    const endpoint = `/v1/auth/token`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = credentials
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  supportLogin(credentials: SupportCredentials) {
    const endpoint = `/v1/auth/support`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = credentials
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  resetPassword(email: AuthUserResetPasswordInterface['email']) {
    const endpoint = `/v1/auth/password`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url: string = `${this.apiUrl}${endpoint}`
    const body: AuthUserResetPasswordInterface = { email }
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  setInvitedUserPassword(
    inviteToken: AuthUserInvitePasswordInterface['jwt'],
    newPassword: AuthUserInvitePasswordInterface['password'],
  ) {
    const endpoint = `/v1/user/setinviteduserpassword`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiUrl}${endpoint}`
    const body: AuthUserInvitePasswordInterface = {
      jwt: inviteToken,
      password: newPassword,
    } as AuthUserInvitePasswordInterface
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  checkInviteStatus(inviteToken: AuthUserInviteStatusInterface['jwt']) {
    const endpoint = `/v1/user/checkinvitestatus`
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiUrl}${endpoint}`
    const body: AuthUserInviteStatusInterface = {
      jwt: inviteToken,
    } as AuthUserInviteStatusInterface
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response)
      .catch(err => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err.response)
      })
  }

  inviteUsers(invite: AuthUserInviteInterface) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/user/invitation`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = invite
    return this.rest
      .post(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  getUsers() {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/users`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    return this.rest
      .get(url, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  updateUser(update: AuthUserUpdateInterface) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/user`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = Array.isArray(update) ? update : [update]

    return this.rest
      .put(url, body, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  updateUsers(update: AuthUserUpdateInterface | AuthUserUpdateInterface[]) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/users`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = Array.isArray(update) ? update : [update]
    return this.rest
      .put(url, body, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  deleteUser(data: AuthUserDeleteInterface) {
    const token: LocalStorageToken = getToken()
    const { email } = data
    const endpoint = `/v1/user/${email}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    return this.rest
      .delete(url, {
        data,
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  deletePod(podId: AuthDeletePodInterface) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/displays/${podId}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    return this.rest
      .delete(url, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  updateRole(data: UpdateRoleApiRequest) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/user/${data.email}/authorization/roles`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`

    return this.rest
      .put(url, { roles: [data.role] }, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  getUserAuthorization() {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/user/me/authorization`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    return this.rest
      .get(url, {
        headers,
      })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble loading ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  changePassword(data: ChangePasswordApiRequest) {
    const token: LocalStorageToken = getToken()
    const endpoint = `/v1/auth/password`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const url = `${this.apiUrl}${endpoint}`
    const body = data
    return this.rest
      .put(url, body, { headers })
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  getSsoStatus(email: string) {
    const endpoint = `/auth/status`
    const url = `${this.ssoUrl}${endpoint}/${email}`
    return this.rest
      .get(url)
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }

  createOrg(data: CreateOrgInterface ) {
    const endpoint = `/create-org`
    const url = `${GRAPHQL_URL}${endpoint}`
    return this.rest
      .post(url, data)
      .then((response: AxiosResponse) => response.data)
      .catch((err: AxiosError) => {
        /* eslint-disable-next-line no-console */
        console.log(`trouble posting to ${this.apiUrl}${endpoint}: ${err.stack}`)
        return Promise.reject(err)
      })
  }
}

export default new AuthServiceApi(AUTH_URL, SSO_URL, defaultRest)
