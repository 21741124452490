import { connect } from 'react-redux'

import { State } from 'shared/store/slices/reducerUtils'
import AccountList from './AccountList'
import { showError } from 'shared/store/slices/alert'
import { UserManager } from './UserManager'

const mapRowRendererStateToProps = (state: State) => ({
  signedInUser: state.user,
})

export const ConnectedUserManager = connect(mapRowRendererStateToProps, {
  showError,
})(UserManager)

export default AccountList
