import React from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import {
  DataTableInstance,
  Direction,
  Column,
  Categorizable,
} from 'components/DataTableSlim/DataTableSlim'
import { AuthUserInviteStatus } from 'shared/services/auth/types'
import { Role, RoleDisplayText } from 'shared/types/Role'
import { ConnectedUserManager } from '.'
import { User } from '../types'
import { useAccountList } from './useAccountList'
import { Icon } from 'semantic-ui-react'
import { SolLink } from 'SolComponents'
import style from './AccountList.module.scss'

const getStatusText = (status?: AuthUserInviteStatus) => ({
  current: 'Current',
  'invite sent': 'Invite Sent',
  deleted: null,
  invalid: 'Something went wrong',
}[status ?? 'invalid'])

const defaultSort = {
  field: 'email',
  direction: 'ascending' as Direction,
}

type Props = {
  me: { name: string; role: Role; permissions: string[] }
  ssoEnabled: boolean
} & Omit<ReturnType<typeof useAccountList>, 'refetchUsers' | 'addSsoUser' | 'sendingInvite'>

const AccountList = ({
  sendInviteForUser,
  updateUser,
  updateSsoStatus,
  deleteUser,
  totalUsers,
  users,
  loadingUsers,
  ssoEnabled,
}: Props) => {

  const userManager = (row: User) => (
    <ConnectedUserManager
      row={row}
      sendInviteForUser={sendInviteForUser}
      updateUser={updateUser}
      deleteUser={deleteUser}
      updateSsoStatus={updateSsoStatus}
      showSsoItem={ssoEnabled}
      userHasPassword={!!row.has_password}
    />
  )

  const columns: Column<User>[] = [
    {
      name: 'email',
      displayName: 'Email',
    },
    {
      name: 'role',
      displayName: 'Role',
      render: user => RoleDisplayText[user.role ?? Role.Viewer],
    },
    {
      name: 'status',
      displayName: 'Status',
      render: user => getStatusText(user.status as AuthUserInviteStatus),
    },
    {
      name: 'manageUsers',
      displayName: 'Edit',
      render: userManager,
      sortable: false,
    },
  ]

  const getTooltip = () => {
    return (
      <div>
        <p>Enabled (<Icon name="checkmark" color="green" />) requires a user to log in through your SSO provider.</p>
        <p>Users not set to SSO Only can log in either with a username and password or with SSO.</p>
        <div className={style.centerButton}>
          <SolLink
            url="https://documentation.mersive.com/en/users-and-roles.html"
            label="Learn More"
          />
        </div>
      </div>
    )
  }

  // only add the Sso Only column when we are ready
  if (ssoEnabled) {
    columns.splice(3, 0,
      {
        name: 'sso_only',
        displayName: 'SSO Only',
        render: user => (
          <p>
            {user.sso_only && <Icon name="checkmark" color="green" />}
          </p>
        ),
        tooltipText: getTooltip(),
      },
    )
  }

  return (
    <>
      {ServerDataTable<Categorizable<User>>({
        id: DataTableInstance.Users,
        title: 'Current Solstice Cloud Users',
        defaultSort,
        columns,
        data: users,
        loading: loadingUsers,
        totalItems: totalUsers,
        filterable: false,
        selectable: false,
        searchable: true,
        scrollable: false,
        addableColumns: false,
        wideHeader: true,
        filterDrawer: false,
        padded: false,
      })}
    </>
  )
}

export default AccountList
