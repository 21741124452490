import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Icon, Segment } from 'semantic-ui-react'
import { NetworkStatus } from '@apollo/client'
import {
  MetricType,
  MetricsByDateQuery,
  MetricsByDateQueryVariables,
  MetricsByDateDocument,
  MetricsByDate,
} from 'graphql/__generated__/types'
import styles from './UsageHistoryHomePage.module.scss'

import { AxisOptions } from 'pages/UsageHistory/types'
import { Option } from 'SolComponents/SolChart/types'
import UsageChart from '../../UsageHistory/UsageChart'
import { secondaryOptions as allMetricsAvailable } from 'pages/UsageHistory/chartUtils'
import { ComputeMetrics } from 'shared/domain/metric'
import { DateRange } from '../TopRow'
import { TestingProps } from 'shared/types/TestingProps'
import { Link } from 'react-router-dom'

interface InitialState {
  isLoading: boolean
  metrics: AxisOptions[]
}

interface UsageProps extends TestingProps {
  dateRange: DateRange
  doneLoading: Function
}

export const selectOptions = (metrics: AxisOptions[]): Option[] =>
  metrics.map(
    metric =>
      allMetricsAvailable
        .filter(metricAvailable => metric === metricAvailable.value)
        .map(
          metricAvailable =>
            ({
              internalName: metricAvailable.value,
              displayName: metricAvailable.text,
            } as Option),
        )[0],
  )

const metricTypeValues = Object.values<string>(MetricType)

const filterAxisOptions = (axisSelected: AxisOptions[]) =>
  axisSelected
    .filter(axis => axis === ComputeMetrics.Hour || (axis && metricTypeValues.includes(axis)))
    .map(res => (res === ComputeMetrics.Hour ? MetricType.MinutesInUse : res)) as MetricType[]

const standarizeChartData = (data: MetricsByDate[] = [], optionSelected: AxisOptions[]) => {
  if (optionSelected.includes(ComputeMetrics.Hour)) {
    return data.map(res => {
      const updateMetrics = res.metrics.map(metric => {
        if (metric?.name === MetricType.MinutesInUse) {
          return {
            ...metric,
            name: ComputeMetrics.Hour,
            total: metric.total / 60,
          }
        }
        return metric
      })
      return { ...res, metrics: updateMetrics }
    })
  }
  return data
}

export const initialState: InitialState = {
  isLoading: true,
  metrics: [MetricType.Meetings, MetricType.Users],
}

const UsageHistoryHomePage = ({ dateRange, doneLoading }: UsageProps) => {
  const [state, setState] = React.useState(initialState)
  const { metrics } = state

  const { fromDate, toDate, interval } = dateRange

  const optionDate = {
    fromDate,
    toDate,
  }

  const { data, networkStatus: graphStatus } = useQuery<MetricsByDateQuery, MetricsByDateQueryVariables>(
    MetricsByDateDocument,
    {
      variables: {
        options: {
          timeInterval: interval,
          metricTypes: filterAxisOptions(metrics),
          fromDate: optionDate.fromDate,
          toDate: optionDate.toDate,
        },
      },
    },
  )

  const updateState = (newState: Partial<InitialState>) => setState({ ...state, ...newState })

  const handleChangeMetric = (axisSelected: AxisOptions[]) => updateState({ metrics: axisSelected })

  useEffect(() => {
    if (graphStatus === NetworkStatus.ready) {
      doneLoading()
    }
  }, [graphStatus])

  const style = {
    header: {
      fontSize: '24px',
      padding: '15px',
    } as React.CSSProperties,
  }

  return (
    <>
      <div className={styles.usageChart} data-testid="usage-chart">
        <Segment raised className={styles.segment}>
          <div className={styles.body}>
            <div style={style.header}>Recent Usage</div>
            <UsageChart
              data={standarizeChartData((data?.metricsByDate || []) as MetricsByDate[], metrics) as MetricsByDate[]}
              interval={interval}
              setInterval={setInterval}
              options={selectOptions(metrics)}
              toDate={new Date(toDate)}
              onChangeMetrics={handleChangeMetric}
            />
          </div>
          <div>
            <div className={styles.link}>
              <Link to="/usage-history">
                USAGE&nbsp;
                <Icon name="angle right" />
              </Link>
            </div>
          </div>
        </Segment>
      </div>
    </>
  )
}

export default UsageHistoryHomePage
