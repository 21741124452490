import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { format, set, getTime, getDate, getMonth, setMonth, endOfMonth, getYear, addYears, getUnixTime } from 'date-fns'
import { FlowButtons } from 'components/Maintenance/Renewal/Partials'
import { MxRenewLeadId, MxRenewLeadInterface } from 'shared/services/mxRenew'

const style = require('./datePicker.css')

export const errorState = {
  hasError: {
    border: '1px solid red',
    width: '0px!important',
  } as React.CSSProperties,

  noError: {
    borderColor: '1px solid #96c8da',
  } as React.CSSProperties,
  errorText: {
    color: 'red',
    fontSize: '16px',
    paddingBottom: '5px',
  } as React.CSSProperties,
  customDropdown: {
    display: 'inlineBlock',
    height: '38px',
    width: '196px',
    marginRight: '10px',
  } as React.CSSProperties,
}

export interface Props {
  latestExpirationDate: number
  cancelFlow: () => void
  nextStep: number
  earliestExpirationDate: number
  updateLead: (id: MxRenewLeadId, update: Partial<MxRenewLeadInterface>, nextStep: number) => void
  currentLead: MxRenewLeadInterface
}

export interface State {
  monthString: string
  selectedYear: number
  monthNumber: number
  isValidDate: boolean
}

/* eslint-disable-next-line new-cap */
const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

const selectedDateIsInvalid = (selectedYear: number, latestExpirationDate: number, selectedMonth: number) => {
  const isInvalid
    = selectedYear === getYear( zonedTimeToUtc( new Date(latestExpirationDate), localTimezone) )
    && selectedMonth <= getMonth( zonedTimeToUtc( new Date(latestExpirationDate), localTimezone) )
    
  return isInvalid || set(new Date(), { year: selectedYear, month: selectedMonth })  < new Date()
}

export default class DatePicker extends React.Component<Props, State> {
  defaultDate: Date
  constructor(props: Props) {
    super(props)
    const { latestExpirationDate, currentLead } = props
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.getYearList = this.getYearList.bind(this)
    this.isValidMonth = this.isValidMonth.bind(this)
    this.defaultDate = currentLead.newPodExpiration
      ? zonedTimeToUtc(new Date(
        Math.max(
          getUnixTime( new Date(currentLead.newPodExpiration)) * 1000,
          getUnixTime(addYears( new Date(latestExpirationDate), 1)) * 1000,
        ),
      ), localTimezone)
      : addYears(endOfMonth(zonedTimeToUtc(new Date(latestExpirationDate), localTimezone )), 1)

    const getAutomaticMonth = getMonth(this.defaultDate) // Number
    const getMonthNumber = parseInt(format( new Date(this.defaultDate), 'M'))
    const getDisplayMonthString = `${format( endOfMonth( setMonth(new Date(), getMonthNumber) ), 'MMMM')}`
    const defaultYear = getYear( new Date(this.defaultDate))

    this.state = {
      monthNumber: getAutomaticMonth,
      selectedYear: defaultYear,
      monthString: getDisplayMonthString,
      isValidDate: true,
    }
  }

  getYearList() {
    const beginningYear = Math.max(getYear(new Date(this.props.latestExpirationDate)), getYear(new Date()))
    let year: any = [] // how do I type this?
    year.push({
      value: beginningYear,
      text: beginningYear.toString(),
    })
    for (let i = year.length; i < 5; i++) {
      const getLastYear = [...year].pop().value
      const newYear = getLastYear + 1
      year.push({
        value: newYear,
        text: newYear.toString(),
      })
    }
    return year
  }

  handleMonthChange(event: any, { value }: any) {
    const { latestExpirationDate } = this.props
    const { selectedYear } = this.state
    const monthNumber = getMonth(new Date(`${value} 1`))
    const endOfMonthString = format(
      endOfMonth(
        setMonth( zonedTimeToUtc(
          new Date(), localTimezone,
        ), monthNumber,
        )), 'MMMM',
    )
    this.setState({
      monthString: endOfMonthString,
      monthNumber: monthNumber,
      isValidDate: !selectedDateIsInvalid(selectedYear, latestExpirationDate, monthNumber),
    })
  }

  handleYearChange(event: any, { value }: any) {
    const { latestExpirationDate } = this.props
    const { monthNumber } = this.state
    this.setState({
      selectedYear: value,
      isValidDate: !selectedDateIsInvalid(value, latestExpirationDate, monthNumber),
    })
  }

  showErrorMessage() {
    const dateString = format(
      zonedTimeToUtc(
        Math.max( getTime(new Date(this.props.latestExpirationDate)),
          getTime( new Date()) ), localTimezone),
      'MMMM yyyy')
    if (!this.state.isValidDate) {
      return (
        <div style={errorState.errorText}>
          <p>* The new maintenance expiration date must be after {dateString}</p>
        </div>
      )
    }
    return
  }

  handleSubmit() {
    const { nextStep, updateLead, currentLead } = this.props

    const { monthNumber, selectedYear } = this.state

    const getDay = getDate(endOfMonth( zonedTimeToUtc(
      set(new Date(), { year: selectedYear, month: monthNumber }), localTimezone,
    )))

    updateLead(
      currentLead.id,
      {
        ...currentLead,
        step: nextStep,
        newPodExpiration: format(zonedTimeToUtc(
          set(new Date(), { year: selectedYear, month: monthNumber, date: getDay }), localTimezone),
        'MMMM d yyyy'),
      },
      nextStep,
    )
  }

  handleCancel() {
    const { updateLead, currentLead, nextStep, cancelFlow } = this.props
    const { monthNumber, selectedYear } = this.state

    const getDay = getDate(zonedTimeToUtc( endOfMonth(
      set(new Date(), { year: selectedYear, month: monthNumber }),
    ), localTimezone))

    updateLead(
      currentLead.id,
      {
        ...currentLead,
        newPodExpiration: format( zonedTimeToUtc(
          set(new Date(), { year: selectedYear, month: monthNumber, date: getDay }), localTimezone,
        ), 'MMMM d yyyy'),
        orderStatus: 'cancelled',
      },
      nextStep - 1,
    )
    cancelFlow()
  }

  isValidMonth(month: number) {
    const earliestYear = Math.max(getYear(new Date(this.props.latestExpirationDate)))
    const earliestMonth = getMonth(new Date(this.props.latestExpirationDate))
    if (
      (this.state.selectedYear > earliestYear || (this.state.selectedYear === earliestYear && month > earliestMonth))
      && set(new Date(), { year: this.state.selectedYear, month: month }) > new Date()
    ) {
      return true
    }
    return false
  }

  render() {
    const { isValidDate, selectedYear, monthString } = this.state

    let hasError = errorState.noError
    if (!isValidDate) {
      hasError = errorState.hasError
    }

    return (
      <div>
        <div id={style.customDropdownId}>
          {this.showErrorMessage()}
          <div className="customDropdown">
            <Dropdown
              selection
              scrolling
              onChange={this.handleMonthChange}
              options={months.map((m, index) => ({
                text: format( setMonth(new Date(), index), 'MMMM'),
                value: m,
                key: index,
                disabled: !this.isValidMonth(index),
              }))}
              defaultValue={months[getMonth(this.defaultDate)]}
              style={hasError}
              text={monthString}
            />
          </div>
          <div className="customDropdown">
            <Dropdown
              selection
              scrolling
              text={selectedYear.toString()}
              style={hasError}
              onChange={this.handleYearChange}
              options={this.getYearList()}
            />
          </div>
        </div>
        <FlowButtons next={this.handleSubmit} nextText="Next" disabled={!isValidDate} cancel={this.handleCancel} />
      </div>
    )
  }
}
