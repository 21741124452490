import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'
import {
  PreferredHdmInputResolution,
  PreferredHdmiInput,
} from 'graphql/__generated__/types'
import styles from '../Advanced.module.scss'
import {
  SolCheckbox,
  SolDropdown,
} from 'SolComponents'

const hdmiInputResolutions: DropdownItemProps[] = [
  { value: PreferredHdmInputResolution._1080p, text: '1080p' },
  { value: PreferredHdmInputResolution._720p, text: '720p' },
  { value: PreferredHdmInputResolution.Vga, text: 'VGA' },
]

interface Props {
  preferredHDMIInput?: PreferredHdmiInput
  updatePreferredHDMIInput: (input: PreferredHdmiInput) => void
  templateDiff?: any
}

export function PreferredHDMIInputSettings({
  preferredHDMIInput,
  updatePreferredHDMIInput,
  templateDiff,
}: Props) {
  return (
    <>
      <SolCheckbox
        label="Enable Preferred HDMI Input Resolution"
        checked={preferredHDMIInput?.enabled ?? false}
        mismatched={templateDiff?.enabled === 'mismatched'}
        onClick={enabled => (
          updatePreferredHDMIInput({
            ...preferredHDMIInput,
            enabled,
          })
        )}
      />
      <div className={styles.subItem}>
        <SolDropdown
          options={hdmiInputResolutions}
          label="Preferred HDMI Input Resolution"
          value={preferredHDMIInput?.resolution ?? PreferredHdmInputResolution._1080p}
          mismatched={templateDiff?.resolution === 'mismatched'}
          onChange={(resolution: PreferredHdmInputResolution) => (
            updatePreferredHDMIInput({
              ...preferredHDMIInput,
              resolution,
            })
          )}
          disabled={!preferredHDMIInput?.enabled}
          size="small"
        />
      </div>
    </>
  )
}
