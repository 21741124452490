import React from 'react'
import styles from './index.module.scss'

const telescope = require('shared/images/telescope.png')

const NoResults = () => {
  return (
    <div className={styles.empty}>
      <figure>
        <img data-testid="no-result-img" src={telescope} alt="Telescope image - No results found."/>
        <div className={styles.caption}>No results found.</div>
      </figure>
    </div>
  )
}

export default NoResults
