import React from 'react'
import { Header } from 'semantic-ui-react'

import { SolCard } from 'SolComponents'
import { MostCollaborativeSpacesQuery, ViewMostCollaborativeSpacesResult } from 'graphql/__generated__/hasura-types'
import styles from './index.module.scss'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import { round } from 'shared/helpers/round'
import { SolCloseIcon } from 'SolComponents/Icons'

type Props = {
  mostCollaborativeSpaces?: MostCollaborativeSpacesQuery
  loadingMostCollaborativeSpaces: boolean
  setSelectedLocation: (location: ViewMostCollaborativeSpacesResult | null) => void
  selectedLocation: ViewMostCollaborativeSpacesResult | null
}

export const TopTenList = (props: Props) => {
  const { mostCollaborativeSpaces: data, setSelectedLocation, selectedLocation } = props

  return (
    <div className={styles.listContainer}>
      {selectedLocation ? (
        <div>
          <div className={styles.headerContainer}>
            <Header
              className={styles.detailHeader}
              as="h3"
              textAlign="left"
              content={selectedLocation?.name ?? selectedLocation?.display_id}
            />
            <button className={styles.closeSelected} onClick={() => setSelectedLocation(null)}>
              <SolCloseIcon onClick={() => setSelectedLocation(null)} />
            </button>
          </div>
          <ul className={styles.detailList}>
            <li>{selectedLocation?.name}</li>
            <li>{selectedLocation?.room_type ?? <span className={styles.italic}>Room Type Unknown</span>}</li>
            <li>{selectedLocation?.nickname ?? selectedLocation?.building}</li>
            <li>{selectedLocation?.address}</li>
            <li>
              <span className={styles.detailName}># of Collaborative Meetings:</span>{' '}
              {round(selectedLocation.mtngs || 0)}
            </li>
            <li>
              <span className={styles.detailName}>Average Collaborators:</span>{' '}
              {round(selectedLocation.unq_users_avg || 0)}
            </li>
            <li>
              <span className={styles.detailName}>Collaborative Score:</span> {round(selectedLocation.plot_score || 0)}
            </li>
          </ul>
        </div>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <Header className={styles.header} as="h3" textAlign="left" content="Top 10 Most Collaborative Rooms" />
          </div>
          <LoadingWrapper
            isEmpty={(props.mostCollaborativeSpaces?._search_most_collaborative_spaces?.length || 0) === 0}
            isLoading={props.loadingMostCollaborativeSpaces || false}
          >
            <ol className={styles.list}>
              {data?._search_most_collaborative_spaces
                ?.slice(0, 10)
                ?.filter(display => !!display.name)
                .map((display, i) => (
                  <li className={styles.pod} key={`${display.display_id}-${display.room_type?.replace(/\s/g, '-')}`}>
                    <button className={styles.podButton} onClick={() => setSelectedLocation(display)}>
                      <span className={styles.podNumber}>{`${i + 1}.`}</span> {display?.name}
                    </button>
                  </li>
                ))}
            </ol>
          </LoadingWrapper>
        </>
      )}
    </div>
  )
}

const TopTenCollaborativeRooms = (props: Props) => (
  <SolCard>
    <TopTenList {...props} />
  </SolCard>
)

export default TopTenCollaborativeRooms
