import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { MxModalSlideKey } from 'shared/store/types'

import MaintenanceModal from './MaintenanceModal'
import SolStickyBanner from '../../SolComponents/SolStickyBanner/SolStickyBanner'
import styles from './styles.module.scss'
import { SolCloseIcon } from 'SolComponents/Icons'

export interface Props {
  expiredCount: number
  modalSlideKeys: MxModalSlideKey[]
  closeBanner: () => void
}

export interface State {
  openModal: boolean
}

function StickyBanner(props: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { closeBanner, expiredCount, modalSlideKeys } = props
  const determineCount = expiredCount > 1 ? 'have' : 'has'

  return (
    <SolStickyBanner
      color="warning"
      text={
        <span className="renewText">
          {expiredCount} of your Pods {determineCount} lapsed Solstice
          Subscriptions. <strong>Renew Now</strong> to continue receiving data
          from your entire fleet.
        </span>
      }
    >
      <div className={styles.fixButton}>
        <Button
          className={styles.fixingBasic}
          onClick={() => setOpenModal(true)}
        >
          FIX THIS
        </Button>
        <MaintenanceModal
          slideKeys={modalSlideKeys}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      </div>
      <span className={styles.closeBanner}>
        <SolCloseIcon onClick={closeBanner} />
      </span>
    </SolStickyBanner>
  )
}

export default StickyBanner
