import { createSlice } from '@reduxjs/toolkit'

export interface AccountState {
  name: string
  token: string
  role?: number
  org: string
  ssoOnly?: boolean
  permissions?: string[]
}

const initialState: AccountState = {
  name: '',
  token: '',
  org: '',
}

export const accountSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: AccountState, action) => {
      state.name = action.payload.name
      state.token = action.payload.token
      state.role = action.payload.role
      state.org = action.payload.org
      state.ssoOnly = action.payload.ssoOnly
      state.permissions = action.payload.permissions
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser } = accountSlice.actions

export default accountSlice.reducer
