export const RoleNames = {
  'Room Discovery Guest': 'Room Discovery Guest',
  'Viewer': 'Viewer',
  'Active Learning Facilitator': 'Active Learning Facilitator',
  'Active Learning Designer': 'Active Learning Designer',
  'Super Admin': 'Super Admin',
} as const

export type RoleName = keyof typeof RoleNames

export const RoleRanking = {
  [RoleNames['Super Admin']]: 0,
  [RoleNames['Room Discovery Guest']]: 500,
  [RoleNames.Viewer]: 200,
  [RoleNames['Active Learning Designer']]: 110,
  [RoleNames['Active Learning Facilitator']]: 120,
} as const

export type RoleRank = typeof RoleRanking[RoleName]

export enum Role {
  'Super Admin' = 0,
  'Room Discovery Guest' = 500,
  'Viewer' = 200,
  'Active Learning Designer' = 110,
  'Active Learning Facilitator' = 120,
}

export const RoleDescriptions = {
  [RoleNames['Room Discovery Guest']]: 'Can only discover Pods on share.mersive.com by logging in.',
  [RoleNames['Super Admin']]: 'Can perform all tasks and manage all Pods.',
  [RoleNames.Viewer]: 'Can monitor deployment and view analytics. Can’t add, delete, or make changes to Pods.',
  [RoleNames['Active Learning Designer']]: 'Can design and set up video routing spaces using the Active'
  + 'Learning app.',
  [RoleNames['Active Learning Facilitator']]: 'Can route video between multiple displays using the Active'
  + 'Learning app.',
} as const

export type RoleDescription = typeof RoleDescriptions[RoleName]

export const RoleDisplayText = {
  0: 'Admin',
  500: 'Room Discovery Guest',
  200: 'Viewer',
  110: 'Active Learning Designer',
  120: 'Active Learning Facilitator',
}

export interface RoleDetail {
  id: RoleRank
  name: RoleName
  description: RoleDescription
}
