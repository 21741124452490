import React, { useEffect } from 'react'
import Joyride from 'react-joyride'
import { useJoyride, Ride, styles } from 'shared/hooks/useJoyride'

interface Props {
  forceShowRide?: boolean
}

export function ComparePageRide({ forceShowRide }: Props) {
  const { show: showRide, finishRide, startRide } = useJoyride(
    Ride.ComparePage,
    2000, // delay ride to wait for categories to load - which pushes down the screen
  )
  useEffect(() => {
    if (forceShowRide) {
      startRide()
    }
  }, [])
  return (
    <Joyride
      disableScrolling
      showSkipButton
      styles={{
        ...styles,
        tooltip: {
          width: '275px',
        },
        tooltipContent: {
          padding: '0',
        },
      }}
      disableOverlayClose
      callback={event => {
        if (event.action === 'close' || event.type === 'tour:end') {
          finishRide()
        }
      }}
      run={showRide}
      continuous
      locale={{
        last: 'Finish',
      }}
      steps={[
        {
          disableBeacon: true,
          target: '#category-filter',
          title: <span>First choose a category.</span>,
          content: null,
        },
        {
          disableBeacon: true,
          target: '.joyride-metric-selector',
          title: (
            <span>
              Second, choose a metric to <br />
              compare it to.
            </span>
          ),
          content: null,
        },
      ]}
      disableScrollParentFix
    />
  )
}
