import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import styles from './settings.module.scss'
import {
  SolAlertMessage,
  SolCancelSave,
  SolCard,
  SolCheckbox,
  SolRouteGuard,
  SolTooltip,
} from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { Divider, Icon } from 'semantic-ui-react'
import {
  SolsticeRoutingOrg,
  useSolsticeRoutingOrgQuery,
  useUpdateSolsticeRoutingOrgMutation,
} from 'graphql/__generated__/types'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'

/**
 * The 'Settings' Page of Solstice Routing holds settings that are Org-wide
 */

export type SpaceParams = { spaceId: string | undefined }

const SolsticeRoutingSettings = ({ history }: RouteComponentProps) => {
  const [toast, setToast] = useState<string | null>(null)
  const [settingIsDirty, setSettingIsDirty] = useState(false)

  const org = useCurrentUser().org

  const { data, loading } = useSolsticeRoutingOrgQuery({
    variables: { orgId: org },
  })

  const [orgSettings, setOrgSettings] = useState<SolsticeRoutingOrg>()

  useEffect(() => {
    setOrgSettings(data?.solsticeRoutingOrg)
  }, [data?.solsticeRoutingOrg])

  const [updateSolsticeRoutingOrgMutation] = useUpdateSolsticeRoutingOrgMutation()
  const updateOrg = async () => {
    const updatedOrg = (await updateSolsticeRoutingOrgMutation(
      { variables: {
        options: {
          orgId: org,
          require_password_on_exit: orgSettings?.require_password_on_exit,
        },
      },
      })).data?.updateSolsticeRoutingOrg
    return updatedOrg
  }

  const saveOrg = async () => {
    const updated = await updateOrg()
    if (updated) {
      setOrgSettings(updated)
      setToast('Org Successfully Saved.')
    }
  }

  return (
    <div className={styles.wrapper}>
      <SolAlertMessage
        message={toast ?? ''}
        show={!!toast}
        type="success"
        onDismiss={() => setToast(null)}
      />
      <ContentWrapper>

        <div className={styles.header}>
          <span
            className={styles.grey}
            onClick={() => {history.replace('/solstice-routing/')}}
          >Solstice Routing</span>
          <Icon name="angle right" color="grey" />
          <span>Settings</span>
        </div>

        <SolCard
          fluid
          className={styles.settingsCard}
        >
          <div className={styles.splitter}>
            <div className={styles.subHeader}>Solstice Routing Settings</div>
            <SolCancelSave
              className={styles.cancelSave}
              cancelText="Cancel"
              acceptingText="Accept"
              acceptingDisabled={!settingIsDirty}
              cancelDisabled={!settingIsDirty}
              cancelClick={() => {
                setOrgSettings(data?.solsticeRoutingOrg)
                setSettingIsDirty(false)
              }}
              hideCancel={false}
              acceptingClick={() => {
                saveOrg()
                setSettingIsDirty(false)
              }}
              loading={loading}
            />
          </div>
          
          <Divider/>

          <div className={styles.rowGroup}>
            <SolCheckbox
              label="Require password on logout"
              checked={orgSettings?.require_password_on_exit ?? false}
              onClick={(val: boolean): void => {
                setOrgSettings({
                  ...orgSettings,
                  require_password_on_exit: val,
                })
                setSettingIsDirty(true)
              }
              }/>
            <SolTooltip text="This will not apply if users login with SSO."/>
          </div>
        </SolCard>

        <SolRouteGuard
          modalProps={{
            modalText: 'Are you sure you want to leave?',
            cancelText: 'Cancel',
          }}
          when={settingIsDirty}
        />
      </ContentWrapper>
    </div>
  )
}

export default SolsticeRoutingSettings
