export enum Color {
    PinkRed = '#fe4062',
    GreenBlue = '#00be89',
    LightBlue = '#55a3d8',
    Green = '#61b424',
    MutedBlue = '#7080d4',
    SkyBlue = '#7ac4e5',
    MutedGray = '#8f9fa5',
    Purple = '#945ecf',
    Gray = '#b8bdbf',
    MutedPink = '#f36f8f',
    YellowOrange = '#f3b11b',
    Orange = '#f95127',
    LightOrange = '#fb8048',
    Red = '#ff423d',
};
