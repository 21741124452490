import {
  SettingsGroupType,
  Template,
} from '../../../graphql/__generated__/types'

import { DefaultTemplate } from '../TemplateTypes'

export const defaultRoomIntelligenceTemplate: Template = {
  ...DefaultTemplate,
  settingsGroup: {
    name: 'Advanced',
    type: SettingsGroupType.RoomIntelligence,
    hidden: false,
  },
  revision: {
    id: '',
    configuration: {
      enableSolsticeConferencing: true,
      enableLocationServices: true,
      occupancySettings: {
        enableOccupancyData: false,
        enableAutoReserve: false,
      },
      endOfMeetingMessage: {
        enabled: false,
        timeBeforeMeeting: 15,
        messageDuration: 10,
      },
      secondaryEndOfMeetingMessage: {
        enabled: false,
        timeBeforeMeeting: 10,
        messageDuration: 10,
      },
    },
  },
  assignedDisplays: {
    totalRecords: 0,
  },
}
