import React from 'react'
import { connect, useSelector } from 'react-redux'
import { dispatchInitMxRenew } from 'shared/store/helpers/maintenance'
import DeploymentPage from './DeploymentPage'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { useLatestVersion } from 'shared/hooks/versions'
import {
  useDeploymentsQuery,
  useSdsPodsPerHostQuery,
  useDeploymentsLazyQuery,
  DeploymentsByDisplay,
  useDeletePodMutation, PlatformConfigQuery, PlatformConfigDocument,
} from 'graphql/__generated__/types'
import { useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { useQuery } from '@apollo/client'

type Props = {
  // aspectRatio?: number
}

export enum SolsticeVersionStatus {
  LatestVersion = 'LatestVersion',
  Upgradeable = 'Upgradeable',
}

export enum SolsticeSubscriptionStatus {
  Current = 'Current',
  ExpiringSoon = 'ExpiringSoon',
  Expired = 'Expired',
}

const DeploymentController = (
  { initFlow }:
    Props & { initFlow: () => void },
) => {

  const {
    searchValue,
    page,
    orderBy,
  } = useDataTable<DeploymentsByDisplay>(DataTableInstance.Deployment)

  const areCountsLoading = false
  const userRole = useSelector((state: any) => state.user.role)

  const [fetchCsvData] = useDeploymentsLazyQuery({
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Deployment),
        nameLike: searchValue,
        offset: 0,
      },
    },
  })

  const {
    customFilters: customVersionFilters,
    setCustomFilter: setCustomVersionFilter,
  } = useCustomFilters<SolsticeVersionStatus>(DataTableInstance.Deployment)

  const {
    customFilters: customSubscriptionFilters,
    setCustomFilter: setCustomSubscriptionFilter,
  } = useCustomFilters<SolsticeSubscriptionStatus>(DataTableInstance.Deployment)

  const versionCustomFilterOptions: {
    solsticeVersionFilters?: string[]
  } = (() => {
    const versionKeys = Object.keys(customVersionFilters?.SolsticeVersionStatus?.values ?? {})
    // If both options together is equiv to no filter
    if ( versionKeys.length > 1) {return {}}
    return { solsticeVersionFilters: versionKeys }
  })()

  const solsticeSubscriptionCustomFilterOptions: {
    solsticeSubscriptionFilters?: string[]
  } = ( () => {
    const solsticeSubscriptionKeys
      = Object.keys(customSubscriptionFilters?.SolsticeSubscriptionStatus?.values ?? {})
    // All three options together is equiv to no filter
    if ( solsticeSubscriptionKeys.length > 2) {return {}}
    return { solsticeSubscriptionFilters: solsticeSubscriptionKeys }
  })()

  const { data, loading: isTableLoading, refetch } = useDeploymentsQuery({
    pollInterval: 20000,
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Deployment),
        nameLike: searchValue,
        ...versionCustomFilterOptions,
        ...solsticeSubscriptionCustomFilterOptions,
        orderBy: { [orderBy?.field ?? 'name']: (orderBy?.direction !== 'ascending' ? 'DESC' : 'ASC') },
        offset: (page.number - 1) * page.size,
        limit: page.size,
      },
    },
  })

  const deployments = data?.deployments

  const aggregate = deployments?.aggregate

  const { full: latestVersion, short: shortVersion } = useLatestVersion()
  if (latestVersion) {}

  const totalPodsCurrentLmsVersion = aggregate?.totalCurrentLmsVersionPods
  const totalPodsExpired = aggregate?.totalExpiredPods
  const totalPodsExpiring = aggregate?.totalExpiringPods

  const { data: sdsData, loading: isSDSLoading } = useSdsPodsPerHostQuery({
    variables: {
      options: {
        ...useAppliedFilterOptions(DataTableInstance.Deployment),
        nameLike: searchValue,
      },
    },
  })

  const [handleDeletePod] = useDeletePodMutation()

  const deletePod = async (podId: string) => {
    await  handleDeletePod({
      variables: {
        options: { podId },
      },
    })
    refetch()
  }

  const hasElementPods = useQuery<PlatformConfigQuery>(PlatformConfigDocument)
    .data
    ?.platformConfig
    ?.hasElementPods

  const isPageLoading = areCountsLoading || isTableLoading || isSDSLoading

  return (<DeploymentPage
    totalPods={aggregate?.totalPods ?? 0}
    shortVersion={shortVersion}
    totalPodsExpired={totalPodsExpired ?? 0}
    totalPodsExpiring={totalPodsExpiring ?? 0}
    totalPodsCurrentLmsVersion={totalPodsCurrentLmsVersion ?? 0}
    fetchCsvData={fetchCsvData}
    customVersionFilters={customVersionFilters}
    setCustomVersionFilter={setCustomVersionFilter}
    customSubscriptionFilters={customSubscriptionFilters}
    setCustomSubscriptionFilter={setCustomSubscriptionFilter}
    sdsData={sdsData?.podsPerSdsHost?.sdsHosts}
    tableData={deployments?.pods ?? []}
    loading={false}
    isPageLoading={isPageLoading}
    initFlow={initFlow}
    userRole={userRole}
    handleDeletePod={deletePod}
    hasElementPods={hasElementPods}
  />)
}

const ConnectedDeployment = connect(
  _ => ({}),
  dispatch => ({ initFlow: dispatchInitMxRenew(dispatch) }),
)(DeploymentController)

export default () => <ConnectedDeployment/>
