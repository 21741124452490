import { toPercentage } from 'shared/core/utils'
import { useGetSoftwareVersion } from 'shared/hooks/versions'
import {
  UpdateablePodsByVersion,
  useUpdateablePodsByVersionQuery,
} from 'graphql/__generated__/types'

export const usePieData = () => {
  const { data, loading } = useUpdateablePodsByVersionQuery()
  const getSoftwareVersion = useGetSoftwareVersion()
  const totalPods = data?.updateablePodsByVersion?.reduce(
    (acc: number, element: UpdateablePodsByVersion) => (acc + element.count), 0)
  return {
    loading,
    pieData:
      (data?.updateablePodsByVersion
        ?.filter(v => v.count)
        .map(v => {
          const version = v.version ? v.version.join('.') : '3.4'
          const count = v.count ?? 0
          return {
            name: `Version ${getSoftwareVersion(version)}`,
            count,
            percentage: toPercentage(count, totalPods as number),
          }
        })
        ?? [])
      || [],
  }
}
