import { Category } from '../../CategoryTypes'

export interface RecommendedCategory extends Category {
  caption: string
  default?: boolean
}

export const recommendedCategories: RecommendedCategory[] = [
  {
    name: 'Room Type',
    id: '',
    caption: 'Huddle, Conference, Public...',
    options: [
      {
        id: '',
        name: 'Huddle',
      },
      {
        id: '',
        name: 'Medium Conference Room',
      },
      {
        id: '',
        name: 'Large Conference Room',
      },
      {
        id: '',
        name: 'Public Area',
      },
      {
        id: '',
        name: 'Personal Office',
      },
    ],
    default: true,
  },
  {
    name: 'Display Type',
    id: '',
    caption: 'Flat Panel, 4K, Projector...',
    options: [
      {
        id: '',
        name: 'Flat Panel',
      },
      {
        id: '',
        name: 'Projector',
      },
      {
        id: '',
        name: '4K',
      },
    ],
  },
  {
    name: 'Interaction',
    id: '',
    caption: 'Touch, Mouse, No interaction...',
    options: [
      {
        id: '',
        name: 'Touch',
      },
      {
        id: '',
        name: 'Mouse',
      },
      {
        id: '',
        name: 'No Interaction',
      },
    ],
  },
]
