import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export const FormValidation = ({ children }: { children: React.ReactNode }) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  return (
    /**           ------ DANGER ------
     * FormContext renamed to FormProvider in react-hook-form v6,
     * this is NOT reflected in the react-hook-form "legacy" docs
     */
    <FormProvider {...methods}>
      <React.Fragment>{children}</React.Fragment>
    </FormProvider>
  )
}
