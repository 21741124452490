import React, { useState } from 'react'
import qs from 'qs'
import { Button, Menu, Header } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import classNames from 'classnames'
import {
  ScheduledTask,
  UpdateablePod,
  UpdateablePodsQuery,
  UpdateablePodsQueryResult,
  UpdateScheduledTaskInput,
} from 'graphql/__generated__/types'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { SolsticeUpdatesRide } from './SolsticeUpdatesRide'
import { SolTabs, SolNotification } from 'SolComponents'
import styles from './UpdatesPage.module.scss'
import { useJoyride, Ride } from 'shared/hooks/useJoyride'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { UpdatePodsModal } from './UpdatePodsModal'
import { UpdatesTable } from './UpdatesTable'
import { UpdatesHeaderCard } from './UpdatesHeaderCard'
import { TasksTab } from './Tabs/TasksTab/TasksTab'
import HistoryTab from './Tabs/HistoryTab/HistoryTabController'
import { useNumSelectedPods } from './hooks/useSelectedPods'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { getNumberOfCurrentPods } from './Tabs/scheduledTask'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'
import { UpdateScheduledTaskResult } from './types'

interface Props extends RouteComponentProps {
    onTaskCreated: (displayIds: string[]) => void
    onTaskDeleted: () => void
    updateScheduledTask: (options: UpdateScheduledTaskInput) => Promise<UpdateScheduledTaskResult>
    updateablePods?: UpdateablePodsQuery
    currentTasks: ScheduledTask[]
    versionUtils: VersionUtils
    loading: boolean
    fetchCsvData: () => Promise<UpdateablePodsQueryResult>
}

const UpdatesPage = ({
  location,
  history,
  onTaskCreated,
  onTaskDeleted,
  updateablePods,
  currentTasks = [],
  versionUtils,
  loading,
  fetchCsvData,
  updateScheduledTask,
}: Props) => {
  const { setSelectedItems } = useDataTable<UpdateablePod>(DataTableInstance.Updates)

  const [currentTab, setCurrentTab] = useState<string>(
    (qs.parse(location.search.replace('?', '')) || {}).tab || ('updates' as any),
  )

  const numUpgradeablePods = updateablePods?.updateablePods?.updateablePodsCount || 0
  const totalPods = updateablePods?.updateablePods?.totalPodsCount || 0
  const numSelectedPods = useNumSelectedPods(numUpgradeablePods)
  const numScheduledTasks = currentTasks.length
  const forceShowRide = (qs.parse(location.search.replace('?', '')) || {}).onboard === 'true'
  const tabs = [
    {
      displayName: 'Updates',
      name: 'updates',
    },
    {
      displayName: (
        <Menu.Item key="tasks">
          Tasks
          {numScheduledTasks > 0 && (
            <SolNotification className={styles.notification} type="info" notifications={numScheduledTasks} />
          )}
        </Menu.Item>
      ),
      name: 'tasks',
    },
    {
      displayName: 'History',
      name: 'history',
    },
  ]
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const joyrideProps = useJoyride(Ride.SolsticeUpdates)

  const joyride
    = (!loading && <SolsticeUpdatesRide forceShowRide={forceShowRide} {...joyrideProps} />) || null
  const updateButton = (
    <Button
      primary
      disabled={modalIsOpen || !joyrideProps.show && numSelectedPods === 0}
      onClick={() => {
        setModalIsOpen(true)
      }}
      className={classNames('joyride-update-pods-button', styles.updatePodsButton)}
    >
      Update Pods
    </Button>
  )

  return (
    <div>
      <SolFilterHeaderCard
        header="Solstice Software Updates"
        filteredItems={numSelectedPods}
        tableId={DataTableInstance.Updates}
        getTotalPods={() => totalPods ?? 0}
      />
      <ContentWrapper>
        <UpdatesHeaderCard
          taskTab={() => {
            setCurrentTab('tasks')
            history.replace('?tab=tasks')
          }}
          data={updateablePods?.updateablePods}
          scheduledTaskCount={currentTasks.map(getNumberOfCurrentPods).reduce((sum, val) => sum + val, 0)}
        />
        {modalIsOpen && <UpdatePodsModal
          latestVersion={versionUtils.latestVersion.full}
          totalUpdateablePods={numUpgradeablePods}
          releases={versionUtils.releases}
          onCloseModal={(() => setModalIsOpen(false))}
          onTaskCreated={((displayIds: string[]) => {
            onTaskCreated(displayIds)
            setCurrentTab('tasks')
            setSelectedItems({ items: [], ids: [] })
          })}
        />}
        <Header
          className={styles.solsticeHeader}
          as="h2"
          content="Solstice Software Updates Overview"
          textAlign="center"
        />
        <SolTabs
          alignment="center"
          menuItems={tabs}
          currentTab={currentTab}
          onTabChange={tab => {
            setCurrentTab(tab)
            history.replace(`?tab=${tab}`)
          }}
        />
        <div className={styles.tabWrapper}>
          {currentTab === 'updates' && (
            <UpdatesTable
              joyride={joyride}
              updateButton={updateButton}
              data={updateablePods}
              loading={loading}
              totalItemsSelected={numSelectedPods}
              fetchCsvData={fetchCsvData}
              versionUtils={versionUtils}
            />
          )}
          {currentTab === 'tasks' && <TasksTab
            tasks={currentTasks}
            loading={loading}
            onTaskDelete={onTaskDeleted}
            updateScheduledTask={updateScheduledTask}
            versionUtils={versionUtils}
          />}
          {currentTab === 'history' && <HistoryTab
            versionUtils={versionUtils}
          />}
        </div>
      </ContentWrapper>
    </div>
  )
}

export default withRouter(UpdatesPage)
