import { ExpiredDisplaysQuery } from 'graphql/__generated__/hasura-types'
import { format } from 'date-fns'

type ExpiredDisplay = ExpiredDisplaysQuery['_displays'][number]

const getMxExpires = (display: ExpiredDisplay) => {
  const flexeraEnd = display?.subscription?.flexera_maintenance_end
  const maintenanceEnd = display?.subscription?.maintenance_end
  const dateFormat = 'M/d/yyyy'

  if (flexeraEnd) {
    return format(new Date(flexeraEnd), dateFormat)
  }

  if (maintenanceEnd) {
    return format(new Date(maintenanceEnd), dateFormat)
  }
  return format(new Date(), dateFormat)
}

export const getRenewFlowSelectedPods = (
  selectedDisplays: ExpiredDisplay[],
) => {
  const flowSelectedPods = {}

  if (selectedDisplays && selectedDisplays.length > 0) {
    selectedDisplays.map(selectedDisplay => {
      const flowSelectedPod = {
        price: 0,
        hardware: selectedDisplay.hardware || '(unknown)',
        mxExpires: getMxExpires(selectedDisplay),
        name: selectedDisplay.name,
        id: selectedDisplay.id,
        edition: selectedDisplay.deployment?.edition || '',
        license: selectedDisplay.deployment?.license || '',
      }
      Object.defineProperty(flowSelectedPods, flowSelectedPod.id ?? '', {
        enumerable: true,
        writable: true,
        value: flowSelectedPod,
      })
    })
  }

  return flowSelectedPods
}
