import React, { useState } from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  useLocationsPageQuery,
  useLocationsPageTableQuery,
  LocationsPageTableQuery,
} from 'graphql/__generated__/hasura-types'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { LocationPageFilters } from '../Utils/get-hasura-params'
import { AreYouSureFloorModalContext, ChangeFloorModal } from './ChangeFloorModal'
import { AreYouSureModalContext, ChangeLocationModal } from './ChangeLocationModal'
import { Pod, useColumns } from './useColumns'

type Props = {
  searchParamsAll: LocationPageFilters
  setToast: (t: boolean) => void
  mapBounds?: number[][]
  tabs: () => JSX.Element
}

export const LocationsTable = React.memo(function({ searchParamsAll, setToast, mapBounds, tabs }: Props) {
  const searchParams = searchParamsAll.tableFilters
  const graphSearchParams = searchParamsAll.selectedItemsFilters

  const [displays, setDisplays] = useState<LocationsPageTableQuery['_displays']>([])
  const [areYouSureModalContext, setAreYouSureModalContext] = useState<AreYouSureModalContext>({ isOpen: false })

  const [areYouSureFloorModalContext, setAreYouSureFloorModalContext] = useState<AreYouSureFloorModalContext>({
    isFloorOpen: false,
  })

  const { data, loading } = useLocationsPageTableQuery({
    fetchPolicy: 'cache-and-network',
    variables: searchParams,
    pollInterval: 5000,
  })

  useDeepCompareEffect(() => {
    setDisplays(data?._displays ?? [])
  }, [data?._displays ?? []])

  const getUnknownBuilding = (item: Pod) => {
    return item?.location?.address?.address_full === null || !item?.location?.address?.address_full
  }

  const { data: locationPageData, loading: locationPageLoading } = useLocationsPageQuery({
    variables: {
      where: graphSearchParams,
      whereTotal: searchParams.where,
    },
  })

  const selectedPodCount = locationPageData?.filteredDisplayCount?.aggregate?.count ?? 0
  const totalItems = locationPageData?.totalDisplayCount?.aggregate?.count ?? 0

  const columns = useColumns({
    selectedPodCount,
    setAreYouSureModalContext,
    setAreYouSureFloorModalContext,
    searchParams,
    mapBounds,
  })

  return (
    <div>
      <ServerDataTable<Pod>
        id={DataTableInstance.Locations}
        title="Pod Locations"
        columnManager={tabs}
        addableColumns={false}
        data={displays}
        defaultSort={{ field: 'name', direction: 'ascending' }}
        scrollable={false}
        errorOnRow={getUnknownBuilding}
        columns={columns}
        loading={loading || locationPageLoading}
        totalItems={totalItems}
        totalItemsSelectable={totalItems}
        totalItemsSelected={selectedPodCount}
        selectable
        padded={false}
      />
      <ChangeFloorModal
        searchParamsAll={searchParamsAll}
        setToast={t => setToast(t)}
        areYouSureFloorModalContext={areYouSureFloorModalContext}
        setAreYouSureFloorModalContext={a => setAreYouSureFloorModalContext(a)}
      />
      <ChangeLocationModal
        searchParamsAll={searchParamsAll}
        setToast={t => setToast(t)}
        areYouSureModalContext={areYouSureModalContext}
        setAreYouSureModalContext={a => setAreYouSureModalContext(a)}
      />
    </div>
  )
})
