import React from 'react'
import AvailabilityTable from './AvailabilityTable'
import { PieSector } from 'SolComponents/SolDonutChart/SolDonutChart'
import Status, { StatusColors, labelColor } from './StatusChart'
import styles from './index.module.scss'
import { useCustomFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import { SolArrowButton } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { toPercentage } from 'shared/core/utils'
import {
  AvailabilityByDisplay,
  AvailabilityQueryResult,
} from '../../graphql/__generated__/types'
import SolElementInfoMessage from '../../SolComponents/SolElementInfoMessage/SolElementInfoMessage'

type Props = {
  aspectRatio?: number
  rows: AvailabilityByDisplay[]
  pods: number
  subscribedPods: number
  podsOnlineNow: number
  podsOnlineSinceYesterday: number
  loading: boolean
  hasElementPods?: boolean | null
  fetchCsvData: () => Promise<AvailabilityQueryResult>
}

export enum CurrentlyStatus {
  Offline = 'Offline',
  Online = 'Online',
}

const responsiveType: ResponsiveType = {
  desktop: {
    breakpoint: { max: 9999, min: 1390 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1390, min: 970 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 970, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
}

const AvailabilityPage = ({
  aspectRatio,
  rows,
  pods,
  subscribedPods,
  podsOnlineNow,
  podsOnlineSinceYesterday,
  loading,
  fetchCsvData,
  hasElementPods,
}: Props) => {

  const { customFilters, setCustomFilter } = useCustomFilters<CurrentlyStatus>(DataTableInstance.Availability)

  const addOnline = () => addCurrentlyFilter('Online', 'Online', CurrentlyStatus.Online)

  const addOffline = () => addCurrentlyFilter('Offline', 'Offline', CurrentlyStatus.Offline)

  const addCurrentlyFilter = (internalName: string, displayName: string, status: CurrentlyStatus) => {
    const values = {
      ...customFilters.currentlyStatus?.values,
      [internalName]: {
        displayName,
        value: status,
      },
    }
    setCustomFilter('currentlyStatus', {
      values,
      color: 'blue',
    })
  }

  const getOnlineData = (totDisplaysOnline: number, totDisplayCount: number, offCount: number): PieSector[] => {
    return [
      {
        name: 'Online',
        count: totDisplaysOnline,
        percentage: toPercentage(totDisplaysOnline, totDisplayCount, 1),
        fill: StatusColors.SUCCESS,
        labelColor: labelColor,
        onClick: addOnline,
      },
      {
        name: 'Offline',
        count: offCount,
        percentage: toPercentage(offCount, totDisplayCount, 1),
        fill: StatusColors.ERROR,
        labelColor: labelColor,
        onClick: addOffline,
      },
    ]
  }

  const getUpTimeData = (upTime: number, totDisplayCount: number, downCount: number): PieSector[] => {
    return [
      {
        name: 'Yes',
        count: upTime,
        percentage: toPercentage(upTime, totDisplayCount, 1),
        fill: '#1b2b86',
        labelColor: '#333333',
      },
      {
        name: 'No',
        count: downCount,
        percentage: toPercentage(downCount, totDisplayCount, 1),
        fill: '#6bcdcf',
        labelColor: '#333333',
      },
    ]
  }

  const currentOnlineData
    = !(podsOnlineNow == null) && !(subscribedPods == null)
      ? getOnlineData(podsOnlineNow, subscribedPods, subscribedPods - podsOnlineNow)
      : undefined

  const uptimeData
    = !(podsOnlineSinceYesterday == null) && !(subscribedPods == null)
      ? getUpTimeData(podsOnlineSinceYesterday, subscribedPods, subscribedPods - podsOnlineSinceYesterday)
      : undefined

  return (
    <div>
      <SolFilterHeaderCard
        header="Availability"
        tableId={DataTableInstance.Availability}
        filteredItems={pods}
      />
      <ContentWrapper>
        <div className={styles.chartContainer}>
          <Carousel
            draggable={false}
            responsive={responsiveType}
            ssr={!!aspectRatio}
            deviceType={aspectRatio ? 'desktop' : ''}
            containerClass={styles.topRow}
            partialVisible
            customLeftArrow={<SolArrowButton direction="left" />}
            customRightArrow={<SolArrowButton direction="right" />}
          >
            <div>
              <Status
                data={currentOnlineData}
                loading={loading}
                dataTestId="current-card"
                title="Currently Online"
              />
            </div>
            <div>
              <Status
                data={uptimeData}
                loading={loading}
                dataTestId="uptime-card"
                title="Uptime > 24 Hours"
              />
            </div>
          </Carousel>
          {hasElementPods
            && (
              <SolElementInfoMessage
                message="Solstice Element pods are not included"
              />
            )
          }
        </div>
        <AvailabilityTable
          data={rows ?? []}
          totalItems={pods || 0}
          loading={loading}
          fetchCsvData={fetchCsvData}
        />
      </ContentWrapper>
    </div>
  )
}

export default AvailabilityPage

