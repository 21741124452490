import React, { useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import { SolCloseIcon } from '../../SolComponents/Icons'
import SolStickyBanner from '../../SolComponents/SolStickyBanner/SolStickyBanner'
import { useQuery } from '@apollo/client'
import {
  PlatformConfigDocument,
  PlatformConfigQuery,
} from '../../graphql/__generated__/types'

// Importing styles from Maintenance banned, because they are using the same stype properties
import styles from '../Maintenance/styles.module.scss'


export type State = {
  showBanner: boolean
}

export interface StateFromProps {
  showBanner: boolean
  setBannerOpen: (open: boolean | undefined | null) => void
  onButtonClick: () => void
}

const EndOfLifeBanner = ({ showBanner, setBannerOpen, onButtonClick }: StateFromProps) => {
  const { data } = useQuery<PlatformConfigQuery>(PlatformConfigDocument)

  useEffect(() => {
    setBannerOpen(data?.platformConfig?.hasGen2Pods)
  }, [data])

  const onCloseBanner = () => {
    setBannerOpen(false)
  }

  if (showBanner) {
    return (
      <SolStickyBanner
        color="warning"
        text={
          <span className="renewText">
            There are Gen2i pods in your fleet that are EOL as of December 31, 2023.
          </span>
        }
      >
        <div className={styles.fixButton}>
          <Button
            className={styles.fixingBasic}
            onClick={onButtonClick}
          >
            Learn More
          </Button>
        </div>
        <span className={styles.closeBanner}>
          <SolCloseIcon onClick={onCloseBanner} />
        </span>
      </SolStickyBanner>
    )
  }
  return null
}

export default EndOfLifeBanner
