import { MutableRefObject, useState, useRef } from 'react'
import { format, endOfDay, differenceInDays, isSameDay } from 'date-fns'
import { TimeInterval } from 'graphql/__generated__/types'
import { getPreviousDate, getNextDate } from '../chartUtils'
import { ChartTimePeriod } from '../types'
import { Props } from './TimeInterval'

export const useTimeInterval = ({ timePeriod, interval, setTimePeriod, setInterval }: Props) => {
  const calendarRef: MutableRefObject<any> = useRef(null)

  let [range, setRange] = useState({
    fromDate: format( getPreviousDate(timePeriod, new Date()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    toDate: format( new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
  })

  const onCalendarChange = (dates: Date[]) => {
    const [fromDate, toDate] = dates
    if (dates.length > 1) {
      if (fromDate.valueOf() !== toDate.valueOf()) {
        const newRange = {
          fromDate: format(new Date(fromDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
          toDate: format( endOfDay(new Date(toDate)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
        }
        setTimePeriod(ChartTimePeriod.CUSTOM, newRange)
        const difference = differenceInDays( new Date(toDate), new Date(fromDate) )
        if (difference >= 90) {
          setInterval(TimeInterval.Month)
        } else {
          setInterval(TimeInterval.Day)
        }
        setRange(() => newRange)
      } else {
        calendarRef.current.flatpickr.setDate([new Date(range.fromDate), new Date(range.toDate)])
      }
    }
  }

  const onCalendarClose = (dates: Date[]) => {
    if (dates.length < 2) {
      calendarRef.current.flatpickr.setDate([new Date(range.fromDate), new Date(range.toDate)])
    }
  }

  const resetInitialRange = (period: ChartTimePeriod) => {
    const initialDate = {
      fromDate: format(getPreviousDate(period, new Date()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
      toDate: format( new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
    }
    setRange(initialDate)
    return initialDate
  }

  const clearCalendar = () => {
    calendarRef.current.flatpickr.clear()
  }

  const onHours = () => {
    // Changing Time Period to "Last 48 hours" mean the interval MUST BE "Hours"
    setTimePeriod(ChartTimePeriod.HOURS, resetInitialRange(ChartTimePeriod.HOURS))
    setInterval(TimeInterval.Hour)
    clearCalendar()
  }

  const onWeek = () => {
    // Changing Time Period to "Last week" mean the interval MUST BE "Days"
    setTimePeriod(ChartTimePeriod.WEEK, resetInitialRange(ChartTimePeriod.WEEK))
    setInterval(TimeInterval.Day)
    clearCalendar()
  }

  const onMonth = () => {
    // Changing Time Period to "Last Month" means the interval MUST BE "Days" OR "Weeks"
    setTimePeriod(ChartTimePeriod.MONTH, resetInitialRange(ChartTimePeriod.MONTH))
    if (interval === TimeInterval.Hour) {
      setInterval(TimeInterval.Day)
    }
    if (interval === TimeInterval.Month) {
      setInterval(TimeInterval.Week)
    }
    clearCalendar()
  }

  const onQuarter = () => {
    // Changing Time Period to "Last Quarter" means the interval CANNOT BE "Hours"
    setTimePeriod(ChartTimePeriod.QUARTER, resetInitialRange(ChartTimePeriod.QUARTER))
    if (interval === TimeInterval.Hour) {
      setInterval(TimeInterval.Day)
    }
    clearCalendar()
  }

  const onCustom = () => {
    setTimePeriod(ChartTimePeriod.CUSTOM)
    openCalendar()
  }

  const shiftDateRangeBackward = () => {
    const fromDate = format( getPreviousDate(timePeriod, new Date(range.fromDate)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
    const toDate = format( new Date(range.fromDate), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
    const newRange = { fromDate, toDate }
    setRange(newRange)
    setTimePeriod(timePeriod, newRange)
  }

  const shiftDateRangeForward = () => {
    const now = new Date()
    const fromDate = format( getNextDate(timePeriod, new Date(range.fromDate)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
    const toDate = format( getNextDate(timePeriod, new Date(range.toDate)), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
    const toDateCorrectedForToday = format( (isSameDay( now, new Date(toDate) )
      ? now : new Date(toDate) ), 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
    if ( new Date(toDate) <= new Date()) {
      const newRange = { fromDate, toDate: toDateCorrectedForToday }
      setRange(newRange)
      setTimePeriod(timePeriod, newRange)
    }
  }

  const openCalendar = () => {
    // Work around to open the calendar.
    // In recent versions of react-flatpickr the function to open the calendar stopped working
    // The problem might be around this library because it uses the latest version of flatpickr and doesn't have
    // a defined version number in the his package.json this makes it
    // vunerable to issues or incompatibilities or problem raise by changes in the flatpickr API.
    setTimeout(() => {
      calendarRef.current?.flatpickr.open()
    })
  }

  const isCustomIntervalActive = timePeriod === ChartTimePeriod.CUSTOM

  const openOnCalLabel = () => {
    if (isCustomIntervalActive) {
      openCalendar()
    }
  }

  return {
    range,
    calendarRef,
    onCalendarChange,
    onCalendarClose,
    openCalendar,
    onHours,
    onWeek,
    onMonth,
    onQuarter,
    onCustom,
    setPreviousDate: shiftDateRangeBackward,
    nextDate: shiftDateRangeForward,
    isCustomIntervalActive,
    openOnCalLabel,
  }
}
