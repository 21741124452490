import React, { useState, useEffect } from 'react'
import styles from './UniqueSettings.module.scss'
import { useDirtyTemplate } from 'shared/hooks/management/useDirtyTemplate'
import { useDisplay } from 'shared/hooks/management/useDisplay'
import { ServerSelectedItems } from 'components/DataTableSlim/types'
import {
  SettingsGroupType,
  SettingsGroup,
  Template,
  UniqueSettingsValidation,
  Display,
  AssignTemplateMutation,
  ManagementStatus,
} from 'graphql/__generated__/types'
import { defaultUniqueSettingsTemplate } from './UniqueSettingsTypes'
import { useFormContext } from 'react-hook-form'
import { EthernetFields, WelcomeScreenFields, CalendarFields, WifiFields } from './UniqueSettingsFields'
import { SolStatus, SolButton } from 'SolComponents'
import { ExecutionResult } from 'graphql'
import { useAlerts } from 'shared/hooks/useAlerts'

type Props = {
  fieldValidation: UniqueSettingsValidation
  pod: Display
  setPod: (pod: Display) => void
  template?: Template & {
    settingsGroup: Pick<SettingsGroup, 'name'>
  }
  handleNext: () => void
  assignTemplates?: (
    selectedItems: ServerSelectedItems,
    templateId: string,
    settingsGroupType: SettingsGroupType
  ) => Promise<ExecutionResult<AssignTemplateMutation>>
  isFinalPod: boolean
}

export default function UniqueSettingsForm({
  fieldValidation,
  pod,
  setPod,
  template,
  handleNext,
  assignTemplates,
  isFinalPod,
}: Props) {
  const assignedTemplate = pod.assignedTemplate?.template ?? defaultUniqueSettingsTemplate
  const { updateTemplate, currentTemplate } = useDirtyTemplate(assignedTemplate)
  const { errors } = useFormContext()
  useEffect(() => {
    updateTemplate(assignedTemplate)
  }, [assignedTemplate, template])
  const { updateDisplay, updatingDisplay: saving } = useDisplay(pod.id ?? '')
  const [isSaveInProgress, setSaveInProgress] = useState(false)
  const saveDisabled = Object.keys(errors).length > 0 || saving || isSaveInProgress
  const { showError } = useAlerts()
  const handleSave = async () => {
    setSaveInProgress(true)
    await updateDisplay({
      displayId: pod.id,
      settingsGroupType: SettingsGroupType.UniqueSettings,
      configuration: currentTemplate.revision.configuration,
    })
    if (template?.settingsGroup?.type && template?.revision) {
      await updateDisplay({
        displayId: pod.id,
        settingsGroupType: template?.settingsGroup?.type,
        configuration: template?.revision?.configuration,
      })
    }
    const response
      = (!!assignTemplates
        && (await assignTemplates(
          { includedIds: [pod.id ?? ''] },
          template?.id ?? '',
          SettingsGroupType.UniqueSettings,
        )))
      || null
    if (response?.data?.assignTemplate.validation.incompatibleDisplays?.length) {
      showError('Something went wrong updating the display')
    }
    setPod({
      ...pod,
      assignedTemplate: {
        template: {
          ...assignedTemplate,
          revision: {
            id: '',
            configuration: currentTemplate.revision.configuration,
          },
        },
      },
    })
    setSaveInProgress(false)
    handleNext()
  }

  return (
    <>
      <div className={styles.title}>
        <SolStatus status={ManagementStatus.Online} iconSize="small" />
        <h1>{pod.name}</h1>
      </div>
      <div className={styles.inputs}>
        {fieldValidation.ETHERNET && (
          <EthernetFields
            validation={fieldValidation.ETHERNET}
            configuration={currentTemplate.revision.configuration.ETHERNET}
            setConfiguration={ETHERNET =>
              updateTemplate({
                ...currentTemplate,
                revision: {
                  ...currentTemplate.revision,
                  configuration: {
                    ...currentTemplate.revision.configuration,
                    ETHERNET,
                  },
                },
              })
            }
          />
        )}
        {fieldValidation.WELCOME_SCREEN && (
          <WelcomeScreenFields
            validation={fieldValidation.WELCOME_SCREEN}
            configuration={currentTemplate.revision.configuration.WELCOME_SCREEN}
            setConfiguration={WELCOME_SCREEN =>
              updateTemplate({
                ...currentTemplate,
                revision: {
                  ...currentTemplate.revision,
                  configuration: {
                    ...currentTemplate.revision.configuration,
                    WELCOME_SCREEN,
                  },
                },
              })
            }
          />
        )}
        {fieldValidation.CALENDAR && (
          <CalendarFields
            validation={fieldValidation.CALENDAR}
            configuration={currentTemplate.revision.configuration.CALENDAR}
            setConfiguration={CALENDAR =>
              updateTemplate({
                ...currentTemplate,
                revision: {
                  ...currentTemplate.revision,
                  configuration: {
                    ...currentTemplate.revision.configuration,
                    CALENDAR,
                  },
                },
              })
            }
          />
        )}
        {fieldValidation.WIFI && (
          <WifiFields
            validation={fieldValidation.WIFI}
            configuration={currentTemplate.revision.configuration.WIFI}
            setConfiguration={WIFI =>
              updateTemplate({
                ...currentTemplate,
                revision: {
                  ...currentTemplate.revision,
                  configuration: {
                    ...currentTemplate.revision.configuration,
                    WIFI,
                  },
                },
              })
            }
          />
        )}
      </div>
      <div className={styles.saveButton}>
        <SolButton
          disabled={saveDisabled}
          primary
          text={isFinalPod ? 'Save & Close' : 'Save & Next'}
          handleClick={handleSave}
        />
      </div>
    </>
  )
}
