import React from 'react'
import {
  BuildingLocationsDocument,
  BuildingLocationsQuery,
  BuildingLocationsQueryVariables,
  useBuildingLocationsQuery,
} from 'graphql/__generated__/hasura-types'
import pick from 'lodash/pick'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { Column, DataTableInstance, Direction } from 'components/DataTableSlim/DataTableSlim'
import styles from './LocationsTable.module.scss'
import { useTableExport } from 'components/DataTableSlim/Hooks/useTableExport'
import { BuildingName } from './BuildingName'
import { Building } from './types'

type Props = {
  variables: BuildingLocationsQueryVariables
  tabs: () => JSX.Element
}

const defaultSort = {
  field: 'email',
  direction: 'ascending' as Direction,
}

const columns: Column<Building>[] = [
  {
    name: 'building',
    className: styles.building,
    render: (building, toCsv) => {
      const buildingAddress = building.building ?? building.address ?? ''
      if (toCsv) {
        return buildingAddress
      }
      return <BuildingName id={building.id} name={buildingAddress} />
    },
    width: 2,
  },
  {
    name: 'address',
    width: 2,
  },
  {
    name: 'podCount',
    displayName: '# of Pods',
    render: building => building.totalPods.aggregate?.count,
    width: 1,
  },
  { name: 'city', width: 1 },
  {
    name: 'country',
    width: 1,
  },
]

export const BuildingLocationsTable = ({ variables, tabs }: Props) => {
  const { data, loading } = useBuildingLocationsQuery({
    fetchPolicy: 'cache-and-network',
    variables,
  })
  const buildings = data?.buildings ?? []
  const totalItems = data?.totalBuildings.aggregate?.count ?? 0
  const { onExport, isLoading: loadingExport } = useTableExport<BuildingLocationsQuery, Building>({
    title: 'Building Locations',
    query: BuildingLocationsDocument,
    searchOptions: pick(variables, ['where', 'whereDisplay']),
    totalItems,
    resultMapper: (result: BuildingLocationsQuery) => result.buildings,
    addableColumns: false,
  })
  return (
    <div>
      <ServerDataTable<Building>
        id={DataTableInstance.Buildings}
        title="Building Locations"
        columnManager={tabs}
        addableColumns={false}
        data={buildings}
        defaultSort={defaultSort}
        columns={columns}
        loading={loading}
        totalItems={totalItems}
        allowExportToCsv
        exportProcessing={loadingExport}
        onExport={onExport}
        fixed
        padded={false}
      />
    </div>
  )
}
