import { useQuery } from '@apollo/client'
import compareVersions from 'compare-versions'
import { VersionsDocument, VersionsQuery } from 'graphql/__generated__/types'

export interface VersionUtils {
  releases: Release[]
  latestVersion: LatestVersion
  getSoftwareVersion: (version: string) => string
}

export interface LatestVersion {
  full: string
  short: string
}

export interface Release {
  key: string
  text: string
  value: string
  date: string
}

export const useVersionUtils = (): VersionUtils => {
  const { data } = useQuery<VersionsQuery>(VersionsDocument)

  const getSoftwareVersion = (version: string) => {
    const releasesRaw = data?.versions?.[0]?.releases ?? []
    const release = releasesRaw.find(r => r?.version === version)
    return release?.customerFacingVersion ?? version ?? '(unknown)'
  }

  const latestVersion = ((): LatestVersion => {
    const versions
    = data?.versions?.[0]?.releases?.map(r => ({
      full: r?.version ?? '',
      short: r?.customerFacingVersion ?? '',
    })) ?? []
    const [version] = versions.sort((v1, v2) => compareVersions(v2.full, v1.full))
    return version ?? { full: '3.4', short: '3.4' }
  })()

  const releases = ((): Release[] => {
    const versionsObj = data?.versions
    const versionReleases = versionsObj && versionsObj[0] && versionsObj[0].releases ? versionsObj[0].releases : []
    return versionReleases?.map((release, index) => {
      const releaseV = release?.version || ''
      let releaseText = 'v' + getSoftwareVersion(releaseV)
      if (index === versionReleases.length - 1) {
        releaseText += ' (latest)'
      }
      return { key: releaseV, text: releaseText, value: releaseV, date: release?.date || '' }
    })
  })()

  return {
    releases,
    getSoftwareVersion,
    latestVersion,
  }
}
