import React, { useState, useEffect } from 'react'
import { SolInput, SolTooltip, SolPassword, SolButton } from 'SolComponents'
import styles from './UniqueSettingsInput.module.scss'
import classNames from 'classnames'
import { UniqueSettingsConfiguration, SettingsGroupType } from 'graphql/__generated__/types'
import { defaultUniqueSettingsTemplate } from '../UniqueSettingsTypes'
import { useDisplay } from 'shared/hooks/management/useDisplay'
import { FormValidation } from 'shared/context/FormValidation'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { ValidationInput } from 'components/FormValidation/ValidationInput'
import { useFormContext } from 'react-hook-form'
import LoadingBar from 'components/Loaders/LoadingBar'
import { NetworkStatus } from '@apollo/client'
import { InputOnChangeData } from 'semantic-ui-react'
import { Props as SolTooltipProps } from 'SolComponents/SolTooltip/SolTooltip'

type TooltipValues = {
  setting: string
  template: string
}

export const getTooltipText = ({ setting, template }: TooltipValues) =>
  [
    `${setting} is a unique setting in the ${template} template,`,
    'and needs to be configured individually on every Pod.',
    'You will be prompted to enter this information for the Pod(s)',
    'selected after applying the template in the Pods table.',
  ]

export interface Props {
  label: string
  tooltipText?: SolTooltipProps['text']
  size?: 'tiny' | 'small' | 'medium' | 'large'
  getValue: (uniqueSettings: UniqueSettingsConfiguration) => any
  updateConfig: (uniqueSettings: UniqueSettingsConfiguration, uniqueValue: any) => UniqueSettingsConfiguration
  inline?: boolean
  podId?: string // defined if within PodConfig page
  variant?: 'text' | 'password'
  name?: string
  rules?: RegisterOptions
  requiredIfPopulated?: boolean
  maxLength?: number
  placeholder?: string
  notifications?: JSX.Element | JSX.Element[]
}

function UniqueSettingsInput({
  label,
  size = 'medium',
  getValue,
  updateConfig,
  inline,
  podId,
  variant = 'text',
  name = '',
  rules,
  requiredIfPopulated,
  maxLength,
  placeholder = '',
  notifications,
}: Props) {
  const { updateDisplay, updatingDisplay: saving, networkStatus, templates } = useDisplay(podId ?? '')
  const uniqueSettingsTemplate = templates.uniqueSettings?.template ?? defaultUniqueSettingsTemplate
  const value = getValue(uniqueSettingsTemplate.revision.configuration)

  const loading = networkStatus === NetworkStatus.loading
  const [uniqueValue, setUniqueValue] = useState<string>()
  useEffect(() => {
    if (!loading) {
      setUniqueValue(value)
    }
  }, [loading, value])

  const isDirty = value !== uniqueValue
  const [showCancelSave, setShowCancelSave] = useState(false)
  const sizeClass = classNames([
    { [styles.tiny]: size === 'tiny' },
    { [styles.small]: size === 'small' },
    { [styles.defaultMedium]: size === 'medium' },
    { [styles.large]: size === 'large' },
  ])
  const outerContainerClasses = classNames([styles.uniqueSetting, sizeClass, { [styles.inline]: inline }])
  const required = !!podId && (!!rules?.required || (
    requiredIfPopulated && !!value && value !== ''
  ) )
  const innerContainerClasses = classNames([
    sizeClass,
    { [styles.float]: podId },
    { [styles.active]: showCancelSave },
    { [styles.disabled]: !podId },
  ])
  const configuration = updateConfig(uniqueSettingsTemplate?.revision.configuration, uniqueValue)
  const save = async () => {
    if (podId) {
      try {
        const result = await updateDisplay({
          displayId: podId,
          settingsGroupType: SettingsGroupType.UniqueSettings,
          configuration,
        })
        const savedValue = getValue(
          result?.data?.updateDisplay?.display?.assignedTemplates?.find(
            t => t?.settingsGroup?.type === SettingsGroupType.UniqueSettings,
          )?.template?.revision?.configuration,
        )
        setUniqueValue(savedValue)
      } finally {
        setShowCancelSave(false)
      }
    }
  }
  const { errors } = useFormContext()
  const TextInput = podId && variant === 'password' ? SolPassword : SolInput
  const Input = podId && name && rules ? ValidationInput : TextInput

  return (
    <div
      className={outerContainerClasses}
      onBlur={e => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
          setShowCancelSave(false)
        }
      }}
    >
      <div>
        <LoadingBar visible={saving} header />
        <div className={innerContainerClasses}>
          <Input
            isDirty={isDirty}
            size={size}
            label={label}
            required={required}
            value={podId ? uniqueValue : 'Unique To Pod'}
            maxLength={maxLength}
            onChange={(e: React.SyntheticEvent<HTMLInputElement, Event> | null, val: InputOnChangeData) => {
              setUniqueValue(val.value?.toString())
            }}
            onClear={() => {
              setUniqueValue('')
            }}
            disabled={!podId || saving || loading}
            onFocus={() => setShowCancelSave(true)}
            variant={podId ? variant : 'text'}
            name={name}
            placeholder={placeholder}
            rules={rules ? { ...rules, required } : {}}
          />
          {!!notifications && (
            <span className={styles.fixBluePillNotification}>
              {notifications}
            </span>
          )}
          {!!podId && showCancelSave && (
            <div className={styles.saveCancel}>
              <SolButton
                primary={false}
                basic
                color="blue"
                text="CANCEL"
                disabled={saving || !isDirty}
                handleClick={() => {
                  setUniqueValue(value)
                  setShowCancelSave(false)
                }}
                className={styles.button}
                containerClassName={styles.cancel}
                isLink
              />
              <SolButton
                primary={false}
                basic
                color="blue"
                text="SAVE"
                disabled={(!!(name && rules) && !!errors[name]) || saving || !isDirty}
                handleClick={save}
                className={styles.button}
                containerClassName={styles.save}
                isLink
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default (props: Props) => (
  <FormValidation>
    {props.tooltipText && (
      <SolTooltip
        hoverable
        position="top left"
        trigger={<UniqueSettingsInput {...props} />}
        text={props.tooltipText}
        isInline
      />
    )}
    {!props.tooltipText && <UniqueSettingsInput {...props} />}
  </FormValidation>
)
