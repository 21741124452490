import React, { useState } from 'react'

import { Period } from 'pages/UsageHistory/types'
import { SolButtonGroupProps } from 'SolComponents/SolButtonGroup/SolButtonGroup'
import { SolButtonGroup } from 'SolComponents'

interface Option {
  text: string
  value: number
}

interface PeriodSelectorProps {
  onChange: (value: Period) => void
}

const values: Period[] = [Period.WEEK, Period.MONTH, Period.QUARTER]

const options: Option[] = values.map(v => ({
  text: `Last ${v} days`,
  value: v,
}))

const PeriodSelector = ({ onChange }: PeriodSelectorProps) => {

  const [value, setValue] = useState(Period.MONTH)

  const handleChange = (val: Period) => {
    const selectedValue = parseInt(val.toString())
    onChange(selectedValue)
    setValue(selectedValue)
  }
  const timeButtons: SolButtonGroupProps = []

  options.map(option => {
    timeButtons.push({
      primary: value.toString() === option.value.toString(),
      value: option.value.toString(),
      content: option.text,
      onClick: handleChange,
    })
  })
  return (
    <SolButtonGroup buttons={timeButtons} />
  )
}

export default PeriodSelector
