import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
  Template,
  PowerManagementConfiguration,
} from 'graphql/__generated__/types'
import { suspendOptions } from './PowerManagementTypes'
import { SolDropdown, SolCheckbox, SolTimePicker } from 'SolComponents'
import styles from './PowerManagement.module.scss'

export interface Props {
  template: Template
  weekType: string
  updateConfiguration: (newSettings: PowerManagementConfiguration) => void
}

function PowerSettings({
  updateConfiguration,
  template,
  weekType,
}: Props) {

  const configuration = template.revision.configuration as PowerManagementConfiguration

  const [dropdownText, setDropdownText] = useState('')

  useEffect(() => {
    suspendOptions.map(value => {
      if (configuration[weekType].suspendAfter === value.value) {
        return setDropdownText(value.text)
      }

      return dropdownText
    })
  }, [configuration])
  return (
    <div>
      <SolDropdown
        label="Suspend After Inactive"
        type="caret"
        direction="left"
        disabled={!configuration.enableOffHours}
        options={suspendOptions}
        size="small"
        value={configuration[weekType].suspendAfter}
        onChange={value => {
          return updateConfiguration({
            ...configuration,
            [weekType]: {
              startTime: configuration[weekType].startTime,
              allDay: configuration[weekType].allDay,
              endTime:  configuration[weekType].endTime,
              suspendAfter: parseInt(value),
            },
          })
        }}
      />
      <div className={styles.allDay}>
        <SolCheckbox
          label="All Day"
          checked={configuration[weekType]?.allDay}
          disabled={!configuration.enableOffHours}
          onClick={() => {
            updateConfiguration({
              ...configuration,
              [weekType]: {
                startTime: configuration[weekType].startTime,
                allDay:  !configuration[weekType].allDay,
                endTime:  configuration[weekType].endTime,
                suspendAfter: configuration[weekType].suspendAfter,
              },
            })
          }}
        />
      </div>
      <div className={styles.timePicker}>
        <SolTimePicker
          // @ts-ignore
          onClick={(e: any) => {
            e.stopPropagation()
          }}
          label="Start Time"
          value={configuration[weekType].startTime}
          onChange={dates => {
            const twentyFourHourTime = format(dates[0], 'HH:mm')
            updateConfiguration({
              ...configuration,
              [weekType]: {
                ...configuration[weekType],
                startTime: twentyFourHourTime,
              },
            })
          }}
          disabled={!configuration.enableOffHours || configuration[weekType].allDay}
        />
        <span>
          <SolTimePicker
            // @ts-ignore
            onClick={(e: any) => {
              e.stopPropagation()
            }}
            label="End Time"
            value={configuration[weekType].endTime}
            onChange={dates => {
              const twentyFourHourTime = format(dates[0], 'HH:mm')
              updateConfiguration({
                ...configuration,
                [weekType]: {
                  ...configuration[weekType],
                  endTime: twentyFourHourTime,
                },
              })
            }}
            disabled={!configuration.enableOffHours || configuration[weekType].allDay}
          />
        </span>
      </div>
    </div>
  )
}

export default PowerSettings
