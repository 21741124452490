import React from 'react'
import styles from './DigitalSignage.module.scss'
import { Template, DigitalSignageConfiguration, ScreenOption, InstructionOption } from 'graphql/__generated__/types'
import { SolCheckbox, SolDropdown, SolRadio } from 'SolComponents'
import { DropdownItemProps } from 'semantic-ui-react'
import { ValidationInput, validateUrlOrIpAddress } from 'components/FormValidation/ValidationInput'

const fullScreenImg = require('assets/screen_options/full_screen.png')
const footerOnlyImg = require('assets/screen_options/footer_only.png')
const footerOverlayInstructionsOnlyImg = require('assets/screen_options/footer_overlay_instructions_only.png')
const footerOverlayCalOnlyImg = require('assets/screen_options/footer_overlay_cal_only.png')
const footerOverlayNetworkCalImg = require('assets/screen_options/footer_overlay_network_cal.png')

export interface Props {
  template: Template
  updateConfiguration: (newSettings: DigitalSignageConfiguration) => void
}

function DigitalSignage({ template, updateConfiguration }: Props) {
  const configuration = template.revision.configuration as DigitalSignageConfiguration

  const startAfterOptions: DropdownItemProps[] = [
    { value: 0, text: 'Immediate' },
    { value: 10, text: '10s' },
    { value: 30, text: '30s' },
    { value: 60, text: '1m' },
    { value: 300, text: '5m' },
    { value: 600, text: '10m' },
  ]

  const ScreenOptionRadio = ({ text, value }: { text: string; value: ScreenOption }) => (
    <div>
      <SolRadio
        label={text}
        checked={configuration.screenOption === value}
        onClick={() =>
          updateConfiguration({
            ...configuration,
            screenOption: value,
          })
        }
        disabled={!configuration.enabled}
      />
    </div>
  )

  const InstructionOptionRadio = ({ text, value }: { text: string; value: InstructionOption }) => (
    <div>
      <SolRadio
        label={text}
        checked={configuration.instructionOption === value}
        onClick={() =>
          updateConfiguration({
            ...configuration,
            instructionOption: value,
          })
        }
        disabled={!configuration.enabled}
      />
    </div>
  )

  const screenInstructionOptionImg
    = {
      [ScreenOption.Fullscreen]: fullScreenImg,
      [ScreenOption.FooterOnly]: footerOnlyImg,
      [ScreenOption.FooterAndOverlay]: null,
    }[configuration.screenOption]
    ?? {
      [InstructionOption.NetworkOnly]: footerOverlayInstructionsOnlyImg,
      [InstructionOption.CalendarOnly]: footerOverlayCalOnlyImg,
      [InstructionOption.NetworkAndCalendar]: footerOverlayNetworkCalImg,
    }[configuration.instructionOption]

  return (
    <div className={styles.templateWrapper}>
      <SolCheckbox
        bold
        label="Enable Digital Signage"
        checked={configuration.enabled}
        onClick={enabled => updateConfiguration({ ...configuration, enabled })}
      />
      <div className={styles.settings}>
        <div className={styles.row}>
          <ValidationInput
            label="Source URL"
            name="source_url"
            size="extraLarge"
            rules={{ validate: validateUrlOrIpAddress }}
            value={configuration.url}
            onChange={(_, v) => updateConfiguration({ ...configuration, url: v.value })}
            disabled={!configuration.enabled}
          />
          <SolDropdown
            type="caret"
            direction="left"
            options={startAfterOptions}
            label="Start After"
            value={configuration.startAfter}
            onChange={startAfter =>
              updateConfiguration({
                ...configuration,
                startAfter: Number(startAfter),
              })
            }
            size="medium"
            disabled={!configuration.enabled}
          />
        </div>
        <div className={styles.thumbnailText}>[Thumbnail Preview]</div>
        <div className={styles.row}>
          <img src={screenInstructionOptionImg} />
          <div className={styles.screenInstructionOptions}>
            <ScreenOptionRadio text="Full Screen" value={ScreenOption.Fullscreen} />
            <ScreenOptionRadio text="Footer Only" value={ScreenOption.FooterOnly} />
            <ScreenOptionRadio text="Footer + Overlay" value={ScreenOption.FooterAndOverlay} />
            {configuration.screenOption === ScreenOption.FooterAndOverlay && (
              <div className={styles.instructionOptions}>
                <InstructionOptionRadio text="Network Instructions Only" value={InstructionOption.NetworkOnly} />
                <InstructionOptionRadio text="Calendar Only" value={InstructionOption.CalendarOnly} />
                <InstructionOptionRadio text="Network & Calendar" value={InstructionOption.NetworkAndCalendar} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DigitalSignage
