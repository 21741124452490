import { useState } from 'react'

interface Props {
  total?: number
  offset: number
  limit: number
}

export interface Pagination {
  offset: number
  limit: number
  currentPage: number
  maxPage: number
  prev: () => number
  next: () => number
  reset: () => void
  setParams: (props: Partial<Props>) => void
}

export const usePagination = (params?: Props): Pagination => {
  const { total: totalP = 0, offset: offsetP = 0, limit: limitP = 0 } = params || {}
  const [{ offset, limit, total, currentPage }, setPaginationParams] = useState({
    offset: offsetP,
    limit: limitP,
    total: totalP,
    currentPage: 1,
  })
  const maxPage = Math.ceil(total / limit)
    
  const next = () => {
    const newOffset = Math.min(offset + limit, total)
    const newCurrentPage = Math.min(currentPage + 1, maxPage)
    setPaginationParams(state => ({ ...state, offset: newOffset, currentPage: newCurrentPage }))
    return newOffset
  }

  const prev = () => {
    const newOffset = Math.max(offset - limit, 0)
    const newCurrentPage = Math.max(currentPage - 1, 0)
    setPaginationParams(state => ({ ...state, offset: newOffset, currentPage: newCurrentPage }))
    return newOffset
  }
  
  const setParams = (props: Partial<Props>) => setPaginationParams(state => ({ ...state, ...props }))
  
  const reset = () => setPaginationParams(state => ({ ...state, offset: 0, currentPage: 1 }))
  
  return {
    offset,
    limit,
    prev,
    next,
    reset,
    currentPage,
    maxPage,
    setParams,
  }
}
