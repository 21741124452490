import React, { useState } from 'react'
import { AdvancedConfiguration } from 'graphql/__generated__/types'
import styles from '../Advanced.module.scss'
import {
  SolCheckbox,
  SolNotification,
  SolConfirmationModal,
} from 'SolComponents'

export interface Props {
  configuration: AdvancedConfiguration
  templateDiff?: any
  updateConfiguration: (newSettings: AdvancedConfiguration) => void
}

export function HDCPForHDMISettings({
  configuration,
  templateDiff,
  updateConfiguration,
}: Props) {
  const [HDCPModal, setHDCPModal] = useState(false)

  return (
    <>
      <SolCheckbox
        label="HDCP Support for HDMI Input"
        checked={configuration.enableHDCP ?? false}
        mismatched={templateDiff?.enableHDCP === 'mismatched'}
        onClick={() => {
          if (configuration.enableHDCP) {
            updateConfiguration({
              ...configuration,
              enableHDCP: !configuration.enableHDCP,
              licenseAgree: !configuration.licenseAgree,
            })
          } else {
            setHDCPModal(!HDCPModal)
          }
        }}
        bold={false}
        notification={[
          <SolNotification key={0} type="menu" notifications="v5.2+" />,
          <SolNotification key={1} type="menu" notifications="Gen 3+" />,
        ]}
      />
      <SolConfirmationModal
        isOpen={HDCPModal}
        modalText={
          <div className={styles.modal}>
            By enabling this feature, you agree that you are abiding by the copyright laws of your jurisdiction.
          </div>
        }
        subHeaderText="HDCP is supported only on Pods with serial number MPOD8000A2xxxxxx or higher"
        acceptingText="Accept"
        cancelText="Do not accept"
        onCloseModal={() => setHDCPModal(false)}
        onAcceptingAction={() => {
          updateConfiguration({
            ...configuration,
            licenseAgree: true,
            enableHDCP: true,
          })
          setHDCPModal(false)
        }}
      />
    </>
  )
}
