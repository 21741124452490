import React from 'react'
import { useHistoryTable } from './useHistoryTable'
import HistoryTable from './HistoryTable/HistoryTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { SolFilterHeaderCard } from 'SolComponents'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

const History = () => {
  const {
    data,
    isLoading,
    totalItems,
    onExport,
  } = useHistoryTable()

  return (
    <>
      <SolFilterHeaderCard
        header="Management History"
        tableId={DataTableInstance.AlertHistory}
        dateRange={{
          include: true,
          options: {
            fourtyEightHours: false,
            sevenDays: false,
            ninetyDays: false,
            customStart: false,
          },
        }}
        subHeaderDisabled
        handleIntervalChange={() => {}}
      />
      <ContentWrapper>
        <HistoryTable
          data={data ?? []}
          loading={isLoading}
          totalItems={totalItems}
          onExport={onExport}
        />
      </ContentWrapper>
    </>
  )
}

export default History
