import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isMersive, getPath } from 'shared/store/selectors/common'
import Layout from './Layout'
import { State } from 'shared/store/slices/reducerUtils'

const mapStateToProps = (state: State) => ({
  path: getPath(state),
  isMersive: isMersive(state),
})

export default withRouter(connect<{}, any, any>(mapStateToProps)(Layout))
