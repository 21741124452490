import React from 'react'
import { SolRow, SolGrid, SolCheckbox, SolRadio } from 'SolComponents'
import { WifiConfiguration, Template, WifiMode } from '../../../graphql/__generated__/types'
import styles from './Wifi.module.scss'
import WapSettings from './WapSettings/WapSettings'
import ExistingNetwork from './ExistingNetwork/ExistingNetwork'
import { SolNotification } from 'SolComponents'

export interface Props {
  template: Template
  updateConfiguration: (newSettings: WifiConfiguration) => void
  podId?: string
}

function Wifi({ updateConfiguration, template, podId }: Props) {
  const config = template.revision.configuration as WifiConfiguration

  return (
    <div className={styles.wifiWrapper}>
      <SolGrid>
        <SolRow>
          <SolCheckbox
            label="Enable WiFi"
            bold
            checked={!!config.enabled}
            onClick={enabled => {
              updateConfiguration({
                ...config,
                enabled,
              })
            }}
          />
          {config.enabled && (
            <div className={styles.enabledWifiWrapper}>
              <SolCheckbox
                label="Allow admin configuration access"
                notification={<SolNotification type="menu" notifications="Gen 2i+" />}
                checked={config?.allowAdmin ?? true}
                onClick={val => {
                  updateConfiguration({
                    ...config,
                    allowAdmin: val,
                  })
                }}
              />
              <div className={styles.radioButtonsGroup}>
                <div className={styles.singleRadio}>
                  <SolRadio
                    label="WAP"
                    checked={config.mode === WifiMode.Wap}
                    onClick={() => {
                      updateConfiguration({
                        ...config,
                        mode: WifiMode.Wap,
                        // toggling between modes 'defaults' to not hiding SSID
                        wapSettings: {
                          ...config?.wapSettings,
                          hideSsid: false,
                        },
                      })
                    }}
                  />
                </div>
                <div className={styles.singleRadio}>
                  <SolRadio
                    label="Existing network"
                    checked={config.mode === WifiMode.WifiClient}
                    onClick={() => {
                      updateConfiguration({
                        ...config,
                        mode: WifiMode.WifiClient,
                      })
                    }}
                  />
                </div>
                {config.mode === WifiMode.Wap && (
                  <WapSettings
                    config={config?.wapSettings ?? {}}
                    updateConfiguration={wapSettings =>
                      updateConfiguration({
                        ...config,
                        wapSettings: wapSettings,
                      })
                    }
                    podId={podId}
                  />
                )}
                {config.mode === WifiMode.WifiClient && (
                  <ExistingNetwork
                    wifiClientSettings={config?.wifiClientSettings ?? {}}
                    updateWifiClientSettings={clientSettings =>
                      updateConfiguration({
                        ...config,
                        wifiClientSettings: clientSettings,
                      })
                    }
                    podId={podId}
                    isDefault={template.isDefault}
                  />
                )}
              </div>
            </div>
          )}
        </SolRow>
      </SolGrid>
    </div>
  )
}

export default Wifi
