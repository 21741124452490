import React from 'react'
import styles from './CategoriesCard.module.scss'
import { SolCard, SolDropdown, SolButton } from 'SolComponents'
import classNames from 'classnames'
import { Props } from '../SettingsCards'

const telescope = require('shared/images/telescope.png')

export const CategoriesCard = (props: Pick<Props, 'categories' | 'appliedCategories' | 'applyCategory'>) => {
  const categories = (props.categories ?? []).map(category => {
    const appliedCategory = props.appliedCategories?.find(c => c?.categoryId === category?.id)
    const categoryOptions
      = category?.options?.map(option => ({
        text: option?.name,
        value: option?.id,
        disabled: appliedCategory?.id === option?.id,
      })) ?? []
    return {
      id: category?.id ?? '',
      name: category?.name ?? '',
      options: [
        {
          text: 'Not set',
          value: 'unassign',
          disabled: !appliedCategory,
        },
        ...categoryOptions,
      ],
      value: appliedCategory?.id,
    }
  })

  const hasCategories = categories.length > 0

  const content = hasCategories ? (
    <div className={styles.categories}>
      {categories.map(category => (
        <SolDropdown
          key={category.id}
          label={category.name}
          options={category.options}
          defaultValue={category.value ?? 'unassign'}
          onChange={optionId => props.applyCategory(category.id, optionId)}
          containerClassName={styles.category}
          size="fluid"
        />
      ))}
    </div>
  ) : (
    <div className={styles.noCategories}>
      <img src={telescope} className={styles.telescope} />
      <SolButton text="Get started with Categories" color="blue" basic internalLink="/categories" />
    </div>
  )

  return (
    <SolCard
      header={<h2>{hasCategories ? 'Categories' : 'No Categories found.'}</h2>}
      className={classNames(styles.categoriesCard, {
        [styles.hasCategories]: hasCategories,
      })}
    >
      {content}
    </SolCard>
  )
}
